h6.p-group:first-child {
	margin-top: 0;
}
h6.p-group {
	margin-top: 20px;
	padding-bottom: 3px;
	border-bottom: 1px solid #cccccc;
}
.main-profile-menu .profile-user.btn-primary {
	background-color: transparent;
	border-color: transparent;
}

.main-profile-menu .profile-user.btn-primary:active {
	background-color: transparent;
	border-color: transparent;
}

.main-profile-menu .dropdown-toggle.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: inherit;
}

.main-profile-menu.show .dropdown-menu {
	inset: 0px auto auto -180px !important;
}

.main-header-message .dropdown-toggle.btn-primary {
	background-color: transparent;
	border-color: transparent;
}

.main-header-message .dropdown-toggle.btn-primary:active {
	background-color: transparent;
	border-color: transparent;
}

.main-header-message .dropdown-toggle.btn-primary:active:focus {
	box-shadow: inherit;
}

.main-header-message.show .dropdown-menu {
	inset: 6px auto auto -245px !important;
}

.main-header-notification .dropdown-toggle.btn-primary {
	background-color: transparent;
	border-color: transparent;
}

.main-header-notification .dropdown-toggle.btn-primary:active {
	background-color: transparent;
	border-color: transparent;
}

.main-header-notification .dropdown-toggle.btn-primary:active:focus {
	box-shadow: inherit;
}

.main-header-notification.show .dropdown-menu {
	top: 52px;
}

.full-screen.fullscreen-button .full-screen-link svg {
	position: absolute;
	top: 10px;
	right: 10px;
}

.dropdown-toggle::after {
	display: none;
}

.main-header-message.right-toggle .nav-link svg {
	position: absolute;
	top: 10px;
	left: 0;
}

.bg-font {
	color: #efefef;
	font-weight: bold;
}

.bg-blue {
	background-color: #3a87ad;
}

.bg-green {
	background-color: #468847;
}

.bg-red {
	background-color: #580925;
}

.bg-pending {
	background-color: #f89406;
}

.bg-canceled {
	background-color: #b94a48;
}

table.table-list-booking td .bg-canceled {
	display: none;
}

.bg-transferred {
	background-color: #0162e8;
}

.bg-inspection {
	background-color: #ee1289;
}

.bg-upTo {
	background-color: #63259f;
}



.date-control-button-item button {
	margin-top: 1.8rem;
}

.table-info-cruise-title {
	font-weight: 700;
	color: #242f48;
	font-size: 20px;
	text-transform: uppercase;
}

.w-220 {
	width: 370px;
}

.capitalize {
	text-transform: capitalize;
}

.hideClass {
	display: none;
}

.custom-control-label {
	cursor: pointer;
}

.ck-editor__editable_inline {
	min-height: 250px;
}

.modal-full {
	max-width: 90%;
}

.ck.ck-dropdown .ck-dropdown__arrow {
	z-index: 0 !important;
}

.content-button {
	/* width: 420px; */
	width: 320px;
	/* position: fixed !important; */
	background: white;
	border-width: 2px 2px;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-top-style: solid;
	border-right-color: #dddddd;
	border-bottom-color: #dddddd;
	border-left-color: #dddddd;
	border-top-color: #dddddd;
	border-image: inherit;
	border-radius: 5px;
	z-index: 30;
	padding: 15px;
	/* inset: 0px auto auto 0px !important; */
}

.header-more .form-group label {
	margin: 0;
	line-height: 40px;
}

.popover {
	max-width: 350px;
	width: 100%;
	min-height: 150px;
}

.fullWidth100 {
	width: 82%;
	margin-left: 222px;
	padding-bottom: 5px;
	white-space: nowrap;
	overflow-x: auto;
}

.fullWidth100.fullWidth {
	width: 100%;
	margin-left: 0;
}

.fullWidth100 .table {
	width: 100%;
	margin-bottom: 1rem;
	background-color: transparent;
}

.fullWidth100 table tr.day-of-week > td {
	text-transform: none;
	font-weight: bold;
	font-size: 12px;
}
.fullWidth100 table tr.day-of-week > td.weekend,
.fullWidth100 table tr.day-of-week > td.holiday {
	color: #be2020;
}

table > thead > tr > th.sortable {
	cursor: pointer;
}

.table-responsive .table::-webkit-scrollbar {
	display: block;
	width: 5px;
}

.table-responsive .table::-webkit-scrollbar-track {
	background: transparent;
}

.table-responsive .table::-webkit-scrollbar-thumb {
	background-color: #dddddd;
	border-right: none;
	border-left: none;
}

.table-responsive .table::-webkit-scrollbar-track-piece:end {
	background: transparent;
	margin-bottom: 10px;
}

.table-responsive .table::-webkit-scrollbar-track-piece:start {
	background: transparent;
	margin-top: 10px;
}

.fullWidth100 .table tr th {
	text-align: center;
}
.fullWidth100act .table tr th {
	text-align: center;
}

.fullWidth100.fullWidth .table tr td {
	padding: 0.75rem;
	font-size: 12px;
}

.fullWidth100 .table tr td {
	padding: 5px 12px;
	min-width: 90px;
	font-size: 14px;
	text-align: center;
	height: 18px;
	position: relative;
	line-height: 18px;
	vertical-align: middle;
}
.table tr td.td-left {
	text-align: left;
}
.table tr td.td-right {
	text-align: right;
}
.td-hidden {
	display: none;
}
.fullWidth100 .table tr td .action {
	padding: 0 5px;
}
.fullWidth100.operating .table tr td:first-child {
	min-width: 20px;
}

.fullWidth100.operating .table tr td:nth-child(3) {
	white-space: normal;
	min-width: 100px;
}
.fullWidth100.operating .table tr td:nth-child(4),
.fullWidth100.operating .table tr td:nth-child(5) {
	white-space: normal;
	min-width: 150px;
}
.fullWidth100.operating .table tr td:nth-child(6),
.fullWidth100.operating .table tr td:nth-child(12),
.fullWidth100.operating .table tr td:nth-child(13),
.fullWidth100.operating .table tr td:nth-child(14) {
	white-space: normal;
	word-break: break-all;
	min-width: 300px;
}
.table-info-cruise-body .table tr td {
	padding: 0;
}
.list-booking .table tr td:nth-child(1),
.list-booking .table tr td:nth-child(2) {
	font-size: 10px;
}
.contentTable .search-label {
	width: 325px;
}
.contentTable .pull-right {
	float: right;
}
.date-control {
	display: flex;
	justify-content: center;
}
.date-control-select {
	min-width: 125px;
}
.show-more-cell{
	display: block;
}
a.today-item {
    background-color: #078586;
    color: #fff;
    border-radius: 50%;
    padding: 5px;
}
@media(min-width:576px) {
	.date-control {
		display: flex;
		flex-wrap: wrap;
		justify-content: left;
	}
	.date-control-select {
		min-width: 150px;
	}
	.show-more-cell{
		display: none;
	}
	
	

}

@media (max-width:576px) {
	.contentTable .pull-right {
		float: none;
		display: none;
	}

	.revenue-table .table th:first-child,
	.revenue-table .table td:first-child {
		position: unset !important;
	}
	.revenue-table .table th:nth-child(2),
	.revenue-table .table td:nth-child(2) {
		position: unset !important;
	}
}
.k2-bar {
	width: 100%;
	text-align: center;
	color: #ffffff;
}
.k2-bar .bar-status {
	display: inline-block;
}
.k2-bar .bar-booked {
	background-color: #580925
}
.k2-bar .bar-available {
	background-color: #45b327
}
.k2-bar > div {
	padding: 3px 0;
}

.margin-bottom-150 {
	margin-bottom: 150px;
}
.margin-bottom-50 {
	margin-bottom: 50px;
}
.margin-top-10 {
	margin-top: 10px;
}
.margin-top-30 {
	margin-top: 30px;
}
label.booking-summary-filter {
	font-size: 20px;
	color: #078586;
	text-transform: capitalize;
}
.main-color, .table thead tr:nth-child(2) th.main-color:nth-child(3) {
	color: #078586;
}

.revenue-table .table th:first-child,
.revenue-table .table td:first-child {
	position: sticky;
	left: 0px;
	background-color: #ffffff;
	z-index: 1;
}
.revenue-table .table th:nth-child(2),
.revenue-table .table td:nth-child(2) {
	position: sticky;
	left: 90px;
	background-color: #ffffff;
	z-index: 1;
}

.fullWidth100 .table .zui-sticky-col {
	left: 12px;
	width: 120px;
	position: absolute;
	top: auto;
	border-right: 0;
	border-bottom: 0;
	margin-top: -1px;
}

.fullWidth100 .table .zui-sticky-col.last-child {
	border-bottom: 1px solid #dde2ef;
	height: 21px;
}

.fullWidth100 .table .zui-sticky-col2 {
	left: 132px;
	width: 90px;
	position: absolute;
	top: auto;
	border-right: 0;
	border-bottom: 0;
	margin-top: -1px;
}

.fullWidth100 .table .zui-sticky-col2.last-child {
	border-bottom: 1px solid #dde2ef;
	height: 21px;
}

.list-booking {
	color: #ffffff;
	width: 100%;
	float: left;
	text-align: center;
	font-size: 12px;
}

.table-responsive.revenue-table .fullWidth100 .revenue-link {
	color: #ffffff;
	text-align: center;
	font-size: 12px;
	padding: 2px 5px;
	display: inline-block;
	margin: 5px 0;
}

.table-responsive.revenue-table .fullWidth100 td {
	font-size: 12px;
}
th > a:hover {
	text-decoration: underline;
}
a.list-booking:hover {
	text-decoration: underline;
	color: #ffffff;
}

.fullWidth100.fullWidth .list-booking {
	border-radius: 10px;
	padding: 2px;
}

.hide-link {
	position: absolute;
	width: 8px;
	height: 8px;
	right: 0;
	top: 4px;
	border-radius: 50%;
}

.day-black {
	background-color: #9f041b;
}

.btn-customer {
	background-color: #00d1b2;
	color: #ffffff;
	border-color: transparent;
	font-weight: 500;
}

.mt-17 {
	margin-top: 1.7rem !important;
}

.fullWidth100.widthFull {
	width: 100%;
	margin-left: 0;
}

.fullWidth100.fullWidth .hide-link.list-booking {
	position: relative;
	width: 100%;
	height: initial;
}

.info-header h1 {
	font-size: 16px;
	font-weight: initial;
	line-height: 39px;
	margin: 0;
}

.title {
	margin: 0;
}

.title p {
	margin: 0;
	line-height: 25px;
}

.title p strong {
	font-size: 14px;
	font-weight: 600;
}

a.btn-customer:hover {
	background-color: #0a927e;
	color: #ffffff;
}

.show-booking:hover {
	text-decoration: underline;
}

.message {
	border-radius: 4px;
	font-size: 1rem;
}

.message.is-info {
	background-color: #f6fbfe;
}

.message.is-danger {
	background-color: #fff5f7;
	margin-top: 25px;
}

.message.is-info .message-body {
	border-radius: 4px;
	border-style: solid;
	border-width: 0 0 0 4px;
	padding: 1.25em 1.5em;
	border-color: #078586;
	color: #12537e;
}

.message.is-danger .message-body {
	border-radius: 4px;
	border-style: solid;
	border-width: 0 0 0 4px;
	padding: 1.25em 1.5em;
	border-color: #ff3860;
	color: #cd0930;
}

.message .message-body .is-horizontal {
	margin: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.field-label {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-right: 1.5rem;
	text-align: left;
}

.field-body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 5;
	-ms-flex-positive: 5;
	flex-grow: 5;
	-ms-flex-negative: 1;
	flex-shrink: 1;
}

.message .message-body .is-horizontal .field-label.is-normal {
	min-width: 150px;
	padding-top: 0;
	line-height: 40px;
}

.message .message-body .is-horizontal .field-label.is-normal label {
	margin: 0;
}

.field-body .field .control,
.field-body .field p {
	margin: 0;
	line-height: 40px;
}

.field-body .field p strong {
	color: #ad0425;
}

.info-header-detail hr {
	width: 100%;
	float: left;
}

.hide-extraService {
	display: none;
}

.revenue-info .message .message-body .is-horizontal .field-label.is-normal {
	min-width: 220px;
}

#table-to-xls {
	display: none;
}

.th-style {
	line-height: 62px !important;
}

.pie-charts {
	width: 100%;
	display: inline-block;
	text-align: center;
}

.pie-charts .pie-charts-doughnut {
	width: 50%;
	display: inline-block;
}

.style-bar {
	width: 100%;
	display: inline-block;
	text-align: center;
}

table.dataTable thead .sorting::after,
table.dataTable thead .sorting::before {
	content: none;
}

.cruises-content {
	float: right;
}

.cruises-content .item-cruise {
	display: inline-block;
}

.cruises-content .item-cruise .btn-image {
	border: 0;
	background-color: transparent;
	padding: 0;
	margin: 0 5px;
}

.cruises-content .item-cruise .btn-image img {
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 2px solid #aaaaaa;
}

.accordion .card-body {
	padding: 20px;
}

.list-room .table-responsive {
	overflow-x: scroll;
	white-space: nowrap;
}

.list-room .table-responsive::-webkit-scrollbar-button {
	width: 50px;
	height: 50px;
}

.list-room .table-responsive .table {
	min-width: 900px;
}

/* custom scrollbar */

.list-room .table-responsive::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

.list-room .table-responsive::-webkit-scrollbar-thumb {
	border-radius: 10px;
}

.fullWidth100::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

.fullWidth100::-webkit-scrollbar-thumb {
	border-radius: 10px;
}

.tooltip {
	z-index: 99993 !important;
}

.border-double {
	width: 100%;
	float: left;
	border: 2px solid #b94a48;
}

.ajax-booking-cruise {
	min-height: 900px;
	text-align: center;
}

.header-search {
	width: 600px;
}

.table-info-cruise-body .table-responsive {
	position: relative;
	width: 100%;
}

td.cruise-full {
	background-color: #f62208;
	color: #ffffff;
}

.list-customer-delete {
	width: 100%;
	display: inline-block;
}

.list-customer-delete > .form-check {
	display: inline-block;
	margin-right: 15px;
	line-height: 24px;
}

.list-customer-delete > .form-check:last-child {
	margin-right: 0;
}

.info-property .ck-editor.ck-rounded-corners .ck-editor__main .ck-blurred {
	max-height: 550px;
}

.modal-body .form-group .image-block {
	width: 100%;
	display: inline-block;
	margin-top: 15px;
}

.modal-body .form-group .image-block .image-block-item {
	display: inline-block;
	margin: 3px 3px;
}

.border-black {
	width: 100%;
	float: left;
}

.border-black a:nth-child(1 of .show-booking) {
	border: 3px solid #000000;
	height: 18px;
	line-height: 15px;
}

.border-black .list-booking.hide-link {
	border: 1px solid #ffffff;
	margin-right: 3px;
}

.hide-link.bg-pending {
	border: 1px solid #ffffff;
	margin-right: 3px;
}

.hide-link.bg-green {
	border: 1px solid #ffffff;
	margin-right: 3px;
}

.hide-link.bg-blue {
	border: 1px solid #ffffff;
	margin-right: 3px;
}

.hide-link.bg-upTo {
	border: 1px solid #ffffff;
	margin-right: 3px;
}

.hide-link.bg-inspection {
	border: 1px solid #ffffff;
	margin-right: 3px;
}

.hide-link.bg-red {
	border: 1px solid #ffffff;
	margin-right: 3px;
}

.today-activity .today-activity-body {
	width: 100%;
	float: left;
}

.today-activity .today-activity-body > h4 {
	width: 100%;
	float: left;
}

.today-activity .today-activity-body .info-today-activity {
	width: 100%;
	display: inline-block;
}

.today-activity .today-activity-body .info-today-activity .item-info-today-activity {
	display: inline-block;
	margin-right: 3rem;
}

.today-activity .today-activity-body .info-today-activity .item-info-today-activity:last-child {
	margin-right: 0;
}

.today-activity .today-activity-body .info-today-activity .item-info-today-activity h4 {
	color: #0162e8;
	font-size: 25px;
}

.cruise-list-activity {
	width: 100%;
	float: left;
}

div.add-booking {
	color: #000000;
	width: 100%;
	float: left;
	height: 20px;
}
div.add-booking:hover,
.table-info-cruise-body tbody tr:first-child {
	cursor: pointer;
}
.partner-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	/* background-color: pink; */
	grid-gap: 10px;
	justify-content: space-between;
}
.partner-grid-item {
	background-color: rgba(255, 255, 255, 0.8);
	border: 1px solid rgba(0, 0, 0, 0.8);
	font-size: 18px;
	text-align: center;
}
.search-partner {
	display: flex;
	justify-self: space-between;
}
.holiday-item {
	width: 90px;
	float: left;
	margin-right: 15px;
}
.holiday-item button.close {
	color: #9f041b;
	line-height: 0.8;
	opacity: 0;
}
.holiday-item button.close:hover {
	opacity: 1;
}
.holiday-group .remove-all {
	color: #9f041b;
	float: left;
	font-size: 35px;
	line-height: 0.5;
}
.weekend-item {
	float: left;
	margin-right: 15px;
}
.weekend-item input {
	margin-right: 5px;
}
.export-to-excel {
	margin-right: 5px;
}
.export-to-excel:first-child {
	margin-right: 0;
}
.item-error {
	border: 1px solid #9f041b;
	border-radius: 5px;
}
.special-date {
	color: #be2020;
}

/*Responsive*/

/*-------------------------------------------------*/

/* 1. MOBILE VIEW RESPONSIVE
/*-------------------------------------------------*/

/*-------------------------------------------------------*/

/* When the browser is 1300px to all small screens,below css will be applied.
/*-------------------------------------------------------*/

@media screen and (max-width: 1300px) {
}

/*-------------------------------------------------------*/

/* When the browser is 1200px to all small screens,below css will be applied.
/*-------------------------------------------------------*/

@media screen and (max-width: 1200px) {
}

/*-------------------------------------------------------*/

/* When the browser is 1050px to all small screens,below css will be applied.
/*-------------------------------------------------------*/

@media screen and (max-width: 1050px) {
}

/*-------------------------------------------------------*/

/* When the browser is 992px to all small screens,below css will be applied.
/*-------------------------------------------------------*/

@media screen and (max-width: 992px) {
	.header-search {
		width: 600px;
	}
	.main-header-message,
	.main-header-notification,
	.full-screen.fullscreen-button,
	.main-header-message {
		display: none;
	}
	.main-profile-menu.show .dropdown-menu {
		inset: 0px auto auto -30px !important;
	}
	.explain-dashboard.mt-5 {
		margin-top: 6rem !important;
	}
}

/*-------------------------------------------------------*/

/* When the browser is 768px to all small screens,below css will be applied.
/*-------------------------------------------------------*/

@media screen and (max-width: 768px) {
}

/*-------------------------------------------------------*/

/* When the browser is 670px to all small screens,below css will be applied.
/*-------------------------------------------------------*/

@media screen and (max-width: 670px) {
}

/*-------------------------------------------------------*/

/* When the browser is 576px to all small screens,below css will be applied.
/*-------------------------------------------------------*/

@media screen and (max-width: 576px) {
}

/*-------------------------------------------------------*/

/* When the browser is 520px to all small screens,below css will be applied.
/*-------------------------------------------------------*/

@media screen and (max-width: 520px) {
}

/*-------------------------------------------------------*/

/* When the browser is 480px to all small screens,below css will be applied.
/*-------------------------------------------------------*/

@media screen and (max-width: 480px) {
	.main-profile-menu.show .dropdown-menu {
		inset: 0px auto auto -182px !important;
	}
	.fullWidth100 {
		width: 100%;
		margin-left: 0;
	}
	.fullWidth100 .table .zui-sticky-col {
		position: inherit;
		left: 0;
	}
	.fullWidth100 .table .zui-sticky-col2 {
		position: inherit;
		left: 0;
	}
}

/*-------------------------------------------------------*/

/* When the browser is 420px to all small screens,below css will be applied.
/*-------------------------------------------------------*/

@media screen and (max-width: 420px) {
}

/*-------------------------------------------------------*/

/* When the browser is 372px to all small screens,below css will be applied.
/*-------------------------------------------------------*/

@media screen and (max-width: 372px) {
}

/*-------------------------------------------------------*/

/* When the browser is 364px to all small screens,below css will be applied.
/*-------------------------------------------------------*/

@media screen and (max-width: 364px) {
}
