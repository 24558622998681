@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900);
.accordion>.card {
    overflow: hidden
}

.accordion>.card:not(:first-of-type) .card-header:first-child {
    border-radius: 0
}

.accordion>.card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0
}

.accordion>.card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.accordion>.card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.accordion>.card .card-header {
    margin-bottom: -1px
}

.accordion .card {
    border-width: 0
}

.accordion .card+.card {
    margin-top: 1px
}

.accordion .card-header {
    padding: 0;
    background-color: transparent;
    font-weight: 500
}

.accordion .card-header a {
    display: block;
    padding: 14px 20px;
    color: #242f48;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    background-color: #ecf0fa
}

.accordion .card-header a:focus,
.accordion .card-header a:hover {
    color: #242f48
}

.accordion .card-header a.collapsed {
    color: #4d5875;
    background-color: #ecf0fa;
    border-bottom-color: transparent
}

.accordion .card-header a.collapsed:focus,
.accordion .card-header a.collapsed:hover {
    color: #242f48;
    background-color: #dde2ef
}

.accordion .card-body {
    padding: 0 20px 20px;
    background-color: #ecf0fa;
    transition: none
}

.card .card {
    box-shadow: none!important
}

.accordion>.card {
    border: 0!important
}

.accordion-indigo .card {
    border-color: #078586
}

.accordion-indigo .card-header a {
    color: #078586
}

.accordion-indigo .card-header a:focus,
.accordion-indigo .card-header a:hover {
    color: #078586
}

.accordion-indigo .card-header a.collapsed {
    background-color: #078586;
    color: rgba(255, 255, 255, .8);
    font-weight: 500;
    letter-spacing: -.1px
}

.accordion-indigo .card-header a.collapsed:focus,
.accordion-indigo .card-header a.collapsed:hover {
    background-color: #452efa;
    color: #fff
}

.accordion-blue .card {
    border-color: #078586
}

.accordion-blue .card-header a {
    color: #078586
}

.accordion-blue .card-header a:focus,
.accordion-blue .card-header a:hover {
    color: #078586
}

.accordion-blue .card-header a.collapsed {
    background-color: #078586;
    color: rgba(255, 255, 255, .8);
    font-weight: 500;
    letter-spacing: -.1px
}

.accordion-blue .card-header a.collapsed:focus,
.accordion-blue .card-header a.collapsed:hover {
    background-color: #1a53ff;
    color: #fff
}

.accordion-dark .card {
    border-color: #242f48
}

.accordion-dark .card-header a {
    color: #242f48
}

.accordion-dark .card-header a:focus,
.accordion-dark .card-header a:hover {
    color: #242f48
}

.accordion-dark .card-header a.collapsed {
    background-color: #242f48;
    color: rgba(255, 255, 255, .8);
    font-weight: 500;
    letter-spacing: -.1px
}

.accordion-dark .card-header a.collapsed:focus,
.accordion-dark .card-header a.collapsed:hover {
    background-color: #141c2b;
    color: #fff
}

.accordion-gray .card {
    border-color: #737f9e
}

.accordion-gray .card-header a {
    color: #737f9e
}

.accordion-gray .card-header a:focus,
.accordion-gray .card-header a:hover {
    color: #737f9e
}

.accordion-gray .card-header a.collapsed {
    background-color: #737f9e;
    color: rgba(255, 255, 255, .8);
    font-weight: 500;
    letter-spacing: -.1px
}

.accordion-gray .card-header a.collapsed:focus,
.accordion-gray .card-header a.collapsed:hover {
    background-color: #6a7a96;
    color: #fff
}

.main-accordion .accordion-item {
    background-color: #ecf0fa;
    border: 1px solid #dee2ef;
    position: relative;
    transition: all .2s ease-in-out
}

.main-accordion .accordion-item:focus,
.main-accordion .accordion-item:hover {
    cursor: pointer;
    background-color: #f1f2f6;
    border-color: #bcc4de;
    z-index: 4
}

.main-accordion .accordion-item.active {
    background-color: #fff;
    z-index: 5;
    border-color: #bcc4de
}

.main-accordion .accordion-item.active .accordion-title {
    color: #078586
}

.main-accordion .accordion-item.active .accordion-body {
    display: block
}

.main-accordion .accordion-item+.accordion-item {
    margin-top: -1px
}

.main-accordion .accordion-header {
    display: flex;
    align-items: center;
    padding: 15px 20px
}

.main-accordion .accordion-title {
    font-weight: 700;
    color: #031b4e;
    margin: 0;
    transition: all .2s ease-in-out
}

.main-accordion .accordion-body {
    display: none;
    padding: 0 20px 20px
}

@media (prefers-reduced-motion:reduce) {
    .main-accordion .accordion-item {
        transition: none
    }
}

@media (prefers-reduced-motion:reduce) {
    .main-accordion .accordion-title {
        transition: none
    }
}

.panel-heading1.bg-primary .panel-title1 a {
    display: block;
    color: #6a7882
}

.panel-title1 {
    margin-bottom: 0
}

.panel-heading1.bg-primary .panel-title1 a {
    display: block;
    color: #fff
}

.panel-title1 a {
    display: block;
    color: #fff;
    padding: 15px;
    position: relative;
    font-weight: 400;
    font-size: 15px
}

.panel-heading1 a.collapsed:before {
    content: "\e9af";
    position: absolute;
    font-family: feather!important;
    right: 10px;
    top: 12px;
    font-size: 20px;
    transition: all .5s;
    transform: scale(1)
}

.accor.bg-primary h4 a {
    color: #fff
}

.accor h4 a {
    padding: .75rem 1.25rem;
    display: block;
    font-size: 15px;
    border-bottom: 1px solid rgba(154, 180, 202, .16)
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 3px
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 3.8125rem
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit
}

.alert-primary {
    color: #1b3585;
    background-color: #d6e0ff;
    border-color: #c6d4ff
}

.alert-primary hr {
    border-top-color: #adc1ff
}

.alert-primary .alert-link {
    color: #12245b
}

.alert-secondary {
    color: #3f4654;
    background-color: #e4e7ec;
    border-color: #d9dde5
}

.alert-secondary hr {
    border-top-color: #cacfdb
}

.alert-secondary .alert-link {
    color: #292e37
}

.alert-success {
    color: #1f5c01;
    background-color: #d8efcc;
    border-color: #c8e9b8
}

.alert-success hr {
    border-top-color: #b9e3a5
}

.alert-success .alert-link {
    color: #0e2a00
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb
}

.alert-info hr {
    border-top-color: #abdde5
}

.alert-info .alert-link {
    color: #062c33
}

.alert-warning {
    color: #856404;
    background-color: #fbbc0b;
    border-color: #ffeeba
}

.alert-warning hr {
    border-top-color: #ffe8a1
}

.alert-warning .alert-link {
    color: #533f03
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb
}

.alert-danger hr {
    border-top-color: #f1b0b7
}

.alert-danger .alert-link {
    color: #491217
}

.alert-light {
    color: #7f7f81;
    background-color: #fdfdfe;
    border-color: #fcfcfd
}

.alert-light hr {
    border-top-color: #ededf3
}

.alert-light .alert-link {
    color: #666667
}

.alert-dark {
    color: #1f2533;
    background-color: #d8dae0;
    border-color: #c8ccd3
}

.alert-dark hr {
    border-top-color: #babfc8
}

.alert-dark .alert-link {
    color: #0c0e13
}

.alert {
    border-width: 0;
    padding: 12px 15px;
    border-radius: 0
}

.alert .close {
    text-shadow: none;
    opacity: .2
}

.alert strong {
    font-weight: 600
}

[class*=alert-outline-] {
    border: 1px solid transparent
}

.alert-outline-success {
    border-color: #65fe19;
    color: #21b18a
}

.alert-outline-info {
    border-color: #1ab6cf;
    color: #00b9ff
}

.alert-outline-warning {
    border-color: #ffdb6d;
    color: #d39e00
}

.alert-outline-danger {
    border-color: #eb8c95;
    color: #bd2130
}

[class*=alert-solid-] {
    border-width: 0;
    color: #fff
}

.alert-solid-success {
    background-color: #21b18a
}

.alert-solid-info {
    background-color: #00b9ff
}

.alert-solid-warning {
    background-color: #fbbc0b
}

.alert-solid-danger {
    background-color: #ee335e
}

.badge {
    font-size: 10px;
    font-weight: 500!important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    padding: 3px 5px 4px!important
}

.badge-light {
    background-color: #d0d7e8
}

.badge-pill {
    padding-left: 8px;
    padding-right: 8px
}

.badge-primary-transparent {
    color: #078586;
    background-color: #d9e8fe
}

.badge-success-transparent {
    background-color: #d4f2e1!important;
    color: #21b18a
}

.badge-teal-transparent {
    background-color: #d2f5f5!important;
    color: #0cc
}

.badge-warning-transparent {
    background-color: rgba(255, 193, 7, .15)!important;
    color: #fbbc0b
}

.badge-danger-transparent {
    color: #ee335e;
    background-color: #f9e2e2
}

.badge-purple-transparent {
    color: #673ab7;
    background-color: #e1defe
}

.badge-info-transparent {
    color: #0a7ffb;
    background-color: rgba(3, 85, 208, .1)
}

.badge-pink-transparent {
    color: #f10075;
    background-color: #fee5f1
}

.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 3px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .badge {
        transition: none
    }
}

a.badge:focus,
a.badge:hover {
    text-decoration: none
}

.badge:empty {
    display: none
}

.btn .badge,
.sp-container button .badge {
    position: relative;
    top: -1px
}

.badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem
}

.badge-primary {
    color: #fff;
    background-color: #078586
}

a.badge-primary:hover {
    color: #fff;
    background-color: #0040ff
}

a.badge-primary:focus {
    color: #fff;
    background-color: #0040ff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(51, 102, 255, .5)
}

a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(51, 102, 255, .5)
}

.badge-secondary {
    color: #fff;
    background-color: #737f9e
}

a.badge-secondary:hover {
    color: #fff;
    background-color: #737f9e
}

a.badge-secondary:focus {
    color: #fff;
    background-color: #737f9e;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(121, 135, 161, .5)
}

a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(121, 135, 161, .5)
}

.badge-success {
    color: #fff;
    background-color: #21b18a
}

a.badge-success:hover {
    color: #fff;
    background-color: #2a7d01
}

a.badge-success:focus {
    color: #fff;
    background-color: #2a7d01;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(59, 176, 1, .5)
}

a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(59, 176, 1, .5)
}

.badge-info {
    color: #fff;
    background-color: #00b9ff
}

a.badge-info:hover {
    color: #fff;
    background-color: #00b9ff
}

a.badge-info:focus {
    color: #fff;
    background-color: #00b9ff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.badge-warning {
    color: #242f48;
    background-color: #fbbc0b
}

a.badge-warning:focus,
a.badge-warning:hover {
    color: #242f48;
    background-color: #d39e00
}

.badge-purple {
    color: #fff;
    background-color: 7571f9
}

a.badge-purple:hover {
    color: #fff;
    background-color: #6536bb
}

a.badge-purple:focus {
    color: #fff;
    background-color: #6536bb;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(101, 54, 187, .5)
}

a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(101, 54, 187, .5)
}

.badge-teal {
    color: #fff;
    background-color: #0cc
}

a.badge-teal:focus,
a.badge-teal:hover {
    color: #242f48;
    background-color: #0cc
}

a.badge-warning.focus,
a.badge-warning:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.badge-danger {
    color: #fff;
    background-color: #ee335e
}

a.badge-danger:hover {
    color: #fff;
    background-color: #bd2130
}

a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.badge-pink {
    color: #fff;
    background-color: #f10075
}

a.badge-pink:hover {
    color: #fff;
    background-color: #e20b73
}

a.badge-pink:focus {
    color: #fff;
    background-color: #e20b73;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(241, 0, 117, .5)
}

a.badge-pink.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(241, 0, 117, .5)
}

.badge-purple {
    color: #fff;
    background-color: #673ab7
}

a.badge-purple:hover {
    color: #fff;
    background-color: #582ea2
}

a.badge-purple:focus {
    color: #fff;
    background-color: #582ea2;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(103, 58, 183, .5)
}

a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(103, 58, 183, .5)
}

.badge-light {
    color: #242f48;
    background-color: #ecf0fa
}

a.badge-light:hover {
    color: #242f48;
    background-color: #d5d9e4
}

a.badge-light:focus {
    color: #242f48;
    background-color: #d5d9e4;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(244, 245, 248, .5)
}

a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(244, 245, 248, .5)
}

.badge-dark {
    color: #fff;
    background-color: #364261
}

a.badge-dark:hover {
    color: #fff;
    background-color: #283143
}

a.badge-dark:focus {
    color: #fff;
    background-color: #283143;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(59, 72, 99, .5)
}

a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(59, 72, 99, .5)
}

@media print {
    .badge {
        border: 1px solid #000
    }
}

.breadcrumb-header {
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%
}

.breadcrumb-header .breadcrumb {
    background: 0 0!important;
    padding: 0;
    margin-bottom: 0;
    font-size: 13px
}

.breadcrumb-3 ol {
    position: relative;
    display: block;
    width: 100%;
    text-align: center
}

.breadcrumb-3 li {
    display: contents;
    text-align: center
}

.breadcrumb-4 ol {
    position: relative;
    display: block;
    width: 100%;
    text-align: right
}

.breadcrumb-4 li {
    display: contents;
    text-align: right
}

.breadcrumb-item+.breadcrumb-item::before {
    content: ">"
}

@media (max-width:633px) {
    .breadcrumb-header {
        display: block
    }
}

@media (max-width:380px) {
    .breadcrumb-right .btn-outline-primary {
        display: none
    }
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #dde2ef;
    border-radius: 3px
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #737f9e;
    content: "/"
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline;
    text-decoration: none
}

.breadcrumb-item.active {
    color: #737f9e
}

.breadcrumb {
    background-color: #ecf0fa
}

.breadcrumb-style1,
.breadcrumb-style2,
.breadcrumb-style3 {
    border-radius: 0;
    background-color: transparent;
    padding: 0
}

.breadcrumb-style1 .breadcrumb-item,
.breadcrumb-style2 .breadcrumb-item,
.breadcrumb-style3 .breadcrumb-item {
    font-size: 11px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: .5px;
    text-transform: uppercase
}

.breadcrumb-style1 .breadcrumb-item a,
.breadcrumb-style2 .breadcrumb-item a,
.breadcrumb-style3 .breadcrumb-item a {
    color: #242f48
}

.breadcrumb-style1 .breadcrumb-item a:focus,
.breadcrumb-style1 .breadcrumb-item a:hover {
    color: #078586
}

.breadcrumb-style2 .breadcrumb-item a:focus,
.breadcrumb-style2 .breadcrumb-item a:hover {
    color: #078586
}

.breadcrumb-style3 .breadcrumb-item a:focus,
.breadcrumb-style3 .breadcrumb-item a:hover {
    color: #078586
}

.breadcrumb-style1 .breadcrumb-item.active,
.breadcrumb-style2 .breadcrumb-item.active,
.breadcrumb-style3 .breadcrumb-item.active {
    color: #078586
}

.breadcrumb-style1 .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb-style3 .breadcrumb-item+.breadcrumb-item::before {
    color: #949eb7
}

.breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb-style3 .breadcrumb-item+.breadcrumb-item::before {
    width: 16px;
    text-align: center;
    font-family: Ionicons
}

.breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before {
    content: '\f3d1'
}

.breadcrumb-style3 .breadcrumb-item+.breadcrumb-item::before {
    content: '\f287'
}

.main-content-breadcrumb {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #949eb7;
    margin-bottom: 10px
}

.main-content-breadcrumb span {
    position: relative
}

.main-content-breadcrumb span+span::before {
    content: '\f3d1';
    font-family: Ionicons;
    font-size: 12px;
    font-weight: 300;
    display: inline;
    margin: 0 5px;
    color: #b9c2d8;
    line-height: 0;
    position: relative;
    top: 1px
}

.main-content-breadcrumb span:last-child {
    color: #4d5875
}

.main-breadcrumbs {
    padding: 0;
    background-color: transparent;
    margin-bottom: 0
}

.main-breadcrumbs .breadcrumb-item {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: 700
}

.main-breadcrumbs .breadcrumb-item a {
    color: #031b4e;
    transition: all .2s ease-in-out
}

.main-breadcrumbs .breadcrumb-item a:hover {
    color: #949eb7
}

.main-breadcrumbs .breadcrumb-item.active {
    color: #078586
}

.main-breadcrumbs .breadcrumb-item+.breadcrumb-item {
    padding-left: 8px
}

.main-breadcrumbs .breadcrumb-item+.breadcrumb-item::before {
    color: #949eb7;
    padding-right: 8px;
    font-weight: 400
}

@media (prefers-reduced-motion:reduce) {
    .main-breadcrumbs .breadcrumb-item a {
        transition: none
    }
}

.breadcrumb-3 .breadcrumb-item+.breadcrumb-item::before {
    float: inherit
}

.breadcrumb-4 .breadcrumb-item+.breadcrumb-item::before {
    float: inherit
}

.btn,
.sp-container button {
    display: inline-block;
    font-weight: 400;
    color: #031b4e;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: 3px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .btn,
    .sp-container button {
        transition: none
    }
}

.btn:hover,
.sp-container button:hover {
    color: #031b4e;
    text-decoration: none
}

.btn.focus,
.btn:focus,
.sp-container button.focus,
.sp-container button:focus {
    outline: 0;
    box-shadow: none
}

.btn.disabled,
.btn:disabled,
.sp-container button.disabled,
.sp-container button:disabled {
    opacity: .65
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: #078586;
    border-color: #078586
}

.btn-primary:hover {
    color: #fff;
    background-color: #035a5a;
    border-color: #078586
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(1, 98, 232, .2)
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #078586;
    border-color: #078586
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #078586;
    border-color: #078586
}

.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #078586;
    border-color: #078586
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(114, 115, 245, .2)
}

.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(114, 115, 245, .2)
}

.btn-secondary {
    color: #596882;
    background-color: #ecf0fa;
    border: 1px solid #cdd7ef;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #078586;
    border-color: #078586;
}

.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(141, 175, 168, 0.5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #737f9e;
    border-color: #737f9e
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #5f6d88;
    border-color: #5a6780
}

.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #5f6d88;
    border-color: #5a6780
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(141, 153, 175, .5)
}

.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(141, 153, 175, .5)
}

.btn-success {
    color: #fff;
    background-color: #21b18a;
    border-color: #21b18a
}

.btn-success:hover {
    color: #fff;
    background-color: #066d51;
    border-color: #2a7d01
}

.btn-success.focus,
.btn-success:focus {
    box-shadow: 0 0 0 .2rem rgba(88, 188, 39, .5)
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #21b18a;
    border-color: #21b18a
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #2a7d01;
    border-color: #267101
}

.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #2a7d01;
    border-color: #267101
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(88, 188, 39, .5)
}

.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(88, 188, 39, .5)
}

.btn-info {
    color: #fff;
    background-color: #00b9ff;
    border-color: #00b9ff
}

.btn-info:hover {
    color: #fff;
    background-color: #03a8e6;
    border-color: #00b9ff
}

.btn-info.focus,
.btn-info:focus {
    box-shadow: 0 0 0 .2rem rgba(58, 176, 195, .5)
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #00b9ff;
    border-color: #00b9ff
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #00b9ff;
    border-color: #03a8e6
}

.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #00b9ff;
    border-color: #03a8e6
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(58, 176, 195, .5)
}

.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(58, 176, 195, .5)
}

.btn-warning {
    color: #fff;
    background-color: #fbbc0b;
    border-color: #fbbc0b
}

.btn-warning:hover {
    color: #fff;
    background-color: #e0a800;
    border-color: #d39e00
}

.btn-warning.focus,
.btn-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(221, 170, 15, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #fff;
    background-color: #fbbc0b;
    border-color: #fbbc0b
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #d39e00;
    border-color: #c69500
}

.show>.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #d39e00;
    border-color: #c69500
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(221, 170, 15, .5)
}

.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(221, 170, 15, .5)
}

.btn-danger {
    color: #fff!important;
    background-color: #ee335e;
    border-color: #ee335e
}

.btn-danger:hover {
    color: #fff;
    background-color: #f95374;
    border-color: #f95374
}

.btn-danger.focus,
.btn-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(250, 92, 124, .2)
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #ee335e;
    border-color: #ee335e
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #ee335e;
    border-color: #ee335e
}

.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ee335e;
    border-color: #ee335e
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 92, 124, .5)
}

.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 92, 124, .5)
}

.btn-teal {
    color: #fff;
    background-color: #0cc;
    border-color: #05d6d6
}

.btn-teal:hover {
    color: #fff;
    background-color: #04bfbf;
    border-color: #04bfbf
}

.btn-teal.focus,
.btn-teal:focus {
    box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5)
}

.btn-teal.disabled,
.btn-teal:disabled {
    color: #fff;
    background-color: #04bfbf;
    border-color: #04bfbf
}

.btn-teal:not(:disabled):not(.disabled).active,
.btn-teal:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #04bfbf;
    border-color: #04bfbf
}

.show>.btn-teal.dropdown-toggle {
    color: #fff;
    background-color: #04bfbf;
    border-color: #04bfbf
}

.btn-teal:not(:disabled):not(.disabled).active:focus,
.btn-teal:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(4, 191, 191, .5)
}

.show>.btn-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(4, 191, 191, .5)
}

.btn-light {
    color: #242f48;
    background-color: #ecf0fa;
    border-color: #ecf0fa
}

.btn-light:hover {
    color: #242f48;
    background-color: #dde0e9;
    border-color: #d5d9e4
}

.btn-light.focus,
.btn-light:focus {
    box-shadow: 0 0 0 .2rem rgba(212, 214, 220, .5)
}

.btn-light.disabled,
.btn-light:disabled {
    color: #242f48;
    background-color: #ecf0fa;
    border-color: #ecf0fa
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active {
    color: #242f48;
    background-color: #d5d9e4;
    border-color: #cdd2df
}

.show>.btn-light.dropdown-toggle {
    color: #242f48;
    background-color: #d5d9e4;
    border-color: #cdd2df
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(212, 214, 220, .5)
}

.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(212, 214, 220, .5)
}

.btn-dark {
    color: #fff;
    background-color: #364261;
    border-color: #364261
}

.btn-dark:hover {
    color: #fff;
    background-color: #2d374b;
    border-color: #283143
}

.btn-dark.focus,
.btn-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(88, 99, 122, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #364261;
    border-color: #364261
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #283143;
    border-color: #232b3b
}

.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #283143;
    border-color: #232b3b
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(88, 99, 122, .5)
}

.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(88, 99, 122, .5)
}

.btn-outline-primary {
    color: #078586;
    border-color: #078586
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #078586!important;
    border-color: #078586
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(51, 102, 255, .5);
    background-color: #078586!important
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #078586;
    background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #078586;
    border-color: #078586
}

.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #078586;
    border-color: #078586
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(51, 102, 255, .5)
}

.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(51, 102, 255, .5)
}

.btn-outline-secondary {
    color: #737f9e;
    border-color: #737f9e
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #737f9e;
    border-color: #737f9e
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    background-color: #737f9e;
    box-shadow: 0 0 0 .2rem rgba(121, 135, 161, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #737f9e;
    background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #737f9e;
    border-color: #737f9e
}

.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #737f9e;
    border-color: #737f9e
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(121, 135, 161, .5)
}

.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(121, 135, 161, .5)
}

.btn-outline-success {
    color: #21b18a;
    border-color: #21b18a
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #21b18a;
    border-color: #21b18a
}

.btn-outline-success.focus,
.btn-outline-success:focus {
    background-color: #21b18a;
    box-shadow: 0 0 0 .2rem rgba(59, 176, 1, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #21b18a;
    background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #21b18a;
    border-color: #21b18a
}

.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #21b18a;
    border-color: #21b18a
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(59, 176, 1, .5)
}

.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(59, 176, 1, .5)
}

.btn-outline-info {
    color: #00b9ff;
    border-color: #00b9ff
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #00b9ff;
    border-color: #00b9ff
}

.btn-outline-info.focus,
.btn-outline-info:focus {
    background-color: #00b9ff;
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #00b9ff;
    background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #00b9ff;
    border-color: #00b9ff
}

.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00b9ff;
    border-color: #00b9ff
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-warning {
    color: #fbbc0b;
    border-color: #fbbc0b
}

.btn-outline-warning:hover {
    color: #242f48;
    background-color: #fbbc0b;
    border-color: #fbbc0b
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
    background-color: #fbbc0b;
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #fbbc0b;
    background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active {
    color: #242f48;
    background-color: #fbbc0b;
    border-color: #fbbc0b
}

.show>.btn-outline-warning.dropdown-toggle {
    color: #242f48;
    background-color: #fbbc0b;
    border-color: #fbbc0b
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-danger {
    color: #ee335e;
    border-color: #ee335e
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #ee335e;
    border-color: #ee335e
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
    background-color: #ee335e;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #ee335e;
    background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #ee335e;
    border-color: #ee335e
}

.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ee335e;
    border-color: #ee335e
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-light {
    color: #ecf0fa;
    border-color: #ecf0fa
}

.btn-outline-light:hover {
    color: #242f48;
    background-color: #ecf0fa;
    border-color: #ecf0fa
}

.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: 0 0 0 .2rem rgba(244, 245, 248, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #ecf0fa;
    background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active {
    color: #242f48;
    background-color: #ecf0fa;
    border-color: #ecf0fa
}

.show>.btn-outline-light.dropdown-toggle {
    color: #242f48;
    background-color: #ecf0fa;
    border-color: #ecf0fa
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(244, 245, 248, .5)
}

.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(244, 245, 248, .5)
}

.btn-outline-dark {
    color: #364261;
    border-color: #364261
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #364261;
    border-color: #364261
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
    background-color: #364261;
    box-shadow: 0 0 0 .2rem rgba(59, 72, 99, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #364261;
    background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #364261;
    border-color: #364261
}

.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #364261;
    border-color: #364261
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(59, 72, 99, .5)
}

.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(59, 72, 99, .5)
}

.btn-link {
    font-weight: 400;
    color: #078586;
    text-decoration: none
}

.btn-link:hover {
    color: #8485fb;
    text-decoration: none
}

.btn-link.focus,
.btn-link:focus {
    text-decoration: none;
    box-shadow: none
}

.btn-link.disabled,
.btn-link:disabled {
    color: #737f9e;
    pointer-events: none
}

.btn-group-lg>.btn,
.btn-lg,
.sp-container .btn-group-lg>button {
    padding: .5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: .3rem
}

.btn-group-sm>.btn,
.btn-sm,
.sp-container .btn-group-sm>button {
    padding: .25rem .5rem;
    font-size: .76562rem;
    line-height: 1.5;
    border-radius: .2rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn,
.sp-container .btn-group-vertical>button,
.sp-container .btn-group>button {
    position: relative;
    flex: 1 1 auto
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover,
.sp-container .btn-group-vertical>button.active,
.sp-container .btn-group-vertical>button:active,
.sp-container .btn-group-vertical>button:focus,
.sp-container .btn-group-vertical>button:hover,
.sp-container .btn-group>button.active,
.sp-container .btn-group>button:active,
.sp-container .btn-group>button:focus,
.sp-container .btn-group>button:hover {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn:not(:first-child),
.sp-container .btn-group>button:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn-group:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:last-child)>.btn,
.sp-container .btn-group>.btn-group:not(:last-child)>button,
.sp-container .btn-group>button:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child),
.sp-container .btn-group>.btn-group:not(:first-child)>button,
.sp-container .btn-group>button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split,
.sp-container .btn-group-sm>button+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split,
.sp-container .btn-group-lg>button+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical>.btn {
    width: 100%
}

.sp-container .btn-group-vertical>button {
    width: 100%
}

.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px
}

.sp-container .btn-group-vertical>button:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.sp-container .btn-group-vertical>.btn-group:not(:last-child)>button,
.sp-container .btn-group-vertical>button:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child),
.sp-container .btn-group-vertical>.btn-group:not(:first-child)>button,
.sp-container .btn-group-vertical>button:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn,
.sp-container .btn-group-toggle>.btn-group>button,
.sp-container .btn-group-toggle>button {
    margin-bottom: 0
}

.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio],
.sp-container .btn-group-toggle>.btn-group>button input[type=checkbox],
.sp-container .btn-group-toggle>.btn-group>button input[type=radio],
.sp-container .btn-group-toggle>button input[type=checkbox],
.sp-container .btn-group-toggle>button input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.btn,
.sp-container button {
    border-width: 0;
    line-height: 1.538;
    padding: 9px 20px;
    transition: none
}

.btn:active,
.btn:focus,
.sp-container button:active,
.sp-container button:focus {
    box-shadow: none
}

.btn-light {
    color: #242f48;
    background-color: #ecf0fa;
    border-color: #dce1ec
}

.btn-light:hover {
    color: #242f48;
    background-color: #dce1ec;
    border-color: #dce1ec
}

.btn-light.focus,
.btn-light:focus {
    box-shadow: 0 0 0 .2rem #f0eff4
}

.btn-light.disabled,
.btn-light:disabled {
    color: #242f48;
    background-color: #ecf0fa;
    border-color: #ecf0fa
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active {
    color: #242f48;
    background-color: #ecf0fa;
    border-color: #ecf0fa
}

.show>.btn-light.dropdown-toggle {
    color: #242f48;
    background-color: #e9e7f5;
    border-color: #e9e7f5
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem #f0eff4
}

.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem #f0eff4
}

.btn-indigo {
    color: #fff;
    background-color: #b057bf;
    border-color: #b057bf
}

.btn-indigo:hover {
    color: #fff;
    background-color: #ac50bb;
    border-color: #ac50bb
}

.btn-indigo.focus,
.btn-indigo:focus {
    box-shadow: 0 0 0 .2rem rgba(172, 80, 187, .5)
}

.btn-indigo.disabled,
.btn-indigo:disabled {
    color: #fff;
    background-color: #b057bf;
    border-color: #b057bf
}

.btn-indigo:not(:disabled):not(.disabled).active,
.btn-indigo:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #b057bf;
    border-color: #b057bf
}

.show>.btn-indigo.dropdown-toggle {
    color: #fff;
    background-color: #b057bf;
    border-color: #b057bf
}

.btn-indigo:not(:disabled):not(.disabled).active:focus,
.btn-indigo:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(172, 80, 187, .5)
}

.show>.btn-indigo.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(172, 80, 187, .5)
}

.btn-purple {
    color: #fff;
    background-color: #673ab7;
    border-color: #643ab0
}

.btn-purple:hover {
    color: #fff;
    background-color: #5e37a6;
    border-color: #4e2d89
}

.btn-purple.focus,
.btn-purple:focus {
    box-shadow: 0 0 0 .2rem rgba(123, 87, 188, .5)
}

.btn-purple.disabled,
.btn-purple:disabled {
    color: #fff;
    background-color: #673ab7;
    border-color: #643ab0
}

.btn-purple:not(:disabled):not(.disabled).active,
.btn-purple:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #59339d;
    border-color: #482a80
}

.show>.btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #59339d;
    border-color: #482a80
}

.btn-purple:not(:disabled):not(.disabled).active:focus,
.btn-purple:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(123, 87, 188, .5)
}

.show>.btn-purple.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(123, 87, 188, .5)
}

.btn-pink {
    color: #fff;
    background-color: #f10075;
    border-color: #d80069
}

.btn-pink:hover {
    color: #fff;
    background-color: #cb0062;
    border-color: #a50050
}

.btn-pink.focus,
.btn-pink:focus {
    box-shadow: 0 0 0 .2rem rgba(221, 38, 127, .5)
}

.btn-pink.disabled,
.btn-pink:disabled {
    color: #fff;
    background-color: #f10075;
    border-color: #d80069
}

.btn-pink:not(:disabled):not(.disabled).active,
.btn-pink:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #be005c;
    border-color: #98004a
}

.show>.btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #be005c;
    border-color: #98004a
}

.btn-pink:not(:disabled):not(.disabled).active:focus,
.btn-pink:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(221, 38, 127, .5)
}

.show>.btn-pink.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(221, 38, 127, .5)
}

.btn-gray-500 {
    color: #242f48;
    background-color: #949eb7;
    border-color: #8896af
}

.btn-gray-500:hover {
    color: #fff;
    background-color: #808faa;
    border-color: #697b9a
}

.btn-gray-500.focus,
.btn-gray-500:focus {
    box-shadow: 0 0 0 .2rem rgba(120, 133, 158, .5)
}

.btn-gray-500.disabled,
.btn-gray-500:disabled {
    color: #242f48;
    background-color: #949eb7;
    border-color: #8896af
}

.btn-gray-500:not(:disabled):not(.disabled).active,
.btn-gray-500:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #7988a4;
    border-color: #637493
}

.show>.btn-gray-500.dropdown-toggle {
    color: #fff;
    background-color: #7988a4;
    border-color: #637493
}

.btn-gray-500:not(:disabled):not(.disabled).active:focus,
.btn-gray-500:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(120, 133, 158, .5)
}

.show>.btn-gray-500.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(120, 133, 158, .5)
}

.btn-gray-700 {
    color: #fff;
    background-color: #4d5875;
    border-color: #4f5c73
}

.btn-gray-700:hover {
    color: #fff;
    background-color: #49566b;
    border-color: #3a4455
}

.btn-gray-700.focus,
.btn-gray-700:focus {
    box-shadow: 0 0 0 .2rem rgba(105, 116, 136, .5)
}

.btn-gray-700.disabled,
.btn-gray-700:disabled {
    color: #fff;
    background-color: #4d5875;
    border-color: #4f5c73
}

.btn-gray-700:not(:disabled):not(.disabled).active,
.btn-gray-700:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #445064;
    border-color: #353e4d
}

.show>.btn-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #445064;
    border-color: #353e4d
}

.btn-gray-700:not(:disabled):not(.disabled).active:focus,
.btn-gray-700:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(105, 116, 136, .5)
}

.show>.btn-gray-700.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(105, 116, 136, .5)
}

[class*=btn-outline-] {
    padding: .4rem 1rem;
    line-height: 1.657;
    border-width: 1px;
    background-color: transparent
}

[class*=btn-outline-]:focus,
[class*=btn-outline-]:hover {
    color: #fff
}

.btn-outline-indigo {
    border-color: #b057bf;
    color: #b057bf
}

.btn-outline-indigo:focus,
.btn-outline-indigo:hover {
    background-color: #b057bf
}

.btn-outline-light {
    border-color: #949eb7;
    color: #949eb7
}

.btn-outline-light:focus,
.btn-outline-light:hover {
    background-color: #949eb7
}

.btn-main-primary {
    color: #fff;
    background-color: #078586;
    border-color: #066e6f
}

.btn-main-primary:hover {
    color: #fff;
    background-color: #066e6f;
    border-color: #078586
}

.btn-main-primary.focus,
.btn-main-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(1, 98, 232, .5)
}

.btn-main-primary.disabled,
.btn-main-primary:disabled {
    color: #fff;
    background-color: #078586;
    border-color: #078586
}

.btn-main-primary:not(:disabled):not(.disabled).active,
.btn-main-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #078586;
    border-color: #078586
}

.show>.btn-main-primary.dropdown-toggle {
    color: #fff;
    background-color: #078586;
    border-color: #078586
}

.btn-main-primary:not(:disabled):not(.disabled).active:focus,
.btn-main-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(1, 98, 232, .5)
}

.show>.btn-main-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(1, 98, 232, .5)
}

.btn-main-secondary {
    color: #fff;
    background-color: #078586;
    border-color: #078586
}

.btn-main-secondary:hover {
    color: #fff;
    background-color: #078586;
    border-color: #078586
}

.btn-main-secondary.focus,
.btn-main-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(60, 109, 255, .5)
}

.btn-main-secondary.disabled,
.btn-main-secondary:disabled {
    color: #fff;
    background-color: #078586;
    border-color: #078586
}

.btn-main-secondary:not(:disabled):not(.disabled).active,
.btn-main-secondary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #078586;
    border-color: #078586
}

.show>.btn-main-secondary.dropdown-toggle {
    color: #fff;
    background-color: #078586;
    border-color: #078586
}

.btn-main-secondary:not(:disabled):not(.disabled).active:focus,
.btn-main-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(60, 109, 255, .5)
}

.show>.btn-main-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(60, 109, 255, .5)
}

.btn-main-secondary {
    color: #fff;
    background-color: #078586;
    border-color: #1a53ff
}

.btn-main-secondary:hover {
    color: #fff;
    background-color: #0d49ff;
    border-color: #8485fb
}

.btn-main-secondary.focus,
.btn-main-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(60, 109, 255, .5)
}

.btn-main-secondary.disabled,
.btn-main-secondary:disabled {
    color: #fff;
    background-color: #078586;
    border-color: #1a53ff
}

.btn-main-secondary:not(:disabled):not(.disabled).active,
.btn-main-secondary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #0040ff;
    border-color: #0036d9
}

.show>.btn-main-secondary.dropdown-toggle {
    color: #fff;
    background-color: #0040ff;
    border-color: #0036d9
}

.btn-main-secondary:not(:disabled):not(.disabled).active:focus,
.btn-main-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(60, 109, 255, .5)
}

.show>.btn-main-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(60, 109, 255, .5)
}

.btn-rounded {
    border-radius: 50px
}

.btn-with-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px
}

.btn-with-icon i {
    line-height: 0;
    font-size: 20px;
    margin-right: 5px
}

.btn-with-icon i.typcn {
    line-height: .9
}

.btn-with-icon i.fas {
    font-size: 15px
}

.btn-with-icon i.icon {
    font-size: 18px
}

.btn-icon {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0
}

.btn-icon i {
    line-height: 0;
    font-size: 1.2rem
}

.btn-icon i.typcn {
    line-height: .95
}

.btn-icon-list {
    display: flex;
    align-items: center
}

.btn-icon-list .btn+.btn,
.btn-icon-list .sp-container button+.btn {
    margin-left: 5px
}

.btn-icon-list .sp-container .btn+button,
.btn-icon-list .sp-container button+button,
.sp-container .btn-icon-list .btn+button,
.sp-container .btn-icon-list button+.btn,
.sp-container .btn-icon-list button+button {
    margin-left: 5px
}

@media (max-width:991px) {
    .btn.btn-default.nav-link {
        margin-top: 0
    }
}

.nav-link#bs-example-navbar-collapse-1 {
    padding: .6rem .3rem
}

.btn-warning-light {
    color: #fdac41;
    background-color: rgba(253, 172, 65, .2);
    border-color: rgba(253, 172, 65, .2)
}

.btn-warning-light:hover {
    color: #242f48;
    background-color: #fdac41;
    border-color: #fdac41
}

.btn-warning.focus,
.btn-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(221, 170, 15, .5)
}

.btn-warning-light.disabled,
.btn-warning-light:disabled {
    color: #242f48;
    background-color: #fdac41;
    border-color: #fdac41
}

.btn-warning-light:not(:disabled):not(.disabled).active,
.btn-warning-light:not(:disabled):not(.disabled):active {
    color: #242f48;
    background-color: #fdac41;
    border-color: #fdac41
}

.show>.btn-warning-light.dropdown-toggle {
    color: #242f48;
    background-color: #fdac41;
    border-color: #fdac41
}

.btn-warning-light:not(:disabled):not(.disabled).active:focus,
.btn-warning-light:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(221, 170, 15, .5)
}

.show>.btn-warning-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(221, 170, 15, .5)
}

.btn-primary-gradient {
    color: #fff;
    background: linear-gradient(to left, #0db2de 0, #005bea 100%);
    border: 1px solid #0db2de
}

.btn-primary-gradient:hover {
    color: #fff;
    background: linear-gradient(to left, #099fc7 0, #0555d4 100%);
    border-color: #0db2de
}

.btn-primary-gradient.focus,
.btn-primary-gradient:focus {
    background: linear-gradient(to left, #099fc7 0, #0555d4 100%);
    box-shadow: 0 0 0 .2rem rgba(5, 85, 212, .5)
}

.btn-danger-gradient {
    color: #fff;
    background: linear-gradient(45deg, #f93a5a, #f7778c);
    border: 1px solid #ef6178
}

.btn-danger-gradient:hover {
    color: #fff;
    background: linear-gradient(45deg, #fb3254, #f96d84);
    border-color: #ef6178
}

.btn-danger-gradient.focus,
.btn-danger-gradient:focus {
    background: linear-gradient(45deg, #fb3254, #f96d84);
    box-shadow: 0 0 0 .2rem rgba(251, 50, 84, .5)
}

.btn-pink-gradient {
    color: #fff;
    background: linear-gradient(to right, #f10075 0, #f36eae 100%);
    border: 1px solid #f10075
}

.btn-pink-gradient:hover {
    color: #fff;
    background: linear-gradient(to right, #de056e 0, #f565aa 100%);
    border-color: #f10075
}

.btn-pink-gradient.focus,
.btn-pink-gradient:focus {
    background: linear-gradient(to right, #de056e 0, #f565aa 100%);
    box-shadow: 0 0 0 .2rem rgba(222, 5, 110, .5)
}

.btn-purple-gradient {
    color: #fff;
    background: linear-gradient(to right, #673ab7 0, #884af1 100%);
    border: 1px solid #673ab7
}

.btn-purple-gradient:hover {
    color: #fff;
    background: linear-gradient(to right, #6437b3 0, #7d40e4 100%);
    border-color: #673ab7
}

.btn-purple-gradient.focus,
.btn-purple-gradient:focus {
    background: linear-gradient(to right, #6437b3 0, #7d40e4 100%);
    box-shadow: 0 0 0 .2rem rgba(100, 55, 179, .5)
}

.btn-warning-gradient {
    color: #fff;
    background: linear-gradient(to left, #efa65f, #f76a2d);
    border: 1px solid #efa65f
}

.btn-warning-gradient:hover {
    color: #fff;
    background: linear-gradient(to left, #efa359, #f56729);
    border-color: #efa65f
}

.btn-warning-gradient.focus,
.btn-warning-gradient:focus {
    background: linear-gradient(to left, #efa359, #f56729);
    box-shadow: 0 0 0 .2rem rgba(239, 163, 89, .5)
}

.btn-success-gradient {
    color: #fff;
    background: linear-gradient(to left, #48d6a8 0, #029666 100%);
    border: 1px solid #3dc59a
}

.btn-success-gradient:hover {
    color: #fff;
    background: linear-gradient(to left, #3ed0a1 0, #039062 100%);
    border-color: #3dc59a
}

.btn-success-gradient.focus,
.btn-success-gradient:focus {
    background: linear-gradient(to left, #3ed0a1 0, #039062 100%);
    box-shadow: 0 0 0 .2rem rgba(3, 144, 98, .5)
}

.btn-info-gradient {
    color: #fff;
    background: linear-gradient(to right, #0296ce 0, #38c6f7 100%);
    border: 1px solid #29b9ec
}

.btn-info-gradient:hover {
    color: #fff;
    background: linear-gradient(to right, #0993c7 0, #22b7ea 100%);
    border-color: #29b9ec
}

.btn-info-gradient.focus,
.btn-info-gradient:focus {
    background: linear-gradient(to right, #0993c7 0, #22b7ea 100%);
    box-shadow: 0 0 0 .2rem rgba(34, 183, 234, .5)
}

.btn-light-gradient {
    color: #000;
    background: linear-gradient(120deg, #cdd2de 0, #ecf0fa 100%);
    border: 1px solid #cdd2de
}

.btn-light-gradient:hover {
    color: #000;
    background: linear-gradient(120deg, #c1c7d6 0, #e0e5f1 100%);
    border-color: #cdd2de
}

.btn-light-gradient.focus,
.btn-light-gradient:focus {
    background: linear-gradient(120deg, #c1c7d6 0, #e0e5f1 100%);
    box-shadow: 0 0 0 .2rem rgba(193, 199, 214, .5)
}

.btn-dark-gradient {
    color: #fff;
    background: linear-gradient(60deg, #29323c 0, #485563 100%);
    border: 1px solid #29323c
}

.btn-dark-gradient:hover {
    color: #fff;
    background: linear-gradient(60deg, #212931 0, #424f5d 100%);
    border-color: #29323c
}

.btn-dark-gradient.focus,
.btn-dark-gradient:focus {
    background: linear-gradient(60deg, #212931 0, #424f5d 100%);
    box-shadow: 0 0 0 .2rem rgba(33, 41, 49, .5)
}

.btn-secondary-gradient {
    color: #fff;
    background: linear-gradient(to right, #636f8e 0, #acb8da 100%);
    border: 1px solid #9aa6c7
}

.btn-secondary-gradient:hover {
    color: #fff;
    background: linear-gradient(to right, #596586 0, #92a0ca 100%);
    border-color: #9aa6c7
}

.btn-secondary-gradient.focus,
.btn-secondary-gradient:focus {
    background: linear-gradient(to right, #596586 0, #92a0ca 100%);
    box-shadow: 0 0 0 .2rem rgba(88, 100, 130, .5)
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: #fff!important;
    background-clip: border-box;
    border-radius: 5px;
    border: 1px solid #deebfd;
    margin-bottom: 1.3rem;
    box-shadow: -8px 12px 18px 0 #dadee8
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem
}

.card-title {
    margin-bottom: .75rem
}

.card-subtitle {
    margin-top: -.375rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 1.25rem
}

.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header:first-child {
    border-radius: calc(3px - 1px) calc(3px - 1px) 0 0
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0
}

.card-footer {
    padding: .75rem 1.25rem;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, .125)
}

.card-footer:last-child {
    border-radius: 0 0 calc(3px - 1px) calc(3px - 1px)
}

.card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -.625rem;
    margin-left: -.625rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem
}

.card-img {
    width: 100%;
    border-radius: calc(3px - 1px)
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(3px - 1px);
    border-top-right-radius: calc(3px - 1px)
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(3px - 1px);
    border-bottom-left-radius: calc(3px - 1px)
}

.card-deck {
    display: flex;
    flex-direction: column
}

.card-deck .card {
    margin-bottom: 15px
}

@media (min-width:576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px
    }
    .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px
    }
}

.card-group {
    display: flex;
    flex-direction: column
}

.card-group>.card {
    margin-bottom: 15px
}

@media (min-width:576px) {
    .card-group {
        flex-flow: row wrap
    }
    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }
    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }
    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }
    .card-group>.card:not(:last-child) .card-header,
    .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }
    .card-group>.card:not(:last-child) .card-footer,
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }
    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }
    .card-group>.card:not(:first-child) .card-header,
    .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }
    .card-group>.card:not(:first-child) .card-footer,
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.card-columns .card {
    margin-bottom: .75rem
}

@media (min-width:576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1
    }
    .card-columns .card {
        display: inline-block;
        width: 100%
    }
}

.card {
    border-radius: 7px
}

.card.text-white .card-title,
.card.tx-white .card-title {
    color: #fff
}

.card-footer,
.card-header {
    position: relative;
    border-color: #d0d7e8;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 1.25rem
}

.card-header {
    border-bottom: 0;
    border-top-left-radius: 7px!important;
    border-top-right-radius: 7px!important
}

.card-header:first-child {
    border-radius: 0
}

.card-footer {
    border-bottom-left-radius: 7px!important;
    border-bottom-right-radius: 7px!important
}

.card-header-tab {
    border-bottom: 0;
    padding: 0
}

.card-title {
    font-weight: 700;
    color: #242f48;
    font-size: 14px;
    text-transform: uppercase
}

.card-item-desc .card-item-desc-1 dt,
dd {
    display: inline-block
}

.card-item-desc .card-item-desc-1 dt {
    font-weight: 500;
    font-size: 13px
}

.card-item-desc .card-item-desc-1 dd {
    font-size: 13px;
    color: #737f9e;
    margin-bottom: 0
}

.card-item-desc dl {
    margin-bottom: 0
}

.card .card-header .card-header-right .card-option {
    width: 35px;
    height: 20px;
    overflow: hidden;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

.card .card-header .card-header-right .card-option li {
    display: inline-block
}

.card-category {
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    letter-spacing: .05em;
    margin: 0 0 .5rem;
    background: #ecf0fa;
    padding: 7px 0;
    border-radius: 4px
}

.card-dashboard-audience-metrics .flot-chart .flot-x-axis>div {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: #737f9e;
    display: none
}

.card-minimal-two .nav-pills .nav-link.active {
    border-radius: 2px;
    background-color: #078586
}

.card-minimal-two .nav-link {
    display: block;
    padding: 8px 30px;
    margin: 2px;
    background-color: #dde2ef
}

.card-body-top {
    top: 63px;
    left: -5px;
    position: absolute;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 10
}

.card-body-top a {
    color: #242f48;
    font-weight: 700;
    width: 20px;
    text-align: right;
    display: inline-block
}

.card-minimal-four .card-footer .nav-link {
    flex: 1;
    display: block;
    text-align: center;
    background-color: #dde2ef;
    position: relative;
    padding-left: 10px;
    padding-right: 10px
}

.card-progress {
    display: flex;
    align-items: center
}

.card-progress .progress {
    height: 3px;
    flex: 1;
    margin: 0 5px
}

.card.card-primary {
    border-top: 2px solid #078586!important
}

.card.card-secondary {
    border-top: 2px solid #737f9e!important
}

.card.card-success {
    border-top: 2px solid #22e840!important
}

.card.card-danger {
    border-top: 2px solid #ee335e!important
}

.card.card-warning {
    border-top: 2px solid #ffb209!important
}

.card.card-info {
    border-top: 2px solid #01b8ff!important
}

.card.card-purple {
    border-top: 2px solid #673ab7!important
}

.card.card-dark {
    border-top: 2px solid #343a40!important
}

.carousel-slider #carousel {
    margin: 0
}

.carousel-slider #thumbcarousel {
    margin: 10px 0 0 0;
    padding: 0
}

.carousel-slider #thumbcarousel .carousel-item {
    text-align: center
}

.carousel-slider #thumbcarousel .carousel-item .thumb {
    width: 100%;
    margin: 0 2px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    max-width: 100px
}

.carousel-slider #thumbcarousel .carousel-item .thumb:hover {
    border-color: #e8ebf3
}

.carousel-slider .carousel-item img {
    width: 100%;
    height: auto
}

.carousel-control:active,
.carousel-control:focus,
.carousel-control:hover {
    color: #333
}

#basicSlider img {
    width: 100%
}

#thumbcarousel .carousel-control-prev {
    left: 0;
    background: rgba(0, 0, 0, .8)
}

#thumbcarousel .carousel-control-next {
    right: 0;
    background: rgba(0, 0, 0, .8)
}

.owl-carousel button.owl-dot {
    background: rgba(105, 99, 255, .3);
    opacity: 7;
    color: inherit;
    border: none;
    padding: 0!important;
    font: inherit
}

.owl-nav button:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2098d1;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

.carousel-item.active {
    display: block
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next,
.carousel-item-prev {
    display: block
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
    transform: translateX(100%)
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: 0s .6s opacity
}

@media (prefers-reduced-motion:reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-control-next,
    .carousel-control-prev {
        transition: none
    }
}

.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-next:focus,
.carousel-control-next:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

.carousel-indicators .active {
    opacity: 1
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators li {
        transition: none
    }
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-image: none;
    display: block;
    width: auto;
    height: auto
}

.carousel-control-next-icon svg,
.carousel-control-prev-icon svg {
    width: 48px;
    height: 48px
}

.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%
}

.dashboard-carousel .btn-icons {
    display: inline-block;
    background: #eff2f6!important;
    background: no-repeat 50%/100% 100%;
    text-align: center;
    line-height: 15px;
    margin: 0 auto;
    align-items: center;
    padding: .375rem .75rem;
    min-height: inherit
}

.dashboard-carousel .btn-icons i {
    font-size: 15px;
    margin: 0 auto;
    text-align: center
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #031b4e;
    text-align: left;
    background-color: #ffffff
}

[tabindex="-1"]:focus {
    outline: 0!important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #078586;
    text-decoration: none;
    background-color: transparent
}

a:hover {
    color: #03443f;
    text-decoration: none
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
    outline: 0
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 0rem
}

figure {
    margin: 0 0 1rem
}

img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%
}

svg {
    overflow: hidden;
    vertical-align: middle
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #737f9e;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button {
    text-transform: none
}

select {
    text-transform: none;
    word-wrap: normal
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none!important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2
}

.h1,
h1 {
    font-size: 2.1875rem
}

.h2,
h2 {
    font-size: 1.75rem
}

.h3,
h3 {
    font-size: 1.53125rem
}

.h4,
h4 {
    font-size: 1.3125rem
}

.h5,
h5 {
    font-size: 1.09375rem
}

.h6,
h6 {
    font-size: .875rem
}

.lead {
    font-size: 1.09375rem;
    font-weight: 300
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1)
}

.small,
small {
    font-size: 80%;
    font-weight: 400
}

.mark,
mark {
    padding: .2em;
    background-color: #fcf8e3
}

.list-inline,
.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.09375rem
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #737f9e
}

.blockquote-footer::before {
    content: "\2014\00A0"
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: 90%;
    color: #737f9e
}

code {
    font-size: 87.5%;
    color: #f10075;
    word-break: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #242f48;
    border-radius: .2rem
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700
}

pre {
    display: block;
    font-size: 87.5%;
    color: #242f48
}

pre code {
    font-size: inherit;
    color: #2d91fb;
    word-break: normal
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container,
.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px
    }
    .responsive-logo {
        display: none
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1200px
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -.75rem;
    margin-left: -.75rem
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: .75rem;
    padding-left: .75rem
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
}

.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%
}

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%
}

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%
}

.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    order: -1
}

.order-last {
    order: 13
}

.order-0 {
    order: 0
}

.order-1 {
    order: 1
}

.order-2 {
    order: 2
}

.order-3 {
    order: 3
}

.order-4 {
    order: 4
}

.order-5 {
    order: 5
}

.order-6 {
    order: 6
}

.order-7 {
    order: 7
}

.order-8 {
    order: 8
}

.order-9 {
    order: 9
}

.order-10 {
    order: 10
}

.order-11 {
    order: 11
}

.order-12 {
    order: 12
}

.offset-1 {
    margin-left: 8.33333%
}

.offset-2 {
    margin-left: 16.66667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333%
}

.offset-5 {
    margin-left: 41.66667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333%
}

.offset-8 {
    margin-left: 66.66667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333%
}

.offset-11 {
    margin-left: 91.66667%
}

@media (min-width:576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }
    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }
    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }
    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }
    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }
    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }
    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-sm-first {
        order: -1
    }
    .order-sm-last {
        order: 13
    }
    .order-sm-0 {
        order: 0
    }
    .order-sm-1 {
        order: 1
    }
    .order-sm-2 {
        order: 2
    }
    .order-sm-3 {
        order: 3
    }
    .order-sm-4 {
        order: 4
    }
    .order-sm-5 {
        order: 5
    }
    .order-sm-6 {
        order: 6
    }
    .order-sm-7 {
        order: 7
    }
    .order-sm-8 {
        order: 8
    }
    .order-sm-9 {
        order: 9
    }
    .order-sm-10 {
        order: 10
    }
    .order-sm-11 {
        order: 11
    }
    .order-sm-12 {
        order: 12
    }
    .offset-sm-0 {
        margin-left: 0
    }
    .offset-sm-1 {
        margin-left: 8.33333%
    }
    .offset-sm-2 {
        margin-left: 16.66667%
    }
    .offset-sm-3 {
        margin-left: 25%
    }
    .offset-sm-4 {
        margin-left: 33.33333%
    }
    .offset-sm-5 {
        margin-left: 41.66667%
    }
    .offset-sm-6 {
        margin-left: 50%
    }
    .offset-sm-7 {
        margin-left: 58.33333%
    }
    .offset-sm-8 {
        margin-left: 66.66667%
    }
    .offset-sm-9 {
        margin-left: 75%
    }
    .offset-sm-10 {
        margin-left: 83.33333%
    }
    .offset-sm-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }
    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }
    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }
    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }
    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }
    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }
    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }
    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-md-first {
        order: -1
    }
    .order-md-last {
        order: 13
    }
    .order-md-0 {
        order: 0
    }
    .order-md-1 {
        order: 1
    }
    .order-md-2 {
        order: 2
    }
    .order-md-3 {
        order: 3
    }
    .order-md-4 {
        order: 4
    }
    .order-md-5 {
        order: 5
    }
    .order-md-6 {
        order: 6
    }
    .order-md-7 {
        order: 7
    }
    .order-md-8 {
        order: 8
    }
    .order-md-9 {
        order: 9
    }
    .order-md-10 {
        order: 10
    }
    .order-md-11 {
        order: 11
    }
    .order-md-12 {
        order: 12
    }
    .offset-md-0 {
        margin-left: 0
    }
    .offset-md-1 {
        margin-left: 8.33333%
    }
    .offset-md-2 {
        margin-left: 16.66667%
    }
    .offset-md-3 {
        margin-left: 25%
    }
    .offset-md-4 {
        margin-left: 33.33333%
    }
    .offset-md-5 {
        margin-left: 41.66667%
    }
    .offset-md-6 {
        margin-left: 50%
    }
    .offset-md-7 {
        margin-left: 58.33333%
    }
    .offset-md-8 {
        margin-left: 66.66667%
    }
    .offset-md-9 {
        margin-left: 75%
    }
    .offset-md-10 {
        margin-left: 83.33333%
    }
    .offset-md-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }
    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }
    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }
    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }
    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }
    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }
    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-lg-first {
        order: -1
    }
    .order-lg-last {
        order: 13
    }
    .order-lg-0 {
        order: 0
    }
    .order-lg-1 {
        order: 1
    }
    .order-lg-2 {
        order: 2
    }
    .order-lg-3 {
        order: 3
    }
    .order-lg-4 {
        order: 4
    }
    .order-lg-5 {
        order: 5
    }
    .order-lg-6 {
        order: 6
    }
    .order-lg-7 {
        order: 7
    }
    .order-lg-8 {
        order: 8
    }
    .order-lg-9 {
        order: 9
    }
    .order-lg-10 {
        order: 10
    }
    .order-lg-11 {
        order: 11
    }
    .order-lg-12 {
        order: 12
    }
    .offset-lg-0 {
        margin-left: 0
    }
    .offset-lg-1 {
        margin-left: 8.33333%
    }
    .offset-lg-2 {
        margin-left: 16.66667%
    }
    .offset-lg-3 {
        margin-left: 25%
    }
    .offset-lg-4 {
        margin-left: 33.33333%
    }
    .offset-lg-5 {
        margin-left: 41.66667%
    }
    .offset-lg-6 {
        margin-left: 50%
    }
    .offset-lg-7 {
        margin-left: 58.33333%
    }
    .offset-lg-8 {
        margin-left: 66.66667%
    }
    .offset-lg-9 {
        margin-left: 75%
    }
    .offset-lg-10 {
        margin-left: 83.33333%
    }
    .offset-lg-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }
    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }
    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }
    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }
    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }
    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }
    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }
    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-xl-first {
        order: -1
    }
    .order-xl-last {
        order: 13
    }
    .order-xl-0 {
        order: 0
    }
    .order-xl-1 {
        order: 1
    }
    .order-xl-2 {
        order: 2
    }
    .order-xl-3 {
        order: 3
    }
    .order-xl-4 {
        order: 4
    }
    .order-xl-5 {
        order: 5
    }
    .order-xl-6 {
        order: 6
    }
    .order-xl-7 {
        order: 7
    }
    .order-xl-8 {
        order: 8
    }
    .order-xl-9 {
        order: 9
    }
    .order-xl-10 {
        order: 10
    }
    .order-xl-11 {
        order: 11
    }
    .order-xl-12 {
        order: 12
    }
    .offset-xl-0 {
        margin-left: 0
    }
    .offset-xl-1 {
        margin-left: 8.33333%
    }
    .offset-xl-2 {
        margin-left: 16.66667%
    }
    .offset-xl-3 {
        margin-left: 25%
    }
    .offset-xl-4 {
        margin-left: 33.33333%
    }
    .offset-xl-5 {
        margin-left: 41.66667%
    }
    .offset-xl-6 {
        margin-left: 50%
    }
    .offset-xl-7 {
        margin-left: 58.33333%
    }
    .offset-xl-8 {
        margin-left: 66.66667%
    }
    .offset-xl-9 {
        margin-left: 75%
    }
    .offset-xl-10 {
        margin-left: 83.33333%
    }
    .offset-xl-11 {
        margin-left: 91.66667%
    }
}

.align-baseline {
    vertical-align: baseline!important
}

.align-top {
    vertical-align: top!important
}

.align-middle {
    vertical-align: middle!important
}

.align-bottom {
    vertical-align: bottom!important
}

.align-text-bottom {
    vertical-align: text-bottom!important
}

.align-text-top {
    vertical-align: text-top!important
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.d-none {
    display: none!important
}

.d-inline {
    display: inline!important
}

.d-inline-block {
    display: inline-block!important
}

.d-block {
    display: block!important
}

.d-table {
    display: table!important
}

.d-table-row {
    display: table-row!important
}

.d-table-cell {
    display: table-cell!important
}

.d-flex {
    display: flex!important
}

.d-inline-flex {
    display: inline-flex!important
}

@media (min-width:576px) {
    .d-sm-none {
        display: none!important
    }
    .d-sm-inline {
        display: inline!important
    }
    .d-sm-inline-block {
        display: inline-block!important
    }
    .d-sm-block {
        display: block!important
    }
    .d-sm-table {
        display: table!important
    }
    .d-sm-table-row {
        display: table-row!important
    }
    .d-sm-table-cell {
        display: table-cell!important
    }
    .d-sm-flex {
        display: flex!important
    }
    .d-sm-inline-flex {
        display: inline-flex!important
    }
}

@media (min-width:768px) {
    .d-md-none {
        display: none!important
    }
    .d-md-inline {
        display: inline!important
    }
    .d-md-inline-block {
        display: inline-block!important
    }
    .d-md-block {
        display: block!important
    }
    .d-md-table {
        display: table!important
    }
    .d-md-table-row {
        display: table-row!important
    }
    .d-md-table-cell {
        display: table-cell!important
    }
    .d-md-flex {
        display: flex!important
    }
    .d-md-inline-flex {
        display: inline-flex!important
    }
}

@media (min-width:992px) {
    .d-lg-none {
        display: none!important
    }
    .d-lg-inline {
        display: inline!important
    }
    .d-lg-inline-block {
        display: inline-block!important
    }
    .d-lg-block {
        display: block!important
    }
    .d-lg-table {
        display: table!important
    }
    .d-lg-table-row {
        display: table-row!important
    }
    .d-lg-table-cell {
        display: table-cell!important
    }
    .d-lg-flex {
        display: flex!important
    }
    .d-lg-inline-flex {
        display: inline-flex!important
    }
}

@media (min-width:1200px) {
    .d-xl-none {
        display: none!important
    }
    .d-xl-inline {
        display: inline!important
    }
    .d-xl-inline-block {
        display: inline-block!important
    }
    .d-xl-block {
        display: block!important
    }
    .d-xl-table {
        display: table!important
    }
    .d-xl-table-row {
        display: table-row!important
    }
    .d-xl-table-cell {
        display: table-cell!important
    }
    .d-xl-flex {
        display: flex!important
    }
    .d-xl-inline-flex {
        display: inline-flex!important
    }
}

@media print {
    .d-print-none {
        display: none!important
    }
    .d-print-inline {
        display: inline!important
    }
    .d-print-inline-block {
        display: inline-block!important
    }
    .d-print-block {
        display: block!important
    }
    .d-print-table {
        display: table!important
    }
    .d-print-table-row {
        display: table-row!important
    }
    .d-print-table-cell {
        display: table-cell!important
    }
    .d-print-flex {
        display: flex!important
    }
    .d-print-inline-flex {
        display: inline-flex!important
    }
}

.flex-row {
    flex-direction: row!important
}

.flex-column {
    flex-direction: column!important
}

.flex-row-reverse {
    flex-direction: row-reverse!important
}

.flex-column-reverse {
    flex-direction: column-reverse!important
}

.flex-wrap {
    flex-wrap: wrap!important
}

.flex-nowrap {
    flex-wrap: nowrap!important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse!important
}

.flex-fill {
    flex: 1 1 auto!important
}

.flex-grow-0 {
    flex-grow: 0!important
}

.flex-grow-1 {
    flex-grow: 1!important
}

.flex-shrink-0 {
    flex-shrink: 0!important
}

.flex-shrink-1 {
    flex-shrink: 1!important
}

.justify-content-start {
    justify-content: flex-start!important
}

.justify-content-end {
    justify-content: flex-end!important
}

.justify-content-center {
    justify-content: center!important
}

.justify-content-between {
    justify-content: space-between!important
}

.justify-content-around {
    justify-content: space-around!important
}

.align-items-start {
    align-items: flex-start!important
}

.align-items-end {
    align-items: flex-end!important
}

.align-items-center {
    align-items: center!important
}

.align-items-baseline {
    align-items: baseline!important
}

.align-items-stretch {
    align-items: stretch!important
}

.align-content-start {
    align-content: flex-start!important
}

.align-content-end {
    align-content: flex-end!important
}

.align-content-center {
    align-content: center!important
}

.align-content-between {
    align-content: space-between!important
}

.align-content-around {
    align-content: space-around!important
}

.align-content-stretch {
    align-content: stretch!important
}

.align-self-auto {
    align-self: auto!important
}

.align-self-start {
    align-self: flex-start!important
}

.align-self-end {
    align-self: flex-end!important
}

.align-self-center {
    align-self: center!important
}

.align-self-baseline {
    align-self: baseline!important
}

.align-self-stretch {
    align-self: stretch!important
}

@media (min-width:576px) {
    .flex-sm-row {
        flex-direction: row!important
    }
    .flex-sm-column {
        flex-direction: column!important
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-sm-wrap {
        flex-wrap: wrap!important
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .flex-sm-fill {
        flex: 1 1 auto!important
    }
    .flex-sm-grow-0 {
        flex-grow: 0!important
    }
    .flex-sm-grow-1 {
        flex-grow: 1!important
    }
    .flex-sm-shrink-0 {
        flex-shrink: 0!important
    }
    .flex-sm-shrink-1 {
        flex-shrink: 1!important
    }
    .justify-content-sm-start {
        justify-content: flex-start!important
    }
    .justify-content-sm-end {
        justify-content: flex-end!important
    }
    .justify-content-sm-center {
        justify-content: center!important
    }
    .justify-content-sm-between {
        justify-content: space-between!important
    }
    .justify-content-sm-around {
        justify-content: space-around!important
    }
    .align-items-sm-start {
        align-items: flex-start!important
    }
    .align-items-sm-end {
        align-items: flex-end!important
    }
    .align-items-sm-center {
        align-items: center!important
    }
    .align-items-sm-baseline {
        align-items: baseline!important
    }
    .align-items-sm-stretch {
        align-items: stretch!important
    }
    .align-content-sm-start {
        align-content: flex-start!important
    }
    .align-content-sm-end {
        align-content: flex-end!important
    }
    .align-content-sm-center {
        align-content: center!important
    }
    .align-content-sm-between {
        align-content: space-between!important
    }
    .align-content-sm-around {
        align-content: space-around!important
    }
    .align-content-sm-stretch {
        align-content: stretch!important
    }
    .align-self-sm-auto {
        align-self: auto!important
    }
    .align-self-sm-start {
        align-self: flex-start!important
    }
    .align-self-sm-end {
        align-self: flex-end!important
    }
    .align-self-sm-center {
        align-self: center!important
    }
    .align-self-sm-baseline {
        align-self: baseline!important
    }
    .align-self-sm-stretch {
        align-self: stretch!important
    }
}

@media (min-width:768px) {
    .flex-md-row {
        flex-direction: row!important
    }
    .flex-md-column {
        flex-direction: column!important
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-md-wrap {
        flex-wrap: wrap!important
    }
    .flex-md-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .flex-md-fill {
        flex: 1 1 auto!important
    }
    .flex-md-grow-0 {
        flex-grow: 0!important
    }
    .flex-md-grow-1 {
        flex-grow: 1!important
    }
    .flex-md-shrink-0 {
        flex-shrink: 0!important
    }
    .flex-md-shrink-1 {
        flex-shrink: 1!important
    }
    .justify-content-md-start {
        justify-content: flex-start!important
    }
    .justify-content-md-end {
        justify-content: flex-end!important
    }
    .justify-content-md-center {
        justify-content: center!important
    }
    .justify-content-md-between {
        justify-content: space-between!important
    }
    .justify-content-md-around {
        justify-content: space-around!important
    }
    .align-items-md-start {
        align-items: flex-start!important
    }
    .align-items-md-end {
        align-items: flex-end!important
    }
    .align-items-md-center {
        align-items: center!important
    }
    .align-items-md-baseline {
        align-items: baseline!important
    }
    .align-items-md-stretch {
        align-items: stretch!important
    }
    .align-content-md-start {
        align-content: flex-start!important
    }
    .align-content-md-end {
        align-content: flex-end!important
    }
    .align-content-md-center {
        align-content: center!important
    }
    .align-content-md-between {
        align-content: space-between!important
    }
    .align-content-md-around {
        align-content: space-around!important
    }
    .align-content-md-stretch {
        align-content: stretch!important
    }
    .align-self-md-auto {
        align-self: auto!important
    }
    .align-self-md-start {
        align-self: flex-start!important
    }
    .align-self-md-end {
        align-self: flex-end!important
    }
    .align-self-md-center {
        align-self: center!important
    }
    .align-self-md-baseline {
        align-self: baseline!important
    }
    .align-self-md-stretch {
        align-self: stretch!important
    }
}

@media (min-width:992px) {
    .flex-lg-row {
        flex-direction: row!important
    }
    .flex-lg-column {
        flex-direction: column!important
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-lg-wrap {
        flex-wrap: wrap!important
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .flex-lg-fill {
        flex: 1 1 auto!important
    }
    .flex-lg-grow-0 {
        flex-grow: 0!important
    }
    .flex-lg-grow-1 {
        flex-grow: 1!important
    }
    .flex-lg-shrink-0 {
        flex-shrink: 0!important
    }
    .flex-lg-shrink-1 {
        flex-shrink: 1!important
    }
    .justify-content-lg-start {
        justify-content: flex-start!important
    }
    .justify-content-lg-end {
        justify-content: flex-end!important
    }
    .justify-content-lg-center {
        justify-content: center!important
    }
    .justify-content-lg-between {
        justify-content: space-between!important
    }
    .justify-content-lg-around {
        justify-content: space-around!important
    }
    .align-items-lg-start {
        align-items: flex-start!important
    }
    .align-items-lg-end {
        align-items: flex-end!important
    }
    .align-items-lg-center {
        align-items: center!important
    }
    .align-items-lg-baseline {
        align-items: baseline!important
    }
    .align-items-lg-stretch {
        align-items: stretch!important
    }
    .align-content-lg-start {
        align-content: flex-start!important
    }
    .align-content-lg-end {
        align-content: flex-end!important
    }
    .align-content-lg-center {
        align-content: center!important
    }
    .align-content-lg-between {
        align-content: space-between!important
    }
    .align-content-lg-around {
        align-content: space-around!important
    }
    .align-content-lg-stretch {
        align-content: stretch!important
    }
    .align-self-lg-auto {
        align-self: auto!important
    }
    .align-self-lg-start {
        align-self: flex-start!important
    }
    .align-self-lg-end {
        align-self: flex-end!important
    }
    .align-self-lg-center {
        align-self: center!important
    }
    .align-self-lg-baseline {
        align-self: baseline!important
    }
    .align-self-lg-stretch {
        align-self: stretch!important
    }
}

@media (min-width:1200px) {
    .flex-xl-row {
        flex-direction: row!important
    }
    .flex-xl-column {
        flex-direction: column!important
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-xl-wrap {
        flex-wrap: wrap!important
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .flex-xl-fill {
        flex: 1 1 auto!important
    }
    .flex-xl-grow-0 {
        flex-grow: 0!important
    }
    .flex-xl-grow-1 {
        flex-grow: 1!important
    }
    .flex-xl-shrink-0 {
        flex-shrink: 0!important
    }
    .flex-xl-shrink-1 {
        flex-shrink: 1!important
    }
    .justify-content-xl-start {
        justify-content: flex-start!important
    }
    .justify-content-xl-end {
        justify-content: flex-end!important
    }
    .justify-content-xl-center {
        justify-content: center!important
    }
    .justify-content-xl-between {
        justify-content: space-between!important
    }
    .justify-content-xl-around {
        justify-content: space-around!important
    }
    .align-items-xl-start {
        align-items: flex-start!important
    }
    .align-items-xl-end {
        align-items: flex-end!important
    }
    .align-items-xl-center {
        align-items: center!important
    }
    .align-items-xl-baseline {
        align-items: baseline!important
    }
    .align-items-xl-stretch {
        align-items: stretch!important
    }
    .align-content-xl-start {
        align-content: flex-start!important
    }
    .align-content-xl-end {
        align-content: flex-end!important
    }
    .align-content-xl-center {
        align-content: center!important
    }
    .align-content-xl-between {
        align-content: space-between!important
    }
    .align-content-xl-around {
        align-content: space-around!important
    }
    .align-content-xl-stretch {
        align-content: stretch!important
    }
    .align-self-xl-auto {
        align-self: auto!important
    }
    .align-self-xl-start {
        align-self: flex-start!important
    }
    .align-self-xl-end {
        align-self: flex-end!important
    }
    .align-self-xl-center {
        align-self: center!important
    }
    .align-self-xl-baseline {
        align-self: baseline!important
    }
    .align-self-xl-stretch {
        align-self: stretch!important
    }
}

.float-left {
    float: left!important
}

.float-right {
    float: right!important
}

.float-none {
    float: none!important
}

@media (min-width:576px) {
    .float-sm-left {
        float: left!important
    }
    .float-sm-right {
        float: right!important
    }
    .float-sm-none {
        float: none!important
    }
}

@media (min-width:768px) {
    .float-md-left {
        float: left!important
    }
    .float-md-right {
        float: right!important
    }
    .float-md-none {
        float: none!important
    }
}

@media (min-width:992px) {
    .float-lg-left {
        float: left!important
    }
    .float-lg-right {
        float: right!important
    }
    .float-lg-none {
        float: none!important
    }
}

@media (min-width:1200px) {
    .float-xl-left {
        float: left!important
    }
    .float-xl-right {
        float: right!important
    }
    .float-xl-none {
        float: none!important
    }
}

.heading-primary {
    background-color: #078586;
    color: #fff;
    padding: 5px
}

.heading-inverse {
    background-color: #333;
    color: #fff;
    padding: 5px
}

.heading-success {
    background-color: #21b18a;
    color: #fff;
    padding: 5px
}

.heading-info {
    background-color: #00b9ff;
    color: #fff;
    padding: 5px
}

.heading-warning {
    background-color: #fbbc0b;
    color: #fff;
    padding: 5px
}

.heading-danger {
    background-color: #ee335e;
    color: #fff;
    padding: 5px
}

.header-layout.horizontal-main {
    width: auto;
    position: inherit
}

.header-layout .horizontalMenu-list .side-menu__icon {
    color: #a8b1c7;
    fill: transparent;
    margin-right: 7px
}

.horizontalMenucontainer .layout-pin.side-header.sticky-pin {
    position: fixed!important
}

@media (max-width:991px) {
    .animated-arrow.hor-header-toggle {
        position: inherit;
        margin: 0 0 0 15px
    }
    .animated-arrow.hor-header-toggle span {
        background: #a8b1c7
    }
    .animated-arrow.hor-header-toggle span:after,
    .animated-arrow.hor-header-toggle span:before {
        background: #a8b1c7
    }
}

@media (min-width:992px) and (max-width:1366px) {
    .layout-pin .main-header-left .form-control {
        width: 165px
    }
}

@media (min-width:767px) and (max-width:992px) {
    .active .header-layout .horizontalMenu>.horizontalMenu-list {
        margin-right: 0;
        margin-left: auto
    }
    .header-layout .horizontalMenu>.horizontalMenu-list {
        margin-right: -240px;
        margin-left: auto
    }
    .header-layout .horizontal-overlapbg {
        left: 0;
        right: inherit
    }
}

@media (max-width:568px) {
    .main-header-message {
        display: none
    }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: .875rem;
    color: #031b4e;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(138, 153, 191, .125);
    border-radius: 3px
}

.dropdown-menu-left {
    right: auto;
    left: 0
}

.dropdown-menu-right {
    right: 0;
    left: auto
}

@media (min-width:576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0
    }
    .dropdown-menu-sm-right {
        right: 0;
        left: auto
    }
}

@media (min-width:768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0
    }
    .dropdown-menu-md-right {
        right: 0;
        left: auto
    }
}

@media (min-width:992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0
    }
    .dropdown-menu-lg-right {
        right: 0;
        left: auto
    }
}

@media (min-width:1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0
    }
    .dropdown-menu-xl-right {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .125rem
}

tr:has(> td.row-total), tr:has(> td.row-summary){
	font-weight: bold;
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: .125rem
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-toggle::after {
    vertical-align: 0
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: .125rem
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    display: none
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top] {
    right: auto;
    bottom: auto
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #dde2ef
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #242f48;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #141c2b;
    text-decoration: none;
    background-color: #ecf0fa
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #078586
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #737f9e;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: .5rem 1.5rem;
    margin-bottom: 0;
    font-size: .76562rem;
    color: #737f9e;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: .25rem 1.5rem;
    color: #242f48
}

.dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem
}

.dropdown-toggle-split::after {
    margin-left: 0
}

.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
    margin-left: 0
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0
}

.dropdown-menu {
    padding: 0;
    border-width: 1px;
    border-radius: 0
}

.dropdown-item {
    padding: 8px 15px;
    font-size: 13px
}

.dropdown-header {
    padding-left: 15px;
    padding-right: 15px
}

@media (min-width:768px) {
    .dropdown.d-cart .dropdown-menu,
    .dropdown.message .dropdown-menu {
        width: 20.5rem!important
    }
}

.user-lock .dropdown {
    position: absolute;
    right: 20px
}

.main-dropdown-form-demo .static-dropdown {
    padding: 20px;
    background-color: #dde2ef;
    display: inline-flex;
    justify-content: center
}

.main-dropdown-form-demo .static-dropdown .dropdown-menu {
    display: block;
    position: static;
    float: none
}

.main-dropdown-form-demo .dropdown-menu {
    padding: 20px
}

.main-dropdown-form-demo .dropdown-title {
    font-size: 20px;
    font-weight: 700;
    color: #242f48;
    margin-bottom: 5px
}

@media (min-width:576px) {
    .main-dropdown-form-demo .static-dropdown {
        padding: 40px
    }
}

@media (min-width:576px) {
    .main-dropdown-form-demo .dropdown-menu {
        width: 300px;
        padding: 30px 25px
    }
}

.form-control {
    height: 38px;
    border-radius: 0
}

.form-control:focus {
    border-color: #949eb7;
    box-shadow: none
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

.form-label {
    display: block;
    margin-bottom: 5px;
    color: #4d5875
}

.form-control {
    display: block;
    width: 100%;
    height: 40px;
    padding: .375rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4d5875;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 3px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:focus {
    color: #4d5875;
    background-color: #fff;
    border-color: #b3c6ff;
    outline: 0;
    box-shadow: none
}

.form-control::placeholder {
    color: #737f9e;
    opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #dde2ef;
    opacity: 1
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

select.form-control:focus::-ms-value {
    color: #4d5875;
    background-color: #fff
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    font-size: 1.09375rem;
    line-height: 1.5
}

.col-form-label-sm {
    padding-top: calc(.25rem + 1px);
    padding-bottom: calc(.25rem + 1px);
    font-size: .76562rem;
    line-height: 1.5
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: .375rem;
    padding-bottom: .375rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #031b4e;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .76562rem;
    line-height: 1.5;
    border-radius: .2rem
}

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: .3rem
}

select.form-control[multiple],
select.form-control[size] {
    height: auto
}

textarea.form-control {
    height: auto
}

.form-group {
    margin-bottom: 1rem
}

.form-text {
    display: block;
    margin-top: .25rem
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px
}

.form-row>.col,
.form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
    text-align: center;
}

.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label {
    color: #737f9e
}

.form-check-label {
    margin-bottom: 0
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #21b18a
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .76562rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(59, 176, 1, .9);
    border-radius: 3px
}

.form-control.is-valid,
.was-validated .form-control:valid,
input.form-control.parsley-success,
textarea.form-control.parsley-success {
    border-color: #21b18a;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300cccc ' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center right calc(.375em + .1875rem);
    background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus,
input.form-control.parsley-success:focus,
textarea.form-control.parsley-success:focus {
    border-color: #21b18a;
    box-shadow: 0 0 0 .2rem rgba(59, 176, 1, .25)
}

.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip {
    display: block
}

.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
input.form-control.parsley-success~.valid-feedback,
input.form-control.parsley-success~.valid-tooltip,
textarea.form-control.parsley-success~.valid-feedback,
textarea.form-control.parsley-success~.valid-tooltip {
    display: block
}

.was-validated textarea.form-control:valid {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

textarea.form-control.is-valid,
textarea.form-control.parsley-success {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.custom-select.is-valid,
.was-validated .custom-select:valid,
input.custom-select.parsley-success,
textarea.custom-select.parsley-success {
    border-color: #21b18a;
    padding-right: calc((1em + .75rem) * 3 / 4 + 1.75rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300cccc ' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem)
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus,
input.custom-select.parsley-success:focus,
textarea.custom-select.parsley-success:focus {
    border-color: #21b18a;
    box-shadow: 0 0 0 .2rem rgba(59, 176, 1, .25)
}

.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip {
    display: block
}

.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip,
input.custom-select.parsley-success~.valid-feedback,
input.custom-select.parsley-success~.valid-tooltip,
textarea.custom-select.parsley-success~.valid-feedback,
textarea.custom-select.parsley-success~.valid-tooltip {
    display: block
}

.was-validated .form-control-file:valid~.valid-feedback,
.was-validated .form-control-file:valid~.valid-tooltip {
    display: block
}

.form-control-file.is-valid~.valid-feedback,
.form-control-file.is-valid~.valid-tooltip,
input.form-control-file.parsley-success~.valid-feedback,
input.form-control-file.parsley-success~.valid-tooltip,
textarea.form-control-file.parsley-success~.valid-feedback,
textarea.form-control-file.parsley-success~.valid-tooltip {
    display: block
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label,
input.form-check-input.parsley-success~.form-check-label,
textarea.form-check-input.parsley-success~.form-check-label {
    color: #21b18a
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
    display: block
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
input.form-check-input.parsley-success~.valid-feedback,
input.form-check-input.parsley-success~.valid-tooltip,
textarea.form-check-input.parsley-success~.valid-feedback,
textarea.form-check-input.parsley-success~.valid-tooltip {
    display: block
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label,
input.custom-control-input.parsley-success~.custom-control-label,
textarea.custom-control-input.parsley-success~.custom-control-label {
    color: #21b18a
}

.custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before,
input.custom-control-input.parsley-success~.custom-control-label::before,
textarea.custom-control-input.parsley-success~.custom-control-label::before {
    border-color: #21b18a
}

.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip {
    display: block
}

.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip,
input.custom-control-input.parsley-success~.valid-feedback,
input.custom-control-input.parsley-success~.valid-tooltip,
textarea.custom-control-input.parsley-success~.valid-feedback,
textarea.custom-control-input.parsley-success~.valid-tooltip {
    display: block
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
input.custom-control-input.parsley-success:checked~.custom-control-label::before,
textarea.custom-control-input.parsley-success:checked~.custom-control-label::before {
    border-color: #4ce301;
    background-color: #4ce301
}

.custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
input.custom-control-input.parsley-success:focus~.custom-control-label::before,
textarea.custom-control-input.parsley-success:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(59, 176, 1, .25)
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-file-input:valid~.custom-file-label,
input.custom-control-input.parsley-success:focus:not(:checked)~.custom-control-label::before,
input.custom-file-input.parsley-success~.custom-file-label,
textarea.custom-control-input.parsley-success:focus:not(:checked)~.custom-control-label::before,
textarea.custom-file-input.parsley-success~.custom-file-label {
    border-color: #21b18a
}

.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip {
    display: block
}

.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip,
input.custom-file-input.parsley-success~.valid-feedback,
input.custom-file-input.parsley-success~.valid-tooltip,
textarea.custom-file-input.parsley-success~.valid-feedback,
textarea.custom-file-input.parsley-success~.valid-tooltip {
    display: block
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label,
input.custom-file-input.parsley-success:focus~.custom-file-label,
textarea.custom-file-input.parsley-success:focus~.custom-file-label {
    border-color: #21b18a;
    box-shadow: 0 0 0 .2rem rgba(59, 176, 1, .25)
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #ee335e
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .76562rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220, 53, 69, .9);
    border-radius: 3px
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
input.form-control.parsley-error,
textarea.form-control.parsley-error {
    border-color: #ee335e;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa5c7c' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fa5c7c' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(.375em + .1875rem);
    background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus,
input.form-control.parsley-error:focus,
textarea.form-control.parsley-error:focus {
    border-color: #ee335e;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
input.form-control.parsley-error~.invalid-feedback,
input.form-control.parsley-error~.invalid-tooltip,
textarea.form-control.parsley-error~.invalid-feedback,
textarea.form-control.parsley-error~.invalid-tooltip {
    display: block
}

.was-validated textarea.form-control:invalid {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

textarea.form-control.is-invalid,
textarea.form-control.parsley-error {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid,
input.custom-select.parsley-error,
textarea.custom-select.parsley-error {
    border-color: #ee335e;
    padding-right: calc((1em + .75rem) * 3 / 4 + 1.75rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa5c7c' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fa5c7c' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem)
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
input.custom-select.parsley-error:focus,
textarea.custom-select.parsley-error:focus {
    border-color: #ee335e;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip {
    display: block
}

.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip,
input.custom-select.parsley-error~.invalid-feedback,
input.custom-select.parsley-error~.invalid-tooltip,
textarea.custom-select.parsley-error~.invalid-feedback,
textarea.custom-select.parsley-error~.invalid-tooltip {
    display: block
}

.was-validated .form-control-file:invalid~.invalid-feedback,
.was-validated .form-control-file:invalid~.invalid-tooltip {
    display: block
}

.form-control-file.is-invalid~.invalid-feedback,
.form-control-file.is-invalid~.invalid-tooltip,
input.form-control-file.parsley-error~.invalid-feedback,
input.form-control-file.parsley-error~.invalid-tooltip,
textarea.form-control-file.parsley-error~.invalid-feedback,
textarea.form-control-file.parsley-error~.invalid-tooltip {
    display: block
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label,
input.form-check-input.parsley-error~.form-check-label,
textarea.form-check-input.parsley-error~.form-check-label {
    color: #ee335e
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
    display: block
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
input.form-check-input.parsley-error~.invalid-feedback,
input.form-check-input.parsley-error~.invalid-tooltip,
textarea.form-check-input.parsley-error~.invalid-feedback,
textarea.form-check-input.parsley-error~.invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label,
input.custom-control-input.parsley-error~.custom-control-label,
textarea.custom-control-input.parsley-error~.custom-control-label {
    color: #ee335e
}

.custom-control-input.is-invalid~.custom-control-label::before,
.was-validated .custom-control-input:invalid~.custom-control-label::before,
input.custom-control-input.parsley-error~.custom-control-label::before,
textarea.custom-control-input.parsley-error~.custom-control-label::before {
    border-color: #ee335e
}

.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip,
input.custom-control-input.parsley-error~.invalid-feedback,
input.custom-control-input.parsley-error~.invalid-tooltip,
textarea.custom-control-input.parsley-error~.invalid-feedback,
textarea.custom-control-input.parsley-error~.invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid:checked~.custom-control-label::before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
input.custom-control-input.parsley-error:checked~.custom-control-label::before,
textarea.custom-control-input.parsley-error:checked~.custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d
}

.custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
input.custom-control-input.parsley-error:focus~.custom-control-label::before,
textarea.custom-control-input.parsley-error:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before,
.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-file-input:invalid~.custom-file-label,
input.custom-control-input.parsley-error:focus:not(:checked)~.custom-control-label::before,
input.custom-file-input.parsley-error~.custom-file-label,
textarea.custom-control-input.parsley-error:focus:not(:checked)~.custom-control-label::before,
textarea.custom-file-input.parsley-error~.custom-file-label {
    border-color: #ee335e
}

.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip {
    display: block
}

.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip,
input.custom-file-input.parsley-error~.invalid-feedback,
input.custom-file-input.parsley-error~.invalid-tooltip,
textarea.custom-file-input.parsley-error~.invalid-feedback,
textarea.custom-file-input.parsley-error~.invalid-tooltip {
    display: block
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label,
input.custom-file-input.parsley-error:focus~.custom-file-label,
textarea.custom-file-input.parsley-error:focus~.custom-file-label {
    border-color: #ee335e;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center
}

.form-inline .form-check {
    width: 100%
}

@media (min-width:576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0
    }
    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0
    }
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }
    .form-inline .form-control-plaintext {
        display: inline-block
    }
    .form-inline .custom-select,
    .form-inline .input-group {
        width: auto
    }
    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0
    }
    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0
    }
    .form-inline .custom-control {
        align-items: center;
        justify-content: center
    }
    .form-inline .custom-control-label {
        margin-bottom: 0
    }
}

.main-form-group {
    padding: 12px 15px;
    border: 1px solid #e3e8f7
}

.main-form-group.focus {
    border-color: #b9c2d8;
    box-shadow: 0 0 0 2px rgba(91, 71, 251, .16)
}

.main-form-group .form-label {
    font-size: 13px;
    margin-bottom: 2px;
    font-weight: 400;
    color: #949eb7
}

.main-form-group .form-control {
    padding: 0;
    border-width: 0;
    height: 25px;
    color: #242f48;
    font-weight: 500
}

.main-form-group .form-control:focus {
    box-shadow: none
}

.formgroup-wrapper .form-control {
    height: 40px!important
}

.formgroup-wrapper .main-form-group .form-label {
    margin-bottom: 12px
}

.main-header-center .form-control {
    padding: 1rem 1.5rem
}

.main-header-center .form-control::placeholder {
    padding-left: 0rem;
    padding-right: 0rem
}

.browse-file.form-control {
    background-color: transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.file-browser .btn-default {
    background: #f1f4fb;
    border-top-right-radius: 3px!important;
    border-bottom-right-radius: 3px!important;
    padding: .56rem 15px;
    color: #68769a;
    border-radius: 0;
    border: 1px solid #e2e8f5
}

.row-sm {
    margin-left: -10px;
    margin-right: -10px
}

.row-sm>div {
    padding-left: 10px;
    padding-right: 10px
}

.row-xs {
    margin-left: -5px;
    margin-right: -5px
}

.row-xs>div {
    padding-left: 5px;
    padding-right: 5px
}

@media (min-width:576px) {
    .row-xs--sm {
        margin-left: -5px;
        margin-right: -5px
    }
    .row-xs--sm>div {
        padding-left: 5px;
        padding-right: 5px
    }
    .row-sm--sm {
        margin-left: -10px;
        margin-right: -10px
    }
    .row-sm--sm>div {
        padding-left: 10px;
        padding-right: 10px
    }
    .row--sm {
        margin-left: -15px;
        margin-right: -15px
    }
    .row--sm>div {
        padding-left: 15px;
        padding-right: 15px
    }
}

@media (min-width:768px) {
    .row-xs--md {
        margin-left: -5px;
        margin-right: -5px
    }
    .row-xs--md>div {
        padding-left: 5px;
        padding-right: 5px
    }
    .row-sm--md {
        margin-left: -10px;
        margin-right: -10px
    }
    .row-sm--md>div {
        padding-left: 10px;
        padding-right: 10px
    }
    .row--md {
        margin-left: -15px;
        margin-right: -15px
    }
    .row--md>div {
        padding-left: 15px;
        padding-right: 15px
    }
}

@media (min-width:992px) {
    .row-xs--lg {
        margin-left: -5px;
        margin-right: -5px
    }
    .row-xs--lg>div {
        padding-left: 5px;
        padding-right: 5px
    }
    .row-sm--lg {
        margin-left: -10px;
        margin-right: -10px
    }
    .row-sm--lg>div {
        padding-left: 10px;
        padding-right: 10px
    }
    .row--lg {
        margin-left: -15px;
        margin-right: -15px
    }
    .row--lg>div {
        padding-left: 15px;
        padding-right: 15px
    }
}

@media (min-width:1200px) {
    .row-xs--xl {
        margin-left: -5px;
        margin-right: -5px
    }
    .row-xs--xl>div {
        padding-left: 5px;
        padding-right: 5px
    }
    .row-sm--xl {
        margin-left: -10px;
        margin-right: -10px
    }
    .row-sm--xl>div {
        padding-left: 10px;
        padding-right: 10px
    }
    .row--xl {
        margin-left: -15px;
        margin-right: -15px
    }
    .row--xl>div {
        padding-left: 15px;
        padding-right: 15px
    }
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0
}

.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control {
    margin-left: -1px
}

.input-group>.form-control-plaintext+.custom-file,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.form-control {
    margin-left: -1px
}

.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control {
    margin-left: -1px
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control {
    margin-left: -1px
}

.input-group>.custom-select:focus,
.input-group>.form-control:focus {
    z-index: 3
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
    z-index: 3
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.custom-file {
    display: flex;
    align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-append,
.input-group-prepend {
    display: flex
}

.input-group-prepend .btn,
.input-group-prepend .sp-container button {
    position: relative;
    z-index: 2
}

.sp-container .input-group-prepend button {
    position: relative;
    z-index: 2
}

.input-group-append .btn,
.input-group-append .sp-container button {
    position: relative;
    z-index: 2
}

.sp-container .input-group-append button {
    position: relative;
    z-index: 2
}

.input-group-prepend .btn:focus,
.input-group-prepend .sp-container button:focus {
    z-index: 3
}

.sp-container .input-group-prepend button:focus {
    z-index: 3
}

.input-group-append .btn:focus,
.input-group-append .sp-container button:focus {
    z-index: 3
}

.sp-container .input-group-append button:focus {
    z-index: 3
}

.input-group-prepend .btn+.btn,
.input-group-prepend .sp-container button+.btn {
    margin-left: -1px
}

.input-group-prepend .sp-container .btn+button,
.input-group-prepend .sp-container button+button,
.sp-container .input-group-prepend .btn+button,
.sp-container .input-group-prepend button+.btn,
.sp-container .input-group-prepend button+button {
    margin-left: -1px
}

.input-group-prepend .btn+.input-group-text,
.input-group-prepend .sp-container button+.input-group-text {
    margin-left: -1px
}

.sp-container .input-group-prepend button+.input-group-text {
    margin-left: -1px
}

.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
    margin-left: -1px
}

.input-group-prepend .sp-container .input-group-text+button {
    margin-left: -1px
}

.sp-container .input-group-prepend .input-group-text+button {
    margin-left: -1px
}

.input-group-append .btn+.btn,
.input-group-append .sp-container button+.btn {
    margin-left: -1px
}

.input-group-append .sp-container .btn+button,
.input-group-append .sp-container button+button,
.sp-container .input-group-append .btn+button,
.sp-container .input-group-append button+.btn,
.sp-container .input-group-append button+button {
    margin-left: -1px
}

.input-group-append .btn+.input-group-text,
.input-group-append .sp-container button+.input-group-text {
    margin-left: -1px
}

.sp-container .input-group-append button+.input-group-text {
    margin-left: -1px
}

.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text {
    margin-left: -1px
}

.input-group-append .sp-container .input-group-text+button {
    margin-left: -1px
}

.sp-container .input-group-append .input-group-text+button {
    margin-left: -1px
}

.input-group-prepend {
    margin-right: -1px
}

.input-group-append {
    margin-left: -1px
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4d5875;
    text-align: center;
    white-space: nowrap;
    background-color: #dde2ef;
    border: 1px solid #e3e8f7;
    border-radius: 3px
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
    margin-top: 0
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
    height: calc(1.5em + 1rem + 2px)
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
    padding: .5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: .3rem
}

.input-group-lg>.input-group-append>.btn,
.sp-container .input-group-lg>.input-group-append>button,
.sp-container .input-group-lg>.input-group-prepend>button {
    padding: .5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: .3rem
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control:not(textarea) {
    height: calc(1.5em + .5rem + 2px)
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    padding: .25rem .5rem;
    font-size: .76562rem;
    line-height: 1.5;
    border-radius: .2rem
}

.input-group-sm>.input-group-append>.btn,
.sp-container .input-group-sm>.input-group-append>button,
.sp-container .input-group-sm>.input-group-prepend>button {
    padding: .25rem .5rem;
    font-size: .76562rem;
    line-height: 1.5;
    border-radius: .2rem
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 1.75rem
}

.input-group>.input-group-prepend>.btn,
.sp-container .input-group>.input-group-prepend>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.sp-container .input-group>.input-group-append:not(:last-child)>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:not(:last-child)>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.sp-container .input-group>.input-group-append:last-child>button:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.sp-container .input-group>.input-group-append>button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.sp-container .input-group>.input-group-prepend:not(:first-child)>button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.sp-container .input-group>.input-group-prepend:first-child>button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-append,
.input-group-prepend {
    border-radius: 0
}

.input-group-text {
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;
    border-radius: 0
}

.modal-backdrop {
    background-color: #0c1019
}

.modal-backdrop.show {
    opacity: .8
}

.modal-content {
    border-radius: 0;
    border-width: 0
}

.modal-content .close {
    font-size: 28px;
    padding: 0;
    margin: 0;
    line-height: .5
}

.modal-header {
    align-items: center;
    padding: 15px
}

.modal-header .modal-title {
    margin-bottom: 0
}

@media (min-width:576px) {
    .modal-header {
        padding: 15px 20px
    }
}

@media (min-width:992px) {
    .modal-header {
        padding: 20px
    }
}

@media (min-width:1200px) {
    .modal-header {
        padding: 20px 25px
    }
}

.modal-title {
    font-size: 18px;
    font-weight: 700;
    color: #242f48;
    line-height: 1
}

.modal-body {
    padding: 25px
}

.modal-open {
    overflow: hidden
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99992;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -50px)
}

.modal.show .modal-dialog {
    transform: none
}

@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
    flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""
}

.modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .89)
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .9
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #e3e8f7;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e3e8f7;
    border-bottom-right-radius: .3rem;
    border-bottom-left-radius: .3rem
}
.modal-end-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-top: 1px solid #e3e8f7;
    border-bottom-right-radius: .3rem;
    border-bottom-left-radius: .3rem
}

.modal-footer>:not(:first-child) {
    margin-left: .25rem
}

#modaldemo8 .modal-footer>:not(:first-child) {
    margin-right: 14px
}

.modal-footer>:not(:last-child) {
    margin-right: .25rem
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width:576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }
    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem)
    }
    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem)
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }
    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem)
    }
    .modal-sm {
        max-width: 300px
    }
}

@media (min-width:992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px
    }
}

@media (min-width:1200px) {
    .modal-xl {
        max-width: 1140px
    }
}

.modal.animated .modal-dialog {
    transform: translate(0, 0)
}

.modal.effect-scale .modal-dialog {
    transform: scale(.7);
    opacity: 0;
    transition: all .3s
}

.modal.effect-scale.show .modal-dialog {
    transform: scale(1);
    opacity: 1
}

.modal.effect-slide-in-right .modal-dialog {
    transform: translateX(20%);
    opacity: 0;
    transition: all .3s cubic-bezier(.25, .5, .5, .9)
}

.modal.effect-slide-in-right.show .modal-dialog {
    transform: translateX(0);
    opacity: 1
}

.modal.effect-slide-in-bottom .modal-dialog {
    transform: translateY(20%);
    opacity: 0;
    transition: all .3s
}

.modal.effect-slide-in-bottom.show .modal-dialog {
    transform: translateY(0);
    opacity: 1
}

.modal.effect-newspaper .modal-dialog {
    transform: scale(0) rotate(720deg);
    opacity: 0
}

.modal.effect-newspaper .modal-dialog,
.modal.effect-newspaper.show~.modal-backdrop {
    transition: all .5s
}

.modal.effect-newspaper.show .modal-dialog {
    transform: scale(1) rotate(0);
    opacity: 1
}

.modal.effect-fall {
    -webkit-perspective: 1300px;
    -moz-perspective: 1300px;
    perspective: 1300px
}

.modal.effect-fall .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateZ(600px) rotateX(20deg);
    opacity: 0
}

.modal.effect-fall.show .modal-dialog {
    transition: all .3s ease-in;
    transform: translateZ(0) rotateX(0);
    opacity: 1
}

.modal.effect-flip-horizontal {
    perspective: 1300px
}

.modal.effect-flip-horizontal .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateY(-70deg);
    transition: all .3s;
    opacity: 0
}

.modal.effect-flip-horizontal.show .modal-dialog {
    transform: rotateY(0);
    opacity: 1
}

.modal.effect-flip-vertical {
    perspective: 1300px
}

.modal.effect-flip-vertical .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateX(-70deg);
    transition: all .3s;
    opacity: 0
}

.modal.effect-flip-vertical.show .modal-dialog {
    transform: rotateX(0);
    opacity: 1
}

.modal.effect-super-scaled .modal-dialog {
    transform: scale(2);
    opacity: 0;
    transition: all .3s
}

.modal.effect-super-scaled.show .modal-dialog {
    transform: scale(1);
    opacity: 1
}

.modal.effect-sign {
    perspective: 1300px
}

.modal.effect-sign .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateX(-60deg);
    transform-origin: 50% 0;
    opacity: 0;
    transition: all .3s
}

.modal.effect-sign.show .modal-dialog {
    transform: rotateX(0);
    opacity: 1
}

.modal.effect-rotate-bottom {
    perspective: 1300px
}

.modal.effect-rotate-bottom .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateY(100%) rotateX(90deg);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all .3s ease-out
}

.modal.effect-rotate-bottom.show .modal-dialog {
    transform: translateY(0) rotateX(0);
    opacity: 1
}

.modal.effect-rotate-left {
    perspective: 1300px
}

.modal.effect-rotate-left .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateZ(100px) translateX(-30%) rotateY(90deg);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all .3s
}

.modal.effect-rotate-left.show .modal-dialog {
    transform: translateZ(0) translateX(0) rotateY(0);
    opacity: 1
}

.modal.effect-just-me .modal-dialog {
    transform: scale(.8);
    opacity: 0;
    transition: all .3s
}

.modal.effect-just-me .modal-content {
    background-color: #000
}

#modaldemo8 .modal-header {
    PADDING: 20px 25px
}

#modaldemo8 .modal-body {
    padding: 20px 25px
}

#modaldemo8 .modal-dialog {
    box-shadow: none
}

.modal.effect-just-me .close {
    text-shadow: none;
    color: #fff
}

.modal.effect-just-me .modal-header {
    background-color: transparent;
    border-bottom-color: rgba(255, 255, 255, .1);
    padding-left: 0;
    padding-right: 0
}

.modal.effect-just-me .modal-header h6 {
    color: #fff;
    font-weight: 500
}

.modal.effect-just-me .modal-body {
    color: rgba(255, 255, 255, .8);
    padding-left: 0;
    padding-right: 0
}

.modal.effect-just-me .modal-body h6 {
    color: #fff
}

.modal.effect-just-me .modal-footer {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    border-top-color: rgba(255, 255, 255, .2)
}

.modal.effect-just-me.show~.modal-backdrop {
    opacity: .96
}

.modal.effect-just-me.show .modal-dialog {
    transform: scale(1);
    opacity: 1
}

.modal-content-demo .modal-body h6 {
    color: #242f48;
    font-size: 15px;
    margin-bottom: 15px
}

.modal-content-demo .modal-body p {
    margin-bottom: 0
}

.basic-modal .modal-dialog {
    max-width: inherit;
    margin: 0
}

.basic-modal .modal-content {
    border: 1px solid #e3e8f7
}

.nav-link:active,
.nav-link:focus,
.nav-link:hover {
    outline: 0
}

.nav-pills .nav-link {
    color: #4d5875
}

.nav-pills .nav-link:focus,
.nav-pills .nav-link:hover {
    color: #242f48
}

.nav-pills .nav-link.active {
    border-radius: 3px;
    background-color: #078586
}

.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover {
    color: #fff
}

.nav-dark .nav-link {
    color: #949eb7
}

.nav-dark .nav-link:focus,
.nav-dark .nav-link:hover {
    color: #fff
}

.nav-tabs {
    border-bottom-width: 0
}

.nav-tabs .nav-link {
    background-color: rgba(255, 255, 255, .5);
    border-width: 0;
    border-radius: 0;
    padding: 10px 15px;
    line-height: 1.428;
    color: #4d5875
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    background-color: rgba(255, 255, 255, .5);
    color: #242f48
}

.nav-tabs .nav-link+.nav-link {
    margin-left: 3px
}

.nav-tabs .nav-link.active {
    background-color: #fff;
    color: #242f48;
    font-weight: 500;
    letter-spacing: -.1px
}

@media (min-width:768px) {
    .nav-tabs .nav-link {
        padding: 10px 20px
    }
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none
}

.nav-link.disabled {
    color: #737f9e;
    pointer-events: none;
    cursor: default
}

.latest-tasks .nav-tabs {
    border-bottom: 0
}

.nav-tabs {
    border-bottom: 1px solid #e3e8f7
}

.nav-tabs .nav-item {
    margin-bottom: -1px
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #dde2ef #dde2ef #d0d7e8
}

.nav-tabs .nav-link.disabled {
    color: #737f9e;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-link.active {
    color: #4d5875;
    background-color: #fff;
    border-color: #fff;
    border: 1px solid #e3e8f7;
    border-bottom: none;
}

.nav-tabs .nav-item.show .nav-link {
    color: #4d5875;
    background-color: #fff;
    border-color: #d0d7e8 #d0d7e8 #fff
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    border-radius: 3px
}

.nav-pills .nav-link.active {
    color: #fff;
    background-color: #078586
}

.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #078586
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

@media (min-width:768px) {
    .main-nav {
        align-items: center
    }
}

.main-nav .nav-link {
    display: block;
    color: #4d5875;
    padding: 0;
    position: relative;
    line-height: normal
}

.main-nav .nav-link:focus,
.main-nav .nav-link:hover {
    color: #242f48
}

.main-nav .nav-link+.nav-link {
    padding-top: 12px;
    margin-top: 12px;
    border-top: 1px solid #949eb7
}

.main-nav .nav-link.active {
    color: #078586!important
}

@media (min-width:768px) {
    .main-nav .nav-link+.nav-link {
        padding-top: 0;
        margin-top: 0;
        border-top: 0;
        padding-left: 15px;
        margin-left: 15px;
        border-left: 1px solid #949eb7
    }
}

.main-nav-column {
    flex-direction: column
}

.main-nav-column .nav-link {
    padding: 0;
    height: 38px;
    color: #242f48;
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.main-nav-column .nav-link i {
    font-size: 24px;
    line-height: 0;
    width: 24px;
    margin-right: 12px;
    text-align: center;
    transition: all .2s ease-in-out
}

.main-nav-column .nav-link i:not([class*=' tx-']) {
    color: #737f9e
}

.main-nav-column .nav-link i.typcn {
    line-height: .9
}

.main-nav-column .nav-link span {
    font-weight: 400;
    font-size: 11px;
    color: #949eb7;
    margin-left: auto
}

.main-nav-column .nav-link:focus,
.main-nav-column .nav-link:focus i:not([class*=' tx-']),
.main-nav-column .nav-link:hover,
.main-nav-column .nav-link:hover i:not([class*=' tx-']) {
    color: #242f48
}

.main-nav-column .nav-link.active {
    position: relative;
    color: #078586
}

.main-nav-column .nav-link.active::before {
    content: '';
    position: absolute;
    top: 6px;
    bottom: 6px;
    left: -28px;
    width: 3px;
    background-color: #078586;
    border-radius: 3px;
    display: none
}

.main-nav-column .nav-link.active i,
.main-nav-column .nav-link.active:focus,
.main-nav-column .nav-link.active:focus i,
.main-nav-column .nav-link.active:hover,
.main-nav-column .nav-link.active:hover i {
    color: #078586
}

.main-nav-column .nav-link+.nav-link {
    border-top: 1px dotted #b9c2d8
}

.main-nav-column.sm .nav-link {
    font-size: .875rem;
    font-weight: 400;
    padding: 10px 0
}

.main-nav-column.sm .nav-link i {
    font-size: 21px
}

@media (prefers-reduced-motion:reduce) {
    .main-nav-column .nav-link i {
        transition: none
    }
}

.main-nav-dark .nav-link {
    color: rgba(255, 255, 255, .7)
}

.main-nav-dark .nav-link:focus,
.main-nav-dark .nav-link:hover {
    color: #fff
}

.main-nav-dark .nav-link+.nav-link {
    border-color: #4d5875
}

.main-nav-dark .nav-link.active {
    color: #078586
}

.main-nav-colored-bg .nav-link+.nav-link {
    border-color: rgba(255, 255, 255, .4)
}

.main-nav-colored-bg .nav-link.active {
    color: #fff!important
}

.main-nav-line {
    position: relative
}

.main-nav-line .nav-link {
    padding: 0;
    color: #4d5875;
    position: relative;
    padding: 0 10px 0 10px;
    color: #37326d;
    position: relative
}

.main-nav-line .nav-link:focus,
.main-nav-line .nav-link:hover {
    color: #242f48
}

.main-nav-line .nav-link.active {
    color: #242f48
}

.main-nav-line .nav-link.active::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -20px;
    width: 2px;
    background-color: #078586
}

.main-nav-line.main-nav-dark .nav-link {
    color: rgba(255, 255, 255, .7)
}

.main-nav-line.main-nav-dark .nav-link:focus,
.main-nav-line.main-nav-dark .nav-link:hover {
    color: #fff
}

.main-nav-line.main-nav-dark .nav-link.active {
    color: #fff
}

.main-nav-line.main-nav-dark .nav-link.active::before {
    background-color: #fff
}

@media (min-width:768px) {
    .main-nav-line .nav-link.active::before {
        top: 37px;
        bottom: -20px;
        left: 0;
        right: 0;
        height: 2px;
        width: auto
    }
}

.main-nav-tabs {
    padding: 15px 15px 0;
    background-color: #d0d7e8
}

.main-nav-tabs .lSSlideOuter {
    position: relative;
    padding-left: 32px;
    padding-right: 35px
}

.main-nav-tabs .lSSlideWrapper {
    overflow: visible
}

.main-nav-tabs .lSAction>a {
    display: block;
    height: 40px;
    top: 16px;
    opacity: 1;
    background-color: #b9c2d8;
    background-image: none
}

.main-nav-tabs .lSAction>a:focus,
.main-nav-tabs .lSAction>a:hover {
    background-color: #a5afc4
}

.main-nav-tabs .lSAction>a::before {
    font-family: Ionicons;
    font-size: 18px;
    position: absolute;
    top: -4px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center
}

.main-nav-tabs .lSAction>a.lSPrev {
    left: -32px
}

.main-nav-tabs .lSAction>a.lSPrev::before {
    content: '\f3cf'
}

.main-nav-tabs .lSAction>a.lSNext {
    right: -35px
}

.main-nav-tabs .lSAction>a.lSNext::before {
    content: '\f3d1'
}

.main-nav-tabs .lSAction>a.disabled {
    background-color: #dde2ef;
    color: #fff
}

.main-nav-tabs .lightSlider {
    display: flex
}

.main-nav-tabs .tab-item {
    flex-shrink: 0;
    display: block;
    float: none;
    min-width: 150px
}

.main-nav-tabs .tab-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    line-height: 1.428;
    color: #4d5875;
    white-space: nowrap;
    background-color: #dde2ef
}

.main-nav-tabs .tab-link:focus,
.main-nav-tabs .tab-link:hover {
    background-color: #ecf0fa
}

.main-nav-tabs .tab-link.active {
    background-color: #fff;
    color: #242f48;
    font-weight: 500
}

.main-tab-pane {
    display: none
}

.main-tab-pane.active {
    display: block
}

.nav .nav-item .dropdown-menu {
    top: 46px;
    border-radius: 6px;
    min-width: 245px;
    -webkit-box-shadow: 0 0 15px 1px rgba(69, 65, 78, .2);
    box-shadow: 0 0 15px 1px rgba(69, 65, 78, .2)
}

.nav .nav-item .dropdown-menu .dropdown-item.dropdown-header {
    background: #1b2d8f;
    height: 85px;
    color: #fff
}

.nav-link.with-sub .angle {
    right: 25px;
    top: 18px
}

.nav-link.with-sub .side-badge {
    right: 44px;
    position: absolute
}

.nav-link .side-badge {
    right: 20px;
    position: absolute
}

.nav-sub-item .sub-angle {
    right: 25px;
    top: 18px;
    margin-left: auto;
    font-size: 11px
}

.nav-wrapper.crypto .label {
    font-weight: 500
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
    border-radius: 0
}

.page-item+.page-item {
    margin-left: 1px
}

.page-item.active .page-link {
    background-color: #078586
}

.page-link {
    color: #4d5875;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 36px; */
    /* height: 38px; */
    border-width: 0;
    background-color: #dde2ef;
    margin-left: 0;
    font-weight: 500
}

.page-link i {
    font-size: 18px;
    line-height: 0
}

.page-link:focus,
.page-link:hover {
    color: #242f48;
    background-color: #d0d7e8
}

.pagination-dark .page-item.active .page-link {
    background-color: #078586!important;
    color: #fff;
    border: 1px solid #078586
}

.pagination-dark .page-link {
    font-weight: 400;
    background-color: rgba(255, 255, 255, .05);
    color: rgba(255, 255, 255, .8);
    border: 1px solid rgba(0, 0, 0, .1)
}

.pagination-dark .page-link:focus,
.pagination-dark .page-link:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, .08)
}

.pagination-primary .page-item.active .page-link {
    background-color: #078586
}

.pagination-success .page-item.active .page-link {
    background-color: #21b18a;
    border-color: #21b18a
}

.pagination-circled .page-item+.page-item {
    margin-left: 5px
}

.pagination-circled .page-item:first-child .page-link,
.pagination-circled .page-item:last-child .page-link {
    border-radius: 100%
}

.pagination-circled .page-link {
    padding: 0;
    width: 40px;
    border-radius: 100%
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 3px;
    margin-top: 15px;
}

.page-link {
    position: relative;
    display: flex;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #596882;
    background-color: #e3e7ed;
    border: 1px solid #ecf0fa
}

.page-link:hover {
    z-index: 2;
    color: #ecf0fa;
    text-decoration: none;
    background-color: #078586!important;
    border-color: #078586!important
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(51, 102, 255, .25)
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px
}

.page-item:last-child .page-link {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #078586;
    border-color: #078586
}

.page-item.disabled .page-link {
    color: #737f9e;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #d0d7e8
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.09375rem;
    line-height: 1.5
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .76562rem;
    line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.page-next .page-link,
.page-prev .page-link {
    width: auto!important;
    padding: 0 10px
}

.page-next .page-link,
.page-prev .page-link {
    width: auto!important;
    padding: 10px 15px
}

.pagination li a {
    margin-right: 4px;
    border-radius: 4px
}

.pagination .page-link {
    background-color: #ecf0fa;
    border: 1px solid #cdd7ef
}

.pagination .page-link:hover {
    background-color: #078586!important;
    border: 1px solid #078586!important
}

.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
    border-radius: 4px
}

.pagination.pagination-circled .page-link {
    border-radius: 50%!important
}

.pagination-dark.pagination .page-link {
    background-color: #455377!important;
    border: 1px solid #526392!important;
    background-color: #455377!important;
    border: 1px solid #526392!important
}

.pagination-dark.pagination .page-link:hover {
    background: #078586!important;
    color: #fff;
    border: 1px solid #078586!important
}

@media (max-width:567px) {
    .pagination {
        font-size: 12px
    }
    .pagination .page-link {
        padding: 10px 5px
    }
}

.popover {
    font-size: 12px;
    padding: 0;
    border-radius: 0
}

.popover-header {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #364261;
    letter-spacing: .5px;
    padding: 12px 15px;
    background-color: #fff;
    border-color: rgba(28, 39, 60, .2);
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.popover-header::before {
    display: none!important
}

.popover-body {
    padding: 15px
}

.popover-body p:last-child {
    margin-bottom: 0
}

.popover-head-primary .popover-header {
    color: #fff;
    background-color: #078586
}

.popover-head-primary.bs-popover-auto[x-placement^=top] .arrow,
.popover-head-primary.bs-popover-top .arrow {
    bottom: -7px
}

.popover-head-primary.bs-popover-auto[x-placement^=left] .arrow,
.popover-head-primary.bs-popover-left .arrow {
    right: -7px
}

.popover-head-primary.bs-popover-auto[x-placement^=right] .arrow,
.popover-head-primary.bs-popover-right .arrow {
    left: -7px
}

.popover-head-primary.bs-popover-auto[x-placement^=bottom] .arrow::after,
.popover-head-primary.bs-popover-bottom .arrow::after {
    border-bottom-color: #078586
}

.popover-head-secondary .popover-header {
    color: #fff;
    background-color: #078586
}

.popover-head-secondary.bs-popover-auto[x-placement^=top] .arrow,
.popover-head-secondary.bs-popover-top .arrow {
    bottom: -7px
}

.popover-head-secondary.bs-popover-auto[x-placement^=left] .arrow,
.popover-head-secondary.bs-popover-left .arrow {
    right: -7px
}

.popover-head-secondary.bs-popover-auto[x-placement^=right] .arrow,
.popover-head-secondary.bs-popover-right .arrow {
    left: -7px
}

.popover-head-secondary.bs-popover-auto[x-placement^=bottom] .arrow::after,
.popover-head-secondary.bs-popover-bottom .arrow::after {
    border-bottom-color: #078586
}

.popover-head-primary,
.popover-head-secondary {
    border: 0
}

.popover-head-primary .popover-header,
.popover-head-secondary .popover-header {
    border: 0
}

.popover-head-primary .popover-body,
.popover-head-secondary .popover-body {
    border-top-width: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px
}

.popover-head-primary.bs-popover-auto[x-placement^=bottom] .arrow::before,
.popover-head-primary.bs-popover-bottom .arrow::before {
    display: none
}

.popover-head-secondary.bs-popover-auto[x-placement^=bottom] .arrow::before,
.popover-head-secondary.bs-popover-bottom .arrow::before {
    display: none
}

.popover-primary {
    background-color: #078586!important;
    border-width: 0;
    padding: 15px;
    border: 1px solid #0c5ccc!important
}

.popover-primary .popover-header {
    background-color: transparent;
    border-bottom-width: 0;
    padding: 0 0 15px;
    color: #fff
}

.popover-primary .popover-body {
    padding: 0;
    color: rgba(255, 255, 255, .75)
}

.popover-primary .arrow::before {
    display: none
}

.popover-primary.bs-popover-auto[x-placement^=top] .arrow::after,
.popover-primary.bs-popover-top .arrow::after {
    border-top-color: #078586
}

.popover-primary.bs-popover-auto[x-placement^=bottom] .arrow::after,
.popover-primary.bs-popover-bottom .arrow::after {
    border-bottom-color: #078586
}

.popover-primary.bs-popover-auto[x-placement^=left] .arrow::after,
.popover-primary.bs-popover-left .arrow::after {
    border-left-color: #078586
}

.popover-primary.bs-popover-auto[x-placement^=right] .arrow::after,
.popover-primary.bs-popover-right .arrow::after {
    border-right-color: #078586
}

.popover-secondary {
    background-color: #078586!important;
    border-width: 0;
    padding: 15px;
    border: 1px solid #0c5ccc!important
}

.popover-secondary .popover-header {
    background-color: transparent;
    border-bottom-width: 0;
    padding: 0 0 15px;
    color: #fff
}

.popover-secondary .popover-body {
    padding: 0;
    color: rgba(255, 255, 255, .75)
}

.popover-secondary .arrow::before {
    display: none
}

.popover-secondary.bs-popover-auto[x-placement^=top] .arrow::after,
.popover-secondary.bs-popover-top .arrow::after {
    border-top-color: #078586
}

.popover-secondary.bs-popover-auto[x-placement^=bottom] .arrow::after,
.popover-secondary.bs-popover-bottom .arrow::after {
    border-bottom-color: #078586
}

.popover-secondary.bs-popover-auto[x-placement^=left] .arrow::after,
.popover-secondary.bs-popover-left .arrow::after {
    border-left-color: #078586
}

.popover-secondary.bs-popover-auto[x-placement^=right] .arrow::after,
.popover-secondary.bs-popover-right .arrow::after {
    border-right-color: #078586
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .76562rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .3rem;
    border: 1px solid #d2d4d9
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem;
    margin: 0 .3rem
}

.popover .arrow::after,
.popover .arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-auto[x-placement^=top],
.bs-popover-top {
    margin-bottom: .5rem
}

.bs-popover-auto[x-placement^=top]>.arrow,
.bs-popover-top>.arrow {
    bottom: calc((.5rem + 1px) * -1)
}

.bs-popover-auto[x-placement^=top]>.arrow::before,
.bs-popover-top>.arrow::before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=top]>.arrow::after,
.bs-popover-top>.arrow::after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

.bs-popover-auto[x-placement^=right],
.bs-popover-right {
    margin-left: .5rem
}

.bs-popover-auto[x-placement^=right]>.arrow,
.bs-popover-right>.arrow {
    left: calc((.5rem + 1px) * -1);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
}

.bs-popover-auto[x-placement^=right]>.arrow::before,
.bs-popover-right>.arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=right]>.arrow::after,
.bs-popover-right>.arrow::after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

.bs-popover-auto[x-placement^=bottom],
.bs-popover-bottom {
    margin-top: .5rem
}

.bs-popover-auto[x-placement^=bottom]>.arrow,
.bs-popover-bottom>.arrow {
    top: calc((.5rem + 1px) * -1)
}

.bs-popover-auto[x-placement^=bottom]>.arrow::before,
.bs-popover-bottom>.arrow::before {
    top: 0;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=bottom]>.arrow::after,
.bs-popover-bottom>.arrow::after {
    top: 1px;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-auto[x-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7
}

.bs-popover-auto[x-placement^=left],
.bs-popover-left {
    margin-right: .5rem
}

.bs-popover-auto[x-placement^=left]>.arrow,
.bs-popover-left>.arrow {
    right: calc((.5rem + 1px) * -1);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
}

.bs-popover-auto[x-placement^=left]>.arrow::before,
.bs-popover-left>.arrow::before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=left]>.arrow::after,
.bs-popover-left>.arrow::after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: .875rem;
    background-color: #fff;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: .5rem .75rem;
    color: #031b4e
}

.message.popover-body {
    padding: 0;
}

.popover-static-demo {
    background-color: #dde2ef;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center
}

.popover-static-demo .popover {
    z-index: 0;
    opacity: 1;
    position: relative;
    display: inline-block;
    margin: 0 10px;
    box-shadow: -8px 12px 18px 0 #dadee8;
    border: 1px solid #c0c6d0
}

.popover-static-demo .bs-popover-auto[x-placement^=bottom] .arrow,
.popover-static-demo .bs-popover-auto[x-placement^=top] .arrow,
.popover-static-demo .bs-popover-bottom .arrow,
.popover-static-demo .bs-popover-top .arrow {
    left: 50%;
    margin-left: -5px
}

.popover-static-demo .bs-popover-auto[x-placement^=left],
.popover-static-demo .bs-popover-auto[x-placement^=right],
.popover-static-demo .bs-popover-left,
.popover-static-demo .bs-popover-right {
    margin-top: 5px
}

.popover-static-demo .bs-popover-auto[x-placement^=left] .arrow,
.popover-static-demo .bs-popover-auto[x-placement^=right] .arrow,
.popover-static-demo .bs-popover-left .arrow,
.popover-static-demo .bs-popover-right .arrow {
    top: 50%;
    margin-top: -5px
}

.progress {
    height: auto;
    border-radius: 0
}

.progress-bar {
    border-radius: 0;
    height: 10px;
    font-size: 11px
}

.progress-bar-xs {
    height: 5px
}

.progress-bar-sm {
    height: 8px
}

.progress-bar-lg {
    height: 15px
}

.progress {
    display: flex;
    overflow: hidden;
    font-size: .65625rem;
    background-color: #dde2ef;
    border-radius: 3px
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #078586;
    transition: width .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        animation: none
    }
}

.table {
    color: #364261
}

.table thead td,
.table thead th {
    color: #37374e;
    font-weight: 700;
    font-size: 11px;
    letter-spacing: .5px;
    text-transform: uppercase;
    border-bottom-width: 1px;
    border-top-width: 0;
    padding: 0 15px 5px
}

.table tbody tr {
    background-color: rgba(255, 255, 255, .5)
}

.table tbody tr th {
    font-weight: 500
}

.table th {
    padding: 9px 15px;
    line-height: 1.462
}
.center-td{
	line-height: 38px;
}
.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: rgba(238, 238, 247, .5)
}

.table-bordered thead td,
.table-bordered thead th {
    border-top-width: 1px;
    padding-top: 7px;
    padding-bottom: 7px;
    background-color: rgba(255, 255, 255, .5)
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #031b4e
}

.table td,
.table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dde2ef
}

.table thead th {
    vertical-align: bottom
}

.table tbody+tbody {
    border-top: 2px solid #dde2ef
}

.table-sm td,
.table-sm th {
    padding: .3rem
}

.table-bordered {
    border: 1px solid #dde2ef
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #dde2ef
}

.table-borderless tbody+tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
    border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #ecf0fa
}

.table-hover tbody tr:hover {
    color: #031b4e;
    background-color: #eff3f7
}

.table-primary {
    background-color: #c6d4ff
}

.table-primary>td,
.table-primary>th {
    background-color: #c6d4ff
}

.table-primary tbody+tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
    border-color: #95afff
}

.table-hover .table-primary:hover {
    background-color: #adc1ff
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #adc1ff
}

.table-secondary {
    background-color: #d9dde5
}

.table-secondary>td,
.table-secondary>th {
    background-color: #d9dde5
}

.table-secondary tbody+tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
    border-color: #b9c1ce
}

.table-hover .table-secondary:hover {
    background-color: #cacfdb
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #cacfdb
}

.table-success {
    background-color: #c8e9b8
}

.table-success>td,
.table-success>th {
    background-color: #c8e9b8
}

.table-success tbody+tbody,
.table-success td,
.table-success th,
.table-success thead th {
    border-color: #99d67b
}

.table-hover .table-success:hover {
    background-color: #b9e3a5
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b9e3a5
}

.table-info {
    background-color: #bee5eb
}

.table-info>td,
.table-info>th {
    background-color: #bee5eb
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
    border-color: #86cfda
}

.table-hover .table-info:hover {
    background-color: #abdde5
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abdde5
}

.table-warning {
    background-color: #ffeeba
}

.table-warning>td,
.table-warning>th {
    background-color: #ffeeba
}

.table-warning tbody+tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
    border-color: #ffdf7e
}

.table-hover .table-warning:hover {
    background-color: #ffe8a1
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffe8a1
}

.table-danger {
    background-color: #f5c6cb
}

.table-danger>td,
.table-danger>th {
    background-color: #f5c6cb
}

.table-danger tbody+tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
    border-color: #ed969e
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f1b0b7
}

.table-light {
    background-color: #fcfcfd
}

.table-light>td,
.table-light>th {
    background-color: #fcfcfd
}

.table-light tbody+tbody,
.table-light td,
.table-light th,
.table-light thead th {
    border-color: #f9fafb
}

.table-hover .table-light:hover {
    background-color: #ededf3
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ededf3
}

.table-dark {
    background-color: #c8ccd3
}

.table-dark>td,
.table-dark>th {
    background-color: #c8ccd3
}

.table-dark tbody+tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #99a0ae
}

.table-hover .table-dark:hover {
    background-color: #babfc8
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #babfc8
}

.table-active {
    background-color: rgba(0, 0, 0, .075)
}

.table-active>td,
.table-active>th {
    background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, .075)
}

.table .thead-dark th {
    color: #fff;
    background-color: #364261;
    border-color: #49597b
}

.table .thead-light th {
    color: #4d5875;
    background-color: #dde2ef;
    border-color: #d0d7e8
}

.table-dark {
    color: #fff;
    background-color: #364261
}

.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #49597b
}

.table-dark.table-bordered {
    border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, .05)
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, .075)
}

@media (max-width:575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
    .table-responsive-sm>.table-bordered {
        border: 0
    }
}

@media (max-width:767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
    .table-responsive-md>.table-bordered {
        border: 0
    }
}

@media (max-width:991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
    .table-responsive-lg>.table-bordered {
        border: 0
    }
}

@media (max-width:1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
    .table-responsive-xl>.table-bordered {
        border: 0
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: inherit;
    -webkit-overflow-scrolling: touch
}
@media (max-width:768px){

    .participators-table-mobile{
        overflow-x:auto;
    }
    .select-mobile{
        width: 200px;
    }
    .table-mobile-scroll{
        overflow-x:auto;
    }
}
.table-responsive>.table-bordered {
    border: 0
}

@media print {
    .table {
        border-collapse: collapse!important
    }
    .table td,
    .table th {
        background-color: #fff!important
    }
    .table-bordered td,
    .table-bordered th {
        border: 1px solid #e3e8f7!important
    }
    .table-dark {
        color: inherit
    }
    .table-dark tbody+tbody,
    .table-dark td,
    .table-dark th,
    .table-dark thead th {
        border-color: #d0d7e8
    }
    .table .thead-dark th {
        color: inherit;
        border-color: #d0d7e8
    }
}

.table.table-clean td .value {
    font-size: .9rem;
    line-height: 1.6;
    font-weight: 500
}

.table.table-clean td .sub-value {
    font-size: .72rem;
    color: #737f9e
}

.table.table-clean tr:first-child td {
    border-top: none
}

.table.table-clean td {
    padding-left: 0;
    padding-right: 0;
    border-top-color: rgba(0, 0, 0, .05)
}

.table.table-clean td,
.table.table-clean th {
    padding: 7px 20px!important
}

.toast {
    background-color: rgba(255, 255, 255, .95);
    border-color: rgba(28, 39, 60, .16);
    box-shadow: 0 2px 3px rgba(28, 39, 60, .06);
    border-radius: 3px
}

.toast-header {
    border-bottom-color: rgba(205, 212, 224, .4)
}

.toast-header .close {
    font-weight: 300;
    color: #949eb7
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: .875rem;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border: 1px solid #e5e8f3;
    box-shadow: -8px 12px 18px 0 #ecf0fa;
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: .25rem
}

.toast:not(:last-child) {
    margin-bottom: .75rem
}

.toast.showing {
    opacity: 1
}

.toast.show {
    display: block;
    opacity: 1
}

.toast.hide {
    display: none
}

.toast-header {
    display: flex;
    align-items: center;
    padding: .25rem .75rem;
    color: #2c3e56;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, .05)
}

.toast-body {
    padding: .75rem;
    color: #607086
}

.demo-static-toast .toast {
    opacity: 1
}

.tooltip {
    top: 0
}

.tooltip-inner {
    font-size: 12px;
    border-radius: 2px
}

.tooltip-primary .tooltip-inner {
    background-color: #078586;
    color: #fff
}

.tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip-primary.bs-tooltip-top .arrow::before {
    border-top-color: #078586
}

.tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip-primary.bs-tooltip-bottom .arrow::before {
    border-bottom-color: #078586
}

.tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-primary.bs-tooltip-left .arrow::before {
    border-left-color: #078586
}

.tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-primary.bs-tooltip-right .arrow::before {
    border-right-color: #078586
}

.tooltip-secondary .tooltip-inner {
    background-color: #078586;
    color: #fff
}

.tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip-secondary.bs-tooltip-top .arrow::before {
    border-top-color: #078586
}

.tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip-secondary.bs-tooltip-bottom .arrow::before {
    border-bottom-color: #078586
}

.tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-secondary.bs-tooltip-left .arrow::before {
    border-left-color: #078586
}

.tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-secondary.bs-tooltip-right .arrow::before {
    border-right-color: #078586
}

.tooltip {
    position: absolute;
    z-index: 9;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .76562rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-top .arrow {
    bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #000
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
    top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 3px
}

.tooltip-static-demo {
    text-align: center;
    background-color: #dde2ef;
    padding: 20px
}

.tooltip-static-demo .tooltip {
    z-index: 0;
    opacity: 1;
    position: relative;
    display: inline-block;
    margin: 0 10px
}

.tooltip-static-demo .bs-tooltip-auto[x-placement^=bottom] .arrow,
.tooltip-static-demo .bs-tooltip-auto[x-placement^=top] .arrow,
.tooltip-static-demo .bs-tooltip-bottom .arrow,
.tooltip-static-demo .bs-tooltip-top .arrow {
    left: 50%;
    margin-left: -2px
}

.tooltip-static-demo .bs-tooltip-auto[x-placement^=left],
.tooltip-static-demo .bs-tooltip-auto[x-placement^=right],
.tooltip-static-demo .bs-tooltip-left,
.tooltip-static-demo .bs-tooltip-right {
    margin-top: 5px
}

.tooltip-static-demo .bs-tooltip-auto[x-placement^=left] .arrow,
.tooltip-static-demo .bs-tooltip-auto[x-placement^=right] .arrow,
.tooltip-static-demo .bs-tooltip-left .arrow,
.tooltip-static-demo .bs-tooltip-right .arrow {
    top: 50%;
    margin-top: -6px
}

.tabs-style-1 .main-nav-line .nav-link {
    padding: 10px 18px 10px 18px;
    background: 0 0
}

.tabs-style-1 .main-nav-line .nav-link.active::before {
    bottom: 0
}

.tabs-style-2 .main-nav-line .nav-link {
    padding: .7rem 2rem;
    background: #ecf0fa
}

.tabs-style-2 .main-nav-line .nav-link.active {
    background: 0 0;
    color: #078586;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.tabs-style-2 .main-nav-line .nav-link.active::before {
    display: none
}

.tabs-style-2 .main-nav-line .nav-link {
    padding: .7rem 2rem;
    background: #ecf0fa;
    border: 1px solid #e2e8f5;
    border-bottom: 0
}

.tabs-style-2 li .nav-link.active {
    background: 0 0;
    box-shadow: inset 0 3px 1px #078586;
    color: #078586;
    border-top: 0
}

.tabs-style-3 {
    padding: 20px;
    border: 1px solid #e3e3e3
}

.tabs-style-3 .nav.panel-tabs li a {
    padding: 10px 24px 10px 24px;
    background: #ecf0fa;
    border-radius: 20px;
    margin: 0 5px 5px 0;
    text-align: center;
    display: block;
    color: #14112d
}

.tabs-style-3 .tabs-menu-body {
    margin-top: 20px
}

.tabs-style-3 .nav.panel-tabs li a.active {
    background: #078586;
    color: #fff
}

.tabs-style-4 .nav.panel-tabs li {
    display: block;
    width: 100%
}

.tabs-style-4 .tabs-menu-body {
    padding: 20px;
    border: 1px solid #e3e7f3;
    border-radius: 4px
}

.tabs-style-4 .nav.panel-tabs li a {
    padding: 10px 18px 10px 18px;
    background: #ecf0fa;
    border-radius: 4px;
    margin: 0 0 16px 0;
    text-align: center;
    display: block;
    color: #14112d;
    width: 100%
}

.tabs-style-4 .nav.panel-tabs li a.active {
    background: #078586;
    color: #fff
}

@media (min-width:768px) {
    .tabs-style-4 .tab-menu-heading {
        width: 200px
    }
}

.panel-primary.tabs-style-1 .main-nav-line .nav-link.active::before {
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    width: auto
}

.tab_wrapper {
    display: inline-block;
    width: 100%;
    position: relative
}

.tab_wrapper.right_side>ul {
    width: 25%;
    float: right;
    padding-left: 0;
    border-bottom: 1px solid #f0f2f7
}

.tab_wrapper.right_side>ul li {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 12px 18px;
    border-width: 1px;
    border-right: 0;
    border-left: 0;
    text-align: right;
    list-style-type: none
}

.tab_wrapper.right_side>ul li:after {
    right: 0;
    left: inherit;
    top: 0;
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    background: #f0f2f7
}

.tab_wrapper.right_side>ul li.active {
    border-color: #f0f2f7
}

.tab_wrapper.right_side>ul li.active:after {
    right: 0;
    left: inherit;
    top: 0;
    width: 5px;
    height: 100%;
    content: ""
}

.tab_wrapper.right_side>ul li.active:before {
    left: -2px;
    right: inherit;
    position: absolute;
    top: 0;
    content: "";
    width: 2px;
    height: 100%;
    background: #fff
}

.tab_wrapper.right_side .content_wrapper {
    width: 75%;
    float: left;
    border: 1px solid #f0f2f7
}

.tab_wrapper.right_side .controller {
    top: -50px
}

.tab_wrapper>ul li.active {
    border-color: #078586;
    background: #078586;
    color: #fff
}

.tab_wrapper * {
    box-sizing: border-box
}

.tab_wrapper>ul {
    clear: both;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid rgba(0, 40, 100, .12);
    margin-bottom: 0
}

.tab_wrapper>ul li {
    float: left;
    cursor: pointer;
    font-weight: 500;
    padding: 8px 18px;
    text-transform: uppercase;
    border: 1px solid #f0f2f7;
    font-size: 1.05em;
    border-bottom: 0;
    position: relative;
    border-top: 3px solid #f0f2f7;
    margin: 0 5px 0 0
}

.tab_wrapper>ul li.active:after {
    content: "";
    position: absolute;
    bottom: -1px;
    height: 1px;
    left: 0;
    background: #fff;
    width: 100%
}

.tab_wrapper .controller {
    display: block;
    overflow: hidden;
    clear: both;
    position: absolute;
    top: 0;
    right: 0
}

.tab_wrapper .controller span {
    padding: 8px 22px;
    text-transform: uppercase;
    display: inline-block
}

.tab_wrapper .controller span.next {
    float: right;
    margin-left: 10px
}

.tab_wrapper .content_wrapper {
    float: left;
    width: 100%;
    border: 1px solid rgba(0, 40, 100, .12);
    border-top: 0;
    box-sizing: border-box
}

.tab_wrapper .content_wrapper .tab_content {
    display: none;
    padding: 15px 20px
}

.tab_wrapper .content_wrapper .tab_content .error {
    color: red
}

.tab_wrapper .content_wrapper .accordian_header {
    display: none;
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: 700;
    border-top: 1px solid #f0f2f7;
    border-bottom: 1px solid #fff;
    position: relative;
    cursor: pointer
}

.tab_wrapper .content_wrapper .accordian_header.active {
    border: 1px solid #f0f2f7
}

.tab_wrapper .content_wrapper .accordian_header.active:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 5px;
    height: 100%
}

.tab_wrapper .content_wrapper .accordian_header .arrow {
    float: right;
    display: inline-block;
    width: 12px;
    height: 12px;
    position: relative;
    border-radius: 2px;
    transform: rotate(-135deg);
    background: #fff;
    border-top: 3px solid #cdd2e0;
    border-left: 3px solid #cdd2e0
}

.tab_wrapper .content_wrapper .accordian_header.active .arrow {
    transform: rotate(45deg);
    margin-top: 5px
}

.tab_wrapper.left_side>ul {
    width: 25%;
    float: left;
    padding-left: 0;
    border-bottom: 1px solid #f0f2f7
}

.tab_wrapper.left_side>ul li {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 12px 18px;
    border-width: 1px;
    border-right: 0;
    border-left: 0;
    list-style-type: none
}

.tab_wrapper.left_side>ul li:after {
    left: 0;
    top: 0;
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    background: #f0f2f7
}

.tab_wrapper.left_side>ul li.active {
    border-color: #f0f2f7
}

.tab_wrapper.left_side>ul li.active:after {
    left: 0;
    top: 0;
    width: 5px;
    height: 100%
}

.tab_wrapper.left_side>ul li.active:before {
    right: -1px;
    position: absolute;
    top: 0;
    content: "";
    width: 2px;
    height: 100%;
    background: #fff
}

.tab_wrapper.left_side .content_wrapper {
    width: 75%;
    border: 1px solid #f0f2f7;
    float: left
}

.tab_wrapper.left_side .controller {
    top: -50px
}

.tab_wrapper.right_side>ul {
    width: 25%;
    float: right;
    border-bottom: 1px solid #f0f2f7
}

.tab_wrapper.right_side>ul li {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 12px 18px;
    border-width: 1px;
    border-right: 0;
    border-left: 0;
    text-align: right
}

.tab_wrapper.right_side>ul li:after {
    right: 0;
    left: inherit;
    top: 0;
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    background: #f0f2f7
}

.tab_wrapper.right_side>ul li.active {
    border-color: #f0f2f7
}

.tab_wrapper.right_side>ul li.active:after {
    right: 0;
    left: inherit;
    top: 0;
    width: 5px;
    height: 100%;
    content: ""
}

.tab_wrapper.right_side>ul li.active:before {
    left: -2px;
    right: inherit;
    position: absolute;
    top: 0;
    content: "";
    width: 2px;
    height: 100%;
    background: #fff
}

.tab_wrapper.right_side .content_wrapper {
    width: 75%;
    float: left;
    border: 1px solid #f0f2f7
}

.tab_wrapper.right_side .controller {
    top: -50px
}

.tab_wrapper.accordion .content_wrapper {
    border-color: #f0f2f7
}

.tab_wrapper.accordion .content_wrapper .accordian_header {
    display: block
}

.tab_wrapper.accordion>ul {
    display: none
}

.tab_wrapper .active_tab {
    display: none;
    position: relative;
    background: #000;
    padding: 12px;
    text-align: center;
    padding-right: 50px;
    color: #fff;
    cursor: pointer;
    border-radius: 30px
}

.tab_wrapper .active_tab .arrow {
    width: 30px;
    height: 30px;
    overflow: hidden;
    line-height: 30px;
    text-align: center;
    background: #fff;
    position: absolute;
    right: 6px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%)
}

.tab_wrapper .active_tab .arrow:after {
    content: "";
    width: 10px;
    display: inline-block;
    height: 10px;
    border-right: 3px solid #000;
    border-bottom: 3px solid #000;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -6px;
    margin-top: -8px
}

.tab_wrapper .active_tab .text {
    text-indent: 39px;
    display: block
}

@media only screen and (max-width:768px) {
    .tab_wrapper>ul {
        display: none
    }
    .tab_wrapper .content_wrapper {
        border: 0
    }
    .tab_wrapper .content_wrapper .accordian_header {
        display: block
    }
    .tab_wrapper.left_side .content_wrapper,
    .tab_wrapper.right_side .content_wrapper {
        width: 100%;
        border-top: 0
    }
    .tab_wrapper .controller {
        display: none
    }
    .tab_wrapper.show-as-dropdown>.tab_list {
        border: 0;
        box-shadow: 0 0 8px #f0f2f7;
        position: absolute;
        z-index: 3;
        background: #fff;
        border-radius: 5px;
        margin-top: 15px
    }
    .tab_wrapper.show-as-dropdown>.tab_list:before {
        content: "";
        position: absolute;
        top: -6px;
        left: 50%;
        width: 14px;
        height: 14px;
        background: #fff;
        transform: rotate(45deg);
        box-shadow: -3px -4px 5px #efefef;
        margin-left: -7px
    }
    .tab_wrapper.show-as-dropdown>.tab_list li {
        border: 0;
        border-bottom: 1px solid #f0f2f7
    }
    .tab_wrapper.show-as-dropdown>.tab_list li.active:after {
        display: none
    }
    .tab_wrapper.show-as-dropdown .active_tab {
        display: block
    }
    .tab_wrapper.show-as-dropdown>.content_wrapper>.accordian_header,
    .tab_wrapper.show-as-dropdown>.tab_list {
        display: none
    }
    .tab_wrapper.show-as-dropdown>.tab_list li {
        display: block;
        float: none;
        width: 100%
    }
}

@media only screen and (min-width:769px) {
    .tab_wrapper.show-as-dropdown>.tab_list {
        display: block!important;
        overflow: hidden
    }
}

.dark-theme .tabs-style-2 .main-nav-line .nav-link {
    background: #30385f
}

.dark-theme .tabs-style-2 .main-nav-line .nav-link.active {
    background: #078586!important;
    color: #fff
}

.dark-theme .tabs-style-1 .main-nav-line .nav-link {
    background: #191f3a
}

.dark-theme .tabs-style-3 {
    border: 1px solid rgba(227, 227, 227, .1)
}

.dark-theme .tabs-style-3 .nav.panel-tabs li a {
    background: rgba(239, 239, 245, .3);
    color: #fff
}

.dark-theme .tabs-style-4 .tabs-menu-body {
    border: 1px solid rgba(227, 227, 227, .15)
}

.dark-theme .tabs-style-3 .nav.panel-tabs li a.active {
    background: #078586;
    color: #fff
}

.dark-theme .tabs-style-4 .nav.panel-tabs li a {
    background: rgba(239, 239, 245, .2);
    color: #fff
}

.dark-theme .tabs-style-4 .nav.panel-tabs li a.active {
    background: #078586;
    color: #fff
}

.dark-theme .tab_wrapper.left_side .content_wrapper,
.dark-theme .tab_wrapper.right_side .content_wrapper,
.dark-theme .tab_wrapper>ul li {
    border: 1px solid rgba(240, 242, 247, .12)
}

.dark-theme .tab_wrapper.left_side>ul li.active:before,
.dark-theme .tab_wrapper.left_side>ul li:after,
.dark-theme .tab_wrapper.right_side>ul li.active:before,
.dark-theme .tab_wrapper.right_side>ul li:after {
    background: #078586
}

.dark-theme .tab_wrapper.left_side>ul li.active,
.dark-theme .tab_wrapper.right_side>ul li.active {
    border-color: rgba(240, 242, 247, .1)
}

.dark-theme .tab_wrapper.left_side>ul,
.dark-theme .tab_wrapper.right_side>ul {
    border-bottom: 1px solid rgba(240, 242, 247, .1)
}

.tab_wrapper .content_wrapper .accordian_header.active {
    color: #078586
}

.tab_wrapper .content_wrapper .accordian_header.active:after {
    background: #078586
}

.tab_wrapper .content_wrapper .accordian_header.active .arrow {
    transform: rotate(45deg);
    margin-top: 5px
}

.dark-theme .tab_wrapper .content_wrapper .accordian_header.active {
    border: 1px solid rgba(240, 242, 247, .1)
}

.dark-theme .tab_wrapper .content_wrapper .accordian_header {
    border-top: 1px solid rgba(240, 242, 247, .1);
    border-bottom: 1px solid rgba(240, 242, 247, .1)
}

.dark-theme .tab_wrapper .content_wrapper .accordian_header .arrow {
    background: 0 0;
    border-top: 3px solid rgba(240, 242, 247, .4);
    border-left: 3px solid rgba(240, 242, 247, .4)
}

.tabs-style-1 .panel-tabs {
    border-bottom: 1px solid #e0e6ed
}

.tabs-style-1 .main-nav-line .nav-link.active {
    color: #242f48;
    border: 1px solid;
    border-color: #e0e6ed #e0e6ed #fff
}

.tabs-style-1 .main-nav-line .nav-item {
    margin-bottom: -1px
}

.tabs-style-1 .panel-tabs.main-nav-line .nav-link.active::before {
    background: 0 0!important
}

.ckbox {
    font-weight: 400;
    position: relative;
    display: block;
    line-height: 1;
    margin-bottom: 0
}

.ckbox span {
    padding-left: 15px
}

.ckbox span:empty {
    float: left
}

.ckbox span:after,
.ckbox span:before {
    line-height: 18px;
    position: absolute
}

.ckbox span:before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #949eb7;
    top: 1px;
    left: 0
}

.ckbox span:after {
    top: 1px;
    left: 0;
    width: 16px;
    height: 16px;
    content: '';
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    background-size: 65%;
    background-repeat: no-repeat;
    background-position: 55% 45%;
    background-color: #078586;
    line-height: 17px;
    display: none
}

.ckbox span:empty {
    padding-left: 0;
    width: 3px
}

.ckbox input[type=checkbox] {
    opacity: 0;
    margin: 0
}

.ckbox input[type=checkbox]:checked+span:after {
    display: block
}

.ckbox input[type=checkbox][disabled]+span {
    opacity: .75
}

.ckbox input[type=checkbox][disabled]+span:after,
.ckbox input[type=checkbox][disabled]+span:before {
    opacity: .75
}

.ckbox-inline {
    display: inline-block
}

#count-down {
    position: relative;
    text-align: center;
    display: inline-block
}

#count-down .clock-presenter {
    height: 80px;
    padding: 0;
    text-align: center;
    width: 70px;
    height: 70px;
    float: left;
    margin: 0 20px 0 0;
    border-radius: 3px;
    background-size: cover;
    position: relative
}

#count-down .clock-presenter .digit {
    margin-top: 5px;
    font-size: 1.5rem;
    color: #fff;
    line-height: 60px;
    height: 60px;
    display: inline-block;
    overflow: hidden;
    text-align: center;
    position: relative;
    cursor: default;
    font-weight: 600;
    border-radius: 50%
}

#count-down .clock-presenter:before {
    content: '';
    position: absolute;
    background: linear-gradient(to left, #078586 0, #078586 100%)!important;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    border-radius: 4%
}

#count-down .clock-presenter .note {
    position: relative;
    margin-bottom: 0;
    cursor: default;
    font-size: 16px;
    opacity: .7
}

@media (max-width:568px) {
    #count-down .clock-presenter {
        float: none!important;
        margin-bottom: 45px
    }
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.3125rem;
    padding-left: 1.5rem
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #078586;
    background-color: #078586
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(51, 102, 255, .25)
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #b3c6ff
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #e6ecff;
    border-color: #e6ecff
}

.custom-control-input:disabled~.custom-control-label {
    color: #737f9e
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: #dde2ef
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top
}

.custom-control-label::before {
    position: absolute;
    top: .15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #949eb7 solid 1px
}

.custom-control-label::after {
    position: absolute;
    top: .15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%
}

.custom-checkbox .custom-control-label::before {
    border-radius: 3px
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: #078586;
    background-color: #078586
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(51, 102, 255, .5)
}

.custom-radio .custom-control-label::before {
    border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(51, 102, 255, .5)
}

.custom-switch {
    padding-left: 2.25rem
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: .5rem
}

.custom-switch .custom-control-label::after {
    top: calc(.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #949eb7;
    border-radius: .5rem;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    transform: translateX(.75rem)
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(51, 102, 255, .5)
}

@media (prefers-reduced-motion:reduce) {
    .custom-switch .custom-control-label::after {
        transition: none
    }
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4d5875;
    vertical-align: middle;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
    background-color: #fff;
    border: 1px solid #e3e8f7;
    border-radius: 3px;
    appearance: none
}

.custom-select:focus {
    border-color: #b3c6ff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(51, 102, 255, .25)
}

.custom-select:focus::-ms-value {
    color: #4d5875;
    background-color: #fff
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: .75rem;
    background-image: none
}

.custom-select:disabled {
    color: #737f9e;
    background-color: #dde2ef
}

.custom-select::-ms-expand {
    display: none
}

.custom-select-sm {
    height: calc(1.5em + .5rem + 2px);
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: .76562rem
}

.custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: 1.09375rem
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    margin-bottom: 0
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    margin: 0;
    opacity: 0
}

.custom-file-input:focus~.custom-file-label {
    border-color: #b3c6ff;
    box-shadow: 0 0 0 .2rem rgba(51, 102, 255, .25)
}

.custom-file-input:disabled~.custom-file-label {
    background-color: #dde2ef
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse"
}

.custom-file-input~.custom-file-label[data-browse]::after {
    content: attr(data-browse)
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4d5875;
    background-color: #fff;
    border: 1px solid #e3e8f7;
    border-radius: 3px
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + .75rem);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #4d5875;
    content: "Browse";
    background-color: #dde2ef;
    border-left: inherit;
    border-radius: 0 3px 3px 0
}

.custom-range {
    width: 100%;
    height: calc(1rem + .4rem);
    padding: 0;
    background-color: transparent;
    appearance: none
}

.custom-range:focus {
    outline: 0
}

.custom-range:focus::-moz-range-thumb,
.custom-range:focus::-ms-thumb,
.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(51, 102, 255, .25)
}

.custom-range::-moz-focus-outer {
    border: 0
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #078586;
    border: 0;
    border-radius: 1rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #e6ecff
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #d0d7e8;
    border-color: transparent;
    border-radius: 1rem
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #078586;
    border: 0;
    border-radius: 1rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

.custom-range::-moz-range-thumb:active {
    background-color: #e6ecff
}

.custom-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #d0d7e8;
    border-color: transparent;
    border-radius: 1rem
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: .2rem;
    margin-left: .2rem;
    background-color: #078586;
    border: 0;
    border-radius: 1rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

.custom-range::-ms-thumb:active {
    background-color: #e6ecff
}

.custom-range::-ms-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: .5rem
}

.custom-range::-ms-fill-lower {
    background-color: #d0d7e8;
    border-radius: 1rem
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #d0d7e8;
    border-radius: 1rem
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #949eb7
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #949eb7
}

.custom-range:disabled::-moz-range-track {
    cursor: default
}

.custom-range:disabled::-ms-thumb {
    background-color: #949eb7
}

@media (prefers-reduced-motion:reduce) {
    .custom-range::-webkit-slider-thumb {
        transition: none
    }
}

@media (prefers-reduced-motion:reduce) {
    .custom-range::-moz-range-thumb {
        transition: none
    }
}

@media (prefers-reduced-motion:reduce) {
    .custom-range::-ms-thumb {
        transition: none
    }
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: none
    }
}

.custom-file,
.custom-file-input {
    height: 38px
}

.custom-file-label {
    height: 38px;
    line-height: 1.8;
    border-radius: 0
}

.custom-file-label::after {
    line-height: 1.8;
    border-radius: 0;
    height: auto
}

.custom-select {
    background: 0 0
}

.main-img-user {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    text-align: center
}
.main-img-room{
    position: relative;
    display: flex;
    flex-wrap: wrap;


}
.room-images{
    position: relative;
    object-fit: cover;
    flex: 0 0 calc(25% - 10px);
    margin-right: 10px;
    margin-bottom: 10px;
}
.room-images img {
    object-fit: cover;
    height:100%
}
.close-image{
    position: absolute;
    right:10px;
    font-size: 20px;
    cursor: pointer;
    color: #000;
    background-color: transparent;
    border:none;

}
.main-img-user img {
    width: 100%!important;
    height: 100%!important;
    object-fit: cover;
    border-radius: 100%
}

.p-text {
    display: block;
    margin-left: 12px;
    margin-right: 10px
}

.p-text .p-name {
    color: #3e464e;
    font-size: 14px;
    vertical-align: middle;
    margin-bottom: 0;
    display: block;
    padding-bottom: 4px;
    line-height: 1.8
}

.p-text .p-sub-text {
    line-height: 0;
    display: block;
    color: #737f9e!important
}

.main-img-user {
    display: inline-block;
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    text-align: center
}

.main-img-user img {
    object-fit: cover;
    border-radius: 100%
}

.demo-avatar-group .main-img-user {
    width: 40px;
    height: 40px
}

.example .demo-avatar-group .main-img-user::after {
    right: 3px;
    bottom: 3px
}

.demo-avatar-group.main-avatar-list-stacked .main-img-user::after {
    right: 1px;
    bottom: 3px
}

.main-img-user::after {
    content: '';
    position: absolute;
    bottom: 3px;
    right: 0;
    width: 6px;
    height: 6px;
    background-color: #17b86a;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, .95);
    border-radius: 100%;
    display: block!important
}

.main-img-user.online::after {
    background-color: #0ba360
}

.main-avatar {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    background-color: #4d5875
}

.main-avatar::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 3px;
    width: 6px;
    height: 6px;
    background-color: #949eb7;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, .95);
    border-radius: 100%
}

.main-avatar.online::after {
    background-color: #0ba360
}

.avatar-xs {
    width: 24px!important;
    height: 24px!important;
    font-size: 11px!important
}

.avatar-xs::after {
    width: 5px;
    height: 5px
}

.avatar-sm {
    width: 32px!important;
    height: 32px!important;
    font-size: 14px!important
}

.avatar-sm::after {
    width: 7px;
    height: 7px
}

.avatar-md {
    width: 48px!important;
    height: 48px!important;
    font-size: 24px!important
}

.avatar-md::after {
    width: 9px;
    height: 9px;
    right: 2px;
    bottom: 2px
}

.avatar-lg {
    width: 64px!important;
    height: 64px!important;
    font-size: 28px!important
}

.avatar-lg::after {
    width: 10px;
    height: 10px;
    bottom: 3px;
    right: 4px
}

.avatar-xl {
    width: 72px!important;
    height: 72px!important;
    font-size: 36px!important
}

.avatar-xl::after {
    width: 11px;
    height: 11px;
    bottom: 4px;
    right: 5px;
    box-shadow: 0 0 0 2.5px #fff
}

.avatar-xxl {
    width: 100px!important;
    height: 100px!important;
    font-size: 56px!important
}

.avatar-xxl::after {
    width: 13px;
    height: 13px;
    bottom: 6px;
    right: 7px;
    box-shadow: 0 0 0 3px #fff
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #e3e8f7;
    border-radius: 3px;
    max-width: 100%;
    height: auto
}

.demo-avatar-group {
    display: flex;
    align-items: center
}

.demo-avatar-group .main-avatar,
.demo-avatar-group .main-img-user {
    flex-shrink: 0
}

.demo-avatar-group .main-img-user+.main-avatar,
.demo-avatar-group .main-img-user+.main-img-user {
    margin-left: 5px
}

.demo-avatar-group .main-avatar+.main-avatar,
.demo-avatar-group .main-avatar+.main-img-user {
    margin-left: 5px
}

@media (min-width:576px) {
    .demo-avatar-group .main-img-user+.main-avatar,
    .demo-avatar-group .main-img-user+.main-img-user {
        margin-left: 5px
    }
    .demo-avatar-group .main-avatar+.main-avatar,
    .demo-avatar-group .main-avatar+.main-img-user {
        margin-left: 5px
    }
    .avatar-list .avatar:not(:last-child) {
        margin-right: 5px!important
    }
}

.img-xs {
    width: 32px;
    min-width: 32px;
    height: 32px
}

.user-pic img {
    width: 60px;
    height: 60px;
    font-size: 36px
}

.main-img-user .avatar {
    width: 38px!important;
    height: 38px!important;
    border-radius: 4px!important
}

.main-img-user::after {
    display: none
}

.wrapper.image-group .img-xs.rounded-circle {
    margin-right: -5px
}

.user-lock img {
    width: 70px;
    height: 70px;
    margin: 0 auto
}

.avatar {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    background-color: #078586
}

.avatar-list .avatar:not(:last-child) {
    margin-right: .5rem
}

.img-thumbnail .caption {
    padding: 15px
}

.img-sm {
    width: 43px;
    min-width: 43px;
    height: 43px
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #dde2ef;
    border-radius: .3rem
}

@media (min-width:576px) {
    .jumbotron {
        padding: 4rem 2rem
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0
}

.main-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0
}

.main-list-item+.main-list-item {
    border-top: 1px solid #dde2ef
}

.main-list-item:last-child {
    padding-bottom: 0
}

.main-list-item h6 {
    margin-bottom: 2px;
    font-weight: 600
}

.main-list-item>div:first-child h6 {
    color: #242f48
}

.main-list-item>div span {
    color: #737f9e;
    font-size: 12px
}

.main-list-item>div:last-child {
    text-align: right
}

.main-list-item>div:last-child h6 {
    line-height: 1
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0
}

.list-group-item-action {
    width: 100%;
    color: #434f63;
    text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    z-index: 1;
    color: #4d5875;
    text-decoration: none;
    background-color: #ecf0fa
}

.list-group-item-action:active {
    color: #031b4e;
    background-color: #dde2ef
}

.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #e7ebf3
}

.list-group-item:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #737f9e;
    pointer-events: none;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #078586!important;
    border-color: #078586!important
}

.list-group-horizontal {
    flex-direction: row
}

.list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0
}

.list-group-horizontal .list-group-item:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0
}

.list-group-horizontal .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 0
}

@media (min-width:576px) {
    .list-group-horizontal-sm {
        flex-direction: row
    }
    .list-group-horizontal-sm .list-group-item {
        margin-right: -1px;
        margin-bottom: 0
    }
    .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0
    }
    .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0
    }
}

@media (min-width:768px) {
    .list-group-horizontal-md {
        flex-direction: row
    }
    .list-group-horizontal-md .list-group-item {
        margin-right: -1px;
        margin-bottom: 0
    }
    .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0
    }
    .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0
    }
}

@media (min-width:992px) {
    .list-group-horizontal-lg {
        flex-direction: row
    }
    .list-group-horizontal-lg .list-group-item {
        margin-right: -1px;
        margin-bottom: 0
    }
    .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0
    }
    .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0
    }
}

@media (min-width:1200px) {
    .list-group-horizontal-xl {
        flex-direction: row
    }
    .list-group-horizontal-xl .list-group-item {
        margin-right: -1px;
        margin-bottom: 0
    }
    .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0
    }
    .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0
    }
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.list-group-flush .list-group-item:last-child {
    margin-bottom: -1px
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0
}

.list-group-flush:last-child .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom: 0
}

.list-group-item-primary {
    color: #1b3585;
    background-color: #c6d4ff
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: #1b3585;
    background-color: #adc1ff
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #1b3585;
    border-color: #1b3585
}

.list-group-item-secondary {
    color: #3f4654;
    background-color: #d9dde5
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: #3f4654;
    background-color: #cacfdb
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #3f4654;
    border-color: #3f4654
}

.list-group-item-success {
    color: #1f5c01;
    background-color: #c8e9b8
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: #1f5c01;
    background-color: #b9e3a5
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #1f5c01;
    border-color: #1f5c01
}

.list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: #0c5460;
    background-color: #abdde5
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460
}

.list-group-item-warning {
    color: #856404;
    background-color: #ffeeba
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: #856404;
    background-color: #ffe8a1
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404
}

.list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: #721c24;
    background-color: #f1b0b7
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24
}

.list-group-item-light {
    color: #7f7f81;
    background-color: #fcfcfd
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: #7f7f81;
    background-color: #ededf3
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7f7f81;
    border-color: #7f7f81
}

.list-group-item-dark {
    color: #1f2533;
    background-color: #c8ccd3
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: #1f2533;
    background-color: #babfc8
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1f2533;
    border-color: #1f2533
}

.listgroup-example ul {
    list-style-type: none
}

.listgroup-example ul ul {
    list-style-type: square;
    margin: 10px 0 0 0
}

.listgroup-example ul li {
    padding: 5px 5px
}

.listgroup-example2 ul {
    list-style-type: none
}

.listgroup-example2 ul ul {
    list-style-type: circle;
    margin: 10px 0 0 0
}

.listgroup-example2 ul li {
    padding: 5px 5px
}

.users-list-group .btn-icon {
    width: 34px;
    height: 34px
}

.users-list-group .btn-icon i {
    line-height: 34px;
    line-height: 34px
}

.users-list-group .list-group-item {
    padding: .85rem 1.25rem
}

@media (max-width:991.98px) {
    .main-navbar-show {
        overflow: hidden
    }
    .main-navbar-show .main-navbar-backdrop {
        visibility: visible;
        opacity: 1
    }
    .main-navbar-show .main-navbar {
        transform: translateX(0);
        visibility: visible
    }
}

.main-navbar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .89);
    z-index: 900;
    visibility: hidden;
    opacity: 0;
    transition: all .4s
}

@media (prefers-reduced-motion:reduce) {
    .main-navbar-backdrop {
        transition: none
    }
}

.main-navbar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 240px;
    overflow-y: auto;
    background-color: #fff;
    z-index: 1000;
    visibility: hidden;
    transform: translateX(-240px);
    transition: all .4s
}

.main-navbar>.container,
.main-navbar>.container-fluid {
    display: flex;
    flex-direction: column
}

.main-navbar .nav {
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px
}

.main-navbar .nav-label {
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    color: #737f9e;
    letter-spacing: 1px;
    display: block;
    margin-bottom: 10px
}

.main-navbar .nav-item {
    position: relative
}

.main-navbar .nav-item+.nav-item {
    border-top: 1px dotted #dde2ef
}

.main-navbar .nav-item.active .nav-link {
    color: #078586
}

.main-navbar .nav-item.show>.nav-sub {
    display: block
}

.main-navbar .nav-item-mega {
    position: static
}

.main-navbar .nav-link {
    padding: 0;
    color: #242f48;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    display: flex;
    align-items: center;
    height: 42px;
    outline: 0
}

.main-navbar .nav-link i {
    font-size: 26px;
    margin-right: 10px
}

.main-navbar .nav-link i.typcn {
    line-height: 1;
    width: 20px
}

.main-navbar .nav-link i.typcn::before {
    width: auto
}

.main-navbar .nav-link.with-sub::after {
    content: '\f3d0';
    font-family: Ionicons;
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    margin-left: auto;
    color: #949eb7
}

.main-navbar .nav-link.with-sub::before {
    content: '';
    position: absolute;
    top: 39px;
    left: 50%;
    margin-left: -7px;
    width: 15px;
    height: 15px;
    border: 2px solid transparent;
    border-top-color: rgba(28, 39, 60, .12);
    border-left-color: rgba(28, 39, 60, .12);
    transform: rotate(45deg);
    background-color: #fff;
    z-index: 901;
    display: none
}

.main-navbar .nav-sub {
    display: none;
    border-top: 1px dotted #dde2ef;
    list-style: none;
    padding: 0;
    margin: 0
}

.main-navbar .nav-sub-item {
    position: relative;
    display: block;
    margin-left: 30px
}

.main-navbar .nav-sub-item+.nav-sub-item {
    border-top: 1px dotted #dde2ef
}

.main-navbar .nav-sub-item>.nav-sub {
    left: 176px;
    top: -10px;
    display: none
}

.main-navbar .nav-sub-item.show>.nav-sub {
    display: block
}

.main-navbar .nav-sub-item.active>.nav-sub-link {
    color: #078586
}

.main-navbar .nav-sub-link {
    position: relative;
    font-size: .875rem;
    color: #4d5875;
    display: flex;
    align-items: center;
    height: 34px
}

.main-navbar .nav-sub-link:focus,
.main-navbar .nav-sub-link:hover {
    color: #078586;
    outline: 0
}

.main-navbar .nav-sub-link.with-sub {
    justify-content: space-between
}

.main-navbar .nav-sub-link.with-sub::after {
    content: '\f3d0';
    font-family: Ionicons;
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    margin-left: auto;
    color: #949eb7
}

.main-navbar .nav-sub-mega {
    top: 57px;
    left: 0;
    right: 0;
    background-color: transparent;
    padding: 7px 0;
    border: 0;
    width: auto;
    border-top: 1px dotted #dde2ef;
    pointer-events: none
}

.main-navbar .nav-sub-mega .container,
.main-navbar .nav-sub-mega .container-fluid {
    padding: 0;
    pointer-events: auto
}

.main-navbar .nav-sub-mega .container-fluid>div+div,
.main-navbar .nav-sub-mega .container>div+div {
    margin-top: 25px
}

.main-navbar .nav-sub-mega .nav {
    min-width: 120px;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
    margin: 0
}

.main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: #242f48;
    letter-spacing: .5px;
    margin-bottom: 10px;
    margin-top: 30px;
    margin-left: 30px
}

.main-navbar .nav-sub-mega .nav li:not(.nav-sub-item):first-of-type {
    margin-top: 0
}

.main-navbar .nav-sub-mega .nav-sub-item+.nav-sub-item {
    padding-top: 7px;
    margin-top: 7px
}

.main-navbar .nav-sub-mega .nav-sub-link {
    height: auto
}

@media (prefers-reduced-motion:reduce) {
    .main-navbar {
        transition: none
    }
}

@media (min-width:992px) {
    .main-navbar {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        width: auto;
        padding: 0;
        border-right: 0;
        overflow-y: inherit;
        display: block;
        border-bottom: 1px solid rgba(28, 39, 60, .12);
        visibility: visible;
        transform: none;
        z-index: auto
    }
}

@media (max-width:991.98px) {
    .main-navbar>.container,
    .main-navbar>.container-fluid {
        padding: 0
    }
}

@media (min-width:992px) {
    .main-navbar>.container,
    .main-navbar>.container-fluid {
        flex-direction: row;
        align-items: center;
        height: 48px
    }
}

@media (min-width:992px) {
    .main-navbar>.container {
        padding: 0
    }
}

@media (min-width:992px) {
    .main-navbar>.container-fluid {
        padding-left: 20px;
        padding-right: 20px
    }
}

@media (min-width:992px) {
    .main-navbar .nav {
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
        padding: 0
    }
}

@media (min-width:992px) {
    .main-navbar .nav-label {
        display: none
    }
}

@media (min-width:992px) {
    .main-navbar .nav-item+.nav-item {
        border-top: 0;
        margin-left: 15px;
        padding-left: 15px;
        border-left: 1px dotted #dde2ef
    }
}

@media (min-width:1200px) {
    .main-navbar .nav-item+.nav-item {
        margin-left: 20px;
        padding-left: 20px
    }
}

@media (min-width:992px) {
    .main-navbar .nav-item.show .nav-link::before {
        display: block
    }
}

@media (min-width:992px) {
    .main-navbar .nav-link {
        height: auto;
        font-size: 14px
    }
}

@media (min-width:992px) {
    .main-navbar .nav-link i {
        margin-right: 8px;
        font-size: 22px
    }
}

@media (min-width:992px) {
    .main-navbar .nav-link i.typcn {
        line-height: .9;
        width: auto
    }
}

@media (min-width:992px) {
    .main-navbar .nav-link.with-sub::after {
        margin-left: 4px
    }
}

@media (min-width:992px) {
    .main-navbar .nav-sub {
        position: absolute;
        top: 44.5px;
        left: 0;
        width: 200px;
        background-color: #fff;
        border: 2px solid rgba(28, 39, 60, .12);
        padding: 8px 20px;
        z-index: 900
    }
}

@media (min-width:992px) {
    .main-navbar .nav-sub-item {
        margin-left: 0
    }
}

@media (max-width:991.98px) {
    .main-navbar .nav-sub-item>.nav-sub>.nav-sub-item {
        margin-left: 37px
    }
    .main-navbar .nav-sub-item>.nav-sub>.nav-sub-item>.nav-sub-link {
        padding-left: 10px
    }
}

@media (min-width:992px) {
    .main-navbar .nav-sub-link {
        font-size: .8125rem;
        height: 36px
    }
}

@media (min-width:992px) {
    .main-navbar .nav-sub-link.with-sub::after {
        margin-left: 4px;
        content: '\f3d1';
        font-size: 12px
    }
}

@media (min-width:992px) {
    .main-navbar .nav-sub-mega {
        padding: 0 25px;
        border-top: 0
    }
}

@media (max-width:991.98px) {
    .main-navbar .nav-sub-mega .container,
    .main-navbar .nav-sub-mega .container-fluid {
        max-width: none
    }
}

@media (min-width:992px) {
    .main-navbar .nav-sub-mega .container,
    .main-navbar .nav-sub-mega .container-fluid {
        display: flex;
        background-color: #fff;
        border: 2px solid rgba(28, 39, 60, .12)
    }
}

@media (min-width:992px) {
    .main-navbar .nav-sub-mega .container-fluid>div,
    .main-navbar .nav-sub-mega .container>div {
        display: flex;
        padding: 20px;
        flex: 1;
        margin-top: 0
    }
}

@media (min-width:1200px) {
    .main-navbar .nav-sub-mega .container-fluid>div,
    .main-navbar .nav-sub-mega .container>div {
        padding: 20px 25px
    }
}

@media (min-width:992px) {
    .main-navbar .nav-sub-mega .container-fluid>div+div,
    .main-navbar .nav-sub-mega .container>div+div {
        margin-top: 0;
        border-left: 1px solid rgba(28, 39, 60, .05)
    }
}

@media (min-width:1200px) {
    .main-navbar .nav-sub-mega .nav {
        min-width: 140px
    }
}

@media (max-width:991.98px) {
    .main-navbar .nav-sub-mega .nav+.nav .nav-sub-item:first-child {
        margin-top: 6px;
        padding-top: 6px;
        border-top: 1px dotted #dde2ef
    }
}

@media (min-width:992px) {
    .main-navbar .nav-sub-mega .nav+.nav {
        margin-left: 20px;
        margin-top: 31px;
        padding-top: 0;
        border-top: 0
    }
}

@media (min-width:1200px) {
    .main-navbar .nav-sub-mega .nav+.nav {
        margin-left: 25px
    }
}

@media (min-width:992px) {
    .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
        margin-bottom: 15px;
        margin-left: 0
    }
}

.main-navbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid #dde2ef;
    height: 60px
}

@media (min-width:992px) {
    .main-navbar-header {
        display: none
    }
}

.main-navbar-search {
    position: relative;
    padding: 0 20px;
    border-bottom: 1px solid #dde2ef
}

.main-navbar-search .form-control {
    border-width: 0;
    padding: 0
}

.main-navbar-search .form-control:focus {
    box-shadow: none!important;
    border-color: #d0d7e8
}

.main-navbar-search .btn,
.main-navbar-search .sp-container button {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    line-height: 1
}

@media (min-width:992px) {
    .main-navbar-search {
        display: none
    }
}

.sp-container .main-navbar-search button {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    line-height: 1
}

.main-navbar-two .nav-item.active {
    position: relative
}

.main-navbar-two .nav-item.active::before {
    content: '';
    position: absolute
}

.main-navbar-two .nav-item.show .nav-link::before {
    display: none
}

.main-navbar-two .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700
}

.main-navbar-two .nav-sub {
    border-width: 1px;
    top: 35px;
    border-top-width: 0
}

.main-navbar-two .nav-sub-item>.nav-sub {
    left: 178px;
    top: -8px;
    border-top-width: 1px
}

.main-navbar-two .nav-sub-mega {
    top: 48px
}

@media (min-width:992px) {
    .main-navbar-two .nav-item.active::before {
        top: 33px;
        left: 0;
        right: 12px;
        bottom: auto;
        border-bottom: 2px solid #078586;
        z-index: 5
    }
}

.main-navbar-three .nav-item {
    display: block
}

.main-navbar-three .nav-item+.nav-item {
    border-left-width: 0;
    padding-left: 0
}

.main-navbar-three .nav-item.show .nav-link::before {
    display: none
}

.main-navbar-three .nav-item.active .nav-link {
    color: #078586;
    font-weight: 700
}

.main-navbar-three .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: #364261;
    position: relative
}

.main-navbar-three .nav-link:focus,
.main-navbar-three .nav-link:hover {
    color: #242f48
}

.main-navbar-three .nav-sub {
    top: 35px;
    border-width: 0;
    padding-bottom: 5px
}

.main-navbar-three .nav-sub-item+.nav-sub-item {
    border-top: 0
}

.main-navbar-three .nav-sub-item .nav-sub {
    top: -8px;
    left: 182px
}

.main-navbar-three .nav-sub-link {
    height: 30px
}

@media (min-width:992px) {
    .main-navbar-three .nav-item+.nav-item {
        margin-left: 25px
    }
}

@media (min-width:992px) {
    .main-navbar-three .nav-link {
        display: block
    }
}

@media (min-width:992px) {
    .main-navbar-three .nav-link i {
        display: none
    }
}

@media (min-width:992px) {
    .main-navbar-three .nav-sub {
        box-shadow: 0 0 0 2px rgba(180, 189, 206, .5);
        padding-bottom: 8px
    }
}

@media (min-width:992px) {
    .main-navbar-three .nav-sub-link {
        height: 36px
    }
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem
}

.navbar>.container,
.navbar>.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    padding-top: .33594rem;
    padding-bottom: .33594rem;
    margin-right: 1rem;
    font-size: 1.09375rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.09375rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 3px
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%
}

@media (max-width:575.98px) {
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-sm .main-header {
        display: flex!important;
        flex-basis: auto
    }
    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media (max-width:767.98px) {
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-md .main-header {
        display: flex!important;
        flex-basis: auto
    }
    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media (max-width:991.98px) {
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-lg .main-header {
        display: flex!important;
        flex-basis: auto
    }
    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

@media (max-width:1199.98px) {
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-xl .main-header {
        display: flex!important;
        flex-basis: auto
    }
    .navbar-expand-xl .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    flex-wrap: nowrap
}

.navbar-expand .main-header {
    display: flex!important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .5);
    border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='$black-5' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .5)
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .5);
    border-color: rgba(255, 255, 255, .1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='$white-5' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, .5)
}

.navbar-dark .navbar-text a {
    color: #fff
}

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff
}

@media print {
    .navbar {
        display: none
    }
}

.navbar-form.active .input-group-btn {
    position: absolute;
    right: 12px;
    z-index: 9999;
    top: 13px
}

.navbar-form.nav-item.active .btn.btn-default.nav-link {
    display: none
}

@media (min-width:992px) {
    .navbar-form {
        display: none
    }
}

@media (max-width:991px) {
    .navbar-form.active .btn.btn-default.nav-link.resp-btn {
        display: none
    }
}

.ps {
    overflow: hidden
}

.ps>.ps__rail-y {
    width: 2px;
    background-color: rgba(255, 255, 255, .5);
    z-index: 10;
    position: absolute;
    left: auto!important;
    right: 0;
    opacity: 0;
    transition: opacity .2s
}

.ps>.ps__rail-y>.ps__thumb-y {
    position: absolute;
    border-radius: 0;
    width: 2px;
    left: 4px;
    background-color: #949eb7
}

.ps.ps--active-y:focus>.ps__rail-y,
.ps.ps--active-y:hover>.ps__rail-y {
    opacity: 1
}

@media (prefers-reduced-motion:reduce) {
    .ps>.ps__rail-y {
        transition: none
    }
}

.rdiobox {
    font-weight: 400;
    position: relative;
    display: block;
    line-height: 18px
}

.rdiobox span {
    padding-left: 13px
}

.rdiobox span:after,
.rdiobox span:before {
    line-height: 18px;
    position: absolute
}

.rdiobox span:before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #949eb7;
    border-radius: 50px;
    top: 2px;
    left: 0
}

.rdiobox span:after {
    content: '';
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 50px;
    top: 7px;
    left: 5px;
    display: none
}

.rdiobox span:empty {
    padding-left: 0;
    width: 16px;
    display: block
}

.rdiobox input[type=radio] {
    opacity: 0;
    margin: 0
}

.rdiobox input[type=radio]:checked+span:before {
    border-color: transparent;
    background-color: #078586
}

.rdiobox input[type=radio]:checked+span:after {
    display: block
}

.rdiobox input[type=radio][disabled]+span {
    opacity: .75
}

.rdiobox input[type=radio][disabled]+span:after,
.rdiobox input[type=radio][disabled]+span:before {
    opacity: .75
}

.rdiobox-inline {
    display: inline-block
}

.form-group-rdiobox {
    display: flex;
    align-items: center
}

.form-group-rdiobox .rdiobox {
    margin-bottom: 0
}

.form-group-rdiobox .rdiobox+.rdiobox {
    margin-left: 30px
}

.form-group-rdiobox .rdiobox span {
    padding-left: 0
}

.rating-stars {
    width: 100%;
    text-align: center
}

.rating-stars .rating-stars-container {
    font-size: 0
}

.rating-stars .rating-stars-container .rating-star {
    display: inline-block;
    font-size: 26px;
    cursor: pointer;
    padding: 4px 8px;
    color: #e1e6f1
}

.rating-stars .rating-stars-container .rating-star.is--active .fa-heart,
.rating-stars .rating-stars-container .rating-star.is--hover .fa-heart {
    color: #fb0d00
}

.rating-stars .rating-stars-container .rating-star.sm {
    display: inline-block;
    font-size: 14px;
    color: #eaedf1;
    cursor: pointer;
    padding: 5px
}

.rating-stars .rating-stars-container .rating-star.is--active,
.rating-stars .rating-stars-container .rating-star.is--hover {
    color: #f1c40f
}

.rating-stars .rating-stars-container .rating-star .fa-heart .is--no-hover,
.rating-stars .rating-stars-container .rating-star.is--no-hover {
    color: #e3e8f7
}

.rating-stars .rating-stars-container .rating-star.is--active,
.rating-stars .rating-stars-container .rating-star.is--hover {
    color: #f1c40f
}

.br-theme-bars-horizontal .br-widget a {
    background-color: #e1e6f1
}

.br-theme-bars-horizontal .br-widget a.br-active,
.br-theme-bars-horizontal .br-widget a.br-selected {
    background-color: #078586
}

.br-theme-bars-horizontal .br-widget .br-current-rating {
    color: #078586
}

.br-theme-bars-pill .br-widget a {
    background-color: #e1e6f1;
    color: #078586
}

.br-theme-bars-pill .br-widget a.br-active,
.br-theme-bars-pill .br-widget a.br-selected {
    background-color: #078586;
    color: #fff
}

.br-theme-bars-square .br-widget a {
    border: 2px solid #e1e6f1;
    background-color: #fff;
    color: #334151
}

.br-theme-bars-square .br-widget a.br-active,
.br-theme-bars-square .br-widget a.br-selected {
    border: 2px solid #e1e6f1;
    color: #078586
}

.br-theme-bars-movie .br-widget a {
    background-color: #e1e6f1
}

.br-theme-bars-movie .br-widget a.br-active,
.br-theme-bars-movie .br-widget a.br-selected {
    background-color: #078586
}

.br-theme-bars-movie .br-widget .br-current-rating {
    color: #078586
}

.br-theme-bars-1to10 .br-widget a {
    background-color: #e1e6f1
}

.br-theme-bars-1to10 .br-widget a.br-active,
.br-theme-bars-1to10 .br-widget a.br-selected {
    background-color: #078586
}

.br-theme-bars-1to10 .br-widget .br-current-rating {
    color: #078586
}

.br-theme-fontawesome-stars .br-widget a.br-active:after,
.br-theme-fontawesome-stars .br-widget a.br-selected:after {
    color: #078586
}

.rating-stars input {
    display: block;
    margin: 0 auto;
    text-align: center;
    padding: .375rem .75rem;
    font-size: .9375rem;
    line-height: 1.6;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #eaedf1;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.rating-table.table td,
.rating-table.table th {
    padding: 7px 6px!important;
    line-height: 1.462
}

.rating-table tr {
    padding: 10px 0
}

.tag {
    font-size: .75rem;
    color: #14112d;
    background-color: #ecf0fa;
    border-radius: 3px;
    padding: 0 .5rem;
    line-height: 2em;
    display: -ms-inline-flexbox;
    display: inline-flex;
    cursor: default;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

a.tag {
    text-decoration: none;
    cursor: pointer;
    transition: .3s color, .3s background
}

a.tag:hover {
    background-color: rgba(110, 118, 135, .2);
    color: inherit
}

.tag-addon {
    display: inline-block;
    padding: 0 .5rem;
    color: inherit;
    text-decoration: none;
    background: rgba(0, 0, 0, .06);
    margin: 0 -.5rem 0 .5rem;
    text-align: center;
    min-width: 1.5rem
}

.tag-addon:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px
}

.tag-addon i {
    vertical-align: middle;
    margin: 0 -.25rem
}

a.tag-addon {
    text-decoration: none;
    cursor: pointer;
    transition: .3s color, .3s background
}

a.tag-addon:hover {
    background: rgba(0, 0, 0, .16);
    color: inherit
}

.tag-avatar {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 3px 0 0 3px;
    margin: 0 .5rem 0 -.5rem
}

.tag-blue {
    background-color: #467fcf!important;
    color: #fff
}

.tag-indigo {
    background-color: #078586!important;
    color: #fff
}

.tag-purple {
    background-color: #8500ff!important;
    color: #fff
}

.tag-pink {
    background-color: #ec82ef!important;
    color: #fff
}

.tag-red {
    background-color: #ec2d38!important;
    color: #fff
}

.tag-orange {
    background-color: #fd7e14!important;
    color: #fff
}

.tag-yellow {
    background-color: #fdb901!important;
    color: #fff
}

.tag-green {
    background-color: #0fa751!important;
    color: #fff
}

.tag-teal {
    background-color: #0cc!important;
    color: #fff
}

.tag-cyan {
    background-color: #00b9ff!important;
    color: #fff
}

.tag-white {
    background-color: #fff!important;
    color: #fff
}

.tag-gray {
    background-color: #868e96!important;
    color: #fff
}

.tag-gray-dark {
    background-color: #343a40!important;
    color: #fff
}

.tag-azure {
    background-color: #17c1f4!important;
    color: #fff
}

.tag-lime {
    background-color: #7bd235!important;
    color: #fff
}

.tag-primary {
    background-color: #467fcf!important;
    color: #fff
}

.tag-secondary {
    background-color: #868e96!important;
    color: #fff
}

.tag-success {
    background-color: #0fa751!important;
    color: #fff
}

.tag-info {
    background-color: #17c1f4!important;
    color: #fff
}

.tag-warning {
    background-color: #fdb901!important;
    color: #fff
}

.tag-danger {
    background-color: #ec2d38!important;
    color: #fff
}

.tag-light {
    background-color: #f8f9fa!important;
    color: #fff
}

.tag-dark {
    background-color: #343a40!important;
    color: #fff
}

.tag-rounded {
    border-radius: 50px
}

.tag-rounded .tag-avatar {
    border-radius: 50px
}

.tags {
    margin-bottom: -.5rem;
    font-size: 0
}

.tags>.tag {
    margin-bottom: .5rem
}

.tags>.tag:not(:last-child) {
    margin-right: .5rem
}

@media (min-width:459px) {
    .text-wrap .example .form-group {
        margin-bottom: 0
    }
}

.vtimeline {
    list-style: none;
    padding: 0;
    position: relative;
    margin-bottom: 20px
}

.vtimeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #d1d4e4;
    left: 50%;
    margin-left: -1.5px
}

.vtimeline .timeline-wrapper {
    display: block;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    padding-right: 90px
}

.vtimeline .timeline-wrapper:before {
    content: " ";
    display: table
}

.vtimeline .timeline-wrapper:after {
    content: " ";
    display: table;
    clear: both
}

.vtimeline .timeline-wrapper .timeline-panel {
    border-radius: 2px;
    padding: 20px;
    position: relative;
    background: #ecf0fa;
    border-radius: 0;
    box-shadow: none;
    width: 35%;
    margin-left: 15%
}

.vtimeline .timeline-wrapper .timeline-panel:before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 2px;
    content: "";
    left: 0;
    right: 0
}

.vtimeline .timeline-wrapper .timeline-panel:after {
    position: absolute;
    top: 10px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #ecf0fa;
    border-right: 0 solid #ecf0fa;
    border-bottom: 14px solid transparent;
    content: " "
}

.vtimeline .timeline-wrapper .timeline-panel .timeline-title {
    font-size: 1rem;
    font-weight: 700;
    margin: 0 0 .625rem
}

.vtimeline .timeline-wrapper .timeline-panel .timeline-body p+p {
    margin-top: 5px
}

.timeline-body img {
    max-width: 100%
}

.vtimeline .timeline-wrapper .timeline-panel .timeline-body ul {
    margin-bottom: 0
}

.vtimeline .timeline-wrapper .timeline-panel .timeline-footer span {
    font-size: .6875rem
}

.vtimeline .timeline-wrapper .timeline-badge {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 16px;
    left: 47.8%;
    z-index: 0;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border: 2px solid #fff;
    border-radius: 50%
}

.vtimeline .timeline-wrapper .timeline-badge i {
    color: #fff;
    text-align: center;
    font-size: 25px;
    line-height: 43px;
    margin: 0 auto;
    justify-content: center;
    left: 0;
    right: 0;
    padding: 1px 9px
}

.vtimeline .timeline-wrapper.timeline-inverted {
    padding-right: 0;
    padding-left: 90px
}

.vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
    margin-left: auto;
    margin-right: 15%
}

.vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto
}

.timeline-wrapper-primary .timeline-badge,
.timeline-wrapper-primary .timeline-panel:before {
    background: #078586
}

.timeline-wrapper-secondary .timeline-badge,
.timeline-wrapper-secondary .timeline-panel:before {
    background: #eb6f33
}

.timeline-wrapper-success .timeline-badge,
.timeline-wrapper-success .timeline-panel:before {
    background: #21b18a
}

.timeline-wrapper-info .timeline-badge,
.timeline-wrapper-info .timeline-panel:before {
    background: #01b8ff
}

.timeline-wrapper-warning .timeline-badge,
.timeline-wrapper-warning .timeline-panel:before {
    background: #fbbc0b
}

.timeline-wrapper-danger .timeline-badge,
.timeline-wrapper-danger .timeline-panel:before {
    background: #ee335e
}

.timeline-wrapper-light .timeline-badge,
.timeline-wrapper-light .timeline-panel:before {
    background: #f1f2f9
}

.timeline-wrapper-dark .timeline-badge,
.timeline-wrapper-dark .timeline-panel:before {
    background: #242f48
}

@media (max-width:767px) {
    .timeline .btn {
        font-size: 12px
    }
    .vtimeline:before {
        left: 26px!important
    }
    .vtimeline .timeline-wrapper {
        padding-right: 0!important
    }
    .vtimeline .timeline-wrapper .timeline-badge {
        left: 0!important
    }
    .vtimeline .timeline-wrapper .timeline-panel {
        width: auto!important;
        margin-left: 16%!important
    }
    .vtimeline .timeline-wrapper .timeline-panel:after {
        border-right: 14px solid #ecf0fa!important;
        border-left: 0 solid #ecf0fa!important;
        left: -14px!important;
        right: auto!important
    }
    .vtimeline .timeline-wrapper.timeline-inverted {
        padding-left: 0!important
    }
    .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
        margin-right: 0!important;
        width: auto!important
    }
    .profile-user .p-text {
        display: none
    }
}

.latest-timeline-1 ul.timeline-1 {
    list-style-type: none;
    position: relative
}

.latest-timeline-1 ul.timeline-1:before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    border-left: 2px dotted #c0ccda
}

.latest-timeline-1 ul.timeline-1>li {
    margin: 25px 0;
    padding-left: .9rem
}

.latest-timeline-1 ul.timeline-1>li a {
    color: #000
}

.latest-timeline-1 ul.timeline-1>li .date {
    font-size: 10px
}

.latest-timeline-1 ul.timeline-1>li p {
    font-size: 13px
}

.latest-timeline-1 ul.timeline-1>li .product-icon {
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    left: 1px;
    background-color: #f3f6f7;
    font-size: 20px;
    line-height: 37px;
    font-size: 21px
}

.latest-timeline-1 ul.timeline-1>li:nth-child(2):before {
    border: 3px solid #fb1c52
}

.latest-timeline-1 ul.timeline-1>li:nth-child(3):before {
    border: 3px solid #e9b307
}

.latest-timeline-1 ul.timeline-1>li:nth-child(4):before {
    border: 3px solid #4481eb
}

.latest-timeline-1 ul.timeline-1>li:last-child:before {
    border: 3px solid #27af06
}

.projects-stat .table-bordered td,
.projects-stat .table-bordered th {
    border: transparent
}

.projects-stat .table td,
.projects-stat .table th {
    padding: 7px 7px!important;
    vertical-align: baseline
}

.projects-stat .project-names h6 {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    color: #fff;
    line-height: 2.5;
    margin-bottom: 0;
    font-weight: 600;
    padding: 3px
}

.review-project .project-contain {
    display: inline-block
}

.review-project .table td,
.review-project .table th {
    vertical-align: middle;
    padding: 7px 15px
}

.review-project.card tbody>tr>td:not(:first-child),
.review-project.card tbody>tr>th:not(:first-child) {
    font-family: roboto;
    font-size: 13px!important
}

.review-project img {
    vertical-align: top;
    width: 35px;
    min-width: 32px;
    height: 35px
}

.latest-tasks .tasks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    margin: 0 0 1.15rem 0
}

.latest-tasks .tasks .span {
    margin-left: 1.3rem;
    font-weight: 500;
    color: #242f48
}

.latest-tasks .task-line:before {
    position: absolute;
    display: block;
    width: .2rem;
    top: .2rem;
    content: "";
    border-radius: .2rem;
    height: 2rem
}

.latest-tasks .label {
    color: #031b4e;
    margin-left: 1.3rem;
    font-weight: 500
}

.latest-tasks .time {
    margin-left: 1.3rem;
    font-size: 13px;
    color: #737f9e
}

.latest-tasks .task-line.primary:before {
    background: #078586
}

.latest-tasks .task-line.pink:before {
    background: #f10075
}

.latest-tasks .task-line.success:before {
    background: #21b18a
}

.latest-tasks .task-line.teal:before {
    background: #0cc
}

.latest-tasks .task-line.warning:before {
    background: #fbbc0b
}

.latest-tasks .task-line.orange:before {
    background: #fd7e14
}

.latest-tasks .task-line.info:before {
    background: #00b9ff
}

.latest-tasks .task-line.purple:before {
    background: #673ab7
}

.latest-tasks .task-line.danger:before {
    background: #ee335e
}

.latest-tasks .check-box .ckbox span:before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: rgba(190, 206, 255, .05);
    border: 1px solid #d9e2ff;
    top: 1px;
    left: 0;
    border-radius: 2px
}

.latest-tasks .check-box .ckbox span:after {
    border-radius: 2px
}

.latest-tasks .nav-tabs .nav-link {
    color: #737f9e
}

.latest-tasks .nav-tabs .nav-link.active,
.latest-tasks .nav-tabs .nav-link:focus,
.latest-tasks .nav-tabs .nav-link:hover {
    color: #078586;
    border: 0
}

.image-grouped img {
    margin-right: -.6em!important
}

.background-text {
    position: relative;
    z-index: 10
}

.project-countdown #launch_date {
    margin: 0
}

.project-countdown .countdown {
    padding: 0;
    margin: 0
}

.project-countdown .countdown li {
    width: 65px;
    height: 65px;
    z-index: 0;
    padding: 0;
    line-height: 30px
}

.project-countdown .countdown .number {
    font-size: 20px;
    font-weight: 700;
    line-height: 14px;
    padding-top: 11px
}

.project-countdown .countdown .time {
    padding-top: 0;
    font-size: 11px!important
}

.project-countdown .countdown span {
    display: block;
    color: #fff
}

.project-countdown .countdown li {
    background: #285cf7;
    border: 5px solid #285cf7
}

@media (min-width:992px) {
    .project-countdown .countdown li {
        margin: 2px 5px 7px 5px
    }
}

.Activity-scroll {
    max-height: 349px
}

.activity .added-project {
    font-weight: 700;
    font-size: 12px;
    color: #078586
}

.sparkline .tooltip {
    width: 20px;
    height: 20px
}

.Project-scroll {
    max-height: 367px;
    position: relative
}

.rating-scroll {
    max-height: 344px;
    position: relative
}

.btn-group-sm>.btn,
.btn-sm {
    padding: .2rem .7rem;
    font-size: .7rem
}

.coming-events .icon {
    height: 47px;
    width: 47px;
    border-radius: 50%;
    text-align: center
}

.coming-events .icon span {
    display: block;
    font-size: 12px
}

.coming-events .icon .date {
    line-height: 10px;
    padding-top: 11px
}

.coming-events .icon .month {
    font-size: 11px
}

.coming-events .pb-3 {
    padding-bottom: .8rem!important
}

.coming-events .pt-3 {
    padding-top: .8rem!important
}

.activity {
    position: relative;
    padding: 1.25rem 1.25rem 0 1.25rem
}

.activity-list {
    position: relative
}

.activity-list:before {
    content: ' ';
    border: 1px solid #eaf2f9;
    margin: 20px 20px 0 22px;
    display: inline-block;
    position: absolute;
    width: 2px;
    height: 100%;
    z-index: 0
}

.activity .img-activity {
    width: 42px;
    height: 42px;
    text-align: center;
    line-height: 34px;
    border-radius: 50%;
    position: absolute;
    -webkit-box-shadow: 0 0 0 .5px #f8f9fa;
    box-shadow: 0 0 0 .5px #f8f9fa
}

.activity .item-activity {
    margin-left: 60px;
    margin-bottom: 22px
}

.timeline-image {
    border-radius: 50%
}

@media (max-width:576px) {
    .vtimeline .timeline-wrapper .timeline-panel {
        width: auto!important;
        margin-left: 24%!important
    }
}

.main-toggle {
    width: 60px;
    height: 25px;
    background-color: #b9c2d8;
    padding: 2px;
    position: relative;
    overflow: hidden
}

.main-toggle span {
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    display: block;
    width: 20px;
    background-color: #fff;
    cursor: pointer;
    transition: all .2s ease-in-out
}

.main-toggle span::after,
.main-toggle span::before {
    position: absolute;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: .5px;
    text-transform: uppercase;
    color: #fff;
    top: 2px;
    line-height: 1.38
}

.main-toggle span::before {
    content: 'on';
    left: -25px
}

.main-toggle span::after {
    content: 'off';
    right: -29px
}

.main-toggle.on {
    background-color: #078586
}

.main-toggle.on span {
    left: 37px
}

@media (prefers-reduced-motion:reduce) {
    .main-toggle span {
        transition: none
    }
}

.main-toggle-secondary.on {
    background-color: #078586
}

.main-toggle-success.on {
    background-color: #21b18a
}

.main-toggle-dark.on {
    background-color: #242f48
}

.main-toggle-group-demo {
    display: flex
}

.main-toggle-group-demo .main-toggle+.main-toggle {
    margin-left: 10px
}

:root {
    --blue: $primary;
    --indigo: $primary;
    --purple: $purple;
    --pink: $pink;
    --red: $danger;
    --orange: $orange;
    --yellow: $warning;
    --green: $success;
    --teal: $teal;
    --cyan: $info;
    --white: $white;
    --gray: $gray-600;
    --gray-dark: $gray-800;
    --primary: $primary;
    --secondary: $gray-600;
    --success: $success;
    --info: $info;
    --warning: $warning;
    --danger: $danger;
    --light: $gray-100;
    --dark: $gray-800;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

* {
    box-sizing: border-box
}

::after,
::before {
    box-sizing: border-box
}

#global-loader {
    position: fixed;
    z-index: 50000;
    background: #fff;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    text-align: center
}

.loader-img {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 43%;
    left: 0;
    margin: 0 auto;
    text-align: center
}

.fade {
    transition: opacity .15s linear
}

.fade:not(.show) {
    opacity: 0
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }
    to {
        background-position: 0 0
    }
}

.media {
    display: flex;
    align-items: flex-start
}

.media-body {
    flex: 1
}

.close {
    float: right;
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5
}

.close:hover {
    color: #000;
    text-decoration: none
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
    opacity: .75
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none
}

a.close.disabled {
    pointer-events: none
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        opacity: 0
    }
    50% {
        -webkit-transform: scale(.2);
        opacity: 1
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow .75s linear infinite
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
}

.embed-responsive::before {
    display: block;
    content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%
}

.embed-responsive-16by9::before {
    padding-top: 56.25%
}

.embed-responsive-4by3::before {
    padding-top: 75%
}

.embed-responsive-1by1::before {
    padding-top: 100%
}

.overflow-auto {
    overflow: auto!important
}

.overflow-hidden {
    overflow: hidden!important
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

@supports (position:sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175)!important
}

.shadow-none {
    box-shadow: none!important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0)
}

@media print {
    * {
        text-shadow: none!important;
        box-shadow: none!important
    }
     ::after,
     ::before {
        text-shadow: none!important;
        box-shadow: none!important
    }
    a:not(.btn) {
        text-decoration: underline
    }
    abbr[title]::after {
        content: " ("attr(title) ")"
    }
    pre {
        white-space: pre-wrap!important;
        border: 1px solid #949eb7;
        page-break-inside: avoid
    }
    blockquote {
        border: 1px solid #949eb7;
        page-break-inside: avoid
    }
    thead {
        display: table-header-group
    }
    img,
    tr {
        page-break-inside: avoid
    }
    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }
    h2,
    h3 {
        page-break-after: avoid
    }
    @page {
        size: a3
    }
    .container,
    body {
        min-width: 992px!important
    }
}

body {
    font-family: Roboto, sans-serif
}

.main-body,
.main-dashboard {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative
}

.main-body::before {
    content: 'main';
    position: fixed;
    top: 45%;
    left: 70%;
    transform: translate3d(-50%, -58%, 0);
    font-size: 1300px;
    font-weight: 600;
    letter-spacing: -10px;
    line-height: .5;
    opacity: .02;
    z-index: -1;
    display: none
}

.main-table-reference {
    background-color: #fff
}

.main-table-reference>thead>tr>td,
.main-table-reference>thead>tr>th {
    padding: 8px 10px;
    border: 1px solid #e3e8f7;
    font-size: 13px
}

.main-table-reference>tbody>tr>td,
.main-table-reference>tbody>tr>th {
    padding: 8px 10px;
    border: 1px solid #e3e8f7;
    font-size: 13px
}

.main-table-reference>thead>tr>td,
.main-table-reference>thead>tr>th {
    background-color: #ecf0fa;
    color: #737f9e;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 0
}

.main-home-slider {
    position: relative;
    z-index: 4;
    width: 100%;
    height: 600px;
    max-height: 1800px;
    overflow: hidden;
    background-color: #1904be
}

.main-home-slider .chart-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -20px;
    z-index: 3;
    display: flex;
    align-items: flex-end;
    opacity: .2
}

.main-home-slider .chart-wrapper .flot-chart {
    width: 100%;
    height: 400px
}

.main-home-slider .card-columns {
    column-gap: 5px;
    column-count: 3;
    width: 500px;
    transform: rotate(37deg);
    position: absolute;
    top: 9%;
    left: 24%;
    z-index: 4;
    opacity: .25
}

.main-home-slider .card-columns .card {
    position: relative;
    border-width: 0;
    background-color: transparent;
    margin-bottom: 5px
}

@media (min-width:576px) {
    .main-home-slider {
        height: 600px
    }
}

@media (min-width:1200px) {
    .main-home-slider {
        height: 100vh;
        max-height: 780px
    }
}

@media (min-width:1440px) {
    .main-home-slider {
        max-height: 900px
    }
}

@media (min-width:1680px) {
    .main-home-slider {
        max-height: 1050px
    }
}

@media (min-width:1920px) {
    .main-home-slider {
        max-height: 1200px
    }
}

@media (min-width:2560px) {
    .main-home-slider {
        max-height: 1600px
    }
}

@media (min-width:2880px) {
    .main-home-slider {
        max-height: 1800px
    }
}

@media (min-width:768px) {
    .main-home-slider .chart-wrapper {
        opacity: 1
    }
}

@media (min-width:992px) {
    .main-home-slider .chart-wrapper .flot-chart {
        height: 500px
    }
    .userlist-table .table {
        white-space: nowrap!important
    }
    .nav-link#bs-example-navbar-collapse-1 {
        display: none
    }
}

@media (min-width:576px) {
    .main-home-slider .card-columns {
        width: 120%;
        left: 16%;
        transform: rotate(44deg)
    }
}

@media (min-width:768px) {
    .main-home-slider .card-columns {
        transform: rotate(45deg);
        width: 100%;
        top: 0;
        left: auto;
        right: -45%;
        opacity: 1
    }
}

@media (min-width:992px) {
    .main-home-slider .card-columns {
        width: 80%;
        top: -10%;
        right: -30%;
        column-gap: 10px
    }
}

@media (min-width:1200px) {
    .main-home-slider .card-columns {
        width: 70%;
        right: -15%;
        top: -15%
    }
}

@media (min-width:1300px) {
    .main-home-slider .card-columns {
        right: -5%
    }
}

@media (min-width:992px) {
    .main-home-slider .card-columns .card {
        margin-bottom: 10px
    }
}

.main-home-content {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 5;
    color: rgba(255, 255, 255, .7);
    right: 20px
}

.main-home-content .main-logo {
    font-size: 30px;
    font-weight: 700;
    display: flex;
    line-height: 1;
    color: #fff;
    margin-bottom: 5px;
    text-indent: -1px;
    color: #fff;
    letter-spacing: -.5px
}

.main-home-content h5 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .2px;
    margin-bottom: 25px
}

.main-home-content .logo-group {
    margin-bottom: 60px;
    display: flex
}

.main-home-content .logo-group i {
    font-weight: 400;
    font-size: 32px;
    line-height: .2
}

.main-home-content .logo-group i+i {
    margin-left: 10px
}

.main-home-content h1 {
    color: #fff;
    font-weight: 300;
    font-size: 24px;
    margin-bottom: 20px
}

.main-home-content p:first-of-type {
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 25px
}

.main-home-content p:last-of-type {
    display: flex;
    margin-bottom: 100px
}

.main-home-content p:last-of-type .btn,
.main-home-content p:last-of-type .sp-container button {
    flex: 1;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    min-height: inherit;
    letter-spacing: 1px;
    padding: 10px 15px
}

@media (min-width:576px) {
    .main-home-content {
        top: 40px;
        left: 40px;
        right: auto;
        width: 400px
    }
}

@media (min-width:768px) {
    .main-home-content {
        width: 410px
    }
}

@media (min-width:576px) {
    .main-home-content .main-logo {
        font-size: 36px;
        letter-spacing: -1px
    }
}

@media (min-width:576px) {
    .main-home-content h5 {
        font-size: 15px
    }
}

@media (min-width:576px) {
    .main-home-content h1 {
        font-size: 2.03125rem
    }
}

.sp-container .main-home-content p:last-of-type button {
    flex: 1;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    min-height: inherit;
    letter-spacing: 1px;
    padding: 10px 15px
}

@media (min-width:768px) {
    .main-home-content p:last-of-type .btn,
    .main-home-content p:last-of-type .sp-container button {
        padding: 10px 25px;
        flex: none
    }
    .sp-container .main-home-content p:last-of-type button {
        padding: 10px 25px;
        flex: none
    }
}

@media (min-width:992px) {
    .main-home-content p:last-of-type .btn,
    .main-home-content p:last-of-type .sp-container button {
        flex: 1
    }
    .sp-container .main-home-content p:last-of-type button {
        flex: 1
    }
}

.main-home-content p:last-of-type .btn+.btn,
.main-home-content p:last-of-type .sp-container button+.btn {
    margin-left: 5px
}

.main-home-content p:last-of-type .sp-container .btn+button,
.main-home-content p:last-of-type .sp-container button+button,
.sp-container .main-home-content p:last-of-type .btn+button,
.sp-container .main-home-content p:last-of-type button+.btn,
.sp-container .main-home-content p:last-of-type button+button {
    margin-left: 5px
}

.main-home-content nav:last-child {
    display: flex
}

.main-home-content nav:last-child a {
    font-size: 24px;
    color: rgba(255, 255, 255, .5)
}

.main-home-content nav:last-child a:focus,
.main-home-content nav:last-child a:hover {
    color: #fff
}

.main-home-content nav:last-child a+a {
    margin-left: 8px
}

.main-icon-group {
    text-align: center;
    padding: 20px;
    color: #364261
}

.font-awesome.main-icon-group i {
    font-size: 18px
}

.main-icon-group i {
    line-height: 1;
    font-size: 24px;
    font-size: 18px
}

.main-page-header {
    position: relative;
    padding: 30px 0 40px;
    border-bottom: 1px solid #dee2ef;
    margin-bottom: 40px
}

.main-page-title {
    font-weight: 700;
    font-size: 40px;
    font-family: Raleway, sans-serif;
    margin-bottom: 15px;
    line-height: 1;
    letter-spacing: -1.5px
}

.main-page-text {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 30px
}

.main-page-content-list {
    margin: 0;
    padding: 0;
    list-style: none
}

.brround {
    border-radius: 50%
}

.main-page-content-list li {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500
}

.main-page-content-list li+li {
    margin-top: 5px
}

.main-page-content-list li i {
    margin-right: 10px;
    font-size: 10px;
    line-height: 0;
    color: #949eb7;
    text-indent: 1px
}

.main-page-label {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    letter-spacing: -.2px
}

.main-syntax-wrapper {
    position: relative
}

.main-syntax-wrapper .badge {
    padding: 4px 5px;
    position: absolute;
    top: 5px;
    right: 5px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 9px;
    letter-spacing: normal;
    border-radius: 1px
}

.main-syntax-wrapper pre {
    border-color: #d0d7e8;
    background-color: #fff;
    padding: 15px;
    font-size: 13px
}

.main-syntax-wrapper code[class*=language-],
.main-syntax-wrapper pre[class*=language-] {
    font-family: 'Roboto Mono', monospace
}

.horizontal-nav .mega-dropdown-menu {
    box-shadow: 0 25px 100px rgba(115, 77, 191, .2);
    border: 0;
    margin: -2px;
    width: 100%
}

.horizontal-nav .mega-dropdown-menu:before {
    content: '';
    position: absolute;
    top: -10px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    right: auto;
    left: 16px
}

.mega-dropdown-menu:after {
    right: auto;
    left: 16px
}

.horizontal-nav .dropdown-menu {
    min-width: 12.5rem
}

.drop-icon-wrap .drop-icon-item {
    display: inline-block;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
    color: #020202;
    text-transform: capitalize;
    width: 33.3%;
    float: left
}

.h-6 {
    height: 2rem!important
}

.dropgroupicons .dropdown-menu {
    width: 260px!important;
    padding: 5px!important;
    top: 52px!important;
    border-radius: 6px;
    -webkit-box-shadow: 0 0 15px 1px rgba(69, 65, 78, .2);
    box-shadow: 0 0 15px 1px rgba(69, 65, 78, .2)
}

.flag .dropdown-menu {
    top: 52px!important;
    border-radius: 6px;
    -webkit-box-shadow: 0 0 15px 1px rgba(69, 65, 78, .2);
    box-shadow: 0 0 15px 1px rgba(69, 65, 78, .2)
}

.flag>a::before {
    content: '';
    position: absolute;
    top: 47px;
    left: 50%;
    margin-left: -7px;
    width: 14px;
    height: 14px;
    border: 2px solid transparent;
    border-top-color: #d0d7e8;
    border-left-color: #d0d7e8;
    transform: rotate(45deg);
    background-color: #fff;
    z-index: 901;
    display: none
}

.dropdown-toggle-1::after {
    top: 10px;
    right: 9px;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    content: '\f3d0';
    font-family: Ionicons;
    position: absolute;
    display: inline-block;
    color: #7a8eb1
}

.dropdown-toggle-2::after {
    top: 13px;
    right: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    content: '\f3d0';
    font-family: Ionicons;
    position: absolute;
    display: inline-block;
    color: #7a8eb1
}

.dropgroupicons .nav-link.icon i {
    font-size: 21px
}

.drop-icon-wrap a:hover {
    background: #eff2f6
}

.drop-icon-wrap .drop-icon-item .drop-font {
    font-size: 12px
}

.country-Flag img {
    width: 1.5rem;
    height: 1rem;
    line-height: .1rem;
    font-size: .75rem
}

.dropdown.flag .dropdown-item span {
    font-size: 14px
}

.btn.btn-default.nav-link {
    height: 38px;
    width: 38px;
    text-align: center;
    border-radius: 50%;
    line-height: 1;
    font-size: 17px;
    padding: 1px
}

.d-md-block.nav.nav-item.nav-link {
    padding: .5rem .7rem
}

.mega-dropdown-menu {
    width: 65rem;
    padding: .7rem .8rem .5rem .8rem
}

.mega-dropdown-menu .dropdown-item {
    padding: .5rem 1rem
}

.mega-dropdown-menu .dropdown-item:hover {
    color: #078586;
    background: 0 0
}

.btn-absolute {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: 12px;
    padding: 20px
}

.arrow-ribbon {
    padding: 6px 8px;
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 999;
    font-size: 14px;
    line-height: 17px;
    background: #000;
    color: #fff
}

.arrow-ribbon:before {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    right: -15px;
    border-top: 14px solid transparent;
    border-left: 15px solid #000;
    border-bottom: 15px solid transparent;
    width: 0
}

.header-megamenu-dropdown .nav-item .btn-link {
    color: #031b4e
}

.wordpress-project {
    border-radius: 5px;
    width: 55px;
    height: 55px
}

.mega-dropdown-menu .media-body .progress-bar {
    height: 4px
}

.header-megamenu-dropdown .dropdown-menu {
    top: 8px!important
}

.mega-menu:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 14px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent
}

.mega-menu:after {
    content: '';
    position: absolute;
    top: -10px;
    left: 14px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #c9cddb transparent;
    border-color: transparent transparent rgba(64, 82, 128, .9) transparent;
    z-index: -99
}

.drop-menu-header {
    padding: 1.5rem;
    color: #fff
}

.dropdown-menu .dropdown-header {
    text-transform: uppercase;
    font-size: .73333333rem;
    color: #3f6ad8;
    font-weight: 700
}

.flag-text {
    color: #031b4e;
    font-weight: 400
}

.flag-text:before {
    content: '\f3d0';
    font-family: Ionicons;
    font-weight: 400;
    font-size: 12px;
    position: absolute;
    top: 10px;
    right: 0;
    color: #031b4e
}

.drop-flag {
    margin-right: 2.3rem!important
}

.flag-notification a {
    display: block;
    font-size: 22px;
    color: #242f48;
    position: relative;
    line-height: 1.5;
    outline: 0;
    padding: .5rem .5rem
}

.flag-img {
    width: 2rem;
    height: 1.3rem
}

.drop-flag .dropdown-menu {
    width: 150px;
    position: absolute;
    top: 52px;
    left: auto;
    right: -10px;
    bottom: auto;
    padding: 10px 10px 10px;
    border-color: #d0d7e8;
    border-width: 2px
}

.drop-flag .dropdown-menu .dropdown-item {
    font-size: 14px
}

.setting-menu-footer li {
    list-style-type: none
}

.setting-menu-btn {
    padding: .5rem 1rem
}

.setting-menu-footer .divider {
    margin: .5rem 0;
    height: 1px;
    overflow: hidden;
    background: #dee2e6
}

.setting-menu .dropdown-item {
    padding: .4rem 1.5rem
}

.setting-scroll {
    max-height: 162px;
    position: relative
}

.header-img {
    background: linear-gradient(rgba(109, 110, 243, .7), rgba(109, 110, 243, .7)), url("../img/20.jpg");
    background-size: cover;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 20px 0 20px 0
}

#dropdownMenuButton2 .badge-pill {
    padding: 5px 7px;
    font-size: 10.5px
}

.bx-flip-horizontal {
    transform: scaleX(-1)
}

.bx-tada {
    -webkit-animation: tada 1.5s ease infinite;
    animation: tada 1.5s ease infinite
}

.drop-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    position: relative
}

.dropdown-item .content {
    margin-left: 15px;
    width: 200px;
    white-space: normal
}

.avatar-status {
    content: '';
    position: absolute;
    bottom: 0;
    right: 5px;
    width: 6px;
    height: 6px;
    background-color: #949eb7;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, .95);
    border-radius: 100%;
    bottom: 4px
}

.user-pro-body .profile-status {
    position: absolute;
    display: block;
    bottom: 0;
    right: 98px;
    width: 8px;
    height: 8px;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, .95);
    border-radius: 100%;
    top: 73px;
    background: #21b18a
}

.user-pro-body .profile-status:before {
    content: '';
    top: -6px;
    right: -6px;
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background: rgba(34, 192, 60, .5);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(34, 192, 60, .9);
    animation: pulse 1s linear infinite
}

.pulse {
    display: block;
    position: absolute;
    top: 7px;
    right: 8px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #21b18a
}

.pulse:before {
    content: '';
    top: -4px;
    right: -4px;
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    background: rgba(34, 192, 60, .6);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(34, 192, 60, .9);
    animation: pulse 1s linear infinite
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0
    }
    8% {
        -webkit-transform: scale(0);
        opacity: 0
    }
    15% {
        -webkit-transform: scale(.1);
        opacity: 1
    }
    30% {
        -webkit-transform: scale(.5);
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1.1)
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0
    }
    8% {
        -webkit-transform: scale(0);
        opacity: 0
    }
    15% {
        -webkit-transform: scale(.1);
        opacity: 1
    }
    30% {
        -webkit-transform: scale(.5);
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1.1)
    }
}

@-moz-keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0
    }
    8% {
        -webkit-transform: scale(0);
        opacity: 0
    }
    15% {
        -webkit-transform: scale(.1);
        opacity: 1
    }
    30% {
        -webkit-transform: scale(.5);
        opacity: 1
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1.1)
    }
}

.pulse-danger {
    display: block;
    position: absolute;
    top: 7px;
    right: 8px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #ee335e
}

.pulse-danger:before {
    content: '';
    top: -4px;
    right: -4px;
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    background: rgba(238, 51, 94, .8);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(238, 51, 94, .9);
    animation: pulse 1s linear infinite
}

.main-message-list a .desc {
    font-size: 13px;
    color: #535384;
    margin-left: 10px
}

.main-message-list a .name {
    font-size: .9rem;
    transition: all .2s ease-in-out;
    border-radius: 2px;
    color: #031b4e;
    margin-left: 10px
}

.main-message-list a .time {
    font-size: 11px;
    color: #8d8db3;
    font-weight: 400
}

.menu-header-content {
    padding: 1.2rem;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px
}

.Notification-scroll,
.chat-scroll {
    max-height: 291px;
    position: relative
}

.cover-image {
    background-size: cover!important
}

.notifyimg {
    border-radius: 50%
}

.notifyimg i {
    color: #000;
    float: left;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    width: 40px;
    font-size: 21px
}

.notification-label {
    font-size: 13.5px;
    transition: all .2s ease-in-out;
    border-radius: 2px;
    color: #031b4e
}

.notification-label:hover {
    color: #078586
}

.main-message-list .name:hover {
    color: #078586
}

.notification-subtext {
    font-size: 12px;
    color: #4d5875
}

.box-shadow-primary {
    box-shadow: 0 5px 10px rgba(40, 92, 247, .25)
}

.box-shadow-success {
    box-shadow: 0 5px 10px rgba(59, 176, 1, .25)
}

.box-shadow-warning {
    box-shadow: 0 5px 10px rgba(255, 193, 7, .25)
}

.box-shadow-danger {
    box-shadow: 0 5px 10px rgba(220, 53, 69, .25)
}

.box-shadow-pink {
    box-shadow: 0 5px 10px rgba(241, 0, 117, .25)
}

.main-notification-list .las {
    font-size: 13px
}

.main-notification-list a:hover {
    background: #f6f6fb;
    color: #45567b
}

.main-message-list a:hover {
    background: #f6f6fb;
    color: #45567b
}

.page-header-icon i {
    margin: 0 auto;
    color: #078586;
    text-shadow: -2px 2px 2px rgba(34, 5, 191, .2)
}

.nav-badge {
    margin-left: auto!important;
    z-index: 9;
    padding: 5px 7px;
    font-size: 10.5px;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .09), 0 1px 3px rgba(0, 0, 0, .08);
    position: absolute;
    right: 12px
}

.nav-text-badge {
    margin-left: auto!important;
    z-index: 9;
    position: absolute;
    right: 7px
}

.widget-icons {
    height: 45px;
    width: 45px;
    padding: 7px;
    border-radius: 50%;
    font-size: 23px;
    text-align: center;
    line-height: 28px
}

.widget-icons i {
    margin: 0 auto;
    text-align: center;
    align-items: center
}

.line-list {
    padding-left: 30px;
    margin-bottom: 0;
    position: relative;
    list-style-type: none
}

.line-list li:before {
    content: "";
    position: absolute;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    left: 0;
    border: 3px solid #078586;
    margin-right: 15px;
    z-index: 2;
    background: #fff
}

.line-list li:after {
    content: "";
    border: 1px solid #e6edf7;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%
}

.image-grouped .profile-img {
    width: 30px;
    height: 30px
}

.decrease i,
.increase i {
    height: 35px;
    width: 35px;
    margin: 0 auto;
    text-align: center;
    line-height: 35px;
    font-size: 16px
}

.sales-bar {
    min-height: 180px!important;
    position: relative;
    bottom: -21px
}

.progress-sm {
    height: 4px
}

.expansion-value strong:first-child {
    color: #242f48
}

.expansion-value strong:last-child {
    color: #737f9e
}

.card-chart {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 3px
}

.sales-details {
    position: absolute;
    width: 100%
}

.table-dashboard-one {
    width: 100%;
    font-size: 13px
}

.table-dashboard-one td {
    vertical-align: top;
    line-height: 1;
    padding: 9px 0
}

.table-dashboard-one td:first-child {
    padding-left: 0
}

.table-dashboard-one td:last-child {
    color: #8392a5
}

.table-dashboard-one tr+tr td {
    border-top: 1px solid #e5e9f2
}

.browser-stats i {
    height: 32px;
    width: 32px;
    text-align: center;
    border-radius: 50%;
    line-height: 32px
}

.browser-stats .item {
    padding: 11px 1rem
}

.browser-stats h6 {
    font-size: 13px;
    margin-bottom: 2px
}

.browser-stats .sub-text {
    display: block;
    color: #949eb7;
    font-size: 10.6px
}

.sales-card .badge {
    border-radius: 30px;
    background-color: #c6d8ff;
    padding: 4px 15px;
    color: #4680ff;
    font-weight: 600
}

.sales-card .sales-icon {
    color: #fff;
    font-size: 18px;
    padding: 40px 40px 20px 20px;
    border-radius: 50%;
    position: absolute;
    top: -30px;
    right: -30px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.sales-card:hover .sales-icon {
    font-size: 25px
}

.aligner-wrapper {
    position: relative
}

.aligner-wrapper .absolute.absolute-center {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    position: absolute
}

.media img {
    width: 35px;
    height: 35px
}

.sales-dash-header {
    position: absolute;
    left: 20px;
    right: 20px;
    padding: 0;
    background-color: transparent;
    z-index: 5
}

.sales-session {
    margin-left: 0;
    padding-left: 0
}

.sales-session li {
    list-style-type: none;
    margin-bottom: 1.2rem
}

.stats .icon {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 45px;
    border-radius: 3px
}

.stats .icon i {
    font-size: 20px
}

.ecommerce-card .icons {
    height: 50px;
    width: 50px;
    line-height: 63px;
    border-radius: 50px;
    text-align: center;
    background: #e9eefe
}

.ecommerce-card .icons.primary {
    background: rgba(0, 0, 0, .2)
}

.ecommerce-card .icons.primary i {
    color: rgba(255, 255, 255, .8)!important
}

.ecommerce-card .icons i {
    color: rgba(0, 0, 0, .5);
    font-size: 30px
}

.ecommerce-card .icons.pink {
    background: rgba(241, 0, 117, .08)!important;
    color: #f10075
}

.ecommerce-card .icons.pink i {
    color: #f10075;
    opacity: .8
}

.ecommerce-card:hover .icons.pink {
    background: #f10075!important
}

.ecommerce-card:hover .icons.pink i {
    color: #fff;
    opacity: 1
}

.ecommerce-card .icons.success {
    background: rgba(34, 192, 60, .1);
    color: #21b18a
}

.ecommerce-card .icons.success i {
    color: #21b18a;
    opacity: .8
}

.ecommerce-card:hover .icons.success {
    background: #21b18a!important
}

.ecommerce-card:hover .icons.success i {
    color: #fff;
    opacity: 1
}

.ecommerce-card .icons.warning {
    background: rgba(255, 193, 7, .12);
    color: #fbbc0b
}

.ecommerce-card .icons.warning i {
    color: #fbbc0b;
    opacity: .8
}

.ecommerce-card:hover .icons.warning {
    background: #fbbc0b!important
}

.ecommerce-card:hover .icons.warning i {
    color: #fff;
    opacity: 1
}

.product-timeline ul.timeline-1 {
    list-style-type: none;
    position: relative
}

.product-timeline .name {
    color: #031b4e;
    font-size: 15px
}

.product-timeline ul.timeline-1:before {
    content: ' ';
    margin: 20px 20px 0 22px;
    display: inline-block;
    position: absolute;
    left: -2px;
    height: 94%;
    z-index: 0;
    content: '';
    position: absolute;
    bottom: 0;
    border-left: 2px dotted #c0ccda
}

.product-timeline ul.timeline-1>li {
    margin: 20.7px 0;
    padding-left: 1rem
}

.product-timeline ul.timeline-1>li .product-icon {
    width: 37px;
    height: 37px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    left: 3px;
    background-color: #f3f6f7;
    font-size: 20px;
    line-height: 37px;
    font-size: 17px
}

.font-weight-semibold {
    font-weight: 500!important
}

.bounceIn {
    -webkit-animation-duration: .75s;
    animation-duration: .75s;
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn
}

@keyframes bounceIn {
    20%,
    40%,
    60%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }
    40% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03)
    }
    80% {
        -webkit-transform: scale3d(.97, .97, .97);
        transform: scale3d(.97, .97, .97)
    }
    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.order-list .list {
    padding-left: 0
}

.order-list .list .list-item:first-child {
    padding-top: 0
}

.order-list .list.list-noborders .list-item {
    border: none
}

.order-list .list .list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 0 19px 0
}

.order-list .list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #dee2e6
}

.top-selling-product img {
    border-radius: 6px
}

.top-selling-product .table td,
.top-selling-product .table th {
    padding: 7px 7px 7px 13px!important;
    vertical-align: baseline
}

.top-selling-product .table-bordered thead td,
.top-selling-product .table-bordered thead th {
    border-top-width: 1px;
    padding-top: 11px!important;
    padding-bottom: 11px!important
}

.out-of-stock {
    position: relative
}

.out-of-stock:before {
    position: absolute;
    content: '';
    background: rgba(239, 242, 246, .8)!important;
    top: 0;
    left: 0;
    width: 43px;
    min-width: 43px;
    height: 43px;
    bottom: 0;
    background-size: cover;
    display: block
}

.top-selling-product .text-default {
    color: #cdd4e8!important
}

.order-list img {
    border-radius: 6px
}

.traffic-resource .table td,
.traffic-resource .table th {
    padding: 15px 0!important;
    border-top: 0;
    border-bottom: 1px solid #e3e8f7
}

.traffic-resource h6 {
    margin-bottom: 0
}

.traffic-resource .table-hover tbody tr:hover {
    color: #031b4e;
    background-color: transparent
}

@keyframes ani {
    0% {
        transform: scale(1)
    }
    25% {
        transform: scale(1)
    }
    30% {
        transform: scale(1.4)
    }
    50% {
        transform: scale(1.2)
    }
    70% {
        transform: scale(1.4)
    }
    90% {
        transform: scale(1)
    }
    100% {
        transform: scale(1)
    }
}

.row-cards>.col,
.row-cards>[class*=col-] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.row-deck>.col,
.row-deck>[class*=col-] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: stretch;
    align-items: stretch
}

.row-deck>.col .card,
.row-deck>[class*=col-] .card {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.semi-circle {
    background: rgba(234, 239, 254, .5);
    border-radius: 0 0 300px 300px
}

.project-card {
    overflow: hidden
}

.project-card:hover i {
    color: #285cf7;
    opacity: .16
}

.project-card i {
    font-size: 65px;
    margin: 0 24px 0 -30px;
    position: relative;
    opacity: .07;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.project-card .project-content {
    width: 100%
}

.project-card .project-content ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.project-card .project-content ul strong {
    color: #949eb7;
    font-size: 12px;
    font-weight: 400
}

.project-card .project-content ul li {
    line-height: 20px
}

.project-card .project-content ul span {
    color: #323232;
    float: right;
    font-weight: 600;
    text-align: right;
    margin-left: auto
}

.project-card .primary {
    fill: #078586
}

.project-card:hover .primary {
    fill: #078586;
    opacity: 1
}

.project-card .teal,
.project-card:hover .teal {
    fill: #0cc
}

.project-card .pink {
    fill: #f10075
}

.project-card:hover .pink {
    fill: #f10075;
    opacity: 1
}

.project-card .purple {
    fill: #673ab7
}

.project-card:hover .purple {
    fill: #673ab7;
    opacity: 1
}

.area.chart-legend {
    top: 125px
}

.recent-operations-card .operation-icon {
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 0;
    font-size: 18px;
    color: #fff;
    border-radius: 5px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.crypto-scroll {
    max-height: 291px;
    position: relative
}

.transcation-scroll {
    max-height: 400px;
    position: relative
}

.transcation-scroll .list p a {
    color: #4d5875
}

.crypto #flotChart1,
.crypto #flotChart3,
.crypto #flotChart5 {
    position: relative;
    margin: 0 -40px;
    margin-bottom: -40px
}

.crypto .media {
    position: absolute
}

.crypto .card-footer {
    background-color: #fff;
    padding: 0
}

.crypto .card-footer .nav-link:focus,
.crypto .card-footer .nav-link:hover {
    background-color: #eeeff4
}

.crypto .card-footer .nav-link span {
    display: block
}

.crypto .card-footer .nav-link span:first-child {
    color: #949eb7;
    font-weight: 700;
    font-size: 11px
}

.crypto .card-footer .nav-link span:last-child {
    color: #031b4e;
    font-weight: 500;
    font-size: 11px;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.crypto .card-footer .nav-link+.nav-link {
    border-left: 1px solid #e3e8f7
}

.crypto.card-footer .nav-link.active {
    background-color: #fafcff
}

.crypto .card-footer .nav-link {
    display: block;
    padding: .5rem 1.49rem
}

.crypto .card-footer .nav-link.active::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    z-index: 5
}

.crypto .nav-link+.nav-link {
    border-left: 1px solid #e3e8f7
}

.crypto .card-footer .nav-link {
    flex: 1;
    display: block;
    text-align: center;
    background-color: rgba(227, 231, 237, .45);
    position: relative;
    padding-left: 10px;
    padding-right: 10px
}

.crypto .coin-logo {
    width: 42px;
    height: 42px;
    color: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-right: 15px
}

.crypto h6 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 2px;
    color: #242f48
}

.market-values .btn {
    min-height: initial
}

.fs-name {
    font-size: .92rem;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 3px
}

.task-box {
    background-color: #eff2f6;
    margin-bottom: 15px;
    padding: 10px
}

.task-box.pink p,
.task-box.primary p {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    margin-bottom: 5px;
    letter-spacing: .5px
}

.task-box.primary {
    background-color: rgba(217, 232, 254, .6)!important;
    color: #078586!important;
    border: 1px dashed #7da7e4
}

.task-box.pink {
    background-color: rgba(254, 229, 241, .5)!important;
    color: #f10075!important;
    border: 1px dashed #f196c3
}

.task-stat .tasks {
    color: #031b4e;
    padding: .65rem 1.25rem;
    font-weight: 500;
    border-bottom: 1px solid #e7ebf3
}

.svg-icons {
    fill: #031b4e;
    height: 20px
}

.eve-icon {
    height: 20px
}

.ui-bottom-data {
    position: relative;
    bottom: -10px;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 10px rgba(24, 28, 33, .2);
    box-shadow: 0 1px 10px rgba(24, 28, 33, .2)
}

.project-list .list-unstyled li {
    display: flex;
    margin-bottom: 20px;
    padding: 14px;
    border: 1px solid #dee4ec;
    border-left: 1px solid #000
}

.project-list .list-unstyled li .media-body {
    margin-left: 13px
}

.pricing-card .list-unstyled {
    padding-left: 0;
    list-style: none
}

.pricing-card .display-5 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.1
}

.pricing-card .list-unstyled li {
    border-bottom: 1px solid #eaedf1;
    display: flow-root;
    margin-bottom: 0;
    padding: 9px 0
}

.panel.price {
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -webkit-transition: all .3s ease
}

.panel.price>.panel-heading {
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -webkit-transition: all .3s ease
}

.panel.price h3 {
    margin-bottom: 0;
    padding: 20px 0
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, .2);
    background: #fff
}

.panel.price {
    margin-bottom: 1.5rem
}

.panel.price>.panel-heading {
    color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px
}

.panel.price .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.panel.price .list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.price .panel-footer {
    border-bottom: 0;
    background-color: #fff;
    border-left: 0;
    border-right: 0
}

.price.panel-color>.panel-body {
    background-color: #fff
}

.panel-body {
    padding: 15px
}

.panel-body .lead {
    font-size: 20px;
    margin-bottom: 0;
    padding: 10px 0
}

.panel-body .lead strong {
    font-size: 40px;
    margin-bottom: 0
}

.panel-footer {
    padding: 10px 15px;
    background-color: #fff;
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
    border-right: 1px solid #eaedf1;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.panel.price .btn {
    border: 0
}

.pricing .list-unstyled li {
    border-bottom: 1px solid #eaedf1;
    display: flow-root;
    margin-bottom: 0;
    padding: 9px 0
}

.demo-gallery>ul>li a {
    border-radius: 3px;
    display: block;
    overflow: hidden;
    position: relative;
    float: left;
    height: 100%;
    width: 100%
}

.demo-gallery>ul>li a>img {
    opacity: 1;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    width: 100%!important
}

.demo-gallery>ul>li a:hover .demo-gallery-poster>img,
.demo-gallery>ul>li a:hover>img {
    opacity: .5
}

.demo-gallery>ul>li a .demo-gallery-poster {
    background-color: rgba(0, 0, 0, .1);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: background-color .15s ease 0s;
    -o-transition: background-color .15s ease 0s;
    transition: background-color .15s ease 0s
}

.demo-gallery>ul>li a .demo-gallery-poster>img {
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transition: opacity .3s ease 0s;
    -o-transition: opacity .3s ease 0s;
    transition: opacity .3s ease 0s
}

.demo-gallery>ul>li a:hover .demo-gallery-poster {
    background-color: rgba(0, 0, 0, .5)
}

.demo-gallery .justified-gallery>a>img {
    -webkit-transition: -webkit-transform .15s ease 0s;
    -moz-transition: -moz-transform .15s ease 0s;
    -o-transition: -o-transform .15s ease 0s;
    transition: transform .15s ease 0s;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    height: 100%;
    width: 100%
}

.demo-gallery .justified-gallery>a:hover>img {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1)
}

.demo-gallery .justified-gallery>a:hover .demo-gallery-poster>img {
    opacity: 1
}

.demo-gallery .justified-gallery>a .demo-gallery-poster {
    background-color: rgba(0, 0, 0, .1);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: background-color .15s ease 0s;
    -o-transition: background-color .15s ease 0s;
    transition: background-color .15s ease 0s
}

.demo-gallery .justified-gallery>a .demo-gallery-poster>img {
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transition: opacity .3s ease 0s;
    -o-transition: opacity .3s ease 0s;
    transition: opacity .3s ease 0s
}

.demo-gallery .justified-gallery>a:hover .demo-gallery-poster {
    background-color: rgba(0, 0, 0, .5)
}

.demo-gallery .video .demo-gallery-poster img {
    height: 48px;
    margin-left: -24px;
    margin-top: -24px;
    opacity: .8;
    width: 48px
}

.demo-gallery.dark>ul>li a {
    border: 3px solid #04070a
}

.card-body+.card-body {
    border-top: 1px solid #eaedf1
}

.left-side-product-box img {
    width: 100%
}

.left-side-product-box .sub-img img {
    margin-top: 5px;
    width: 83px;
    height: 100px
}

.right-side-pro-detail span {
    font-size: 15px
}

.right-side-pro-detail p {
    font-size: 25px;
    color: #a1a1a1
}

.right-side-pro-detail .price-pro {
    color: #e45641
}

.right-side-pro-detail .tag-section {
    font-size: 18px;
    color: #5d4c46
}

.pro-box-section .pro-box img {
    width: 100%;
    height: 200px
}

@media (min-width:360px) and (max-width:640px) {
    .pro-box-section .pro-box img {
        height: auto
    }
}

.product-details .media img {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    border-radius: 5px;
    max-width: inherit;
    border: 1px solid #d4daea
}

.preview-pic {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.preview-thumbnail.nav-tabs {
    border: none;
    margin-top: 15px
}

.preview-thumbnail.nav-tabs li {
    width: 18%;
    margin-right: 2.5%
}

.preview-thumbnail.nav-tabs li img {
    max-width: 100%;
    display: block
}

.preview-thumbnail.nav-tabs li a {
    padding: 0;
    margin: 0
}

.preview-thumbnail.nav-tabs li:last-of-type {
    margin-right: 0
}

.tab-content {
    overflow: hidden
}

.tab-content img {
    width: 100%
}

.details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.colors {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.colors,
.price,
.product-title,
.sizes {
    text-transform: UPPERCASE;
    font-weight: 700
}

.checked {
    color: #ff9f1a
}

.price span {
    color: #031b4e
}

.price,
.product-description,
.product-title,
.rating,
.sizes,
.vote {
    margin-bottom: 15px
}

.product-title {
    margin-top: 0
}

.size {
    margin-right: 10px
}

.size:first-of-type {
    margin-left: 40px
}

.details .rdiobox span:after {
    top: 5px
}

.details .rdiobox span:before {
    top: 0
}

@-webkit-keyframes opacity {
    0% {
        opacity: 0;
        -webkit-transform: scale(3);
        transform: scale(3)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes opacity {
    0% {
        opacity: 0;
        -webkit-transform: scale(3);
        transform: scale(3)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

.colorinput {
    margin: 0;
    position: relative;
    cursor: pointer
}

.colorinput-input {
    position: absolute;
    z-index: -1;
    opacity: 0
}

.colorinput-color {
    display: inline-block;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 3px;
    border: 1px solid #eaf0f7;
    color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05)
}

.colorinput-color:before {
    content: '';
    opacity: 0;
    position: absolute;
    top: .25rem;
    left: .25rem;
    height: 1.25rem;
    width: 1.25rem;
    transition: .3s opacity;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center center/50% 50%
}

.colorinput-input:checked~.colorinput-color:before {
    opacity: 1
}

.feature2 i {
    line-height: 3rem;
    font-size: 20px;
    margin: 0 0 15px 0
}

.item-card .cardtitle span {
    display: block;
    font-size: .75rem
}

.item-card .cardtitle a {
    color: #1f252d;
    font-weight: 500;
    text-decoration: none
}

.item-card .cardprice {
    position: absolute;
    top: 17px;
    right: 15px
}

.item-card .cardprice span {
    display: block;
    color: #1f252d
}

.item-card .cardprice span.type--strikethrough {
    opacity: .7;
    text-decoration: line-through
}

.item-card .relative {
    position: relative
}

.main-icon-list {
    padding: 0
}

.icons-list {
    list-style: none;
    margin: 0 -1px -1px 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.icons-list-item {
    height: 2.5rem;
    width: 2.5rem;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    border: 1px solid #d6dcec;
    margin: 4px 4px;
    border-radius: 6px
}

.icons-list .flag-icon {
    border-radius: 0
}

.example {
    padding: 1rem;
    border: 1px solid #e7ebf5;
    font-size: .9375rem
}

.example+.highlight {
    border-top: none;
    margin-top: 0
}

.text-wrap>:last-child {
    margin-bottom: 0
}

.highlight {
    margin: 0 0 2rem;
    border: 1px solid #23241f;
    border-radius: 0;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    font-size: .9375rem;
    background: #edeff7;
    border-top-right-radius: 8px;
    position: relative!important
}

.highlight pre {
    margin-bottom: 0;
    background-color: #23241f;
    max-height: 20rem
}

pre {
    color: #3d3f4e;
    padding: 1rem;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #edeff7;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    border-top-right-radius: 7px
}

.clip-widget {
    position: relative
}

.clipboard-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    background: #fff;
    border: 1px solid #e7ebf5;
    border-radius: 8px;
    cursor: pointer;
    height: 35px;
    width: 35px;
    text-align: center;
    line-height: 33px
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    transition: .3s background
}

::-webkit-scrollbar-thumb {
    background: #e1e6f1
}

:hover::-webkit-scrollbar-thumb {
    background: #adb5bd
}

@media (max-width:568px) {
    .example {
        padding: 10px!important;
        border: 0;
        font-size: .876rem
    }
}

.angle {
    color: #bac5d4!important
}

.pro-img-box img {
    border: 1px solid #e3e8f7!important
}

.banner {
    background: rgba(109, 110, 243, .1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    margin: 1.5rem 0;
    padding: 14px 15px;
    border: 1px solid #dbdbf7
}

.nav-sub-item .sub-slide-item {
    color: #fff
}

@media (max-width:768px) and (min-width:574px) {
    .responsive-logo .logo-2 {
        dispLay: none
    }
    .responsive-logo .logo-1 {
        height: 2rem
    }
}

@media (max-width:574px) {
    .responsive-logo .logo-2 {
        dispLay: block;
        height: 2.5rem
    }
    .responsive-logo .logo-1 {
        dispLay: none
    }
}

@media (max-width:633px) {
    .right-content {
        margin-top: 20px!important
    }
}

@media (max-width:1199px) {
    .total-revenue {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 0 solid #e3e8f7!important
    }
}

.sub-menu li a:before {
    margin-right: 8px;
    content: '';
    width: inherit;
    height: inherit;
    border-width: 0;
    position: absolute;
    left: 8px;
    top: 15px;
    z-index: 99;
    border-color: #bec5d0;
    content: "\e92f";
    font-family: feather!important;
    position: absolute;
    top: 9px;
    left: 8px;
    font-size: 9px
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
    color: #696af1
}

.mega-menubg.hor-mega-menu li a:before {
    margin-right: 8px;
    content: '';
    width: inherit;
    height: inherit;
    border-width: 0;
    position: absolute;
    left: 8px;
    top: 15px;
    z-index: 99;
    border-color: #bec5d0;
    content: "\e92f";
    font-family: feather!important;
    position: absolute;
    top: 10px;
    left: 8px;
    font-size: 9px;
    background: 0 0
}

.slide.active.is-expanded {
    background: rgba(238, 238, 247, .5)
}

@media (max-width:1134px) {
    .p-text {
        display: none
    }
}

@media (max-width:659px) {
    .fullscreen-button {
        display: none
    }
}

.horizontalMenucontainer.main-header form[role=search] {
    width: inherit
}

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh
}

.prev-price {
    text-decoration: line-through
}

.qunatity-list {
    margin-left: 0;
    padding-left: 6px
}

.qunatity-list li {
    list-style-type: none
}

.chips .chip {
    margin: 0 .5rem .5rem 0
}

.chip {
    display: inline-block;
    height: 2rem;
    line-height: 2rem;
    font-size: .875rem;
    font-weight: 500;
    color: #6e7687;
    padding: 0 .75rem;
    border-radius: 1rem;
    background-color: #f5f6fb;
    transition: .3s background
}

.chip .avatar {
    float: left;
    margin: 0 .5rem 0 -.75rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%
}

.team i {
    margin-left: 10px;
    float: right
}

.feature .project {
    display: flex;
    width: 45px;
    height: 45px;
    line-height: 2em;
    vertical-align: middle;
    padding-top: 0;
    color: #fff;
    font-size: 20px;
    border-radius: 50px;
    position: relative;
    align-items: center;
    text-align: center;
    left: 0;
    right: 0;
    justify-content: center;
    font-weight: 500
}

.plan-icon {
    font-size: 25px;
    width: 80px;
    height: 80px;
    line-height: 80px!important;
    overflow: hidden;
    border: 1px solid #ebeaf1;
    border-radius: 50%;
    background: #f5f6fb;
    transition: all .3s
}

.country-table .table td,
.country-table .table th {
    padding: 10px 15px;
    line-height: 1.462
}

#back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    display: none;
    text-align: center;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -o-border-radius: 4px;
    z-index: 1000;
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color .1s linear;
    -moz-transition: background-color .1s linear;
    -webkit-transition: background-color .1s linear;
    -o-transition: background-color .1s linear;
    background: #078586;
    color: #fff;
    border-radius: 5px
}

#back-to-top i {
    padding-top: 0;
    font-size: 20px;
    line-height: 2.4
}

#compositeline,
#compositeline2 {
    position: relative;
    bottom: -1px
}

@media (max-width:480px) {
    .sales-bar {
        padding-top: 50px
    }
}

@media (max-width:578px) {
    .main-logo {
        display: none!important
    }
    .logo-icon {
        display: block!important;
        height: 2.2rem
    }
    .total-revenue .media {
        display: none
    }
}

.email-button {
    background: #009a3e;
    color: #fff;
    position: fixed;
    bottom: 80px;
    line-height: 50px;
    right: 20px;
    z-index: 99;
    text-align: center;
    border-radius: 50%;
    z-index: 1000;
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color .1s linear;
    -moz-transition: background-color .1s linear;
    -webkit-transition: background-color .1s linear;
    -o-transition: background-color .1s linear
}

.email-button:hover {
    background: #faf9ff;
    color: #009a3e;
    border: 2px solid #009a3e
}

.box-shadow {
    box-shadow: none
}

@media (max-width:480px) {
    .sales-bar {
        padding-top: 50px
    }
}

@media (max-width:575px) {
    .sales-bar {
        padding-top: 50px
    }
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover:before {
    border-color: #078586;
    color: #078586
}

.main-logo-pages {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 auto;
    height: 2.3rem
}

.dark-logo-1,
.dark-logo-2,
.desktop-dark,
.desktop-logo-dark,
.main-logo.dark-theme {
    display: none
}

.desktop-logo.logo-dark .main-logo.dark-theme {
    display: none!important
}

@media (max-width:578px) {
    .desktop-logo.logo-light .main-logo {
        display: block
    }
    .logo-icon.mobile-logo.icon-light {
        display: block!important
    }
    .logo-icon.mobile-logo.icon-dark {
        display: none!important
    }
}

@media (max-width:767px) {
    .sticky-pin {
        position: fixed!important;
        box-shadow: 0 1px 15px 1px rgba(58, 58, 62, .3)
    }
    .icons-list-item {
        margin: 4px 4px
    }
}

.main-content-dashboard {
    padding-top: 20px
}

@media (min-width:992px) {
    .main-content-dashboard {
        padding-top: 30px
    }
}

.main-dashboard-one-title {
    margin-bottom: 20px
}

@media (min-width:576px) {
    .main-dashboard-one-title {
        display: flex;
        align-items: center;
        justify-content: space-between
    }
}

@media (min-width:992px) {
    .main-dashboard-one-title {
        margin-bottom: 30px
    }
}

.main-dashboard-title {
    font-size: 22px;
    font-weight: 700;
    color: #1c273c;
    letter-spacing: -1px;
    margin-bottom: 3px
}

.main-dashboard-text {
    font-size: 13px;
    margin-bottom: 0
}

.main-dashboard-date {
    display: flex;
    align-items: center;
    margin-top: 20px
}

.main-dashboard-date .date {
    display: flex
}

.main-dashboard-date .date>div:first-child {
    font-size: 32px;
    font-family: Arial, sans-serif;
    font-weight: 500;
    color: #1c273c;
    line-height: .9
}

.main-dashboard-date .date>div:last-child {
    margin-left: 5px
}

.main-dashboard-date .date>div:last-child span {
    display: block;
    color: #1c273c;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: -.5px
}

.main-dashboard-date .date>div:last-child span:last-child {
    color: #7987a1;
    font-weight: 400
}

.main-dashboard-date>i {
    font-size: 21px;
    line-height: 0;
    display: block;
    margin: 0 15px;
    color: #97a3b9
}

.main-dashboard-date .wallet {
    border-radius: 3px
}

.main-dashboard-date .wallet i {
    line-height: 40px
}

@media (min-width:576px) {
    .main-dashboard-date {
        margin-top: 0
    }
}

@media (min-width:768px) {
    .main-dashboard-date .date>div:first-child {
        font-size: 36px
    }
}

@media (min-width:768px) {
    .main-dashboard-date .date>div:last-child span {
        font-size: 13px
    }
}

@media (min-width:768px) {
    .main-dashboard-date>i {
        margin: 0 20px;
        font-size: 24px
    }
}

.main-dashboard-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e2e8f5;
    margin-bottom: 20px
}

.main-dashboard-nav .nav-link {
    font-size: 12px;
    padding: 5px 10px 8px;
    display: flex;
    align-items: center;
    line-height: 1
}

.main-dashboard-nav .nav-link:focus,
.main-dashboard-nav .nav-link:hover {
    color: #078586
}

.main-dashboard-nav .nav-link+.nav-link {
    border-left: 1px solid #e2e8f5
}

.main-dashboard-nav .nav:first-child .nav-link {
    color: #1c273c;
    display: none
}

.main-dashboard-nav .nav:first-child .nav-link.active,
.main-dashboard-nav .nav:first-child .nav-link:focus,
.main-dashboard-nav .nav:first-child .nav-link:hover {
    color: #078586
}

.main-dashboard-nav .nav:first-child .nav-link:first-child {
    padding-left: 0;
    display: block
}

.main-dashboard-nav .nav:first-child .nav-link:last-child {
    display: block
}

.main-dashboard-nav .nav:last-child .nav-link {
    color: #596882;
    display: none
}

.main-dashboard-nav .nav:last-child .nav-link:focus,
.main-dashboard-nav .nav:last-child .nav-link:hover {
    color: #078586
}

.main-dashboard-nav .nav:last-child .nav-link i {
    font-size: 16px;
    margin-right: 7px;
    line-height: 0
}

.main-dashboard-nav .nav:last-child .nav-link:last-child {
    padding-right: 0;
    display: block
}

.main-dashboard-nav .nav:last-child .nav-link:last-child i {
    margin-right: 0
}

@media (min-width:576px) {
    .main-dashboard-nav .nav-link {
        font-size: .875rem
    }
}

@media (min-width:992px) {
    .main-dashboard-nav .nav-link {
        padding: 5px 15px 10px
    }
}

@media (min-width:576px) {
    .main-dashboard-nav .nav:first-child .nav-link {
        display: block
    }
}

@media (min-width:768px) {
    .main-dashboard-nav .nav:last-child .nav-link {
        display: block
    }
}

@media (min-width:768px) {
    .main-dashboard-nav .nav:last-child .nav-link:last-child {
        display: none
    }
}

.card-dashboard-one {
    position: relative;
    border-color: #e2e8f5
}

.card-dashboard-one .card-header {
    padding: 20px;
    background-color: transparent
}

.card-dashboard-one .card-header .card-title {
    color: #1c273c;
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 3px
}

.card-dashboard-one .card-header .card-text {
    margin-bottom: 0
}

.card-dashboard-one .card-header .btn-group {
    margin-top: 15px
}

.card-dashboard-one .card-header .btn-group .btn,
.card-dashboard-one .card-header .btn-group .sp-container button {
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    min-height: inherit;
    background-color: #fff;
    color: #7987a1;
    border: 1px solid #e2e8f5
}

@media (min-width:576px) {
    .card-dashboard-one .card-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between
    }
}

@media (min-width:576px) {
    .card-dashboard-one .card-header .btn-group {
        margin-top: 0
    }
}

.sp-container .card-dashboard-one .card-header .btn-group button {
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    min-height: inherit;
    background-color: #fff;
    color: #7987a1;
    border: 1px solid #e2e8f5
}

.card-dashboard-one .card-header .btn-group .btn:hover,
.card-dashboard-one .card-header .btn-group .sp-container button:hover {
    background-color: #f4f5f8;
    border-color: #e2e8f5
}

.sp-container .card-dashboard-one .card-header .btn-group button:hover {
    background-color: #f4f5f8;
    border-color: #e2e8f5
}

.card-dashboard-one .card-header .btn-group .btn:focus,
.card-dashboard-one .card-header .btn-group .sp-container button:focus {
    background-color: #f4f5f8;
    border-color: #e2e8f5
}

.sp-container .card-dashboard-one .card-header .btn-group button:focus {
    background-color: #f4f5f8;
    border-color: #e2e8f5
}

.card-dashboard-one .card-header .btn-group .btn+.btn,
.card-dashboard-one .card-header .btn-group .sp-container button+.btn {
    margin-left: -1px
}

.card-dashboard-one .card-header .btn-group .sp-container .btn+button,
.card-dashboard-one .card-header .btn-group .sp-container button+button,
.sp-container .card-dashboard-one .card-header .btn-group .btn+button,
.sp-container .card-dashboard-one .card-header .btn-group button+.btn,
.sp-container .card-dashboard-one .card-header .btn-group button+button {
    margin-left: -1px
}

.card-dashboard-one .card-header .btn-group .btn.active,
.card-dashboard-one .card-header .btn-group .sp-container button.active {
    color: #1c273c;
    background-color: #e3e7ed;
    border-color: #e2e8f5
}

.sp-container .card-dashboard-one .card-header .btn-group button.active {
    color: #1c273c;
    background-color: #e3e7ed;
    border-color: #e2e8f5
}

.card-dashboard-one .card-body {
    padding: 10px 0 20px;
    position: relative;
    overflow: hidden
}

.card-dashboard-one .card-body .flot-chart-wrapper {
    position: relative;
    margin-left: -28px;
    margin-right: -20px
}

.card-dashboard-one .card-body .flot-chart {
    width: 100%;
    height: 150px
}

.card-dashboard-one .card-body .flot-chart .flot-y-axis>div {
    transform: translateX(50px);
    text-shadow: 1px 1px rgba(255, 255, 255, .75);
    color: #031b4e;
    font-weight: 700;
    font-size: 11px
}

.card-dashboard-one .card-body .flot-chart .flot-x-axis>div {
    color: #97a3b9;
    font-weight: 500;
    font-size: 11px
}

.card-dashboard-one .card-body-top {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-left: 20px
}

.card-dashboard-one .card-body-top>div {
    flex-basis: 50%
}

.card-dashboard-one .card-body-top label {
    font-size: 12px;
    margin-bottom: 3px
}

.card-dashboard-one .card-body-top h2 {
    font-size: 20px;
    font-weight: 600;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c
}

@media (min-width:576px) {
    .card-dashboard-one .card-body .flot-chart {
        height: 275px
    }
}

@media (min-width:992px) and (max-width:1199.98px) {
    .card-dashboard-one .card-body .flot-chart {
        height: 252px
    }
}

@media (min-width:576px) {
    .card-dashboard-one .card-body-top {
        position: absolute;
        top: -5px;
        left: 20px;
        z-index: 5;
        padding-left: 0
    }
}

@media (min-width:576px) {
    .card-dashboard-one .card-body-top>div {
        flex-basis: auto
    }
}

@media (min-width:576px) {
    .card-dashboard-one .card-body-top>div+div {
        margin-left: 30px
    }
}

@media (min-width:576px) {
    .card-dashboard-one .card-body-top label {
        font-size: .875rem
    }
}

@media (min-width:576px) {
    .card-dashboard-one .card-body-top h2 {
        font-size: 24px;
        letter-spacing: -.5px
    }
}

.card-dashboard-two {
    height: 100%;
    border-radius: 0;
    border-color: #e2e8f5;
    background-color: rgba(255, 255, 255, .5)
}

.card-dashboard-two .card-header {
    background-color: transparent;
    padding: 20px 20px 0
}

.card-dashboard-two .card-header h6 {
    font-size: 28px;
    font-weight: 600;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    display: flex;
    align-items: baseline;
    margin-bottom: 2px;
    line-height: 1;
    letter-spacing: -1px
}

.card-dashboard-two .card-header h6 i {
    margin-left: 10px;
    font-size: 24px
}

.card-dashboard-two .card-header h6 small {
    font-size: 12px;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin-left: 5px;
    color: #7987a1;
    letter-spacing: normal
}

.card-dashboard-two .card-header p {
    margin-bottom: 0;
    font-size: 13px
}

.card-dashboard-two .card-body {
    padding: 0;
    overflow: hidden
}

.card-dashboard-two .chart-wrapper {
    position: relative;
    margin: 0 -10px -10px
}

.card-dashboard-two .flot-chart {
    width: 100%;
    height: 100px
}

@media (min-width:992px) and (max-width:1199.98px) {
    .card-dashboard-two .card-header h6 {
        font-size: 24px
    }
}

.card-dashboard-three {
    height: 150px;
    border-radius: 0;
    border-color: #e2e8f5;
    background-color: rgba(255, 255, 255, .5)
}

.card-dashboard-three .card-header {
    background-color: transparent;
    padding: 20px;
    position: absolute;
    max-width: 50%
}

.card-dashboard-three .card-header h6 {
    font-size: 28px;
    font-weight: 600;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    display: flex;
    align-items: baseline;
    margin-bottom: 15px;
    line-height: 1;
    letter-spacing: -1px
}

.card-dashboard-three .card-header h6 small {
    font-size: 12px;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin-left: 5px;
    letter-spacing: normal
}

.card-dashboard-three .card-header p {
    margin-bottom: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: #1c273c
}

.card-dashboard-three .card-header>small {
    font-size: 11px;
    display: none;
    color: #7987a1
}

.card-dashboard-three .card-body {
    padding: 0 0 0 20px;
    display: flex;
    justify-content: flex-end
}

.card-dashboard-three .chart {
    width: 70%;
    height: 150px
}

@media (min-width:768px) {
    .card-dashboard-three {
        height: 170px
    }
}

@media (min-width:992px) {
    .card-dashboard-three {
        height: 100%
    }
}

@media (min-width:1200px) {
    .card-dashboard-three .card-header h6 {
        font-size: 32px
    }
}

@media (min-width:768px) {
    .card-dashboard-three .card-header>small {
        display: block
    }
}

@media (min-width:375px) {
    .card-dashboard-three .chart {
        width: auto
    }
}

@media (min-width:768px) {
    .card-dashboard-three .chart {
        height: 170px
    }
}

@media (min-width:992px) {
    .card-dashboard-three .chart {
        height: 200px;
        width: 80%
    }
}

.card-dashboard-pageviews {
    border-color: #e2e8f5;
    border-radius: 0;
    padding: 20px
}

.card-dashboard-pageviews .card-header {
    background-color: transparent;
    padding: 0 0 10px
}

.card-dashboard-pageviews .card-title {
    font-weight: 700;
    font-size: 14px;
    color: #1c273c;
    margin-bottom: 5px
}

.card-dashboard-pageviews .card-text {
    font-size: 13px;
    margin-bottom: 0
}

.card-dashboard-pageviews .card-body {
    padding: 0
}

.card-dashboard-four {
    border-radius: 0;
    border-color: #e2e8f5;
    padding: 20px;
    height: 100%
}

.card-dashboard-four .card-header {
    padding: 0 0 20px;
    background-color: transparent
}

.card-dashboard-four .card-title {
    font-weight: 700;
    font-size: 14px;
    color: #1c273c;
    margin-bottom: 0
}

.card-dashboard-four .card-body {
    padding: 0
}

.card-dashboard-four .chart {
    width: calc(100vw - 80px);
    height: 200px
}

@media (min-width:992px) {
    .card-dashboard-four .card-body {
        padding: 0 20px
    }
}

@media (min-width:375px) {
    .card-dashboard-four .chart {
        width: auto
    }
}

@media (min-width:992px) {
    .card-dashboard-four .chart {
        height: 85%
    }
}

.card-dashboard-five {
    border-color: #e2e8f5;
    padding: 20px
}

.card-dashboard-five .card-header {
    padding: 0;
    background-color: transparent
}

.card-dashboard-five .card-title {
    font-weight: 700;
    font-size: 14px;
    color: #1c273c;
    margin-bottom: 5px
}

.card-dashboard-five .card-text {
    display: block;
    font-size: 12px
}

.card-dashboard-five .card-body {
    padding: 20px 0 5px
}

.card-dashboard-five .card-body label {
    display: block;
    margin-bottom: 2px;
    font-size: 12px
}

.card-dashboard-five .card-body h4 {
    color: #1c273c;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    letter-spacing: -.5px;
    margin-bottom: 0
}

.card-dashboard-five .card-chart {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px
}

@media (min-width:576px) {
    .card-dashboard-five .card-chart {
        margin-bottom: 0;
        margin-right: 10px
    }
}

.card-table-one {
    padding: 20px 20px 10px;
    border-color: #e2e8f5
}

.card-table-one .card-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px
}

.card-table-one .table-responsive {
    width: calc(100vw - 80px)
}

.card-table-one .table {
    margin-bottom: 0
}

.card-table-one .table thead tr>td,
.card-table-one .table thead tr>th {
    vertical-align: middle;
    white-space: nowrap;
    padding-right: 2px
}

.card-table-one .table tbody tr>td,
.card-table-one .table tbody tr>th {
    vertical-align: middle;
    white-space: nowrap;
    padding-right: 2px
}

.card-table-one .table thead tr>th:nth-child(3),
.card-table-one .table thead tr>th:nth-child(4),
.card-table-one .table thead tr>th:nth-child(5) {
    text-align: right
}

.card-table-one .table thead tr>td:nth-child(3),
.card-table-one .table thead tr>td:nth-child(4),
.card-table-one .table thead tr>td:nth-child(5) {
    text-align: right
}

.card-table-one .table tbody tr>th:nth-child(3),
.card-table-one .table tbody tr>th:nth-child(4),
.card-table-one .table tbody tr>th:nth-child(5) {
    text-align: right
}

.card-table-one .table tbody tr>td:nth-child(3),
.card-table-one .table tbody tr>td:nth-child(4),
.card-table-one .table tbody tr>td:nth-child(5) {
    text-align: right
}

.card-table-one .table thead tr>td strong,
.card-table-one .table thead tr>th strong {
    color: #1c273c
}

.card-table-one .table tbody tr>td strong,
.card-table-one .table tbody tr>th strong {
    color: #1c273c
}

.card-table-one .flag-icon {
    font-size: 20px;
    line-height: 1;
    border-radius: 100%
}

@media (min-width:576px) {
    .card-table-one .table-responsive {
        width: 100%
    }
}

@media (min-width:768px) {
    .card-table-one .table thead tr>td,
    .card-table-one .table thead tr>th {
        padding-left: 0;
        padding-right: 0
    }
    .card-table-one .table tbody tr>td,
    .card-table-one .table tbody tr>th {
        padding-left: 0;
        padding-right: 0
    }
}

.main-content-dashboard-two {
    background-color: #eff2f6;
    transition: all .4s
}

.main-content-dashboard-two .main-content-title {
    letter-spacing: -.5px
}

.main-content-dashboard-two .main-content-body {
    padding: 0 20px
}

.main-content-dashboard-two .main-header-menu-icon {
    margin-right: 0
}

@media (prefers-reduced-motion:reduce) {
    .main-content-dashboard-two {
        transition: none
    }
}

@media (min-width:992px) {
    .main-content-dashboard-two .main-content-body {
        padding: 0 20px 20px
    }
}

.main-dashboard-header-right {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap
}

.main-dashboard-header-right>div label {
    display: block;
    margin-bottom: 0;
    color: #78829a
}

.main-dashboard-header-right>div h5 {
    font-size: 20px;
    font-weight: 700;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    margin-bottom: 0;
    line-height: 1
}

.main-dashboard-header-right>div:first-child {
    flex-basis: 100%
}

.main-dashboard-header-right>div+div {
    margin-top: 10px
}

.left-content p {
    color: #78829a;
    font-weight: 400
}

@media (min-width:576px) {
    .main-dashboard-header-right {
        flex-wrap: nowrap
    }
}

@media (min-width:768px) {
    .main-dashboard-header-right {
        margin-top: 0
    }
}

@media (min-width:768px) {
    .main-dashboard-header-right>div {
        text-align: right
    }
}

@media (max-width:575.98px) {
    .main-dashboard-header-right>div+div {
        flex-basis: 50%
    }
}

@media (min-width:576px) {
    .main-dashboard-header-right>div+div {
        margin-left: 25px;
        margin-top: 0;
        flex-shrink: 0
    }
}

@media (min-width:992px) {
    .main-dashboard-header-right>div+div {
        margin-left: 50px
    }
}

.main-star {
    display: flex;
    align-items: center
}

.main-star i {
    font-size: 20px;
    line-height: 0;
    color: #97a3b9;
    display: inline-block
}

.main-star i.typcn {
    line-height: .9
}

.main-star i.active {
    color: #fbbc0b
}

.main-star span {
    font-size: 13px;
    display: inline-block;
    margin-left: 5px
}

.card-dashboard-seven {
    border-color: #e2e8f5;
    margin-bottom: 20px;
    width: 100vw;
    border-left-width: 0;
    border-right-width: 0;
    position: relative;
    margin-left: -20px;
    margin-right: -20px
}

.card-dashboard-seven .card-header {
    background-color: transparent;
    padding: 20px;
    border-bottom: 1px solid #e2e8f5;
    background-color: #f4f5f8
}

.card-dashboard-seven .card-header .media {
    align-items: center
}

.card-dashboard-seven .card-header .media>div:first-child {
    line-height: 0;
    font-size: 16px;
    margin-right: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 34px;
    background-color: #97a3b9
}

.card-dashboard-seven .card-header .media-body {
    margin-top: 3px
}

.card-dashboard-seven .card-header label {
    margin-bottom: 0;
    display: block;
    line-height: 1;
    font-size: 11px;
    color: #97a3b9
}

.card-dashboard-seven .card-header .date {
    font-weight: 500;
    display: flex;
    align-items: center
}

.card-dashboard-seven .card-header .date span {
    display: block
}

.card-dashboard-seven .card-header .date a {
    margin-left: 5px;
    font-size: 14px;
    color: #97a3b9;
    display: block
}

.card-dashboard-seven .card-header .date a i {
    line-height: 0;
    position: relative;
    top: 1px
}

.card-dashboard-seven .card-body {
    padding: 20px
}

.card-dashboard-seven .card-body .row>div+div {
    position: relative
}

.card-dashboard-seven .card-body .row>div+div::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    bottom: 0;
    border-left: 1px dotted #e2e8f5;
    display: none
}

.card-dashboard-seven .card-body .card-table-two .card-title,
.card-dashboard-seven .card-body .main-content-label {
    text-transform: none;
    color: #031b4e;
    font-weight: 500;
    font-size: .875rem;
    margin-bottom: 10px
}

@media (min-width:576px) {
    .card-dashboard-seven {
        margin-bottom: 20px;
        margin-left: 0;
        margin-right: 0;
        width: auto;
        border-left-width: 1px;
        border-right-width: 1px
    }
}

@media (min-width:576px) {
    .card-dashboard-seven .card-header {
        padding: 20px
    }
}

@media (min-width:576px) {
    .card-dashboard-seven .card-header .media>div:first-child {
        width: 34px;
        font-size: 24px;
        margin-right: 8px
    }
}

@media (min-width:992px) {
    .card-dashboard-seven .card-body .row>div+div::before {
        display: block
    }
}

.card-dashboard-eight .card-dashboard-seven .card-body .card-title,
.card-dashboard-seven .card-body .card-dashboard-eight .card-title,
.card-table-two .card-dashboard-seven .card-body .card-title {
    text-transform: none;
    color: #031b4e;
    font-weight: 500;
    font-size: .875rem;
    margin-bottom: 10px
}

.card-dashboard-seven .card-body h2 {
    color: #1c273c;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    font-size: 24px;
    letter-spacing: -1px;
    margin-bottom: 5px;
    line-height: 1
}

.card-dashboard-seven .card-body h2 span {
    font-weight: 400;
    color: #031b4e;
    letter-spacing: normal
}

.card-dashboard-seven .card-body .desc {
    display: flex;
    align-items: flex-end;
    margin-bottom: 15px
}

.card-dashboard-seven .card-body .desc>i {
    line-height: 0;
    font-size: 18px
}

.card-dashboard-seven .card-body .desc span {
    display: block;
    line-height: 1;
    margin-left: 5px
}

.card-dashboard-seven .card-body .desc span strong {
    color: #1c273c
}

.card-dashboard-seven .card-body .desc.up>i {
    color: #21b18a
}

.card-dashboard-seven .card-body .desc.down>i {
    color: #ee335e
}

@media (min-width:576px) {
    .card-dashboard-seven .card-body h2 {
        font-size: 32px
    }
}

.card-dashboard-six {
    border-color: #e2e8f5;
    padding: 20px
}

.card-dashboard-six .card-header {
    padding: 0;
    background-color: transparent;
    margin-bottom: 20px
}

.card-dashboard-six .card-table-two .card-title,
.card-dashboard-six .main-content-label {
    margin-bottom: 5px;
    font-size: .875rem
}

@media (min-width:992px) {
    .card-dashboard-six {
        height: 100%
    }
}

@media (min-width:576px) {
    .card-dashboard-six .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between
    }
}

@media (min-width:992px) {
    .card-dashboard-six .card-header {
        margin-bottom: 30px
    }
}

.card-dashboard-eight .card-dashboard-six .card-title,
.card-dashboard-six .card-dashboard-eight .card-title,
.card-table-two .card-dashboard-six .card-title {
    margin-bottom: 5px;
    font-size: .875rem
}

.card-dashboard-six .chart-legend {
    margin-top: 20px;
    display: flex
}

.card-dashboard-six .chart-legend>div {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 500
}

.card-dashboard-six .chart-legend>div+div {
    margin-top: 2px
}

.card-dashboard-six .chart-legend>div span:last-child {
    width: 8px;
    height: 8px;
    margin-left: 5px
}

.card-dashboard-six .chart-legend>div span:first-child {
    width: 100px;
    text-align: right
}

@media (min-width:576px) {
    .card-dashboard-six .chart-legend {
        display: block;
        margin-top: 0
    }
}

@media (min-width:576px) {
    .card-dashboard-six .chart-legend>div span:last-child {
        margin-left: 10px;
        width: 20px
    }
}

.card-dashboard-map-one {
    border-color: #e2e8f5;
    padding: 20px
}

.card-dashboard-map-one .card-table-two .card-title,
.card-dashboard-map-one .main-content-label {
    font-size: .875rem
}

.card-dashboard-eight .card-dashboard-map-one .card-title,
.card-dashboard-map-one .card-dashboard-eight .card-title,
.card-table-two .card-dashboard-map-one .card-title {
    font-size: .875rem
}

.card-dashboard-map-one .vmap-wrapper {
    height: 200px
}

@media (min-width:576px) {
    .card-dashboard-map-one .vmap-wrapper {
        height: 300px
    }
}

@media (min-width:992px) {
    .card-dashboard-map-one .vmap-wrapper {
        height: 270px
    }
}

.card-table-two {
    border-color: #e2e8f5;
    padding: 20px
}

.card-table-two .card-title {
    font-size: 13px;
    margin-bottom: 5px
}

.card-table-two .table-responsive {
    width: calc(100vw - 62px)
}

@media (min-width:320px) {
    .card-table-two .table-responsive {
        width: 100%;
        overflow-x: auto;
        /* position: relative; */
    }
    /* .table-bordered tbody>tr>td:nth-child(3),
    .table-bordered thead>tr>th:nth-child(3)
    {
        position: sticky;
        top: auto;
        border-right: 0;
        border-bottom: 0;
        margin-top: -1px;
        left: 0px;
        width: 90px;
        background: #ffffff;
        z-index: 9999;
    } */
}

.table-dashboard-two thead>tr>td,
.table-dashboard-two thead>tr>th {
    white-space: nowrap
}

.table-dashboard-two tbody>tr>td,
.table-dashboard-two tbody>tr>th {
    white-space: nowrap
}

.table-dashboard-two tbody>tr>td:not(:first-child),
.table-dashboard-two tbody>tr>th:not(:first-child) {
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    font-size: 13px
}

.card-dashboard-eight {
    border-color: #e2e8f5;
    background-color: rgba(255, 255, 255, .5);
    padding: 20px
}

.card-dashboard-eight .card-title {
    font-size: 13px;
    margin-bottom: 5px
}

.card-dashboard-eight .list-group-item {
    padding: 10px 0;
    border-left-width: 0;
    border-right-width: 0;
    border-style: dotted;
    border-color: #e2e8f5;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start
}

.card-dashboard-eight .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.card-dashboard-eight .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.card-dashboard-eight .list-group-item p {
    margin-bottom: 0;
    margin-left: 10px
}

.card-dashboard-eight .list-group-item span {
    display: block;
    margin-left: auto;
    font-weight: 600;
    font-family: Archivo, Arial, sans-serif;
    color: #1c273c
}

.card-dashboard-eight .flag-icon {
    font-size: 24px;
    line-height: 1;
    border-radius: 100%
}

.main-content-dashboard-three {
    position: relative;
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fdfdfd
}

@media (min-width:992px) {
    .main-content-dashboard-three {
        border-left: 1px solid #e2e8f5;
        margin-left: 64px
    }
}

.main-header-dashboard-three {
    height: auto
}

.main-header-dashboard-three .container-fluid {
    padding: 15px 20px
}

.main-header-dashboard-three .main-header-center {
    margin-left: 0
}

.main-header-dashboard-three .main-header-right {
    margin-left: auto
}

@media (min-width:992px) {
    .main-header-dashboard-three .main-header-left {
        display: none
    }
    .main-header-left .form-control {
        height: 40px;
        border-radius: 25px;
        background: #ecf0fa;
        border: 1px solid #edecf3
    }
    .main-header-left .btn {
        position: absolute;
        top: 0;
        right: 11px;
        background-color: transparent;
        height: 40px;
        color: #a2aec7;
        transition: none;
        font-size: 16px;
        padding-right: 13px
    }
}

.main-content-body-dashboard-three {
    padding: 20px;
    position: relative;
    width: 100%
}

.main-content-body-dashboard-three .main-content-title {
    letter-spacing: -.5px
}

.card-dashboard-nine {
    border-color: #e2e8f5;
    background-color: rgba(255, 255, 255, .5);
    position: relative;
    padding: 20px 20px 15px
}

.card-dashboard-nine .card-header {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    padding: 0;
    background-color: transparent;
    z-index: 5
}

.card-dashboard-nine .card-header h1 {
    font-size: 32px;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin-bottom: 8px;
    line-height: 1;
    letter-spacing: -.5px;
    color: #1c273c
}

.card-dashboard-nine .card-table-two .card-title,
.card-dashboard-nine .main-content-label {
    font-size: 13px;
    margin-bottom: 10px;
    color: #031b4e
}

.card-dashboard-eight .card-dashboard-nine .card-title,
.card-dashboard-nine .card-dashboard-eight .card-title,
.card-table-two .card-dashboard-nine .card-title {
    font-size: 13px;
    margin-bottom: 10px;
    color: #031b4e
}

.card-dashboard-nine .card-title {
    font-weight: 600
}

.card-dashboard-nine .main-content-text {
    color: #7987a1
}

.card-dashboard-nine .bar-chart {
    position: relative;
    width: auto;
    height: 200px
}

@media (min-width:992px) {
    .card-dashboard-nine .bar-chart {
        height: 210px
    }
}

.card-dashboard-ten {
    border-width: 0;
    padding: 20px
}

.card-dashboard-ten+.card-dashboard-ten {
    margin-top: 20px
}

.card-dashboard-ten .card-table-two .card-title,
.card-dashboard-ten .main-content-label {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 15px;
    line-height: 1.05;
    color: #fff
}

@media (min-width:576px) {
    .card-dashboard-ten {
        width: calc(50% - 11px);
        display: inline-block
    }
}

@media (min-width:992px) {
    .card-dashboard-ten {
        width: auto;
        display: block
    }
}

@media (min-width:576px) {
    .card-dashboard-ten+.card-dashboard-ten {
        margin-top: 0;
        margin-left: 18px
    }
}

@media (min-width:992px) {
    .card-dashboard-ten+.card-dashboard-ten {
        margin-left: 0;
        margin-top: 20px
    }
}

.card-dashboard-eight .card-dashboard-ten .card-title,
.card-dashboard-ten .card-dashboard-eight .card-title,
.card-table-two .card-dashboard-ten .card-title {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 15px;
    line-height: 1.05;
    color: #fff
}

.card-dashboard-ten .card-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.card-dashboard-ten .card-body>div {
    flex: 1
}

.card-dashboard-ten .card-body>div label {
    font-size: 13px;
    margin-bottom: 0;
    color: rgba(255, 255, 255, .75)
}

.card-dashboard-ten .card-body>div h6 {
    margin-bottom: 2px;
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: .975;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.card-dashboard-ten .card-body>div h6 span {
    font-size: 13px
}

.card-dashboard-ten .card-body>div h6 .percent {
    font-size: 20px
}

@media (min-width:992px) {
    .card-dashboard-ten .card-body>div {
        flex: none
    }
}

@media (min-width:1360px) {
    .card-dashboard-ten .card-body>div:first-child {
        flex-basis: 60%
    }
    .card-dashboard-ten .card-body>div:last-child {
        flex-basis: 40%
    }
}

@media (min-width:992px) {
    .card-dashboard-ten .card-body>div h6 {
        font-size: 24px
    }
}

.card-dashboard-eleven {
    border-color: #e2e8f5;
    position: relative;
    overflow: hidden
}

.card-dashboard-eleven .card-header {
    padding: 20px;
    background-color: transparent;
    position: relative;
    z-index: 200
}

.card-dashboard-eleven .card-table-two .card-title,
.card-dashboard-eleven .main-content-label {
    display: block;
    font-size: 13px;
    margin-bottom: 5px;
    color: #3b4863;
    line-height: 1.4
}

.card-dashboard-eight .card-dashboard-eleven .card-title,
.card-dashboard-eleven .card-dashboard-eight .card-title,
.card-table-two .card-dashboard-eleven .card-title {
    display: block;
    font-size: 13px;
    margin-bottom: 5px;
    color: #3b4863;
    line-height: 1.4
}

.card-dashboard-eleven .card-text {
    color: #7987a1
}

.card-dashboard-eleven .card-body {
    padding: 0 0 5px;
    margin-left: 20px;
    position: relative;
    z-index: 200
}

.card-dashboard-eleven .flot-chart {
    width: 100%;
    height: 250px
}

.card-dashboard-eleven .card-footer {
    z-index: 200;
    background-color: transparent;
    padding: 0;
    border-width: 0
}

.card-dashboard-eleven .card-footer>div {
    flex: 1;
    padding: 15px 20px;
    border-top: 1px solid #e2e8f5;
    position: relative
}

.card-dashboard-eleven .card-footer label {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #596882;
    display: block
}

.card-dashboard-eleven .card-footer h6 {
    font-size: 28px;
    font-weight: 600;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    line-height: .7;
    letter-spacing: -1px;
    margin-bottom: 10px;
    display: flex;
    align-items: baseline
}

.card-dashboard-eleven .card-footer h6 span {
    margin-left: 5px;
    font-size: 11px;
    font-weight: 500;
    display: block;
    padding: 4px 6px;
    color: #fff;
    line-height: .8;
    position: relative;
    bottom: 3px;
    letter-spacing: normal
}

.card-dashboard-eleven .card-footer h6 span.up {
    background-color: #21b18a
}

.card-dashboard-eleven .card-footer h6 span.down {
    background-color: #ee335e
}

.card-dashboard-eleven .card-footer small {
    display: block;
    font-size: 13px;
    color: #596882
}

.card-dashboard-eleven .jqvmap {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: 300px
}

@media (min-width:768px) {
    .card-dashboard-eleven .card-footer {
        display: flex;
        align-items: stretch
    }
}

@media (min-width:768px) {
    .card-dashboard-eleven .card-footer>div+div {
        border-left: 1px solid #e2e8f5
    }
}

@media (min-width:992px) {
    .card-dashboard-eleven .jqvmap {
        height: 80%
    }
}

.main-navbar-dashboard-four {
    box-shadow: 0 0 30px rgba(28, 39, 60, .08)
}

@media (min-width:992px) {
    .main-navbar-dashboard-four .container-fluid {
        padding-left: 25px;
        padding-right: 25px
    }
}

.main-content-dashboard-four {
    padding: 20px
}

.main-content-dashboard-four~.main-footer .container,
.main-content-dashboard-four~.main-footer .container-fluid {
    padding-left: 25px;
    padding-right: 25px
}

.main-content-dashboard-four>.media-dashboard {
    display: block
}

.main-content-dashboard-four>.media-dashboard .media-aside {
    margin-top: 20px
}

.main-content-dashboard-four .main-content-header {
    height: auto;
    margin-bottom: 20px;
    align-items: flex-start;
    padding: 0
}

@media (min-width:992px) {
    .main-content-dashboard-four {
        flex: 1;
        padding: 30px 25px
    }
}

@media (min-width:1200px) {
    .main-content-dashboard-four>.media-dashboard {
        display: flex;
        align-items: stretch;
        height: 100%
    }
}

@media (min-width:1200px) {
    .main-content-dashboard-four>.media-dashboard .media-aside {
        margin-top: 0;
        display: block;
        width: 320px
    }
}

@media (min-width:1200px) {
    .main-content-dashboard-four>.media-dashboard>.media-body {
        padding: 0 20px 0 0
    }
}

@media (min-width:768px) {
    .main-content-dashboard-four .main-content-header {
        display: flex
    }
}

.main-content-header-right {
    display: none
}

.main-content-header-right .media label {
    margin-bottom: 2px;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: .5px;
    text-transform: uppercase;
    color: #97a3b9
}

.main-content-header-right .media h6 {
    color: #1c273c;
    margin-bottom: 0
}

.main-content-header-right .media+.media {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid #e2e8f5
}

.main-content-header-right .btn:first-of-type,
.main-content-header-right .sp-container button:first-of-type {
    margin-left: 30px
}

@media (min-width:992px) {
    .main-content-header-right {
        display: flex;
        align-items: center
    }
}

.sp-container .main-content-header-right button:first-of-type {
    margin-left: 30px
}

.main-content-header-right .btn+.btn,
.main-content-header-right .sp-container button+.btn {
    margin-left: 5px
}

.main-content-header-right .sp-container .btn+button,
.main-content-header-right .sp-container button+button,
.sp-container .main-content-header-right .btn+button,
.sp-container .main-content-header-right button+.btn,
.sp-container .main-content-header-right button+button {
    margin-left: 5px
}

.card-dashboard-calendar {
    border-color: #e2e8f5;
    padding: 20px;
    box-shadow: 0 0 10px rgba(28, 39, 60, .1)
}

.card-dashboard-calendar .card-title {
    color: #1c273c;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px
}

.card-dashboard-calendar .card-body {
    padding: 0
}

.card-dashboard-calendar .ui-datepicker-inline {
    border-width: 0;
    max-width: none;
    padding: 0;
    margin: 0
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-header {
    border-bottom: 1px solid #e2e8f5;
    padding: 10px;
    margin-bottom: 5px
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
.card-dashboard-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
    top: 8px
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-month {
    color: #078586
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar {
    width: calc(100% - 20px)
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar th {
    font-size: 10px;
    text-align: center
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar td {
    text-align: center
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar td a {
    display: block;
    width: 34px;
    height: 34px;
    font-size: 12px;
    font-family: Archivo, sans-serif;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: transparent;
    border: 2px solid #078586;
    color: #078586;
    font-weight: 700
}

@media (min-width:576px) {
    .card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar {
        width: 100%
    }
}

.card-dashboard-twelve {
    border-color: #e2e8f5;
    box-shadow: 0 0 10px rgba(28, 39, 60, .1)
}

.card-dashboard-twelve .card-header {
    background-color: transparent;
    border-bottom: 1px solid #e3e7ed;
    padding: 20px
}

.card-dashboard-twelve .card-title {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 20px
}

.card-dashboard-twelve .card-title span {
    font-weight: 400;
    color: #031b4e;
    text-transform: capitalize
}

.card-dashboard-twelve .sales-overview {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap
}

.card-dashboard-twelve .sales-overview .media {
    align-items: flex-end;
    flex-shrink: 0;
    flex-basis: calc(50% - 10px)
}

.card-dashboard-twelve .sales-overview .media+.media {
    margin-left: 20px
}

.card-dashboard-twelve .sales-overview .media:last-child {
    display: none
}

.card-dashboard-twelve .sales-overview .media-icon {
    width: 45px;
    height: 45px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -5px;
    margin-right: 15px;
    border-radius: 100%;
    display: none
}

.card-dashboard-twelve .sales-overview .media-icon .typcn {
    line-height: .9;
    font-size: 24px
}

.card-dashboard-twelve .sales-overview label {
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 700;
    letter-spacing: .5px;
    color: #031b4e;
    margin-bottom: 8px
}

.card-dashboard-twelve .sales-overview h4 {
    font-size: 20px;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    color: #1c273c;
    line-height: 1;
    letter-spacing: -1px;
    margin-bottom: 3px
}

.card-dashboard-twelve .sales-overview h4 span {
    color: #031b4e;
    font-weight: 400
}

.card-dashboard-twelve .sales-overview .media-body>span {
    display: block;
    font-size: 10px;
    color: #97a3b9
}

.card-dashboard-twelve .sales-overview .media-body>span strong {
    font-weight: 500;
    color: #031b4e
}

.card-dashboard-twelve .sales-overview p {
    font-size: 11px;
    margin-bottom: 0;
    color: #97a3b9
}

.card-dashboard-twelve .card-body {
    position: relative;
    padding: 20px 5px
}

@media (min-width:992px) {
    .card-dashboard-twelve .sales-overview {
        margin-bottom: 10px;
        flex-wrap: nowrap
    }
}

@media (min-width:576px) {
    .card-dashboard-twelve .sales-overview .media {
        flex-basis: auto
    }
}

@media (min-width:576px) {
    .card-dashboard-twelve .sales-overview .media+.media {
        margin-left: 40px
    }
}

@media (min-width:992px) {
    .card-dashboard-twelve .sales-overview .media+.media {
        margin-left: 50px
    }
}

@media (max-width:575.98px) {
    .card-dashboard-twelve .sales-overview .media:nth-child(3) {
        margin-left: 0;
        margin-top: 15px
    }
}

@media (min-width:768px) {
    .card-dashboard-twelve .sales-overview .media:last-child {
        flex: 1;
        display: block
    }
}

@media (min-width:576px) {
    .card-dashboard-twelve .sales-overview label {
        font-size: 10px;
        margin-bottom: 10px
    }
}

@media (min-width:576px) {
    .card-dashboard-twelve .sales-overview h4 {
        font-size: 28px
    }
}

@media (min-width:576px) {
    .card-dashboard-twelve .sales-overview .media-body>span {
        font-size: 11px
    }
}

@media (min-width:576px) {
    .card-dashboard-twelve .card-body {
        padding: 20px
    }
}

.chart-legend {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    z-index: 10;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase
}

.chart-legend div {
    display: flex;
    align-items: center
}

.chart-legend div+div {
    margin-left: 15px
}

.chart-legend span {
    width: 10px;
    height: 4px;
    display: block;
    margin-right: 5px
}

@media (min-width:576px) {
    .chart-legend div+div {
        margin-left: 30px
    }
}

@media (min-width:576px) {
    .chart-legend span {
        width: 40px;
        margin-right: 10px
    }
}

.chart-wrapper {
    position: relative;
    margin-left: -13px;
    margin-right: -13px
}

@media (min-width:576px) {
    .chart-wrapper {
        margin-left: -10px;
        margin-right: -15px
    }
}

.card-dashboard-twelve .flot-chart {
    width: 100%;
    height: 200px;
    position: relative
}

.card-dashboard-twelve .flot-chart .flot-x-axis>div span:first-child {
    display: block;
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 500;
    font-size: 10px;
    color: #7987a1
}

.card-dashboard-twelve .flot-chart .flot-x-axis>div span:last-child {
    display: block;
    font-size: 16px;
    font-weight: 700;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    line-height: 1
}

@media (min-width:768px) {
    .card-dashboard-twelve .flot-chart {
        height: 250px
    }
}

@media (min-width:992px) {
    .card-dashboard-twelve .flot-chart {
        height: 300px
    }
}

.main-media-date h1 {
    font-size: 42px;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    line-height: .95
}

.main-media-date .media-body {
    margin-left: 5px
}

.main-media-date p {
    color: #1c273c;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: uppercase
}

.main-media-date span {
    display: block;
    line-height: 1.2
}

.card-dashboard-thirteen {
    border-color: #e2e8f5;
    padding: 20px;
    box-shadow: 0 0 10px rgba(28, 39, 60, .1)
}

.card-dashboard-thirteen .card-table-two .card-title,
.card-dashboard-thirteen .main-content-label {
    font-size: 13px
}

.card-dashboard-eight .card-dashboard-thirteen .card-title,
.card-dashboard-thirteen .card-dashboard-eight .card-title,
.card-table-two .card-dashboard-thirteen .card-title {
    font-size: 13px
}

.card-dashboard-thirteen .media {
    margin-bottom: 15px
}

.card-dashboard-thirteen .media-icon {
    font-size: 45px;
    line-height: 1;
    margin-right: 10px;
    display: flex
}

.card-dashboard-thirteen .media-icon i {
    line-height: 0
}

.card-dashboard-thirteen .media-body h6 {
    font-size: 22px;
    font-weight: 600;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -.5px;
    margin-bottom: 2px
}

.card-dashboard-thirteen .media-body h6 span {
    font-weight: 400;
    color: #031b4e
}

.card-dashboard-thirteen .media-body label {
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0;
    display: block;
    letter-spacing: .5px
}

.card-dashboard-events .card-header {
    padding: 20px;
    background-color: transparent
}

.card-dashboard-events .card-subtitle {
    color: #1c273c;
    font-weight: 400;
    font-size: 18px
}

.card-dashboard-events .card-body {
    padding: 0
}

.card-dashboard-events .list-group-item {
    padding: 15px 20px 15px 40px;
    border-left-width: 0;
    border-right-width: 0;
    position: relative
}

.card-dashboard-events .list-group-item:first-child,
.card-dashboard-events .list-group-item:last-child {
    border-radius: 0
}

.card-dashboard-events .list-group-item label {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    color: #596882;
    margin-bottom: 5px;
    letter-spacing: .5px
}

.card-dashboard-events .list-group-item label span {
    color: #97a3b9;
    font-weight: 500
}

.card-dashboard-events .list-group-item h6 {
    font-size: 14px;
    font-weight: 700;
    color: #1c273c;
    margin-bottom: 5px;
    display: flex;
    align-items: center
}

.card-dashboard-events .list-group-item p {
    margin-bottom: 5px
}

.card-dashboard-events .list-group-item p strong {
    font-weight: 500
}

.card-dashboard-events .list-group-item small {
    font-size: 11px
}

.card-dashboard-events .list-group-item small span {
    font-weight: 700;
    text-transform: uppercase
}

.card-dashboard-events .event-indicator {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 21px;
    left: 20px;
    border-radius: 100%
}

.card-dashboard-events .event-user {
    margin-top: 10px;
    display: flex;
    align-items: center
}

.card-dashboard-events .event-user .main-img-user {
    width: 28px;
    height: 28px
}

.card-dashboard-events .event-user .main-img-user::after {
    display: none
}

.main-img-user.online::after {
    background-color: #21b18a
}

.card-dashboard-events .event-user .main-img-user+.main-img-user {
    margin-left: -6px
}

.card-dashboard-events .event-user a:last-child {
    margin-left: 10px;
    color: #7987a1;
    font-weight: 500;
    font-size: 11px
}

.card-dashboard-events .event-user a:last-child:focus,
.card-dashboard-events .event-user a:last-child:hover {
    color: #596882
}

.card-dashboard-fourteen {
    border-color: #e2e8f5;
    box-shadow: 0 0 10px rgba(28, 39, 60, .1);
    padding: 20px
}

.card-dashboard-fourteen .card-table-two .card-title,
.card-dashboard-fourteen .main-content-label {
    font-size: .875rem;
    margin-bottom: 20px
}

@media (min-width:768px) {
    .card-dashboard-fourteen {
        height: 100%
    }
}

.card-dashboard-eight .card-dashboard-fourteen .card-title,
.card-dashboard-fourteen .card-dashboard-eight .card-title,
.card-table-two .card-dashboard-fourteen .card-title {
    font-size: .875rem;
    margin-bottom: 20px
}

.card-dashboard-fourteen .card-table-two .card-title span,
.card-dashboard-fourteen .main-content-label span {
    font-weight: 400;
    color: #97a3b9;
    text-transform: none
}

.card-dashboard-eight .card-dashboard-fourteen .card-title span,
.card-dashboard-fourteen .card-dashboard-eight .card-title span,
.card-table-two .card-dashboard-fourteen .card-title span {
    font-weight: 400;
    color: #97a3b9;
    text-transform: none
}

.card-dashboard-fourteen .card-body {
    padding: 0;
    min-height: 100px
}

.card-dashboard-fourteen .card-body h1 {
    color: #1c273c;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    letter-spacing: -1px;
    margin-bottom: 0
}

.card-dashboard-fourteen .card-body h1 span {
    color: #97a3b9;
    letter-spacing: normal;
    font-weight: 400;
    margin-right: 5px
}

.card-dashboard-fourteen .card-body h4 {
    color: #1c273c;
    font-weight: 700;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin-bottom: 0;
    letter-spacing: -.5px
}

.card-dashboard-fourteen .card-body .progress {
    margin-bottom: 2px
}

.card-dashboard-fourteen .card-body small {
    font-size: 11px;
    color: #97a3b9
}

.card-dashboard-fourteen .sparkline-wrapper canvas {
    width: 100%!important
}

.card-dashboard-fourteen .card-footer {
    background-color: transparent;
    padding: 0;
    margin-top: 15px;
    border-top-width: 0
}

.card-dashboard-fourteen .card-footer h6 {
    color: #1c273c;
    font-size: 20px;
    font-weight: 700;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin-bottom: 0;
    letter-spacing: -.5px
}

.card-dashboard-fourteen .card-footer label {
    display: block;
    margin-bottom: 0;
    color: #97a3b9;
    font-size: 11px
}

@media (min-width:576px) {
    .card-dashboard-fourteen .card-footer label {
        font-size: .875rem
    }
}

.main-body-sidebar.main-light .main-sidebar {
    background-color: #f4f5f8;
    border-right-color: #e2e8f5;
    box-shadow: 2px 0 3px rgba(28, 39, 60, .04)
}

.main-content-dashboard-five {
    background-color: #eeeff4;
    transition: all .4s
}

.main-content-dashboard-five .main-header {
    background-color: #fafcff;
    box-shadow: 0 2px 3px rgba(28, 39, 60, .05)
}

.main-content-dashboard-five .main-header-menu-icon {
    margin-right: 0
}

.main-content-dashboard-five .main-header-center .form-control {
    border-color: #e2e8f5
}

.main-content-dashboard-five .main-content-header {
    padding: 20px 20px 0
}

.main-content-dashboard-five .main-content-body {
    padding: 20px
}

.main-content-dashboard-five .card {
    background-color: #fafcff;
    border-color: #e2e8f5;
    box-shadow: 0 0 10px rgba(28, 39, 60, .1)
}

.main-content-dashboard-five .card-header {
    padding: 20px 20px 0;
    background-color: transparent
}

.main-content-dashboard-five .card-text {
    font-size: 12px;
    line-height: 1.4;
    color: #97a3b9;
    margin-bottom: 0
}

@media (prefers-reduced-motion:reduce) {
    .main-content-dashboard-five {
        transition: none
    }
}

@media (min-width:1200px) {
    .main-content-dashboard-five .main-content-header {
        padding: 25px 25px 0
    }
}

.card-dashboard-fifteen {
    padding: 20px 20px 0;
    overflow: hidden
}

.card-dashboard-fifteen h1 {
    font-size: 40px;
    font-weight: 500;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    margin-bottom: 0;
    line-height: 1
}

.card-dashboard-fifteen h1 span {
    display: inline;
    font-size: 18px;
    color: #7987a1
}

.card-dashboard-fifteen label {
    font-size: 14px;
    font-weight: 500;
    display: block
}

.card-dashboard-fifteen span {
    color: #97a3b9;
    display: block;
    font-size: 12px
}

.card-dashboard-fifteen .chart-wrapper {
    position: relative;
    margin-left: -32px;
    margin-right: -32px;
    margin-bottom: -10px
}

.card-dashboard-fifteen .flot-chart {
    width: 100%;
    height: 127px;
    position: relative;
    margin-top: 20px
}

.card-dashboard-fifteen .flot-chart .flot-x-axis>div:nth-child(4) {
    color: #1c273c!important
}

.table-talk-time {
    margin-bottom: 0
}

.table-talk-time thead>tr td,
.table-talk-time thead>tr th {
    padding: 7px 10px
}

.table-talk-time tbody>tr td,
.table-talk-time tbody>tr th {
    padding: 7px 10px
}

.table-talk-time thead>tr td:first-child,
.table-talk-time thead>tr th:first-child {
    padding-left: 20px
}

.table-talk-time tbody>tr td:first-child,
.table-talk-time tbody>tr th:first-child {
    padding-left: 20px
}

.card-dashboard-sixteen .main-img-user::after {
    display: none
}

.card-dashboard-sixteen .table tbody>tr {
    background-color: transparent
}

.card-dashboard-sixteen .table tbody>tr td,
.card-dashboard-sixteen .table tbody>tr th {
    padding: 10px 10px 10px 0;
    vertical-align: middle;
    white-space: nowrap
}

.card-dashboard-sixteen .table tbody>tr td:first-child,
.card-dashboard-sixteen .table tbody>tr th:first-child {
    width: 10%
}

.card-dashboard-sixteen .table tbody>tr td:nth-child(2),
.card-dashboard-sixteen .table tbody>tr th:nth-child(2) {
    width: 60%
}

.card-dashboard-sixteen .table tbody>tr td:last-child,
.card-dashboard-sixteen .table tbody>tr th:last-child {
    width: 30%
}

.dashboard-five-stacked-chart {
    height: 200px
}

@media (min-width:768px) {
    .dashboard-five-stacked-chart {
        height: 240px
    }
}

@media (min-width:847px) {
    .dashboard-five-stacked-chart {
        height: 260px
    }
}

@media (min-width:992px) {
    .dashboard-five-stacked-chart {
        height: 240px
    }
}

@media (min-width:1067px) {
    .dashboard-five-stacked-chart {
        height: 260px
    }
}

.main-light .main-iconbar,
.main-light .main-iconbar-aside {
    background-color: #e3e7ed
}

.main-light .main-iconbar-body .nav {
    border-left-color: #e2e8f5
}

.main-light .main-iconbar-body .nav-item+.nav-item,
.main-light .main-iconbar-body .nav-sub,
.main-light .main-iconbar-body .nav-sub-item+.nav-sub-item {
    border-top-color: #bdc6d6
}

@media (min-width:992px) and (max-width:1199.98px) {
    .main-light .main-iconbar-aside.show+.main-content {
        box-shadow: none
    }
}

.main-content-dashboard-six {
    border-left-color: #e2e8f5;
    box-shadow: 0 0 7px rgba(28, 39, 60, .08);
    position: relative;
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #f4f5f8
}

@media (min-width:992px) {
    .main-content-dashboard-six {
        border-left: 1px solid #e2e8f5;
        margin-left: 64px;
        z-index: 550
    }
}

.main-header-dashboard-six {
    background-color: #fff;
    border-bottom: 1px solid #e2e8f5;
    box-shadow: 0 0 10px rgba(28, 39, 60, .06)
}

.main-header-dashboard-six .main-header-menu-icon {
    margin-right: 0
}

@media (min-width:992px) and (max-width:1199.98px) {
    .main-header-dashboard-six .main-header-center {
        margin-left: 5px
    }
}

@media (min-width:1200px) {
    .main-header-dashboard-six .main-header-center {
        margin-left: 0
    }
}

@media (min-width:992px) {
    .main-header-dashboard-six .main-header-menu-icon {
        display: none
    }
}

.main-content-body-dashboard-six {
    padding: 25px
}

.main-content-body-dashboard-six .main-content-title {
    letter-spacing: -.8px
}

.main-content-body-dashboard-six .card {
    border-color: #e2e8f5;
    box-shadow: 0 0 10px rgba(28, 39, 60, .06)
}

.main-content-body-dashboard-six .card-header {
    background-color: transparent;
    padding: 20px 20px 0
}

.main-content-body-dashboard-six .card-title {
    text-transform: uppercase;
    font-weight: 700
}

.card-dashboard-donut .card-body {
    padding: 25px 20px;
    display: flex;
    align-items: center;
    justify-content: center
}

.card-dashboard-donut .main-donut-chart.chart1 {
    width: 140px;
    height: 140px;
    background: #e2e8f5
}

.card-dashboard-donut .main-donut-chart.chart1 .slice.one {
    clip: rect(0 140px 70px 0);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    background: #6f42c1
}

.card-dashboard-donut .main-donut-chart.chart1 .slice.two {
    clip: rect(0 70px 140px 0);
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    background: #6f42c1
}

.card-dashboard-donut .main-donut-chart.chart1 .chart-center {
    top: 10px;
    left: 10px;
    width: 120px;
    height: 120px;
    background: #fff
}

.card-dashboard-donut .main-donut-chart.chart1 .chart-center span {
    font-size: 40px;
    line-height: 120px;
    color: #6f42c1
}

.card-dashboard-donut .main-donut-chart.chart1 .chart-center span:after {
    content: "75%"
}

.card-dashboard-donut .main-donut-chart.chart2 {
    width: 140px;
    height: 140px;
    background: #e2e8f5
}

.card-dashboard-donut .main-donut-chart.chart2 .slice.one {
    clip: rect(0 140px 70px 0);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    background: #078586
}

.card-dashboard-donut .main-donut-chart.chart2 .slice.two {
    clip: rect(0 70px 140px 0);
    -webkit-transform: rotate(244.8deg);
    transform: rotate(244.8deg);
    background: #078586
}

.card-dashboard-donut .main-donut-chart.chart2 .chart-center {
    top: 10px;
    left: 10px;
    width: 120px;
    height: 120px;
    background: #fff
}

.card-dashboard-donut .main-donut-chart.chart2 .chart-center span {
    font-size: 40px;
    line-height: 120px;
    color: #078586
}

.card-dashboard-donut .main-donut-chart.chart2 .chart-center span:after {
    content: "68%"
}

.card-dashboard-donut .main-donut-chart.chart1 .chart-center span,
.card-dashboard-donut .main-donut-chart.chart2 .chart-center span {
    font-weight: 600;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 32px
}

.card-dashboard-balance {
    position: relative;
    overflow: hidden
}

.card-dashboard-balance .card-body {
    padding: 20px;
    position: relative;
    z-index: 5
}

.card-dashboard-balance .fab {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 48px;
    color: #078586;
    z-index: 5;
    background-color: #fff
}

.card-dashboard-balance .card-table-two .card-title,
.card-dashboard-balance .main-content-label {
    color: #7987a1;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px
}

@media (min-width:576px) {
    .card-dashboard-balance {
        min-height: 260px;
        height: 100%
    }
}

@media (min-width:576px) {
    .card-dashboard-balance .card-body {
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between
    }
}

.card-dashboard-balance .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-balance .card-title,
.card-table-two .card-dashboard-balance .card-title {
    color: #7987a1;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px
}

@media (min-width:576px) {
    .card-dashboard-balance .card-table-two .card-title,
    .card-dashboard-balance .main-content-label {
        font-size: 11px
    }
    .card-dashboard-balance .card-dashboard-eight .card-title,
    .card-dashboard-eight .card-dashboard-balance .card-title,
    .card-table-two .card-dashboard-balance .card-title {
        font-size: 11px
    }
}

.card-dashboard-balance .balance-amount {
    color: #1c273c;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    font-size: 28px;
    letter-spacing: -1px;
    margin-bottom: 20px
}

.card-dashboard-balance .balance-amount span {
    color: #97a3b9;
    font-weight: 400;
    letter-spacing: normal
}

.card-dashboard-balance .account-number {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -1px;
    margin-bottom: 20px
}

.card-dashboard-balance .account-number span {
    display: flex;
    align-items: center;
    line-height: 1
}

.card-dashboard-balance .account-number span+span {
    margin-left: 20px
}

.card-dashboard-balance .account-number span i {
    width: 5px;
    height: 5px;
    background-color: #1c273c;
    border-radius: 100%
}

.card-dashboard-balance .account-number span i+i {
    margin-left: 5px
}

.card-dashboard-balance .account-name {
    color: #1c273c;
    font-weight: 400;
    margin-bottom: 0
}

.card-dashboard-balance .chart-wrapper {
    position: absolute;
    top: 20px;
    bottom: 10px;
    left: -10px;
    right: -10px;
    opacity: .3;
    z-index: 4
}

.card-dashboard-balance .flot-chart {
    width: 100%;
    height: 100%
}

@media (min-width:576px) {
    .card-dashboard-balance .balance-amount {
        font-size: 36px
    }
}

@media (min-width:576px) {
    .card-dashboard-balance .account-number {
        font-size: 28px
    }
}

@media (min-width:576px) {
    .card-dashboard-balance .account-number span+span {
        margin-left: 30px
    }
}

@media (min-width:1200px) {
    .card-dashboard-balance .account-number span+span {
        margin-left: 50px
    }
}

@media (min-width:576px) {
    .card-dashboard-balance .account-number span i {
        width: 10px;
        height: 10px
    }
}

@media (min-width:1200px) {
    .card-dashboard-ratio {
        height: 350px
    }
}

.card-dashboard-ratio .card-body {
    display: flex;
    align-items: center
}

.card-dashboard-ratio .card-body+.card-body {
    padding-top: 0
}

.card-dashboard-ratio .card-body>div:first-child {
    width: 140px;
    display: none;
    align-items: center;
    justify-content: center
}

.card-dashboard-ratio .card-body>div:last-child {
    flex: 1
}

.card-dashboard-ratio .card-body h5 {
    font-size: 28px;
    font-weight: 600;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -1px;
    margin-bottom: 2px
}

.card-dashboard-ratio .card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e2e8f5;
    font-size: 140px
}

.card-dashboard-ratio .card-icon .typcn {
    line-height: 1
}

.card-dashboard-ratio .card-icon .typcn::before {
    width: auto
}

@media (min-width:576px) {
    .card-dashboard-ratio .card-body>div:first-child {
        display: flex
    }
}

@media (min-width:576px) {
    .card-dashboard-ratio .card-body>div:last-child {
        margin-left: 30px
    }
}

.card-dashboard-finance {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end
}

.card-dashboard-finance .card-title {
    margin-bottom: 20px
}

.card-dashboard-finance h2 {
    line-height: 1;
    color: #1c273c;
    font-weight: 600;
    font-size: 30px;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: -1px;
    margin-top: 10px;
    margin-bottom: 2px
}

.card-dashboard-finance h2 span {
    letter-spacing: normal;
    color: #97a3b9;
    font-weight: 400;
    margin-right: 5px
}

@media (min-width:992px) {
    .card-dashboard-finance {
        min-height: 172px
    }
}

@media (min-width:1200px) {
    .card-dashboard-finance {
        min-height: 165px
    }
}

@media (min-width:992px) {
    .card-dashboard-finance .card-title {
        margin-bottom: auto
    }
}

.card-dashboard-table-six .card-title {
    padding: 15px 20px;
    margin-bottom: 0;
    border-bottom: 1px solid #e2e8f5
}

.card-dashboard-table-six .table-responsive {
    width: calc(100vw - 42px)
}

.card-dashboard-table-six .table {
    margin-bottom: 0
}

.card-dashboard-table-six .table thead tr th {
    white-space: nowrap
}

.card-dashboard-table-six .table thead tr:first-child {
    background-color: #e3e7ed
}

.card-dashboard-table-six .table thead tr:first-child th {
    padding: 10px 12px;
    line-height: 1
}

.card-dashboard-table-six .table thead tr:first-child th:last-child {
    border-left: 1px solid #e2e8f5
}

.card-dashboard-table-six .table thead tr:last-child th {
    width: 13%;
    padding: 10px 12px;
    line-height: 1;
    text-align: right
}

.card-dashboard-table-six .table thead tr:last-child th:first-child {
    width: 22%
}

.card-dashboard-table-six .table thead tr:last-child th:nth-child(2),
.card-dashboard-table-six .table thead tr:last-child th:nth-child(6) {
    border-left: 1px solid #e2e8f5
}

.card-dashboard-table-six .table tbody tr td {
    text-align: right;
    white-space: nowrap
}

.card-dashboard-table-six .table tbody tr td:first-child {
    text-align: left
}

.card-dashboard-table-six .table tbody tr td:nth-child(2),
.card-dashboard-table-six .table tbody tr td:nth-child(6) {
    border-left: 1px solid #e2e8f5
}

.card-dashboard-table-six .table tbody tr:last-child td {
    font-weight: 500;
    color: #1c273c
}

@media (min-width:992px) {
    .card-dashboard-table-six .table-responsive {
        width: 100%
    }
}

.main-minimal {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff
}

.main-minimal .main-navbar-three .nav-item.active::before {
    bottom: -3px
}

.main-minimal .main-navbar-three .nav-item.active .nav-link {
    font-weight: 700
}

.main-minimal .main-navbar-three .nav-link {
    font-size: 13px
}

.main-minimal.main-navbar-show .main-header {
    z-index: auto
}

@media (min-width:1200px) {
    .main-minimal {
        padding: 10px 0 0
    }
}

.main-content-title-minimal {
    color: #1c273c;
    font-weight: 500;
    font-size: 22px;
    letter-spacing: -1px;
    line-height: 1;
    margin-bottom: 8px
}

.main-content-title-minimal span {
    font-weight: 700
}

.main-content-text-minimal {
    font-weight: 400;
    color: #596882;
    margin-bottom: 20px
}

.media-dashboard-one {
    display: block
}

.media-dashboard-one .media-right {
    width: 100%;
    flex-shrink: 0
}

.media-dashboard-one .media-body {
    margin-bottom: 20px
}

@media (min-width:992px) {
    .media-dashboard-one {
        display: flex;
        align-items: stretch
    }
}

@media (min-width:992px) {
    .media-dashboard-one .media-right {
        width: 280px
    }
}

@media (min-width:992px) {
    .media-dashboard-one .media-body {
        margin-right: 20px;
        margin-bottom: 0
    }
}

.card-minimal-one {
    border-color: #e2e8f5;
    min-height: 320px;
    height: 100%;
    overflow: hidden;
    position: relative
}

.card-minimal-one .card-body {
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 5
}

.card-minimal-one .card-body>label {
    font-weight: 700;
    font-size: 14px;
    color: #078586;
    text-transform: uppercase;
    margin-bottom: 5px;
    display: block;
    line-height: 1
}

.card-minimal-one .card-body>label span {
    font-weight: 500;
    color: #596882
}

.card-minimal-one .card-body>small {
    font-size: 12px;
    display: block;
    color: #97a3b9
}

.card-minimal-one .card-body>h6 {
    color: #1c273c;
    font-size: 36px;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    letter-spacing: -2px;
    display: flex;
    line-height: 1;
    align-items: flex-end;
    margin: 15px 0
}

.card-minimal-one .card-body>h6 span {
    font-weight: 400;
    margin-right: 3px;
    color: #031b4e
}

.card-minimal-one .value-info {
    display: flex;
    align-items: center
}

.card-minimal-one .value-info+.value-info {
    margin-top: 5px
}

.card-minimal-one .value-info p {
    margin-bottom: 0;
    color: #1c273c;
    font-weight: 700;
    font-family: Archivo, sans-serif;
    font-size: 14px;
    letter-spacing: -.35px
}

.card-minimal-one .value-info p span {
    letter-spacing: normal;
    margin-right: 2px;
    color: #031b4e
}

.card-minimal-one .value-info label {
    display: block;
    margin-bottom: 0;
    font-weight: 700;
    width: 40px;
    position: relative;
    top: -2px
}

.card-minimal-one .open-info {
    display: flex;
    align-items: flex-start;
    margin-top: auto;
    margin-bottom: 25px
}

.card-minimal-one .open-info>div+div {
    margin-left: 25px
}

.card-minimal-one .open-info label {
    font-size: 12px;
    margin-bottom: 2px;
    color: #7f8da9
}

.card-minimal-one .open-info h6 {
    margin-bottom: 0;
    color: #1c273c;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 13px
}

.card-minimal-one .open-info h6 span {
    font-weight: 400
}

.card-minimal-one .chart-wrapper {
    position: absolute;
    left: -9px;
    right: -8px;
    bottom: -13px
}

.card-minimal-one .chart-wrapper .flot-x-axis>div {
    transform: translate(20px, -23px)
}

.card-minimal-one .flot-chart {
    width: 100%;
    height: 250px;
    position: relative
}

.card-minimal-two {
    height: 72px;
    border-color: #e2e8f5;
    background-color: transparent;
    overflow: hidden
}

.card-minimal-two .card-header {
    background-color: transparent;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e2e8f5
}

.card-minimal-two .card-header>.nav .nav-link {
    padding: 8px 18px;
    font-weight: 500;
    background-color: #e3e7ed
}

.card-minimal-two .card-header>.nav .nav-link+.nav-link {
    margin-left: 2px
}

.card-minimal-two .card-header>.nav .nav-link:focus,
.card-minimal-two .card-header>.nav .nav-link:hover {
    background-color: #e2e8f5
}

.card-minimal-two .card-header>.nav .nav-link.active {
    background-color: #078586;
    color: #fff
}

.card-minimal-two .card-header-right {
    display: flex;
    align-items: center;
    margin-top: 20px
}

.card-minimal-two .card-header-right .nav {
    justify-content: flex-end;
    width: 100%
}

.card-minimal-two .card-header-right .nav-link {
    padding: 5px 10px;
    text-transform: capitalize
}

.card-minimal-two .card-header-right .nav-link+.nav-link {
    margin-left: 2px
}

.card-minimal-two .card-header-right .nav-link span:first-child {
    display: none
}

.card-minimal-two .card-header-right .nav-link span:last-child {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500
}

.card-minimal-two .card-header-right .nav-link:focus,
.card-minimal-two .card-header-right .nav-link:hover {
    background-color: #e3e7ed
}

.card-minimal-two .card-header-right .nav-link.active {
    background-color: #97a3b9;
    color: #fff
}

.card-minimal-two .card-body {
    position: relative;
    overflow: hidden;
    padding: 0
}

.card-minimal-two .flot-wrapper {
    position: relative;
    bottom: -14px;
    margin-right: -9px;
    margin-left: -31px;
    margin-top: -25px
}

.card-minimal-two .flot-wrapper .flot-x-axis>div {
    transform: translate(22px, -23px)
}

.card-minimal-two .flot-wrapper .flot-y-axis>div {
    transform: translateX(-10px)
}

.card-minimal-two .flot-chart {
    width: 100%;
    height: 250px;
    position: relative
}

.card-minimal-two .more-info {
    position: absolute;
    top: 10px;
    right: 10px
}

.card-minimal-two .more-info div {
    display: flex;
    align-items: flex-start;
    font-size: 13px
}

.card-minimal-two .more-info span:first-child {
    width: 80px;
    text-align: right;
    color: #7987a1;
    display: block;
    line-height: 1.35
}

.card-minimal-two .more-info span:last-child {
    margin-left: 15px;
    color: #1c273c;
    font-weight: 700;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 14px
}

.card-minimal-two .card-footer {
    padding: 20px;
    position: relative;
    z-index: 2;
    overflow-y: hidden;
    width: calc(100vw - 40px);
    margin-bottom: -8px
}

@media (min-width:576px) {
    .card-minimal-two .card-header {
        display: flex;
        padding: 15px 20px
    }
}

@media (min-width:992px) {
    .card-minimal-two .card-header>.nav .nav-link {
        padding: 8px 20px
    }
}

@media (min-width:1200px) {
    .card-minimal-two .card-header>.nav .nav-link {
        padding: 8px 30px
    }
}

@media (min-width:576px) {
    .card-minimal-two .card-header-right {
        margin-top: 0
    }
}

@media (min-width:576px) {
    .card-minimal-two .card-header-right .nav-link {
        padding: 8px 10px
    }
}

@media (min-width:768px) {
    .card-minimal-two .card-header-right .nav-link span:first-child {
        display: inline
    }
}

@media (min-width:768px) {
    .card-minimal-two .card-header-right .nav-link span:last-child {
        display: none
    }
}

@media (min-width:992px) {
    .card-minimal-two .card-body {
        padding: 0 20px
    }
}

@media (min-width:992px) {
    .card-minimal-two .flot-wrapper {
        margin-right: -9px;
        margin-left: 10px;
        margin-top: 0
    }
}

@media (min-width:992px) {
    .card-minimal-two .flot-chart {
        height: 300px
    }
}

@media (min-width:576px) {
    .card-minimal-two .more-info {
        right: 20px;
        top: 30px
    }
}

@media (min-width:992px) {
    .card-minimal-two .more-info {
        right: 30px;
        top: 50px
    }
}

@media (min-width:992px) {
    .card-minimal-two .card-footer {
        width: auto
    }
}

.card-bitcoin .media-icon {
    color: #f7931a;
    font-size: 42px;
    line-height: 0
}

.card-bitcoin .media-body {
    margin-left: 20px;
    margin-bottom: 10px
}

.card-bitcoin .row {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto
}

.card-bitcoin .row>div {
    flex-shrink: 0
}

.card-bitcoin label {
    margin-bottom: 2px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: .5px;
    color: #7987a1
}

.card-bitcoin p {
    margin-bottom: 0;
    color: #1c273c;
    font-weight: 700;
    font-size: 16px;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.card-minimal-three {
    border-color: #e2e8f5;
    width: calc(100vw - 38px)
}

.card-minimal-three .card-header {
    border-bottom: 1px solid #e2e8f5;
    background-color: transparent;
    padding: 20px 20px 0
}

.card-minimal-three .card-header .main-content-text {
    margin-bottom: 20px
}

.card-minimal-three .main-nav-tabs {
    padding: 0;
    background-color: transparent;
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 3px
}

.card-minimal-three .main-nav-tabs .lSAction>a {
    background-color: #e2e8f5
}

.card-minimal-three .main-nav-tabs .lSAction>a::before {
    top: -4px
}

.card-minimal-three .main-nav-tabs .lSAction>a.lSPrev {
    left: -35px
}

.card-minimal-three .main-nav-tabs .tab-item {
    min-width: 203px
}

.card-minimal-three .main-nav-tabs .tab-link {
    background-color: #e3e7ed;
    font-weight: 500;
    align-items: flex-start;
    padding-top: 8px
}

.card-minimal-three .main-nav-tabs .tab-link:hover {
    background-color: #e2e8f5
}

.card-minimal-three .main-nav-tabs .tab-link.active {
    background-color: #596882;
    color: #fff
}

.card-minimal-three .main-nav-tabs .tab-link.active:focus,
.card-minimal-three .main-nav-tabs .tab-link.active:hover {
    background-color: #596882;
    color: #fff
}

.card-minimal-three .card-body {
    padding: 0
}

.card-minimal-three .tab-pane-header {
    padding: 20px;
    background-color: #fff;
    border-bottom: 1px solid #e2e8f5
}

.card-minimal-three .tab-pane-header .card-table-two .card-title,
.card-minimal-three .tab-pane-header .main-content-label {
    margin-bottom: 3px
}

@media (min-width:992px) {
    .card-minimal-three {
        width: 634px
    }
}

@media (min-width:1200px) {
    .card-minimal-three {
        width: 753px
    }
}

@media (min-width:576px) {
    .card-minimal-three .main-nav-tabs .tab-item {
        min-width: 150px
    }
}

@media (min-width:576px) {
    .card-minimal-three .tab-pane-header {
        display: flex;
        align-items: center;
        justify-content: space-between
    }
}

.card-dashboard-eight .card-minimal-three .tab-pane-header .card-title,
.card-minimal-three .tab-pane-header .card-dashboard-eight .card-title,
.card-table-two .card-minimal-three .tab-pane-header .card-title {
    margin-bottom: 3px
}

.card-minimal-three .tab-pane-header>div:last-child {
    display: flex;
    margin-top: 15px
}

.card-minimal-three .tab-pane-header>div:last-child a {
    flex: 1
}

.card-minimal-three .tab-pane-header>div:last-child a+a {
    margin-left: 10px
}

.card-minimal-three .tab-pane-body {
    padding: 20px
}

.card-minimal-three .tab-pane-body .table thead tr td,
.card-minimal-three .tab-pane-body .table thead tr th {
    white-space: nowrap
}

.card-minimal-three .tab-pane-body .table tbody tr td,
.card-minimal-three .tab-pane-body .table tbody tr th {
    white-space: nowrap
}

.card-minimal-three .no-transactions-panel {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.card-minimal-three .no-transactions-panel .icon-wrapper {
    width: 80px;
    height: 80px;
    background-color: #e3e7ed;
    color: #97a3b9;
    font-size: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    border-radius: 100%
}

.card-minimal-three .no-transactions-panel .icon-wrapper i {
    line-height: 0
}

.card-minimal-three .no-transactions-panel h6 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px;
    color: #1c273c;
    letter-spacing: 1px;
    margin-bottom: 2px
}

.card-minimal-three .table tbody tr td {
    vertical-align: middle
}

.card-minimal-three .table tbody tr td span {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%
}

.card-minimal-three .table tbody tr td:last-child {
    font-weight: 500;
    color: #1c273c
}

.card-crypto-portfolio {
    width: 100%;
    border-color: #e2e8f5;
    margin-top: 20px
}

.card-crypto-portfolio .card-title {
    text-transform: uppercase
}

.card-crypto-portfolio .table {
    margin-bottom: 0
}

.card-crypto-portfolio .table thead tr td,
.card-crypto-portfolio .table thead tr th {
    padding: 10px 0;
    text-align: right;
    white-space: nowrap
}

.card-crypto-portfolio .table tbody tr td,
.card-crypto-portfolio .table tbody tr th {
    padding: 10px 0;
    text-align: right;
    white-space: nowrap
}

.card-crypto-portfolio .table thead tr td:first-child,
.card-crypto-portfolio .table thead tr th:first-child {
    text-align: left
}

.card-crypto-portfolio .table tbody tr td:first-child,
.card-crypto-portfolio .table tbody tr th:first-child {
    text-align: left
}

.card-crypto-portfolio .table thead tr th {
    font-size: 10px
}

.card-crypto-portfolio .table tbody tr td:first-child {
    font-weight: 500;
    color: #1c273c
}

@media (min-width:992px) {
    .card-crypto-portfolio {
        width: calc(33.33333% - 13px);
        margin-top: 0
    }
}

.card-minimal-four {
    border-color: #e2e8f5
}

.card-minimal-four .card-header {
    background-color: transparent;
    border-bottom: 1px solid #e2e8f5
}

.card-minimal-four .card-header h6 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 2px;
    color: #1c273c
}

.card-minimal-four .card-header p {
    margin-bottom: 0;
    white-space: nowrap
}

.card-minimal-four .card-header p span {
    color: #ee335e
}

.card-minimal-four .card-header .media {
    align-items: center
}

.card-minimal-four .card-header .media-body {
    margin-left: 12px
}

.card-minimal-four .coin-logo {
    width: 42px;
    height: 42px;
    color: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px
}

.card-minimal-four .coin-logo i {
    line-height: 0
}

.card-minimal-four .card-body {
    padding: 0;
    overflow: hidden;
    position: relative;
    background-color: #fafcff
}

.card-minimal-four .card-body-top {
    top: 15px;
    left: 10px;
    position: absolute;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 10
}

.card-minimal-four .card-body-top a {
    color: #1c273c;
    font-weight: 700;
    width: 20px;
    text-align: right;
    display: inline-block
}

.card-minimal-four .card-body-top a:focus,
.card-minimal-four .card-body-top a:hover {
    color: #078586
}

.card-minimal-four .card-body-top div+div {
    margin-top: 5px
}

.card-minimal-four .chart-wrapper {
    position: relative;
    margin-left: -20px;
    margin-right: -9px;
    margin-bottom: -17px
}

.card-minimal-four .flot-chart {
    width: 100%;
    height: 150px
}

.card-minimal-four .card-footer {
    background-color: transparent;
    border-top-color: #e2e8f5;
    padding: 0
}

.card-minimal-four .card-footer .nav-link {
    flex: 1;
    display: block;
    text-align: center;
    background-color: #e3e7ed;
    position: relative;
    padding-left: 10px;
    padding-right: 10px
}

.card-minimal-four .card-footer .nav-link:focus,
.card-minimal-four .card-footer .nav-link:hover {
    background-color: #eeeff4
}

.card-minimal-four .card-footer .nav-link span {
    display: block
}

.card-minimal-four .card-footer .nav-link span:first-child {
    color: #97a3b9;
    font-weight: 700;
    font-size: 11px
}

.card-minimal-four .card-footer .nav-link span:last-child {
    color: #031b4e;
    font-weight: 500;
    font-size: 11px;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.card-minimal-four .card-footer .nav-link+.nav-link {
    border-left: 1px solid #e2e8f5
}

.card-minimal-four .card-footer .nav-link.active {
    background-color: #fafcff
}

.card-minimal-four .card-footer .nav-link.active::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    border-top: 1px solid #f7f7f7;
    z-index: 5
}

.card-minimal-four .card-footer .nav-link.active span:first-child {
    color: #1c273c
}

.main-dashboard-eight {
    background-color: #dde2e9
}

.main-header-primary {
    background-color: #0040ff
}

.main-header-primary .main-header-center .form-control {
    background-color: #03c;
    border-width: 0;
    height: 38px;
    border-radius: 2px;
    color: #fff
}

.main-header-primary .main-header-center .form-control::placeholder {
    color: rgba(255, 255, 255, .4)
}

.main-header-primary .main-header-center .form-control:focus+.btn {
    color: #fff
}

.main-header-primary .main-header-center .sp-container .form-control:focus+button {
    color: #fff
}

.sp-container .main-header-primary .main-header-center .form-control:focus+button {
    color: #fff
}

.main-header-primary .main-header-center .btn,
.main-header-primary .main-header-center .sp-container button {
    font-size: 14px
}

.sp-container .main-header-primary .main-header-center button {
    font-size: 14px
}

.main-header-primary .main-header-center .btn:hover,
.main-header-primary .main-header-center .sp-container button:hover {
    color: #fff
}

.sp-container .main-header-primary .main-header-center button:hover {
    color: #fff
}

.main-header-primary .main-header-center .btn:focus,
.main-header-primary .main-header-center .sp-container button:focus {
    color: #fff
}

.sp-container .main-header-primary .main-header-center button:focus {
    color: #fff
}

.main-header-primary .main-logo {
    color: #fff;
    font-weight: 600;
    letter-spacing: -1.2px
}

.main-header-primary .main-logo span {
    color: rgba(255, 255, 255, .65)
}

.main-header-primary .main-header-menu-icon span {
    background-color: rgba(255, 255, 255, .65)
}

.main-header-primary .main-header-menu-icon span::after,
.main-header-primary .main-header-menu-icon span::before {
    background-color: rgba(255, 255, 255, .65)
}

.main-header-primary .main-header-message>a {
    color: rgba(255, 255, 255, .75)
}

.main-header-primary .main-header-notification>a {
    color: rgba(255, 255, 255, .75)
}

.main-header-primary .main-header-notification>a::after {
    display: none
}

.main-header-primary .main-header-notification .dropdown-menu {
    border-width: 0;
    box-shadow: 0 0 5px rgba(28, 39, 60, .1)
}

.main-header-primary .main-profile-menu .dropdown-menu {
    border-width: 0;
    box-shadow: 0 0 5px rgba(28, 39, 60, .1)
}

.main-header-primary .main-profile-menu.show .main-img-user::before {
    display: none
}

@media (min-width:576px) {
    .main-header-primary .main-header-notification .dropdown-menu {
        top: 43px
    }
}

@media (min-width:992px) {
    .main-header-primary .main-header-notification .dropdown-menu {
        top: 45px
    }
}

@media (min-width:576px) {
    .main-header-primary .main-profile-menu .dropdown-menu {
        top: 46px
    }
}

@media (min-width:992px) {
    .main-header-primary .main-profile-menu .dropdown-menu {
        top: 48px
    }
}

.main-navbar-dashboard-eight {
    background-color: #f9f9f9;
    border-bottom-width: 0
}

.main-navbar-dashboard-eight .main-navbar-header {
    background-color: #0040ff;
    border-bottom-width: 0;
    color: #fff
}

.main-navbar-dashboard-eight .main-navbar-header .main-logo {
    color: #fff
}

.main-navbar-dashboard-eight .nav-item.active::before {
    border-bottom-color: #1a53ff
}

.main-navbar-dashboard-eight .nav-item.active .nav-link {
    color: #1a53ff
}

.main-navbar-dashboard-eight .nav-link {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px
}

.main-navbar-dashboard-eight .nav-sub:not(.nav-sub-mega) {
    background-color: #f9f9f9;
    border-width: 0
}

.main-navbar-dashboard-eight .nav-sub:not(.nav-sub-mega)::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    height: 5px;
    background-color: #f9f9f9;
    z-index: 4
}

.main-navbar-dashboard-eight .nav-sub-item>.nav-sub {
    left: 180px
}

.main-navbar-dashboard-eight .nav-sub-item>.nav-sub::before {
    left: -5px;
    top: -5px;
    bottom: -5px;
    right: auto;
    width: 5px;
    height: auto
}

.main-navbar-dashboard-eight .nav-sub-item.active .nav-sub-link {
    color: #078586
}

.main-navbar-dashboard-eight .nav-sub-link:hover {
    color: #078586
}

.main-navbar-dashboard-eight .nav-sub-mega .container,
.main-navbar-dashboard-eight .nav-sub-mega .container-fluid {
    background-color: #f9f9f9;
    border-width: 0
}

@media (min-width:992px) {
    .main-navbar-dashboard-eight .nav-sub:not(.nav-sub-mega) {
        box-shadow: 0 0 5px rgba(28, 39, 60, .12)
    }
}

@media (min-width:992px) {
    .main-navbar-dashboard-eight .nav-sub-mega .container,
    .main-navbar-dashboard-eight .nav-sub-mega .container-fluid {
        box-shadow: 0 5px 3px -3px rgba(28, 39, 60, .12)
    }
}

.main-content-dashboard-eight {
    padding-top: 25px
}

.main-content-dashboard-eight .main-content-title {
    letter-spacing: -.8px
}

.main-content-dashboard-eight .card {
    border-width: 0;
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(28, 39, 60, .1)
}

.main-content-dashboard-eight .card-title {
    font-weight: 500
}

@media (max-width:575px) {
    .main-content-dashboard-eight .container {
        padding-left: 20px;
        padding-right: 20px
    }
}

.card-dashboard-seventeen {
    position: relative
}

.card-dashboard-seventeen .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 180px;
    position: relative;
    z-index: 10
}

.card-dashboard-seventeen .card-body h4 {
    margin-bottom: 0;
    font-weight: 700;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c
}

.card-dashboard-seventeen .card-body span {
    font-size: 11px
}

.card-dashboard-seventeen .card-title {
    line-height: 1.3
}

.card-dashboard-seventeen.bg-primary-dark {
    background-color: #03c
}

.card-dashboard-seventeen .chart-wrapper {
    position: absolute;
    right: -8px;
    bottom: -8px;
    left: -8px
}

.card-dashboard-seventeen .flot-chart {
    width: 100%;
    height: 180px
}

.card-dashboard-eighteen {
    display: block;
    padding: 20px
}

.card-dashboard-eighteen .card-title {
    font-weight: 700;
    text-transform: uppercase
}

.card-dashboard-eighteen .card-body {
    padding: 20px 0 34px;
    align-items: flex-start
}

.card-dashboard-eighteen .card-body h6 {
    color: #1c273c;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    font-size: 21px;
    letter-spacing: -.8px;
    margin-bottom: 2px;
    position: relative;
    padding-left: 23px
}

.card-dashboard-eighteen .card-body h6 span {
    font-weight: 400;
    color: #97a3b9;
    letter-spacing: normal
}

.card-dashboard-eighteen .card-body h6::before {
    content: '';
    position: absolute;
    display: block;
    top: calc(50% - 8px);
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 4px solid transparent
}

.card-dashboard-eighteen .card-body h6.dot-primary::before {
    border-color: #078586
}

.card-dashboard-eighteen .card-body h6.dot-purple::before {
    border-color: #6f42c1
}

.card-dashboard-eighteen .card-body h6.dot-teal::before {
    border-color: #0cc
}

.card-dashboard-eighteen .card-body h6.dot-dark-blue::before {
    border-color: #03c
}

.card-dashboard-eighteen .card-body label {
    color: #7987a1;
    margin-bottom: 0;
    display: block
}

.card-dashboard-eighteen .chartjs-wrapper {
    height: 309px;
    position: relative;
    margin-left: -10px;
    margin-right: -5px
}

.card-dashboard-nineteen {
    overflow: hidden;
    position: relative;
    background-color: #f9f9f9
}

.card-dashboard-nineteen .card-header {
    padding: 20px 20px 0;
    background-color: transparent
}

.card-dashboard-nineteen .card-header .row {
    display: block
}

.card-dashboard-nineteen .card-header .row>div {
    max-width: none
}

.card-dashboard-nineteen .card-header .row>div+div {
    margin-top: 20px
}

.card-dashboard-nineteen .card-header h4 {
    font-size: 24px;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    letter-spacing: -1px;
    color: #1c273c;
    margin-bottom: 10px
}

.card-dashboard-nineteen .card-header h4 span {
    font-weight: 400;
    letter-spacing: normal;
    color: #97a3b9
}

.card-dashboard-nineteen .card-header .card-table-two .card-title,
.card-dashboard-nineteen .card-header .main-content-label {
    text-transform: none;
    line-height: 1.3;
    margin-bottom: 5px;
    font-size: .875rem
}

@media (min-width:375px) {
    .card-dashboard-nineteen .card-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10
    }
}

@media (min-width:375px) {
    .card-dashboard-nineteen .card-header .row {
        display: flex
    }
}

@media (min-width:375px) {
    .card-dashboard-nineteen .card-header .row>div+div {
        margin-top: 0
    }
}

@media (min-width:576px) {
    .card-dashboard-nineteen .card-header h4 {
        font-size: 30px
    }
}

.card-dashboard-eight .card-dashboard-nineteen .card-header .card-title,
.card-dashboard-nineteen .card-header .card-dashboard-eight .card-title,
.card-table-two .card-dashboard-nineteen .card-header .card-title {
    text-transform: none;
    line-height: 1.3;
    margin-bottom: 5px;
    font-size: .875rem
}

@media (min-width:992px) {
    .card-dashboard-nineteen .card-header .card-table-two .card-title,
    .card-dashboard-nineteen .card-header .main-content-label {
        font-size: 14px
    }
    .card-dashboard-eight .card-dashboard-nineteen .card-header .card-title,
    .card-dashboard-nineteen .card-header .card-dashboard-eight .card-title,
    .card-table-two .card-dashboard-nineteen .card-header .card-title {
        font-size: 14px
    }
}

.card-dashboard-nineteen .card-header p {
    font-size: 11px;
    color: #7987a1;
    margin-bottom: 0
}

.card-dashboard-nineteen .card-header .btn,
.card-dashboard-nineteen .card-header .sp-container button {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: .5px;
    padding: 5px 20px;
    min-height: inherit;
    border-width: 2px
}

@media (min-width:576px) {
    .card-dashboard-nineteen .card-header p {
        font-size: 12px
    }
}

.sp-container .card-dashboard-nineteen .card-header button {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: .5px;
    padding: 5px 20px;
    min-height: inherit;
    border-width: 2px
}

.card-dashboard-nineteen .card-title {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 15px;
    color: #0040ff
}

.card-dashboard-nineteen .chart-legend {
    margin-top: 22px;
    display: flex;
    align-items: center
}

.card-dashboard-nineteen .chart-legend>div {
    position: relative;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: .5px
}

.card-dashboard-nineteen .chart-legend>div::before {
    content: '';
    position: relative;
    top: 1.5px;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px
}

.card-dashboard-nineteen .chart-legend>div:first-child::before {
    background-color: #05478f
}

.card-dashboard-nineteen .chart-legend>div:nth-child(2)::before {
    background-color: #2f93fe
}

.card-dashboard-nineteen .chart-legend>div:last-child::before {
    background-color: #bbdafc
}

.card-dashboard-nineteen .chart-legend>div+div {
    margin-left: 10px
}

.card-dashboard-nineteen .card-body {
    padding: 0;
    position: relative
}

.card-dashboard-nineteen .flot-chart-wrapper {
    position: relative;
    margin: -30px -8px -16px -47px
}

.card-dashboard-nineteen .flot-chart {
    width: 100%;
    height: 250px
}

.card-dashboard-nineteen .flot-chart .flot-x-axis {
    transform: translate(18px, -25px);
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    opacity: .5
}

.card-dashboard-nineteen .flot-chart .flot-y-axis {
    transform: translate(66px, -13px);
    color: rgba(0, 0, 0, .65);
    font-weight: 400;
    font-size: 10px;
    letter-spacing: .5px
}

@media (min-width:768px) {
    .card-dashboard-nineteen .chart-legend {
        margin-left: 70px
    }
}

@media (min-width:375px) {
    .card-dashboard-nineteen .chart-legend>div {
        letter-spacing: 1px
    }
}

@media (min-width:375px) {
    .card-dashboard-nineteen .chart-legend>div+div {
        margin-left: 30px
    }
}

@media (min-width:375px) and (max-width:575px) {
    .card-dashboard-nineteen .card-body {
        padding-top: 170px
    }
}

@media (min-width:576px) {
    .card-dashboard-nineteen .card-body {
        padding-top: 130px
    }
}

@media (min-width:768px) {
    .card-dashboard-nineteen .card-body {
        padding-top: 95px
    }
}

@media (min-width:375px) {
    .card-dashboard-nineteen .flot-chart-wrapper {
        margin-top: 0
    }
}

@media (min-width:576px) {
    .card-dashboard-nineteen .flot-chart {
        height: 314px
    }
}

.card-dashboard-twenty .card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start
}

.card-dashboard-twenty .chartjs-wrapper {
    width: 100%;
    height: 230px
}

.card-dashboard-twenty .expansion-value {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    font-weight: 700;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: -.5px;
    margin-bottom: 5px;
    margin-top: auto
}

.card-dashboard-twenty .expansion-value strong:first-child {
    color: #1c273c
}

.card-dashboard-twenty .expansion-value strong:last-child {
    color: #7987a1
}

.card-dashboard-twenty .progress {
    margin-bottom: 3px;
    height: 4px
}

.card-dashboard-twenty .expansion-label {
    display: flex;
    justify-content: space-between
}

.card-dashboard-twenty .expansion-label span {
    font-weight: 400;
    font-size: 11px;
    color: #97a3b9
}

@media (min-width:992px) and (max-width:1199.98px) {
    .card-dashboard-twenty .chartjs-wrapper {
        margin-top: auto
    }
}

.card-dashboard-progress .progress-legend {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center
}

.card-dashboard-progress .progress-legend li {
    position: relative;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    padding-left: 15px;
    line-height: .95
}

.card-dashboard-progress .progress-legend li::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    width: 8px;
    height: 8px
}

.card-dashboard-progress .progress-legend li:first-child::before {
    background-color: #078586
}

.card-dashboard-progress .progress-legend li:last-child::before {
    background-color: #0cc
}

.card-dashboard-progress .progress-legend li+li {
    margin-left: 20px
}

.card-dashboard-progress .media {
    display: block
}

.card-dashboard-progress .media>label {
    width: 50px;
    display: block;
    margin-bottom: 0
}

.card-dashboard-progress .media+.media {
    margin-top: 15px
}

.card-dashboard-progress .media-body {
    margin-top: 5px
}

.card-dashboard-progress .progress {
    background-color: #e3e7ed
}

.card-dashboard-progress .progress-bar {
    height: 15px;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600
}

.card-dashboard-progress .progress-bar+.progress-bar {
    margin-left: 1px
}

@media (min-width:576px) {
    .card-dashboard-progress .media {
        display: flex;
        align-items: center
    }
}

@media (min-width:576px) {
    .card-dashboard-progress .media-body {
        margin-top: 0;
        margin-left: 15px
    }
}

.main-body-dashboard-nine {
    background-color: #e2e8f5
}

@media (min-width:992px) {
    .main-body-dashboard-nine .main-header .container-fluid {
        padding-left: 20px;
        padding-right: 20px
    }
}

@media (min-width:992px) {
    .main-body-dashboard-nine.main-sidebar-hide .main-header-dashboard-nine {
        left: 0
    }
}

.main-sidebar-indigo-dark {
    background-color: #4130c5;
    border-right-width: 0
}

.main-sidebar-indigo-dark .main-logo {
    color: #fff;
    letter-spacing: -1.4px
}

.main-sidebar-indigo-dark .main-sidebar-loggedin .media-body h6 {
    color: #fff;
    font-weight: 500
}

.main-sidebar-indigo-dark .main-sidebar-loggedin .media-body span {
    color: rgba(255, 255, 255, .5)
}

.main-sidebar-indigo-dark .main-img-user::after {
    box-shadow: none
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-label {
    border-bottom-color: rgba(255, 255, 255, .2);
    color: rgba(255, 255, 255, .35);
    font-weight: 400
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-item+.nav-item {
    margin-top: -1px
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-item+.nav-item::before {
    border-top-color: rgba(255, 255, 255, .12)
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-item.active {
    position: relative;
    z-index: 5
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-item.active::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -20px;
    right: -20px;
    background-color: #33269c;
    height: 41px
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-item.active .nav-link {
    font-weight: 500;
    color: #fff
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-item.active .nav-link:focus,
.main-sidebar-indigo-dark .main-sidebar-body .nav-item.active .nav-link:hover {
    font-weight: 500;
    color: #fff
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-item.active .nav-link i,
.main-sidebar-indigo-dark .main-sidebar-body .nav-item.active .nav-link:focus i,
.main-sidebar-indigo-dark .main-sidebar-body .nav-item.active .nav-link:hover i {
    color: #fff
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-link {
    color: rgba(255, 255, 255, .45);
    font-weight: 400
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-link i {
    color: rgba(255, 255, 255, .75)
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-link.with-sub::after {
    opacity: .3
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-link:focus,
.main-sidebar-indigo-dark .main-sidebar-body .nav-link:hover {
    color: rgba(255, 255, 255, .75)
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-sub {
    border-left-color: rgba(255, 255, 255, .1);
    padding-left: 0;
    padding-bottom: 0;
    position: relative
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-sub::before {
    content: '';
    position: absolute;
    top: 0;
    left: -32px;
    right: -20px;
    bottom: 0;
    background-color: rgba(0, 0, 0, .1);
    z-index: 4
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-sub-item.active .nav-sub-link {
    color: #fff
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-sub-link {
    padding-left: 21px;
    color: rgba(255, 255, 255, .4);
    border-top-color: rgba(255, 255, 255, .1);
    position: relative;
    z-index: 5
}

.main-sidebar-indigo-dark .main-sidebar-body .nav-sub-link:focus,
.main-sidebar-indigo-dark .main-sidebar-body .nav-sub-link:hover {
    color: #fff
}

.main-header-dashboard-nine {
    background-color: #fff
}

.main-header-dashboard-nine .main-header-menu-icon {
    margin-right: 0
}

@media (min-width:992px) {
    .main-header-dashboard-nine {
        position: fixed;
        top: 0;
        left: 220px;
        right: 0;
        box-shadow: 0 0 15px rgba(28, 39, 60, .1)
    }
}

@media (min-width:1200px) {
    .main-header-dashboard-nine {
        left: 240px
    }
}

.main-content-1 {
    position: relative
}

.main-content-1 .main-content-header {
    display: block;
    padding: 20px;
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid #e3e8f1
}

.main-content-1 .main-content-header .nav {
    justify-content: flex-start
}

.main-content-1 .main-content-header .nav-link {
    color: #596882;
    font-weight: 500
}

.main-content-1 .main-content-header .nav-link:focus,
.main-content-1 .main-content-header .nav-link:hover {
    color: #1c273c
}

.main-content-1 .main-content-header .nav-link.active {
    color: #078586
}

.main-content-1 .main-content-header .nav-link.active::before {
    top: 34px;
    bottom: auto;
    background-color: #078586
}

.main-content-1 .main-content-header .nav-link+.nav-link {
    margin-top: 0;
    margin-left: 25px
}

.main-content-1 .main-content-header .nav-link:last-child {
    position: relative
}

.main-content-1 .main-content-header .nav-link:last-child::after {
    content: '';
    position: relative;
    display: inline-block;
    width: 25px
}

.main-content-1 .main-content-header-top {
    margin-bottom: 20px
}

.main-content-1 .main-content-title {
    letter-spacing: -.7px;
    font-size: 24px
}

.main-content-1 .main-content-body {
    padding: 20px
}

.main-content-1 .card {
    border-width: 0;
    box-shadow: 0 0 10px rgba(28, 39, 60, .1)
}

@media (min-width:992px) {
    .main-content-1 {
        margin-top: 64px
    }
}

@media (min-width:992px) {
    .main-content-1 .main-content-header {
        padding: 15.7px 21px;
        margin: 0 0 1.3rem 0
    }
}

@media (max-width:991.98px) {
    .main-content-1 .main-content-header .nav-wrapper {
        overflow: hidden;
        width: 100%;
        height: 20px
    }
}

@media (max-width:991.98px) {
    .main-content-1 .main-content-header .nav {
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        width: calc(100vw - 20px)
    }
}

@media (max-width:991.98px) {
    .main-content-1 .main-content-header .nav-link {
        white-space: nowrap;
        padding-bottom: 10px
    }
}

@media (min-width:992px) {
    .main-content-1 .main-content-header .nav-link:last-child,
    .main-content-1 .main-content-header .nav-link:nth-child(5) {
        font-weight: 400
    }
}

@media (min-width:992px) {
    .main-content-1 .main-content-header .nav-link:nth-child(5) {
        margin-left: auto
    }
}

@media (min-width:768px) {
    .main-content-1 .main-content-header .nav-link:last-child::after {
        display: none
    }
}

@media (min-width:576px) {
    .main-content-1 .main-content-header-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px
    }
}

.card-dashboard-twentyone {
    position: relative
}

.card-dashboard-twentyone .card-body {
    background-color: #f4f5f8;
    padding: 20px;
    height: 100%
}

.card-dashboard-twentyone .card-body .card-table-two .card-title,
.card-dashboard-twentyone .card-body .main-content-label {
    margin-bottom: 8px;
    text-transform: capitalize;
    font-size: 14px
}

.card-dashboard-eight .card-dashboard-twentyone .card-body .card-title,
.card-dashboard-twentyone .card-body .card-dashboard-eight .card-title,
.card-table-two .card-dashboard-twentyone .card-body .card-title {
    margin-bottom: 8px;
    text-transform: capitalize;
    font-size: 14px
}

@media (min-width:768px) {
    .card-dashboard-twentyone .list-group {
        margin-bottom: 10px
    }
}

.card-dashboard-twentyone .list-group-item {
    padding: 8px 0;
    border-width: 0;
    border-right-width: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    background-color: transparent
}

.card-dashboard-twentyone .list-group-item span {
    flex-basis: 50%;
    max-width: 50%
}

.card-dashboard-twentyone .list-group-item span:last-of-type {
    text-align: right;
    color: #1c273c;
    font-size: 12px;
    font-weight: 700;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.card-dashboard-twentyone .list-group-item .progress {
    margin-top: 5px;
    margin-bottom: 0;
    flex-basis: 100%;
    max-width: 100%;
    height: 5px;
    background-color: #b4bdce
}

.card-dashboard-twentyone .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.card-dashboard-twentyone .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.card-dashboard-twentyone .vmap-wrapper {
    width: 100%;
    height: 180px
}

.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomin,
.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomout {
    width: 24px;
    height: 24px;
    left: 15px;
    border-radius: 100%;
    background-color: #1c273c;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    opacity: .2
}

.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomin:focus,
.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomin:hover {
    opacity: .8
}

.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomout:focus,
.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomout:hover {
    opacity: .8
}

.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomin {
    top: 15px
}

.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomout {
    top: 45px
}

@media (min-width:576px) {
    .card-dashboard-twentyone .vmap-wrapper {
        height: 250px
    }
}

@media (min-width:768px) {
    .card-dashboard-twentyone .vmap-wrapper {
        height: 100%
    }
}

.card-dashboard-twentytwo {
    background-color: #fff;
    position: relative;
    height: 120px
}

.card-dashboard-twentytwo .media {
    padding: 20px;
    position: relative;
    z-index: 5
}

.card-dashboard-twentytwo .media-icon {
    width: 45px;
    height: 45px;
    color: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.card-dashboard-twentytwo .media-icon i {
    font-size: 24px;
    line-height: 0
}

.card-dashboard-twentytwo .media-icon i.typcn {
    line-height: .9
}

.card-dashboard-twentytwo .media-body {
    margin-left: 15px;
    padding-top: 5px
}

.card-dashboard-twentytwo .media-body h6 {
    margin-bottom: 5px;
    line-height: .7;
    color: #1c273c;
    font-size: 26px;
    font-weight: 700;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: -.5px;
    display: flex;
    align-items: flex-end
}

.card-dashboard-twentytwo .media-body h6>small {
    color: #fff;
    padding: 2px 3px;
    font-size: 9px;
    font-weight: 500;
    line-height: 1.1;
    margin-left: 5px;
    letter-spacing: normal
}

.card-dashboard-twentytwo .media-body h6>small.up {
    background-color: #21b18a
}

.card-dashboard-twentytwo .media-body h6>small.down {
    background-color: #ee335e
}

.card-dashboard-twentytwo .media-body>span {
    display: block
}

.card-dashboard-twentytwo .media-body>small {
    display: block;
    font-size: 11px;
    color: #97a3b9
}

.card-dashboard-twentytwo .media-body>small strong {
    font-weight: 500
}

.card-dashboard-twentytwo .chart-wrapper {
    position: absolute;
    right: -9px;
    left: -9px;
    opacity: .3
}

.card-dashboard-twentytwo .flot-chart {
    width: 100%;
    height: 120px
}

.card-dashboard-twentythree {
    background-color: #f4f5f8
}

.card-dashboard-twentythree .main-donut-chart.chart1 {
    width: 130px;
    height: 130px;
    background: #b4bdce
}

.card-dashboard-twentythree .main-donut-chart.chart1 .slice.one {
    clip: rect(0 130px 65px 0);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    background: #078586
}

.card-dashboard-twentythree .main-donut-chart.chart1 .slice.two {
    clip: rect(0 65px 130px 0);
    -webkit-transform: rotate(234deg);
    transform: rotate(234deg);
    background: #078586
}

.card-dashboard-twentythree .main-donut-chart.chart1 .chart-center {
    top: 15px;
    left: 15px;
    width: 100px;
    height: 100px;
    background: #f4f5f8
}

.card-dashboard-twentythree .main-donut-chart.chart1 .chart-center span {
    font-size: 40px;
    line-height: 100px;
    color: #078586;
    font-weight: 700;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 32px
}

.card-dashboard-twentythree .main-donut-chart.chart1 .chart-center span:after {
    content: "65%"
}

.card-dashboard-twentythree label {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
    line-height: 1;
    letter-spacing: .5px
}

.card-dashboard-twentythree label span {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 5px
}

.card-dashboard-twentythree h5 {
    font-size: 24px;
    font-weight: 700;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -1px
}

.card-dashboard-audience-metrics {
    background-color: #f4f5f8;
    position: relative;
    overflow: hidden;
    height: 250px
}

.card-dashboard-audience-metrics .card-header {
    padding: 20px 20px 0;
    background-color: #fff;
    position: relative;
    z-index: 3
}

.card-dashboard-audience-metrics .card-title {
    font-size: 14px;
    margin-bottom: 5px
}

.card-dashboard-audience-metrics .chart-wrapper {
    position: absolute;
    right: 0;
    bottom: -20px;
    left: 0;
    padding: 5px 5px 0
}

.card-dashboard-audience-metrics .flot-chart {
    width: 100%;
    height: 180px
}

.card-dashboard-audience-metrics .flot-chart .flot-x-axis>div {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: #7987a1;
    display: none
}

.card-dashboard-audience-metrics .flot-chart .flot-x-axis>div span:last-child {
    padding-left: 2px;
    font-weight: 700;
    color: #1c273c
}

.card-dashboard-audience-metrics .card-body {
    background-color: #fff;
    display: flex;
    padding: 0 20px 20px;
    flex: none;
    position: relative;
    z-index: 3
}

.card-dashboard-audience-metrics .card-body>div+div {
    margin-left: 10px;
    padding-left: 10px
}

.card-dashboard-audience-metrics .card-body h4 {
    font-weight: 700;
    font-size: 17px;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -.5px;
    margin-bottom: 3px
}

.card-dashboard-audience-metrics .card-body label {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-size: 11px
}

.card-dashboard-audience-metrics .card-body label span {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-right: 5px;
    border-radius: 100%
}

@media (min-width:576px) {
    .card-dashboard-audience-metrics {
        height: 270px
    }
}

@media (min-width:576px) {
    .card-dashboard-audience-metrics .chart-wrapper {
        padding-bottom: 10px;
        bottom: 10px
    }
}

@media (min-width:576px) {
    .card-dashboard-audience-metrics .flot-chart .flot-x-axis>div {
        display: block
    }
}

@media (min-width:576px) {
    .card-dashboard-audience-metrics .card-body>div+div {
        margin-left: 20px;
        padding-left: 20px
    }
}

@media (min-width:576px) {
    .card-dashboard-audience-metrics .card-body h4 {
        font-size: 21px
    }
}

@media (min-width:576px) {
    .card-dashboard-audience-metrics .card-body label {
        font-size: .875rem
    }
}

.card-dashboard-twentyfour .card-header {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 0
}

.card-dashboard-twentyfour .card-title {
    font-size: 14px;
    margin-bottom: 0
}

.card-dashboard-twentyfour .card-body {
    padding: 15px 20px 20px
}

.card-dashboard-twentyfour .card-body-top {
    display: flex;
    margin-bottom: 20px
}

.card-dashboard-twentyfour .card-body-top h6 {
    color: #1c273c;
    font-size: 18px;
    font-weight: 600;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin-bottom: 0;
    letter-spacing: -.75px
}

.card-dashboard-twentyfour .card-body-top h6 small {
    font-weight: 600
}

.card-dashboard-twentyfour .card-body-top h6 span {
    color: #97a3b9;
    font-weight: 400;
    letter-spacing: normal
}

.card-dashboard-twentyfour .card-body-top label {
    display: block;
    margin-bottom: 0;
    color: #7987a1;
    font-size: 11px
}

.card-dashboard-twentyfour .card-body-top>div+div {
    margin-left: 30px
}

.card-dashboard-twentyfour .card-table-two .card-title,
.card-dashboard-twentyfour .main-content-label {
    font-size: 10px;
    color: #031b4e;
    letter-spacing: .5px;
    margin-bottom: 0
}

@media (min-width:576px) {
    .card-dashboard-twentyfour .card-body-top h6 {
        font-size: 22px
    }
}

@media (min-width:576px) {
    .card-dashboard-twentyfour .card-body-top label {
        font-size: .875rem
    }
    .card-dashboard-audience-metrics .flot-chart .flot-x-axis .flot-tick-label {
        display: block
    }
}

.card-dashboard-eight .card-dashboard-twentyfour .card-title,
.card-dashboard-twentyfour .card-dashboard-eight .card-title,
.card-table-two .card-dashboard-twentyfour .card-title {
    font-size: 10px;
    color: #031b4e;
    letter-spacing: .5px;
    margin-bottom: 0
}

.card-dashboard-twentyfour .chart-legend {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .5px;
    line-height: .6
}

.card-dashboard-twentyfour .chart-legend span {
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-right: 5px
}

.card-dashboard-twentyfour .chart-legend>div {
    display: flex;
    align-items: center
}

.card-dashboard-twentyfour .chart-legend>div+div {
    margin-left: 20px
}

.card-dashboard-twentyfour .chart-wrapper {
    position: relative;
    margin-right: -10px
}

.card-dashboard-twentyfour .flot-chart {
    width: 100%;
    height: 130px
}

.card-dashboard-twentyfive .card-title {
    font-size: 14px;
    margin-bottom: 15px
}

.card-dashboard-twentyfive .col+.col {
    border-left: 1px solid #e2e8f5
}

.card-dashboard-twentyfive .card-label {
    display: block;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: .5px;
    margin-bottom: 2px;
    color: #97a3b9;
    white-space: nowrap
}

.card-dashboard-twentyfive .card-value {
    font-size: 22px;
    font-weight: 600;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    margin-bottom: 0;
    letter-spacing: -1px
}

.card-dashboard-twentyfive .chart-wrapper {
    position: relative;
    margin: 0 -8px -5px
}

.card-dashboard-twentyfive .flot-chart {
    width: 100%;
    height: 35px
}

#flotChart2 {
    width: 100%;
    height: 35px
}

.card-dashboard-twentysix .card-header {
    padding: 15px 15px 10px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.card-dashboard-twentysix .card-title {
    margin-bottom: 0;
    text-transform: uppercase
}

.card-dashboard-twentysix .chart-legend {
    display: flex;
    align-items: center
}

.card-dashboard-twentysix .chart-legend>div {
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .5px
}

.card-dashboard-twentysix .chart-legend>div+div {
    margin-left: 15px
}

.card-dashboard-twentysix .chart-legend span {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 2px
}

.card-dashboard-twentysix .card-body {
    padding: 0;
    position: relative;
    overflow: hidden
}

.card-dashboard-twentysix .card-body h6 {
    margin-bottom: 0;
    color: #1c273c;
    font-size: 22px;
    font-weight: 600;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: -.5px;
    line-height: 1
}

.card-dashboard-twentysix .card-body h6 span {
    font-weight: 400;
    font-size: 18px;
    letter-spacing: -1px
}

.card-dashboard-twentysix .card-body label {
    font-size: 12px;
    margin-bottom: 0;
    color: #97a3b9
}

.card-dashboard-twentysix .chart-wrapper {
    position: relative;
    margin: -30px -18px -15px -18px
}

.card-dashboard-twentysix .flot-chart {
    width: 100%;
    height: 120px
}

.card-dashboard-twentysix .flot-chart .flot-x-axis>div {
    font-size: 10px;
    letter-spacing: .5px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, .75);
    transform: translateY(-20px)
}

.card-dashboard-twentysix .flot-chart .flot-y-axis>div {
    font-size: 10px;
    transform: translateX(33px);
    color: rgba(28, 39, 60, .5)
}

.card-dashboard-twentysix.card-dark-one .card-body h6,
.card-dashboard-twentysix.card-dark-one .card-title,
.card-dashboard-twentysix.card-dark-two .card-body h6,
.card-dashboard-twentysix.card-dark-two .card-title {
    color: #fff
}

.card-dashboard-twentysix.card-dark-one .card-body h6 span,
.card-dashboard-twentysix.card-dark-two .card-body h6 span {
    color: rgba(255, 255, 255, .5)
}

.card-dashboard-twentysix.card-dark-one .card-body label,
.card-dashboard-twentysix.card-dark-two .card-body label {
    color: rgba(255, 255, 255, .7)
}

.card-dashboard-twentysix.card-dark-one .flot-chart .flot-y-axis>div,
.card-dashboard-twentysix.card-dark-two .flot-chart .flot-y-axis>div {
    color: rgba(255, 255, 255, .2)
}

.card-dashboard-twentysix.card-dark-one {
    background-color: #8485fb;
    background-image: linear-gradient(to bottom, #1f05f0 0, #8485fb 100%);
    background-repeat: repeat-x
}

.card-dashboard-twentysix.card-dark-two {
    background-color: #0040ff;
    background-image: linear-gradient(to bottom, #0a47ff 0, #078586 100%);
    background-repeat: repeat-x
}

.main-rating-value {
    font-size: 40px;
    font-weight: 400;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    margin-bottom: 0;
    letter-spacing: -.5px;
    line-height: .7
}

.main-rating-label {
    margin-bottom: 10px;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .5px
}

.main-media-list-reviews .media+.media {
    margin-top: 25px
}

.main-media-list-reviews .main-img-user {
    width: 32px;
    height: 32px
}

.main-media-list-reviews .main-img-user::after {
    display: none
}

.main-media-list-reviews .media-body {
    margin-left: 15px
}

.main-media-list-reviews .media-body h6 {
    margin-bottom: 2px;
    line-height: 1
}

.main-media-list-reviews .media-body small {
    display: inline-block;
    font-size: 12px;
    color: #97a3b9;
    line-height: 1
}

.main-star-group {
    display: flex;
    align-items: center
}

.main-star-group span:last-child {
    display: block;
    font-weight: 500;
    font-size: 11px;
    margin-left: 5px;
    color: #7987a1
}

.main-star-item {
    color: #078586;
    font-size: 14px;
    position: relative
}

.main-star-item+.main-star-item {
    margin-left: 3px
}

.main-media-list-activity .media+.media {
    margin-top: 23px
}

.main-media-list-activity .media-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 100%
}

.main-media-list-activity .media-icon i {
    font-size: 21px;
    line-height: 0
}

.main-media-list-activity .media-icon i.typcn {
    line-height: .9
}

.main-media-list-activity .media-body {
    margin-left: 15px
}

.main-media-list-activity .media-body h6 {
    margin-bottom: 2px;
    color: #1c273c
}

.main-media-list-activity .media-body span {
    display: block;
    font-size: 11px;
    color: #97a3b9
}

.main-media-list-activity .media-right {
    font-size: 11px;
    color: #97a3b9
}

.app.sidebar-mini .desktop-logo.logo-dark {
    display: none
}

.dark-theme.app.sidebar-mini .desktop-logo.logo-dark {
    display: block
}

.dropdown-menu-left {
    box-shadow: 0 0 15px 1px #bcb5d6
}

.dropdown-menu-left .dropdown-item {
    padding: 4px 19px
}

.dropdown-menu-left img {
    width: 31px;
    height: 20px
}

.main-header-profile {
    border-top-right-radius: 7px;
    border-top-left-radius: 7px
}

.dropdown-menu-arrow:after {
    content: '';
    position: absolute;
    top: -8.5px;
    right: 16px;
    border-bottom: 9px solid #fff;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent
}

.dropdown-menu-left {
    right: 0!important;
    left: auto!important;
    bottom: auto!important
}

.right-content .btn-group-sm>.btn,
.right-content .btn-sm {
    padding: .4rem .8rem
}

.total-revenue {
    display: flex;
    padding: 0 10px 20px;
    flex: none;
    position: absolute;
    z-index: 9
}

.total-revenue h4 {
    font-size: 21px;
    font-weight: 700;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -.5px;
    margin-bottom: 3px
}

.total-revenue label {
    font-size: .875rem
}

.total-revenue label span {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-right: 5px;
    border-radius: 100%
}

.total-revenue>div+div {
    margin-left: 20px;
    padding-left: 20px
}

.customers .list-group-item .media img {
    width: 40px!important;
    height: 40px!important
}

.list-group-item-action {
    width: 100%;
    color: #1d2635;
    text-align: inherit
}

.progress-style .table td,
.progress-style .table th {
    padding: .75rem;
    vertical-align: middle
}

.progress-style.progress {
    overflow: visible!important
}

.progress-style.progress .progress-bar:after {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, .2);
    background: #fff;
    position: absolute;
    right: -5px;
    top: -5px
}

.progress-style .bg-primary-gradient.progress-bar:after,
.progress-style .bg-primary.progress-bar:after {
    border: 4px solid #078586
}

.progress-style .bg-danger.progress-bar:after {
    border: 4px solid #ee335e
}

.progress-style .bg-teal.progress-bar:after {
    border: 4px solid #0cc
}

.progress-style .bg-pink.progress-bar:after {
    border: 4px solid #f10075
}

.progress-style .bg-success.progress-bar:after {
    border: 4px solid #21b18a
}

.progress-style .bg-danger-gradient.progress-bar:after {
    border: 4px solid #ee335e
}

.progress-style .bg-warning.progress-bar:after {
    border: 4px solid #fbbc0b
}

.progress-style .bg-info.progress-bar:after {
    border: 4px solid #00b9ff
}

.progress-style.progress .progress-bar {
    box-shadow: none;
    border-radius: 0;
    position: relative;
    -webkit-animation: animate-positive 2s;
    animation: animate-positive 2s
}

.progress-style .progress-bar {
    float: left;
    height: 100%;
    font-size: 12px;
    border-radius: 20px!important;
    line-height: 20px;
    overflow: visible!important;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease
}

.customers .list-group-item-action:focus,
.customers .list-group-item-action:hover {
    color: #0160e4;
    background-color: #ecf0fa
}

.customers .list-group-item {
    padding: .82rem 1.25rem
}

.card-table h4,
.sales-info h3 {
    font-size: 21px;
    font-weight: 700;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c
}

.sales-infomation {
    position: absolute;
    bottom: 0
}

#chart {
    margin-bottom: 4.5rem
}

.br-br-7 {
    border-bottom-right-radius: 7px!important
}

.br-bl-7 {
    border-bottom-left-radius: 7px!important
}

.header-icon-svgs {
    width: 19px;
    height: 19px;
    color: #5b6e88
}

.fullscreen-button .full-screen-link {
    line-height: 38px!important
}

.country-flag1 strong {
    color: #7282a9;
    display: none
}

@media (max-width:767px) {
    .app-sidebar__toggle {
        text-align: center
    }
}

@media (max-width:920px) {
    .sidebar-mini .main-dashboard-header-right {
        flex-wrap: nowrap;
        margin-top: 20px
    }
    .sidebar-mini .breadcrumb-header {
        display: block!important
    }
    .sidebar-mini .main-dashboard-header-right>div label {
        text-align: left
    }
    .sidebar-mini .main-dashboard-header-right>div:first-child {
        flex-basis: 100%
    }
}

@media (max-width:576px) {
    .main-header .container,
    .main-header .container-fluid {
        padding-left: 5px!important;
        padding-right: 5px!important
    }
    .sidebar-mini .main-dashboard-header-right {
        flex-wrap: wrap!important
    }
    .country-flag1 {
        display: none!important
    }
    .main-header-message,
    .main-header-notification {
        margin: auto 0!important
    }
    .total-revenue {
        padding: 0!important
    }
    .total-revenue>div+div {
        margin-left: 20px!important;
        padding-left: 3px!important
    }
}

.nav-link.html-code.active {
    border-color: transparent;
    background-color: #23241f;
    color: #fff;
    margin-top: 1rem;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom: 0
}

.html-source.nav-tabs .nav-item {
    margin-bottom: -2px
}

.nav-link.html-code {
    background: #ecf0fa;
    display: inline-flex;
    -webkit-box-align: center;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    align-items: center
}

.counter-icon {
    margin-bottom: 0;
    display: inline-flex;
    width: 4rem;
    height: 4rem;
    padding: 1.3rem 1.4rem;
    border-radius: 50%;
    text-align: center;
    background: rgba(21, 22, 23, .2)
}

.counter-icon i {
    color: #fff;
    font-size: 22px
}

.success-widget h3 {
    text-align: center
}

.success-widget h3:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #ccead1
}

.success-widget h3:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #ccead1;
    left: .5em
}

.success-widget h3:before {
    right: .5em
}

.danger-widget h3 {
    text-align: center
}

.danger-widget h3:after,
.danger-widget h3:before {
    background: #cad8a9
}

.danger-widget h3:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f5dce2
}

.danger-widget h3:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f5dce2;
    left: .5em
}

.danger-widget h3:before {
    right: .5em
}

.warning-widget h3 {
    text-align: center
}

.warning-widget h3:after,
.warning-widget h3:before {
    background: #cad8a9
}

.warning-widget h3:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f3e7c6
}

.warning-widget h3:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f3e7c6;
    left: .5em
}

.warning-widget h3:before {
    right: .5em
}

.card .box {
    position: relative;
    top: 50%;
    left: 0;
    text-align: center;
    padding: 30px;
    box-sizing: border-box;
    border: 1px solid #e9f1f1;
    border-radius: 4px;
    box-shadow: 0 0 25px rgba(255, 255, 255, .3);
    width: 100%
}

.card .box .img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden
}

.card .box h2 {
    font-size: 20px;
    margin: 20px auto
}

.card .box ul li a {
    display: block;
    margin: 0 10px;
    font-size: 20px;
    transition: .5s;
    text-align: center
}

.card .box h2 span {
    background: #078586;
    font-size: 14px;
    color: #fff;
    display: inline-block;
    padding: 4px 10px;
    border-radius: 15px;
    margin-top: 10px
}

.userlist-table .table td,
.userlist-table .table th {
    padding: .75rem;
    vertical-align: middle;
    display: table-cell
}

.userlist-table .table-bordered thead td,
.userlist-table .table-bordered thead th {
    padding-top: 12px;
    padding-bottom: 12px
}

.userlist-table .btn-group-sm>.btn,
.userlist-table .btn-sm {
    padding: .44rem .7rem;
    font-size: .9rem;
    display: initial;
    margin: 4px
}

.userlist-table .user-link {
    font-weight: 500;
    color: #000;
    margin-right: 5px
}

.userlist-table .user-subhead {
    color: #8992a9;
    display: none
}

.dot-label {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation: ani 1s linear infinite;
    left: 7px;
    bottom: -12px;
    position: relative
}

@keyframes ani {
    0% {
        transform: scale(1)
    }
    25% {
        transform: scale(1)
    }
    30% {
        transform: scale(1.4)
    }
    50% {
        transform: scale(1.2)
    }
    70% {
        transform: scale(1.4)
    }
    90% {
        transform: scale(1)
    }
    100% {
        transform: scale(1)
    }
}

.search .page-link {
    background-color: #fff
}

.main-card-signin,
.modal-content .card {
    box-shadow: none
}

@media (max-width:920px) {
    .right-content {
        margin-top: 1rem!important
    }
}

@media (max-width:576px) {
    .profile.navtab-custom {
        padding: 11px 0 0 0!important;
        width: 100%
    }
    .profile.navtab-custom li {
        display: flex;
        width: 100%;
        border-radius: 0
    }
    .profile.navtab-custom li a {
        border-radius: 0;
        width: 100%;
        margin-right: 0!important;
        border-top-left-radius: 0!important;
        border-top-right-radius: 0!important
    }
}

.table-vcenter td,
.table-vcenter th {
    vertical-align: middle;
    border-top: 1px solid #f1f5fa
}

.table-center td,
.table-center th {
    text-align: center
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #22252f
}

.userlist-table .dot-label {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation: ani 1s linear infinite;
    left: -6px;
    bottom: -7px;
    position: relative
}

.userlist-table img {
    max-width: inherit
}

.product-pagination .page-link {
    background-color: #fff!important;
    border: 1px solid #dfe4f3
}

.product-pagination .page-item.active .page-link {
    background: #078586!important;
    border-color: #078586
}

.main-logo1 {
    font-weight: 600
}

@media (min-width:576px) {
    .dropdown.nav-itemd-none .dropdown-menu:after {
        border-bottom: 9px solid #fff
    }
}

@media (min-width:992px) {
    .top-header .header-brand {
        display: none
    }
    .top-header .main-header-center {
        margin-left: 0!important
    }
    .top-header .header-brand.header-brand2 {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        top: 17px
    }
}

.btn-outline-danger:focus {
    background: #ee335e
}

@media (max-width:991px) {
    .navbar-form.active .input-group-btn {
        right: 24px!important
    }
}

.panel-heading1 a:before {
    content: "\e994";
    position: absolute;
    font-family: feather!important;
    right: 10px;
    top: 12px;
    font-size: 20px;
    transition: all .5s;
    transform: scale(1)
}

#compositeline canvas,
#compositeline2 canvas,
#compositeline3 canvas,
#compositeline4 canvas {
    width: 100%!important
}

@media (max-width:480px) {
    .main-header.nav.hor-header {
        padding: 0
    }
}

@media (max-width:991px) and (min-width:768px) {
    .main-content-left-contacts .main-nav-line .nav-link {
        padding: 6px 10px
    }
}

@media (min-width:1200px) {
    .main-nav-line-chat .nav-link+.nav-link {
        margin-left: 0!important
    }
}

@media (max-width:1145px) and (min-width:992px) {
    .main-nav-line-chat .nav-link+.nav-link {
        margin-left: 0!important;
        padding: 0 7px
    }
}

@media (max-width:991px) {
    .main-nav-line-chat .nav-link {
        padding: 6px 10px
    }
    .main-datetimepicker>.datepicker_inner_container>.datepicker_calendar {
        width: 250px!important
    }
    .main-datetimepicker>.datepicker_inner_container>.datepicker_timelist {
        width: 100px!important
    }
    .horizontalMenucontainer .main-header.hor-header {
        position: fixed!important
    }
    .horizontalMenucontainer .breadcrumb-header {
        margin-top: 60px;
        padding-top: 30px
    }
    .main-body.active.menu-open .horizontalMenucontainer .main-header {
        left: auto
    }
}

@media screen and (min-width:634px) {
    .main-dashboard-header-right {
        margin-top: 0
    }
}

@media screen and (max-width:767px) {
    .breadcrumb-header {
        margin-top: 24px;
        margin-bottom: 24px!important
    }
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus {
    outline: 0
}

.main-header-center,
::-ms-backdrop {
    display: inline-table!important;
    width: 350px
}

.main-header-center .form-control,
::-ms-backdrop {
    padding: .5rem 1.5rem
}

@media (min-width:991px) {
    .main-header-right .nav-link.btn-default.btn,
     ::-ms-backdrop {
        margin-top: 10px
    }
}

@media (max-width:991px) {
    .card-img-top,
     ::-ms-backdrop {
        height: 300px
    }
    .main-header-right .nav-link.btn-default.btn,
     ::-ms-backdrop {
        margin-top: 7px
    }
}

.pricing .list-unstyled li,
::-ms-backdrop {
    dispLay: block
}

.main-content-body.main-content-body-chat,
.main-content-body.main-content-body-mail,
::-ms-backdrop {
    flex: inherit
}

.breadcrumb-3 li,
.breadcrumb-4 li,
::-ms-backdrop {
    display: inline-block
}

.main-footer,
::-ms-backdrop {
    width: 100%;
    position: relative;
    bottom: -5px;
    margin-top: 1.5rem;
    margin-bottom: -10px;
    height: inherit!important;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto
}

.horizontalMenucontainer .main-header-left,
::-ms-backdrop {
    margin-left: 0!important
}

.main-content,
::-ms-backdrop {
    height: 100%
}

.horizontalMenucontainer .main-footer-,
::-ms-backdrop {
    padding-bottom: 10px
}

.media-list.media.d-block,
::-ms-backdrop {
    display: inline!important
}

.chat .action-header,
::-ms-backdrop {
    padding: 10px 13px 1px 17px
}

.sidebar-mini .main-header-left .btn,
::-ms-backdrop {
    margin-left: 60px;
    left: 250px
}

.sidebar-mini .main-content.app-content .main-header-left,
::-ms-backdrop {
    margin-left: 0
}

.sidebar.sidebar-open,
::-ms-backdrop {
    z-index: 9999!important
}

.p-4.border-bottom,
::-ms-backdrop {
    height: 100%
}

.img-fluid,
.table,
.text-wrap table,
::-ms-backdrop {
    max-width: 100%
}

.sidebar,
::-ms-backdrop {
    z-index: 0!important
}

.d-block.media.media-list,
::-ms-backdrop {
    display: inline!important
}

.full-screen.nav-item>a,
.fullscreen-button .full-screen-link,
.main-header-message>a,
.main-header-notification>a,
::-ms-backdrop {
    line-height: 49px!important
}

.datetimepicker table th.next span,
.datetimepicker table th.prev span,
::-ms-backdrop {
    top: 20px
}

.datetimepicker table th.next,
::-ms-backdrop {
    border-top-right-radius: 0
}

.datetimepicker table th.prev,
::-ms-backdrop {
    border-top-left-radius: 0
}

.main-header-center .btn,
.main-header-center .sp-container button,
.sp-container .main-header-center button,
::-ms-backdrop {
    top: 0
}

@media (min-width:992px) {
    .sidebar-mini .main-header-left,
     ::-ms-backdrop {
        margin-left: 80px
    }
    .sidebar-mini .main-header .main-header-left .header-brand,
     ::-ms-backdrop {
        margin-right: 80px;
        width: auto
    }
    .card-img-top,
     ::-ms-backdrop {
        height: 250px
    }
}

.btn-icon-list .btn,
::-ms-backdrop {
    display: table;
    vertical-align: middle;
    text-align: center;
    margin-right: 10px
}

.side-badge,
::-ms-backdrop {
    top: 14px
}

.media.d-sm-flex,
::-ms-backdrop {
    display: initial
}

.btn,
.sp-container button,
::-ms-backdrop {
    padding: 8.5px
}

.page-h,
::-ms-backdrop {
    height: 100%
}

::-ms-backdrop,
p {
    font-size: 13px
}

.breadcrumb-right .btn,
::-ms-backdrop {
    padding: .4rem 1rem
}

.horizontalMenucontainer .main-footer,
::-ms-backdrop {
    padding-bottom: 10px
}

.media-list.media.d-sm-flex,
::-ms-backdrop {
    display: initial
}

.main-msg-wrapper,
::-ms-backdrop {
    display: inline-table
}

.p-text .p-name,
::-ms-backdrop {
    display: block
}

.sidebar .tab-menu-heading,
::-ms-backdrop {
    position: absolute!important
}

.card--calendar .ui-datepicker-inline,
::-ms-backdrop {
    border-width: 1px!important
}

.fc-datepicker.main-datepicker.hasDatepicker,
::-ms-backdrop {
    border-right: 0!important;
    border-top: 0!important;
    border-bottom: 0!important
}

.second-sidemenu .resp-tabs-container .slide-menu.submenu-list li,
::-ms-backdrop {
    display: flex
}

@media (min-width:992px) {
    .pulse,
    .pulse-danger,
     ::-ms-backdrop {
        top: 13px
    }
    .card.custom-card .box,
     ::-ms-backdrop {
        position: static
    }
    .thumb .thumb-img,
     ::-ms-backdrop {
        height: 139px
    }
    .main-chat-body .media-body .main-msg-wrapper.left,
    .main-chat-body .media-body .main-msg-wrapper.right,
     ::-ms-backdrop {
        display: inline-table
    }
    .main-dashboard-header-right>div:first-child,
     ::-ms-backdrop {
        flex-basis: 150px
    }
    .tasks .ckbox,
     ::-ms-backdrop {
        margin-right: 10px
    }
    .app-sidebar__toggle,
     ::-ms-backdrop {
        margin-right: 30px!important
    }
    .product-details.table-responsive.text-nowrap .media-body,
     ::-ms-backdrop {
        flex: none
    }
}

@media (min-width:767px) {
    .bg-primary-transparent .row.wd-100p.mx-auto.text-center,
     ::-ms-backdrop {
        margin-top: 0
    }
    .login.d-flex.align-items-center.py-2,
     ::-ms-backdrop {
        margin-top: 0
    }
}

@media (max-width:767px) {
    .dropdown.main-header-message.right-toggle,
    .dropdown.nav-item.main-header-message,
    .dropdown.nav-item.main-header-notification,
     ::-ms-backdrop {
        margin-top: 7px!important
    }
    .main-header-left .responsive-logo,
     ::-ms-backdrop {
        margin-left: 40px!important
    }
    .sidebar-mini .main-header .main-header-left .header-brand,
     ::-ms-backdrop {
        margin-left: 30px
    }
}

::-ms-backdrop,
html.fullscreen-button {
    width: 100%
}

::-ms-backdrop,
html.fullscreen-button .app-content,
html.fullscreen-button .main-content {
    overflow: scroll;
    overflow-x: hidden;
    height: 100vh
}

.error-page1,
::-ms-backdrop {
    -ms-overflow-y: hidden
}

#list1 .avatar-md,
::-ms-backdrop {
    width: inherit!important
}

#list3 .mr-4,
::-ms-backdrop {
    margin-right: 0!important
}

.app-sidebar .slide .side-menu__item.active::before,
::-ms-backdrop {
    top: 0
}

.tab-content.border-bottom.p-4,
::-ms-backdrop {
    height: inherit!important
}

.datetimepicker table th.next span,
.datetimepicker table th.prev span,
::-ms-backdrop {
    top: 20px!important
}

@media (min-width:1025px) {
    .btn,
    .sp-container button,
     ::-ms-backdrop {
        padding: 12px
    }
}

.adtocart {
    background: linear-gradient(45deg, #f93a5a, #f7778c)!important;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #fff;
    display: inline-block;
    text-align: center;
    border: 3px solid #fff;
    left: 40%;
    bottom: -24px;
    position: absolute
}

.adtocart i {
    color: #fff;
    font-size: 32px;
    line-height: 42px
}

.pro-title {
    color: #5a5a5a;
    display: inline-block;
    margin-top: 20px;
    font-size: 16px
}

.product-list .price {
    color: #fc5959;
    font-size: 15px
}

.pro-img-box {
    position: relative
}

.prod-cat li ul li a:focus,
.prod-cat li ul li a:hover {
    background: 0 0;
    color: #ee335e
}

.prod-cat li ul li.active a {
    background: 0 0;
    color: #ee335e
}

.prod-cat li a.active,
.prod-cat li a:focus,
.prod-cat li a:hover {
    background: 0 0;
    color: #ee335e
}

.nav.prod-cat {
    position: relative;
    display: block;
    padding: 10px 5px
}

.prod-cat li ul {
    margin-left: 44px;
    display: block
}

.prod-cat li ul li {
    padding: 10px 15px
}

.nav.prod-cat li {
    padding: 10px 0
}

.product-pagination .page-link {
    background-color: #ecf0fa;
    border: 1px solid #dfe4f3
}

.product-pagination .page-item.disabled .page-link {
    border-color: #dfe4f3
}

.nav.prod-cat li a {
    color: #031b4e
}

.nav.prod-cat li a i {
    margin-right: 14px
}

.product-sale {
    position: absolute;
    width: 100%;
    top: 10px;
    left: 13px;
    right: 5px
}

.product-sale .badge {
    color: #fff;
    font-size: 12px!important;
    margin-top: 10px
}

.product-sale .wishlist {
    float: right;
    right: 26px;
    position: absolute;
    font-size: 18px!important;
    top: 5px
}

.order-table,
.shopping-cart,
.wishlist-table {
    margin-bottom: 20px
}

.order-table .table,
.shopping-cart .table,
.wishlist-table .table {
    margin-bottom: 0
}

.order-table .btn,
.shopping-cart .btn,
.wishlist-table .btn {
    margin: 0
}

.shopping-cart>table>thead>tr>td,
.shopping-cart>table>thead>tr>th {
    vertical-align: middle!important
}

.shopping-cart>table>tbody>tr>td,
.shopping-cart>table>tbody>tr>th {
    vertical-align: middle!important
}

.wishlist-table>table>thead>tr>td,
.wishlist-table>table>thead>tr>th {
    vertical-align: middle!important
}

.wishlist-table>table>tbody>tr>td,
.wishlist-table>table>tbody>tr>th {
    vertical-align: middle!important
}

.order-table>table>thead>tr>td,
.order-table>table>thead>tr>th {
    vertical-align: middle!important
}

.order-table>table>tbody>tr>td,
.order-table>table>tbody>tr>th {
    vertical-align: middle!important
}

.order-table>table thead th,
.shopping-cart>table thead th,
.wishlist-table>table thead th {
    padding-top: 17px;
    padding-bottom: 17px;
    border-width: 1px
}

.order-table .remove-from-cart,
.shopping-cart .remove-from-cart,
.wishlist-table .remove-from-cart {
    display: inline-block;
    color: #ff5252;
    font-size: 18px;
    line-height: 1;
    text-decoration: none
}

.order-table .count-input,
.shopping-cart .count-input,
.wishlist-table .count-input {
    display: inline-block;
    width: 100%;
    width: 86px
}

.order-table .product-item,
.shopping-cart .product-item,
.wishlist-table .product-item {
    display: table;
    width: 100%;
    min-width: 150px;
    margin-top: 5px;
    margin-bottom: 3px
}

.shopping-cart .product-item .product-info,
.shopping-cart .product-item .product-thumb {
    display: table-cell;
    vertical-align: top
}

.wishlist-table .product-item .product-info,
.wishlist-table .product-item .product-thumb {
    display: table-cell;
    vertical-align: top
}

.order-table .product-item .product-info,
.order-table .product-item .product-thumb {
    display: table-cell;
    vertical-align: top
}

.order-table .product-item .product-thumb,
.shopping-cart .product-item .product-thumb,
.wishlist-table .product-item .product-thumb {
    width: 109px;
    padding-right: 0
}

.order-table .product-item .product-thumb>img,
.shopping-cart .product-item .product-thumb>img,
.wishlist-table .product-item .product-thumb>img {
    display: block;
    width: 85px;
    height: 85px
}

@media screen and (max-width:860px) {
    .order-table .product-item .product-thumb,
    .shopping-cart .product-item .product-thumb,
    .wishlist-table .product-item .product-thumb {
        display: none
    }
}

.order-table .product-item .product-info span,
.shopping-cart .product-item .product-info span,
.wishlist-table .product-item .product-info span {
    display: block;
    font-size: 13px;
    padding: 3px 0
}

.order-table .product-item .product-info span>em,
.shopping-cart .product-item .product-info span>em,
.wishlist-table .product-item .product-info span>em {
    font-weight: 500;
    font-style: normal
}

.order-table .product-item .product-title,
.shopping-cart .product-item .product-title,
.wishlist-table .product-item .product-title {
    margin-bottom: 6px;
    padding-top: 5px;
    font-size: 15px;
    font-weight: 500
}

.table-responsive.shopping-cart {
    overflow-y: inherit;
    overflow-x: scroll
}

.order-table .product-item .product-title>a,
.shopping-cart .product-item .product-title>a,
.wishlist-table .product-item .product-title>a {
    transition: color .3s;
    color: #374250;
    line-height: 1.5;
    text-decoration: none
}

.order-table .product-item .product-title>a:hover,
.shopping-cart .product-item .product-title>a:hover,
.wishlist-table .product-item .product-title>a:hover {
    color: #078586
}

.order-table .product-item .product-title small,
.shopping-cart .product-item .product-title small,
.wishlist-table .product-item .product-title small {
    display: inline;
    margin-left: 6px;
    font-weight: 500
}

.wishlist-table .product-item .product-thumb {
    display: table-cell!important
}

@media screen and (max-width:576px) {
    .wishlist-table .product-item .product-thumb {
        display: none!important
    }
}

.shopping-cart-footer {
    display: table;
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid #e1e7ec
}

.shopping-cart-footer>.column {
    display: table-cell;
    padding: 5px 0;
    vertical-align: middle
}

.shopping-cart-footer>.column:last-child {
    text-align: right
}

.shopping-cart-footer>.column:last-child .btn {
    margin-right: 0;
    margin-left: 15px
}

@media (max-width:768px) {
    .shopping-cart-footer>.column {
        display: block;
        width: 100%
    }
    .shopping-cart-footer>.column:last-child {
        text-align: center
    }
    .shopping-cart-footer>.column .btn {
        width: 100%;
        margin: 12px 0!important
    }
}

.coupon-form .form-control {
    display: inline-block;
    width: 100%;
    max-width: 235px;
    margin-right: 12px
}

.product-details tr>td {
    vertical-align: middle!important
}

.remove-from-cart {
    display: inline-block;
    color: #ff5252;
    font-size: 18px;
    line-height: 1;
    text-decoration: none
}

.product-details>table thead th {
    padding-top: 17px;
    padding-bottom: 17px;
    border-width: 1px;
    text-align: center
}

.product-details .media {
    display: flex;
    align-items: flex-start;
    padding: .75rem
}

@media screen and (max-width:850px) {
    .shopping-cart-footer>.column {
        display: flow-root
    }
}

@media screen and (max-width:767px) {
    .coupon-form .form-control {
        max-width: inherit!important
    }
}

.widget-user .widget-user-header {
    padding: 20px;
    height: 120px
}

.widget-user .widget-user-username {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 300;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    color: #fff
}

.widget-user .widget-user-desc {
    margin-top: 0;
    color: #fff
}

.widget-user .widget-user-image {
    position: absolute;
    top: 65px;
    left: 50%;
    margin-left: -45px
}

.widget-user .widget-user-image>img {
    width: 90px;
    height: auto;
    border: 3px solid #fff
}

.widget-user .user-wideget-footer {
    padding-top: 30px
}

.user-wideget-footer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid #f4f4f4;
    padding: 10px;
    background-color: #fff
}

.user-wideget .border-right {
    border-right: 1px solid #f4f4f4
}

.description-block {
    display: block;
    margin: 10px 0;
    text-align: center
}

.description-block>.description-header {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 16px
}

.description-block>.description-text {
    text-transform: uppercase;
    color: #a3b1c9
}

.main-footer {
    background-color: #fff;
    border-top: 1px solid #e3e8f7;
    margin-top: auto
}

@media (min-width:992px) {
    .main-footer {
        display: block
    }
    .sticky-pin {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1
    }
    .sticky {
        width: 100%
    }
}

.side-header {
    position: sticky;
    width: 100%;
    z-index: 10!important;
    border-bottom: 1px solid #e7ebf5
}

.main-footer .container {
    font-size: 12px;
    padding: 38px 20px 0;
    align-items: center;
    justify-content: space-between;
    color: #737f9e;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    line-height: 3.7
}

.main-footer .container-fluid {
    font-size: 12px;
    padding: 38px 20px 0;
    align-items: center;
    justify-content: space-between;
    color: #737f9e;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    line-height: 3.7;
    padding-left: 20px;
    padding-right: 20px
}

@media (min-width:992px) {
    .main-footer .container {
        padding: 10px 0 0
    }
}

.main-footer-app .container,
.main-footer-app .container-fluid {
    border-top-width: 0
}

@media (max-width:480px) {
    .main-footer .container-fluid {
        padding-left: 0;
        padding-right: 0
    }
}

@media (max-width:380px) {
    .main-footer .container-fluid {
        font-size: 11px
    }
}

.main-footer-demo {
    background-color: #0c025b;
    color: rgba(255, 255, 255, .4);
    height: 60px
}

.main-footer-demo .container {
    border-top-width: 0
}

.main-footer-demo a {
    color: rgba(255, 255, 255, .5)
}

.main-footer-demo a:focus,
.main-footer-demo a:hover {
    color: #fff
}

@media (max-width:991.98px) {
    .main-header-menu-show {
        overflow: hidden
    }
    .main-header-menu-show .main-header-menu {
        border-right: 1px solid rgba(28, 39, 60, .08);
        box-shadow: 0 0 30px rgba(28, 39, 60, .08);
        transform: translateX(0)
    }
    .main-header-menu-show .main-navbar-backdrop {
        visibility: visible;
        opacity: 1
    }
}

.main-header {
    background-color: transparent;
    z-index: 100;
    height: 64px;
    background: #fff;
    border-bottom: 1px solid #dee4ec;
    z-index: 1025!important;
    width: 100%!important
}

.main-header>.container,
.main-header>.container-fluid {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px
}

@media (min-width:992px) {
    .main-header>.container,
    .main-header>.container-fluid {
        padding-left: 15px;
        padding-right: 15px
    }
}

@media (min-width:992px) {
    .main-header>.container {
        padding: 0
    }
}

@media (max-width:991.98px) {
    .main-header>.container {
        max-width: none
    }
}

@media (max-width:1158px) {
    .main-header-left #dropdownMenuButton {
        display: none
    }
}

@media (max-width:1052px) {
    .main-header-left #dropdownMenuButton2 {
        display: none
    }
}

@media (max-width:633px) {
    .profile-name.ml-2 {
        display: none;
        margin-left: 0
    }
}

.main-profile-menu.nav-link {
    padding: .5rem .5rem
}

.main-header .main-header-left .main-logo {
    display: none
}

@media (min-width:992px) {
    .main-header .main-header-left .main-logo {
        display: flex
    }
}

.main-header-left {
    display: flex;
    align-items: center
}

.main-header-left .list-items {
    margin-bottom: 0
}

.main-header-left .list-items li {
    display: inline-block
}

.main-header-left .list-items li i {
    display: block;
    font-size: 24px;
    color: #242f48;
    position: relative;
    line-height: .9;
    outline: 0;
    margin-right: 15px
}

.main-header-left .main-header-arrow {
    display: none;
    position: relative;
    top: -2px
}

.main-logo {
    display: flex;
    align-items: center;
    position: relative;
    height: 2rem;
    margin: 0 auto;
    justify-content: center;
    text-align: center
}

.main-body.main-sidebar-hide .logo-icon {
    display: block;
    height: 2.3rem;
    text-align: center;
    margin: 0 auto
}

.main-body.main-sidebar-hide .main-logo {
    display: none
}

.main-logo:focus,
.main-logo:hover {
    color: #078586
}

.main-logo i {
    display: block;
    line-height: .9;
    margin-right: 8px;
    font-size: 40px
}

.main-logo i::before {
    width: auto;
    height: auto
}

.main-header-menu-icon {
    margin-right: 10px;
    width: 20px;
    height: 30px;
    display: flex;
    align-items: center
}

.main-header-menu-icon span {
    display: block;
    position: relative;
    width: 15px;
    height: 2px;
    background-color: #242f48;
    border-radius: 3px;
    transition: all .2s ease-in-out
}

.main-header-menu-icon span::after,
.main-header-menu-icon span::before {
    content: '';
    position: absolute;
    left: 0;
    width: 20px;
    height: 2px;
    background-color: #242f48;
    border-radius: 3px;
    transition: all .2s ease-in-out
}

.main-header-menu-icon span::before {
    top: -6px
}

.main-header-menu-icon span::after {
    bottom: -6px
}

@media (min-width:992px) {
    .main-header-menu-icon {
        margin-right: 25px
    }
}

@media (prefers-reduced-motion:reduce) {
    .main-header-menu-icon span {
        transition: none
    }
}

@media (prefers-reduced-motion:reduce) {
    .main-header-menu-icon span::after,
    .main-header-menu-icon span::before {
        transition: none
    }
}

.main-header-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 240px;
    background-color: #fff;
    overflow-y: auto;
    z-index: 1000;
    transform: translateX(-240px);
    transition: all .35s
}

.main-header-menu>.nav {
    flex-direction: column;
    padding: 20px
}

.main-header-menu .nav-item::before {
    content: '';
    position: absolute;
    top: 67px;
    margin-left: 25px;
    width: 15px;
    height: 15px;
    border-top: 2px solid rgba(28, 39, 60, .12);
    border-left: 2px solid rgba(28, 39, 60, .12);
    background-color: #fff;
    transform: rotate(45deg);
    z-index: 100;
    display: none
}

.main-header-menu .nav-item+.nav-item {
    margin-top: 10px
}

.main-header-menu .nav-item>.nav-link {
    display: flex;
    align-items: center;
    position: relative;
    color: #031b4e;
    font-size: 15px;
    font-weight: 500;
    padding: 0;
    transition: all .2s ease-in-out
}

.main-header-menu .nav-item>.nav-link:hover {
    color: #078586
}

.main-header-menu .nav-item>.nav-link:hover i {
    color: #078586
}

.main-header-menu .nav-item>.nav-link.with-sub::after {
    content: '\f3d0';
    display: inline-block;
    font-family: Ionicons;
    font-size: 12px;
    font-weight: 400;
    margin-left: auto;
    margin-top: 3px;
    opacity: .5
}

.main-header-menu .nav-item>.nav-link .typcn {
    font-size: 20px;
    line-height: .95;
    margin-right: 15px;
    width: 16px
}

.main-header-menu .nav-item>.nav-link .typcn::before {
    width: auto
}

.main-header-menu .nav-item.active>.nav-link {
    color: #078586;
    position: relative
}

.main-header-menu .nav-item.active>.nav-link::before {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    left: -20px;
    width: 2px;
    background-color: #078586;
    border-radius: 3px
}

.main-header-menu .nav-item.show .main-menu-sub {
    display: block
}

@media (prefers-reduced-motion:reduce) {
    .main-header-menu {
        transition: none
    }
}

@media (min-width:992px) {
    .main-header-menu {
        background-color: transparent;
        border-right: 0;
        position: static;
        width: auto;
        overflow-y: visible;
        transform: none;
        z-index: auto
    }
}

@media (min-width:992px) {
    .main-header-menu>.nav {
        padding: 0;
        flex-direction: row;
        align-items: center
    }
}

@media (min-width:992px) {
    .main-header-menu .nav-item+.nav-item {
        margin-top: 0;
        margin-left: 30px
    }
}

@media (prefers-reduced-motion:reduce) {
    .main-header-menu .nav-item>.nav-link {
        transition: none
    }
}

@media (min-width:992px) {
    .main-header-menu .nav-item>.nav-link {
        font-size: .875rem
    }
}

@media (min-width:992px) {
    .main-header-menu .nav-item>.nav-link.with-sub::after {
        margin-left: 5px
    }
}

@media (min-width:992px) {
    .main-header-menu .nav-item>.nav-link .typcn {
        font-size: 18px;
        margin-right: 7px
    }
}

@media (min-width:992px) {
    .main-header-menu .nav-item.active>.nav-link::before {
        top: auto;
        bottom: -22px;
        left: 0;
        right: 0;
        width: auto;
        height: 2px
    }
}

@media (min-width:992px) {
    .main-header-menu .nav-item.show::before {
        display: block
    }
}

.main-header-menu-header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid rgba(28, 39, 60, .05)
}

.main-header-menu-header .close {
    font-weight: 300;
    font-size: 28px
}

@media (min-width:992px) {
    .main-header-menu-header {
        display: none
    }
}

.main-menu-sub {
    font-size: 13px;
    margin-left: 32px;
    margin-top: 12px;
    display: none
}

.main-menu-sub .nav {
    flex-direction: column
}

.main-menu-sub .nav-link {
    padding: 0;
    display: block;
    color: #4d5875
}

.main-menu-sub .nav-link+.nav-link {
    margin-top: 6px;
    padding-top: 6px;
    border-top: 1px dotted #dde2ef
}

.main-menu-sub .nav-link:focus,
.main-menu-sub .nav-link:hover {
    color: #078586
}

.main-menu-sub .nav-link.active {
    color: #078586;
    font-weight: 500
}

@media (min-width:992px) {
    .main-menu-sub {
        position: absolute;
        top: 74px;
        background-color: #fff;
        padding: 15px 20px;
        border: 2px solid rgba(28, 39, 60, .12);
        min-width: 200px;
        margin-left: 0;
        margin-top: 0
    }
}

@media (min-width:992px) {
    .main-menu-sub .nav-link.active {
        font-weight: 400
    }
}

.main-menu-sub-mega {
    left: 0;
    right: 0;
    background-color: transparent;
    padding: 0;
    border: 0
}

.main-menu-sub-mega .container {
    padding: 0
}

.main-menu-sub-mega .container>div+div {
    margin-top: 25px
}

.main-menu-sub-mega .nav {
    min-width: 120px;
    flex-direction: column
}

.main-menu-sub-mega .nav+.nav {
    border-top: 1px dotted #dde2ef;
    margin-top: 6px;
    padding-top: 6px
}

.main-menu-sub-mega .nav>span {
    display: block;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: #242f48;
    letter-spacing: .5px;
    margin-bottom: 15px;
    margin-top: 30px
}

.main-menu-sub-mega .nav>span:first-of-type {
    margin-top: 0
}

@media (max-width:991.98px) {
    .main-menu-sub-mega .container {
        max-width: none
    }
}

@media (min-width:992px) {
    .main-menu-sub-mega .container {
        display: flex;
        background-color: #fff;
        border: 2px solid rgba(28, 39, 60, .12)
    }
}

@media (min-width:992px) {
    .main-menu-sub-mega .container>div {
        display: flex;
        padding: 20px;
        flex: 1;
        margin-top: 0
    }
}

@media (min-width:1200px) {
    .main-menu-sub-mega .container>div {
        padding: 20px 25px
    }
}

@media (min-width:992px) {
    .main-menu-sub-mega .container>div+div {
        margin-top: 0;
        border-left: 1px solid rgba(28, 39, 60, .05)
    }
}

@media (min-width:1200px) {
    .main-menu-sub-mega .nav {
        min-width: 140px
    }
}

@media (min-width:992px) {
    .main-menu-sub-mega .nav+.nav {
        margin-left: 20px;
        margin-top: 31px;
        padding-top: 0;
        border-top: 0
    }
}

@media (min-width:1200px) {
    .main-menu-sub-mega .nav+.nav {
        margin-left: 25px
    }
}

@media (min-width:992px) {
    .main-menu-sub-mega .nav>span {
        font-size: 12px
    }
}

.main-header-center {
    flex: 1;
    margin: 0 50px;
    position: relative;
    display: none
}

@media (min-width:992px) {
    .main-header-center {
        display: block
    }
}

.main-header-right {
    display: flex;
    align-items: center
}

.main-header-right .btn-social {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #949eb7;
    border-radius: 3px;
    transition: all .2s ease-in-out
}

.main-header-right .btn-social:focus,
.main-header-right .btn-social:hover {
    color: #4d5875
}

.main-header-right .btn-social i {
    font-size: 18px;
    line-height: 0
}

.main-header-right .btn-social+.btn-social {
    margin-left: 10px
}

.main-header-right .btn-buy {
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 11px;
    font-weight: 500;
    background-color: #078586;
    color: #fff;
    letter-spacing: .5px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    transition: all .2s ease-in-out;
    margin-left: 20px
}

.main-header-right .btn-buy:focus,
.main-header-right .btn-buy:hover {
    background-color: #452efa
}

@media (prefers-reduced-motion:reduce) {
    .main-header-right .btn-social {
        transition: none
    }
}

@media (prefers-reduced-motion:reduce) {
    .main-header-right .btn-buy {
        transition: none
    }
}

.main-header-search-link {
    font-size: 18px;
    color: #4d5875;
    line-height: 1;
    margin-right: 15px
}

.main-header-arrow {
    font-size: 27px;
    color: #242f48;
    display: inline-block;
    line-height: 0
}

.main-header-arrow i {
    line-height: 0
}

.main-header-arrow:focus,
.main-header-arrow:hover {
    color: #4d5875
}
.main-header-message {
    margin-right: 5px;
}
.main-header-message,
.main-header-notification {
    position: relative;
    margin: auto 3px
}

.main-header-message>a,
.main-header-notification>a,
.nav-item.full-screen>a {
    display: block;
    font-size: 22px;
    color: #272746;
    position: relative;
    line-height: 37px;
    outline: 0;
    height: 40px;
    width: 40px;
    background: 0 0;
    margin: auto;
    text-align: center
}

.profile-name {
    margin-top: 3px
}

.main-profile-menu>.profile-name::after {
    content: '';
    position: absolute;
    top: 46px;
    left: 50%;
    margin-left: -7px;
    width: 14px;
    height: 14px;
    border: 2px solid transparent;
    border-top-color: #078586;
    border-left-color: #078586;
    transform: rotate(45deg);
    background-color: #078586;
    z-index: 7;
    display: none
}

@media (min-width:992px) {
    .main-header-message>a::after,
    .main-header-notification>a::after {
        bottom: -36px
    }
}

.main-header-notification .dropdown-menu {
    background-color: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    border-width: 0;
    margin-top: 0;
    z-index: 900;
    border-radius: 0;
    width: 300px;
    position: absolute;
    top: 52px;
    left: auto;
    right: -10px;
    bottom: auto;
    padding: 0;
    border: #d0d7e8;
    border-width: 1px;
    z-index: 9
}

.main-header-message .dropdown-menu {
    width: 300px;
    position: absolute;
    top: 52px;
    left: auto;
    right: -10px;
    bottom: auto;
    border-color: transparent;
    border-width: 1px;
    z-index: 9
}

.main-header-notification .dropdown-footer {
    text-align: center;
    padding-top: 10px;
    font-size: 13px;
    border-top: 1px dotted #b9c2d8
}

.main-header-notification.show .dropdown-menu {
    display: block
}

@media (min-width:576px) {
    .drop-flag.show>a::after,
    .main-header-message.show>a::after,
    .main-header-notification.show>a::after {
        display: block
    }
}

.drop-flag.show .dropdown-menu,
.main-header-message.show .dropdown-menu {
    display: block
}

.main-notification-title {
    font-size: 18px;
    font-weight: 700;
    color: #242f48;
    margin-bottom: 5px;
    line-height: 1
}

.main-notification-text {
    font-size: 13px;
    margin-bottom: 15px
}

.main-notification-list .media {
    padding: 10px 0;
    position: relative
}

.main-notification-list .media+.media {
    border-top: 1px dotted #b9c2d8
}

.main-notification-list .media.new {
    color: #242f48
}

.main-notification-list .media:focus,
.main-notification-list .media:hover {
    z-index: 1
}

.main-notification-list .media:focus::before,
.main-notification-list .media:hover::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -20px;
    bottom: -1px;
    right: -20px;
    background-color: #ecf0fa;
    border-top: 1px solid #e3e8f7;
    border-bottom: 1px solid #e3e8f7;
    z-index: -1
}

.main-notification-list .media-body {
    margin-left: 15px;
    font-size: 13px
}

.main-notification-list .media-body p {
    margin-bottom: 5px;
    line-height: 1.3
}

.main-notification-list .media-body strong {
    font-weight: 500
}

.main-notification-list .media-body span {
    display: block;
    font-size: 11px;
    color: #949eb7
}

.main-profile-menu>.main-img-user {
    outline: 0;
    width: 32px;
    height: 32px
}

.main-profile-menu>.main-img-user::before {
    content: '';
    position: absolute;
    bottom: -20px;
    right: -90px;
    margin-left: -7px;
    width: 14px;
    height: 14px;
    border: 2px solid transparent;
    border-top-color: #d0d7e8;
    border-left-color: #d0d7e8;
    transform: rotate(45deg);
    background-color: #fff;
    z-index: 901;
    display: none
}

.main-profile-menu .dropdown-menu {
    background-color: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    border-width: 0;
    margin-top: 0;
    z-index: 900;
    border-radius: 0;
    position: absolute;
    top: 56px;
    left: auto;
    right: -10px;
    bottom: auto;
    width: 230px;
    border-width: 1px
}

.main-profile-menu .dropdown-item {
    position: relative;
    padding: 20px 16px;
    height: 38px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #031b4e
}

.main-profile-menu .dropdown-item i {
    font-size: 20px;
    margin-right: 10px;
    width: 24px;
    text-align: center
}

.main-profile-menu .dropdown-item i.typcn {
    line-height: .9
}

.main-profile-menu .dropdown-item:focus,
.main-profile-menu .dropdown-item:hover {
    background-color: transparent;
    color: #078586
}

.main-profile-menu .dropdown-item+.dropdown-item {
    border-top: 1px dotted #e2eaf9
}

.main-profile-menu .dropdown-item:last-child {
    margin-bottom: 10px
}

.main-profile-menu.show .dropdown-menu {
    display: block;
    top: 58px
}

@media (min-width:576px) {
    .main-profile-menu.show .main-img-user::before {
        display: block
    }
}

.main-header-profile {
    display: flex;
    flex-direction: column;
    align-items: center
}

.main-header-profile .main-img {
    margin-bottom: 10px;
    margin-top: 10px
}

.main-header-profile .main-img img {
    width: 60px;
    height: 60px;
    border-radius: 50%
}

.main-header-profile h6 {
    font-weight: 700;
    color: #242f48;
    margin-bottom: 3px;
    font-size: 16px;
    color: #fff
}

.main-header-profile span {
    display: block;
    font-size: 12px;
    margin-bottom: 0;
    color: rgba(255, 255, 255, .6)
}

@media (min-width:576px) {
    .main-header-profile .main-img-user {
        width: 80px;
        height: 80px
    }
}

.main-header-sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000
}

.main-header .input-group-btn .btn {
    border: 0 solid #ebeaf1!important;
    background: 0 0;
    border-radius: 3px;
    color: #3c4858;
    height: 38px;
    width: 38px;
    line-height: 24px;
    border-radius: 50px;
    font-size: 22px
}

.main-header .input-group-btn .btn:hover {
    border-radius: 50%;
    color: #078586
}

.main-header .input-group-btn .btn:hover i {
    color: #2205bf
}

.main-header .input-group-btn .btn i {
    font-size: 16px;
    color: #3c4858
}

.main-header .navbar-form.active .input-group-btn .btn i {
    font-size: 14px;
    line-height: 27px
}

.main-header form[role=search] {
    top: 0;
    right: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    z-index: 0
}

.main-header form[role=search] button {
    padding: 5px 12px;
    border-radius: 0;
    border-width: 0;
    color: #6f7282;
    background-color: #f8f8f8;
    border-color: #e7e7e7;
    box-shadow: none;
    outline: 0
}

.main-header form[role=search] input {
    padding: 5px 12px;
    border-radius: 0;
    border-width: 0;
    color: #6f7282;
    background-color: #f8f8f8;
    border-color: #e7e7e7;
    box-shadow: none;
    outline: 0;
    padding: 16px 12px;
    font-size: 14px;
    color: #807b90;
    box-shadow: none
}

.main-header form[role=search] button[type=reset] {
    display: none;
    background: #fff;
    border: 0!important
}

.main-header .input-group-btn .btn i {
    font-size: 18px;
    color: #000
}

.main-header-message.right-toggle .nav-link {
    padding: 0
}

@media (min-width:992px) {
    .main-header-menu-icon {
        margin-right: 25px;
        margin-left: auto;
        margin-right: 8px!important
    }
}

.main-header form[role=search] input {
    font-size: 16px;
    opacity: 0;
    display: none;
    height: 66px;
    transition: all 5s
}

.main-header form[role=search].active {
    width: 100%;
    z-index: 9999;
    right: 0;
    border-left: 1px solid #e3e3f7;
    position: absolute;
    left: -1px
}

.main-header form[role=search].active button {
    display: table-cell;
    opacity: 1
}

.main-header form[role=search].active input {
    display: table-cell;
    opacity: 1;
    width: 100%;
    height: 64px;
    background: #fff;
    padding-left: 20px;
    border-radius: 3px 3px 0 0;
    transition: all 5s;
    box-shadow: 0 1px 15px 1px rgba(62, 57, 107, .1)
}

.main-header-message i {
    margin-right: 5px;
}

.main-header-message i,
.main-header-notification i,
.nav-item.full-screen i {
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 50%;
    line-height: 40px;
    font-size: 18px
}

.main-header-message .dropdown-footer,
.main-header-notification .dropdown-footer {
    border-top: 1px solid #dce1ef;
    padding: 10px;
    background: #f6f6fb;
    box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, .1)
}

.main-header-message .dropdown-footer a {
    font-size: 13px
}

.main-header-center {
    display: flex
}

.main-header {
    margin-bottom: 20px;
    border-bottom: 1px solid #dee4ec;
    box-shadow: 5px 7px 26px -5px #cdd4e7
}

.header .form-control {
    border: 2px solid #d0d7e8
}

@media (min-width:992px) {
    .main-header.fixed-header {
        position: fixed;
        z-index: 999;
        margin-top: 0
    }
    .main-header-left .form-control {
        height: 40px;
        border-radius: 20px;
        background: #ecf0fa;
        border: 1px solid #ecf0fa
    }
    .main-header-left .btn {
        position: absolute;
        top: 0;
        right: 12px;
        background-color: transparent;
        height: 40px;
        color: #b4bdce;
        transition: none;
        font-size: 16px;
        padding-right: 13px
    }
}

.main-header-message .nav-link i,
.main-header-notification .nav-link i,
.nav-item.full-screen .nav-link i {
    text-align: center;
    line-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    font-size: 1.2rem;
    color: #39455d
}
.dropdown.nav-item.main-header-message.contact > a {
    font-size: 15px;
    margin-top: 5px;
    width: auto;
    color: #078586;
}
.dropdown.nav-item.main-header-message.contact > a:hover{
    color: #066162;
}
.dropdown.main-header-message .header-icon-svgs {
    color: #078586;
}
.main-header-message>a,
.main-header-notification>a,
.nav-item.full-screen>a {
    padding: 0;
    border-radius: 50%
}

.header-megamenu-dropdown i {
    font-size: 1.2rem;
    margin-right: 3px
}

.main-header.hor-header {
    box-shadow: none;
    margin-bottom: 0
}

.main-header.hor-header>.container-fluid {
    padding-left: 25px;
    padding-right: 25px;
    width: 1200px
}

.main-header .main-header-left .main-logo {
    display: flex;
    margin: 0
}

@media (max-width:1134px) {
    .main-header-left .form-control {
        width: 280px
    }
}

@media (min-width:1135px) {
    .main-header-left .form-control {
        width: 350px
    }
}

.main-header-arrow {
    display: none;
    position: relative;
    right: auto;
    display: none;
    position: relative;
    right: auto
}

@media (max-width:480px) {
    .main-header-right .nav .nav-item .dropdown-menu {
        left: auto;
        right: auto;
        justify-content: center;
        margin: 0 auto;
        width: 93%;
        position: fixed;
        left: 5px;
        right: 5px;
        margin-top: 0;
        transform: translate3d(0px, 53px, 0px)!important;
    }
    .main-header.nav {
        top: -1px;
        position: fixed;
        padding-left: 5px;
        padding-right: 5px
    }
}

@media (min-width:991px) {
    .header {
        width: 230px
    }
}

@media (max-width:991px) {
    .main-header-center .btn,
    .main-header-left .form-control {
        display: none
    }
}

@media (max-width:578px) {
    .main-header-message>a,
    .main-header-notification>a,
    .nav-item.full-screen>a {
        padding: .5rem .7rem
    }
    .profile-user {
        padding: .5rem .3rem
    }
    .main-header-center {
        display: none
    }
    .main-header-message.right-toggle .nav-link {
        padding: 0 0
    }
    .main-header>.container,
    .main-header>.container-fluid {
        padding-right: 5px;
        padding-left: 5px
    }
    .main-header-message>a,
    .main-header-notification>a,
    .nav-item.full-screen>a {
        line-height: 38px
    }
}

@media (min-width:768px) {
    .main-header {
        z-index: 975!important;
        width: 100%!important;
        /* left: 0; */
        padding-left: 250px;
        position: fixed
    }
    .app.sidenav-toggled .main-header {
        padding-left: 80px;
        padding-left: 80px
    }
    .app.sidenav-toggled .main-sidebar-header {
        width: 80px
    }
}

.right-toggle {
    cursor: pointer
}

.horizontalMenucontainer .main-header.hor-header {
    padding-left: 0
}

.fullscreen-button {
    margin: auto 3px
}

@media (max-width:578px) and (min-width:321px) {
    .main-header-right .nav-link {
        padding: 0 .5rem
    }
}

@media (max-width:633px) and (min-width:321px) {
    .main-profile-menu {
        margin-left: 0!important;
        margin-right: 0!important;
        padding-right: 7px!important;
        padding-left: 7px!important
    }
}

@media (max-width:320px) {
    .main-header-right .nav-link {
        padding: 0
    }
    .main-profile-menu {
        margin-left: 0!important;
        margin-right: 0!important;
        padding-right: 0!important;
        padding-left: 7px!important
    }
    .total-revenue label {
        font-size: .775rem!important
    }
    .total-revenue h4 {
        font-size: 17px!important
    }
}

@media (min-width:576px) {
    .main-header .dropdown-menu:after {
        content: '';
        position: absolute;
        top: -8.5px;
        right: 24px;
        border-bottom: 9px solid #078586;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent
    }
}

.horizontalMenu body,
.horizontalMenu h1,
.horizontalMenu h2,
.horizontalMenu h3,
.horizontalMenu h4,
.horizontalMenu h5,
.horizontalMenu h6,
.horizontalMenu html,
.horizontalMenu iframe {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-family: Helvetica, sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    font-smoothing: antialiased;
    font-smooth: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
    font-smoothing: subpixel-antialiased;
    font-smooth: subpixel-antialiased
}

.horizontalMenu .cl {
    clear: both
}

.horizontalMenu img {
    border: 0 none;
    max-width: 100%
}

embed,
object,
video {
    border: 0 none;
    max-width: 100%
}

.horizontalMenu a:focus {
    outline: 0
}

.horizontalMenu:before {
    content: "";
    display: table
}

.horizontalMenu:after {
    content: "";
    display: table;
    clear: both
}

.horizontalMenucontainer {
    background-size: cover;
    overflow: hidden;
    background-attachment: fixed;
    background-position: 50% 0;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative
}

.horizontal-main {
    width: 100%;
    position: fixed
}

.horizontal-mainwrapper {
    margin: 0 auto
}

.desktoplogo {
    padding: .75rem;
    margin: 0;
    float: left
}

.desktoplogo img {
    width: 100%
}

.horizontalMenu {
    color: #fff;
    font-size: 14px;
    padding: 0;
    display: block
}

.horizontalMenu>.horizontalMenu-list {
    text-align: left;
    margin: 0 auto 0 auto;
    width: 100%;
    display: block;
    padding: 0
}

.horizontalMenu>.horizontalMenu-list>li {
    text-align: center;
    display: block;
    padding: 0;
    margin: 0;
    float: left
}

.horizontalMenu>.horizontalMenu-list>li>a {
    display: block;
    margin: 0 2px;
    border-radius: 0;
    text-decoration: none;
    position: relative;
    color: #5b6e88;
    padding: 15px 12px 15px 12px;
    font-weight: 400
}

.horizontalMenu>.horizontalMenu-list>li:last-child>a {
    border-right: 0
}

.horizontalMenu>.horizontalMenu-list>li a.menuhomeicon {
    padding-left: 25px;
    padding-right: 25px
}

.horizontalMenu>.horizontalMenu-list>li>a i {
    display: inline-block;
    font-size: 14px;
    line-height: inherit;
    margin-right: 5px
}

.horizontalMenu>.horizontalMenu-list>li>a .typcn {
    font-size: 1.15rem
}

.horizontalMenu>.horizontalMenu-list>li>a .arrow:after {
    border-left: 4px solid rgba(0, 0, 0, 0);
    border-right: 4px solid rgba(0, 0, 0, 0);
    border-top: 4px solid;
    content: "";
    float: right;
    right: 11px;
    height: 0;
    margin: 0 0 0 14px;
    position: absolute;
    text-align: right;
    top: 33px;
    width: 0
}

.horizontalMenu>.horizontalMenu-list>li.rightmenu {
    float: right;
    float: right
}

.horizontalMenu>.horizontalMenu-list>li.rightmenu a {
    padding: 0 30px 0 20px;
    border-right: none
}

.horizontalMenu>.horizontalMenu-list>li.rightmenu a i {
    font-size: 15px
}

.horizontalMenu>.horizontalMenu-list>li.rightmenu:before {
    content: "";
    display: table
}

.horizontalMenu-list>li.rightmenu:after {
    content: "";
    display: table
}

.horizontalMenu>.horizontalMenu-list>li.rightmenu {
    zoom: 1
}

.horizontalMenu>.horizontalMenu-list>li.rightmenu:after {
    clear: both
}

.horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch {
    float: right;
    width: 210px;
    height: 39px;
    position: relative;
    margin: 16px 0 0 0
}

.horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch .searchicon {
    -webkit-transition: all .7s ease 0s;
    -moz-transition: all .7s ease 0s;
    -o-transition: all .7s ease 0s;
    transition: all .7s ease 0s
}

.horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch input {
    width: 100%;
    position: relative;
    float: right;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    border: 0;
    padding: 0;
    margin: 0;
    text-indent: 15px;
    height: 39px;
    z-index: 2;
    outline: 0;
    color: #333;
    background-color: #efefef;
    -webkit-transition: all .7s ease 0s;
    -moz-transition: all .7s ease 0s;
    -o-transition: all .7s ease 0s;
    transition: all .7s ease 0s;
    font-size: 12px
}

.horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch input::placeholder {
    color: #a9a9a9
}

.horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch input:focus {
    color: #333;
    width: 230px
}

.horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch input:focus~.btnstyle .fa {
    color: #22252f;
    opacity: 1
}

.horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch input:focus~.searchicon {
    opacity: 1;
    z-index: 3;
    color: #fff
}

.horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch .btnstyle {
    top: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    line-height: 30px;
    z-index: 1;
    cursor: pointer;
    opacity: .3;
    color: #333;
    z-index: 1000;
    background-color: transparent;
    border: solid 0;
    -webkit-transition: all .7s ease 0s;
    -moz-transition: all .7s ease 0s;
    -o-transition: all .7s ease 0s;
    transition: all .7s ease 0s
}

.horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch .btnstyle .fa {
    line-height: 37px;
    margin: 0;
    padding: 0;
    text-align: center
}

.horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch .btnstyle:hover .fa {
    opacity: 1
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
    position: absolute;
    top: auto;
    z-index: 1000;
    margin: 0;
    padding: 5px;
    min-width: 190px;
    background-color: #fff;
    box-shadow: 0 8px 16px 0 rgba(230, 233, 239, .4);
    border: 1px solid #ecf0fa
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li {
    position: relative;
    margin: 0;
    padding: 0;
    display: block
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
    background-image: none;
    color: #6d7790;
    border-right: 0 none;
    text-align: left;
    display: block;
    line-height: 22px;
    padding: 8px 35px;
    text-transform: none;
    font-size: 13px;
    letter-spacing: normal;
    border-right: 0 solid
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
    text-decoration: none
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover:before {
    border-color: #078586
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a.active:before {
    border-color: #078586
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a>i {
    margin-right: 9px
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu {
    min-width: 200px;
    position: absolute;
    left: 80%;
    top: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #fff;
    box-shadow: 0 8px 16px 0 rgba(230, 233, 239, .4);
    border: 1px solid #e7eaf3
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li {
    position: relative;
    margin: 0;
    padding: 0;
    display: block
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a {
    background-image: none;
    color: #8291af;
    border-right: 0 none;
    text-align: left;
    display: block;
    line-height: 22px;
    padding: 8px 35px;
    text-transform: none;
    font-size: 13px;
    letter-spacing: normal;
    border-right: 0 solid
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
    text-decoration: none
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a>i {
    margin-right: 9px
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu {
    min-width: 220px;
    position: absolute;
    left: 100%;
    top: 0;
    margin: 0;
    list-style: none;
    padding: 0;
    background-color: #fff;
    border: 1px solid #e8ebf3
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li {
    position: relative;
    margin: 0;
    padding: 0;
    display: block
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
    background-image: none;
    color: #8291af;
    border-right: 0 none;
    text-align: left;
    display: block;
    line-height: 22px;
    padding: 8px 35px;
    text-transform: none;
    font-size: 13px;
    letter-spacing: normal;
    border-right: 0 solid
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
    text-decoration: none
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a>i {
    margin-right: 9px
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu {
    width: 100%;
    left: 0;
    position: absolute;
    top: auto;
    color: #22252f;
    z-index: 1000;
    margin: 0;
    text-align: left;
    font-size: 15px
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .megamenu-content {
    border: 1px solid #e8ebf3;
    background-color: #fff;
    padding: 14px 20px;
    border-radius: 2px;
    box-shadow: 0 5px 25px rgba(227, 228, 238, .2)
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .title {
    padding: 5px 5px 5px 0;
    font-size: 16px;
    color: #2c2c2c;
    margin: 0 0 4px 0;
    text-align: left;
    font-weight: 600
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .wsmwnutxt {
    width: 100%;
    font-size: 14px;
    text-align: justify;
    line-height: 22px;
    color: #2c2c2c;
    margin-top: 10px
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li {
    display: block;
    text-align: center;
    white-space: nowrap;
    text-align: left;
    padding: 5px 10px 5px 10px;
    position: relative
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a {
    line-height: 18px;
    border-right: none;
    text-align: left;
    padding: 6px 22px;
    background: #fff;
    background-image: none;
    color: #22252f;
    border-right: 0 none;
    display: block;
    font-size: 13px
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu li i {
    margin-right: 5px;
    text-align: center;
    width: 18px
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu li a:hover {
    background: 0 0;
    text-decoration: none
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li i {
    font-size: 12px
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu li i {
    margin-right: 5px;
    text-align: center;
    width: 18px
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .mrgtop {
    margin-top: 15px
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .show-grid div {
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: #dbdbdb;
    border: 1px solid #e7e7e7;
    color: #6a6a6a;
    margin: 2px 0;
    font-size: 13px
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu.halfmenu {
    width: 30%;
    right: auto;
    left: auto
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu.halfdiv {
    width: 20%;
    right: auto;
    left: auto
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form {
    width: 100%;
    display: block
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form input[type=text] {
    width: 100%;
    border: 1px solid #e2e2e2;
    color: #22252f;
    font-size: 13px;
    padding: 8px 5px;
    margin-bottom: 8px
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form textarea {
    width: 100%;
    border: 1px solid #e2e2e2;
    color: #22252f;
    font-size: 13px;
    padding: 8px 5px;
    margin-bottom: 8px;
    min-height: 122px
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form input[type=submit] {
    width: 25%;
    display: block;
    height: 32px;
    float: right;
    border: none;
    margin-right: 15px;
    cursor: pointer;
    background-color: #e1e1e1;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form input[type=button] {
    width: 25%;
    display: block;
    height: 32px;
    float: right;
    border: none;
    cursor: pointer;
    background-color: #e1e1e1;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .carousel-inner .item img {
    width: 100%
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .carousel-control-next,
.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .carousel-control-prev {
    opacity: .8
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .carousel-caption {
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    font-size: 13px;
    height: 31px;
    left: 0;
    padding: 7px 0;
    right: 0;
    width: 100%
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .typography-text {
    padding: 0 0;
    font-size: 14px
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .typography-text ul {
    padding: 0 0;
    margin: 0
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .typography-text p {
    text-align: justify;
    line-height: 24px;
    color: #656565
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .typography-text ul li {
    display: block;
    padding: 2px 0;
    line-height: 22px
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .typography-text ul li a {
    color: #656565
}

@media (min-width:1024px) {
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list:first-child {
        border: 0
    }
}

.horizontal-header {
    display: none
}

.horizontal-overlapbg {
    opacity: 0;
    visibility: hidden
}

.horizontalMenu .horizontalMenu-click,
.horizontalMenu .horizontalMenu-click02,
.horizontalMenu .horizontalMenu-click03,
.horizontalMenu .horizontalMenu-click04 {
    display: none
}

.hometext {
    display: none
}

@media only screen and (max-width:991px) {
    body.active.menu-open {
        overflow: hidden
    }
    .main-body.active.menu-open .horizontalMenucontainer .main-header.hor-header {
        position: absolute
    }
    .desktoplogo {
        display: none
    }
    .horizontal-main {
        height: 0;
        padding: 0
    }
    .horizontalMenucontainer {
        background-attachment: local;
        background-position: 33% 0
    }
    .horizontalMenu {
        width: 100%;
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        left: 0;
        overflow-y: hidden;
        padding: 0;
        top: 0;
        position: fixed;
        visibility: hidden;
        margin: 0
    }
    .horizontalMenu>.horizontalMenu-list {
        height: auto;
        min-height: 100%;
        width: 240px;
        background: #fff;
        padding-bottom: 0;
        margin-left: -240px;
        display: block;
        text-align: center;
        border-right: 1px solid #e8ebf4
    }
    .horizontalMenu>.horizontalMenu-list>li {
        width: 100%;
        display: block;
        float: none;
        border-right: none;
        background-color: transparent;
        position: relative;
        white-space: inherit;
        clear: right;
        padding: 0
    }
    .horizontalMenu>.horizontalMenu-list>li span a {
        width: 85%;
        margin: 0 auto
    }
    .horizontalMenu>.horizontalMenu-list>li>a {
        padding: 8px 32px 8px 17px;
        font-size: 14px;
        text-align: left;
        border-right: solid 0;
        color: #22252f;
        border-bottom: 1px solid;
        border-bottom-color: #e7eaf3;
        position: static;
        margin: 0;
        border-radius: 0
    }
    .horizontalMenu>.horizontalMenu-list>li>a span.i {
        display: none
    }
    .hor-menu .horizontalMenu>.horizontalMenu-list>li>a {
        padding: 10px;
        border-radius: 3px
    }
    .hor-menu .horizontalMenu>.horizontalMenu-list>li:hover .sub-icon {
        color: #078586;
        background: 0 0
    }
    .horizontalMenu>.horizontalMenu-list>li a.menuhomeicon {
        padding-left: 17px;
        padding-right: 17px;
        border-top: solid 1px rgba(0, 0, 0, .13)
    }
    .horizontalMenu>.horizontalMenu-list>li>a>.fa {
        font-size: 16px;
        color: #fff
    }
    .horizontalMenu>.horizontalMenu-list>li>a .arrow:after {
        display: none
    }
    .horizontalMenu>.horizontalMenu-list>li:hover>a {
        text-decoration: none
    }
    .horizontalMenu>.horizontalMenu-list>li>a>.hometext {
        display: inline-block
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
        display: none;
        position: relative;
        top: 0;
        background-color: #fff;
        border: none;
        padding: 0;
        opacity: 1;
        visibility: visible;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        -webkit-transition: inherit;
        -moz-transition: inherit;
        transition: inherit;
        -webkit-transform-style: flat;
        box-shadow: none;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        margin: 5px
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
        line-height: 20px;
        font-size: 13px;
        padding: 10px 0 10px 36px;
        color: #8291af
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li span+a {
        padding-right: 30px
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a>.fa {
        display: none
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
        background-color: #f6f7fb;
        color: #22252f;
        text-decoration: none
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu li:hover>a {
        background-color: #fff;
        color: #078586
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu {
        width: 100%;
        position: static;
        left: 100%;
        top: 0;
        display: none;
        margin: 0;
        padding: 0;
        border: solid 0;
        transform: none;
        opacity: 1;
        visibility: visible;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        -webkit-transition: inherit;
        -moz-transition: inherit;
        transition: inherit;
        -webkit-transform-style: flat
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li {
        margin: 0;
        padding: 0;
        position: relative
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a {
        line-height: 20px;
        font-size: 13px;
        padding: 10px 0 10px 62px
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:before {
        left: 45px
    }
    .horizontalMenu-click.horizontal-activearrow .sub-icon {
        color: #078586;
        background: 0 0
    }
    .horizontalMenu-click.horizontal-activearrow a {
        color: #078586
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li span+a {
        padding-right: 30px
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a>.fa {
        display: none
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
        background-color: transparent;
        color: #078586;
        text-decoration: none
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a.active {
        color: #22252f
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li:hover>a {
        color: #078586
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu {
        width: 100%;
        position: static;
        left: 100%;
        top: 0;
        display: none;
        margin: 0;
        padding: 0;
        border: solid 0;
        transform: none;
        opacity: 1;
        visibility: visible;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        -webkit-transition: inherit;
        -moz-transition: inherit;
        transition: inherit;
        -webkit-transform-style: flat
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li {
        margin: 0
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
        line-height: 20px;
        font-size: 13px;
        padding: 10px 0 10px 80px
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li span+a {
        padding-right: 30px
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a>.fa {
        display: none
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
        background-color: transparent;
        color: #22252f;
        text-decoration: none
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a.active {
        color: #22252f
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu {
        color: #22252f;
        display: none;
        position: relative;
        top: 0;
        padding: 0 0;
        border: solid 0;
        transform: none;
        opacity: 1;
        visibility: visible;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        -webkit-transition: inherit;
        -moz-transition: inherit;
        transition: inherit;
        border-bottom: 1px solid rgba(0, 0, 0, .13);
        -webkit-transform-style: flat
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu.halfmenu {
        width: 100%;
        margin: 0;
        padding: 5px 0 10px 0
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .title {
        color: #22252f;
        font-size: 15px;
        padding: 10px 8px 10px 0
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu>ul {
        width: 100%;
        margin: 0;
        padding: 0;
        font-size: 13px
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu>ul>li>a {
        padding: 9px 14px;
        line-height: normal;
        font-size: 13px;
        background-color: #e7e7e7;
        color: #22252f
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu>ul>li>a:hover {
        background-color: #000
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu ul li.title {
        line-height: 26px;
        color: #22252f;
        margin: 0;
        font-size: 15px;
        padding: 7px 0;
        background-color: transparent
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu.halfdiv {
        width: 100%
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form {
        padding: 5px 0 62px 0
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .show-grid div {
        margin: 0
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form input[type=button],
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form input[type=submit] {
        width: 46%
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form textarea {
        min-height: 100px
    }
    .horizontal-header {
        width: 100%;
        display: block;
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
        height: 54px;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        border-top: 1px solid rgba(0, 0, 0, .1);
        text-align: center;
        -webkit-transition: all .5s ease-in-out;
        -moz-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        -ms-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out
    }
    .active .horizontal-header {
        -webkit-transition: all .5s ease-in-out;
        -moz-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        -ms-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out
    }
    .horizontal-header .smllogo {
        display: inline-block;
        margin-top: 11px
    }
    .horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch {
        width: 86%;
        margin: 7% 7%;
        padding: 0
    }
    .horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch input {
        border-radius: 50px
    }
    .horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch input:focus {
        width: 100%
    }
    .callusbtn {
        color: #a9a9a9;
        font-size: 21px;
        position: absolute;
        right: 5px;
        top: 0;
        transition: all .4s ease-in-out 0s;
        z-index: 102;
        padding: 11px 14px
    }
    .callusbtn .fa {
        vertical-align: top;
        margin-top: 4px
    }
    .callusbtn:hover .fa {
        color: #a9a9a9
    }
    .animated-arrow {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 102;
        -webkit-transition: all .4s ease-in-out;
        -moz-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        -ms-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
        cursor: pointer;
        padding: 14px 35px 16px 0;
        margin: 18px 0 0 15px
    }
    .animated-arrow span {
        cursor: pointer;
        height: 2px;
        width: 17px;
        background: #22252f;
        position: absolute;
        display: block;
        content: '';
        transition: all .5s ease-in-out
    }
    .animated-arrow span:after,
    .animated-arrow span:before {
        cursor: pointer;
        height: 2px;
        width: 17px;
        background: #22252f;
        position: absolute;
        display: block;
        content: ''
    }
    .animated-arrow span:before {
        top: -7px;
        width: 23px
    }
    .animated-arrow span:after {
        bottom: -7px;
        width: 17px
    }
    .animated-arrow span:after,
    .animated-arrow span:before {
        transition: all .5s ease-in-out
    }
    .active .animated-arrow span {
        background-color: transparent
    }
    .active .animated-arrow span:after {
        width: 23px;
        top: -3px
    }
    .active .animated-arrow span:before {
        top: -3px
    }
    .active .animated-arrow.active span:after {
        top: -3px
    }
    .active .animated-arrow span:before {
        transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        bottom: 0
    }
    .active .animated-arrow span:after {
        transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg)
    }
    .horizontal-overlapbg {
        right: 0;
        width: calc(100% - 240px);
        height: 100vh;
        min-height: 100%;
        position: fixed;
        top: 0;
        opacity: 0;
        visibility: hidden;
        background-color: rgba(0, 0, 0, .45);
        cursor: pointer
    }
    .active .horizontalMenu .horizontal-overlapbg {
        opacity: 1;
        visibility: visible;
        -webkit-transition: opacity 3.5s ease-in-out;
        -moz-transition: opacity 3.5s ease-in-out;
        -ms-transition: opacity 3.5s ease-in-out;
        -o-transition: opacity 3.5s ease-in-out
    }
    .horizontalMenucontainer {
        -webkit-transition: all .5s ease-in-out;
        -moz-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        -ms-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out
    }
    .active .horizontalMenucontainer {
        margin-left: 240px;
        -webkit-transition: all .5s ease-in-out;
        -moz-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        -ms-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out
    }
    .active .horizontalMenu {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        visibility: visible;
        z-index: 1000;
        top: 0;
        height: 100%!important
    }
    .active .horizontalMenu>.horizontalMenu-list {
        webkit-transition: all .5s ease-in-out;
        -moz-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        -ms-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
        margin-left: 0
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click {
        height: 49px;
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        cursor: pointer;
        width: 100%
    }
    .horizontalMenu>.horizontalMenu-list>li .horizontalMenu-click.horizontal-activearrow>i {
        transform: rotate(180deg);
        margin-top: 5px
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i {
        display: block;
        height: 23px;
        width: 25px;
        margin-top: 14px;
        margin-right: 10px;
        background-size: 25px;
        font-size: 16px;
        color: #888aaf;
        float: right
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02 {
        height: 41px;
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        cursor: pointer;
        width: 100%
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02>i {
        display: block;
        width: 32px;
        margin: 0 6px 8px 6px;
        font-size: 14px;
        color: rgba(255, 255, 255, .25);
        float: right;
        top: 10px;
        line-height: 38px
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02>i.horizontalMenu-rotate {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg)
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click03 {
        height: 41px;
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        cursor: pointer;
        width: 100%
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click03>i {
        display: block;
        width: 32px;
        margin: 0 6px 8px 6px;
        font-size: 14px;
        color: rgba(255, 255, 255, .25);
        float: right;
        top: 10px;
        line-height: 38px
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click03>i.horizontalMenu-rotate {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg)
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click04 {
        height: 41px;
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        cursor: pointer;
        width: 100%
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click04>i {
        display: block;
        width: 32px;
        margin: 0 6px 8px 6px;
        font-size: 14px;
        color: rgba(255, 255, 255, .25);
        float: right;
        top: 10px;
        line-height: 38px
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click04>i.horizontalMenu-rotate {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg)
    }
    .horizontalMenu>.horizontalMenu-list>li a span,
    .horizontalMenu>.horizontalMenu-list>li>a .horizontal-icon {
        display: none
    }
    .mega-menubg {
        background: #fff!important;
        margin: 5px
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu {
        background-color: #fff;
        box-shadow: none!important
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a {
        color: #22252f;
        background-color: #fff
    }
    .mega-menubg {
        border: 0!important;
        box-shadow: none!important
    }
    .mega-menubg.hor-mega-menu ul {
        margin-left: 0;
        padding-left: 0
    }
    .hor-menu .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a {
        background: #fff!important;
        color: #8291af!important
    }
    .desktop-logo {
        margin-left: 2rem!important
    }
}

@media only screen and (min-width:992px) and (max-width:1162px) {
    .horizontalMenu>.horizontalMenu-list>li>a>.arrow {
        display: none
    }
    .horizontalMenu>.horizontalMenu-list>li a.menuhomeicon {
        padding-left: 22px;
        padding-right: 22px
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .horizontalMenu>.horizontalMenu-list>li>a {
        font-size: 12px;
        padding: 10px 10px 10px 10px
    }
    .horizontalMenu .badge {
        top: 13px!important
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu,
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
        top: 43px
    }
}

.horizontalMenu-list .sub-menu .sub-menu:after,
.horizontalMenu-list .sub-menu .sub-menu:before {
    display: none
}

@media (max-width:991px) {
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .megamenu-content {
        border: 0;
        box-shadow: none
    }
    .horizontalMenucontainer .main-header form[role=search] {
        width: inherit
    }
    .horizontalMenucontainer .main-header.hor-header {
        position: fixed;
        box-shadow: 0 0 10px rgba(28, 39, 60, .2);
        border-bottom: 1px solid #d3dfef
    }
}

@media (min-width:550px) {
    .desktop-logo-1 {
        display: none
    }
}

@media (max-width:549px) {
    .desktop-logo-1 {
        float: left;
        margin-left: 0;
        height: 2.6rem
    }
    .horizontalMenucontainer .header-brand {
        margin-left: 3rem
    }
    .desktop-logo {
        display: none
    }
}

.horizontalMenu ul li a.btn {
    font-size: 14px!important;
    margin: 5px 0
}

@media (min-width:992px) {
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
        opacity: 0;
        visibility: hidden;
        -o-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        -moz-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        -o-transition: -o-transform .3s, opacity .3s;
        -ms-transition: -ms-transform .3s, opacity .3s;
        -moz-transition: -moz-transform .3s, opacity .3s;
        -webkit-transition: -webkit-transform .3s, opacity .3s;
        transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        transform: rotateX(-75deg);
        -o-transform: rotateX(-75deg);
        -moz-transform: rotateX(-75deg);
        -webkit-transform: rotateX(-75deg)
    }
    .horizontalMenu>.horizontalMenu-list>li:hover>.horizontal-megamenu,
    .horizontalMenu>.horizontalMenu-list>li:hover>ul.sub-menu {
        opacity: 1;
        visibility: visible;
        transform: rotateX(0);
        -o-transform: rotateX(0);
        -moz-transform: rotateX(0);
        -webkit-transform: rotateX(0)
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu {
        opacity: 0;
        visibility: hidden;
        transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        transform: rotateX(-75deg);
        -o-transform: rotateX(-75deg);
        -moz-transform: rotateX(-75deg);
        -webkit-transform: rotateX(-75deg)
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li:hover>ul.sub-menu {
        opacity: 1;
        visibility: visible;
        -o-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        -moz-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        -o-transition: -o-transform .4s, opacity .4s;
        -ms-transition: -ms-transform .4s, opacity .4s;
        -moz-transition: -moz-transform .4s, opacity .4s;
        -webkit-transition: -webkit-transform .4s, opacity .4s;
        transform: rotateX(0);
        -o-transform: rotateX(0);
        -moz-transform: rotateX(0);
        -webkit-transform: rotateX(0)
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu {
        opacity: 0;
        visibility: hidden;
        -o-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        -moz-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        -o-transition: -o-transform .4s, opacity .4s;
        -ms-transition: -ms-transform .4s, opacity .4s;
        -moz-transition: -moz-transform .4s, opacity .4s;
        -webkit-transition: -webkit-transform .4s, opacity .4s;
        transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        transform: rotateX(-75deg);
        -o-transform: rotateX(-75deg);
        -moz-transform: rotateX(-75deg);
        -webkit-transform: rotateX(-75deg)
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li:hover>ul.sub-menu {
        opacity: 1;
        visibility: visible;
        transform: rotateX(0);
        -o-transform: rotateX(0);
        -moz-transform: rotateX(0);
        -webkit-transform: rotateX(0)
    }
    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu {
        opacity: 0;
        visibility: hidden;
        -o-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        -moz-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        -o-transition: -o-transform .3s, opacity .3s;
        -ms-transition: -ms-transform .3s, opacity .3s;
        -moz-transition: -moz-transform .3s, opacity .3s;
        -webkit-transition: -webkit-transform .3s, opacity .3s;
        transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        transform: rotateX(-75deg);
        -o-transform: rotateX(-75deg);
        -moz-transform: rotateX(-75deg);
        -webkit-transform: rotateX(-75deg)
    }
    .header-style1 .horizontalMenu>.horizontalMenu-list>li {
        padding: .75rem 0
    }
    .main-header.hor-header.fixed-header {
        box-shadow: none;
        z-index: 99
    }
    .horizontal-mainwrapper.container,
    .horizontal-megamenu .container {
        padding: 0
    }
    .side-badge {
        position: absolute;
        right: 21px;
        top: 11px
    }
}

@media (min-width:768px) {
    .app.sidebar-mini.sidenav-toggled .side-badge {
        display: none!important
    }
}

.mega-menubg {
    background: #fff;
    padding: 14px 5px;
    box-shadow: 0 8px 16px 0 rgba(230, 233, 239, .4);
    border: 1px solid #e7eaf3
}

.horizontal-main.hor-menu {
    background: #fff;
    border-bottom: 1px solid #d5d8e2;
    border-top: 0;
    z-index: 1;
    margin-top: 0;
    box-shadow: 0 1px 15px 1px #c0c0c7
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a {
    background: #fff;
    color: #8291af
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li:hover a {
    color: #078586!important
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li:hover a:before {
    border-color: #078586!important
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>a.active {
    color: #078586;
    background: #fff;
    background: 0 0
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>a:hover {
    color: #078586;
    background: 0 0
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a.active {
    color: #078586
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a.active:before {
    color: #078586
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a.active {
    color: #078586
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a.active:before {
    color: #078586
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover:before {
    color: #078586
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a.active {
    color: #078586!important
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu .sub-menu-sub:after {
    content: '\e92f';
    font-family: feather;
    font-size: 13px;
    position: absolute;
    top: 9px;
    right: 14px;
    color: #121029;
    opacity: .4
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover,
.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
    color: #078586
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a:hover {
    color: #078586
}

.sub-menu li a:before {
    content: "\e92f";
    font-family: feather!important;
    position: absolute;
    top: 9px;
    left: 12px;
    font-size: 9px;
    color: #8b96af;
    display: block
}
.dropdown ul li a:before{
    content: "\e92f";
    font-family: feather!important;
    position: absolute;
    top: 8px;
    left: 12px;
    font-size: 9px;
    color: #8b96af;
    display: block
}

.mega-menubg li a:before {
    content: '';
    width: 8px;
    height: 8px;
    border-width: 1.5px;
    border-style: solid;
    border-radius: 50px;
    position: absolute;
    left: 6px;
    z-index: 99;
    border-color: #bec5d0;
    top: 15px
}

.main-content.hor-content {
    padding-top: 0!important
}

.sticky.sticky-pin .horizontal-main.hor-menu {
    margin-top: 0
}

.horizontalMenu>.horizontalMenu-list>li>a i.horizontal-icon {
    margin-right: 0;
    vertical-align: middle
}

.hor-icon {
    width: 18px;
    height: 18px;
    margin-right: 3px
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>a.active .hor-icon,
.hor-menu .horizontalMenu>.horizontalMenu-list>li>a:hover .hor-icon {
    fill: #078586!important
}

.horizontal-badge {
    position: absolute;
    top: 15px;
    margin-left: 1px
}

.desktop-logo {
    height: 2rem;
    text-align: center;
    margin: 0 auto;
    justify-content: center
}

.horizontalMenucontainer .sticky-pin {
    z-index: 99
}

.horizontalMenucontainer .main-header {
    z-index: 999!important
}

.horizontalMenucontainer .main-header.hor-header {
    position: relative;
    border-bottom: 1px solid #d3dfef
}

.horizontalMenucontainer .side-header {
    position: relative
}

.mega-menubg.hor-mega-menu h3 {
    color: #2c364c;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .5px;
    margin-bottom: 12px;
    height: 15px;
    padding: 0 20px 0 2px
}

.horizontalMenucontainer .main-header {
    border-bottom: 1px solid #dce7f5
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>a.active {
    color: #078586;
    background: 0 0
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>a.active .side-menu__icon {
    fill: #078586
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a.active:focus {
    color: #078586
}

.horizontalMenucontainer .side-menu__icon {
    font-size: 23px;
    line-height: 0;
    margin-right: 7px;
    width: 22px;
    height: 22px;
    line-height: 34px;
    border-radius: 3px;
    text-align: center;
    color: #a8b1c7;
    fill: #5b6e88
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>a:hover .side-menu__icon {
    fill: #078586
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
    color: #078586
}

@media (max-width:991px) {
    body.active.menu-open .horizontalMenucontainer .main-header.hor-header {
        box-shadow: none
    }
    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a:before {
        left: 62px
    }
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a.active,
.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
    color: #078586
}

@media (max-width:991.98px) {
    .main-iconbar-show .main-iconbar {
        display: flex
    }
    .main-iconbar-show .main-iconbar-aside {
        display: block
    }
}

.main-iconbar {
    width: 64px;
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #fff;
    z-index: 500
}

.main-iconbar .nav {
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 5px 0
}

.main-iconbar .nav-link {
    position: relative;
    padding: 0;
    color: #031b4e;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center
}

.main-iconbar .nav-link i {
    font-size: 28px
}

.main-iconbar .nav-link i.typcn {
    line-height: 1
}

.main-iconbar .nav-link i.typcn::before {
    width: auto
}

.main-iconbar .nav-link i.typcn-device-laptop,
.main-iconbar .nav-link i.typcn-th-large-outline {
    font-size: 25px
}

.main-iconbar .nav-link i.fa-desktop {
    font-size: 23px
}

.main-iconbar .nav-link:focus,
.main-iconbar .nav-link:hover {
    color: #4d5875
}

.main-iconbar .nav-link.active {
    color: #078586
}

.main-iconbar .nav-link+.nav-link {
    margin-top: 10px
}

@media (min-width:992px) {
    .main-iconbar {
        display: flex
    }
}

.main-iconbar-logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    line-height: .9;
    color: #242f48;
    flex-shrink: 0;
    margin-bottom: 20px;
    position: relative
}

.main-iconbar-logo::after {
    content: '';
    position: absolute;
    bottom: 6px;
    left: 20px;
    height: 3px;
    width: 24px;
    background-color: #078586;
    border-radius: 2px
}

.main-iconbar-logo:focus,
.main-iconbar-logo:hover {
    color: #242f48
}

.main-iconbar-bottom {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center
}

.main-iconbar-help {
    line-height: 0;
    font-size: 22px;
    color: #737f9e;
    margin-bottom: 20px
}

.main-iconbar-help:focus,
.main-iconbar-help:hover {
    color: #242f48
}

.main-iconbar-aside {
    width: 230px;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid #b9c2d8;
    position: fixed;
    top: 0;
    left: 64px;
    overflow-y: auto;
    z-index: 600;
    display: none
}

@media (min-width:1200px) {
    .main-iconbar-aside {
        border-right: 0;
        z-index: 500
    }
}

@media (min-width:992px) {
    .main-iconbar-aside.show {
        display: block
    }
}

@media (min-width:1200px) {
    .main-iconbar-aside.show+.main-content {
        margin-left: 294px
    }
}

.main-iconbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    padding-left: 0
}

.main-iconbar-header .main-logo {
    line-height: 1
}

.main-iconbar-toggle-menu {
    display: block;
    position: relative;
    line-height: 0;
    color: #242f48;
    font-size: 28px;
    opacity: .5
}

.main-iconbar-toggle-menu:focus,
.main-iconbar-toggle-menu:hover {
    opacity: 1;
    color: #242f48
}

.main-iconbar-toggle-menu i:first-child {
    display: none
}

@media (min-width:1200px) {
    .main-iconbar-toggle-menu {
        font-size: 24px
    }
    .main-iconbar-toggle-menu::before {
        content: '';
        position: absolute;
        top: 3px;
        bottom: 3px;
        left: -1px;
        border-left: 2px solid #242f48
    }
    .main-iconbar-toggle-menu i:first-child {
        display: block
    }
    .main-iconbar-toggle-menu i:last-child {
        display: none
    }
}

.main-iconbar-body {
    padding: 20px;
    padding-left: 0;
    height: calc(100vh - 68px)
}

.main-iconbar-body .nav {
    flex-direction: column;
    border-left: 2px solid #dde2ef;
    padding-top: 10px;
    padding-left: 20px
}

.main-iconbar-body .nav-item {
    position: relative;
    display: block
}

.main-iconbar-body .nav-item+.nav-item {
    margin-top: -1px;
    border-top: 1px dotted #d0d7e8
}

.main-iconbar-body .nav-item.active .nav-link {
    color: #078586
}

.main-iconbar-body .nav-item.active .nav-link i {
    color: #078586
}

.main-iconbar-body .nav-item.show .nav-sub {
    display: block
}

.main-iconbar-body .nav-link {
    position: relative;
    color: #031b4e;
    font-size: 13px;
    padding: 0;
    display: flex;
    align-items: center;
    height: 38px
}

.main-iconbar-body .nav-link i {
    color: #949eb7;
    font-size: 16px;
    font-weight: 400;
    line-height: 0;
    margin-right: 10px
}

.main-iconbar-body .nav-link i.typcn {
    line-height: .9
}

.main-iconbar-body .nav-link i.typcn::before {
    width: auto
}

.main-iconbar-body .nav-link:focus,
.main-iconbar-body .nav-link:focus i,
.main-iconbar-body .nav-link:hover,
.main-iconbar-body .nav-link:hover i {
    color: #078586
}

.main-iconbar-body .nav-link.with-sub {
    justify-content: space-between
}

.main-iconbar-body .nav-link.with-sub::after {
    content: '\f3d0';
    font-family: Ionicons;
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    margin-left: auto;
    color: #949eb7
}

.main-iconbar-body .nav-sub {
    list-style: none;
    padding: 0;
    margin: 0;
    border-top: 1px dotted #d0d7e8;
    display: none
}

.main-iconbar-body .nav-sub .nav-sub-item {
    position: relative;
    display: block;
    margin-left: 15px
}

.main-iconbar-body .nav-sub .nav-sub-item+.nav-sub-item {
    border-top: 1px dotted #d0d7e8
}

.main-iconbar-body .nav-sub .nav-sub-item.active>.nav-sub-link {
    color: #078586
}

.main-iconbar-body .nav-sub .nav-sub-link {
    display: flex;
    align-items: center;
    height: 36px;
    color: #031b4e
}

.main-iconbar-body .nav-sub .nav-sub-link:focus,
.main-iconbar-body .nav-sub .nav-sub-link:hover {
    color: #078586
}

@media (min-width:992px) {
    .main-iconbar-body .nav-link.with-sub::after {
        margin-left: 4px;
        font-size: 12px
    }
}

.main-iconbar-title {
    font-size: 20px;
    font-weight: 700;
    color: #242f48;
    margin-bottom: 10px;
    line-height: 1
}

.main-iconbar-text {
    display: block;
    font-size: 12px;
    color: #737f9e;
    margin-bottom: 12px
}

.main-iconbar-pane {
    margin-bottom: 20px;
    display: none
}

.main-iconbar-pane.show {
    display: block
}

@media (max-width:575px) {
    .main-content-left-show {
        overflow: hidden
    }
}

@media (max-width:991.98px) {
    .main-content-left-show .main-content-left {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 800;
        border-right: 1px solid #e3e8f7;
        box-shadow: 0 0 3px rgba(28, 39, 60, .1);
        overflow-y: auto
    }
}

@media (max-width:575px) {
    .main-content-left-show .main-content-body {
        display: none
    }
}

@media (max-width:991.98px) {
    .main-content-body-show .main-header .main-header-menu-icon {
        display: none
    }
    .main-content-body-show .main-header .main-header-left .main-header-arrow {
        display: block
    }
    .main-content-body-show .main-content-left {
        display: none
    }
    .main-content-body-show .main-content-body {
        display: block
    }
}

.main-content .container,
.main-content .container-fluid {
    padding-left: 20px;
    padding-right: 20px
}

@media (min-width:992px) {
    .main-content .container {
        padding: 0
    }
}

@media (max-width:991.98px) {
    .main-content .container {
        max-width: none
    }
    .browser-stats .sub-text {
        display: none
    }
    .browser-stats h6 {
        line-height: 2
    }
}

.main-content-app {
    padding-top: 0
}

.main-content-app .container,
.main-content-app .container-fluid {
    height: 100%;
    padding: 0
}

@media (min-width:992px) {
    .main-content-app {
        padding-top: 0
    }
}

.main-content-left {
    width: 100%;
    position: relative;
    flex-shrink: 0
}

@media (min-width:992px) {
    .main-content-left {
        display: block;
        padding: 0
    }
}

.main-content-header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.main-content-header .main-logo {
    flex: 1;
    text-align: center
}

@media (min-width:992px) {
    .main-content-header {
        display: none
    }
}

.main-content-body {
    flex: 1
}

.main-content-right {
    padding-left: 25px;
    margin-left: 25px;
    width: 200px;
    display: none
}

@media (min-width:992px) {
    .main-content-right {
        display: block
    }
}

.main-content-title {
    color: #242f48;
    font-weight: 500;
    font-size: 32px;
    text-indent: -1px;
    line-height: 1;
    position: relative;
    margin-bottom: 20px
}

@media (min-width:992px) {
    .main-content-title {
        margin-bottom: 40px
    }
}

.card-dashboard-eight .card-title,
.card-table-two .card-title,
.main-content-label {
    color: #242f48;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 10px;
    letter-spacing: .2px
}

.main-content-label-sm {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: #949eb7;
    letter-spacing: .5px
}

.main-content-text {
    font-size: 12px;
    line-height: 1.4;
    display: block;
    color: #737f9e
}

.main-content-1 .main-content-header {
    display: block;
    padding: 20px;
    background-color: transparent;
    border: 0
}

.main-content .header {
    border-radius: 8px;
    margin: 20px
}

.main-content.horizontal-content {
    padding-top: 0!important;
    margin-left: 0
}

@media (min-width:992px) {
    .main-content {
        padding-top: 0!important;
        margin-left: 240px
    }
    .main-body.main-sidebar-hide .main-content {
        margin-left: 80px!important;
        transition: margin-left .3s ease
    }
}

@media (max-width:991px) {
    .main-content-body-show .main-header-arrow {
        display: block;
        padding: 10px 20px;
        background: #078586;
        color: #fff
    }
}

@media (min-width:991px) {
    .main-content.horizontal-content {
        padding-top: 0!important;
        margin-top: 0
    }
}

@media (min-width:992px) {
    .main-content-body-show .main-header-arrow {
        display: none
    }
}

@media (max-width:635px) {
    .main-content .breadcrumb-header .breadcrumb-right {
        margin-top: 12px!important
    }
}

@media (max-width:991.98px) {
    .main-content.horizontal-content {
        margin-top: 0!important
    }
}

.main-content-left-components {
    border-right: 1px solid #dde2ef;
    width: 200px
}

.main-content-left-components .component-item label {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .5px
}

.main-content-left-components .component-item label~label {
    margin-top: 30px
}

.main-content-left-components .component-item .nav-link {
    padding: 0;
    display: block;
    font-size: 13px;
    color: #4d5875
}

.main-content-left-components .component-item .nav-link+.nav-link {
    margin-top: 5px
}

.main-content-left-components .component-item .nav-link:hover {
    color: #078586
}

.main-content-left-components .component-item .nav-link.active {
    color: #078586;
    font-weight: 500
}

.main-content-choose-demo {
    position: relative;
    z-index: 5;
    background-color: #0f0373;
    color: rgba(255, 255, 255, .5);
    padding: 0
}

.main-content-choose-demo .container {
    display: block;
    padding: 20px
}

.main-content-choose-demo .title-label {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 10px;
    color: #078586;
    margin-bottom: 3px
}

.main-content-choose-demo .title {
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    letter-spacing: -.5px;
    margin-bottom: 10px
}

.main-content-choose-demo .title-text {
    font-weight: 300;
    margin-bottom: 20px
}

.main-content-choose-demo .card {
    border-width: 0;
    margin-bottom: 10px;
    height: 200px;
    overflow: hidden;
    position: relative
}

.main-content-choose-demo .card img {
    transition: all .2s ease-in-out
}

.main-content-choose-demo .card figure {
    margin-bottom: 0
}

.main-content-choose-demo .card figure:focus img,
.main-content-choose-demo .card figure:hover img {
    transform: scale(1.75, 1.75)
}

.main-content-choose-demo .card figure:focus figcaption,
.main-content-choose-demo .card figure:hover figcaption {
    opacity: 1
}

.main-content-choose-demo .card figcaption {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(25, 4, 190, .75);
    opacity: 0;
    transition: all .2s ease-in-out
}

.main-content-choose-demo .card figcaption .btn,
.main-content-choose-demo .card figcaption .sp-container button {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 11px;
    font-weight: 500;
    min-height: inherit;
    padding: 10px 20px;
    border-radius: 2px
}

@media (min-width:576px) {
    .main-content-choose-demo .container {
        padding: 40px
    }
}

@media (min-width:992px) {
    .main-content-choose-demo .container {
        padding: 100px 40px
    }
}

@media (min-width:1200px) {
    .main-content-choose-demo .container {
        padding: 100px 0
    }
}

@media (min-width:768px) {
    .main-content-choose-demo .title-label {
        font-size: 11px
    }
}

@media (min-width:768px) {
    .main-content-choose-demo .title {
        font-size: 30px
    }
}

@media (min-width:576px) {
    .main-content-choose-demo .title-text {
        margin-bottom: 40px
    }
}

@media (min-width:768px) {
    .main-content-choose-demo .title-text {
        font-size: 16px
    }
}

@media (min-width:992px) {
    .main-content-choose-demo .title-text {
        margin-bottom: 60px
    }
}

@media (min-width:576px) {
    .main-content-choose-demo .card {
        height: 155px
    }
}

@media (min-width:768px) {
    .main-content-choose-demo .card {
        height: 215px;
        margin-bottom: 15px
    }
}

@media (min-width:1200px) {
    .main-content-choose-demo .card {
        height: 255px
    }
}

@media (prefers-reduced-motion:reduce) {
    .main-content-choose-demo .card img {
        transition: none
    }
}

@media (prefers-reduced-motion:reduce) {
    .main-content-choose-demo .card figcaption {
        transition: none
    }
}

.sp-container .main-content-choose-demo .card figcaption button {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 11px;
    font-weight: 500;
    min-height: inherit;
    padding: 10px 20px;
    border-radius: 2px
}

.main-content-choose-demo .card.coming-soon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #242f48;
    background-color: #dde2ef
}

.main-content-choose-demo .card.coming-soon .typcn {
    line-height: .9;
    font-size: 48px;
    margin-bottom: 10px
}

.main-content-choose-demo .card.coming-soon h6 {
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px
}

.main-content-choose-demo .card-table-two .card-title,
.main-content-choose-demo .main-content-label {
    color: #737f9e;
    margin-bottom: 5px;
    letter-spacing: 1px
}

.card-dashboard-eight .main-content-choose-demo .card-title,
.card-table-two .main-content-choose-demo .card-title,
.main-content-choose-demo .card-dashboard-eight .card-title {
    color: #737f9e;
    margin-bottom: 5px;
    letter-spacing: 1px
}

.main-content-choose-demo .main-content-title {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    color: #fff
}

@media (min-width:768px) {
    .main-content-choose-demo .main-content-title {
        font-size: 17px
    }
}

.main-content-left.main-content-left-mail.card-body {
    padding: 1.25rem
}

.main-header-profile .main-img-user {
    display: inline-block;
    position: relative;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    text-align: center
}

.main-header-profile .main-img-user::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 6px;
    width: 6px;
    height: 6px;
    background-color: #17b86a;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, .95);
    border-radius: 100%;
    bottom: 3px;
    display: block!important;
    top: 35px
}

.main-profile-menu .profile-user img {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    margin-left: 0;
    width: 37px;
    height: 37px;
    border-radius: 50%
}

.main-profile-overview .main-img-user::after {
    display: none!important
}

.main-content-profile {
    flex: 1
}

@media (max-width:991.98px) {
    .main-content-profile .container,
    .main-content-profile .container-fluid {
        display: block
    }
}

.main-content-left-profile {
    padding-left: 0;
    padding-right: 0;
    display: block;
    border-bottom: 1px solid #e3e8f7;
    padding-bottom: 25px;
    width: auto
}

@media (min-width:992px) {
    .main-content-left-profile {
        width: 270px;
        padding-right: 20px;
        padding-bottom: 0;
        border-right: 1px solid #e3e8f7;
        border-bottom: 0
    }
}

@media (min-width:1200px) {
    .main-content-left-profile {
        padding-right: 25px
    }
}

.main-profile-overview .main-img-user {
    width: 120px;
    height: 120px;
    margin-bottom: 20px
}

.main-profile-overview .btn-icon-list .btn,
.main-profile-overview .btn-icon-list .sp-container button {
    border-radius: 100%
}

.sp-container .main-profile-overview .btn-icon-list button {
    border-radius: 100%
}

.main-profile-name {
    color: #242f48;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 2px
}

.main-profile-name-text {
    color: #737f9e;
    font-size: 13px;
    margin-bottom: 0
}

.main-profile-bio {
    font-size: 13px;
    margin-bottom: 20px
}

.main-profile-social-list .media {
    align-items: center
}

.main-profile-social-list .media+.media {
    margin-top: 20px
}

.main-profile-social-list .media-icon {
    font-size: 34px;
    width: 30px;
    line-height: 0
}

.main-profile-social-list .media-body {
    margin-left: 20px
}

.main-profile-social-list .media-body span {
    display: block;
    font-size: 12px
}

.main-profile-social-list .media-body a {
    font-size: 13px
}

.main-content-body-profile .nav {
    flex-direction: column;
    padding: 20px 20px 20px 0;
    border-bottom: 1px solid #e3e8f7;
    margin-bottom: 1.3rem
}

.main-content-body-profile .main-nav-line .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    color: #364261
}

.main-content-body-profile .main-nav-line .nav-link.active {
    font-weight: 700;
    color: #078586
}

.main-content-body-profile .main-nav-line .nav-link.active::before {
    background-color: #078586
}

@media (min-width:576px) {
    .main-content-body-profile .nav {
        flex-direction: row;
        align-items: center
    }
}

@media (min-width:992px) {
    .main-content-body-profile .nav {
        padding-left: 20px
    }
}

@media (min-width:1200px) {
    .main-content-body-profile .nav {
        padding-left: 25px
    }
}

@media (min-width:576px) and (max-width:767.98px) {
    .main-content-body-profile .main-nav-line .nav-link.active::before {
        width: auto;
        top: auto;
        left: 0;
        right: 0;
        height: 2px
    }
}

@media (min-width:576px) and (max-width:767.98px) {
    .main-content-body-profile .main-nav-line .nav-link+.nav-link {
        margin-top: 0;
        margin-left: 20px
    }
}

.main-profile-body {
    padding: 15px 0 0
}

@media (min-width:576px) {
    .main-profile-body {
        padding-top: 20px
    }
}

@media (min-width:992px) {
    .main-profile-body {
        padding: 25px 0 0 20px
    }
}

@media (min-width:1200px) {
    .main-profile-body {
        padding-left: 25px
    }
}

.main-profile-view-chart {
    position: relative;
    width: calc(100% - 10px);
    height: 200px
}

@media (min-width:375px) {
    .main-profile-view-chart {
        width: 100%
    }
}

@media (min-width:576px) {
    .main-profile-view-chart {
        height: 250px
    }
}

.main-profile-view-info {
    position: absolute;
    top: 0;
    left: 0
}

.main-profile-view-info h6 {
    font-size: 32px;
    font-weight: 500;
    color: #242f48;
    margin-bottom: 0
}

.main-profile-view-info span {
    font-size: 12px;
    color: #f10075;
    margin-left: 5px
}

.main-profile-view-info p {
    font-size: 13px;
    margin-bottom: 0
}

.main-traffic-detail-item>div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 7px
}

.main-traffic-detail-item>div:first-child>span:first-child {
    color: #737f9e
}

.main-traffic-detail-item>div:first-child>span:last-child {
    font-size: 11px;
    font-weight: 700;
    color: #242f48
}

.main-traffic-detail-item>div:first-child>span:last-child span {
    color: #737f9e;
    font-weight: 400
}

.main-traffic-detail-item+.main-traffic-detail-item {
    margin-top: 25px
}

.main-traffic-detail-item .progress {
    height: 8px
}

.main-profile-work-list .media+.media {
    margin-top: 25px
}

.main-profile-work-list .media-logo {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    color: #fff;
    font-size: 21px;
    position: relative;
    top: 2px;
    border-radius: 100%
}

.main-profile-work-list .media-body {
    margin-left: 20px
}

.main-profile-work-list .media-body h6 {
    color: #242f48;
    font-weight: 500;
    margin-bottom: 2px
}

.main-profile-work-list .media-body span {
    display: block;
    margin-bottom: 5px
}

.main-profile-work-list .media-body p {
    margin-bottom: 0;
    font-size: 12px;
    color: #737f9e
}

.main-profile-contact-list .media {
    align-items: center
}

.main-profile-contact-list .media+.media {
    margin-top: 25px
}

.main-profile-contact-list .media-icon {
    line-height: 0;
    font-size: 36px;
    width: 35px;
    text-align: center
}

.main-profile-contact-list .media-body {
    margin-left: 25px
}

.main-profile-contact-list .media-body span {
    font-size: 12px;
    color: #737f9e;
    display: block;
    line-height: 1.3
}

.main-profile-contact-list .media-body div {
    font-weight: 500;
    color: #242f48
}

@media (max-width:1134px) {
    .main-profile-body .media.profile-footer {
        display: block!important
    }
    .main-profile-body .media.profile-footer .media-body {
        padding: 3px 0 15px 0
    }
}

@media (max-width:578px) {
    .main-profile-menu .profile-user img {
        margin-left: 0
    }
}

.main-profile-contact-list .media-icon,
.main-profile-social-list .media-icon,
.main-profile-work-list .media-logo {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    color: #fff;
    font-size: 21px;
    position: relative;
    top: 2px;
    border-radius: 100%
}

.profile-user .profile-edit {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    right: 0;
    background: #d5d4e0;
    margin: 0 auto;
    border: 0;
    color: #078586;
    text-align: center
}

.main-profile-body .card-header {
    padding-top: 0;
    padding-left: 0
}

.drop-down-profile {
    position: relative
}

.profile-footer a {
    width: 34px;
    height: 34px;
    background: #efeff5;
    color: #000;
    margin: 1px auto;
    text-align: center;
    line-height: 34px;
    display: inline-block;
    border-radius: 50%;
    font-size: 12px
}

.drop-down-profile span.assigned-task {
    position: absolute;
    font-size: 12px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    color: #fff;
    top: 0;
    margin: 0 auto;
    text-align: center;
    border-radius: 50%;
    right: 0
}

@media (max-width:575px) {
    .main-content-body-profile .main-nav-line .nav-link.active::before {
        left: 0
    }
}

@media (max-width:767.98px) {
    .main-profile-menu .profile-user img {
        margin-left: 0!important
    }
}

.profile-name {
    margin-top: 8px
}

.profile.navtab-custom {
    padding: 11px 0 10px 0
}

.profile.navtab-custom li a {
    padding: 13px;
    color: #000;
    background: 0 0;
    margin-right: 7px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border: 1px solid #e3e6f0;
    border-bottom: 0;
    font-size: 13px
}

.profile.navtab-custom .active a,
.profile.navtab-custom a.active {
    background: #ecf0fa;
    border-bottom: 0;
    color: #000
}

.ga-border {
    height: 3px;
    width: 40px;
    background-color: #078586;
    margin: 10px auto 5px auto
}

@media (max-width:991.98px) {
    .main-sidebar-show {
        overflow: hidden
    }
    .main-sidebar-show .main-sidebar {
        display: flex;
        overflow-y: auto;
        transform: translateX(0);
        visibility: visible
    }
    .main-sidebar-show .main-content {
        transform: translateX(240px)
    }
    .main-sidebar-show .main-navbar-backdrop {
        visibility: visible;
        opacity: 1
    }
}

.main-body-sidebar {
    display: block
}

.main-sidebar-body .nav-sub-item {
    margin-left: 55px
}

@media (min-width:992px) {
    .main-body-sidebar {
        display: flex;
        flex-direction: row
    }
}

.main-body-sidebar .main-content {
    flex: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
    transition: margin-left .3s ease
}

.main-body-sidebar .main-content-header {
    height: auto;
    padding: 15px 20px;
    background: #fff;
    margin: 21px;
    border: 1px solid #e3e8f1
}

.main-body-sidebar .main-footer {
    margin-top: auto
}

@media (min-width:992px) {
    .main-body-sidebar .main-header .container-fluid {
        padding-left: 20px;
        padding-right: 20px
    }
}

.main-sidebar {
    width: 240px;
    flex-direction: column;
    flex-shrink: 0;
    border-right: 0 solid #d8dfea;
    transform: translateX(-240px);
    visibility: hidden
}

@media (prefers-reduced-motion:reduce) {
    .main-sidebar {
        transition: none
    }
}

@media (max-width:991.98px) {
    .main-sidebar {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1000;
        background-color: #fff
    }
}

@media (min-width:992px) {
    .main-sidebar {
        position: fixed;
        display: flex;
        transform: none;
        z-index: 99;
        border-right-width: 1px;
        visibility: visible;
        background: #fff
    }
    .main-sidebar-header {
        width: 240px;
        z-index: 999
    }
    .main-sidebar-hide .main-sidebar-body .nav-link {
        border-radius: 0;
        margin-right: 0
    }
}

.main-sidebar-sticky {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0
}

.main-sidebar-sticky .main-sidebar-body {
    height: calc(100vh - 122px);
    position: relative
}

@media (min-width:992px) {
    .main-sidebar-sticky+.main-content {
        margin-left: 220px
    }
}

@media (min-width:1200px) {
    .main-sidebar-sticky+.main-content {
        margin-left: 240px
    }
}

.main-sidebar-header {
    height: 64px;
    padding: 15px 20px;
    width: 240px;
    background: #fff;
    border-bottom: 1px solid #eae8f1;
    position: fixed;
    border-right: 1px solid #eae8f1;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    transition: left .3s ease, width .3s ease
}

.main-sidemenu {
    margin-top: 70px;
    height: 90%
}

.main-sidebar-loggedin {
    padding: 20px 20px;
    display: flex;
    flex-shrink: 0;
    border-bottom: 1px solid #dee4ec;
    margin-top: 64px
}

.main-sidebar .nav-pills-circle {
    position: relative;
    margin: 0 auto;
    text-align: center
}

.main-sidebar .nav-pills-circle.nav .nav-item .dropdown-menu {
    left: -30px;
    min-width: 200px
}

.main-sidebar .nav-pills-circle.nav .nav-item .dropdown-menu .dropdown-item i {
    font-size: 14px;
    margin-right: 3px
}

.main-sidebar .nav-pills-circle .nav-pill {
    background: rgba(255, 255, 255, .1);
    border: 1px solid #eaedf1!important;
    color: #68798b!important;
    border-radius: 5px;
    margin: 0 4px;
    padding: .4rem .7rem
}

.main-sidebar-hide .main-sidebar-loggedin .main-img-user {
    margin: auto
}

.main-sidebar-loggedin .main-img-user {
    width: 38px;
    height: 38px
}

.main-sidebar-loggedin .media-body {
    margin-left: 12px;
    transition: left .4s ease, width .4s ease
}

.main-sidebar-loggedin .media-body h6 {
    font-weight: 700;
    font-size: 14px;
    color: #242f48;
    margin-bottom: 2px
}

.main-sidebar-loggedin .media-body span {
    display: block;
    color: #737f9e;
    font-size: 13px
}

@media (min-width:1200px) {
    .main-sidebar-body {
        padding: 0
    }
}

.main-sidebar-hide .side-badge {
    display: none
}

.main-sidebar-body .nav {
    flex-direction: column
}

.main-sidebar-body .nav-item.active .nav-link .nav-badge {
    right: 12px!important
}

.main-sidebar-body .nav-sub {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height .9s ease;
    -o-transition: max-height .9s ease;
    transition: max-height .9s ease;
    transition: all .9s;
    margin-left: 32px
}

.main-sidebar-body .nav-item+.nav-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 30px;
    right: 0;
    border-top: 1px dotted #e2eaf9
}

.main-sidebar-body .nav-link {
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    color: #242f48;
    padding-bottom: 2px
}

.main-sidebar-hide .main-sidebar-body .nav-link .leftmenu-icon {
    margin: auto
}

.main-sidebar-hide .nav-text-badge {
    display: none
}

.main-sidebar-body .nav-link i.typcn::before {
    width: auto
}

.nav-link.with-sub .angle {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    position: absolute;
    top: 17px;
    right: 23px;
    font-size: 12px
}

.nav-link.with-sub .side-badge {
    right: 23px;
    position: absolute
}

.main-sidebar-body .nav-link .leftmenu-icon .eve-icon {
    fill: #031b4e
}

.main-sidebar-body .nav-sub {
    list-style: none;
    padding: 0;
    margin: 0 0 0 7px;
    position: relative
}

.main-sidebar-body .nav-sub-item .nav-sub {
    padding: 0 0 0 18px;
    border-left-width: 1px;
    margin-left: 0
}

.main-sidebar-body .nav-sub-item .nav-sub .nav-sub-link {
    height: auto;
    padding: 7px 0
}

.main-sidebar-body .nav-sub-item.active>.nav-sub-link {
    color: #078586;
    border-radius: 1px
}

.main-sidebar-body .nav-sub-item.show .nav-sub {
    display: block
}

.main-sidebar-body .nav-sub-link {
    height: 34px;
    display: flex;
    align-items: center;
    color: #6c7293;
    font-size: 13px;
    webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.main-sidebar-body .nav-sub-link.with-sub {
    justify-content: space-between
}

.main-sidebar-body .nav-sub-link.with-sub::after {
    content: '\f3d0';
    font-family: Ionicons;
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    margin-left: auto;
    color: #d0d7e8
}

.main-sidebar-body .nav-sub-link+.nav-sub-link {
    border-top: 1px dotted #d0d7e8
}

@media (min-width:992px) {
    .main-sidebar-body .nav-sub-link.with-sub::after {
        margin-left: 4px;
        font-size: 12px
    }
}

.main-sidebar-open .main-sidebar {
    width: 240px;
    transition: left .3s ease
}

.main-sidebar {
    background: #078586;
    box-shadow: 0 0 11px rgba(0, 0, 0, .13)
}

.main-sidebar-body .nav-label {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1px;
    opacity: 1;
    padding: 20px 29px 10px;
    text-transform: capitalize;
    width: 100%;
    color: rgba(255, 255, 255, .4)!important;
    position: relative
}

.main-sidebar-body .nav-link {
    height: 45px;
    display: flex;
    align-items: center;
    padding: 2px 15px;
    font-size: 15px;
    color: #334151
}

.main-sidebar-body .nav-link:focus,
.main-sidebar-body .nav-link:hover {
    color: #fff
}

.main-sidebar-body .nav-sub li .nav-sub-link::after {
    content: "\e92f";
    font-family: feather!important;
    position: absolute;
    top: 7px;
    left: -30px;
    font-size: 12px
}

.main-sidebar-body .nav-item+.nav-item::before {
    border-top: 0
}

.main-sidebar-body .nav-link .leftmenu-icon {
    font-size: 18px;
    line-height: 0;
    margin-right: 4px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 3px;
    text-align: center
}

.main-sidebar-body .nav-label:after {
    content: "--";
    position: absolute;
    left: 13px;
    top: 18px
}

@media (min-width:992px) {
    .main-sidebar-hide .main-sidebar-body .nav-link i.typcn {
        line-height: 1.69;
        font-size: 1.125rem;
        margin-right: 0
    }
    .main-body.main-sidebar-hide .main-sidebar {
        width: 80px
    }
    .main-body.main-sidebar-hide .main-sidebar-loggedin .media-body,
    .main-body.main-sidebar-hide .sidemenu-label {
        display: none
    }
    .main-body.main-sidebar-hide .main-sidebar-body .nav-label {
        display: none
    }
    .main-body.main-sidebar-hide .main-sidebar-body .nav-link .leftmenu-icon {
        margin: 0
    }
    .main-body.main-sidebar-hide .main-sidebar-body .nav-item {
        margin: 0 auto;
        text-align: center
    }
    .main-body.main-sidebar-hide .main-sidebar-body .angle {
        display: none
    }
    .main-body.main-sidebar-hide .sidebar-navs,
    .main-body.main-sidebar-hide .user-info {
        display: none
    }
    .main-body.main-sidebar-hide .user-pic img {
        width: 40px!important;
        height: 40px;
        font-size: 36px!important;
        border-radius: 4px!important
    }
    .main-body.main-sidebar-hide .main-sidebar-loggedin {
        padding: 20px
    }
}

.sidebar-right .main-nav-line .nav-link {
    padding: 10px 18px 10px 21px;
    background: #dde2ef
}

.sidebar-right .main-nav-line .nav-link.active::before {
    bottom: 0
}

.main-sidebar-body .nav-item.show>.nav-sub {
    max-height: 100vh;
    -webkit-transition: max-height 2s ease;
    -o-transition: max-height 2s ease;
    transition: max-height 2s ease;
    transition: all 2s
}

.main-sidebar-body .nav-item.active .nav-link {
    color: #fff;
    font-weight: 500;
    border-top: 0;
    background-color: #5e5fe0
}

.main-sidebar-body .nav-item.active .angle {
    color: #fff!important
}

.main-sidebar-body .nav-sub-item.active>.nav-sub-link {
    color: #14c6e2;
    border-radius: 1px
}

.main-sidebar-body .nav-link {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    white-space: nowrap;
    padding: 7px 12px;
    color: #fff;
    -webkit-transition-duration: .45s;
    -moz-transition-duration: .45s;
    -o-transition-duration: .45s;
    transition-duration: .45s;
    transition-property: color;
    -webkit-transition-property: color;
    position: relative;
    font-size: .875rem;
    line-height: 1;
    vertical-align: middle;
    font-weight: 400
}

.main-sidebar-body .nav-sub-item {
    margin-left: 0;
    text-align: left;
    padding: 2.2px 25px 2.2px 52px
}

.main-sidebar-body .nav-item.show>.nav-sub {
    position: relative
}

.main-sidebar-body .nav-sub li.active .nav-sub-link::after {
    border-color: #078586;
    color: #14c6e2
}

.main-sidebar-hide .nav-sub {
    display: none
}

.main-sidebar-body .nav-sub {
    list-style: none;
    padding: 0;
    margin: 0!important;
    position: relative
}

.main-sidebar-body .nav-item {
    position: relative
}

.main-sidebar-body .nav-item.active {
    border-top: 0
}

.right-page .value {
    color: #161537;
    font-size: 1rem;
    display: block;
    font-weight: 700;
    line-height: 1;
    margin-top: 1px
}

.right-page .label {
    color: #979a9f;
    font-size: 12px;
    line-height: 1
}

.main-sidebar-body .nav-sub-link {
    height: 34px;
    display: flex;
    align-items: center;
    color: #fff;
    position: relative;
    font-size: 14px;
    font-weight: 200;
    webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.main-sidebar-body .nav-sub-link:hover {
    color: #14c6e2
}

@media (min-width:992px) {
    .main-sidebar-hide .main-header-menu-icon {
        margin: 0 auto!important;
        text-align: center
    }
    .main-sidebar-hide .main-sidebar-body .nav-item {
        border-top: 0
    }
    .main-sidebar-hide .main-sidebar-body .nav-item.active {
        background: 0 0
    }
    .main-sidebar-hide .main-sidebar-body .nav-link {
        border-radius: 0;
        margin-right: 0;
        width: 38px;
        height: 38px;
        margin: 5px 0;
        border-radius: 5px
    }
    .main-sidebar-hide .nav-search {
        display: none
    }
    .main-sidebar-hide .main-sidebar-body .nav-link {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 2px 10px
    }
    .animated-arrow span {
        transition: all .5s ease-in-out;
        transition: all .5s ease-in-out
    }
    .animated-arrow span:after,
    .animated-arrow span:before {
        transition: all .5s ease-in-out
    }
}

.app-sidebar__user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #a8a8a8;
    width: 100%;
    display: inline-block;
    background-size: cover;
    background-position: left
}

.user-info .text-dark {
    color: #031b4e!important;
    font-weight: 600;
    font-size: 14px;
    margin-top: 13px
}

.user-info .text-muted {
    color: #f2f5fb!important;
    font-size: 13px
}

.sidebar-navs .nav-pills-circle .nav-link {
    display: block;
    padding: .4rem .6rem;
    font-size: 16px;
    border: 1px solid #dde2ef;
    border-radius: 4px;
    line-height: 1
}

.main-sidebar-loggedin {
    padding: 0 20px;
    display: flex;
    flex-shrink: 0;
    border-bottom: 0;
    margin-top: 0;
    padding: 20px 20px 15px;
    transition: left .3s ease
}

.main-sidebar-loggedin .media-body h6 {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 5px
}

.nav-search .input-group-text {
    background: 0 0
}

.nav-search .form-control {
    color: #fff;
    background-color: #7878eb;
    border: 1px solid rgba(226, 232, 245, .25)
}

.nav-search .input-group-text {
    color: #fff;
    background-color: #7878eb;
    border: 1px solid rgba(226, 232, 245, .22)
}

.main-sidebar-loggedin .media-body {
    margin-left: 13px;
    transition: left .4s ease, width .4s ease
}

.main-sidebar .nav-pills-circle {
    margin-bottom: 10px!important;
    text-align: center;
    margin: 0 auto;
    justify-content: center
}

.main-sidebar-body .nav-item.show .nav-link.with-sub .angle {
    transform: rotate(90deg)
}

.main-sidebar {
    width: 240px
}

@media screen and (min-width:997px) {
    .main-sidebar-open .main-sidebar {
        width: 240px;
        transition: left .3s ease
    }
}

.main-sidebar-body .nav-item.active:before {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    height: 45px;
    width: 3px;
    left: 0;
    z-index: 99
}

.main-sidebar-body .nav .nav-item .nav-sub:before {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    height: 10%;
    width: 3px;
    left: 0;
    z-index: 99;
    height: 100%
}

@media (min-width:768px) {
    .sidebar-mini.sidenav-toggled .main-sidebar-header .logo-icon.dark-theme {
        display: none
    }
}

.main-sidebar-hide .nav-badge {
    position: absolute;
    top: 5px;
    display: block!important;
    padding: 3px 5px!important;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .09), 0 1px 3px rgba(0, 0, 0, .08)
}

.main-sidebar-body .nav-sub .nav-sub-item .nav-sub-link:before::hover {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    height: 10%;
    width: 1px;
    background: #078586;
    left: -27px;
    z-index: 99;
    height: 34px
}

.main-sidebar .search {
    position: relative;
    padding: 0 10px
}

.main-sidebar .search .form-control {
    display: block;
    width: 214px;
    height: 40px;
    border-radius: 25px
}

.main-sidebar .search .btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    height: 40px;
    color: #b9c2d8;
    transition: none;
    font-size: 14px;
    padding-right: 31px
}

.app-sidebar .slide.active .side-menu__item {
    background: 0 0
}

.app-sidebar__user .user-pro-body {
    display: block;
    padding: 15px 0
}

.app-sidebar__user .user-pro-body img {
    display: block;
    margin: 0 auto 0;
    border: 2px solid #c9d2e8;
    box-shadow: 0 5px 5px 0 rgba(44, 44, 44, .2);
    padding: 3px;
    background: #fff
}

.app-sidebar__user .user-pro-body .u-dropdown {
    color: #97999f
}

.app-sidebar__user .user-pro-body .user-info span.text-gray {
    color: #969696!important
}

.app-sidebar__user .user-info {
    margin: 0 auto;
    text-align: center
}

.app-sidebar__user .user-info h4 {
    color: #14112d;
    font-size: 15px
}

.sidebar {
    position: fixed;
    display: block;
    top: 0;
    width: 300px;
    bottom: 0;
    z-index: 9999;
    height: 100%;
    background: #fff;
    box-shadow: none
}

.sidebar .tab-menu-heading {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    margin-bottom: 27px;
    background: #ecf0fa
}

.sidebar .tabs-menu-body {
    margin-top: 56px
}

.sidebar .tabs-menu ul li {
    width: 100%;
    display: block
}

.sidebar .tabs-menu ul li .active {
    background: #078586;
    color: #fff;
    border: 1px solid #078586
}

.sidebar .tabs-menu ul li a {
    padding: 8px 16px;
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid #ebeaf1;
    font-weight: 300;
    background: #ecf0fa;
    color: #000
}

.sidebar .tabs-menu ul li:last-child a {
    margin-bottom: 0
}

.sidebar .nav.panel-tabs {
    padding: 20px
}

.sidebar .avatar-md {
    width: 40px!important;
    height: 40px!important;
    font-size: 15px!important
}

.sidebar .avatar-lg {
    width: 50px!important;
    height: 50px!important
}

.sidebar .card {
    box-shadow: none;
    border: 0!important
}

.sidebar .list-group-item {
    margin-bottom: -2px
}

@media (max-width:480px) {
    .sidebar .tab-menu-heading .tabs-menu ul li {
        width: auto;
        margin: 0
    }
}

.sidebar-right .list a {
    color: #081e3c
}

.morris-hover.morris-default-style {
    border-radius: 0
}

.main-donut-chart {
    position: relative;
    border-radius: 50%;
    overflow: hidden
}

.main-donut-chart .slice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.main-donut-chart .chart-center {
    position: absolute;
    border-radius: 50%
}

.main-donut-chart .chart-center span {
    display: block;
    text-align: center
}

#ecom-chart {
    height: 200px!important
}

.legend {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 0;
    margin-right: 10px;
    margin-top: 6px
}

.list-unstyled .legend-content {
    display: inline-block;
    vertical-align: top
}

.list-unstyled li {
    display: flex;
    margin-bottom: 20px
}

.dot-label {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation: ani 1s linear infinite;
    left: 9px;
    bottom: -12px;
    position: relative
}

#summary-chart {
    height: 204px;
    width: 100%
}

.summary.chart-legend {
    top: 169px
}

.morris-wrapper-demo {
    height: 200px;
    position: relative;
    margin-left: -15px;
    margin-right: -15px
}

@media (min-width:576px) {
    .morris-wrapper-demo {
        height: 300px
    }
}

.morris-donut-wrapper-demo {
    height: 200px
}

@media (min-width:576px) {
    .morris-donut-wrapper-demo {
        height: 250px
    }
}

.chartjs-wrapper-demo {
    height: 250px
}

@media (max-width:330px) {
    .chartjs-wrapper-demo {
        width: 290px
    }
}

@media (min-width:992px) {
    .chartjs-wrapper-demo {
        height: 300px
    }
}

.chartist-wrapper-demo {
    height: 200px
}

@media (min-width:768px) {
    .chartist-wrapper-demo {
        height: 300px
    }
}

.flotChart6 {
    width: 100%;
    height: 180px
}

.chart-icons {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center
}

.sales-flot {
    position: relative
}

.sales-flot .flot-chart .flot-x-axis>div span:first-child {
    display: block;
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 500;
    font-size: 10px;
    color: #737f9e;
    top: 10px;
    position: relative
}

.sales-flot .flot-chart .flot-x-axis>div span:last-child {
    display: block;
    font-size: 16px;
    font-weight: 700;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #242f48;
    line-height: 1;
    top: 15px;
    position: relative
}

.sales-bar-chart {
    position: relative;
    width: auto;
    height: 160px
}

.chart-legend {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    z-index: 10;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase
}

.chart-legend div {
    display: flex;
    align-items: center
}

.chart-legend div+div {
    margin-left: 15px
}

.chart-legend span {
    width: 10px;
    height: 4px;
    display: block;
    margin-right: 5px
}

@media (min-width:576px) {
    .chart-legend div+div {
        margin-left: 30px
    }
}

@media (min-width:576px) {
    .chart-legend span {
        width: 40px;
        margin-right: 10px
    }
}

.chart-wrapper {
    position: relative;
    margin-left: -13px;
    margin-right: -13px
}

@media (min-width:576px) {
    .chart-wrapper {
        margin-left: -10px;
        margin-right: -15px
    }
}

.card-dashboard-twelve .flot-chart {
    width: 100%;
    height: 200px;
    position: relative
}

.card-dashboard-twelve .flot-chart .flot-x-axis>div span:first-child {
    display: block;
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 500;
    font-size: 10px;
    color: #7987a1
}

.card-dashboard-twelve .flot-chart .flot-x-axis>div span:last-child {
    display: block;
    font-size: 16px;
    font-weight: 700;
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    line-height: 1
}

@media (min-width:768px) {
    .card-dashboard-twelve .flot-chart {
        height: 250px
    }
}

@media (min-width:992px) {
    .card-dashboard-twelve .flot-chart {
        height: 300px
    }
}

table.dataTable {
    border: 1px solid #e3e8f7
}

table.dataTable thead td,
table.dataTable thead th {
    border-top-width: 0;
    border-bottom-width: 0;
    padding: 8px 10px;
    font-weight: 700;
    font-size: 12px;
    color: #242f48
}

table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
    background-image: none;
    background-color: #ecf0fa;
    position: relative
}

table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc::after {
    font-family: Ionicons;
    font-size: 11px;
    position: absolute;
    line-height: 0;
    top: 50%;
    right: 10px
}

table.dataTable thead .sorting_asc::after {
    content: '\f3d8'
}

table.dataTable thead .sorting_desc::after {
    content: '\f3d0'
}

table.dataTable thead .sorting {
    background-image: none;
    position: relative
}

table.dataTable thead .sorting::after,
table.dataTable thead .sorting::before {
    font-family: Ionicons;
    font-size: 11px;
    position: absolute;
    line-height: 0;
    right: 10px
}

table.dataTable thead .sorting::before {
    content: '\f3d8';
    top: 40%
}

table.dataTable thead .sorting::after {
    content: '\f3d0';
    top: 60%
}

table.dataTable tbody td.sorting_1 {
    background-color: #ecf0fa
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role=row]>td:first-child::before {
    top: 9.5px;
    left: 7px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0;
    box-shadow: none;
    background-color: #d0d7e8;
    font-size: 14px;
    font-weight: 700;
    color: #949eb7
}

table.dataTable>tbody>tr.child ul.dtr-details {
    display: block
}

table.dataTable>tbody>tr.child span.dtr-title {
    font-weight: 500
}

.dataTables_wrapper .dataTables_length {
    text-align: left
}

.dataTables_wrapper .dataTables_length label {
    display: block;
    margin-bottom: 15px
}

.dataTables_wrapper .dataTables_length .select2-container--default {
    margin-right: 5px
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single {
    height: 32px;
    border-color: #d0d7e8;
    border-radius: 3px
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 32px
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 30px
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-top-color: #949eb7
}

.dataTables_wrapper .dataTables_filter {
    text-align: left
}

.dataTables_wrapper .dataTables_filter label {
    display: block;
    margin-bottom: 15px
}

.dataTables_wrapper .dataTables_filter input {
    margin-left: 0;
    border: 1px solid #e3e8f7;
    padding: 5px 10px;
    line-height: 1.539;
    color: #031b4e;
    border-radius: 3px;
    width: 100%
}

.dataTables_wrapper .dataTables_filter input::placeholder {
    color: #949eb7
}

.dataTables_wrapper .dataTables_info {
    margin-top: 19px!important;
    padding: 0!important;
    text-align: left
}

.dataTables_wrapper .dataTables_paginate {
    margin-top: 15px!important;
    padding-top: 0;
    margin-top: 15px;
    text-align: left
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 1px;
    transition: all .2s ease-in-out
}

.dataTables_wrapper .dataTables_paginate .paginate_button+.paginate_button {
    margin-left: 3px
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
    background-color: transparent;
    color: #d0d7e8!important
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:focus,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
    background-color: #eceff3;
    color: #d0d7e8!important
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous {
    margin-right: 3px
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next {
    margin-left: 3px
}

.dataTables_wrapper .dataTables_paginate .paginate_button:focus,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    border: 0;
    background-image: none;
    background-color: transparent;
    color: #031b4e!important
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
    border: 0;
    background-image: none;
    background-color: #078586;
    color: #fff!important
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:focus,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    border: 0;
    background-image: none;
    background-color: #078586;
    color: #fff!important
}

@media (min-width:576px) {
    .dataTables_wrapper .dataTables_length {
        float: left
    }
}

@media (min-width:576px) {
    .dataTables_wrapper .dataTables_filter {
        float: right;
        margin-top: 0
    }
}

@media (min-width:576px) {
    .dataTables_wrapper .dataTables_filter input {
        width: auto
    }
}

@media (min-width:576px) {
    .dataTables_wrapper .dataTables_info {
        float: left
    }
}

@media (min-width:576px) {
    .dataTables_wrapper .dataTables_paginate {
        float: right
    }
}

@media (prefers-reduced-motion:reduce) {
    .dataTables_wrapper .dataTables_paginate .paginate_button {
        transition: none
    }
}

@media (max-width:575px) {
    div.dataTables_wrapper div.dataTables_filter input {
        margin-left: -12px
    }
}

.table.dataTable thead .sorting:before {
    display: none
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:before {
    display: none
}

.dataTables_paginate .pagination .page-link {
    padding: .5rem .75rem;
    background-color: #ecf0fa;
    border: 1px solid #cdd7ef
}

.dataTables_paginate .page-item.active .page-link {
    background-color: #078586
}

.dataTables_wrapper .dataTables_paginate .paginate_button.focus {
    background: #078586
}

.dataTables_wrapper .dataTables_paginate .page-item.disabled .page-link {
    background-color: #e8ecf5;
    width: 80px;
    color: #b1c1d6
}

.page-item.next a {
    width: 64px
}

table.dataTable tfoot td,
table.dataTable tfoot th {
    padding: 10px 18px 6px 18px;
    border-top: 1px solid #dde2ef
}

.table-responsive.hoverable-table {
    position: relative
}

.hoverable-table .btn-primary {
    position: absolute;
    margin-left: 98px;
    top: 0;
    padding: 7px 16px;
    z-index: 99
}

.ui-datepicker {
    background-color: #fff!important;
    border: 1px solid rgba(28, 39, 60, .2)!important;
    font-family: inherit!important;
    font-size: inherit!important;
    padding: 10px!important;
    margin: 1px 0 0!important;
    display: none!important;
    width: auto!important;
    z-index: 5!important
}

.ui-datepicker .ui-datepicker-header {
    display: flex!important;
    justify-content: space-between!important;
    align-items: center!important;
    position: relative!important;
    font-weight: 500!important;
    font-size: 12px!important;
    text-transform: uppercase!important;
    color: #031b4e!important;
    padding: 0 0 5px!important;
    letter-spacing: 1px!important;
    border: 0!important;
    background-color: transparent!important;
    border-top-left-radius: 3px!important;
    border-top-right-radius: 3px!important
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
    text-indent: -99999px!important;
    color: #737f9e!important;
    top: 3px!important
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
    font-size: 16px!important;
    font-family: Ionicons!important;
    position: absolute!important;
    top: -2px!important;
    text-indent: 0!important
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next:focus::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-next:hover::before {
    color: #364261!important
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:focus::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:hover::before {
    color: #364261!important
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next {
    order: 3!important
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
    right: 5px!important;
    content: '\f3d1'!important
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
    left: 5px!important;
    content: '\f3cf'!important
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next-hover,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev-hover {
    color: #4d5875!important;
    cursor: pointer!important;
    top: 1px!important;
    border: 0!important;
    background-color: transparent!important
}

.ui-datepicker .ui-datepicker-title {
    color: #364261!important;
    font-weight: 700!important;
    font-size: 13px!important
}

.ui-datepicker .ui-datepicker-calendar {
    margin: 0!important;
    background-color: transparent!important;
    border-bottom-right-radius: 3px!important;
    border-bottom-left-radius: 3px!important
}

.ui-datepicker .ui-datepicker-calendar th {
    text-transform: uppercase!important;
    font-size: 12px!important;
    font-weight: 700!important;
    letter-spacing: 1px!important;
    padding: 6px 9px!important;
    color: #364261!important
}

.datetimepicker {
    box-shadow: none!important;
    border-radius: 0!important;
    border-width: 1px!important;
    border-color: #d0d7e8!important;
    min-width: 270px!important;
    padding: 0 10px 10px!important
}

.datetimepicker::before {
    display: none!important
}

.datetimepicker table {
    width: 100%!important
}

.datetimepicker table th.next,
.datetimepicker table th.prev,
.datetimepicker table th.switch {
    background-color: #fff!important;
    color: #031b4e!important
}

.datetimepicker table th.next,
.datetimepicker table th.prev {
    position: relative!important
}

.datetimepicker table th.next span,
.datetimepicker table th.prev span {
    position: absolute!important;
    top: 0!important;
    right: 0!important;
    bottom: 0!important;
    left: 0!important
}

.datetimepicker table th.next span::before,
.datetimepicker table th.prev span::before {
    font-family: Ionicons!important;
    position: absolute!important;
    top: 50%!important;
    font-size: 16px!important;
    color: #242f48!important;
    line-height: 0!important
}

.datetimepicker table th.prev:focus,
.datetimepicker table th.prev:hover {
    background-color: #fff!important
}

.datetimepicker table th.next:focus,
.datetimepicker table th.next:hover {
    background-color: #fff!important
}

.datetimepicker table th.prev:focus span::before,
.datetimepicker table th.prev:hover span::before {
    color: #078586!important
}

.datetimepicker table th.next:focus span::before,
.datetimepicker table th.next:hover span::before {
    color: #078586!important
}

.datetimepicker table th.prev span::before {
    content: '\f3cf'!important;
    left: 0!important
}

.datetimepicker table th.next span::before {
    content: '\f3d1'!important;
    right: 0!important
}

.datetimepicker table th.switch {
    text-transform: uppercase!important;
    font-weight: 700!important;
    font-size: 13px!important;
    letter-spacing: 1px!important;
    color: #242f48!important
}

.datetimepicker table th.switch:focus,
.datetimepicker table th.switch:hover {
    background-color: #fff!important;
    color: #078586!important
}

.datetimepicker table td {
    font-size: .875rem!important;
    text-align: center!important
}

.datetimepicker table td.new,
.datetimepicker table td.old {
    color: #949eb7!important
}

.datetimepicker table td.active {
    background-color: #078586!important;
    color: #fff!important
}

.datetimepicker table td.active:focus,
.datetimepicker table td.active:hover {
    background-color: #078586!important;
    color: #fff!important
}

.datetimepicker table span.active {
    background-color: #078586!important;
    color: #fff!important
}

.datetimepicker table span.active:focus,
.datetimepicker table span.active:hover {
    background-color: #078586!important;
    color: #fff!important
}

.datetimepicker .datetimepicker-days table thead tr:first-child th {
    height: 38px!important
}

.datetimepicker .datetimepicker-days table thead tr:last-child th {
    height: 25px!important;
    text-transform: uppercase!important;
    color: #4d5875!important;
    font-weight: 700!important;
    font-size: 11px!important
}

.main-datetimepicker {
    box-shadow: none!important;
    border-radius: 0!important;
    border-color: #d0d7e8!important;
    margin-top: 2px!important
}

.main-datetimepicker>.datepicker_header {
    padding: 10px!important;
    display: flex!important;
    align-items: center!important;
    justify-content: space-between!important
}

.main-datetimepicker>.datepicker_header>.icon-home {
    display: none!important
}

.main-datetimepicker>.datepicker_header a:last-child,
.main-datetimepicker>.datepicker_header a:nth-child(2) {
    display: block!important;
    position: relative!important;
    text-indent: -999999px!important
}

.main-datetimepicker>.datepicker_header a:last-child::before,
.main-datetimepicker>.datepicker_header a:nth-child(2)::before {
    font-family: Ionicons!important;
    position: absolute!important;
    top: 50%;
    line-height: 0!important;
    text-indent: 0!important;
    font-size: 13px!important
}

.main-datetimepicker>.datepicker_header a:nth-child(2)::before {
    content: '\f3cf'!important;
    left: 0
}

.main-datetimepicker>.datepicker_header a:last-child::before {
    content: '\f3d1'!important;
    right: 0
}

.main-datetimepicker>.datepicker_header span {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-transform: uppercase!important
}

.main-datetimepicker>.datepicker_inner_container {
    border-radius: 0!important;
    box-shadow: none!important;
    border-width: 0!important;
    display: flex!important;
    height: 225px!important;
    margin: 0!important;
    background-color: #fff!important
}

.main-datetimepicker>.datepicker_inner_container::after {
    display: none!important
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar {
    width: 230px!important;
    border-width: 0!important;
    border-radius: 0!important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    padding: 5px!important;
    float: none!important;
    margin: 0!important
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar>table {
    width: 100%!important;
    height: 100%!important
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar th {
    font-size: 11px!important;
    text-transform: uppercase!important;
    color: #242f48!important;
    font-weight: 700!important;
    text-align: center!important;
    padding: 8px 5px!important
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar td {
    font-size: .875rem!important;
    font-weight: 400!important;
    text-align: center!important;
    padding: 5px!important
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar td.day_another_month {
    color: #949eb7!important
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar td.hover {
    background-color: #dde2ef!important;
    cursor: pointer!important
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar td.active {
    background-color: #078586!important;
    color: #fff!important;
    border-radius: 2px!important
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_timelist {
    float: none!important;
    width: 70px!important;
    height: 100%!important;
    border-left: 1px solid #b9c2d8!important
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item {
    padding: 5px 8px!important;
    margin: 0 0 0 1px!important
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item:focus,
.main-datetimepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item:hover {
    background-color: #dde2ef!important
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item.active {
    background-color: #078586!important;
    color: #fff!important
}

.picker-title {
    font-size: 15px!important;
    font-weight: 500!important
}

.picker-cell__header {
    text-transform: uppercase!important;
    font-size: .875rem!important;
    font-weight: 700!important;
    letter-spacing: .4px!important
}

.picker-item {
    font-family: Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.picker-picked {
    font-weight: 500!important;
    color: #078586!important
}

.wizard {
    border: 1px solid #dde2ef;
    background-color: #fff
}

.wizard>.steps {
    padding: 20px
}

.wizard>.steps>ul {
    padding: 0;
    margin-bottom: 0;
    display: flex
}

.wizard>.steps>ul li {
    float: none;
    display: block;
    width: auto
}

.wizard>.steps>ul li .current-info {
    display: none
}

.wizard>.steps>ul li .title {
    margin-left: 5px;
    white-space: nowrap;
    transition: all .2s ease-in-out
}

.wizard>.steps>ul li+li {
    margin-left: 5px
}

.wizard>.steps a {
    color: #242f48;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center
}

.wizard>.steps a:active,
.wizard>.steps a:hover {
    color: #242f48;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center
}

.wizard>.steps a .number,
.wizard>.steps a:active .number,
.wizard>.steps a:hover .number {
    flex-shrink: 0;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #fff;
    display: block;
    text-align: center;
    line-height: 2;
    width: 30px;
    height: 30px;
    background-color: #b9c2d8;
    border-radius: 100%;
    transition: all .2s ease-in-out
}

.wizard>.steps .disabled {
    display: none
}

.wizard>.steps .disabled a {
    color: #949eb7
}

.wizard>.steps .disabled a:active,
.wizard>.steps .disabled a:hover {
    color: #949eb7
}

.wizard>.steps .current a {
    color: #078586
}

.wizard>.steps .current a:active,
.wizard>.steps .current a:hover {
    color: #078586
}

.wizard>.steps .current a .title,
.wizard>.steps .current a:active .title,
.wizard>.steps .current a:hover .title {
    display: inline-block
}

.wizard>.steps .current a .number,
.wizard>.steps .current a:active .number,
.wizard>.steps .current a:hover .number {
    background-color: #078586
}

.wizard>.steps .done a {
    color: #0cc
}

.wizard>.steps .done a:active,
.wizard>.steps .done a:hover {
    color: #0cc
}

.wizard>.steps .done a .title,
.wizard>.steps .done a:active .title,
.wizard>.steps .done a:hover .title {
    display: none
}

.wizard>.steps .done a .number,
.wizard>.steps .done a:active .number,
.wizard>.steps .done a:hover .number {
    background-color: #0cc
}

.wizard>.content {
    border-top: 1px solid #dde2ef;
    border-bottom: 1px solid #dde2ef;
    min-height: 150px;
    padding: 20px
}

.wizard>.content>.title {
    font-size: 18px;
    color: #242f48;
    font-weight: 700;
    margin-bottom: 5px;
    display: none
}

.wizard>.content>.title.current {
    display: block
}

.wizard>.content>.body {
    float: none;
    position: static;
    width: auto;
    height: auto
}

.wizard>.content>.body input.parsley-error {
    border-color: #ee335e
}

.wizard>.content>.body input.parsley-error+ul {
    list-style: none!important
}

.wizard>.actions {
    padding: 20px
}

.wizard>.actions>ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between
}

.wizard>.actions>ul>li:last-child a {
    background-color: #0cc
}

.wizard>.actions a {
    display: block;
    background-color: #078586;
    padding: 9px 25px;
    line-height: 1.573;
    color: #fff;
    border-radius: 3px
}

.wizard>.actions a:active,
.wizard>.actions a:hover {
    display: block;
    background-color: #078586;
    padding: 9px 25px;
    line-height: 1.573;
    color: #fff
}

.wizard>.actions .disabled a {
    background-color: #949eb7;
    border-radius: 3px
}

.wizard>.actions .disabled a:active,
.wizard>.actions .disabled a:hover {
    background-color: #949eb7
}

.wizard.vertical>.steps {
    padding: 20px
}

.wizard.vertical>.steps ul li+li {
    margin-left: 10px
}

.wizard.vertical>.steps ul li .title {
    display: none
}

.wizard.vertical>.steps .current a .title {
    display: inline-block
}

.wizard.vertical>.content {
    margin: 0;
    padding: 20px
}

.wizard.vertical>.actions {
    padding: 20px
}

.wizard.vertical>.actions ul {
    float: none;
    margin: 0;
    padding: 0
}

@media (min-width:768px) {
    .wizard>.steps {
        padding: 25px
    }
}

@media (min-width:992px) {
    .wizard>.steps {
        padding: 30px
    }
}

@media (prefers-reduced-motion:reduce) {
    .wizard>.steps>ul li .title {
        transition: none
    }
}

@media (min-width:576px) {
    .wizard>.steps>ul li .title {
        display: none;
        margin-left: 10px
    }
}

@media (min-width:768px) {
    .wizard>.steps>ul li .title {
        display: none
    }
}

@media (min-width:576px) {
    .wizard>.steps>ul li+li {
        margin-left: 20px
    }
}

@media (min-width:992px) {
    .wizard>.steps>ul li+li {
        margin-left: 30px
    }
}

@media (min-width:1200px) {
    .wizard>.steps a {
        justify-content: flex-start
    }
    .wizard>.steps a:active,
    .wizard>.steps a:hover {
        justify-content: flex-start
    }
}

@media (prefers-reduced-motion:reduce) {
    .wizard>.steps a .number,
    .wizard>.steps a:active .number,
    .wizard>.steps a:hover .number {
        transition: none
    }
}

@media (min-width:576px) {
    .wizard>.steps a .number,
    .wizard>.steps a:active .number,
    .wizard>.steps a:hover .number {
        font-size: 18px;
        font-weight: 600;
        line-height: 2.1;
        width: 40px;
        height: 40px
    }
}

@media (min-width:576px) {
    .wizard>.steps .disabled {
        display: block
    }
}

@media (min-width:768px) {
    .wizard>.steps .done a .title,
    .wizard>.steps .done a:active .title,
    .wizard>.steps .done a:hover .title {
        display: inline-block
    }
}

@media (min-width:768px) {
    .wizard>.content {
        padding: 25px
    }
}

@media (min-width:992px) {
    .wizard>.content {
        padding: 30px
    }
}

@media (min-width:768px) {
    .wizard>.actions {
        padding: 25px
    }
}

@media (min-width:992px) {
    .wizard>.actions {
        padding: 30px
    }
}

@media (min-width:576px) {
    .wizard.vertical>.steps {
        float: left;
        width: 20%
    }
}

@media (min-width:768px) {
    .wizard.vertical>.steps {
        width: 15%
    }
}

@media (min-width:992px) {
    .wizard.vertical>.steps {
        padding: 25px;
        width: 30%
    }
}

@media (min-width:576px) {
    .wizard.vertical>.steps ul {
        flex-direction: column
    }
}

@media (min-width:576px) {
    .wizard.vertical>.steps ul li+li {
        margin-top: 10px;
        margin-left: 0
    }
}

@media (min-width:768px) {
    .wizard.vertical>.steps ul li+li {
        margin-top: 20px
    }
}

@media (min-width:992px) {
    .wizard.vertical>.steps ul li .title {
        display: block
    }
}

@media (min-width:992px) {
    .wizard.vertical>.steps a {
        justify-content: flex-start
    }
}

@media (min-width:576px) {
    .wizard.vertical>.steps .current a .title {
        display: none
    }
}

@media (min-width:992px) {
    .wizard.vertical>.steps .current a .title {
        display: inline-block
    }
}

@media (min-width:576px) {
    .wizard.vertical>.content {
        border-top-width: 0;
        border-bottom-width: 0;
        width: 80%;
        float: right;
        border-left: 1px solid #e3e8f7
    }
}

@media (min-width:768px) {
    .wizard.vertical>.content {
        width: 85%
    }
}

@media (min-width:992px) {
    .wizard.vertical>.content {
        width: 70%;
        padding: 25px
    }
}

@media (min-width:576px) {
    .wizard.vertical>.actions {
        width: 80%;
        float: right;
        border-left: 1px solid #e3e8f7
    }
}

@media (min-width:768px) {
    .wizard.vertical>.actions {
        width: 85%
    }
}

@media (min-width:992px) {
    .wizard.vertical>.actions {
        width: 70%;
        padding: 25px
    }
}

.step-equal-width>.steps>ul {
    display: flex;
    display: flex
}

.step-equal-width>.steps>ul>li {
    flex: 1;
    width: auto;
    float: none;
    flex: 1;
    width: auto;
    float: none
}

.wizard-style-1>.steps>ul a {
    padding: 0;
    height: 50px
}

.wizard-style-1>.steps>ul a:active,
.wizard-style-1>.steps>ul a:hover {
    padding: 0;
    height: 50px
}

.wizard-style-1>.steps>ul a .number,
.wizard-style-1>.steps>ul a:active .number,
.wizard-style-1>.steps>ul a:hover .number {
    width: 50px;
    height: 100%;
    border: 0;
    font-size: 18px;
    font-weight: 700;
    color: #737f9e;
    background-color: #d0d7e8;
    border-radius: 0
}

.wizard-style-1>.steps>ul a .title,
.wizard-style-1>.steps>ul a:active .title,
.wizard-style-1>.steps>ul a:hover .title {
    margin-right: 20px;
    margin-left: 20px
}

.wizard-style-1>.steps>ul .current a .number,
.wizard-style-1>.steps>ul .current a:active .number,
.wizard-style-1>.steps>ul .current a:hover .number {
    background-color: #452efa;
    color: #fff
}

.wizard-style-1>.steps>ul .done a .number,
.wizard-style-1>.steps>ul .done a:active .number,
.wizard-style-1>.steps>ul .done a:hover .number {
    background-color: #643ab0;
    color: #fff
}

.wizard-style-2>.steps>ul a {
    padding: 0;
    height: 50px;
    border-radius: 50px
}

.wizard-style-2>.steps>ul a:active,
.wizard-style-2>.steps>ul a:hover {
    padding: 0;
    height: 50px;
    border-radius: 50px
}

.wizard-style-2>.steps>ul a .number,
.wizard-style-2>.steps>ul a:active .number,
.wizard-style-2>.steps>ul a:hover .number {
    width: 50px;
    height: 100%;
    border: 2px solid #dde2ef;
    font-size: 18px;
    font-weight: 700;
    color: #737f9e;
    background-color: #fff
}

.wizard-style-2>.steps>ul a .title,
.wizard-style-2>.steps>ul a:active .title,
.wizard-style-2>.steps>ul a:hover .title {
    margin-right: 20px
}

.wizard-style-2>.steps>ul .current a .number,
.wizard-style-2>.steps>ul .current a:active .number,
.wizard-style-2>.steps>ul .current a:hover .number {
    border-color: #078586;
    color: #078586
}

.wizard-style-2>.steps>ul .done a .number,
.wizard-style-2>.steps>ul .done a:active .number,
.wizard-style-2>.steps>ul .done a:hover .number {
    border-color: #673ab7;
    color: #673ab7
}

.jqvmap-label {
    padding: 2px 8px;
    background-color: rgba(17, 17, 17, .9);
    border-radius: 2px
}

.jqvmap-zoomin {
    font-size: 20px;
    padding: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px
}

.jqvmap-zoomout {
    font-size: 20px;
    padding: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    top: 32px
}

.jqvmap-pin {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    cursor: default;
    pointer-events: none
}

#jqvmap1_dc_pin,
#jqvmap1_de_pin,
#jqvmap1_md_pin,
#jqvmap1_ri_pin {
    display: none
}

#jqvmap1_ak_pin {
    margin-top: -2%
}

#jqvmap1_ca_pin {
    margin-left: -2%
}

#jqvmap1_ct_pin {
    margin-top: -.25%;
    margin-left: -.25%
}

#jqvmap1_fl_pin {
    margin-left: 5%
}

#jqvmap1_id_pin {
    margin-top: 3%;
    margin-left: -1%
}

#jqvmap1_ky_pin {
    margin-left: 2%
}

#jqvmap1_la_pin {
    margin-left: -2%
}

#jqvmap1_mi_pin {
    margin-top: 4%;
    margin-left: 3%
}

#jqvmap1_ma_pin {
    margin-top: -.25%
}

#jqvmap1_mn_pin {
    margin-top: 2%;
    margin-left: -2%
}

#jqvmap1_nh_pin {
    margin-top: 1%;
    margin-left: -.25%
}

#jqvmap1_nj_pin {
    margin-top: 1%
}

#jqvmap1_ok_pin,
#jqvmap1_va_pin {
    margin-left: 2%
}

#jqvmap1_wv_pin {
    margin-left: -1%;
    margin-top: 1%
}

@media only screen and (min-width:320px) {
    .jqvmap-pin {
        font-size: 6px
    }
}

@media only screen and (min-width:480px) {
    .jqvmap-pin {
        font-size: 8px
    }
}

@media only screen and (min-width:640px) {
    .jqvmap-pin {
        font-size: 10px
    }
}

@media only screen and (min-width:800px) {
    .jqvmap-pin {
        font-size: 12px
    }
}

@media only screen and (min-width:1024px) {
    .jqvmap-pin {
        font-size: 14px
    }
}

.jqstooltip {
    width: 20px
}

.ql-wrapper {
    display: flex;
    flex-direction: column
}

.ql-wrapper-modal .ql-snow.ql-toolbar {
    border-width: 0;
    border-bottom-width: 1px
}

.ql-wrapper-modal .ql-snow.ql-container {
    border-width: 0
}

.ql-wrapper-modal .ql-snow .ql-editor {
    padding: 20px
}

@media (min-width:576px) {
    .ql-wrapper-modal .ql-snow.ql-toolbar {
        padding: 15px 20px
    }
}

.ql-bubble.ql-container {
    border: 1px solid #dee4f3
}

.ql-bubble.ql-container:not(.ql-disabled) a::before {
    background-color: #242f48;
    border-radius: 2px
}

.ql-bubble .ql-tooltip {
    background-color: #242f48;
    border-radius: 2px
}

.ql-bubble .ql-tooltip.ql-flip .ql-tooltip-arrow {
    border-top-color: #242f48
}

.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
    border-bottom-color: #242f48
}

.ql-bubble .ql-toolbar {
    padding: 0 8px 8px
}

.ql-bubble .ql-toolbar button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8896af;
    outline: 0
}

.ql-bubble .ql-toolbar button i {
    font-size: 24px;
    line-height: 0;
    display: inline-block
}

.ql-bubble .ql-toolbar button i.la-bold,
.ql-bubble .ql-toolbar button i.la-underline {
    width: 21px
}

.ql-bubble .ql-toolbar button .ql-fill {
    fill: #8896af
}

.ql-bubble .ql-toolbar button .ql-stroke {
    stroke: #8896af
}

.ql-bubble .ql-toolbar button.ql-active {
    color: #fff;
    background-color: rgba(255, 255, 255, .08)
}

.ql-bubble .ql-toolbar .ql-formats {
    margin: 8px 8px 0 0
}

.ql-bubble .ql-toolbar .ql-formats:first-child {
    margin-left: 0
}

@media (min-width:576px) {
    .ql-bubble .ql-toolbar .ql-formats {
        margin: 8px 0 0
    }
    .ql-bubble .ql-toolbar .ql-formats:first-child {
        margin-left: 0
    }
}

.ql-snow.ql-container {
    border-color: #dee4f3;
    border-width: 1px
}

.ql-snow.ql-toolbar {
    border-color: #dee4f3;
    border-width: 1px;
    border-bottom-width: 1px;
    padding: 5px 10px 10px
}

.ql-snow.ql-toolbar .ql-picker-label {
    border-color: #b9c2d8
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active {
    background-color: #dde2ef;
    border-color: transparent;
    color: #242f48;
    font-weight: 500
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke {
    stroke: #031b4e
}

.ql-snow.ql-toolbar button {
    border: 1px solid transparent;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #031b4e;
    outline: 0
}

.ql-snow.ql-toolbar button svg {
    width: 20px;
    float: none
}

.ql-snow.ql-toolbar button i {
    font-size: 24px;
    line-height: 0;
    display: inline-block
}

.ql-snow.ql-toolbar button i.la-bold,
.ql-snow.ql-toolbar button i.la-underline {
    width: 21px
}

.ql-snow.ql-toolbar button .ql-stroke {
    stroke-width: 2px
}

.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button:hover {
    border-color: #b9c2d8;
    color: #063598
}

.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke {
    stroke: #737f9e
}

.ql-snow.ql-toolbar button.ql-active {
    background-color: #dde2ef;
    color: #364261;
    border-color: transparent
}

.ql-snow.ql-toolbar button.ql-active .ql-fill {
    fill: #364261
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke {
    stroke: #364261
}

.ql-snow.ql-toolbar .ql-formats {
    margin-top: 5px;
    margin-right: 5px
}

.ql-snow .ql-formats button+button {
    margin: 0 0 0 2px
}

.ql-snow .ql-picker {
    display: block;
    border-width: 0;
    height: 32px;
    color: #4d5875
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: 3px
}

.ql-snow .ql-picker:focus .ql-picker-label,
.ql-snow .ql-picker:hover .ql-picker-label {
    color: #242f48
}

.ql-snow .ql-picker:focus .ql-picker-label .ql-stroke,
.ql-snow .ql-picker:hover .ql-picker-label .ql-stroke {
    stroke: #031b4e
}

.ql-snow .ql-picker.ql-header {
    width: 120px
}

.ql-snow .ql-picker-label {
    display: block;
    padding-left: 10px;
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    border: 1px solid #e3e8f7;
    background-color: #fff;
    outline: 0
}

.ql-snow .ql-picker-label::before {
    line-height: 2.25;
    font-weight: 400
}

.ql-snow .ql-stroke {
    stroke: #031b4e;
    stroke-linecap: square;
    stroke-linejoin: miter;
    stroke-width: 1
}

.ql-snow .ql-editor {
    padding: 20px;
    color: #364261
}

@media (min-width:576px) {
    .ql-snow.ql-toolbar {
        padding: 15px
    }
}

.ql-wrapper-demo {
    background-color: #fff
}

.ql-wrapper-demo .ql-container {
    height: 250px
}

.ql-wrapper-demo .ql-editor {
    height: 100%;
    overflow-y: auto
}

@media (min-width:1200px) {
    .ql-wrapper-demo {
        width: 100%
    }
}

.ql-scrolling-demo {
    background-color: #fff;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    border: 1px solid #dde2ef;
    position: relative
}

.ql-scrolling-demo .ql-container {
    border-width: 0;
    height: auto;
    min-height: 100%;
    padding: 30px
}

.ql-scrolling-demo .ql-container .ql-editor {
    font-size: 16px;
    overflow-y: visible;
    color: #364261
}

@media (min-width:768px) {
    .ql-scrolling-demo {
        padding-left: 0
    }
}

@media (min-width:768px) {
    .ql-scrolling-demo .ql-container {
        padding: 1rem
    }
}

input.parsley-error:focus,
input.parsley-success:focus,
textarea.parsley-error:focus,
textarea.parsley-success:focus {
    box-shadow: none!important
}

.parsley-checkbox.parsley-error {
    display: inline-block;
    padding: 10px;
    border: 1px solid #ee335e
}

.parsley-checkbox.parsley-success {
    display: inline-block;
    padding: 10px;
    border: 1px solid #21b18a
}

.parsley-errors-list {
    color: #ee335e;
    list-style: none;
    font-size: 12px;
    padding: 0;
    margin-bottom: 0
}

.parsley-errors-list li {
    margin: 5px 0 0
}

.parsley-style-1 .parsley-input.parsley-error .form-control {
    background-color: #fef9f9;
    border-color: #ee335e;
    border-bottom-width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.parsley-style-1 .parsley-input.parsley-success .form-control {
    border-color: #21b18a
}

.parsley-style-1 .parsley-checkbox.parsley-error {
    border: 1px solid #ee335e;
    background-color: #fef9f9;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px
}

.parsley-style-1 .parsley-select.parsley-error .select2-container--default .select2-selection--single {
    background-color: #fef9f9;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.parsley-style-1 .parsley-errors-list.filled {
    background-color: #ee335e;
    color: #fff;
    padding: 7px 10px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px
}

.parsley-style-1 .parsley-errors-list.filled li:first-child {
    margin-top: 0
}

.irs-max,
.irs-min {
    font-family: Roboto, sans-serif;
    border-radius: 0
}

.irs-from,
.irs-single,
.irs-to {
    font-family: Roboto, sans-serif;
    background-color: #078586;
    border-radius: 0
}

.irs-bar {
    background-image: none;
    background-color: #078586
}

.irs-bar-edge {
    background-image: none;
    background-color: #078586;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.irs-line-left {
    background-image: none;
    background-color: #d0d7e8;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.irs-line-mid {
    background-image: none;
    background-color: #d0d7e8
}

.irs-line-right {
    background-image: none;
    background-color: #d0d7e8;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.irs-from::after,
.irs-single::after,
.irs-to::after {
    border-top-color: #078586
}

.irs-slider {
    background-image: none
}

.irs-slider:before {
    content: '';
    width: 2px;
    height: 18px;
    position: absolute;
    background-color: #078586;
    left: 50%;
    margin-left: -1px
}

.irs-slider:focus:before,
.irs-slider:hover:before {
    background-color: #260bfa
}

.irs-primary .irs-bar,
.irs-primary .irs-bar-edge,
.irs-primary .irs-from,
.irs-primary .irs-single,
.irs-primary .irs-slider::before,
.irs-primary .irs-to {
    background-color: #078586
}

.irs-primary .irs-from::after,
.irs-primary .irs-single::after,
.irs-primary .irs-to::after {
    border-top-color: #078586
}

.irs-success .irs-bar,
.irs-success .irs-bar-edge,
.irs-success .irs-from,
.irs-success .irs-single,
.irs-success .irs-slider::before,
.irs-success .irs-to {
    background-color: #21b18a
}

.irs-success .irs-from::after,
.irs-success .irs-single::after,
.irs-success .irs-to::after {
    border-top-color: #21b18a
}

.irs-dark .irs-bar,
.irs-dark .irs-bar-edge,
.irs-dark .irs-from,
.irs-dark .irs-single,
.irs-dark .irs-slider::before,
.irs-dark .irs-to {
    background-color: #364261
}

.irs-dark .irs-from::after,
.irs-dark .irs-single::after,
.irs-dark .irs-to::after {
    border-top-color: #364261
}

.irs-modern .irs-line-left,
.irs-modern .irs-line-mid,
.irs-modern .irs-line-right {
    height: 6px;
    box-shadow: inset -2px 2px 2px 0 rgba(34, 34, 34, .1)
}

.irs-modern .irs-bar,
.irs-modern .irs-bar-edge {
    height: 6px;
    box-shadow: inset 0 1px 1px 0 rgba(34, 34, 34, .1)
}

.irs-modern .irs-slider {
    top: 19px;
    background-color: #fff;
    border: 1px solid #078586;
    border-radius: 0
}

.irs-modern .irs-slider::before {
    width: 4px;
    height: 9px;
    background-color: #fff;
    border-left: 1px solid #078586;
    border-right: 1px solid #078586;
    margin-left: -2px;
    top: 3px
}

.irs-modern.irs-primary .irs-slider {
    border-color: #078586
}

.irs-modern.irs-primary .irs-slider::before {
    border-color: #078586
}

.irs-modern.irs-success .irs-slider {
    border-color: #21b18a
}

.irs-modern.irs-success .irs-slider::before {
    border-color: #21b18a
}

.irs-modern.irs-dark .irs-slider {
    border-color: #242f48
}

.irs-modern.irs-dark .irs-slider::before {
    border-color: #242f48
}

.irs-outline .irs-line {
    border: 1px solid #078586;
    height: 10px;
    border-radius: 5px
}

.irs-outline .irs-line-left,
.irs-outline .irs-line-mid,
.irs-outline .irs-line-right {
    height: 10px;
    background-color: transparent
}

.irs-outline .irs-bar {
    height: 10px
}

.irs-outline .irs-bar-edge {
    height: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px
}

.irs-outline .irs-slider {
    top: 21px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #078586;
    border-radius: 100%
}

.irs-outline .irs-slider::before {
    margin-left: -4px;
    top: 4px;
    width: 8px;
    height: 8px;
    border-radius: 100%
}

.irs-outline .irs-slider:focus::before,
.irs-outline .irs-slider:hover::before {
    background-color: #452efa
}

.irs-outline.irs-primary .irs-line,
.irs-outline.irs-primary .irs-slider {
    border-color: #078586
}

.irs-outline.irs-primary .irs-line:focus::before,
.irs-outline.irs-primary .irs-line:hover::before {
    background-color: #1a53ff
}

.irs-outline.irs-primary .irs-slider:focus::before,
.irs-outline.irs-primary .irs-slider:hover::before {
    background-color: #1a53ff
}

.irs-outline.irs-success .irs-line,
.irs-outline.irs-success .irs-slider {
    border-color: #21b18a
}

.irs-outline.irs-success .irs-line:focus::before,
.irs-outline.irs-success .irs-line:hover::before {
    background-color: #339701
}

.irs-outline.irs-success .irs-slider:focus::before,
.irs-outline.irs-success .irs-slider:hover::before {
    background-color: #339701
}

.irs-outline.irs-dark .irs-line,
.irs-outline.irs-dark .irs-slider {
    border-color: #364261
}

.irs-outline.irs-dark .irs-line:focus::before,
.irs-outline.irs-dark .irs-line:hover::before {
    background-color: #313c53
}

.irs-outline.irs-dark .irs-slider:focus::before,
.irs-outline.irs-dark .irs-slider:hover::before {
    background-color: #313c53
}

.select2-results__option {
    border-radius: 0;
    margin-bottom: 1px;
    font-size: 13px
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border-color: #e1e5ef;
    border-radius: 3px;
    height: 38px;
    outline: 0;
    border-radius: 3px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #4d5875;
    line-height: calc(1.5em + .75rem + 2px) -.1rem;
    padding-left: .75rem;
    height: 100%;
    display: flex;
    align-items: center
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #737f9e
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    width: 30px;
    height: 38px;
    line-height: calc(1.5em + .75rem + 2px)
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    margin-top: -3px
}

.select2-container--default .select2-selection--multiple {
    background-color: #fff;
    border-color: #d0d7e8;
    border-radius: 0;
    min-height: 38px;
    outline: 0
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 0 4px
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    position: relative;
    margin-top: 5px;
    margin-right: 4px;
    padding: 3px 10px 3px 20px;
    border-color: transparent;
    border-radius: 0;
    background-color: #078586;
    color: #fff;
    line-height: 1.45
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff;
    opacity: .5;
    font-size: 12px;
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 7px
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #d0d7e8
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border-color: #d0d7e8;
    border-radius: 0
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #fff
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #078586
}

.select2-container--default .select2-results>.select2-results__options {
    margin: 4px
}

.select2-container--default .select2-search--inline .select2-search__field {
    margin-top: 7px;
    line-height: 26px;
    padding-left: 7px;
    opacity: 1
}

.select2-container--default.select2-container--disabled .select2-selection__choice {
    padding-left: 10px;
    background-color: #949eb7
}

.select2-container--default.select2-container--disabled .select2-selection__choice .select2-selection__choice__remove {
    display: none
}

.select2-container--open .select2-selection--multiple,
.select2-container--open .select2-selection--single {
    background-color: #fff;
    border-color: #d0d7e8
}

.select2-container--open .select2-dropdown--above {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-container--open .select2-dropdown--below {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    top: 0
}

.select2-dropdown {
    border-color: #d0d7e8;
    z-index: 200
}

.select2-search--dropdown {
    padding-bottom: 0
}

.select2-results__option {
    padding: 6px 10px;
    font-size: .875rem
}

.has-success .select2-container--default .select2-selection--single,
.parsley-select.parsley-success .select2-container--default .select2-selection--single {
    border-color: #21b18a
}

.has-warning .select2-container--default .select2-selection--single {
    border-color: #fbbc0b
}

.has-danger .select2-container--default .select2-selection--single,
.parsley-select.parsley-error .select2-container--default .select2-selection--single {
    border-color: #ee335e
}

.select2-dropdown-xs .select2-results__option,
.select2-xs+.select2-container {
    font-size: 12px
}

.select2-dropdown-sm .select2-results__option,
.select2-sm+.select2-container {
    font-size: 14px
}

.select2-bd-0+.select2-container--default .select2-selection--single {
    border-width: 0
}

.select2-dropdown {
    z-index: 99999
}

.sp-container {
    background-color: #fff;
    border-color: #d0d7e8;
    z-index: 200
}

.sp-container button {
    border: 0;
    padding: 8px 15px;
    background-image: none;
    background-color: #d0d7e8;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 12px;
    text-shadow: none;
    text-transform: capitalize;
    border-radius: 2px
}

.sp-container button:focus,
.sp-container button:hover {
    border: 0;
    background-image: none;
    background-color: #949eb7;
    text-shadow: none
}

.sp-button-container .sp-cancel {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 12px;
    text-transform: capitalize;
    color: #737f9e!important
}

.sp-button-container .sp-cancel:focus,
.sp-button-container .sp-cancel:hover {
    color: #364261!important;
    text-decoration: none
}

.sp-picker-container {
    border-left: 0
}

.sp-replacer {
    border-color: #d0d7e8;
    background-color: #fff
}

.sp-replacer:focus,
.sp-replacer:hover {
    border-color: #b9c2d8
}

.sp-replacer.sp-active {
    border-color: #737f9e
}

.sp-dd {
    text-indent: -99999px;
    position: relative;
    width: 10px
}

.sp-dd::before {
    content: '\f280';
    font-family: Ionicons;
    font-size: 15px;
    color: #949eb7;
    position: absolute;
    text-indent: 0;
    left: 0;
    z-index: 10
}

.sp-preview {
    border: 0
}

.sp-dragger {
    background-color: transparent
}

.sp-choose {
    background-color: #d0d7e8
}

.sp-palette .sp-thumb-el.sp-thumb-active,
.sp-palette .sp-thumb-el:hover {
    border-color: #364261
}

.card--calendar .card-title {
    color: #14112d;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px
}

.card--calendar .card-body {
    padding: 0
}

.card--calendar .ui-datepicker-inline {
    border-width: 0;
    max-width: none;
    padding: 0;
    margin: 0
}

.card--calendar .ui-datepicker .ui-datepicker-header {
    border-bottom: 1px solid #e3e3e3;
    padding: 10px;
    margin-bottom: 5px
}

.card--calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
.card--calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
    top: 8px
}

.card--calendar .ui-datepicker .ui-datepicker-month {
    color: #134cf7
}

.card--calendar .ui-datepicker .ui-datepicker-calendar {
    width: calc(100% - 20px)
}

.card--calendar .ui-datepicker .ui-datepicker-calendar th {
    font-size: 10px;
    text-align: center
}

.card--calendar .ui-datepicker .ui-datepicker-calendar td {
    text-align: center
}

.card--calendar .ui-datepicker .ui-datepicker-calendar td a {
    display: block;
    width: 34px;
    height: 34px;
    font-size: 12px;
    font-family: Archivo, sans-serif;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center
}

.card--calendar .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: transparent;
    border: 1px solid #134cf7;
    color: #fff;
    background: #134cf7;
    font-weight: 700
}

.card--calendar .card--events .list-group-item {
    padding: 13px 20px 13px 40px
}

@media (min-width:576px) {
    .card--calendar .ui-datepicker .ui-datepicker-calendar,
    .card--twelve .flot-chart {
        width: 100%
    }
}

.card--events {
    border-color: #e3e3e3;
    box-shadow: 5px 7px 26px -5px #d5d4e0
}

.card--events .card-header {
    padding: 20px;
    background-color: transparent
}

.card--calendar .card--events {
    margin-left: -20px;
    margin-right: -20px
}

.card--calendar .card--events .list-group-item {
    padding: 13px 20px 13px 40px
}

.card--events .card-title {
    color: #a5a0b1;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px
}

.card--events .card-subtitle {
    color: #14112d;
    font-weight: 400;
    font-size: 18px
}

.card--events .card-body {
    padding: 0
}

.card--events .list-group-item {
    padding: 15px 20px 15px 40px;
    border-left-width: 0;
    border-right-width: 0;
    position: relative
}

.card--events .list-group-item:first-child,
.card--events .list-group-item:last-child {
    border-radius: 0
}

.card--events .list-group-item label {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    color: #37326d;
    margin-bottom: 8px;
    letter-spacing: .5px
}

.card--events .list-group-item label span {
    color: #a5a0b1;
    font-weight: 500
}

.card--events .list-group-item h6 {
    font-size: 14px;
    font-weight: 700;
    color: #14112d;
    margin-bottom: 5px;
    display: flex;
    align-items: center
}

.card--events .list-group-item p {
    margin-bottom: 5px
}

.card--events .list-group-item p strong {
    font-weight: 500
}

.card--events .list-group-item small {
    font-size: 11px
}

.card--events .list-group-item small span {
    font-weight: 700;
    text-transform: uppercase
}

.card--events .event-indicator {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 21px;
    left: 20px;
    border-radius: 100%
}

.card--events .event-user {
    margin-top: 10px;
    display: flex;
    align-items: center
}

.card--events .event-user .main-img-user {
    width: 28px;
    height: 28px
}

.card--events .event-user .main-img-user::after {
    display: none
}

.card--events .event-user .main-img-user+.main-img-user {
    margin-left: -6px
}

.card--events .event-user a:last-child {
    margin-left: 10px;
    color: #a5a0b1;
    font-weight: 500;
    font-size: 11px
}

.card--events .event-user a:last-child:focus,
.card--events .event-user a:last-child:hover {
    color: #37326d
}

@media (max-width:320px) {
    .ui-datepicker .ui-datepicker-calendar th {
        padding: 4px 0;
        letter-spacing: normal
    }
}

.ui-datepicker .ui-datepicker-calendar td {
    border: 1px solid #fff;
    padding: 0;
    background-color: #fff;
    text-align: right
}

.ui-datepicker .ui-datepicker-calendar td:last-child {
    border-right: 0
}

.ui-datepicker .ui-datepicker-calendar td.ui-datepicker-other-month .ui-state-default {
    color: #ccc
}

.ui-datepicker .ui-datepicker-calendar td span {
    transition: all .2s ease-in-out;
    padding: 5px;
    color: #031b4e;
    padding: 6px 10px;
    display: block;
    font-weight: 400;
    font-size: 12px;
    border: 0;
    border-radius: 1px
}

.ui-datepicker .ui-datepicker-calendar td a {
    transition: all .2s ease-in-out;
    padding: 5px;
    color: #031b4e;
    padding: 6px 10px;
    display: block;
    font-weight: 400;
    font-size: 12px;
    border: 0;
    border-radius: 1px
}

.ui-datepicker .ui-datepicker-calendar td a:hover {
    background-color: #fff;
    color: #031b4e
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: #078586;
    color: #fff
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a:focus,
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a:hover {
    background-color: #078586;
    color: #fff
}

@media (prefers-reduced-motion:reduce) {
    .ui-datepicker .ui-datepicker-calendar td a,
    .ui-datepicker .ui-datepicker-calendar td span {
        transition: none
    }
}

.ui-datepicker-multi .ui-datepicker-group {
    padding-right: 15px;
    width: auto;
    float: left
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-title {
    margin: auto
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev::before {
    left: 10px
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next::before {
    right: 10px
}

.ui-datepicker-multi .ui-datepicker-group table {
    margin: 0
}

.ui-datepicker-multi .ui-datepicker-group-last {
    padding-right: 0
}

.ui-datepicker-inline {
    border-color: #e4e8f3
}

@media (min-width:576px) {
    .ui-datepicker-inline {
        max-width: 270px
    }
}

@media (min-width:992px) {
    .main-content-calendar {
        padding: 25px 0
    }
}

.main-content-calendar .container,
.main-content-calendar .container-fluid {
    padding: 20px 0 0
}

@media (min-width:992px) {
    .main-content-calendar .container,
    .main-content-calendar .container-fluid {
        padding-top: 0
    }
}

@media (min-width:576px) {
    .main-content-left-calendar {
        width: 300px
    }
}

@media (min-width:992px) {
    .main-content-left-calendar {
        width: 230px
    }
}

.main-datepicker .ui-datepicker-inline {
    margin-top: 0
}

.main-datepicker .ui-datepicker .ui-datepicker-title {
    color: #078586;
    letter-spacing: -.2px
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar {
    width: 100%
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar th {
    padding: 5px;
    font-size: 10px;
    color: #242f48;
    text-align: center
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar td {
    text-align: center
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar th.ui-datepicker-week-end:first-child:not(.ui-datepicker-other-month) a {
    color: #ee335e
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar td {
    padding: 0 1px
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-week-end:first-child:not(.ui-datepicker-other-month) a {
    color: #ee335e
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar td a,
.main-datepicker .ui-datepicker .ui-datepicker-calendar td span {
    padding: 4px 5px;
    font-family: Verdana, sans-serif;
    font-size: 11px;
    color: #4d5875
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: #078586;
    color: #fff;
    border-radius: 2px
}

.main-nav-calendar-event .nav-link {
    font-size: 13px;
    position: relative
}

.main-nav-calendar-event .nav-link i {
    font-size: 21px;
    position: relative;
    margin-right: 10px;
    top: -.5px
}

.main-nav-calendar-event .nav-link.exclude {
    color: #737f9e
}

.main-nav-calendar-event .nav-link.exclude i {
    color: #737f9e
}

.main-nav-calendar-event .nav-link.exclude::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 1px solid #737f9e
}

@media (min-width:992px) {
    .main-content-body-calendar {
        padding-left: 25px
    }
}

.main-calendar {
    border-color: #e4e8f3
}

.main-calendar .fc-content,
.main-calendar .fc-divider,
.main-calendar .fc-list-heading td,
.main-calendar .fc-list-view,
.main-calendar .fc-popover,
.main-calendar .fc-row,
.main-calendar tbody,
.main-calendar td,
.main-calendar th,
.main-calendar thead {
    border-color: #e4e8f3
}

.main-calendar .fc-header-toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0 20px
}

.main-calendar .fc-header-toolbar h2 {
    margin-bottom: 0;
    color: #242f48;
    font-size: 20px;
    font-weight: 700
}

.main-calendar .fc-header-toolbar .fc-right {
    order: 3
}

.main-calendar .fc-header-toolbar .fc-clear {
    display: none
}

.main-calendar .fc-header-toolbar button {
    height: 38px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e3e8f7;
    box-shadow: none;
    padding: 0 15px;
    display: flex;
    align-items: center;
    color: #242f48;
    font-size: 13px;
    text-transform: capitalize;
    outline: 0;
    text-shadow: none;
    border-radius: 0
}

.main-calendar .fc-header-toolbar button:focus,
.main-calendar .fc-header-toolbar button:hover {
    background-color: #dde2ef
}

.main-calendar .fc-header-toolbar button.fc-next-button,
.main-calendar .fc-header-toolbar button.fc-prev-button {
    padding: 0;
    width: 38px;
    justify-content: center
}

.main-calendar .fc-header-toolbar button.fc-next-button span,
.main-calendar .fc-header-toolbar button.fc-prev-button span {
    margin: 0
}

.main-calendar .fc-header-toolbar button.fc-state-active {
    background-color: #dde2ef
}

.main-calendar .fc-header-toolbar button.fc-today-button {
    color: #fff;
    background-color: #078586;
    border-color: #1a53ff
}

.main-calendar .fc-header-toolbar button.fc-today-button.fc-state-disabled {
    border-color: #e4e8f3;
    background-color: #ecf0fa;
    color: #949eb7;
    cursor: default
}

.main-calendar .fc-icon-left-single-arrow,
.main-calendar .fc-icon-right-single-arrow {
    top: 0
}

.main-calendar .fc-icon-left-single-arrow::after,
.main-calendar .fc-icon-right-single-arrow::after {
    font-family: Ionicons;
    font-size: 18px;
    font-weight: 400;
    line-height: 1
}

.main-calendar .fc-icon-left-single-arrow::after {
    content: '\f3cf'
}

.main-calendar .fc-icon-right-single-arrow::after {
    content: '\f3d1'
}

.main-calendar .fc-head-container .fc-day-header {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 14px 0;
    color: #242f48
}

.main-calendar .fc-view>table {
    background-color: #fff
}

.main-calendar .fc-view>table>thead td,
.main-calendar .fc-view>table>thead th {
    border-color: #e4e8f3
}

.main-calendar .fc-view>table>tbody>tr>td {
    border-color: #e4e8f3
}

.main-calendar .fc-view .fc-other-month {
    background-color: #eff3fd
}

.main-calendar .fc-view .fc-day-number {
    font-size: 14px;
    font-weight: 400;
    font-family: Arial, sans-serif;
    color: #4d5875;
    display: inline-block;
    padding: 5px 8px;
    position: relative;
    transition: all .2s ease-in-out;
    margin: 2px 2px 0 0;
    min-width: 20px;
    text-align: center
}

.main-calendar .fc-view .fc-day-number:focus,
.main-calendar .fc-view .fc-day-number:hover {
    color: #242f48;
    background-color: #ecf0fa
}

.main-calendar .fc-view .fc-scroller {
    height: auto!important;
    overflow: visible!important
}

.main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span {
    display: block
}

.main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:first-child {
    color: #737f9e;
    font-size: 8px;
    font-weight: 400;
    line-height: 1.2
}

.main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:last-child {
    font-weight: 700;
    font-size: 14px;
    font-family: Helvetica, Arial, sans-serif;
    line-height: 1
}

.main-calendar .fc-view.fc-agenda-view .fc-day-header.fc-today>a {
    color: #078586
}

.main-calendar .fc-view.fc-listMonth-view,
.main-calendar .fc-view.fc-listWeek-view {
    border-width: 0
}

.main-calendar .fc-view.fc-listMonth-view .fc-scroller,
.main-calendar .fc-view.fc-listWeek-view .fc-scroller {
    padding: 0 20px 20px
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-table,
.main-calendar .fc-view.fc-listWeek-view .fc-list-table {
    border-collapse: separate;
    display: block
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-table>tbody,
.main-calendar .fc-view.fc-listWeek-view .fc-list-table>tbody {
    display: flex;
    flex-wrap: wrap
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
    flex: 0 0 20%;
    max-width: 20%;
    margin-top: 15px
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading:first-child,
.main-calendar .fc-view.fc-listMonth-view .fc-list-heading:first-child+.fc-list-item,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading:first-child,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading:first-child+.fc-list-item {
    margin-top: 0
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading td,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading td {
    background-color: transparent;
    border-width: 0;
    padding: 0
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main {
    display: block;
    font-size: 11px;
    text-transform: uppercase;
    color: #737f9e;
    font-weight: 600;
    padding: 5px;
    text-align: center
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main span:last-child,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main span:last-child {
    display: block;
    font-size: 30px;
    font-weight: 700;
    font-family: Helvetica, Arial, sans-serif;
    letter-spacing: -1px;
    color: #242f48;
    line-height: 1
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main.now,
.main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main.now span:last-child,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main.now,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main.now span:last-child {
    color: #078586
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(80% - 5px);
    max-width: calc(80% - 5px);
    dispLay: flex;
    flex-direction: column;
    border-left: 4px solid transparent;
    background-color: #fff;
    margin-top: 15px
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item:focus,
.main-calendar .fc-view.fc-listMonth-view .fc-list-item:hover {
    cursor: pointer
}

.main-calendar .fc-view.fc-listWeek-view .fc-list-item:focus,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item:hover {
    cursor: pointer
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item:focus td,
.main-calendar .fc-view.fc-listMonth-view .fc-list-item:hover td {
    background-color: transparent
}

.main-calendar .fc-view.fc-listWeek-view .fc-list-item:focus td,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item:hover td {
    background-color: transparent
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item>td,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item>td {
    border-width: 0
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-time,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-time {
    border-top: 1px solid #dde2ef;
    border-right: 1px solid #dde2ef;
    box-sizing: border-box
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-title,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-title {
    border-bottom: 1px solid #dde2ef;
    border-right: 1px solid #dde2ef;
    box-sizing: border-box
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
    margin-left: 20%
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-marker,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-marker {
    display: none
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-time,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-time {
    padding: 12px 15px 2px;
    font-size: 13px;
    text-transform: capitalize;
    width: 100%
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-title,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-title {
    padding: 0 15px 12px
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-title a,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-title a {
    display: block;
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 5px;
    color: #242f48
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-title .fc-desc,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-title .fc-desc {
    font-size: 13px;
    line-height: 1.375;
    display: block
}

.main-calendar td.fc-today {
    background-color: #078586
}

.main-calendar td.fc-today .fc-day-number {
    background-color: #078586;
    color: #fff;
    border-radius: 1px
}

.main-calendar td.fc-today .fc-day-number:focus,
.main-calendar td.fc-today .fc-day-number:hover {
    background-color: #078586;
    color: #fff;
    border-radius: 1px
}

.main-calendar .fc-axis {
    font-size: 11px
}

.main-calendar .fc-event {
    border-width: 0;
    border-top-width: 5px;
    border-radius: 0
}

.main-calendar .fc-event.fc-day-grid-event>div {
    display: none
}

.main-calendar .fc-event.fc-day-grid-event>div .fc-time {
    font-weight: 500
}

.main-calendar .fc-event.fc-day-grid-event>div .fc-desc {
    display: none
}

.main-calendar .fc-time-grid-event {
    padding: 5px;
    border-left-width: 0;
    border-top-width: 2px
}

.main-calendar .fc-time-grid-event .fc-time {
    font-size: 12px
}

.main-calendar .fc-time-grid-event .fc-title {
    font-size: 14px;
    font-weight: 700;
    color: #242f48;
    margin-bottom: 10px
}

.main-calendar .fc-time-grid-event .fc-desc {
    font-size: 13px;
    line-height: 1.3;
    color: rgba(28, 39, 60, .8)
}

.main-calendar .fc-agendaWeek-view .fc-time-grid-event>div {
    display: none
}

.main-calendar .fc-agendaWeek-view .fc-time-grid-event>div .fc-time {
    font-size: 11px
}

.main-calendar .fc-agendaWeek-view .fc-time-grid-event>div .fc-title {
    font-size: 13px
}

.main-calendar .fc-agendaWeek-view .fc-time-grid-event>div .fc-desc {
    display: none
}

.main-calendar .fc-list-empty {
    padding: 20px 0;
    background-color: #fff;
    border: 1px solid #e3e8f7
}

@media (min-width:992px) {
    .main-calendar .fc-header-toolbar {
        padding: 0;
        margin-bottom: 24px
    }
}

@media (min-width:576px) {
    .main-calendar .fc-header-toolbar h2 {
        font-size: 22px
    }
}

@media (max-width:767.98px) {
    .main-calendar .fc-header-toolbar .fc-center {
        order: 1;
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px
    }
    .main-calendar .fc-header-toolbar .fc-left {
        order: 2;
        flex: 0 0 50%
    }
    .main-calendar .fc-header-toolbar .fc-right {
        flex: 0 0 50%;
        display: flex;
        justify-content: flex-end
    }
}

@media (max-width:991px) {
    .main-calendar .fc-header-toolbar .fc-center {
        order: 1;
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px
    }
    .main-calendar .fc-header-toolbar .fc-left {
        order: 2;
        flex: 0 0 50%
    }
    .main-calendar .fc-header-toolbar .fc-right {
        flex: 0 0 50%;
        display: flex;
        justify-content: flex-end
    }
    .main-calendar .fc-header-toolbar button {
        padding: 0 8px
    }
}

@media (max-width:575px) {
    .main-calendar .fc-header-toolbar button.fc-next-button,
    .main-calendar .fc-header-toolbar button.fc-prev-button {
        width: 32px;
        height: 32px
    }
    .main-calendar .fc-header-toolbar button.fc-today-button {
        height: 32px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11px
    }
    .main-calendar .fc-header-toolbar button.fc-agendaDay-button,
    .main-calendar .fc-header-toolbar button.fc-agendaWeek-button,
    .main-calendar .fc-header-toolbar button.fc-listMonth-button,
    .main-calendar .fc-header-toolbar button.fc-listWeek-button,
    .main-calendar .fc-header-toolbar button.fc-month-button {
        text-indent: -9999px;
        width: 32px;
        height: 32px;
        position: relative;
        color: transparent
    }
    .main-calendar .fc-header-toolbar button.fc-agendaDay-button::before,
    .main-calendar .fc-header-toolbar button.fc-agendaWeek-button::before,
    .main-calendar .fc-header-toolbar button.fc-listMonth-button::before,
    .main-calendar .fc-header-toolbar button.fc-listWeek-button::before,
    .main-calendar .fc-header-toolbar button.fc-month-button::before {
        position: absolute;
        top: 6px;
        left: 10px;
        z-index: 100;
        display: inline-block;
        text-indent: 0;
        font-size: 12px;
        font-weight: 700;
        color: #031b4e
    }
    .main-calendar .fc-header-toolbar button.fc-month-button::before {
        content: 'M'
    }
    .main-calendar .fc-header-toolbar button.fc-agendaWeek-button::before {
        content: 'W'
    }
    .main-calendar .fc-header-toolbar button.fc-agendaDay-button::before {
        content: 'D'
    }
    .main-calendar .fc-header-toolbar button.fc-listMonth-button::before,
    .main-calendar .fc-header-toolbar button.fc-listWeek-button::before {
        content: 'L';
        left: 11px
    }
}

@media (min-width:992px) {
    .main-calendar .fc-icon-left-single-arrow::after,
    .main-calendar .fc-icon-right-single-arrow::after {
        line-height: .75
    }
}

@media (min-width:576px) {
    .main-calendar .fc-head-container .fc-day-header {
        font-size: 12px
    }
}

@media (max-width:991.98px) {
    .main-calendar .fc-view>table>thead td:first-child,
    .main-calendar .fc-view>table>thead th:first-child {
        border-left-width: 0
    }
    .main-calendar .fc-view>table>thead td:last-child,
    .main-calendar .fc-view>table>thead th:last-child {
        border-right-width: 0
    }
}

@media (max-width:991.98px) {
    .main-calendar .fc-view>table>tbody>tr>td:first-child {
        border-left-width: 0
    }
    .main-calendar .fc-view>table>tbody>tr>td:last-child {
        border-right-width: 0
    }
}

@media (prefers-reduced-motion:reduce) {
    .main-calendar .fc-view .fc-day-number {
        transition: none
    }
}

@media (min-width:576px) {
    .main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:first-child {
        font-size: 11px;
        font-weight: 600
    }
}

@media (min-width:576px) {
    .main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:last-child {
        font-size: 25px
    }
}

@media (min-width:992px) {
    .main-calendar .fc-view.fc-listMonth-view .fc-scroller,
    .main-calendar .fc-view.fc-listWeek-view .fc-scroller {
        padding: 0
    }
}

@media (min-width:576px) {
    .main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
        flex: 0 0 15%;
        max-width: 15%
    }
}

@media (min-width:768px) {
    .main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
        flex: 0 0 12%;
        max-width: 12%
    }
}

@media (min-width:992px) {
    .main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
        flex: 0 0 10%;
        max-width: 10%
    }
}

@media (min-width:1200px) {
    .main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
        flex: 0 0 8%;
        max-width: 8%
    }
}

@media (min-width:576px) {
    .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
        flex: 0 0 calc(85% - 5px);
        max-width: calc(85% - 5px)
    }
}

@media (min-width:768px) {
    .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
        flex: 0 0 calc(88% - 5px);
        max-width: calc(88% - 5px)
    }
}

@media (min-width:992px) {
    .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
        flex: 0 0 calc(90% - 5px);
        max-width: calc(90% - 5px)
    }
}

@media (min-width:1200px) {
    .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
        flex: 0 0 calc(92% - 5px);
        max-width: calc(92% - 5px)
    }
}

@media (min-width:576px) {
    .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: 15%
    }
}

@media (min-width:768px) {
    .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: 12%
    }
}

@media (min-width:992px) {
    .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: 10%
    }
}

@media (min-width:1200px) {
    .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: 8%
    }
}

@media (min-width:576px) {
    .main-calendar .fc-axis {
        font-size: 13px
    }
}

@media (min-width:576px) {
    .main-calendar .fc-event {
        border-top-width: 0;
        border-left-width: 2px;
        padding: 3px 4px
    }
}

@media (min-width:576px) {
    .main-calendar .fc-event.fc-day-grid-event>div {
        display: block
    }
}

@media (min-width:576px) {
    .main-calendar .fc-agendaWeek-view .fc-time-grid-event>div {
        display: block
    }
}

@media (min-width:992px) {
    .main-calendar .fc-agendaWeek-view .fc-time-grid-event>div .fc-desc {
        display: block
    }
}

.main-modal-calendar-schedule .modal-content {
    border-width: 0;
    position: relative
}

.main-modal-calendar-schedule .modal-content>.close {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 28px;
    font-weight: 700;
    color: #737f9e;
    text-shadow: none
}

.main-modal-calendar-schedule .modal-header {
    padding: 20px 25px
}

.main-modal-calendar-schedule .modal-body {
    padding: 30px 25px
}

.main-modal-calendar-schedule .form-group-date {
    display: flex;
    align-items: center;
    border: 1px solid #e3e8f7;
    background-color: #ecf0fa;
    padding: 10px 5px;
    color: #242f48;
    font-size: 13px;
    font-weight: 600
}

.main-modal-calendar-schedule .form-group-date>i {
    font-size: 32px;
    line-height: 0;
    margin-right: 10px;
    opacity: .5
}

.main-modal-calendar-schedule .select2-container {
    width: 100%!important
}

.main-modal-calendar-event .modal-content {
    border-width: 0;
    position: relative;
    background-color: transparent
}

.main-modal-calendar-event .modal-content>.close {
    font-size: 18px;
    text-shadow: none
}

.main-modal-calendar-event .modal-header {
    position: relative;
    padding: 20px;
    border-bottom-width: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.main-modal-calendar-event .nav-modal-event {
    align-items: center
}

.main-modal-calendar-event .nav-modal-event .nav-link {
    padding: 0;
    color: rgba(255, 255, 255, .75);
    font-size: 21px;
    line-height: 0;
    position: relative;
    transition: all .2s ease-in-out
}

.main-modal-calendar-event .nav-modal-event .nav-link:focus,
.main-modal-calendar-event .nav-modal-event .nav-link:hover {
    color: #fff
}

.main-modal-calendar-event .nav-modal-event .nav-link+.nav-link {
    margin-left: 15px
}

.main-modal-calendar-event .nav-modal-event .nav-link:last-child {
    font-size: 24px
}

.main-modal-calendar-event .event-title {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1
}

.main-modal-calendar-event .modal-body {
    padding: 20px;
    background-color: #fff
}

.main-modal-calendar-event .event-end-date,
.main-modal-calendar-event .event-start-date {
    color: #242f48;
    font-weight: 500
}

.main-modal-calendar-event .event-desc {
    margin-top: 5px
}

.main-modal-calendar-event .event-desc:empty {
    margin-bottom: 5px
}

@media (min-width:576px) {
    .main-modal-calendar-event .modal-header {
        padding: 20px 25px
    }
}

@media (prefers-reduced-motion:reduce) {
    .main-modal-calendar-event .nav-modal-event .nav-link {
        transition: none
    }
}

@media (min-width:576px) {
    .main-modal-calendar-event .modal-body {
        padding: 30px
    }
}

@media (max-width:605px) {
    .main-calendar .fc-header-toolbar {
        padding: 0
    }
    .fc-view {
        border: 1px solid #e3e8f7
    }
    .fc-view>table {
        border: 1px solid #e3e8f7
    }
    .card--calendar .ui-datepicker .ui-datepicker-calendar td a {
        width: 28px;
        height: 28px
    }
    .main-content-body.main-content-body-calendar.card.p-4 {
        padding: 1rem!important
    }
}

@media (max-width:320px) {
    .main-calendar .fc-header-toolbar .fc-right {
        flex: 0 0 100%;
        display: flex;
        justify-content: left;
        margin-top: 20px
    }
    .main-calendar .fc-header-toolbar .fc-left {
        flex: 0 0 100%;
        display: flex;
        justify-content: left
    }
}

.fc-datepicker.main-datepicker.hasDatepicker {
    border: 1px solid #e3e8f7
}

@media (min-width:320px) and (max-width:568px) {
    .ui-datepicker-calendar {
        width: 100%
    }
    .ui-datepicker .ui-datepicker-calendar td,
    .ui-datepicker .ui-datepicker-calendar th {
        text-align: center
    }
}

@media (max-width:991.98px) {
    .main-content-body-show .main-header-menu-icon {
        display: none
    }
    .main-content-body-show .main-header-left .main-header-arrow {
        display: block
    }
    .main-content-body-show .main-content-left-chat {
        display: none
    }
    .main-content-body-show .main-content-body-chat {
        display: flex
    }
}

.main-content-left-chat {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0
}

.main-nav-line-chat {
    border-bottom: 1px solid #e3e8f7;
    padding-bottom: 5px;
    flex-shrink: 0;
    flex-direction: row;
    padding-left: 20px
}

.main-nav-line-chat .nav-link {
    font-size: 13px;
    font-weight: 500;
    color: #364261;
    text-transform: uppercase
}

.main-nav-line-chat .nav-link:active,
.main-nav-line-chat .nav-link:focus,
.main-nav-line-chat .nav-link:hover {
    outline: 0
}

.main-nav-line-chat .nav-link+.nav-link {
    margin-top: 0;
    margin-left: 30px
}

.main-nav-line-chat .nav-link.active {
    color: #078586
}

.main-nav-line-chat .nav-link.active::before {
    top: 34px;
    left: 0;
    right: 0;
    width: auto;
    bottom: -6px;
    background-color: #078586
}

@media (min-width:992px) {
    .main-nav-line-chat {
        padding-left: 0;
        flex-shrink: 0;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 20px;
        border-bottom: 1px solid #e3e8f7
    }
}

.main-chat-body .main-img-user::after,
.main-chat-contacts-wrapper .main-img-user::after,
.main-chat-header .main-img-user::after,
.main-chat-list .main-img-user::after {
    right: 1px;
    bottom: 3px
}

.main-chat-contacts-wrapper {
    padding: 10px 20px;
    flex-shrink: 0;
    border-bottom: 1px solid #e3e8f7;
    overflow: hidden
}

.main-chat-contacts-wrapper .card-table-two .card-title,
.main-chat-contacts-wrapper .main-content-label {
    margin-bottom: 15px;
    line-height: 1;
    padding-left: 20px
}

.card-table-two {
    padding: 20px
}

.card-table-two .card-title {
    font-size: 13px
}
.card.card-table-two.unpaid-table {
    border-color: #ffe000;
    box-shadow: -8px 12px 18px 0 #d9d16d;
}
.card.card-table-two.unpaid-table .card-title {
    color: #9f8d0f;
}
.card.card-table-two.overdue-table {
    border-color: #ff0000;
    box-shadow: -8px 12px 18px 0 #d96d6d;
}
.card.card-table-two.overdue-table .card-title {
    color: #ff0000;
}

@media (min-width:992px) {
    .main-chat-contacts-wrapper {
        padding: 10px 15px 10px 0
    }
}

.card-dashboard-eight .main-chat-contacts-wrapper .card-title,
.card-table-two .main-chat-contacts-wrapper .card-title,
.main-chat-contacts-wrapper .card-dashboard-eight .card-title {
    margin-bottom: 15px;
    line-height: 1;
    padding-left: 20px
}

.main-chat-contacts-wrapper .lSSlideOuter,
.main-chat-contacts-wrapper .lSSlideWrapper {
    overflow: visible
}

.main-chat-contacts {
    padding-left: 20px;
    display: flex
}

.main-chat-contacts .lslide {
    display: flex;
    flex-direction: column;
    align-items: center;
    float: none
}

.main-chat-contacts small {
    font-size: 11px;
    width: 36px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 3px;
    text-align: center
}

.main-chat-contacts-more {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #078586;
    font-size: 12px;
    font-weight: 500;
    color: #fff
}

@media (min-width:992px) {
    .main-chat-list {
        max-height: 490px;
        position: relative
    }
}

.main-chat-list .media {
    padding: 12px 20px;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #e3e8f7;
    position: relative
}

.main-chat-list .media .main-img-user {
    flex-shrink: 0;
    top: 3px
}

.main-chat-list .media::after {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: 1px;
    background-color: #078586;
    display: none
}

.main-chat-list .media+.media::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 65px;
    right: 0
}

.main-chat-list .media.new {
    background-color: #fff
}

.main-chat-list .media.new .main-img-user span {
    display: flex
}

.main-chat-list .media.new .media-contact-name span:first-child {
    font-weight: 700;
    color: #242f48
}

.main-chat-list .media.new .media-body p {
    color: #031b4e
}

.main-chat-list .media:focus,
.main-chat-list .media:hover {
    cursor: pointer;
    background-color: #fafcff;
    border-top-color: #dde2ef;
    border-bottom-color: #dde2ef;
    z-index: 1
}

.main-chat-list .media:focus:first-child,
.main-chat-list .media:hover:first-child {
    border-top-color: transparent
}

.main-chat-list .media.selected {
    background-color: #ecf0fa;
    border-top-color: #dde2ef;
    border-bottom-color: #dde2ef;
    border-left: 1px solid #078586;
    z-index: 2
}

.main-chat-list .media.selected:first-child {
    border-top-color: transparent
}

.main-chat-list .media.selected::after {
    display: block
}

.main-chat-list .media.selected .media-contact-name span:first-child {
    color: #242f48
}

.main-chat-list .media.selected .media-body p {
    color: #031b4e
}

.main-chat-list .main-img-user span {
    position: absolute;
    top: 0;
    left: -2px;
    width: 14px;
    height: 14px;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 9px;
    font-weight: 500;
    color: #fff;
    background-color: #ee335e;
    box-shadow: 0 0 0 2px #fff
}

.main-chat-list .media-body {
    margin-left: 15px
}

.main-chat-list .media-body p {
    font-size: 13px;
    margin-bottom: 0;
    color: #737f9e;
    line-height: 1.35
}

.main-chat-list .media-contact-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px
}

.main-chat-list .media-contact-name span:first-child {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #242f48
}

.main-chat-list .media-contact-name span:last-child {
    display: block;
    font-size: 11px;
    color: #949eb7
}

@media (min-width:992px) {
    .main-chat-list .media {
        padding: 12px 15px
    }
}

.main-content-body-chat {
    flex-direction: column;
    display: none
}

@media (min-width:992px) {
    .main-content-body-chat {
        display: flex
    }
}

.main-chat-header {
    flex-shrink: 0;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    border-bottom: 1px solid #e3e8f7
}

.main-chat-header .nav {
    margin-left: auto;
    align-items: center
}

.main-chat-header .nav-link {
    padding: 0;
    color: #b8c2d8;
    font-size: 20px;
    display: none
}

.main-chat-header .nav-link:first-child {
    display: block
}

.main-chat-header .nav-link i {
    line-height: 0
}

.main-chat-header .nav-link .typcn {
    line-height: .9
}

.main-chat-header .nav-link .typcn::before {
    width: auto
}

.main-chat-header .nav-link:focus,
.main-chat-header .nav-link:hover {
    color: #078586
}

.main-chat-header .nav-link+.nav-link {
    margin-left: 15px
}

@media (min-width:576px) {
    .main-chat-header .nav-link {
        display: block
    }
    .main-chat-header .nav-link:first-child {
        display: none
    }
}

.main-chat-msg-name {
    margin-left: 15px
}

.main-chat-msg-name h6 {
    margin-bottom: 2px;
    font-size: 15px;
    font-weight: 700;
    color: #242f48
}

.main-chat-msg-name small {
    font-size: 12px;
    color: #737f9e
}

.main-chat-body {
    position: relative;
    padding-bottom: 50px
}

.main-chat-body .content-inner {
    padding: 20px
}

.main-chat-body .media+.media {
    margin-top: 20px
}

.main-chat-body .media.flex-row-reverse .media-body {
    margin-left: 0;
    margin-right: 20px;
    align-items: flex-end
}

.main-chat-body .media.flex-row-reverse .main-msg-wrapper {
    background-color: #078586;
    color: #fff
}

.main-chat-body .media-body {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start
}

.main-chat-body .media-body>div:last-child {
    font-size: 11px;
    color: #737f9e;
    display: flex;
    align-items: center
}

@media (min-width:992px) {
    .main-chat-body {
        padding-bottom: 0;
        max-height: 535px;
        overflow: hidden
    }
}

@media (min-width:576px) {
    .main-chat-body .media.flex-row-reverse .media-body {
        margin-left: 55px
    }
}

@media (min-width:576px) {
    .main-chat-body .media-body {
        margin-right: 55px
    }
}

.main-chat-time {
    display: block;
    position: relative;
    text-align: center;
    margin: 20px 0
}

.main-chat-time:first-of-type {
    margin-top: 0
}

.main-chat-time span {
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px
}

.main-msg-wrapper {
    padding: 10px 15px;
    background-color: #ecf0fa;
    font-size: 13px;
    margin-bottom: 5px;
    border-radius: 2px;
    display: inline-block
}

.main-chat-footer {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    border-top: 1px solid #e3e8f7;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0
}

.main-chat-footer .nav {
    align-items: center
}

.main-chat-footer .nav-link {
    padding: 0;
    color: #949eb7;
    font-size: 16px;
    display: none
}

.main-chat-footer .nav-link+.nav-link {
    margin-left: 10px
}

.main-chat-footer .nav-link:last-child {
    display: block;
    margin-left: 0
}

.main-chat-footer .form-control {
    flex: 1;
    margin: 0 10px;
    padding-left: 0;
    padding-right: 0;
    border-width: 0
}

.main-chat-footer .form-control:focus,
.main-chat-footer .form-control:hover {
    box-shadow: none
}

@media (min-width:992px) {
    .main-chat-footer {
        position: relative;
        bottom: auto;
        left: auto;
        right: auto
    }
}

@media (min-width:576px) {
    .main-chat-footer .nav-link {
        display: block
    }
    .main-chat-footer .nav-link:last-child {
        display: none
    }
}

@media (min-width:576px) {
    .main-chat-footer .form-control {
        margin-left: 20px
    }
}

@media (min-width:768px) {
    .main-chat-footer .form-control {
        margin: 0 20px
    }
}

.main-msg-send {
    font-size: 20px;
    color: #078586
}

@media (max-width:991px) and (min-width:768px) {
    .app.sidebar-mini .main-chat-footer {
        margin-left: 249px
    }
    .app.sidebar-mini.sidenav-toggled .main-chat-footer {
        margin-left: 0
    }
}

@media (max-width:380px) and (min-width:305px) {
    .main-nav-line-chat .nav-link+.nav-link {
        margin: 0
    }
}

.chat {
    margin-bottom: auto
}

.chat .card {
    height: 600px
}

.chat .card-header {
    padding: 1rem
}

.contacts_body {
    white-space: nowrap
}

.msg_card_body {
    overflow-y: auto
}

.contacts_body .contacts {
    list-style: none;
    padding: 0
}

.contacts_body .contacts li {
    width: 100%!important;
    padding: 15px;
    border-bottom: 1px solid #ebeaf1
}

.contacts_body .active {
    background-color: #f5f6fb
}

#action_menu_btn {
    position: absolute;
    right: 36px;
    top: 21px;
    color: #fff;
    cursor: pointer;
    font-size: 20px
}

.action_menu {
    z-index: 1;
    position: absolute;
    padding: 15px 0;
    background-color: #fff;
    color: #646367;
    top: 40px;
    right: 15px;
    display: none
}

.action_menu ul {
    list-style: none;
    padding: 0;
    margin: 0
}

.action-header {
    position: relative;
    background: #078586;
    padding: 15px 13px 15px 17px
}

.ah-actions {
    z-index: 3;
    float: right!important;
    margin-top: 7px;
    position: relative
}

.actions {
    list-style: none;
    padding: 0;
    margin: 0
}

.actions>li {
    display: inline-block
}

.actions:not(.a-alt)>li>a>i {
    color: #a7a8c6
}

.actions>li>a {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 auto;
    line-height: 50px;
    text-align: center
}

.actions>li>a>i {
    font-size: 20px
}

.actions>li>a:hover {
    background: rgba(0, 0, 0, .1)
}

.dash-5 {
    text-align: center
}

.dash-5 h3 {
    font-size: 2rem;
    margin-bottom: .5rem
}

.well {
    color: #5e629c
}

.icon.icon-shape i {
    height: 3rem;
    width: 3rem;
    background: #000;
    border-radius: 50%;
    line-height: 3rem;
    background: rgba(9, 22, 49, .2);
    color: #fff
}

.user-img-circle {
    box-shadow: 0 0 0 rgba(81, 15, 167, .4);
    animation: pulse 2s infinite;
    height: 90px
}

.chat .action-header {
    position: relative;
    padding: 15px 13px 15px 17px;
    box-shadow: 0 1px 15px 1px rgba(58, 58, 62, .5)
}

.chat .dropdown-menu.dropdown-menu-right.show {
    width: 100%;
    margin-bottom: 5px;
    position: absolute;
    transform: translate3d(-176px, 22px, 0);
    top: 10px!important;
    left: 0;
    will-change: transform
}

.chat .msb-reply textarea {
    width: 100%;
    font-size: 13px;
    padding: 12px 47px;
    resize: none;
    height: 44px;
    border: 1px solid #ebeaf1;
    background: #faf9ff;
    margin-left: -42px
}

.chat .dropdown-menu li {
    padding: 7px;
    color: #5e629c
}

.chat .dropdown-menu li:hover {
    background: #faf9ff
}

.chat .dropdown-menu li i {
    padding: 7px
}

.action_menu ul li:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, .2)
}

.chat .actions>li {
    display: inline-block
}

.chat .actions:not(.a-alt)>li>a>i {
    color: #fff;
    line-height: unset
}

.chat .justify-content-end,
.chat .justify-content-start {
    margin-bottom: 25px
}

.chatbox .user_img {
    height: 50px;
    width: 50px;
    border: 1.5px solid #f5f6fa
}

.chatbox .user_img_msg {
    height: 40px;
    width: 40px;
    border: 1.5px solid #f5f6fa
}

.chatbox .img_cont {
    position: relative;
    height: 50px;
    width: 50px
}

.chatbox .img_cont_msg {
    height: 40px;
    width: 40px;
    display: contents
}

.chat-box-single-line {
    height: 12px;
    margin: 7px 0 30px;
    position: relative;
    text-align: center
}

.chat abbr.timestamp {
    padding: 4px 14px;
    border-radius: 4px;
    color: #000
}

.chatbox .user_info {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px
}

.chatbox .user_info span {
    color: #464444
}

.chatbox .action-header small {
    font-size: 10px
}

.chat .video_cam {
    margin-left: 50px;
    margin-top: 5px
}

.chat .video_cam span {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin-right: 20px
}

.chat .msg_cotainer {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    background-color: rgba(76, 175, 80, .15);
    padding: 10px;
    position: relative
}

.chat .msg_cotainer_send {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    background-color: #e4edf9;
    padding: 10px;
    position: relative
}

.chat .msg_time {
    position: absolute;
    left: 0;
    bottom: -18px;
    color: #3a374e;
    font-size: 10px
}

.chat .msg_time_send {
    position: absolute;
    right: 0;
    bottom: -18px;
    color: #3a374e;
    font-size: 10px
}

.chat .msg_head {
    position: relative;
    background: #60529f;
    box-shadow: 0 1px 15px 1px rgba(58, 58, 62, .4)
}

@media (min-width:768px) and (max-width:991.98px) {
    .main-content-body-show .main-header-contacts .main-header-menu-icon {
        display: flex
    }
    .main-content-body-show .main-content-left-contacts {
        display: block
    }
}

.main-content-left-contacts {
    width: 100%;
    display: block;
    padding: 0
}

.main-content-left-contacts .main-content-breadcrumb,
.main-content-left-contacts .main-content-title {
    padding-left: 20px
}

@media (min-width:768px) {
    .main-content-left-contacts {
        border-right: 1px solid #e3e8f7
    }
}

@media (min-width:992px) {
    .main-content-left-contacts .main-content-breadcrumb,
    .main-content-left-contacts .main-content-title {
        padding-left: 0
    }
}

@media (min-width:768px) {
    .main-contacts-list {
        height: 730px;
        position: relative
    }
}

.main-contact-label {
    padding-top: 20px;
    padding-bottom: 5px;
    padding-left: 20px;
    font-weight: 500;
    font-size: 12px;
    color: #949eb7;
    position: relative
}

.main-contact-label::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    border-bottom: 1px solid #e3e8f7;
    z-index: 5
}

@media (min-width:992px) {
    .main-contact-label {
        padding-left: 30px
    }
}

.main-contact-item {
    padding: 10px 20px;
    border: 1px solid transparent;
    border-right-width: 0;
    display: flex;
    position: relative;
    cursor: pointer
}

.main-contact-item+.main-contact-item {
    margin-top: -1px;
    border-top-color: #dde2ef
}

.main-contact-item+.main-contact-item::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 65px;
    right: 0;
    border-top: 1px solid #dde2ef
}

.main-contact-item:focus,
.main-contact-item:hover {
    background-color: #ecf0fa;
    border-top-color: #dde2ef;
    border-bottom-color: #dde2ef
}

.main-contact-item.selected {
    z-index: 1;
    border-left-color: #078586;
    border-top-color: #dde2ef;
    border-bottom-color: #dde2ef;
    background-color: #ecf0fa
}

@media (min-width:992px) {
    .main-contact-item {
        padding: 10px 15px
    }
}

.main-contact-body {
    flex: 1;
    margin-left: 15px
}

.main-contact-body h6 {
    color: #242f48;
    font-weight: 500;
    margin-bottom: 2px
}

.main-contact-body span {
    font-size: 13px;
    color: #737f9e
}

.main-contact-star {
    font-size: 16px;
    color: #949eb7
}

.main-contact-star:focus,
.main-contact-star:hover {
    color: #737f9e
}

.main-contact-star.active {
    color: #fbbc0b
}

.main-content-body-contacts {
    display: none
}

@media (min-width:768px) {
    .main-content-body-contacts {
        display: block;
        overflow-y: auto
    }
}

@media (min-width:992px) {
    .main-content-body-contacts {
        overflow: visible
    }
}

.main-contact-info-header {
    padding-top: 40px;
    padding-left: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e3e8f7;
    position: relative
}

.main-contact-info-header .media {
    display: block
}

.main-contact-info-header .main-img-user {
    width: 100px;
    height: 100px
}

.main-contact-info-header .main-img-user a {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #737f9e;
    color: #fff;
    font-size: 18px;
    line-height: .9;
    box-shadow: 0 0 0 2px #fff;
    border-radius: 100%
}

.main-contact-info-header .main-img-user a:focus,
.main-contact-info-header .main-img-user a:hover {
    background-color: #078586
}

.main-contact-info-header .media-body {
    margin-top: 30px
}

.main-contact-info-header .media-body h4 {
    color: #242f48;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 8px;
    line-height: 1
}

.main-contact-info-header .media-body p {
    color: #031b4e;
    margin-bottom: 15px
}

.main-contact-info-header .media-body .nav-link {
    padding: 0;
    display: flex;
    align-items: center;
    color: #737f9e;
    font-size: 13px
}

.main-contact-info-header .media-body .nav-link i {
    font-size: .875rem;
    line-height: 0;
    margin-right: 5px
}

.main-contact-info-header .media-body .nav-link i.typcn {
    line-height: .9
}

.main-contact-info-header .media-body .nav-link i.typcn::before {
    width: auto
}

.main-contact-info-header .media-body .nav-link:focus,
.main-contact-info-header .media-body .nav-link:hover {
    color: #078586
}

.main-contact-info-header .media-body .nav-link+.nav-link {
    margin-left: 10px
}

@media (min-width:576px) {
    .main-contact-info-header {
        padding-top: 20px
    }
}

@media (min-width:992px) {
    .main-contact-info-header {
        padding-left: 30px;
        padding-bottom: 25px
    }
}

@media (min-width:992px) {
    .main-contact-info-header .media {
        display: flex;
        align-items: center
    }
}

@media (min-width:992px) {
    .main-contact-info-header .media-body {
        margin-top: 0;
        margin-left: 30px
    }
}

@media (min-width:576px) {
    .main-contact-info-header .media-body .nav-link {
        font-size: .875rem
    }
}

@media (min-width:576px) {
    .main-contact-info-header .media-body .nav-link i {
        margin-right: 7px
    }
}

@media (min-width:576px) {
    .main-contact-info-header .media-body .nav-link+.nav-link {
        margin-left: 25px
    }
}

.main-contact-action {
    position: absolute;
    top: 24px;
    right: 20px;
    display: flex
}

.main-contact-action a {
    display: flex;
    align-items: center;
    color: #737f9e;
    font-size: 12px
}

.main-contact-action a:focus,
.main-contact-action a:hover {
    color: #078586
}

.main-contact-action a i {
    font-size: 21px;
    line-height: .5
}

.main-contact-action a+a {
    margin-left: 15px
}

@media (min-width:992px) {
    .main-contact-action {
        right: 20px
    }
}

.main-contact-info-body {
    padding-left: 20px
}

.main-contact-info-body .media-list {
    padding: 20px 0
}

.main-contact-info-body .media {
    align-items: center;
    position: relative
}

.main-contact-info-body .media+.media {
    margin-top: 30px
}

.main-contact-info-body .media+.media::before {
    content: '';
    position: absolute;
    top: -15px;
    left: -19px;
    right: 0;
    border-top: 1px dotted #d0d7e8
}

.main-contact-info-body .media:last-child {
    margin-bottom: 15px
}

.main-contact-info-body .media-icon {
    font-size: 32px;
    color: #949eb7;
    line-height: 1;
    width: 40px;
    text-align: center
}

.main-contact-info-body .media-body {
    margin-left: 0
}

.main-contact-info-body .media-body>div+div {
    margin-top: 15px
}

.main-contact-info-body .media-body label {
    color: #737f9e;
    margin-bottom: 0;
    display: block;
    font-size: 13px
}

.main-contact-info-body .media-body span {
    display: block;
    color: #242f48;
    font-weight: 500
}

@media (min-width:992px) {
    .main-contact-info-body {
        height: calc(100% - 126px);
        position: relative
    }
}

@media (min-width:992px) {
    .main-contact-info-body .media-list {
        padding: 25px 0
    }
}

@media (min-width:992px) {
    .main-contact-info-body .media-icon {
        width: 100px
    }
}

@media (min-width:992px) {
    .main-contact-info-body .media-body {
        display: flex
    }
}

@media (min-width:992px) {
    .main-contact-info-body .media-body>div {
        flex: 1
    }
}

@media (min-width:992px) {
    .main-contact-info-body .media-body>div+div {
        margin-top: 0
    }
}

@media (min-width:992px) {
    .main-contact-info-body {
        height: calc(100% - 126px);
        position: relative
    }
}

.main-contact-info-header .main-img-user img {
    width: 100px!important;
    height: 100px!important
}

.contact-icon {
    display: inline-flex;
    width: 35px;
    height: 35px;
    text-align: center;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%
}

@media (min-width:769px) and (max-width:991px) {
    .main-contacts-list {
        height: 627px;
        position: relative
    }
}

@media (max-width:768px) {
    .main-contacts-list {
        height: 600px;
        position: relative
    }
}

.main-contact-item .main-img-user::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 6px;
    height: 6px;
    background-color: #17b86a;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, .95);
    border-radius: 100%;
    bottom: 3px;
    display: block!important
}

.main-content-body-contacts .main-contact-info-header .main-img-user::after {
    display: none!important
}

.contact-icon {
    display: inline-flex;
    width: 35px;
    height: 35px;
    text-align: center;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%
}

.main-error-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    text-align: center
}

.main-error-wrapper h1 {
    line-height: 1;
    font-size: 150px;
    font-weight: 700;
    color: #242f48
}

.main-error-wrapper h2 {
    font-weight: 500;
    color: #364261;
    letter-spacing: -.5px;
    margin-bottom: 15px
}

.main-error-wrapper h6 {
    margin-bottom: 40px;
    font-size: 14px
}

.main-error-wrapper .btn,
.main-error-wrapper .sp-container button {
    border-width: 2px;
    font-weight: 700
}

.sp-container .main-error-wrapper button {
    border-width: 2px;
    font-weight: 700
}

.main-signin-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 20px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center
}

.main-card-signin {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    position: relative
}

.main-card-signup {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, .5);
    position: relative
}

@media (max-width:767px) {
    .main-card-signin {
        display: block!important;
        padding: 0
    }
    .page-signin-style:before {
        display: none
    }
    .app-sidebar {
        z-index: 1029!important;
        top: 0!important
    }
    .main-nav-line-chat {
        padding: 5px 20px!important
    }
}

@media (min-width:480px) {
    .main-card-signin {
        padding: 0;
        max-width: 750px
    }
    .main-card-signin.construction {
        max-width: 1000px
    }
    .main-card-signup {
        border: 1px solid #e3e3e3;
        padding: 0;
        max-width: 850px
    }
    .sign-up-body {
        padding: 2.6rem!important
    }
}

.main-signin-header h2 {
    font-weight: 500;
    color: #078586;
    letter-spacing: -1px
}

.main-signin-header h4 {
    font-weight: 500;
    color: #14112d;
    margin-bottom: 25px;
    font-size: 16px
}

.main-signin-header label {
    color: #a5a0b1
}

.main-signin-header .form-control {
    color: #14112d;
    font-weight: 500;
    border-width: 2px;
    border-color: #e3e3e3
}

.main-signin-header .form-control:focus {
    border-color: #b9c2d8;
    box-shadow: none
}

.main-signin-header .form-control::placeholder {
    font-weight: 400;
    color: #a5a0b1
}

.page-signin-style:before {
    content: '';
    background: rgba(109, 110, 243, .92);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 0
}

.authentication-pages {
    position: relative;
    z-index: 99
}

.main-card-signin {
    box-shadow: none
}

.main-signin-header .btn,
.main-signin-header .sp-container button {
    margin-top: 25px
}

.sp-container .main-signin-header button {
    margin-top: 25px
}

.main-signin-footer p {
    color: #a5a0b1
}

.main-signin-footer p:first-child {
    margin-bottom: 5px
}

.main-signin-footer p:last-child {
    margin-bottom: 0
}

.main-signin-footer a {
    color: #14112d;
    font-weight: 700
}

.main-signin-footer a:focus,
.main-signin-footer a:hover {
    color: #078586
}

.main-signup-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    background-color: rgba(244, 245, 248, .2)
}

@media (min-width:992px) {
    .main-signup-wrapper {
        justify-content: flex-end
    }
    .page-signin-style {
        height: auto!important;
        height: auto!important
    }
}

.page-signin-style {
    width: 100%;
    height: 100%;
    background: url(../img/media/4.jpg);
    left: 0;
    top: 0;
    bottom: 0;
    position: relative
}

.main-column-signup-left {
    flex: 1;
    padding: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none
}

.main-column-signup-left>div {
    max-width: 500px
}

.main-column-signup-left .typcn {
    font-size: 80px;
    margin-bottom: 50px
}

.main-column-signup-left .typcn::before {
    width: auto
}

.main-column-signup-left .main-logo {
    font-size: 40px;
    line-height: 1
}

.main-column-signup-left h5 {
    color: #14112d;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 25px
}

.main-column-signup-left p {
    line-height: 1.7;
    margin-bottom: 25px
}

.main-column-signup-left .btn,
.main-column-signup-left .sp-container button {
    border-width: 2px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: 700;
    text-transform: uppercase;
    height: 38px
}

@media (min-width:576px) {
    .main-column-signup-left {
        padding: 40px
    }
}

@media (min-width:992px) {
    .main-column-signup-left {
        display: flex
    }
}

.sp-container .main-column-signup-left button {
    border-width: 2px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: 700;
    text-transform: uppercase;
    height: 38px
}

.main-column-signup {
    background-color: #fff;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

@media (min-width:576px) {
    .main-column-signup {
        padding: 40px;
        width: 500px
    }
}

@media (min-width:992px) {
    .main-column-signup {
        border-left: 1px solid #e3e3e3
    }
}

.main-signup-header h2 {
    font-weight: 500;
    color: #078586;
    letter-spacing: -1px
}

.main-signup-header h4 {
    font-size: 20px;
    font-weight: 400;
    color: #14112d;
    margin-bottom: 25px
}

.main-signup-header label {
    color: #a5a0b1
}

.main-signup-header .form-control {
    color: #14112d;
    font-weight: 500;
    border-width: 2px;
    border-color: #e3e3e3
}

.main-signup-header .form-control:focus {
    border-color: #b9c2d8;
    box-shadow: none
}

.main-signup-header .form-control::placeholder {
    font-weight: 400;
    color: #a5a0b1
}

.main-signup-header>.btn {
    margin-top: 25px
}

.sp-container .main-signup-header>button {
    margin-top: 25px
}

.main-signup-header .row {
    margin-top: 20px
}

.main-signup-header .row>div .btn i,
.main-signup-header .row>div .sp-container button i {
    font-size: 15px;
    line-height: 0;
    margin-right: 5px
}

.sp-container .main-signup-header .row>div button i {
    font-size: 15px;
    line-height: 0;
    margin-right: 5px
}

.main-signup-header .row>div:first-child .btn,
.main-signup-header .row>div:first-child .sp-container button {
    background-color: #4267b2;
    color: #fff
}

.sp-container .main-signup-header .row>div:first-child button {
    background-color: #4267b2;
    color: #fff
}

.main-signup-header .row>div:first-child .btn:hover,
.main-signup-header .row>div:first-child .sp-container button:hover {
    background-color: #375694;
    color: #fff
}

.sp-container .main-signup-header .row>div:first-child button:hover {
    background-color: #375694;
    color: #fff
}

.main-signup-header .row>div:first-child .btn:focus,
.main-signup-header .row>div:first-child .sp-container button:focus {
    background-color: #375694;
    color: #fff
}

.sp-container .main-signup-header .row>div:first-child button:focus {
    background-color: #375694;
    color: #fff
}

.main-signup-footer p {
    color: #a5a0b1;
    margin-bottom: 0
}

.main-signup-footer a {
    color: #14112d;
    font-weight: 700
}

.main-signup-footer a:focus,
.main-signup-footer a:hover {
    color: #078586
}

.construction .form-control {
    height: 39px;
    padding: .375rem 1rem
}

.construction .btn.btn-icon {
    border: 0;
    text-align: center;
    padding: 0;
    background: 0 0;
    font-size: 20px;
    color: rgba(0, 0, 0, .9);
    margin: 0 5px;
    border-radius: 3px
}

.image,
.login {
    min-height: 100vh
}

.construction .form-control {
    border-radius: 4px;
    width: 100%
}

.construction .input-group-append,
.construction .input-group-prepend {
    position: absolute;
    right: 0
}

.construction .input-group>.input-group-append>.btn {
    border-radius: 0 4px 4px 0;
    margin: 0;
    padding: 0 25px;
    height: 38px;
    line-height: 39px;
    float: right;
    border: 0;
    font-size: 14px;
    color: #fff;
    box-shadow: none;
    position: relative;
    overflow: hidden;
    z-index: 99
}

.construction .input-group>.custom-select:not(:last-child),
.construction .input-group>.form-control:not(:last-child) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

@media (max-width:767px) {
    .main-card-signin,
    .main-signup-header {
        padding: 1.5rem;
        border: 1px solid #e1e5ef;
        border-radius: 6px
    }
    .error-page {
        height: 70%;
        width: 70%
    }
}

@media (min-width:768px) {
    .main-card-signin {
        border: 0!important;
        box-shadow: none!important
    }
    .main-signin-header {
        box-shadow: none!important
    }
    .error-page {
        height: 30%;
        width: 30%
    }
}

.main-content-left-invoice {
    display: block;
    padding: 0
}

.main-content-left-invoice .main-content-breadcrumb,
.main-content-left-invoice .main-content-title {
    padding-left: 20px
}

@media (max-width:991.98px) {
    .main-content-left-invoice {
        width: 100%
    }
}

@media (min-width:992px) {
    .main-content-left-invoice {
        border-right: 1px solid #e3e8f7
    }
}

@media (min-width:992px) {
    .main-content-left-invoice .main-content-breadcrumb,
    .main-content-left-invoice .main-content-title {
        padding-left: 0
    }
}

.main-invoice-list {
    position: relative;
    border-top: 1px solid #e3e8f7
}

.main-invoice-list .media {
    align-items: center;
    border: 1px solid transparent;
    border-right-width: 0;
    padding: 10px 20px;
    position: relative
}

.main-invoice-list .media:focus,
.main-invoice-list .media:hover {
    cursor: pointer;
    background-color: #ecf0fa
}

.main-invoice-list .media+.media {
    margin-top: -1px
}

.main-invoice-list .media+.media::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 55px;
    right: 0;
    border-top: 1px dotted #d0d7e8
}

.main-invoice-list .media-icon {
    width: 24px;
    text-align: center;
    font-size: 32px;
    color: #949eb7
}

.main-invoice-list .media-body {
    margin-left: 15px
}

.main-invoice-list .media-body h6 {
    font-weight: 500;
    color: #242f48;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.main-invoice-list .media-body h6 span:last-child {
    color: #078586
}

.main-invoice-list .media-body div {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.main-invoice-list .media-body p {
    margin-bottom: 0;
    font-size: 11px;
    font-family: Arial, sans-serif;
    color: #364261
}

.main-invoice-list .media-body p span {
    color: #737f9e
}

.main-invoice-list .selected {
    background-color: #ecf0fa;
    border-top-color: #e3e8f7;
    border-bottom-color: #e3e8f7;
    border-left-color: #078586
}

.main-invoice-list .selected:first-child {
    border-top-color: transparent
}

.card-invoice hr {
    border-top: 0!important
}

@media (min-width:992px) {
    .main-invoice-list {
        height: calc(100% - 65px)
    }
}

@media (min-width:992px) {
    .main-invoice-list .media {
        padding: 10px 15px
    }
}

.main-content-body-invoice {
    position: relative
}

@media (min-width:992px) {
    .main-content-body-invoice {
        display: block
    }
}

.card-invoice {
    background-color: transparent;
    border-width: 0
}

.card-invoice .card-body {
    padding: 20px
}

@media (min-width:768px) {
    .invoice-header {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse
    }
}

.invoice-title {
    margin-bottom: 0;
    text-transform: uppercase;
    color: #d0d7e8;
    font-weight: 700
}

.billed-from {
    margin-top: 25px;
    margin-bottom: 25px
}

.billed-from h6 {
    color: #242f48;
    font-size: 14px;
    font-weight: 700
}

.billed-from p {
    font-size: 13px;
    margin-bottom: 0
}

@media (min-width:768px) {
    .billed-from {
        margin-top: 0
    }
}

.billed-to h6 {
    color: #242f48;
    font-size: 14px;
    font-weight: 700
}

.billed-to p {
    font-size: 13px;
    margin-bottom: 0
}

.invoice-info-row {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    margin-bottom: 0;
    font-size: 13px
}

.invoice-info-row+.invoice-info-row {
    border-top: 1px dotted #d0d7e8
}

.invoice-info-row span:first-child {
    color: #4d5875
}

.table-invoice thead>tr>td,
.table-invoice thead>tr>th {
    font-size: 11px;
    font-weight: 700;
    color: #737f9e
}

.table-invoice tbody>tr>td:first-child,
.table-invoice tbody>tr>th:first-child {
    color: #242f48;
    font-weight: 500
}

.table-invoice tbody>tr>td:first-child .invoice-notes,
.table-invoice tbody>tr>th:first-child .invoice-notes {
    margin-right: 20px
}

.table-invoice tbody>tr>td:first-child .invoice-notes .section-label-sm,
.table-invoice tbody>tr>th:first-child .invoice-notes .section-label-sm {
    font-weight: 600
}

.table-invoice tbody>tr>td:first-child .invoice-notes p,
.table-invoice tbody>tr>th:first-child .invoice-notes p {
    font-size: 13px;
    font-weight: 400;
    color: #031b4e
}

.table-invoice.table thead td,
.table-invoice.table thead th {
    padding: 9px 15px
}

.main-content-mail .container,
.main-content-mail .container-fluid {
    padding: 20px 0 0
}

@media (min-width:992px) {
    .main-content-mail .container,
    .main-content-mail .container-fluid {
        padding: 0
    }
}

@media (min-width:992px) {
    .main-content-left-mail {
        display: block
    }
}

.main-content-left-mail .btn-compose {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    padding: 0 20px;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px
}

@media (min-width:992px) {
    .main-content-left-mail .btn-compose {
        margin-right: 0
    }
}

.main-mail-menu {
    position: relative;
    padding-right: 0
}

.main-mail-menu .nav-link {
    height: 38px
}

.main-mail-menu .nav-link i {
    font-size: 22px
}

@media (min-width:992px) {
    .main-mail-menu {
        padding-right: 0
    }
}

@media (min-width:992px) {
    .main-mail-menu .nav-link {
        font-size: 13px
    }
}

.main-mail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 20px
}

.main-mail-header>div:first-child p {
    font-size: 13px;
    margin-bottom: 0
}

.main-mail-header>div:last-child {
    display: none
}

.main-mail-header>div:last-child>span {
    font-size: 12px;
    margin-right: 10px
}

.main-mail-header .btn-group .btn,
.main-mail-header .btn-group .sp-container button {
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    line-height: 0;
    padding: 0;
    position: relative;
    z-index: 1;
    border-color: #d0d7e8;
    background-color: #fff
}

@media (min-width:992px) {
    .main-mail-header {
        padding: 0 0 0 25px;
        margin-bottom: 25px
    }
}

@media (min-width:768px) {
    .main-mail-header>div:last-child {
        display: flex;
        align-items: center
    }
}

.sp-container .main-mail-header .btn-group button {
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    line-height: 0;
    padding: 0;
    position: relative;
    z-index: 1;
    border-color: #d0d7e8;
    background-color: #fff
}

.main-mail-header .btn-group .btn:hover,
.main-mail-header .btn-group .sp-container button:hover {
    color: #242f48;
    background-color: #ecf0fa
}

.sp-container .main-mail-header .btn-group button:hover {
    color: #242f48;
    background-color: #ecf0fa
}

.main-mail-header .btn-group .btn:focus,
.main-mail-header .btn-group .sp-container button:focus {
    color: #242f48;
    background-color: #ecf0fa
}

.sp-container .main-mail-header .btn-group button:focus {
    color: #242f48;
    background-color: #ecf0fa
}

.main-mail-header .btn-group .btn.disabled,
.main-mail-header .btn-group .sp-container button.disabled {
    background-color: #fff;
    color: #d0d7e8;
    border-color: #d0d7e8;
    z-index: 0
}

.sp-container .main-mail-header .btn-group button.disabled {
    background-color: #fff;
    color: #d0d7e8;
    border-color: #d0d7e8;
    z-index: 0
}

.main-mail-header .btn-group .btn.disabled:focus,
.main-mail-header .btn-group .sp-container button.disabled:focus {
    box-shadow: none
}

.sp-container .main-mail-header .btn-group button.disabled:focus {
    box-shadow: none
}

.main-mail-header .btn-group .btn.disabled:active,
.main-mail-header .btn-group .sp-container button.disabled:active {
    box-shadow: none
}

.sp-container .main-mail-header .btn-group button.disabled:active {
    box-shadow: none
}

.main-mail-header .btn-group .btn+.btn,
.main-mail-header .btn-group .sp-container button+.btn {
    margin-left: -2px
}

.main-mail-header .btn-group .sp-container .btn+button,
.main-mail-header .btn-group .sp-container button+button,
.sp-container .main-mail-header .btn-group .btn+button,
.sp-container .main-mail-header .btn-group button+.btn,
.sp-container .main-mail-header .btn-group button+button {
    margin-left: -2px
}

.main-mail-options {
    padding: 5px 10px 5px 20px;
    border: 1px solid #e3e8f7;
    display: none;
    align-items: center;
    justify-content: flex-end
}

.main-mail-options .btn,
.main-mail-options .sp-container button {
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: transparent
}

@media (min-width:992px) {
    .main-mail-options {
        padding-left: 25px;
        display: flex;
        justify-content: space-between
    }
}

.sp-container .main-mail-options button {
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: transparent
}

.main-mail-options .btn:hover,
.main-mail-options .sp-container button:hover {
    background-color: #dde2ef
}

.sp-container .main-mail-options button:hover {
    background-color: #dde2ef
}

.main-mail-options .btn:focus,
.main-mail-options .sp-container button:focus {
    background-color: #dde2ef
}

.sp-container .main-mail-options button:focus {
    background-color: #dde2ef
}

.main-mail-options .btn i,
.main-mail-options .sp-container button i {
    line-height: 0
}

.sp-container .main-mail-options button i {
    line-height: 0
}

.main-mail-options .btn i.typcn,
.main-mail-options .sp-container button i.typcn {
    line-height: .75
}

.sp-container .main-mail-options button i.typcn {
    line-height: .75
}

.main-mail-options .btn.disabled,
.main-mail-options .sp-container button.disabled {
    background-color: transparent;
    color: #737f9e
}

.sp-container .main-mail-options button.disabled {
    background-color: transparent;
    color: #737f9e
}

.main-mail-list {
    border: 1px solid #dde2ef
}

@media (min-width:992px) {
    .main-mail-list {
        border-top-width: 0
    }
}

.main-mail-item {
    padding: 10px 15px;
    border-top: 1px solid #dde2ef;
    border-bottom: 1px solid #dde2ef;
    background-color: #fafcff;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%
}

.main-mail-item+.main-mail-item {
    margin-top: -1px
}

.main-mail-item:first-child {
    border-top-width: 0
}

.main-mail-item .main-avatar,
.main-mail-item .main-img-user {
    flex-shrink: 0;
    margin-right: 15px
}

.main-mail-item:focus,
.main-mail-item:hover {
    background-color: #ecf0fa
}

.main-mail-item.unread {
    background-color: #fff
}

.main-mail-item.selected {
    background-color: #fff
}

@media (min-width:576px) {
    .main-mail-item {
        padding: 10px 20px
    }
}

@media (min-width:992px) {
    .main-mail-item {
        width: auto;
        padding: 10px 25px
    }
}

.main-mail-checkbox {
    margin-right: 15px;
    display: none
}

@media (min-width:992px) {
    .main-mail-checkbox {
        display: block
    }
}

.main-mail-star {
    margin-right: 15px;
    font-size: 18px;
    line-height: .9;
    color: #d0d7e8;
    position: absolute;
    bottom: 10px;
    right: 0
}

.main-mail-star.active {
    color: #fbbc0b
}

@media (min-width:992px) {
    .main-mail-star {
        position: relative;
        bottom: auto;
        top: 2px
    }
}

.main-mail-body {
    width: calc(100% - 80px);
    cursor: pointer
}

@media (min-width:992px) {
    .main-mail-body {
        max-width: 460px;
        margin-right: 15px;
        flex: 1
    }
}

@media (min-width:1200px) {
    .main-mail-body {
        max-width: 640px
    }
}

.main-mail-from {
    font-size: 13px
}

@media (min-width:576px) {
    .main-mail-subject {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%
    }
}

.main-mail-subject strong {
    font-weight: 700;
    font-size: 14px;
    color: #242f48;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%
}

.main-mail-subject span {
    font-size: 13px;
    color: #737f9e;
    display: none
}

@media (min-width:576px) {
    .main-mail-subject strong {
        display: inline;
        width: auto;
        white-space: normal;
        text-overflow: inherit;
        overflow: visible
    }
}

@media (min-width:576px) {
    .main-mail-subject span {
        display: inline
    }
}

.main-mail-attachment {
    margin-right: 15px;
    font-size: 21px;
    line-height: .9;
    display: none
}

@media (min-width:992px) {
    .main-mail-attachment {
        display: block;
        position: absolute;
        right: 63px;
        top: 8px
    }
}

.main-mail-date {
    font-size: 11px;
    position: absolute;
    top: 12px;
    right: 15px;
    color: #949eb7;
    margin-left: auto
}

@media (min-width:992px) {
    .main-mail-date {
        position: absolute;
        font-size: 13px;
        top: 8px
    }
}

.main-mail-compose {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(28, 39, 60, .5);
    z-index: 1000;
    display: none
}

.main-mail-compose>div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%
}

@media (min-width:992px) {
    .main-mail-compose .container {
        padding: 0
    }
}

.main-mail-compose-box {
    box-shadow: 0 0 30px rgba(28, 39, 60, .2);
    border-radius: 3px
}

.main-mail-compose-header {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #242f48;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.main-mail-compose-header .nav-link {
    color: rgba(255, 255, 255, .3);
    font-size: 14px;
    line-height: 1;
    padding: 0;
    transition: all .2s ease-in-out
}

.main-mail-compose-header .nav-link:focus,
.main-mail-compose-header .nav-link:hover {
    color: #fff
}

.main-mail-compose-header .nav-link+.nav-link {
    margin-left: 15px
}

.main-mail-compose-header .nav-link:nth-child(2) {
    display: none
}

@media (min-width:992px) {
    .main-mail-compose-header {
        padding: 20px 25px
    }
}

@media (prefers-reduced-motion:reduce) {
    .main-mail-compose-header .nav-link {
        transition: none
    }
}

@media (min-width:768px) {
    .main-mail-compose-header .nav-link:nth-child(2) {
        display: block
    }
}

.main-mail-compose-body {
    background-color: #fff;
    padding: 20px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.main-mail-compose-body .form-group {
    display: flex;
    align-items: center
}

.main-mail-compose-body .form-group>div {
    flex: 1;
    margin-left: 10px
}

.main-mail-compose-body .form-group .form-label {
    margin: 0;
    color: #242f48
}

.main-mail-compose-body .form-group .form-control {
    border-width: 0;
    border-radius: 0;
    background-color: #ecf0fa
}

.main-mail-compose-body .form-group .form-control:focus {
    box-shadow: none!important
}

.main-mail-compose-body .form-group+.form-group {
    border-top: 1px dotted #d0d7e8;
    padding-top: 1rem
}

.main-mail-compose-body .form-group:last-child {
    display: block
}

.main-mail-compose-body .form-group:last-child .btn,
.main-mail-compose-body .form-group:last-child .sp-container button {
    width: 100%;
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px
}

@media (min-width:992px) {
    .main-mail-compose-body {
        padding: 25px
    }
}

@media (min-width:576px) {
    .main-mail-compose-body .form-group:last-child {
        display: flex;
        justify-content: space-between
    }
}

.sp-container .main-mail-compose-body .form-group:last-child button {
    width: 100%;
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px
}

@media (min-width:576px) {
    .main-mail-compose-body .form-group:last-child .btn,
    .main-mail-compose-body .form-group:last-child .sp-container button {
        width: auto;
        margin-top: 0
    }
    .sp-container .main-mail-compose-body .form-group:last-child button {
        width: auto;
        margin-top: 0
    }
}

.main-mail-compose-body .form-group .nav-link {
    padding: 0;
    font-size: 18px;
    line-height: 0;
    color: #031b4e;
    position: relative;
    transition: all .2s ease-in-out
}

.main-mail-compose-body .form-group .nav-link:focus,
.main-mail-compose-body .form-group .nav-link:hover {
    color: #242f48
}

.main-mail-compose-body .form-group .nav-link+.nav-link {
    margin-left: 15px
}

@media (prefers-reduced-motion:reduce) {
    .main-mail-compose-body .form-group .nav-link {
        transition: none
    }
}

.main-mail-compose-compress,
.main-mail-compose-minimize {
    top: auto;
    left: auto;
    bottom: 0;
    right: 30px;
    width: 560px;
    height: auto;
    background-color: transparent
}

.main-mail-compose-compress .container {
    max-width: none;
    padding: 0
}

.main-mail-compose-minimize .container {
    max-width: none;
    padding: 0
}

.main-mail-compose-minimize .main-mail-compose-body {
    display: none
}

.main-mail-two .main-content {
    display: flex;
    flex-direction: column
}

.main-mail-two .main-header {
    width: 100%;
    border-bottom: 1px solid #e3e8f7
}

.main-mail-two .main-footer {
    width: 100%
}

.main-mail-two .main-header-menu-icon {
    margin-right: 0
}

.main-mail-two .main-content-body {
    display: flex;
    padding: 0
}

.main-mail-left {
    background-color: #f9f9f9;
    width: 240px;
    border-right: 1px solid #b9c2d8;
    padding: 20px;
    display: none
}

.main-mail-left .btn-compose {
    display: block;
    margin-bottom: 20px
}

.main-mail-left .main-mail-menu {
    padding-right: 0
}

@media (min-width:1200px) {
    .main-mail-left {
        display: block
    }
}

.main-mail-content {
    background-color: #fafcff;
    flex: 1;
    max-width: 100vw -480px
}

.main-mail-content .main-mail-header {
    margin-bottom: 0;
    padding: 20px
}

.main-mail-content .main-mail-body {
    max-width: 590px
}

.bg-primary {
    background-color: #078586!important
}

a.bg-primary:focus,
a.bg-primary:hover {
    background-color: #0040ff!important
}

button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #0040ff!important
}

.bg-secondary {
    background-color: #737f9e!important
}

a.bg-secondary:focus,
a.bg-secondary:hover {
    background-color: #5f6d88!important
}

button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: #5f6d88!important
}

.bg-success {
    background-color: #21b18a!important
}

a.bg-success:focus,
a.bg-success:hover {
    background-color: #2a7d01!important
}

button.bg-success:focus,
button.bg-success:hover {
    background-color: #2a7d01!important
}

.bg-info {
    background-color: #00b9ff!important
}

a.bg-info:focus,
a.bg-info:hover {
    background-color: #00b9ff!important
}

button.bg-info:focus,
button.bg-info:hover {
    background-color: #00b9ff!important
}

.bg-warning {
    background-color: #fbbc0b!important
}

a.bg-warning:focus,
a.bg-warning:hover {
    background-color: #d39e00!important
}

button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #d39e00!important
}

.bg-danger {
    background-color: #ee335e!important
}

a.bg-danger:focus,
a.bg-danger:hover {
    background-color: #bd2130!important
}

button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #bd2130!important
}

.bg-purple {
    background-color: #673ab7!important
}

a.bg-purple:focus,
a.bg-purple:hover {
    background-color: #5930a2!important
}

button.bg-purple:focus,
button.bg-purple:hover {
    background-color: #5930a2!important
}

.bg-light {
    background-color: #ecf0fa!important
}

a.bg-light:focus,
a.bg-light:hover {
    background-color: #d5d9e4!important
}

button.bg-light:focus,
button.bg-light:hover {
    background-color: #d5d9e4!important
}

.bg-dark {
    background-color: #364261!important
}

a.bg-dark:focus,
a.bg-dark:hover {
    background-color: #283143!important
}

button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #283143!important
}

.bg-white {
    background-color: #fff!important
}

.bg-transparent {
    background-color: transparent!important
}

.bg-gray+.select2-container--default .select2-selection--single {
    background-color: #455473
}

.bg-gray+.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #d0d7e8
}

.bg-transparent {
    background-color: transparent
}

.bg-gray-100 {
    background-color: #ecf0fa
}

.bg-gray-200 {
    background-color: #dde2ef
}

.bg-gray-300 {
    background-color: #d0d7e8
}

.bg-gray-400 {
    background-color: #b9c2d8
}

.bg-gray-500 {
    background-color: #949eb7
}

.bg-gray-600 {
    background-color: #737f9e
}

.bg-gray-700 {
    background-color: #4d5875
}

.bg-gray-800 {
    background-color: #364261
}

.bg-gray-900 {
    background-color: #242f48
}

.bg-white-1 {
    background-color: rgba(255, 255, 255, .1)
}

.bg-white-2 {
    background-color: rgba(255, 255, 255, .2)
}

.bg-white-3 {
    background-color: rgba(255, 255, 255, .3)
}

.bg-white-4 {
    background-color: rgba(255, 255, 255, .4)
}

.bg-white-5 {
    background-color: rgba(255, 255, 255, .5)
}

.bg-white-6 {
    background-color: rgba(255, 255, 255, .6)
}

.bg-white-7 {
    background-color: rgba(255, 255, 255, .7)
}

.bg-white-8 {
    background-color: rgba(255, 255, 255, .8)
}

.bg-white-9 {
    background-color: rgba(255, 255, 255, .9)
}

.bg-black-1 {
    background-color: rgba(0, 0, 0, .1)
}

.bg-black-2 {
    background-color: rgba(0, 0, 0, .2)
}

.bg-black-3 {
    background-color: rgba(0, 0, 0, .3)
}

.bg-black-4 {
    background-color: rgba(0, 0, 0, .4)
}

.bg-black-5 {
    background-color: rgba(0, 0, 0, .5)
}

.bg-black-6 {
    background-color: rgba(0, 0, 0, .6)
}

.bg-black-7 {
    background-color: rgba(0, 0, 0, .7)
}

.bg-black-8 {
    background-color: rgba(0, 0, 0, .8)
}

.bg-black-9 {
    background: rgba(0, 0, 0, .9)
}

.bg-indigo {
    background: #078586
}

.bg-purple {
    background: #673ab7
}

.bg-pink {
    background: #f10075!important
}

.bg-orange {
    background: #fd7e14
}

.bg-teal {
    background: #0cc!important
}

.bg-purple-dark {
    background: #59339d
}

.bg-transparent {
    background: 0 0
}

.bg-success-transparent {
    background-color: #def6e2!important
}

.bg-danger-transparent {
    background-color: #fce1e7!important
}

.bg-primary-transparent {
    background-color: #d9e8fc!important
}

.bg-secondary-transparent {
    background-color: rgba(250, 92, 124, .13)!important
}

.bg-warning-transparent {
    background-color: #fdf6de!important
}

.bg-pink-transparent {
    background-color: #fee5f1!important
}

.bg-purple-transparent {
    background-color: #e1defe!important
}

.bg-teal-transparent {
    background-color: #d2f5f5!important
}

.bg-info-transparent {
    background-color: rgba(57, 175, 209, .13)!important
}

.bg-orange-transparent {
    background-color: rgba(253, 126, 20, .2)
}

.bg-gradient-blue {
    background-image: linear-gradient(to right, #1976d2 0, #64b5f6 100%)
}

.bg-primary {
    background-color: #078586!important
}

.bg-primary-gradient {
    background-image: linear-gradient(to left, #0db2de 0, #005bea 100%)!important
}

.bg-danger-gradient {
    background-image: linear-gradient(45deg, #f93a5a, #f7778c)!important
}

.bg-success-gradient {
    background-image: linear-gradient(to left, #48d6a8 0, #029666 100%)!important
}

.bg-warning-gradient {
    background-image: linear-gradient(to left, #efa65f, #f76a2d)!important
}

.bg-purple-gradient {
    background-image: linear-gradient(to right, #673ab7 0, #ba7bfb 100%)!important
}

.bg-info-gradient {
    background-image: linear-gradient(to right, #0296ce 0, #38c6f7 100%)!important
}

.bg-secondary-gradient {
    background-image: linear-gradient(to right, #636f8e 0, #acb8da 100%)!important
}

.bg-pink-gradient {
    background-image: linear-gradient(to right, #f10075 0, #f36eae 100%)!important
}

.bg-purple-gradient {
    background-image: linear-gradient(to right, #673ab7 0, #884af1 100%)!important
}

.bg-teal-gradient {
    background-image: linear-gradient(to right, #0cc 0, #96e8e8 100%)!important
}

.border {
    border: 1px solid #e3e8f7!important
}

.border-top {
    border-top: 1px solid #e3e8f7!important
}

.border-right {
    border-right: 1px solid #e3e8f7!important
}

.border-bottom {
    border-bottom: 1px solid #e3e8f7!important
}

.border-left {
    border-left: 1px solid #e3e8f7!important
}

.border-0 {
    border: 0!important
}

.border-top-0 {
    border-top: 0!important
}

.border-right-0 {
    border-right: 0!important
}

.border-bottom-0 {
    border-bottom: 0!important
}

.border-left-0 {
    border-left: 0!important
}

.border-primary {
    border-color: #078586!important
}

.border-secondary {
    border-color: #737f9e!important
}

.border-success {
    border-color: #21b18a!important
}

.border-info {
    border-color: #00b9ff!important
}

.border-warning {
    border-color: #fbbc0b!important
}

.border-danger {
    border-color: #ee335e!important
}

.border-light {
    border-color: #ecf0fa!important
}

.border-dark {
    border-color: #364261!important
}

.border-white {
    border-color: #fff!important
}

.rounded-sm {
    border-radius: .2rem!important
}

.rounded {
    border-radius: 3px!important
}

.rounded-top {
    border-top-left-radius: 3px!important;
    border-top-right-radius: 3px!important
}

.rounded-right {
    border-top-right-radius: 3px!important;
    border-bottom-right-radius: 3px!important
}

.rounded-bottom {
    border-bottom-right-radius: 3px!important;
    border-bottom-left-radius: 3px!important
}

.rounded-left {
    border-top-left-radius: 3px!important;
    border-bottom-left-radius: 3px!important
}

.rounded-lg {
    border-radius: .3rem!important
}

.rounded-circle {
    border-radius: 50%!important
}

.rounded-pill {
    border-radius: 50rem!important
}

.rounded-0 {
    border-radius: 0!important
}

.bd-transparent {
    border-color: transparent
}

.bd {
    border: 1px solid #cdd5ec
}

.bd-t {
    border-top: 1px solid #cdd5ec
}

.bd-r {
    border-right: 1px solid #cdd5ec
}

.bd-b {
    border-bottom: 1px solid #cdd5ec
}

.bd-l {
    border-left: 1px solid #cdd5ec
}

.bd-y {
    border-top: 1px solid #cdd5ec;
    border-bottom: 1px solid #cdd5ec
}

.bd-x {
    border-left: 1px solid #cdd5ec;
    border-right: 1px solid #cdd5ec
}

.bd-0 {
    border-width: 0
}

.bd-1 {
    border-width: 1px
}

.bd-2 {
    border-width: 2px
}

.bd-3 {
    border-width: 3px
}

.bd-4 {
    border-width: 4px
}

.bd-5 {
    border-width: 5px
}

.bd-t-0 {
    border-top: 0
}

.bd-r-0 {
    border-right: 0
}

.bd-b-0 {
    border-bottom: 0
}

.bd-l-0 {
    border-left: 0
}

.bd-t-0-f {
    border-top: 0!important
}

.bd-r-0-f {
    border-right: 0!important
}

.bd-b-0-f {
    border-bottom: 0!important
}

.bd-l-0-f {
    border-left: 0!important
}

.bd-y-0 {
    border-top-width: 0;
    border-bottom-width: 0
}

.bd-y-1 {
    border-top-width: 1px;
    border-bottom-width: 1px
}

.bd-x-0 {
    border-left-width: 0;
    border-right-width: 0
}

.bd-x-1 {
    border-left-width: 1px;
    border-right-width: 1px
}

.bd-primary {
    border-color: #078586!important
}

.bd-success {
    border-color: #21b18a!important
}

.bd-warning {
    border-color: #fbbc0b!important
}

.bd-danger {
    border-color: #ee335e!important
}

.bd-info {
    border-color: #00b9ff!important
}

.bd-white {
    border-color: #fff!important
}

.bd-gray-100 {
    border-color: #ecf0fa
}

.bd-gray-200 {
    border-color: #dde2ef
}

.bd-gray-300 {
    border-color: #d0d7e8
}

.bd-gray-400 {
    border-color: #b9c2d8
}

.bd-gray-500 {
    border-color: #949eb7
}

.bd-gray-600 {
    border-color: #737f9e
}

.bd-gray-700 {
    border-color: #4d5875
}

.bd-gray-800 {
    border-color: #364261
}

.bd-gray-900 {
    border-color: #242f48
}

.bd-white-1 {
    border-color: rgba(255, 255, 255, .1)
}

.bd-white-2 {
    border-color: rgba(255, 255, 255, .2)
}

.bd-white-3 {
    border-color: rgba(255, 255, 255, .3)
}

.bd-white-4 {
    border-color: rgba(255, 255, 255, .4)
}

.bd-white-5 {
    border-color: rgba(255, 255, 255, .5)
}

.bd-white-6 {
    border-color: rgba(255, 255, 255, .6)
}

.bd-white-7 {
    border-color: rgba(255, 255, 255, .7)
}

.bd-white-8 {
    border-color: rgba(255, 255, 255, .8)
}

.rounded-5 {
    border-radius: 5px
}

.rounded-10 {
    border-radius: 10px
}

.rounded-20 {
    border-radius: 20px
}

.rounded-30 {
    border-radius: 30px
}

.rounded-40 {
    border-radius: 40px
}

.rounded-50 {
    border-radius: 50px
}

.bd-dashed {
    border-style: dashed
}

.bd-dotted {
    border-style: dotted
}

@media (min-width:480px) {
    .bd-xs {
        border: 1px solid #e3e8f7
    }
    .bd-xs-t {
        border-top: 1px solid #e3e8f7
    }
    .bd-xs-r {
        border-right: 1px solid #e3e8f7
    }
    .bd-xs-b {
        border-bottom: 1px solid #e3e8f7
    }
    .bd-xs-l {
        border-left: 1px solid #e3e8f7
    }
    .bd-xs-y {
        border-top: 1px solid #e3e8f7;
        border-bottom: 1px solid #e3e8f7
    }
    .bd-xs-x {
        border-left: 1px solid #e3e8f7;
        border-right: 1px solid #e3e8f7
    }
}

@media (min-width:576px) {
    .bd-sm {
        border: 1px solid #e3e8f7
    }
    .bd-sm-t {
        border-top: 1px solid #e3e8f7
    }
    .bd-sm-r {
        border-right: 1px solid #e3e8f7
    }
    .bd-sm-b {
        border-bottom: 1px solid #e3e8f7
    }
    .bd-sm-l {
        border-left: 1px solid #e3e8f7
    }
    .bd-sm-y {
        border-top: 1px solid #e3e8f7;
        border-bottom: 1px solid #e3e8f7
    }
    .bd-sm-x {
        border-left: 1px solid #e3e8f7;
        border-right: 1px solid #e3e8f7
    }
}

@media (min-width:768px) {
    .bd-md {
        border: 1px solid #e3e8f7
    }
    .bd-md-t {
        border-top: 1px solid #e3e8f7
    }
    .bd-md-r {
        border-right: 1px solid #e3e8f7
    }
    .bd-md-b {
        border-bottom: 1px solid #e3e8f7
    }
    .bd-md-l {
        border-left: 1px solid #e3e8f7
    }
    .bd-md-y {
        border-top: 1px solid #e3e8f7;
        border-bottom: 1px solid #e3e8f7
    }
    .bd-md-x {
        border-left: 1px solid #e3e8f7;
        border-right: 1px solid #e3e8f7
    }
}

@media (min-width:992px) {
    .bd-lg {
        border: 1px solid #e3e8f7
    }
    .bd-lg-t {
        border-top: 1px solid #e3e8f7
    }
    .bd-lg-r {
        border-right: 1px solid #e3e8f7
    }
    .bd-lg-b {
        border-bottom: 1px solid #e3e8f7
    }
    .bd-lg-l {
        border-left: 1px solid #e3e8f7
    }
    .bd-lg-y {
        border-top: 1px solid #e3e8f7;
        border-bottom: 1px solid #e3e8f7
    }
    .bd-lg-x {
        border-left: 1px solid #e3e8f7;
        border-right: 1px solid #e3e8f7
    }
}

@media (min-width:1200px) {
    .bd-xl {
        border: 1px solid #e3e8f7
    }
    .bd-xl-t {
        border-top: 1px solid #e3e8f7
    }
    .bd-xl-r {
        border-right: 1px solid #e3e8f7
    }
    .bd-xl-b {
        border-bottom: 1px solid #e3e8f7
    }
    .bd-xl-l {
        border-left: 1px solid #e3e8f7
    }
    .bd-xl-y {
        border-top: 1px solid #e3e8f7;
        border-bottom: 1px solid #e3e8f7
    }
    .bd-xl-x {
        border-left: 1px solid #e3e8f7;
        border-right: 1px solid #e3e8f7
    }
}

.br-tl-0 {
    border-top-left-radius: 0!important
}

.br-bl-0 {
    border-bottom-left-radius: 0!important
}

.br-tr-0 {
    border-top-right-radius: 0!important
}

.br-br-0 {
    border-bottom-right-radius: 0!important
}

.border-left-primary {
    border-left-color: #078586!important
}

.border-left-success {
    border-left-color: #21b18a!important
}

.border-left-warning {
    border-left-color: #fbbc0b!important
}

.border-primary-light {
    border: 1px solid #d4d4f7
}

.border-pink-light {
    border: 1px solid #f3a7cc
}

.border-secondary-light {
    border: 1px solid #f1cbd3
}

.h-25 {
    height: 25%!important
}

.h-50 {
    height: 50%!important
}

.h-75 {
    height: 75%!important
}

.h-100 {
    height: 100%!important
}

.h-auto {
    height: auto!important
}

.mw-100 {
    max-width: 100%!important
}

.mh-100 {
    max-height: 100%!important
}

.min-vw-100 {
    min-width: 100vw!important
}

.min-vh-100 {
    min-height: 100vh!important
}

.vw-100 {
    width: 100vw!important
}

.vh-100 {
    height: 100vh!important
}

.ht-5 {
    height: 5px
}

.ht-5p {
    height: 5%
}

.mx-ht-5p {
    max-height: 5%
}

.mn-ht-5p {
    min-height: 5%
}

.ht-5-f {
    height: 5px!important
}

.ht-5p-f {
    height: 5%!important
}

.ht-10 {
    height: 10px
}

.ht-10p {
    height: 10%
}

.mx-ht-10p {
    max-height: 10%
}

.mn-ht-10p {
    min-height: 10%
}

.ht-10-f {
    height: 10px!important
}

.ht-10p-f {
    height: 10%!important
}

.ht-15 {
    height: 15px
}

.ht-15p {
    height: 15%
}

.mx-ht-15p {
    max-height: 15%
}

.mn-ht-15p {
    min-height: 15%
}

.ht-15-f {
    height: 15px!important
}

.ht-15p-f {
    height: 15%!important
}

.ht-20 {
    height: 20px
}

.ht-20p {
    height: 20%
}

.mx-ht-20p {
    max-height: 20%
}

.mn-ht-20p {
    min-height: 20%
}

.ht-20-f {
    height: 20px!important
}

.ht-20p-f {
    height: 20%!important
}

.ht-25 {
    height: 25px
}

.ht-25p {
    height: 25%
}

.mx-ht-25p {
    max-height: 25%
}

.mn-ht-25p {
    min-height: 25%
}

.ht-25-f {
    height: 25px!important
}

.ht-25p-f {
    height: 25%!important
}

.ht-30 {
    height: 30px
}

.ht-30p {
    height: 30%
}

.mx-ht-30p {
    max-height: 30%
}

.mn-ht-30p {
    min-height: 30%
}

.ht-30-f {
    height: 30px!important
}

.ht-30p-f {
    height: 30%!important
}

.ht-35 {
    height: 35px
}

.ht-35p {
    height: 35%
}

.mx-ht-35p {
    max-height: 35%
}

.mn-ht-35p {
    min-height: 35%
}

.ht-35-f {
    height: 35px!important
}

.ht-35p-f {
    height: 35%!important
}

.ht-40 {
    height: 40px
}

.ht-40p {
    height: 40%
}

.mx-ht-40p {
    max-height: 40%
}

.mn-ht-40p {
    min-height: 40%
}

.ht-40-f {
    height: 40px!important
}

.ht-40p-f {
    height: 40%!important
}

.ht-45 {
    height: 45px
}

.ht-45p {
    height: 45%
}

.mx-ht-45p {
    max-height: 45%
}

.mn-ht-45p {
    min-height: 45%
}

.ht-45-f {
    height: 45px!important
}

.ht-45p-f {
    height: 45%!important
}

.ht-50 {
    height: 50px
}

.ht-50p {
    height: 50%
}

.mx-ht-50p {
    max-height: 50%
}

.mn-ht-50p {
    min-height: 50%
}

.ht-50-f {
    height: 50px!important
}

.ht-50p-f {
    height: 50%!important
}

.ht-55 {
    height: 55px
}

.ht-55p {
    height: 55%
}

.mx-ht-55p {
    max-height: 55%
}

.mn-ht-55p {
    min-height: 55%
}

.ht-55-f {
    height: 55px!important
}

.ht-55p-f {
    height: 55%!important
}

.ht-60 {
    height: 60px
}

.ht-60p {
    height: 60%
}

.mx-ht-60p {
    max-height: 60%
}

.mn-ht-60p {
    min-height: 60%
}

.ht-60-f {
    height: 60px!important
}

.ht-60p-f {
    height: 60%!important
}

.ht-65 {
    height: 65px
}

.ht-65p {
    height: 65%
}

.mx-ht-65p {
    max-height: 65%
}

.mn-ht-65p {
    min-height: 65%
}

.ht-65-f {
    height: 65px!important
}

.ht-65p-f {
    height: 65%!important
}

.ht-70 {
    height: 70px
}

.ht-70p {
    height: 70%
}

.mx-ht-70p {
    max-height: 70%
}

.mn-ht-70p {
    min-height: 70%
}

.ht-70-f {
    height: 70px!important
}

.ht-70p-f {
    height: 70%!important
}

.ht-75 {
    height: 75px
}

.ht-75p {
    height: 75%
}

.mx-ht-75p {
    max-height: 75%
}

.mn-ht-75p {
    min-height: 75%
}

.ht-75-f {
    height: 75px!important
}

.ht-75p-f {
    height: 75%!important
}

.ht-80 {
    height: 80px
}

.ht-80p {
    height: 80%
}

.mx-ht-80p {
    max-height: 80%
}

.mn-ht-80p {
    min-height: 80%
}

.ht-80-f {
    height: 80px!important
}

.ht-80p-f {
    height: 80%!important
}

.ht-85 {
    height: 85px
}

.ht-85p {
    height: 85%
}

.mx-ht-85p {
    max-height: 85%
}

.mn-ht-85p {
    min-height: 85%
}

.ht-85-f {
    height: 85px!important
}

.ht-85p-f {
    height: 85%!important
}

.ht-90 {
    height: 90px
}

.ht-90p {
    height: 90%
}

.mx-ht-90p {
    max-height: 90%
}

.mn-ht-90p {
    min-height: 90%
}

.ht-90-f {
    height: 90px!important
}

.ht-90p-f {
    height: 90%!important
}

.ht-95 {
    height: 95px
}

.ht-95p {
    height: 95%
}

.mx-ht-95p {
    max-height: 95%
}

.mn-ht-95p {
    min-height: 95%
}

.ht-95-f {
    height: 95px!important
}

.ht-95p-f {
    height: 95%!important
}

.ht-100 {
    height: 100px!important
}

.ht-100p {
    height: 100%
}

.mx-ht-100p {
    max-height: 100%
}

.mn-ht-100p {
    min-height: 100%
}

.ht-100-f {
    height: 100px!important
}

.ht-100p-f {
    height: 100%!important
}

.ht-136 {
    height: 136px
}

.ht-150 {
    height: 150px
}

.ht-175 {
    height: 175px
}

.ht-180 {
    height: 180px
}

.ht-200 {
    height: 200px
}

.ht-210 {
    height: 210px
}

.ht-220 {
    height: 220px
}

.ht-226 {
    height: 226px
}

.ht-230 {
    height: 230px!important
}

.ht-238 {
    height: 238px!important
}

.wd-230 {
    width: 230px!important
}

.ht-250 {
    height: 250px
}

.ht-270 {
    height: 270px
}

.ht-300 {
    height: 300px
}

.ht-350 {
    height: 350px
}

.ht-400 {
    height: 400px
}

.ht-450 {
    height: 450px
}

.ht-500 {
    height: 500px
}

.ht-550 {
    height: 550px
}

.ht-600 {
    height: 600px
}

.ht-650 {
    height: 650px
}

.ht-700 {
    height: 700px
}

.ht-750 {
    height: 750px
}

.ht-800 {
    height: 800px
}

.ht-100v {
    height: 100vh
}

.ht-1 {
    height: 1px
}

.ht-2 {
    height: 2px
}

.ht-3 {
    height: 3px
}

.ht-4 {
    height: 4px
}

.ht-6 {
    height: 6px
}

.ht-7 {
    height: 7px
}

.ht-8 {
    height: 8px
}

.ht-9 {
    height: 9px
}

.ht-auto {
    height: auto
}

.ht-16 {
    height: 16px
}

.ht-32 {
    height: 32px
}

.ht-36 {
    height: 36px
}

.ht-48 {
    height: 48px
}

.ht-64 {
    height: 64px
}

.ht-72 {
    height: 72px
}

.ht-115 {
    height: 115px
}

.ht-120 {
    height: 120px
}

.ht-160 {
    height: 160px
}

@media (min-width:480px) {
    .ht-xs-5 {
        height: 5px
    }
    .ht-xs-5p {
        height: 5%
    }
    .mx-ht-xs-5p {
        max-height: 5%
    }
    .mn-ht-xs-5p {
        min-height: 5%
    }
    .ht-xs-5-f {
        height: 5px!important
    }
    .ht-xs-5p-f {
        height: 5%!important
    }
    .ht-xs-10 {
        height: 10px
    }
    .ht-xs-10p {
        height: 10%
    }
    .mx-ht-xs-10p {
        max-height: 10%
    }
    .mn-ht-xs-10p {
        min-height: 10%
    }
    .ht-xs-10-f {
        height: 10px!important
    }
    .ht-xs-10p-f {
        height: 10%!important
    }
    .ht-xs-15 {
        height: 15px
    }
    .ht-xs-15p {
        height: 15%
    }
    .mx-ht-xs-15p {
        max-height: 15%
    }
    .mn-ht-xs-15p {
        min-height: 15%
    }
    .ht-xs-15-f {
        height: 15px!important
    }
    .ht-xs-15p-f {
        height: 15%!important
    }
    .ht-xs-20 {
        height: 20px
    }
    .ht-xs-20p {
        height: 20%
    }
    .mx-ht-xs-20p {
        max-height: 20%
    }
    .mn-ht-xs-20p {
        min-height: 20%
    }
    .ht-xs-20-f {
        height: 20px!important
    }
    .ht-xs-20p-f {
        height: 20%!important
    }
    .ht-xs-25 {
        height: 25px
    }
    .ht-xs-25p {
        height: 25%
    }
    .mx-ht-xs-25p {
        max-height: 25%
    }
    .mn-ht-xs-25p {
        min-height: 25%
    }
    .ht-xs-25-f {
        height: 25px!important
    }
    .ht-xs-25p-f {
        height: 25%!important
    }
    .ht-xs-30 {
        height: 30px
    }
    .ht-xs-30p {
        height: 30%
    }
    .mx-ht-xs-30p {
        max-height: 30%
    }
    .mn-ht-xs-30p {
        min-height: 30%
    }
    .ht-xs-30-f {
        height: 30px!important
    }
    .ht-xs-30p-f {
        height: 30%!important
    }
    .ht-xs-35 {
        height: 35px
    }
    .ht-xs-35p {
        height: 35%
    }
    .mx-ht-xs-35p {
        max-height: 35%
    }
    .mn-ht-xs-35p {
        min-height: 35%
    }
    .ht-xs-35-f {
        height: 35px!important
    }
    .ht-xs-35p-f {
        height: 35%!important
    }
    .ht-xs-40 {
        height: 40px
    }
    .ht-xs-40p {
        height: 40%
    }
    .mx-ht-xs-40p {
        max-height: 40%
    }
    .mn-ht-xs-40p {
        min-height: 40%
    }
    .ht-xs-40-f {
        height: 40px!important
    }
    .ht-xs-40p-f {
        height: 40%!important
    }
    .ht-xs-45 {
        height: 45px
    }
    .ht-xs-45p {
        height: 45%
    }
    .mx-ht-xs-45p {
        max-height: 45%
    }
    .mn-ht-xs-45p {
        min-height: 45%
    }
    .ht-xs-45-f {
        height: 45px!important
    }
    .ht-xs-45p-f {
        height: 45%!important
    }
    .ht-xs-50 {
        height: 50px
    }
    .ht-xs-50p {
        height: 50%
    }
    .mx-ht-xs-50p {
        max-height: 50%
    }
    .mn-ht-xs-50p {
        min-height: 50%
    }
    .ht-xs-50-f {
        height: 50px!important
    }
    .ht-xs-50p-f {
        height: 50%!important
    }
    .ht-xs-55 {
        height: 55px
    }
    .ht-xs-55p {
        height: 55%
    }
    .mx-ht-xs-55p {
        max-height: 55%
    }
    .mn-ht-xs-55p {
        min-height: 55%
    }
    .ht-xs-55-f {
        height: 55px!important
    }
    .ht-xs-55p-f {
        height: 55%!important
    }
    .ht-xs-60 {
        height: 60px
    }
    .ht-xs-60p {
        height: 60%
    }
    .mx-ht-xs-60p {
        max-height: 60%
    }
    .mn-ht-xs-60p {
        min-height: 60%
    }
    .ht-xs-60-f {
        height: 60px!important
    }
    .ht-xs-60p-f {
        height: 60%!important
    }
    .ht-xs-65 {
        height: 65px
    }
    .ht-xs-65p {
        height: 65%
    }
    .mx-ht-xs-65p {
        max-height: 65%
    }
    .mn-ht-xs-65p {
        min-height: 65%
    }
    .ht-xs-65-f {
        height: 65px!important
    }
    .ht-xs-65p-f {
        height: 65%!important
    }
    .ht-xs-70 {
        height: 70px
    }
    .ht-xs-70p {
        height: 70%
    }
    .mx-ht-xs-70p {
        max-height: 70%
    }
    .mn-ht-xs-70p {
        min-height: 70%
    }
    .ht-xs-70-f {
        height: 70px!important
    }
    .ht-xs-70p-f {
        height: 70%!important
    }
    .ht-xs-75 {
        height: 75px
    }
    .ht-xs-75p {
        height: 75%
    }
    .mx-ht-xs-75p {
        max-height: 75%
    }
    .mn-ht-xs-75p {
        min-height: 75%
    }
    .ht-xs-75-f {
        height: 75px!important
    }
    .ht-xs-75p-f {
        height: 75%!important
    }
    .ht-xs-80 {
        height: 80px
    }
    .ht-xs-80p {
        height: 80%
    }
    .mx-ht-xs-80p {
        max-height: 80%
    }
    .mn-ht-xs-80p {
        min-height: 80%
    }
    .ht-xs-80-f {
        height: 80px!important
    }
    .ht-xs-80p-f {
        height: 80%!important
    }
    .ht-xs-85 {
        height: 85px
    }
    .ht-xs-85p {
        height: 85%
    }
    .mx-ht-xs-85p {
        max-height: 85%
    }
    .mn-ht-xs-85p {
        min-height: 85%
    }
    .ht-xs-85-f {
        height: 85px!important
    }
    .ht-xs-85p-f {
        height: 85%!important
    }
    .ht-xs-90 {
        height: 90px
    }
    .ht-xs-90p {
        height: 90%
    }
    .mx-ht-xs-90p {
        max-height: 90%
    }
    .mn-ht-xs-90p {
        min-height: 90%
    }
    .ht-xs-90-f {
        height: 90px!important
    }
    .ht-xs-90p-f {
        height: 90%!important
    }
    .ht-xs-95 {
        height: 95px
    }
    .ht-xs-95p {
        height: 95%
    }
    .mx-ht-xs-95p {
        max-height: 95%
    }
    .mn-ht-xs-95p {
        min-height: 95%
    }
    .ht-xs-95-f {
        height: 95px!important
    }
    .ht-xs-95p-f {
        height: 95%!important
    }
    .ht-xs-100 {
        height: 100px
    }
    .ht-xs-100p {
        height: 100%
    }
    .mx-ht-xs-100p {
        max-height: 100%
    }
    .mn-ht-xs-100p {
        min-height: 100%
    }
    .ht-xs-100-f {
        height: 100px!important
    }
    .ht-xs-100p-f {
        height: 100%!important
    }
    .ht-xs-150 {
        height: 150px
    }
    .ht-xs-150p {
        height: 150%
    }
    .mx-ht-xs-150p {
        max-height: 150%
    }
    .mn-ht-xs-150p {
        min-height: 150%
    }
    .ht-xs-150-f {
        height: 150px!important
    }
    .ht-xs-150p-f {
        height: 150%!important
    }
    .ht-xs-200 {
        height: 200px
    }
    .ht-xs-200p {
        height: 200%
    }
    .mx-ht-xs-200p {
        max-height: 200%
    }
    .mn-ht-xs-200p {
        min-height: 200%
    }
    .ht-xs-200-f {
        height: 200px!important
    }
    .ht-xs-200p-f {
        height: 200%!important
    }
    .ht-xs-250 {
        height: 250px
    }
    .ht-xs-250p {
        height: 250%
    }
    .mx-ht-xs-250p {
        max-height: 250%
    }
    .mn-ht-xs-250p {
        min-height: 250%
    }
    .ht-xs-250-f {
        height: 250px!important
    }
    .ht-xs-250p-f {
        height: 250%!important
    }
    .ht-xs-300 {
        height: 300px
    }
    .ht-xs-300p {
        height: 300%
    }
    .mx-ht-xs-300p {
        max-height: 300%
    }
    .mn-ht-xs-300p {
        min-height: 300%
    }
    .ht-xs-300-f {
        height: 300px!important
    }
    .ht-xs-300p-f {
        height: 300%!important
    }
    .ht-xs-350 {
        height: 350px
    }
    .ht-xs-350p {
        height: 350%
    }
    .mx-ht-xs-350p {
        max-height: 350%
    }
    .mn-ht-xs-350p {
        min-height: 350%
    }
    .ht-xs-350-f {
        height: 350px!important
    }
    .ht-xs-350p-f {
        height: 350%!important
    }
    .ht-xs-400 {
        height: 400px
    }
    .ht-xs-400p {
        height: 400%
    }
    .mx-ht-xs-400p {
        max-height: 400%
    }
    .mn-ht-xs-400p {
        min-height: 400%
    }
    .ht-xs-400-f {
        height: 400px!important
    }
    .ht-xs-400p-f {
        height: 400%!important
    }
    .ht-xs-450 {
        height: 450px
    }
    .ht-xs-450p {
        height: 450%
    }
    .mx-ht-xs-450p {
        max-height: 450%
    }
    .mn-ht-xs-450p {
        min-height: 450%
    }
    .ht-xs-450-f {
        height: 450px!important
    }
    .ht-xs-450p-f {
        height: 450%!important
    }
    .ht-xs-500 {
        height: 500px
    }
    .ht-xs-500p {
        height: 500%
    }
    .mx-ht-xs-500p {
        max-height: 500%
    }
    .mn-ht-xs-500p {
        min-height: 500%
    }
    .ht-xs-500-f {
        height: 500px!important
    }
    .ht-xs-500p-f {
        height: 500%!important
    }
    .ht-xs-550 {
        height: 550px
    }
    .ht-xs-550p {
        height: 550%
    }
    .mx-ht-xs-550p {
        max-height: 550%
    }
    .mn-ht-xs-550p {
        min-height: 550%
    }
    .ht-xs-550-f {
        height: 550px!important
    }
    .ht-xs-550p-f {
        height: 550%!important
    }
    .ht-xs-600 {
        height: 600px
    }
    .ht-xs-600p {
        height: 600%
    }
    .mx-ht-xs-600p {
        max-height: 600%
    }
    .mn-ht-xs-600p {
        min-height: 600%
    }
    .ht-xs-600-f {
        height: 600px!important
    }
    .ht-xs-600p-f {
        height: 600%!important
    }
    .ht-xs-650 {
        height: 650px
    }
    .ht-xs-650p {
        height: 650%
    }
    .mx-ht-xs-650p {
        max-height: 650%
    }
    .mn-ht-xs-650p {
        min-height: 650%
    }
    .ht-xs-650-f {
        height: 650px!important
    }
    .ht-xs-650p-f {
        height: 650%!important
    }
    .ht-xs-700 {
        height: 700px
    }
    .ht-xs-700p {
        height: 700%
    }
    .mx-ht-xs-700p {
        max-height: 700%
    }
    .mn-ht-xs-700p {
        min-height: 700%
    }
    .ht-xs-700-f {
        height: 700px!important
    }
    .ht-xs-700p-f {
        height: 700%!important
    }
    .ht-xs-750 {
        height: 750px
    }
    .ht-xs-750p {
        height: 750%
    }
    .mx-ht-xs-750p {
        max-height: 750%
    }
    .mn-ht-xs-750p {
        min-height: 750%
    }
    .ht-xs-750-f {
        height: 750px!important
    }
    .ht-xs-750p-f {
        height: 750%!important
    }
    .ht-xs-800 {
        height: 800px
    }
    .ht-xs-800p {
        height: 800%
    }
    .mx-ht-xs-800p {
        max-height: 800%
    }
    .mn-ht-xs-800p {
        min-height: 800%
    }
    .ht-xs-800-f {
        height: 800px!important
    }
    .ht-xs-800p-f {
        height: 800%!important
    }
    .ht-xs-850 {
        height: 850px
    }
    .ht-xs-850p {
        height: 850%
    }
    .mx-ht-xs-850p {
        max-height: 850%
    }
    .mn-ht-xs-850p {
        min-height: 850%
    }
    .ht-xs-850-f {
        height: 850px!important
    }
    .ht-xs-850p-f {
        height: 850%!important
    }
    .ht-xs-100v {
        height: 100vh
    }
}

@media (min-width:576px) {
    .ht-sm-5 {
        height: 5px
    }
    .ht-sm-5p {
        height: 5%
    }
    .mx-ht-sm-5p {
        max-height: 5%
    }
    .mn-ht-sm-5p {
        min-height: 5%
    }
    .ht-sm-5-f {
        height: 5px!important
    }
    .ht-sm-5p-f {
        height: 5%!important
    }
    .ht-sm-10 {
        height: 10px
    }
    .ht-sm-10p {
        height: 10%
    }
    .mx-ht-sm-10p {
        max-height: 10%
    }
    .mn-ht-sm-10p {
        min-height: 10%
    }
    .ht-sm-10-f {
        height: 10px!important
    }
    .ht-sm-10p-f {
        height: 10%!important
    }
    .ht-sm-15 {
        height: 15px
    }
    .ht-sm-15p {
        height: 15%
    }
    .mx-ht-sm-15p {
        max-height: 15%
    }
    .mn-ht-sm-15p {
        min-height: 15%
    }
    .ht-sm-15-f {
        height: 15px!important
    }
    .ht-sm-15p-f {
        height: 15%!important
    }
    .ht-sm-20 {
        height: 20px
    }
    .ht-sm-20p {
        height: 20%
    }
    .mx-ht-sm-20p {
        max-height: 20%
    }
    .mn-ht-sm-20p {
        min-height: 20%
    }
    .ht-sm-20-f {
        height: 20px!important
    }
    .ht-sm-20p-f {
        height: 20%!important
    }
    .ht-sm-25 {
        height: 25px
    }
    .ht-sm-25p {
        height: 25%
    }
    .mx-ht-sm-25p {
        max-height: 25%
    }
    .mn-ht-sm-25p {
        min-height: 25%
    }
    .ht-sm-25-f {
        height: 25px!important
    }
    .ht-sm-25p-f {
        height: 25%!important
    }
    .ht-sm-30 {
        height: 30px
    }
    .ht-sm-30p {
        height: 30%
    }
    .mx-ht-sm-30p {
        max-height: 30%
    }
    .mn-ht-sm-30p {
        min-height: 30%
    }
    .ht-sm-30-f {
        height: 30px!important
    }
    .ht-sm-30p-f {
        height: 30%!important
    }
    .ht-sm-35 {
        height: 35px
    }
    .ht-sm-35p {
        height: 35%
    }
    .mx-ht-sm-35p {
        max-height: 35%
    }
    .mn-ht-sm-35p {
        min-height: 35%
    }
    .ht-sm-35-f {
        height: 35px!important
    }
    .ht-sm-35p-f {
        height: 35%!important
    }
    .ht-sm-40 {
        height: 40px
    }
    .ht-sm-40p {
        height: 40%
    }
    .mx-ht-sm-40p {
        max-height: 40%
    }
    .mn-ht-sm-40p {
        min-height: 40%
    }
    .ht-sm-40-f {
        height: 40px!important
    }
    .ht-sm-40p-f {
        height: 40%!important
    }
    .ht-sm-45 {
        height: 45px
    }
    .ht-sm-45p {
        height: 45%
    }
    .mx-ht-sm-45p {
        max-height: 45%
    }
    .mn-ht-sm-45p {
        min-height: 45%
    }
    .ht-sm-45-f {
        height: 45px!important
    }
    .ht-sm-45p-f {
        height: 45%!important
    }
    .ht-sm-50 {
        height: 50px
    }
    .ht-sm-50p {
        height: 50%
    }
    .mx-ht-sm-50p {
        max-height: 50%
    }
    .mn-ht-sm-50p {
        min-height: 50%
    }
    .ht-sm-50-f {
        height: 50px!important
    }
    .ht-sm-50p-f {
        height: 50%!important
    }
    .ht-sm-55 {
        height: 55px
    }
    .ht-sm-55p {
        height: 55%
    }
    .mx-ht-sm-55p {
        max-height: 55%
    }
    .mn-ht-sm-55p {
        min-height: 55%
    }
    .ht-sm-55-f {
        height: 55px!important
    }
    .ht-sm-55p-f {
        height: 55%!important
    }
    .ht-sm-60 {
        height: 60px
    }
    .ht-sm-60p {
        height: 60%
    }
    .mx-ht-sm-60p {
        max-height: 60%
    }
    .mn-ht-sm-60p {
        min-height: 60%
    }
    .ht-sm-60-f {
        height: 60px!important
    }
    .ht-sm-60p-f {
        height: 60%!important
    }
    .ht-sm-65 {
        height: 65px
    }
    .ht-sm-65p {
        height: 65%
    }
    .mx-ht-sm-65p {
        max-height: 65%
    }
    .mn-ht-sm-65p {
        min-height: 65%
    }
    .ht-sm-65-f {
        height: 65px!important
    }
    .ht-sm-65p-f {
        height: 65%!important
    }
    .ht-sm-70 {
        height: 70px
    }
    .ht-sm-70p {
        height: 70%
    }
    .mx-ht-sm-70p {
        max-height: 70%
    }
    .mn-ht-sm-70p {
        min-height: 70%
    }
    .ht-sm-70-f {
        height: 70px!important
    }
    .ht-sm-70p-f {
        height: 70%!important
    }
    .ht-sm-75 {
        height: 75px
    }
    .ht-sm-75p {
        height: 75%
    }
    .mx-ht-sm-75p {
        max-height: 75%
    }
    .mn-ht-sm-75p {
        min-height: 75%
    }
    .ht-sm-75-f {
        height: 75px!important
    }
    .ht-sm-75p-f {
        height: 75%!important
    }
    .ht-sm-80 {
        height: 80px
    }
    .ht-sm-80p {
        height: 80%
    }
    .mx-ht-sm-80p {
        max-height: 80%
    }
    .mn-ht-sm-80p {
        min-height: 80%
    }
    .ht-sm-80-f {
        height: 80px!important
    }
    .ht-sm-80p-f {
        height: 80%!important
    }
    .ht-sm-85 {
        height: 85px
    }
    .ht-sm-85p {
        height: 85%
    }
    .mx-ht-sm-85p {
        max-height: 85%
    }
    .mn-ht-sm-85p {
        min-height: 85%
    }
    .ht-sm-85-f {
        height: 85px!important
    }
    .ht-sm-85p-f {
        height: 85%!important
    }
    .ht-sm-90 {
        height: 90px
    }
    .ht-sm-90p {
        height: 90%
    }
    .mx-ht-sm-90p {
        max-height: 90%
    }
    .mn-ht-sm-90p {
        min-height: 90%
    }
    .ht-sm-90-f {
        height: 90px!important
    }
    .ht-sm-90p-f {
        height: 90%!important
    }
    .ht-sm-95 {
        height: 95px
    }
    .ht-sm-95p {
        height: 95%
    }
    .mx-ht-sm-95p {
        max-height: 95%
    }
    .mn-ht-sm-95p {
        min-height: 95%
    }
    .ht-sm-95-f {
        height: 95px!important
    }
    .ht-sm-95p-f {
        height: 95%!important
    }
    .ht-sm-100 {
        height: 100px
    }
    .ht-sm-100p {
        height: 100%
    }
    .mx-ht-sm-100p {
        max-height: 100%
    }
    .mn-ht-sm-100p {
        min-height: 100%
    }
    .ht-sm-100-f {
        height: 100px!important
    }
    .ht-sm-100p-f {
        height: 100%!important
    }
    .ht-sm-150 {
        height: 150px
    }
    .ht-sm-150p {
        height: 150%
    }
    .mx-ht-sm-150p {
        max-height: 150%
    }
    .mn-ht-sm-150p {
        min-height: 150%
    }
    .ht-sm-150-f {
        height: 150px!important
    }
    .ht-sm-150p-f {
        height: 150%!important
    }
    .ht-sm-200 {
        height: 200px
    }
    .ht-sm-200p {
        height: 200%
    }
    .mx-ht-sm-200p {
        max-height: 200%
    }
    .mn-ht-sm-200p {
        min-height: 200%
    }
    .ht-sm-200-f {
        height: 200px!important
    }
    .ht-sm-200p-f {
        height: 200%!important
    }
    .ht-sm-250 {
        height: 250px
    }
    .ht-sm-250p {
        height: 250%
    }
    .mx-ht-sm-250p {
        max-height: 250%
    }
    .mn-ht-sm-250p {
        min-height: 250%
    }
    .ht-sm-250-f {
        height: 250px!important
    }
    .ht-sm-250p-f {
        height: 250%!important
    }
    .ht-sm-300 {
        height: 300px
    }
    .ht-sm-300p {
        height: 300%
    }
    .mx-ht-sm-300p {
        max-height: 300%
    }
    .mn-ht-sm-300p {
        min-height: 300%
    }
    .ht-sm-300-f {
        height: 300px!important
    }
    .ht-sm-300p-f {
        height: 300%!important
    }
    .ht-sm-350 {
        height: 350px
    }
    .ht-sm-350p {
        height: 350%
    }
    .mx-ht-sm-350p {
        max-height: 350%
    }
    .mn-ht-sm-350p {
        min-height: 350%
    }
    .ht-sm-350-f {
        height: 350px!important
    }
    .ht-sm-350p-f {
        height: 350%!important
    }
    .ht-sm-400 {
        height: 400px
    }
    .ht-sm-400p {
        height: 400%
    }
    .mx-ht-sm-400p {
        max-height: 400%
    }
    .mn-ht-sm-400p {
        min-height: 400%
    }
    .ht-sm-400-f {
        height: 400px!important
    }
    .ht-sm-400p-f {
        height: 400%!important
    }
    .ht-sm-450 {
        height: 450px
    }
    .ht-sm-450p {
        height: 450%
    }
    .mx-ht-sm-450p {
        max-height: 450%
    }
    .mn-ht-sm-450p {
        min-height: 450%
    }
    .ht-sm-450-f {
        height: 450px!important
    }
    .ht-sm-450p-f {
        height: 450%!important
    }
    .ht-sm-500 {
        height: 500px
    }
    .ht-sm-500p {
        height: 500%
    }
    .mx-ht-sm-500p {
        max-height: 500%
    }
    .mn-ht-sm-500p {
        min-height: 500%
    }
    .ht-sm-500-f {
        height: 500px!important
    }
    .ht-sm-500p-f {
        height: 500%!important
    }
    .ht-sm-550 {
        height: 550px
    }
    .ht-sm-550p {
        height: 550%
    }
    .mx-ht-sm-550p {
        max-height: 550%
    }
    .mn-ht-sm-550p {
        min-height: 550%
    }
    .ht-sm-550-f {
        height: 550px!important
    }
    .ht-sm-550p-f {
        height: 550%!important
    }
    .ht-sm-600 {
        height: 600px
    }
    .ht-sm-600p {
        height: 600%
    }
    .mx-ht-sm-600p {
        max-height: 600%
    }
    .mn-ht-sm-600p {
        min-height: 600%
    }
    .ht-sm-600-f {
        height: 600px!important
    }
    .ht-sm-600p-f {
        height: 600%!important
    }
    .ht-sm-650 {
        height: 650px
    }
    .ht-sm-650p {
        height: 650%
    }
    .mx-ht-sm-650p {
        max-height: 650%
    }
    .mn-ht-sm-650p {
        min-height: 650%
    }
    .ht-sm-650-f {
        height: 650px!important
    }
    .ht-sm-650p-f {
        height: 650%!important
    }
    .ht-sm-700 {
        height: 700px
    }
    .ht-sm-700p {
        height: 700%
    }
    .mx-ht-sm-700p {
        max-height: 700%
    }
    .mn-ht-sm-700p {
        min-height: 700%
    }
    .ht-sm-700-f {
        height: 700px!important
    }
    .ht-sm-700p-f {
        height: 700%!important
    }
    .ht-sm-750 {
        height: 750px
    }
    .ht-sm-750p {
        height: 750%
    }
    .mx-ht-sm-750p {
        max-height: 750%
    }
    .mn-ht-sm-750p {
        min-height: 750%
    }
    .ht-sm-750-f {
        height: 750px!important
    }
    .ht-sm-750p-f {
        height: 750%!important
    }
    .ht-sm-800 {
        height: 800px
    }
    .ht-sm-800p {
        height: 800%
    }
    .mx-ht-sm-800p {
        max-height: 800%
    }
    .mn-ht-sm-800p {
        min-height: 800%
    }
    .ht-sm-800-f {
        height: 800px!important
    }
    .ht-sm-800p-f {
        height: 800%!important
    }
    .ht-sm-850 {
        height: 850px
    }
    .ht-sm-850p {
        height: 850%
    }
    .mx-ht-sm-850p {
        max-height: 850%
    }
    .mn-ht-sm-850p {
        min-height: 850%
    }
    .ht-sm-850-f {
        height: 850px!important
    }
    .ht-sm-850p-f {
        height: 850%!important
    }
    .ht-sm-100v {
        height: 100vh
    }
}

@media (min-width:768px) {
    .ht-md-5 {
        height: 5px
    }
    .ht-md-5p {
        height: 5%
    }
    .mx-ht-md-5p {
        max-height: 5%
    }
    .mn-ht-md-5p {
        min-height: 5%
    }
    .ht-md-5-f {
        height: 5px!important
    }
    .ht-md-5p-f {
        height: 5%!important
    }
    .ht-md-10 {
        height: 10px
    }
    .ht-md-10p {
        height: 10%
    }
    .mx-ht-md-10p {
        max-height: 10%
    }
    .mn-ht-md-10p {
        min-height: 10%
    }
    .ht-md-10-f {
        height: 10px!important
    }
    .ht-md-10p-f {
        height: 10%!important
    }
    .ht-md-15 {
        height: 15px
    }
    .ht-md-15p {
        height: 15%
    }
    .mx-ht-md-15p {
        max-height: 15%
    }
    .mn-ht-md-15p {
        min-height: 15%
    }
    .ht-md-15-f {
        height: 15px!important
    }
    .ht-md-15p-f {
        height: 15%!important
    }
    .ht-md-20 {
        height: 20px
    }
    .ht-md-20p {
        height: 20%
    }
    .mx-ht-md-20p {
        max-height: 20%
    }
    .mn-ht-md-20p {
        min-height: 20%
    }
    .ht-md-20-f {
        height: 20px!important
    }
    .ht-md-20p-f {
        height: 20%!important
    }
    .ht-md-25 {
        height: 25px
    }
    .ht-md-25p {
        height: 25%
    }
    .mx-ht-md-25p {
        max-height: 25%
    }
    .mn-ht-md-25p {
        min-height: 25%
    }
    .ht-md-25-f {
        height: 25px!important
    }
    .ht-md-25p-f {
        height: 25%!important
    }
    .ht-md-30 {
        height: 30px
    }
    .ht-md-30p {
        height: 30%
    }
    .mx-ht-md-30p {
        max-height: 30%
    }
    .mn-ht-md-30p {
        min-height: 30%
    }
    .ht-md-30-f {
        height: 30px!important
    }
    .ht-md-30p-f {
        height: 30%!important
    }
    .ht-md-35 {
        height: 35px
    }
    .ht-md-35p {
        height: 35%
    }
    .mx-ht-md-35p {
        max-height: 35%
    }
    .mn-ht-md-35p {
        min-height: 35%
    }
    .ht-md-35-f {
        height: 35px!important
    }
    .ht-md-35p-f {
        height: 35%!important
    }
    .ht-md-40 {
        height: 40px
    }
    .ht-md-40p {
        height: 40%
    }
    .mx-ht-md-40p {
        max-height: 40%
    }
    .mn-ht-md-40p {
        min-height: 40%
    }
    .ht-md-40-f {
        height: 40px!important
    }
    .ht-md-40p-f {
        height: 40%!important
    }
    .ht-md-45 {
        height: 45px
    }
    .ht-md-45p {
        height: 45%
    }
    .mx-ht-md-45p {
        max-height: 45%
    }
    .mn-ht-md-45p {
        min-height: 45%
    }
    .ht-md-45-f {
        height: 45px!important
    }
    .ht-md-45p-f {
        height: 45%!important
    }
    .ht-md-50 {
        height: 50px
    }
    .ht-md-50p {
        height: 50%
    }
    .mx-ht-md-50p {
        max-height: 50%
    }
    .mn-ht-md-50p {
        min-height: 50%
    }
    .ht-md-50-f {
        height: 50px!important
    }
    .ht-md-50p-f {
        height: 50%!important
    }
    .ht-md-55 {
        height: 55px
    }
    .ht-md-55p {
        height: 55%
    }
    .mx-ht-md-55p {
        max-height: 55%
    }
    .mn-ht-md-55p {
        min-height: 55%
    }
    .ht-md-55-f {
        height: 55px!important
    }
    .ht-md-55p-f {
        height: 55%!important
    }
    .ht-md-60 {
        height: 60px
    }
    .ht-md-60p {
        height: 60%
    }
    .mx-ht-md-60p {
        max-height: 60%
    }
    .mn-ht-md-60p {
        min-height: 60%
    }
    .ht-md-60-f {
        height: 60px!important
    }
    .ht-md-60p-f {
        height: 60%!important
    }
    .ht-md-65 {
        height: 65px
    }
    .ht-md-65p {
        height: 65%
    }
    .mx-ht-md-65p {
        max-height: 65%
    }
    .mn-ht-md-65p {
        min-height: 65%
    }
    .ht-md-65-f {
        height: 65px!important
    }
    .ht-md-65p-f {
        height: 65%!important
    }
    .ht-md-70 {
        height: 70px
    }
    .ht-md-70p {
        height: 70%
    }
    .mx-ht-md-70p {
        max-height: 70%
    }
    .mn-ht-md-70p {
        min-height: 70%
    }
    .ht-md-70-f {
        height: 70px!important
    }
    .ht-md-70p-f {
        height: 70%!important
    }
    .ht-md-75 {
        height: 75px
    }
    .ht-md-75p {
        height: 75%
    }
    .mx-ht-md-75p {
        max-height: 75%
    }
    .mn-ht-md-75p {
        min-height: 75%
    }
    .ht-md-75-f {
        height: 75px!important
    }
    .ht-md-75p-f {
        height: 75%!important
    }
    .ht-md-80 {
        height: 80px
    }
    .ht-md-80p {
        height: 80%
    }
    .mx-ht-md-80p {
        max-height: 80%
    }
    .mn-ht-md-80p {
        min-height: 80%
    }
    .ht-md-80-f {
        height: 80px!important
    }
    .ht-md-80p-f {
        height: 80%!important
    }
    .ht-md-85 {
        height: 85px
    }
    .ht-md-85p {
        height: 85%
    }
    .mx-ht-md-85p {
        max-height: 85%
    }
    .mn-ht-md-85p {
        min-height: 85%
    }
    .ht-md-85-f {
        height: 85px!important
    }
    .ht-md-85p-f {
        height: 85%!important
    }
    .ht-md-90 {
        height: 90px
    }
    .ht-md-90p {
        height: 90%
    }
    .mx-ht-md-90p {
        max-height: 90%
    }
    .mn-ht-md-90p {
        min-height: 90%
    }
    .ht-md-90-f {
        height: 90px!important
    }
    .ht-md-90p-f {
        height: 90%!important
    }
    .ht-md-95 {
        height: 95px
    }
    .ht-md-95p {
        height: 95%
    }
    .mx-ht-md-95p {
        max-height: 95%
    }
    .mn-ht-md-95p {
        min-height: 95%
    }
    .ht-md-95-f {
        height: 95px!important
    }
    .ht-md-95p-f {
        height: 95%!important
    }
    .ht-md-100 {
        height: 100px
    }
    .ht-md-100p {
        height: 100%
    }
    .mx-ht-md-100p {
        max-height: 100%
    }
    .mn-ht-md-100p {
        min-height: 100%
    }
    .ht-md-100-f {
        height: 100px!important
    }
    .ht-md-100p-f {
        height: 100%!important
    }
    .ht-md-150 {
        height: 150px
    }
    .ht-md-150p {
        height: 150%
    }
    .mx-ht-md-150p {
        max-height: 150%
    }
    .mn-ht-md-150p {
        min-height: 150%
    }
    .ht-md-150-f {
        height: 150px!important
    }
    .ht-md-150p-f {
        height: 150%!important
    }
    .ht-md-200 {
        height: 200px
    }
    .ht-md-200p {
        height: 200%
    }
    .mx-ht-md-200p {
        max-height: 200%
    }
    .mn-ht-md-200p {
        min-height: 200%
    }
    .ht-md-200-f {
        height: 200px!important
    }
    .ht-md-200p-f {
        height: 200%!important
    }
    .ht-md-250 {
        height: 250px
    }
    .ht-md-250p {
        height: 250%
    }
    .mx-ht-md-250p {
        max-height: 250%
    }
    .mn-ht-md-250p {
        min-height: 250%
    }
    .ht-md-250-f {
        height: 250px!important
    }
    .ht-md-250p-f {
        height: 250%!important
    }
    .ht-md-300 {
        height: 300px
    }
    .ht-md-300p {
        height: 300%
    }
    .mx-ht-md-300p {
        max-height: 300%
    }
    .mn-ht-md-300p {
        min-height: 300%
    }
    .ht-md-300-f {
        height: 300px!important
    }
    .ht-md-300p-f {
        height: 300%!important
    }
    .ht-md-350 {
        height: 350px
    }
    .ht-md-350p {
        height: 350%
    }
    .mx-ht-md-350p {
        max-height: 350%
    }
    .mn-ht-md-350p {
        min-height: 350%
    }
    .ht-md-350-f {
        height: 350px!important
    }
    .ht-md-350p-f {
        height: 350%!important
    }
    .ht-md-400 {
        height: 400px
    }
    .ht-md-400p {
        height: 400%
    }
    .mx-ht-md-400p {
        max-height: 400%
    }
    .mn-ht-md-400p {
        min-height: 400%
    }
    .ht-md-400-f {
        height: 400px!important
    }
    .ht-md-400p-f {
        height: 400%!important
    }
    .ht-md-450 {
        height: 450px
    }
    .ht-md-450p {
        height: 450%
    }
    .mx-ht-md-450p {
        max-height: 450%
    }
    .mn-ht-md-450p {
        min-height: 450%
    }
    .ht-md-450-f {
        height: 450px!important
    }
    .ht-md-450p-f {
        height: 450%!important
    }
    .ht-md-500 {
        height: 500px
    }
    .ht-md-500p {
        height: 500%
    }
    .mx-ht-md-500p {
        max-height: 500%
    }
    .mn-ht-md-500p {
        min-height: 500%
    }
    .ht-md-500-f {
        height: 500px!important
    }
    .ht-md-500p-f {
        height: 500%!important
    }
    .ht-md-550 {
        height: 550px
    }
    .ht-md-550p {
        height: 550%
    }
    .mx-ht-md-550p {
        max-height: 550%
    }
    .mn-ht-md-550p {
        min-height: 550%
    }
    .ht-md-550-f {
        height: 550px!important
    }
    .ht-md-550p-f {
        height: 550%!important
    }
    .ht-md-600 {
        height: 600px
    }
    .ht-md-600p {
        height: 600%
    }
    .mx-ht-md-600p {
        max-height: 600%
    }
    .mn-ht-md-600p {
        min-height: 600%
    }
    .ht-md-600-f {
        height: 600px!important
    }
    .ht-md-600p-f {
        height: 600%!important
    }
    .ht-md-650 {
        height: 650px
    }
    .ht-md-650p {
        height: 650%
    }
    .mx-ht-md-650p {
        max-height: 650%
    }
    .mn-ht-md-650p {
        min-height: 650%
    }
    .ht-md-650-f {
        height: 650px!important
    }
    .ht-md-650p-f {
        height: 650%!important
    }
    .ht-md-700 {
        height: 700px
    }
    .ht-md-700p {
        height: 700%
    }
    .mx-ht-md-700p {
        max-height: 700%
    }
    .mn-ht-md-700p {
        min-height: 700%
    }
    .ht-md-700-f {
        height: 700px!important
    }
    .ht-md-700p-f {
        height: 700%!important
    }
    .ht-md-750 {
        height: 750px
    }
    .ht-md-750p {
        height: 750%
    }
    .mx-ht-md-750p {
        max-height: 750%
    }
    .mn-ht-md-750p {
        min-height: 750%
    }
    .ht-md-750-f {
        height: 750px!important
    }
    .ht-md-750p-f {
        height: 750%!important
    }
    .ht-md-800 {
        height: 800px
    }
    .ht-md-800p {
        height: 800%
    }
    .mx-ht-md-800p {
        max-height: 800%
    }
    .mn-ht-md-800p {
        min-height: 800%
    }
    .ht-md-800-f {
        height: 800px!important
    }
    .ht-md-800p-f {
        height: 800%!important
    }
    .ht-md-850 {
        height: 850px
    }
    .ht-md-850p {
        height: 850%
    }
    .mx-ht-md-850p {
        max-height: 850%
    }
    .mn-ht-md-850p {
        min-height: 850%
    }
    .ht-md-850-f {
        height: 850px!important
    }
    .ht-md-850p-f {
        height: 850%!important
    }
    .ht-md-100v {
        height: 100vh
    }
}

@media (min-width:992px) {
    .ht-lg-5 {
        height: 5px
    }
    .ht-lg-5p {
        height: 5%
    }
    .mx-ht-lg-5p {
        max-height: 5%
    }
    .mn-ht-lg-5p {
        min-height: 5%
    }
    .ht-lg-5-f {
        height: 5px!important
    }
    .ht-lg-5p-f {
        height: 5%!important
    }
    .ht-lg-10 {
        height: 10px
    }
    .ht-lg-10p {
        height: 10%
    }
    .mx-ht-lg-10p {
        max-height: 10%
    }
    .mn-ht-lg-10p {
        min-height: 10%
    }
    .ht-lg-10-f {
        height: 10px!important
    }
    .ht-lg-10p-f {
        height: 10%!important
    }
    .ht-lg-15 {
        height: 15px
    }
    .ht-lg-15p {
        height: 15%
    }
    .mx-ht-lg-15p {
        max-height: 15%
    }
    .mn-ht-lg-15p {
        min-height: 15%
    }
    .ht-lg-15-f {
        height: 15px!important
    }
    .ht-lg-15p-f {
        height: 15%!important
    }
    .ht-lg-20 {
        height: 20px
    }
    .ht-lg-20p {
        height: 20%
    }
    .mx-ht-lg-20p {
        max-height: 20%
    }
    .mn-ht-lg-20p {
        min-height: 20%
    }
    .ht-lg-20-f {
        height: 20px!important
    }
    .ht-lg-20p-f {
        height: 20%!important
    }
    .ht-lg-25 {
        height: 25px
    }
    .ht-lg-25p {
        height: 25%
    }
    .mx-ht-lg-25p {
        max-height: 25%
    }
    .mn-ht-lg-25p {
        min-height: 25%
    }
    .ht-lg-25-f {
        height: 25px!important
    }
    .ht-lg-25p-f {
        height: 25%!important
    }
    .ht-lg-30 {
        height: 30px
    }
    .ht-lg-30p {
        height: 30%
    }
    .mx-ht-lg-30p {
        max-height: 30%
    }
    .mn-ht-lg-30p {
        min-height: 30%
    }
    .ht-lg-30-f {
        height: 30px!important
    }
    .ht-lg-30p-f {
        height: 30%!important
    }
    .ht-lg-35 {
        height: 35px
    }
    .ht-lg-35p {
        height: 35%
    }
    .mx-ht-lg-35p {
        max-height: 35%
    }
    .mn-ht-lg-35p {
        min-height: 35%
    }
    .ht-lg-35-f {
        height: 35px!important
    }
    .ht-lg-35p-f {
        height: 35%!important
    }
    .ht-lg-40 {
        height: 40px
    }
    .ht-lg-40p {
        height: 40%
    }
    .mx-ht-lg-40p {
        max-height: 40%
    }
    .mn-ht-lg-40p {
        min-height: 40%
    }
    .ht-lg-40-f {
        height: 40px!important
    }
    .ht-lg-40p-f {
        height: 40%!important
    }
    .ht-lg-45 {
        height: 45px
    }
    .ht-lg-45p {
        height: 45%
    }
    .mx-ht-lg-45p {
        max-height: 45%
    }
    .mn-ht-lg-45p {
        min-height: 45%
    }
    .ht-lg-45-f {
        height: 45px!important
    }
    .ht-lg-45p-f {
        height: 45%!important
    }
    .ht-lg-50 {
        height: 50px
    }
    .ht-lg-50p {
        height: 50%
    }
    .mx-ht-lg-50p {
        max-height: 50%
    }
    .mn-ht-lg-50p {
        min-height: 50%
    }
    .ht-lg-50-f {
        height: 50px!important
    }
    .ht-lg-50p-f {
        height: 50%!important
    }
    .ht-lg-55 {
        height: 55px
    }
    .ht-lg-55p {
        height: 55%
    }
    .mx-ht-lg-55p {
        max-height: 55%
    }
    .mn-ht-lg-55p {
        min-height: 55%
    }
    .ht-lg-55-f {
        height: 55px!important
    }
    .ht-lg-55p-f {
        height: 55%!important
    }
    .ht-lg-60 {
        height: 60px
    }
    .ht-lg-60p {
        height: 60%
    }
    .mx-ht-lg-60p {
        max-height: 60%
    }
    .mn-ht-lg-60p {
        min-height: 60%
    }
    .ht-lg-60-f {
        height: 60px!important
    }
    .ht-lg-60p-f {
        height: 60%!important
    }
    .ht-lg-65 {
        height: 65px
    }
    .ht-lg-65p {
        height: 65%
    }
    .mx-ht-lg-65p {
        max-height: 65%
    }
    .mn-ht-lg-65p {
        min-height: 65%
    }
    .ht-lg-65-f {
        height: 65px!important
    }
    .ht-lg-65p-f {
        height: 65%!important
    }
    .ht-lg-70 {
        height: 70px
    }
    .ht-lg-70p {
        height: 70%
    }
    .mx-ht-lg-70p {
        max-height: 70%
    }
    .mn-ht-lg-70p {
        min-height: 70%
    }
    .ht-lg-70-f {
        height: 70px!important
    }
    .ht-lg-70p-f {
        height: 70%!important
    }
    .ht-lg-75 {
        height: 75px
    }
    .ht-lg-75p {
        height: 75%
    }
    .mx-ht-lg-75p {
        max-height: 75%
    }
    .mn-ht-lg-75p {
        min-height: 75%
    }
    .ht-lg-75-f {
        height: 75px!important
    }
    .ht-lg-75p-f {
        height: 75%!important
    }
    .ht-lg-80 {
        height: 80px
    }
    .ht-lg-80p {
        height: 80%
    }
    .mx-ht-lg-80p {
        max-height: 80%
    }
    .mn-ht-lg-80p {
        min-height: 80%
    }
    .ht-lg-80-f {
        height: 80px!important
    }
    .ht-lg-80p-f {
        height: 80%!important
    }
    .ht-lg-85 {
        height: 85px
    }
    .ht-lg-85p {
        height: 85%
    }
    .mx-ht-lg-85p {
        max-height: 85%
    }
    .mn-ht-lg-85p {
        min-height: 85%
    }
    .ht-lg-85-f {
        height: 85px!important
    }
    .ht-lg-85p-f {
        height: 85%!important
    }
    .ht-lg-90 {
        height: 90px
    }
    .ht-lg-90p {
        height: 90%
    }
    .mx-ht-lg-90p {
        max-height: 90%
    }
    .mn-ht-lg-90p {
        min-height: 90%
    }
    .ht-lg-90-f {
        height: 90px!important
    }
    .ht-lg-90p-f {
        height: 90%!important
    }
    .ht-lg-95 {
        height: 95px
    }
    .ht-lg-95p {
        height: 95%
    }
    .mx-ht-lg-95p {
        max-height: 95%
    }
    .mn-ht-lg-95p {
        min-height: 95%
    }
    .ht-lg-95-f {
        height: 95px!important
    }
    .ht-lg-95p-f {
        height: 95%!important
    }
    .ht-lg-100 {
        height: 100px
    }
    .ht-lg-100p {
        height: 100%
    }
    .mx-ht-lg-100p {
        max-height: 100%
    }
    .mn-ht-lg-100p {
        min-height: 100%
    }
    .ht-lg-100-f {
        height: 100px!important
    }
    .ht-lg-100p-f {
        height: 100%!important
    }
    .ht-lg-150 {
        height: 150px
    }
    .ht-lg-150p {
        height: 150%
    }
    .mx-ht-lg-150p {
        max-height: 150%
    }
    .mn-ht-lg-150p {
        min-height: 150%
    }
    .ht-lg-150-f {
        height: 150px!important
    }
    .ht-lg-150p-f {
        height: 150%!important
    }
    .ht-lg-200 {
        height: 200px
    }
    .ht-lg-200p {
        height: 200%
    }
    .mx-ht-lg-200p {
        max-height: 200%
    }
    .mn-ht-lg-200p {
        min-height: 200%
    }
    .ht-lg-200-f {
        height: 200px!important
    }
    .ht-lg-200p-f {
        height: 200%!important
    }
    .ht-lg-250 {
        height: 250px
    }
    .ht-lg-250p {
        height: 250%
    }
    .mx-ht-lg-250p {
        max-height: 250%
    }
    .mn-ht-lg-250p {
        min-height: 250%
    }
    .ht-lg-250-f {
        height: 250px!important
    }
    .ht-lg-250p-f {
        height: 250%!important
    }
    .ht-lg-300 {
        height: 300px
    }
    .ht-lg-300p {
        height: 300%
    }
    .mx-ht-lg-300p {
        max-height: 300%
    }
    .mn-ht-lg-300p {
        min-height: 300%
    }
    .ht-lg-300-f {
        height: 300px!important
    }
    .ht-lg-300p-f {
        height: 300%!important
    }
    .ht-lg-350 {
        height: 350px
    }
    .ht-lg-350p {
        height: 350%
    }
    .mx-ht-lg-350p {
        max-height: 350%
    }
    .mn-ht-lg-350p {
        min-height: 350%
    }
    .ht-lg-350-f {
        height: 350px!important
    }
    .ht-lg-350p-f {
        height: 350%!important
    }
    .ht-lg-400 {
        height: 400px
    }
    .ht-lg-400p {
        height: 400%
    }
    .mx-ht-lg-400p {
        max-height: 400%
    }
    .mn-ht-lg-400p {
        min-height: 400%
    }
    .ht-lg-400-f {
        height: 400px!important
    }
    .ht-lg-400p-f {
        height: 400%!important
    }
    .ht-lg-450 {
        height: 450px
    }
    .ht-lg-450p {
        height: 450%
    }
    .mx-ht-lg-450p {
        max-height: 450%
    }
    .mn-ht-lg-450p {
        min-height: 450%
    }
    .ht-lg-450-f {
        height: 450px!important
    }
    .ht-lg-450p-f {
        height: 450%!important
    }
    .ht-lg-500 {
        height: 500px
    }
    .ht-lg-500p {
        height: 500%
    }
    .mx-ht-lg-500p {
        max-height: 500%
    }
    .mn-ht-lg-500p {
        min-height: 500%
    }
    .ht-lg-500-f {
        height: 500px!important
    }
    .ht-lg-500p-f {
        height: 500%!important
    }
    .ht-lg-550 {
        height: 550px
    }
    .ht-lg-550p {
        height: 550%
    }
    .mx-ht-lg-550p {
        max-height: 550%
    }
    .mn-ht-lg-550p {
        min-height: 550%
    }
    .ht-lg-550-f {
        height: 550px!important
    }
    .ht-lg-550p-f {
        height: 550%!important
    }
    .ht-lg-600 {
        height: 600px
    }
    .ht-lg-600p {
        height: 600%
    }
    .mx-ht-lg-600p {
        max-height: 600%
    }
    .mn-ht-lg-600p {
        min-height: 600%
    }
    .ht-lg-600-f {
        height: 600px!important
    }
    .ht-lg-600p-f {
        height: 600%!important
    }
    .ht-lg-650 {
        height: 650px
    }
    .ht-lg-650p {
        height: 650%
    }
    .mx-ht-lg-650p {
        max-height: 650%
    }
    .mn-ht-lg-650p {
        min-height: 650%
    }
    .ht-lg-650-f {
        height: 650px!important
    }
    .ht-lg-650p-f {
        height: 650%!important
    }
    .ht-lg-700 {
        height: 700px
    }
    .ht-lg-700p {
        height: 700%
    }
    .mx-ht-lg-700p {
        max-height: 700%
    }
    .mn-ht-lg-700p {
        min-height: 700%
    }
    .ht-lg-700-f {
        height: 700px!important
    }
    .ht-lg-700p-f {
        height: 700%!important
    }
    .ht-lg-750 {
        height: 750px
    }
    .ht-lg-750p {
        height: 750%
    }
    .mx-ht-lg-750p {
        max-height: 750%
    }
    .mn-ht-lg-750p {
        min-height: 750%
    }
    .ht-lg-750-f {
        height: 750px!important
    }
    .ht-lg-750p-f {
        height: 750%!important
    }
    .ht-lg-800 {
        height: 800px
    }
    .ht-lg-800p {
        height: 800%
    }
    .mx-ht-lg-800p {
        max-height: 800%
    }
    .mn-ht-lg-800p {
        min-height: 800%
    }
    .ht-lg-800-f {
        height: 800px!important
    }
    .ht-lg-800p-f {
        height: 800%!important
    }
    .ht-lg-850 {
        height: 850px
    }
    .ht-lg-850p {
        height: 850%
    }
    .mx-ht-lg-850p {
        max-height: 850%
    }
    .mn-ht-lg-850p {
        min-height: 850%
    }
    .ht-lg-850-f {
        height: 850px!important
    }
    .ht-lg-850p-f {
        height: 850%!important
    }
    .ht-lg-100v {
        height: 100vh
    }
}

@media (min-width:1200px) {
    .ht-xl-5 {
        height: 5px
    }
    .ht-xl-5p {
        height: 5%
    }
    .mx-ht-xl-5p {
        max-height: 5%
    }
    .mn-ht-xl-5p {
        min-height: 5%
    }
    .ht-xl-5-f {
        height: 5px!important
    }
    .ht-xl-5p-f {
        height: 5%!important
    }
    .ht-xl-10 {
        height: 10px
    }
    .ht-xl-10p {
        height: 10%
    }
    .mx-ht-xl-10p {
        max-height: 10%
    }
    .mn-ht-xl-10p {
        min-height: 10%
    }
    .ht-xl-10-f {
        height: 10px!important
    }
    .ht-xl-10p-f {
        height: 10%!important
    }
    .ht-xl-15 {
        height: 15px
    }
    .ht-xl-15p {
        height: 15%
    }
    .mx-ht-xl-15p {
        max-height: 15%
    }
    .mn-ht-xl-15p {
        min-height: 15%
    }
    .ht-xl-15-f {
        height: 15px!important
    }
    .ht-xl-15p-f {
        height: 15%!important
    }
    .ht-xl-20 {
        height: 20px
    }
    .ht-xl-20p {
        height: 20%
    }
    .mx-ht-xl-20p {
        max-height: 20%
    }
    .mn-ht-xl-20p {
        min-height: 20%
    }
    .ht-xl-20-f {
        height: 20px!important
    }
    .ht-xl-20p-f {
        height: 20%!important
    }
    .ht-xl-25 {
        height: 25px
    }
    .ht-xl-25p {
        height: 25%
    }
    .mx-ht-xl-25p {
        max-height: 25%
    }
    .mn-ht-xl-25p {
        min-height: 25%
    }
    .ht-xl-25-f {
        height: 25px!important
    }
    .ht-xl-25p-f {
        height: 25%!important
    }
    .ht-xl-30 {
        height: 30px
    }
    .ht-xl-30p {
        height: 30%
    }
    .mx-ht-xl-30p {
        max-height: 30%
    }
    .mn-ht-xl-30p {
        min-height: 30%
    }
    .ht-xl-30-f {
        height: 30px!important
    }
    .ht-xl-30p-f {
        height: 30%!important
    }
    .ht-xl-35 {
        height: 35px
    }
    .ht-xl-35p {
        height: 35%
    }
    .mx-ht-xl-35p {
        max-height: 35%
    }
    .mn-ht-xl-35p {
        min-height: 35%
    }
    .ht-xl-35-f {
        height: 35px!important
    }
    .ht-xl-35p-f {
        height: 35%!important
    }
    .ht-xl-40 {
        height: 40px
    }
    .ht-xl-40p {
        height: 40%
    }
    .mx-ht-xl-40p {
        max-height: 40%
    }
    .mn-ht-xl-40p {
        min-height: 40%
    }
    .ht-xl-40-f {
        height: 40px!important
    }
    .ht-xl-40p-f {
        height: 40%!important
    }
    .ht-xl-45 {
        height: 45px
    }
    .ht-xl-45p {
        height: 45%
    }
    .mx-ht-xl-45p {
        max-height: 45%
    }
    .mn-ht-xl-45p {
        min-height: 45%
    }
    .ht-xl-45-f {
        height: 45px!important
    }
    .ht-xl-45p-f {
        height: 45%!important
    }
    .ht-xl-50 {
        height: 50px
    }
    .ht-xl-50p {
        height: 50%
    }
    .mx-ht-xl-50p {
        max-height: 50%
    }
    .mn-ht-xl-50p {
        min-height: 50%
    }
    .ht-xl-50-f {
        height: 50px!important
    }
    .ht-xl-50p-f {
        height: 50%!important
    }
    .ht-xl-55 {
        height: 55px
    }
    .ht-xl-55p {
        height: 55%
    }
    .mx-ht-xl-55p {
        max-height: 55%
    }
    .mn-ht-xl-55p {
        min-height: 55%
    }
    .ht-xl-55-f {
        height: 55px!important
    }
    .ht-xl-55p-f {
        height: 55%!important
    }
    .ht-xl-60 {
        height: 60px
    }
    .ht-xl-60p {
        height: 60%
    }
    .mx-ht-xl-60p {
        max-height: 60%
    }
    .mn-ht-xl-60p {
        min-height: 60%
    }
    .ht-xl-60-f {
        height: 60px!important
    }
    .ht-xl-60p-f {
        height: 60%!important
    }
    .ht-xl-65 {
        height: 65px
    }
    .ht-xl-65p {
        height: 65%
    }
    .mx-ht-xl-65p {
        max-height: 65%
    }
    .mn-ht-xl-65p {
        min-height: 65%
    }
    .ht-xl-65-f {
        height: 65px!important
    }
    .ht-xl-65p-f {
        height: 65%!important
    }
    .ht-xl-70 {
        height: 70px
    }
    .ht-xl-70p {
        height: 70%
    }
    .mx-ht-xl-70p {
        max-height: 70%
    }
    .mn-ht-xl-70p {
        min-height: 70%
    }
    .ht-xl-70-f {
        height: 70px!important
    }
    .ht-xl-70p-f {
        height: 70%!important
    }
    .ht-xl-75 {
        height: 75px
    }
    .ht-xl-75p {
        height: 75%
    }
    .mx-ht-xl-75p {
        max-height: 75%
    }
    .mn-ht-xl-75p {
        min-height: 75%
    }
    .ht-xl-75-f {
        height: 75px!important
    }
    .ht-xl-75p-f {
        height: 75%!important
    }
    .ht-xl-80 {
        height: 80px
    }
    .ht-xl-80p {
        height: 80%
    }
    .mx-ht-xl-80p {
        max-height: 80%
    }
    .mn-ht-xl-80p {
        min-height: 80%
    }
    .ht-xl-80-f {
        height: 80px!important
    }
    .ht-xl-80p-f {
        height: 80%!important
    }
    .ht-xl-85 {
        height: 85px
    }
    .ht-xl-85p {
        height: 85%
    }
    .mx-ht-xl-85p {
        max-height: 85%
    }
    .mn-ht-xl-85p {
        min-height: 85%
    }
    .ht-xl-85-f {
        height: 85px!important
    }
    .ht-xl-85p-f {
        height: 85%!important
    }
    .ht-xl-90 {
        height: 90px
    }
    .ht-xl-90p {
        height: 90%
    }
    .mx-ht-xl-90p {
        max-height: 90%
    }
    .mn-ht-xl-90p {
        min-height: 90%
    }
    .ht-xl-90-f {
        height: 90px!important
    }
    .ht-xl-90p-f {
        height: 90%!important
    }
    .ht-xl-95 {
        height: 95px
    }
    .ht-xl-95p {
        height: 95%
    }
    .mx-ht-xl-95p {
        max-height: 95%
    }
    .mn-ht-xl-95p {
        min-height: 95%
    }
    .ht-xl-95-f {
        height: 95px!important
    }
    .ht-xl-95p-f {
        height: 95%!important
    }
    .ht-xl-100 {
        height: 100px
    }
    .ht-xl-100p {
        height: 100%
    }
    .mx-ht-xl-100p {
        max-height: 100%
    }
    .mn-ht-xl-100p {
        min-height: 100%
    }
    .ht-xl-100-f {
        height: 100px!important
    }
    .ht-xl-100p-f {
        height: 100%!important
    }
    .ht-xl-150 {
        height: 150px
    }
    .ht-xl-150p {
        height: 150%
    }
    .mx-ht-xl-150p {
        max-height: 150%
    }
    .mn-ht-xl-150p {
        min-height: 150%
    }
    .ht-xl-150-f {
        height: 150px!important
    }
    .ht-xl-150p-f {
        height: 150%!important
    }
    .ht-xl-200 {
        height: 200px
    }
    .ht-xl-200p {
        height: 200%
    }
    .mx-ht-xl-200p {
        max-height: 200%
    }
    .mn-ht-xl-200p {
        min-height: 200%
    }
    .ht-xl-200-f {
        height: 200px!important
    }
    .ht-xl-200p-f {
        height: 200%!important
    }
    .ht-xl-250 {
        height: 250px
    }
    .ht-xl-250p {
        height: 250%
    }
    .mx-ht-xl-250p {
        max-height: 250%
    }
    .mn-ht-xl-250p {
        min-height: 250%
    }
    .ht-xl-250-f {
        height: 250px!important
    }
    .ht-xl-250p-f {
        height: 250%!important
    }
    .ht-xl-300 {
        height: 300px
    }
    .ht-xl-300p {
        height: 300%
    }
    .mx-ht-xl-300p {
        max-height: 300%
    }
    .mn-ht-xl-300p {
        min-height: 300%
    }
    .ht-xl-300-f {
        height: 300px!important
    }
    .ht-xl-300p-f {
        height: 300%!important
    }
    .ht-xl-350 {
        height: 350px
    }
    .ht-xl-350p {
        height: 350%
    }
    .mx-ht-xl-350p {
        max-height: 350%
    }
    .mn-ht-xl-350p {
        min-height: 350%
    }
    .ht-xl-350-f {
        height: 350px!important
    }
    .ht-xl-350p-f {
        height: 350%!important
    }
    .ht-xl-400 {
        height: 400px
    }
    .ht-xl-400p {
        height: 400%
    }
    .mx-ht-xl-400p {
        max-height: 400%
    }
    .mn-ht-xl-400p {
        min-height: 400%
    }
    .ht-xl-400-f {
        height: 400px!important
    }
    .ht-xl-400p-f {
        height: 400%!important
    }
    .ht-xl-450 {
        height: 450px
    }
    .ht-xl-450p {
        height: 450%
    }
    .mx-ht-xl-450p {
        max-height: 450%
    }
    .mn-ht-xl-450p {
        min-height: 450%
    }
    .ht-xl-450-f {
        height: 450px!important
    }
    .ht-xl-450p-f {
        height: 450%!important
    }
    .ht-xl-500 {
        height: 500px
    }
    .ht-xl-500p {
        height: 500%
    }
    .mx-ht-xl-500p {
        max-height: 500%
    }
    .mn-ht-xl-500p {
        min-height: 500%
    }
    .ht-xl-500-f {
        height: 500px!important
    }
    .ht-xl-500p-f {
        height: 500%!important
    }
    .ht-xl-550 {
        height: 550px
    }
    .ht-xl-550p {
        height: 550%
    }
    .mx-ht-xl-550p {
        max-height: 550%
    }
    .mn-ht-xl-550p {
        min-height: 550%
    }
    .ht-xl-550-f {
        height: 550px!important
    }
    .ht-xl-550p-f {
        height: 550%!important
    }
    .ht-xl-600 {
        height: 600px
    }
    .ht-xl-600p {
        height: 600%
    }
    .mx-ht-xl-600p {
        max-height: 600%
    }
    .mn-ht-xl-600p {
        min-height: 600%
    }
    .ht-xl-600-f {
        height: 600px!important
    }
    .ht-xl-600p-f {
        height: 600%!important
    }
    .ht-xl-650 {
        height: 650px
    }
    .ht-xl-650p {
        height: 650%
    }
    .mx-ht-xl-650p {
        max-height: 650%
    }
    .mn-ht-xl-650p {
        min-height: 650%
    }
    .ht-xl-650-f {
        height: 650px!important
    }
    .ht-xl-650p-f {
        height: 650%!important
    }
    .ht-xl-700 {
        height: 700px
    }
    .ht-xl-700p {
        height: 700%
    }
    .mx-ht-xl-700p {
        max-height: 700%
    }
    .mn-ht-xl-700p {
        min-height: 700%
    }
    .ht-xl-700-f {
        height: 700px!important
    }
    .ht-xl-700p-f {
        height: 700%!important
    }
    .ht-xl-750 {
        height: 750px
    }
    .ht-xl-750p {
        height: 750%
    }
    .mx-ht-xl-750p {
        max-height: 750%
    }
    .mn-ht-xl-750p {
        min-height: 750%
    }
    .ht-xl-750-f {
        height: 750px!important
    }
    .ht-xl-750p-f {
        height: 750%!important
    }
    .ht-xl-800 {
        height: 800px
    }
    .ht-xl-800p {
        height: 800%
    }
    .mx-ht-xl-800p {
        max-height: 800%
    }
    .mn-ht-xl-800p {
        min-height: 800%
    }
    .ht-xl-800-f {
        height: 800px!important
    }
    .ht-xl-800p-f {
        height: 800%!important
    }
    .ht-xl-850 {
        height: 850px
    }
    .ht-xl-850p {
        height: 850%
    }
    .mx-ht-xl-850p {
        max-height: 850%
    }
    .mn-ht-xl-850p {
        min-height: 850%
    }
    .ht-xl-850-f {
        height: 850px!important
    }
    .ht-xl-850p-f {
        height: 850%!important
    }
    .ht-xl-100v {
        height: 100vh
    }
}

.m-0 {
    margin: 0!important
}

.mt-0,
.my-0 {
    margin-top: 0!important
}

.mr-0,
.mx-0 {
    margin-right: 0!important
}

.mb-0,
.my-0 {
    margin-bottom: 0!important
}

.ml-0,
.mx-0 {
    margin-left: 0!important
}

.m-1 {
    margin: .25rem!important
}

.mt-1,
.my-1 {
    margin-top: .25rem!important
}

.mr-1,
.mx-1 {
    margin-right: .25rem!important
}

.mb-1,
.my-1 {
    margin-bottom: .25rem!important
}

.ml-1,
.mx-1 {
    margin-left: .25rem!important
}

.m-2 {
    margin: .5rem!important
}

.mt-2,
.my-2 {
    margin-top: .5rem!important
}

.mr-2,
.mx-2 {
    margin-right: .5rem!important
}

.mb-2,
.my-2 {
    margin-bottom: .5rem!important
}

.ml-2,
.mx-2 {
    margin-left: .5rem!important
}

.m-3 {
    margin: 1rem!important
}

.mt-3,
.my-3 {
    margin-top: 1rem!important
}

.mr-3,
.mx-3 {
    margin-right: 1rem!important
}

.mb-3,
.my-3 {
    margin-bottom: 1rem!important
}

.ml-3,
.mx-3 {
    margin-left: 1rem!important
}

.m-4 {
    margin: 1.5rem!important
}

.mt-4,
.my-4 {
    margin-top: 1.5rem!important
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem!important
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem!important
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem!important
}

.m-5 {
    margin: 3rem!important
}

.mt-5,
.my-5 {
    margin-top: 3rem!important
}

.mr-5,
.mx-5 {
    margin-right: 3rem!important
}

.mb-5,
.my-5 {
    margin-bottom: 3rem!important
}

.ml-5,
.mx-5 {
    margin-left: 3rem!important
}

.m-n1 {
    margin: -.25rem!important
}

.mt-n1,
.my-n1 {
    margin-top: -.25rem!important
}

.mr-n1,
.mx-n1 {
    margin-right: -.25rem!important
}

.mb-n1,
.my-n1 {
    margin-bottom: -.25rem!important
}

.ml-n1,
.mx-n1 {
    margin-left: -.25rem!important
}

.m-n2 {
    margin: -.5rem!important
}

.mt-n2,
.my-n2 {
    margin-top: -.5rem!important
}

.mr-n2,
.mx-n2 {
    margin-right: -.5rem!important
}

.mb-n2,
.my-n2 {
    margin-bottom: -.5rem!important
}

.ml-n2,
.mx-n2 {
    margin-left: -.5rem!important
}

.m-n3 {
    margin: -1rem!important
}

.mt-n3,
.my-n3 {
    margin-top: -1rem!important
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem!important
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem!important
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem!important
}

.m-n4 {
    margin: -1.5rem!important
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem!important
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem!important
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem!important
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem!important
}

.m-n5 {
    margin: -3rem!important
}

.mt-n5,
.my-n5 {
    margin-top: -3rem!important
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem!important
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem!important
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem!important
}

.m-auto {
    margin: auto!important
}

.mt-auto,
.my-auto {
    margin-top: auto!important
}

.mr-auto,
.mx-auto {
    margin-right: auto!important
}

.mb-auto,
.my-auto {
    margin-bottom: auto!important
}

.ml-auto,
.mx-auto {
    margin-left: auto!important
}

@media (min-width:576px) {
    .m-sm-0 {
        margin: 0!important
    }
    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0!important
    }
    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0!important
    }
    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0!important
    }
    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0!important
    }
    .m-sm-1 {
        margin: .25rem!important
    }
    .mt-sm-1,
    .my-sm-1 {
        margin-top: .25rem!important
    }
    .mr-sm-1,
    .mx-sm-1 {
        margin-right: .25rem!important
    }
    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: .25rem!important
    }
    .ml-sm-1,
    .mx-sm-1 {
        margin-left: .25rem!important
    }
    .m-sm-2 {
        margin: .5rem!important
    }
    .mt-sm-2,
    .my-sm-2 {
        margin-top: .5rem!important
    }
    .mr-sm-2,
    .mx-sm-2 {
        margin-right: .5rem!important
    }
    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: .5rem!important
    }
    .ml-sm-2,
    .mx-sm-2 {
        margin-left: .5rem!important
    }
    .m-sm-3 {
        margin: 1rem!important
    }
    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem!important
    }
    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem!important
    }
    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem!important
    }
    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem!important
    }
    .m-sm-4 {
        margin: 1.5rem!important
    }
    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem!important
    }
    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem!important
    }
    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem!important
    }
    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem!important
    }
    .m-sm-5 {
        margin: 3rem!important
    }
    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem!important
    }
    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem!important
    }
    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem!important
    }
    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem!important
    }
    .m-sm-n1 {
        margin: -.25rem!important
    }
    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -.25rem!important
    }
    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -.25rem!important
    }
    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -.25rem!important
    }
    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -.25rem!important
    }
    .m-sm-n2 {
        margin: -.5rem!important
    }
    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -.5rem!important
    }
    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -.5rem!important
    }
    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -.5rem!important
    }
    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -.5rem!important
    }
    .m-sm-n3 {
        margin: -1rem!important
    }
    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem!important
    }
    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem!important
    }
    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem!important
    }
    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem!important
    }
    .m-sm-n4 {
        margin: -1.5rem!important
    }
    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem!important
    }
    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem!important
    }
    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem!important
    }
    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem!important
    }
    .m-sm-n5 {
        margin: -3rem!important
    }
    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem!important
    }
    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem!important
    }
    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem!important
    }
    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem!important
    }
    .m-sm-auto {
        margin: auto!important
    }
    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto!important
    }
    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto!important
    }
    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto!important
    }
    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto!important
    }
}

@media (min-width:768px) {
    .m-md-0 {
        margin: 0!important
    }
    .mt-md-0,
    .my-md-0 {
        margin-top: 0!important
    }
    .mr-md-0,
    .mx-md-0 {
        margin-right: 0!important
    }
    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0!important
    }
    .ml-md-0,
    .mx-md-0 {
        margin-left: 0!important
    }
    .m-md-1 {
        margin: .25rem!important
    }
    .mt-md-1,
    .my-md-1 {
        margin-top: .25rem!important
    }
    .mr-md-1,
    .mx-md-1 {
        margin-right: .25rem!important
    }
    .mb-md-1,
    .my-md-1 {
        margin-bottom: .25rem!important
    }
    .ml-md-1,
    .mx-md-1 {
        margin-left: .25rem!important
    }
    .m-md-2 {
        margin: .5rem!important
    }
    .mt-md-2,
    .my-md-2 {
        margin-top: .5rem!important
    }
    .mr-md-2,
    .mx-md-2 {
        margin-right: .5rem!important
    }
    .mb-md-2,
    .my-md-2 {
        margin-bottom: .5rem!important
    }
    .ml-md-2,
    .mx-md-2 {
        margin-left: .5rem!important
    }
    .m-md-3 {
        margin: 1rem!important
    }
    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem!important
    }
    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem!important
    }
    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem!important
    }
    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem!important
    }
    .m-md-4 {
        margin: 1.5rem!important
    }
    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem!important
    }
    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem!important
    }
    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem!important
    }
    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem!important
    }
    .m-md-5 {
        margin: 3rem!important
    }
    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem!important
    }
    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem!important
    }
    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem!important
    }
    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem!important
    }
    .m-md-n1 {
        margin: -.25rem!important
    }
    .mt-md-n1,
    .my-md-n1 {
        margin-top: -.25rem!important
    }
    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -.25rem!important
    }
    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -.25rem!important
    }
    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -.25rem!important
    }
    .m-md-n2 {
        margin: -.5rem!important
    }
    .mt-md-n2,
    .my-md-n2 {
        margin-top: -.5rem!important
    }
    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -.5rem!important
    }
    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -.5rem!important
    }
    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -.5rem!important
    }
    .m-md-n3 {
        margin: -1rem!important
    }
    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem!important
    }
    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem!important
    }
    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem!important
    }
    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem!important
    }
    .m-md-n4 {
        margin: -1.5rem!important
    }
    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem!important
    }
    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem!important
    }
    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem!important
    }
    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem!important
    }
    .m-md-n5 {
        margin: -3rem!important
    }
    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem!important
    }
    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem!important
    }
    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem!important
    }
    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem!important
    }
    .m-md-auto {
        margin: auto!important
    }
    .mt-md-auto,
    .my-md-auto {
        margin-top: auto!important
    }
    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto!important
    }
    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto!important
    }
    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto!important
    }
}

@media (min-width:992px) {
    .m-lg-0 {
        margin: 0!important
    }
    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0!important
    }
    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0!important
    }
    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0!important
    }
    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0!important
    }
    .m-lg-1 {
        margin: .25rem!important
    }
    .mt-lg-1,
    .my-lg-1 {
        margin-top: .25rem!important
    }
    .mr-lg-1,
    .mx-lg-1 {
        margin-right: .25rem!important
    }
    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: .25rem!important
    }
    .ml-lg-1,
    .mx-lg-1 {
        margin-left: .25rem!important
    }
    .m-lg-2 {
        margin: .5rem!important
    }
    .mt-lg-2,
    .my-lg-2 {
        margin-top: .5rem!important
    }
    .mr-lg-2,
    .mx-lg-2 {
        margin-right: .5rem!important
    }
    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: .5rem!important
    }
    .ml-lg-2,
    .mx-lg-2 {
        margin-left: .5rem!important
    }
    .m-lg-3 {
        margin: 1rem!important
    }
    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem!important
    }
    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem!important
    }
    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem!important
    }
    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem!important
    }
    .m-lg-4 {
        margin: 1.5rem!important
    }
    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem!important
    }
    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem!important
    }
    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem!important
    }
    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem!important
    }
    .m-lg-5 {
        margin: 3rem!important
    }
    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem!important
    }
    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem!important
    }
    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem!important
    }
    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem!important
    }
    .m-lg-n1 {
        margin: -.25rem!important
    }
    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -.25rem!important
    }
    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -.25rem!important
    }
    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -.25rem!important
    }
    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -.25rem!important
    }
    .m-lg-n2 {
        margin: -.5rem!important
    }
    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -.5rem!important
    }
    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -.5rem!important
    }
    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -.5rem!important
    }
    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -.5rem!important
    }
    .m-lg-n3 {
        margin: -1rem!important
    }
    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem!important
    }
    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem!important
    }
    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem!important
    }
    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem!important
    }
    .m-lg-n4 {
        margin: -1.5rem!important
    }
    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem!important
    }
    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem!important
    }
    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem!important
    }
    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem!important
    }
    .m-lg-n5 {
        margin: -3rem!important
    }
    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem!important
    }
    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem!important
    }
    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem!important
    }
    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem!important
    }
    .m-lg-auto {
        margin: auto!important
    }
    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto!important
    }
    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto!important
    }
    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto!important
    }
    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto!important
    }
}

@media (min-width:1200px) {
    .m-xl-0 {
        margin: 0!important
    }
    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0!important
    }
    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0!important
    }
    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0!important
    }
    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0!important
    }
    .m-xl-1 {
        margin: .25rem!important
    }
    .mt-xl-1,
    .my-xl-1 {
        margin-top: .25rem!important
    }
    .mr-xl-1,
    .mx-xl-1 {
        margin-right: .25rem!important
    }
    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: .25rem!important
    }
    .ml-xl-1,
    .mx-xl-1 {
        margin-left: .25rem!important
    }
    .m-xl-2 {
        margin: .5rem!important
    }
    .mt-xl-2,
    .my-xl-2 {
        margin-top: .5rem!important
    }
    .mr-xl-2,
    .mx-xl-2 {
        margin-right: .5rem!important
    }
    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: .5rem!important
    }
    .ml-xl-2,
    .mx-xl-2 {
        margin-left: .5rem!important
    }
    .m-xl-3 {
        margin: 1rem!important
    }
    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem!important
    }
    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem!important
    }
    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem!important
    }
    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem!important
    }
    .m-xl-4 {
        margin: 1.5rem!important
    }
    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem!important
    }
    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem!important
    }
    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem!important
    }
    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem!important
    }
    .m-xl-5 {
        margin: 3rem!important
    }
    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem!important
    }
    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem!important
    }
    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem!important
    }
    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem!important
    }
    .m-xl-n1 {
        margin: -.25rem!important
    }
    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -.25rem!important
    }
    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -.25rem!important
    }
    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -.25rem!important
    }
    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -.25rem!important
    }
    .m-xl-n2 {
        margin: -.5rem!important
    }
    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -.5rem!important
    }
    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -.5rem!important
    }
    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -.5rem!important
    }
    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -.5rem!important
    }
    .m-xl-n3 {
        margin: -1rem!important
    }
    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem!important
    }
    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem!important
    }
    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem!important
    }
    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem!important
    }
    .m-xl-n4 {
        margin: -1.5rem!important
    }
    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem!important
    }
    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem!important
    }
    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem!important
    }
    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem!important
    }
    .m-xl-n5 {
        margin: -3rem!important
    }
    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem!important
    }
    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem!important
    }
    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem!important
    }
    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem!important
    }
    .m-xl-auto {
        margin: auto!important
    }
    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto!important
    }
    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto!important
    }
    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto!important
    }
    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto!important
    }
}

.mg-0 {
    margin: 0
}

.mg-0-f {
    margin: 0!important
}

.mg-y-0 {
    margin-top: 0;
    margin-bottom: 0
}

.mg-y-0-f {
    margin-top: 0!important;
    margin-bottom: 0!important
}

.mg-x-0 {
    margin-left: 0;
    margin-right: 0
}

.mg-x-0-f {
    margin-left: 0!important;
    margin-right: 0!important
}

.mg-t-0 {
    margin-top: 0
}

.mg-r-0 {
    margin-right: 0
}

.mg-b-0 {
    margin-bottom: 0
}

.mg-l-0 {
    margin-left: 0
}

.mg-t-0-f {
    margin-top: 0!important
}

.mg-r-0-f {
    margin-right: 0!important
}

.mg-b-0-f {
    margin-bottom: 0!important
}

.mg-l-0-f {
    margin-left: 0!important
}

.mg-1 {
    margin: 1px
}

.mg-1-f {
    margin: 1px!important
}

.mg-y-1 {
    margin-top: 1px;
    margin-bottom: 1px
}

.mg-y-1-f {
    margin-top: 1px!important;
    margin-bottom: 1px!important
}

.mg-x-1 {
    margin-left: 1px;
    margin-right: 1px
}

.mg-x-1-f {
    margin-left: 1px!important;
    margin-right: 1px!important
}

.mg-t-1 {
    margin-top: 1px
}

.mg-r-1 {
    margin-right: 1px
}

.mg-b-1 {
    margin-bottom: 1px
}

.mg-l-1 {
    margin-left: 1px
}

.mg-t-1-f {
    margin-top: 1px!important
}

.mg-r-1-f {
    margin-right: 1px!important
}

.mg-b-1-f {
    margin-bottom: 1px!important
}

.mg-l-1-f {
    margin-left: 1px!important
}

.mg-2 {
    margin: 2px
}

.mg-2-f {
    margin: 2px!important
}

.mg-y-2 {
    margin-top: 2px;
    margin-bottom: 2px
}

.mg-y-2-f {
    margin-top: 2px!important;
    margin-bottom: 2px!important
}

.mg-x-2 {
    margin-left: 2px;
    margin-right: 2px
}

.mg-x-2-f {
    margin-left: 2px!important;
    margin-right: 2px!important
}

.mg-t-2 {
    margin-top: 2px
}

.mg-r-2 {
    margin-right: 2px
}

.mg-b-2 {
    margin-bottom: 2px
}

.mg-l-2 {
    margin-left: 2px
}

.mg-t-2-f {
    margin-top: 2px!important
}

.mg-r-2-f {
    margin-right: 2px!important
}

.mg-b-2-f {
    margin-bottom: 2px!important
}

.mg-l-2-f {
    margin-left: 2px!important
}

.mg-3 {
    margin: 3px
}

.mg-3-f {
    margin: 3px!important
}

.mg-y-3 {
    margin-top: 3px;
    margin-bottom: 3px
}

.mg-y-3-f {
    margin-top: 3px!important;
    margin-bottom: 3px!important
}

.mg-x-3 {
    margin-left: 3px;
    margin-right: 3px
}

.mg-x-3-f {
    margin-left: 3px!important;
    margin-right: 3px!important
}

.mg-t-3 {
    margin-top: 3px
}

.mg-r-3 {
    margin-right: 3px
}

.mg-b-3 {
    margin-bottom: 3px
}

.mg-l-3 {
    margin-left: 3px
}

.mg-t-3-f {
    margin-top: 3px!important
}

.mg-r-3-f {
    margin-right: 3px!important
}

.mg-b-3-f {
    margin-bottom: 3px!important
}

.mg-l-3-f {
    margin-left: 3px!important
}

.mg-4 {
    margin: 4px
}

.mg-4-f {
    margin: 4px!important
}

.mg-y-4 {
    margin-top: 4px;
    margin-bottom: 4px
}

.mg-y-4-f {
    margin-top: 4px!important;
    margin-bottom: 4px!important
}

.mg-x-4 {
    margin-left: 4px;
    margin-right: 4px
}

.mg-x-4-f {
    margin-left: 4px!important;
    margin-right: 4px!important
}

.mg-t-4 {
    margin-top: 4px
}

.mg-r-4 {
    margin-right: 4px
}

.mg-b-4 {
    margin-bottom: 4px
}

.mg-l-4 {
    margin-left: 4px
}

.mg-t-4-f {
    margin-top: 4px!important
}

.mg-r-4-f {
    margin-right: 4px!important
}

.mg-b-4-f {
    margin-bottom: 4px!important
}

.mg-l-4-f {
    margin-left: 4px!important
}

.mg-5 {
    margin: 5px
}

.mg-5-f {
    margin: 5px!important
}

.mg-y-5 {
    margin-top: 5px;
    margin-bottom: 5px
}

.mg-y-5-f {
    margin-top: 5px!important;
    margin-bottom: 5px!important
}

.mg-x-5 {
    margin-left: 5px;
    margin-right: 5px
}

.mg-x-5-f {
    margin-left: 5px!important;
    margin-right: 5px!important
}

.mg-t-5 {
    margin-top: 5px
}

.mg-r-5 {
    margin-right: 5px
}

.mg-b-5 {
    margin-bottom: 5px
}

.mg-l-5 {
    margin-left: 5px
}

.mg-t-5-f {
    margin-top: 5px!important
}

.mg-r-5-f {
    margin-right: 5px!important
}

.mg-b-5-f {
    margin-bottom: 5px!important
}

.mg-l-5-f {
    margin-left: 5px!important
}

.mg-6 {
    margin: 6px
}

.mg-6-f {
    margin: 6px!important
}

.mg-y-6 {
    margin-top: 6px;
    margin-bottom: 6px
}

.mg-y-6-f {
    margin-top: 6px!important;
    margin-bottom: 6px!important
}

.mg-x-6 {
    margin-left: 6px;
    margin-right: 6px
}

.mg-x-6-f {
    margin-left: 6px!important;
    margin-right: 6px!important
}

.mg-t-6 {
    margin-top: 6px
}

.mg-r-6 {
    margin-right: 6px
}

.mg-b-6 {
    margin-bottom: 6px
}

.mg-l-6 {
    margin-left: 6px
}

.mg-t-6-f {
    margin-top: 6px!important
}

.mg-r-6-f {
    margin-right: 6px!important
}

.mg-b-6-f {
    margin-bottom: 6px!important
}

.mg-l-6-f {
    margin-left: 6px!important
}

.mg-7 {
    margin: 7px
}

.mg-7-f {
    margin: 7px!important
}

.mg-y-7 {
    margin-top: 7px;
    margin-bottom: 7px
}

.mg-y-7-f {
    margin-top: 7px!important;
    margin-bottom: 7px!important
}

.mg-x-7 {
    margin-left: 7px;
    margin-right: 7px
}

.mg-x-7-f {
    margin-left: 7px!important;
    margin-right: 7px!important
}

.mg-t-7 {
    margin-top: 7px
}

.mg-r-7 {
    margin-right: 7px
}

.mg-b-7 {
    margin-bottom: 7px
}

.mg-l-7 {
    margin-left: 7px
}

.mg-t-7-f {
    margin-top: 7px!important
}

.mg-r-7-f {
    margin-right: 7px!important
}

.mg-b-7-f {
    margin-bottom: 7px!important
}

.mg-l-7-f {
    margin-left: 7px!important
}

.mg-8 {
    margin: 8px
}

.mg-8-f {
    margin: 8px!important
}

.mg-y-8 {
    margin-top: 8px;
    margin-bottom: 8px
}

.mg-y-8-f {
    margin-top: 8px!important;
    margin-bottom: 8px!important
}

.mg-x-8 {
    margin-left: 8px;
    margin-right: 8px
}

.mg-x-8-f {
    margin-left: 8px!important;
    margin-right: 8px!important
}

.mg-t-8 {
    margin-top: 8px
}

.mg-r-8 {
    margin-right: 8px
}

.mg-b-8 {
    margin-bottom: 8px
}

.mg-l-8 {
    margin-left: 8px
}

.mg-t-8-f {
    margin-top: 8px!important
}

.mg-r-8-f {
    margin-right: 8px!important
}

.mg-b-8-f {
    margin-bottom: 8px!important
}

.mg-l-8-f {
    margin-left: 8px!important
}

.mg-9 {
    margin: 9px
}

.mg-9-f {
    margin: 9px!important
}

.mg-y-9 {
    margin-top: 9px;
    margin-bottom: 9px
}

.mg-y-9-f {
    margin-top: 9px!important;
    margin-bottom: 9px!important
}

.mg-x-9 {
    margin-left: 9px;
    margin-right: 9px
}

.mg-x-9-f {
    margin-left: 9px!important;
    margin-right: 9px!important
}

.mg-t-9 {
    margin-top: 9px
}

.mg-r-9 {
    margin-right: 9px
}

.mg-b-9 {
    margin-bottom: 9px
}

.mg-l-9 {
    margin-left: 9px
}

.mg-t-9-f {
    margin-top: 9px!important
}

.mg-r-9-f {
    margin-right: 9px!important
}

.mg-b-9-f {
    margin-bottom: 9px!important
}

.mg-l-9-f {
    margin-left: 9px!important
}

.mg-10 {
    margin: 10px
}

.mg-10-f {
    margin: 10px!important
}

.mg-y-10 {
    margin-top: 10px;
    margin-bottom: 10px
}

.mg-y-10-f {
    margin-top: 10px!important;
    margin-bottom: 10px!important
}

.mg-x-10 {
    margin-left: 10px;
    margin-right: 10px
}

.mg-x-10-f {
    margin-left: 10px!important;
    margin-right: 10px!important
}

.mg-t-10 {
    margin-top: 10px
}

.mg-r-10 {
    margin-right: 10px
}

.mg-b-10 {
    margin-bottom: 10px
}

.mg-l-10 {
    margin-left: 10px
}

.mg-t-10-f {
    margin-top: 10px!important
}

.mg-r-10-f {
    margin-right: 10px!important
}

.mg-b-10-f {
    margin-bottom: 10px!important
}

.mg-l-10-f {
    margin-left: 10px!important
}

.mg-15 {
    margin: 15px
}

.mg-15-f {
    margin: 15px!important
}

.mg-y-15 {
    margin-top: 15px;
    margin-bottom: 15px
}

.mg-y-15-f {
    margin-top: 15px!important;
    margin-bottom: 15px!important
}

.mg-x-15 {
    margin-left: 15px;
    margin-right: 15px
}

.mg-x-15-f {
    margin-left: 15px!important;
    margin-right: 15px!important
}

.mg-t-15 {
    margin-top: 15px
}

.mg-r-15 {
    margin-right: 15px
}

.mg-b-15 {
    margin-bottom: 15px
}

.mg-l-15 {
    margin-left: 15px
}

.mg-t-15-f {
    margin-top: 15px!important
}

.mg-r-15-f {
    margin-right: 15px!important
}

.mg-b-15-f {
    margin-bottom: 15px!important
}

.mg-l-15-f {
    margin-left: 15px!important
}

.mg-20 {
    margin: 20px
}

.mg-20-f {
    margin: 20px!important
}

.mg-y-20 {
    margin-top: 20px;
    margin-bottom: 20px
}

.mg-y-20-f {
    margin-top: 20px!important;
    margin-bottom: 20px!important
}

.mg-x-20 {
    margin-left: 20px;
    margin-right: 20px
}

.mg-x-20-f {
    margin-left: 20px!important;
    margin-right: 20px!important
}

.mg-t-20 {
    margin-top: 20px
}

.mg-r-20 {
    margin-right: 20px
}

.mg-b-20 {
    margin-bottom: 20px
}

.mg-l-20 {
    margin-left: 20px
}

.mg-t-20-f {
    margin-top: 20px!important
}

.mg-r-20-f {
    margin-right: 20px!important
}

.mg-b-20-f {
    margin-bottom: 20px!important
}

.mg-l-20-f {
    margin-left: 20px!important
}

.mg-25 {
    margin: 25px
}

.mg-25-f {
    margin: 25px!important
}

.mg-y-25 {
    margin-top: 25px;
    margin-bottom: 25px
}

.mg-y-25-f {
    margin-top: 25px!important;
    margin-bottom: 25px!important
}

.mg-x-25 {
    margin-left: 25px;
    margin-right: 25px
}

.mg-x-25-f {
    margin-left: 25px!important;
    margin-right: 25px!important
}

.mg-t-25 {
    margin-top: 25px
}

.mg-r-25 {
    margin-right: 25px
}

.mg-b-25 {
    margin-bottom: 25px
}

.mg-l-25 {
    margin-left: 25px
}

.mg-t-25-f {
    margin-top: 25px!important
}

.mg-r-25-f {
    margin-right: 25px!important
}

.mg-b-25-f {
    margin-bottom: 25px!important
}

.mg-l-25-f {
    margin-left: 25px!important
}

.mg-30 {
    margin: 30px
}

.mg-30-f {
    margin: 30px!important
}

.mg-y-30 {
    margin-top: 30px;
    margin-bottom: 30px
}

.mg-y-30-f {
    margin-top: 30px!important;
    margin-bottom: 30px!important
}

.mg-x-30 {
    margin-left: 30px;
    margin-right: 30px
}

.mg-x-30-f {
    margin-left: 30px!important;
    margin-right: 30px!important
}

.mg-t-30 {
    margin-top: 30px
}

.mg-r-30 {
    margin-right: 30px
}

.mg-b-30 {
    margin-bottom: 30px
}

.mg-l-30 {
    margin-left: 30px
}

.mg-t-30-f {
    margin-top: 30px!important
}

.mg-r-30-f {
    margin-right: 30px!important
}

.mg-b-30-f {
    margin-bottom: 30px!important
}

.mg-l-30-f {
    margin-left: 30px!important
}

.mg-35 {
    margin: 35px
}

.mg-35-f {
    margin: 35px!important
}

.mg-y-35 {
    margin-top: 35px;
    margin-bottom: 35px
}

.mg-y-35-f {
    margin-top: 35px!important;
    margin-bottom: 35px!important
}

.mg-x-35 {
    margin-left: 35px;
    margin-right: 35px
}

.mg-x-35-f {
    margin-left: 35px!important;
    margin-right: 35px!important
}

.mg-t-35 {
    margin-top: 35px
}

.mg-r-35 {
    margin-right: 35px
}

.mg-b-35 {
    margin-bottom: 35px
}

.mg-l-35 {
    margin-left: 35px
}

.mg-t-35-f {
    margin-top: 35px!important
}

.mg-r-35-f {
    margin-right: 35px!important
}

.mg-b-35-f {
    margin-bottom: 35px!important
}

.mg-l-35-f {
    margin-left: 35px!important
}

.mg-40 {
    margin: 40px
}

.mg-40-f {
    margin: 40px!important
}

.mg-y-40 {
    margin-top: 40px;
    margin-bottom: 40px
}

.mg-y-40-f {
    margin-top: 40px!important;
    margin-bottom: 40px!important
}

.mg-x-40 {
    margin-left: 40px;
    margin-right: 40px
}

.mg-x-40-f {
    margin-left: 40px!important;
    margin-right: 40px!important
}

.mg-t-40 {
    margin-top: 40px
}

.mg-r-40 {
    margin-right: 40px
}

.mg-b-40 {
    margin-bottom: 40px
}

.mg-l-40 {
    margin-left: 40px
}

.mg-t-40-f {
    margin-top: 40px!important
}

.mg-r-40-f {
    margin-right: 40px!important
}

.mg-b-40-f {
    margin-bottom: 40px!important
}

.mg-l-40-f {
    margin-left: 40px!important
}

.mg-45 {
    margin: 45px
}

.mg-45-f {
    margin: 45px!important
}

.mg-y-45 {
    margin-top: 45px;
    margin-bottom: 45px
}

.mg-y-45-f {
    margin-top: 45px!important;
    margin-bottom: 45px!important
}

.mg-x-45 {
    margin-left: 45px;
    margin-right: 45px
}

.mg-x-45-f {
    margin-left: 45px!important;
    margin-right: 45px!important
}

.mg-t-45 {
    margin-top: 45px
}

.mg-r-45 {
    margin-right: 45px
}

.mg-b-45 {
    margin-bottom: 45px
}

.mg-l-45 {
    margin-left: 45px
}

.mg-t-45-f {
    margin-top: 45px!important
}

.mg-r-45-f {
    margin-right: 45px!important
}

.mg-b-45-f {
    margin-bottom: 45px!important
}

.mg-l-45-f {
    margin-left: 45px!important
}

.mg-50 {
    margin: 50px
}

.mg-50-f {
    margin: 50px!important
}

.mg-y-50 {
    margin-top: 50px;
    margin-bottom: 50px
}

.mg-y-50-f {
    margin-top: 50px!important;
    margin-bottom: 50px!important
}

.mg-x-50 {
    margin-left: 50px;
    margin-right: 50px
}

.mg-x-50-f {
    margin-left: 50px!important;
    margin-right: 50px!important
}

.mg-t-50 {
    margin-top: 50px
}

.mg-r-50 {
    margin-right: 50px
}

.mg-b-50 {
    margin-bottom: 50px
}

.mg-l-50 {
    margin-left: 50px
}

.mg-t-50-f {
    margin-top: 50px!important
}

.mg-r-50-f {
    margin-right: 50px!important
}

.mg-b-50-f {
    margin-bottom: 50px!important
}

.mg-l-50-f {
    margin-left: 50px!important
}

.mg-55 {
    margin: 55px
}

.mg-55-f {
    margin: 55px!important
}

.mg-y-55 {
    margin-top: 55px;
    margin-bottom: 55px
}

.mg-y-55-f {
    margin-top: 55px!important;
    margin-bottom: 55px!important
}

.mg-x-55 {
    margin-left: 55px;
    margin-right: 55px
}

.mg-x-55-f {
    margin-left: 55px!important;
    margin-right: 55px!important
}

.mg-t-55 {
    margin-top: 55px
}

.mg-r-55 {
    margin-right: 55px
}

.mg-b-55 {
    margin-bottom: 55px
}

.mg-l-55 {
    margin-left: 55px
}

.mg-t-55-f {
    margin-top: 55px!important
}

.mg-r-55-f {
    margin-right: 55px!important
}

.mg-b-55-f {
    margin-bottom: 55px!important
}

.mg-l-55-f {
    margin-left: 55px!important
}

.mg-60 {
    margin: 60px
}

.mg-60-f {
    margin: 60px!important
}

.mg-y-60 {
    margin-top: 60px;
    margin-bottom: 60px
}

.mg-y-60-f {
    margin-top: 60px!important;
    margin-bottom: 60px!important
}

.mg-x-60 {
    margin-left: 60px;
    margin-right: 60px
}

.mg-x-60-f {
    margin-left: 60px!important;
    margin-right: 60px!important
}

.mg-t-60 {
    margin-top: 60px
}

.mg-r-60 {
    margin-right: 60px
}

.mg-b-60 {
    margin-bottom: 60px
}

.mg-l-60 {
    margin-left: 60px
}

.mg-t-60-f {
    margin-top: 60px!important
}

.mg-r-60-f {
    margin-right: 60px!important
}

.mg-b-60-f {
    margin-bottom: 60px!important
}

.mg-l-60-f {
    margin-left: 60px!important
}

.mg-65 {
    margin: 65px
}

.mg-65-f {
    margin: 65px!important
}

.mg-y-65 {
    margin-top: 65px;
    margin-bottom: 65px
}

.mg-y-65-f {
    margin-top: 65px!important;
    margin-bottom: 65px!important
}

.mg-x-65 {
    margin-left: 65px;
    margin-right: 65px
}

.mg-x-65-f {
    margin-left: 65px!important;
    margin-right: 65px!important
}

.mg-t-65 {
    margin-top: 65px
}

.mg-r-65 {
    margin-right: 65px
}

.mg-b-65 {
    margin-bottom: 65px
}

.mg-l-65 {
    margin-left: 65px
}

.mg-t-65-f {
    margin-top: 65px!important
}

.mg-r-65-f {
    margin-right: 65px!important
}

.mg-b-65-f {
    margin-bottom: 65px!important
}

.mg-l-65-f {
    margin-left: 65px!important
}

.mg-70 {
    margin: 70px
}

.mg-70-f {
    margin: 70px!important
}

.mg-y-70 {
    margin-top: 70px;
    margin-bottom: 70px
}

.mg-y-70-f {
    margin-top: 70px!important;
    margin-bottom: 70px!important
}

.mg-x-70 {
    margin-left: 70px;
    margin-right: 70px
}

.mg-x-70-f {
    margin-left: 70px!important;
    margin-right: 70px!important
}

.mg-t-70 {
    margin-top: 70px
}

.mg-r-70 {
    margin-right: 70px
}

.mg-b-70 {
    margin-bottom: 70px
}

.mg-l-70 {
    margin-left: 70px
}

.mg-t-70-f {
    margin-top: 70px!important
}

.mg-r-70-f {
    margin-right: 70px!important
}

.mg-b-70-f {
    margin-bottom: 70px!important
}

.mg-l-70-f {
    margin-left: 70px!important
}

.mg-75 {
    margin: 75px
}

.mg-75-f {
    margin: 75px!important
}

.mg-y-75 {
    margin-top: 75px;
    margin-bottom: 75px
}

.mg-y-75-f {
    margin-top: 75px!important;
    margin-bottom: 75px!important
}

.mg-x-75 {
    margin-left: 75px;
    margin-right: 75px
}

.mg-x-75-f {
    margin-left: 75px!important;
    margin-right: 75px!important
}

.mg-t-75 {
    margin-top: 75px
}

.mg-r-75 {
    margin-right: 75px
}

.mg-b-75 {
    margin-bottom: 75px
}

.mg-l-75 {
    margin-left: 75px
}

.mg-t-75-f {
    margin-top: 75px!important
}

.mg-r-75-f {
    margin-right: 75px!important
}

.mg-b-75-f {
    margin-bottom: 75px!important
}

.mg-l-75-f {
    margin-left: 75px!important
}

.mg-80 {
    margin: 80px
}

.mg-80-f {
    margin: 80px!important
}

.mg-y-80 {
    margin-top: 80px;
    margin-bottom: 80px
}

.mg-y-80-f {
    margin-top: 80px!important;
    margin-bottom: 80px!important
}

.mg-x-80 {
    margin-left: 80px;
    margin-right: 80px
}

.mg-x-80-f {
    margin-left: 80px!important;
    margin-right: 80px!important
}

.mg-t-80 {
    margin-top: 80px
}

.mg-r-80 {
    margin-right: 80px
}

.mg-b-80 {
    margin-bottom: 80px
}

.mg-l-80 {
    margin-left: 80px
}

.mg-t-80-f {
    margin-top: 80px!important
}

.mg-r-80-f {
    margin-right: 80px!important
}

.mg-b-80-f {
    margin-bottom: 80px!important
}

.mg-l-80-f {
    margin-left: 80px!important
}

.mg-85 {
    margin: 85px
}

.mg-85-f {
    margin: 85px!important
}

.mg-y-85 {
    margin-top: 85px;
    margin-bottom: 85px
}

.mg-y-85-f {
    margin-top: 85px!important;
    margin-bottom: 85px!important
}

.mg-x-85 {
    margin-left: 85px;
    margin-right: 85px
}

.mg-x-85-f {
    margin-left: 85px!important;
    margin-right: 85px!important
}

.mg-t-85 {
    margin-top: 85px
}

.mg-r-85 {
    margin-right: 85px
}

.mg-b-85 {
    margin-bottom: 85px
}

.mg-l-85 {
    margin-left: 85px
}

.mg-t-85-f {
    margin-top: 85px!important
}

.mg-r-85-f {
    margin-right: 85px!important
}

.mg-b-85-f {
    margin-bottom: 85px!important
}

.mg-l-85-f {
    margin-left: 85px!important
}

.mg-90 {
    margin: 90px
}

.mg-90-f {
    margin: 90px!important
}

.mg-y-90 {
    margin-top: 90px;
    margin-bottom: 90px
}

.mg-y-90-f {
    margin-top: 90px!important;
    margin-bottom: 90px!important
}

.mg-x-90 {
    margin-left: 90px;
    margin-right: 90px
}

.mg-x-90-f {
    margin-left: 90px!important;
    margin-right: 90px!important
}

.mg-t-90 {
    margin-top: 90px
}

.mg-r-90 {
    margin-right: 90px
}

.mg-b-90 {
    margin-bottom: 90px
}

.mg-l-90 {
    margin-left: 90px
}

.mg-t-90-f {
    margin-top: 90px!important
}

.mg-r-90-f {
    margin-right: 90px!important
}

.mg-b-90-f {
    margin-bottom: 90px!important
}

.mg-l-90-f {
    margin-left: 90px!important
}

.mg-95 {
    margin: 95px
}

.mg-95-f {
    margin: 95px!important
}

.mg-y-95 {
    margin-top: 95px;
    margin-bottom: 95px
}

.mg-y-95-f {
    margin-top: 95px!important;
    margin-bottom: 95px!important
}

.mg-x-95 {
    margin-left: 95px;
    margin-right: 95px
}

.mg-x-95-f {
    margin-left: 95px!important;
    margin-right: 95px!important
}

.mg-t-95 {
    margin-top: 95px
}

.mg-r-95 {
    margin-right: 95px
}

.mg-b-95 {
    margin-bottom: 95px
}

.mg-l-95 {
    margin-left: 95px
}

.mg-t-95-f {
    margin-top: 95px!important
}

.mg-r-95-f {
    margin-right: 95px!important
}

.mg-b-95-f {
    margin-bottom: 95px!important
}

.mg-l-95-f {
    margin-left: 95px!important
}

.mg-100 {
    margin: 100px
}

.mg-100-f {
    margin: 100px!important
}

.mg-y-100 {
    margin-top: 100px;
    margin-bottom: 100px
}

.mg-y-100-f {
    margin-top: 100px!important;
    margin-bottom: 100px!important
}

.mg-x-100 {
    margin-left: 100px;
    margin-right: 100px
}

.mg-x-100-f {
    margin-left: 100px!important;
    margin-right: 100px!important
}

.mg-t-100 {
    margin-top: 100px
}

.mg-r-100 {
    margin-right: 100px
}

.mg-b-100 {
    margin-bottom: 100px
}

.mg-l-100 {
    margin-left: 100px
}

.mg-t-100-f {
    margin-top: 100px!important
}

.mg-r-100-f {
    margin-right: 100px!important
}

.mg-b-100-f {
    margin-bottom: 100px!important
}

.mg-l-100-f {
    margin-left: 100px!important
}

.mg-105 {
    margin: 105px
}

.mg-105-f {
    margin: 105px!important
}

.mg-y-105 {
    margin-top: 105px;
    margin-bottom: 105px
}

.mg-y-105-f {
    margin-top: 105px!important;
    margin-bottom: 105px!important
}

.mg-x-105 {
    margin-left: 105px;
    margin-right: 105px
}

.mg-x-105-f {
    margin-left: 105px!important;
    margin-right: 105px!important
}

.mg-t-105 {
    margin-top: 105px
}

.mg-r-105 {
    margin-right: 105px
}

.mg-b-105 {
    margin-bottom: 105px
}

.mg-l-105 {
    margin-left: 105px
}

.mg-t-105-f {
    margin-top: 105px!important
}

.mg-r-105-f {
    margin-right: 105px!important
}

.mg-b-105-f {
    margin-bottom: 105px!important
}

.mg-l-105-f {
    margin-left: 105px!important
}

.mg-110 {
    margin: 110px
}

.mg-110-f {
    margin: 110px!important
}

.mg-y-110 {
    margin-top: 110px;
    margin-bottom: 110px
}

.mg-y-110-f {
    margin-top: 110px!important;
    margin-bottom: 110px!important
}

.mg-x-110 {
    margin-left: 110px;
    margin-right: 110px
}

.mg-x-110-f {
    margin-left: 110px!important;
    margin-right: 110px!important
}

.mg-t-110 {
    margin-top: 110px
}

.mg-r-110 {
    margin-right: 110px
}

.mg-b-110 {
    margin-bottom: 110px
}

.mg-l-110 {
    margin-left: 110px
}

.mg-t-110-f {
    margin-top: 110px!important
}

.mg-r-110-f {
    margin-right: 110px!important
}

.mg-b-110-f {
    margin-bottom: 110px!important
}

.mg-l-110-f {
    margin-left: 110px!important
}

.mg-115 {
    margin: 115px
}

.mg-115-f {
    margin: 115px!important
}

.mg-y-115 {
    margin-top: 115px;
    margin-bottom: 115px
}

.mg-y-115-f {
    margin-top: 115px!important;
    margin-bottom: 115px!important
}

.mg-x-115 {
    margin-left: 115px;
    margin-right: 115px
}

.mg-x-115-f {
    margin-left: 115px!important;
    margin-right: 115px!important
}

.mg-t-115 {
    margin-top: 115px
}

.mg-r-115 {
    margin-right: 115px
}

.mg-b-115 {
    margin-bottom: 115px
}

.mg-l-115 {
    margin-left: 115px
}

.mg-t-115-f {
    margin-top: 115px!important
}

.mg-r-115-f {
    margin-right: 115px!important
}

.mg-b-115-f {
    margin-bottom: 115px!important
}

.mg-l-115-f {
    margin-left: 115px!important
}

.mg-120 {
    margin: 120px
}

.mg-120-f {
    margin: 120px!important
}

.mg-y-120 {
    margin-top: 120px;
    margin-bottom: 120px
}

.mg-y-120-f {
    margin-top: 120px!important;
    margin-bottom: 120px!important
}

.mg-x-120 {
    margin-left: 120px;
    margin-right: 120px
}

.mg-x-120-f {
    margin-left: 120px!important;
    margin-right: 120px!important
}

.mg-t-120 {
    margin-top: 120px
}

.mg-r-120 {
    margin-right: 120px
}

.mg-b-120 {
    margin-bottom: 120px
}

.mg-l-120 {
    margin-left: 120px
}

.mg-t-120-f {
    margin-top: 120px!important
}

.mg-r-120-f {
    margin-right: 120px!important
}

.mg-b-120-f {
    margin-bottom: 120px!important
}

.mg-l-120-f {
    margin-left: 120px!important
}

.mg-t-auto {
    margin-top: auto
}

.mg-r-auto {
    margin-right: auto
}

.mg-b-auto {
    margin-bottom: auto
}

.mg-l-auto {
    margin-left: auto
}

.mg-x-auto {
    margin: auto
}

@media (min-width:480px) {
    .mg-xs-0 {
        margin: 0
    }
    .mg-xs-0-f {
        margin: 0!important
    }
    .mg-xs-y-0 {
        margin-top: 0;
        margin-bottom: 0
    }
    .mg-xs-y-0-f {
        margin-top: 0!important;
        margin-bottom: 0!important
    }
    .mg-xs-x-0 {
        margin-left: 0;
        margin-right: 0
    }
    .mg-xs-x-0-f {
        margin-left: 0!important;
        margin-right: 0!important
    }
    .mg-xs-t-0 {
        margin-top: 0
    }
    .mg-xs-r-0 {
        margin-right: 0
    }
    .mg-xs-b-0 {
        margin-bottom: 0
    }
    .mg-xs-l-0 {
        margin-left: 0
    }
    .mg-xs-t-0-f {
        margin-top: 0!important
    }
    .mg-xs-r-0-f {
        margin-right: 0!important
    }
    .mg-xs-b-0-f {
        margin-bottom: 0!important
    }
    .mg-xs-l-0-f {
        margin-left: 0!important
    }
    .mg-xs-1 {
        margin: 1px
    }
    .mg-xs-1-f {
        margin: 1px!important
    }
    .mg-xs-y-1 {
        margin-top: 1px;
        margin-bottom: 1px
    }
    .mg-xs-y-1-f {
        margin-top: 1px!important;
        margin-bottom: 1px!important
    }
    .mg-xs-x-1 {
        margin-left: 1px;
        margin-right: 1px
    }
    .mg-xs-x-1-f {
        margin-left: 1px!important;
        margin-right: 1px!important
    }
    .mg-xs-t-1 {
        margin-top: 1px
    }
    .mg-xs-r-1 {
        margin-right: 1px
    }
    .mg-xs-b-1 {
        margin-bottom: 1px
    }
    .mg-xs-l-1 {
        margin-left: 1px
    }
    .mg-xs-t-1-f {
        margin-top: 1px!important
    }
    .mg-xs-r-1-f {
        margin-right: 1px!important
    }
    .mg-xs-b-1-f {
        margin-bottom: 1px!important
    }
    .mg-xs-l-1-f {
        margin-left: 1px!important
    }
    .mg-xs-2 {
        margin: 2px
    }
    .mg-xs-2-f {
        margin: 2px!important
    }
    .mg-xs-y-2 {
        margin-top: 2px;
        margin-bottom: 2px
    }
    .mg-xs-y-2-f {
        margin-top: 2px!important;
        margin-bottom: 2px!important
    }
    .mg-xs-x-2 {
        margin-left: 2px;
        margin-right: 2px
    }
    .mg-xs-x-2-f {
        margin-left: 2px!important;
        margin-right: 2px!important
    }
    .mg-xs-t-2 {
        margin-top: 2px
    }
    .mg-xs-r-2 {
        margin-right: 2px
    }
    .mg-xs-b-2 {
        margin-bottom: 2px
    }
    .mg-xs-l-2 {
        margin-left: 2px
    }
    .mg-xs-t-2-f {
        margin-top: 2px!important
    }
    .mg-xs-r-2-f {
        margin-right: 2px!important
    }
    .mg-xs-b-2-f {
        margin-bottom: 2px!important
    }
    .mg-xs-l-2-f {
        margin-left: 2px!important
    }
    .mg-xs-3 {
        margin: 3px
    }
    .mg-xs-3-f {
        margin: 3px!important
    }
    .mg-xs-y-3 {
        margin-top: 3px;
        margin-bottom: 3px
    }
    .mg-xs-y-3-f {
        margin-top: 3px!important;
        margin-bottom: 3px!important
    }
    .mg-xs-x-3 {
        margin-left: 3px;
        margin-right: 3px
    }
    .mg-xs-x-3-f {
        margin-left: 3px!important;
        margin-right: 3px!important
    }
    .mg-xs-t-3 {
        margin-top: 3px
    }
    .mg-xs-r-3 {
        margin-right: 3px
    }
    .mg-xs-b-3 {
        margin-bottom: 3px
    }
    .mg-xs-l-3 {
        margin-left: 3px
    }
    .mg-xs-t-3-f {
        margin-top: 3px!important
    }
    .mg-xs-r-3-f {
        margin-right: 3px!important
    }
    .mg-xs-b-3-f {
        margin-bottom: 3px!important
    }
    .mg-xs-l-3-f {
        margin-left: 3px!important
    }
    .mg-xs-4 {
        margin: 4px
    }
    .mg-xs-4-f {
        margin: 4px!important
    }
    .mg-xs-y-4 {
        margin-top: 4px;
        margin-bottom: 4px
    }
    .mg-xs-y-4-f {
        margin-top: 4px!important;
        margin-bottom: 4px!important
    }
    .mg-xs-x-4 {
        margin-left: 4px;
        margin-right: 4px
    }
    .mg-xs-x-4-f {
        margin-left: 4px!important;
        margin-right: 4px!important
    }
    .mg-xs-t-4 {
        margin-top: 4px
    }
    .mg-xs-r-4 {
        margin-right: 4px
    }
    .mg-xs-b-4 {
        margin-bottom: 4px
    }
    .mg-xs-l-4 {
        margin-left: 4px
    }
    .mg-xs-t-4-f {
        margin-top: 4px!important
    }
    .mg-xs-r-4-f {
        margin-right: 4px!important
    }
    .mg-xs-b-4-f {
        margin-bottom: 4px!important
    }
    .mg-xs-l-4-f {
        margin-left: 4px!important
    }
    .mg-xs-5 {
        margin: 5px
    }
    .mg-xs-5-f {
        margin: 5px!important
    }
    .mg-xs-y-5 {
        margin-top: 5px;
        margin-bottom: 5px
    }
    .mg-xs-y-5-f {
        margin-top: 5px!important;
        margin-bottom: 5px!important
    }
    .mg-xs-x-5 {
        margin-left: 5px;
        margin-right: 5px
    }
    .mg-xs-x-5-f {
        margin-left: 5px!important;
        margin-right: 5px!important
    }
    .mg-xs-t-5 {
        margin-top: 5px
    }
    .mg-xs-r-5 {
        margin-right: 5px
    }
    .mg-xs-b-5 {
        margin-bottom: 5px
    }
    .mg-xs-l-5 {
        margin-left: 5px
    }
    .mg-xs-t-5-f {
        margin-top: 5px!important
    }
    .mg-xs-r-5-f {
        margin-right: 5px!important
    }
    .mg-xs-b-5-f {
        margin-bottom: 5px!important
    }
    .mg-xs-l-5-f {
        margin-left: 5px!important
    }
    .mg-xs-6 {
        margin: 6px
    }
    .mg-xs-6-f {
        margin: 6px!important
    }
    .mg-xs-y-6 {
        margin-top: 6px;
        margin-bottom: 6px
    }
    .mg-xs-y-6-f {
        margin-top: 6px!important;
        margin-bottom: 6px!important
    }
    .mg-xs-x-6 {
        margin-left: 6px;
        margin-right: 6px
    }
    .mg-xs-x-6-f {
        margin-left: 6px!important;
        margin-right: 6px!important
    }
    .mg-xs-t-6 {
        margin-top: 6px
    }
    .mg-xs-r-6 {
        margin-right: 6px
    }
    .mg-xs-b-6 {
        margin-bottom: 6px
    }
    .mg-xs-l-6 {
        margin-left: 6px
    }
    .mg-xs-t-6-f {
        margin-top: 6px!important
    }
    .mg-xs-r-6-f {
        margin-right: 6px!important
    }
    .mg-xs-b-6-f {
        margin-bottom: 6px!important
    }
    .mg-xs-l-6-f {
        margin-left: 6px!important
    }
    .mg-xs-7 {
        margin: 7px
    }
    .mg-xs-7-f {
        margin: 7px!important
    }
    .mg-xs-y-7 {
        margin-top: 7px;
        margin-bottom: 7px
    }
    .mg-xs-y-7-f {
        margin-top: 7px!important;
        margin-bottom: 7px!important
    }
    .mg-xs-x-7 {
        margin-left: 7px;
        margin-right: 7px
    }
    .mg-xs-x-7-f {
        margin-left: 7px!important;
        margin-right: 7px!important
    }
    .mg-xs-t-7 {
        margin-top: 7px
    }
    .mg-xs-r-7 {
        margin-right: 7px
    }
    .mg-xs-b-7 {
        margin-bottom: 7px
    }
    .mg-xs-l-7 {
        margin-left: 7px
    }
    .mg-xs-t-7-f {
        margin-top: 7px!important
    }
    .mg-xs-r-7-f {
        margin-right: 7px!important
    }
    .mg-xs-b-7-f {
        margin-bottom: 7px!important
    }
    .mg-xs-l-7-f {
        margin-left: 7px!important
    }
    .mg-xs-8 {
        margin: 8px
    }
    .mg-xs-8-f {
        margin: 8px!important
    }
    .mg-xs-y-8 {
        margin-top: 8px;
        margin-bottom: 8px
    }
    .mg-xs-y-8-f {
        margin-top: 8px!important;
        margin-bottom: 8px!important
    }
    .mg-xs-x-8 {
        margin-left: 8px;
        margin-right: 8px
    }
    .mg-xs-x-8-f {
        margin-left: 8px!important;
        margin-right: 8px!important
    }
    .mg-xs-t-8 {
        margin-top: 8px
    }
    .mg-xs-r-8 {
        margin-right: 8px
    }
    .mg-xs-b-8 {
        margin-bottom: 8px
    }
    .mg-xs-l-8 {
        margin-left: 8px
    }
    .mg-xs-t-8-f {
        margin-top: 8px!important
    }
    .mg-xs-r-8-f {
        margin-right: 8px!important
    }
    .mg-xs-b-8-f {
        margin-bottom: 8px!important
    }
    .mg-xs-l-8-f {
        margin-left: 8px!important
    }
    .mg-xs-9 {
        margin: 9px
    }
    .mg-xs-9-f {
        margin: 9px!important
    }
    .mg-xs-y-9 {
        margin-top: 9px;
        margin-bottom: 9px
    }
    .mg-xs-y-9-f {
        margin-top: 9px!important;
        margin-bottom: 9px!important
    }
    .mg-xs-x-9 {
        margin-left: 9px;
        margin-right: 9px
    }
    .mg-xs-x-9-f {
        margin-left: 9px!important;
        margin-right: 9px!important
    }
    .mg-xs-t-9 {
        margin-top: 9px
    }
    .mg-xs-r-9 {
        margin-right: 9px
    }
    .mg-xs-b-9 {
        margin-bottom: 9px
    }
    .mg-xs-l-9 {
        margin-left: 9px
    }
    .mg-xs-t-9-f {
        margin-top: 9px!important
    }
    .mg-xs-r-9-f {
        margin-right: 9px!important
    }
    .mg-xs-b-9-f {
        margin-bottom: 9px!important
    }
    .mg-xs-l-9-f {
        margin-left: 9px!important
    }
    .mg-xs-10 {
        margin: 10px
    }
    .mg-xs-10-f {
        margin: 10px!important
    }
    .mg-xs-y-10 {
        margin-top: 10px;
        margin-bottom: 10px
    }
    .mg-xs-y-10-f {
        margin-top: 10px!important;
        margin-bottom: 10px!important
    }
    .mg-xs-x-10 {
        margin-left: 10px;
        margin-right: 10px
    }
    .mg-xs-x-10-f {
        margin-left: 10px!important;
        margin-right: 10px!important
    }
    .mg-xs-t-10 {
        margin-top: 10px
    }
    .mg-xs-r-10 {
        margin-right: 10px
    }
    .mg-xs-b-10 {
        margin-bottom: 10px
    }
    .mg-xs-l-10 {
        margin-left: 10px
    }
    .mg-xs-t-10-f {
        margin-top: 10px!important
    }
    .mg-xs-r-10-f {
        margin-right: 10px!important
    }
    .mg-xs-b-10-f {
        margin-bottom: 10px!important
    }
    .mg-xs-l-10-f {
        margin-left: 10px!important
    }
    .mg-xs-15 {
        margin: 15px
    }
    .mg-xs-15-f {
        margin: 15px!important
    }
    .mg-xs-y-15 {
        margin-top: 15px;
        margin-bottom: 15px
    }
    .mg-xs-y-15-f {
        margin-top: 15px!important;
        margin-bottom: 15px!important
    }
    .mg-xs-x-15 {
        margin-left: 15px;
        margin-right: 15px
    }
    .mg-xs-x-15-f {
        margin-left: 15px!important;
        margin-right: 15px!important
    }
    .mg-xs-t-15 {
        margin-top: 15px
    }
    .mg-xs-r-15 {
        margin-right: 15px
    }
    .mg-xs-b-15 {
        margin-bottom: 15px
    }
    .mg-xs-l-15 {
        margin-left: 15px
    }
    .mg-xs-t-15-f {
        margin-top: 15px!important
    }
    .mg-xs-r-15-f {
        margin-right: 15px!important
    }
    .mg-xs-b-15-f {
        margin-bottom: 15px!important
    }
    .mg-xs-l-15-f {
        margin-left: 15px!important
    }
    .mg-xs-20 {
        margin: 20px
    }
    .mg-xs-20-f {
        margin: 20px!important
    }
    .mg-xs-y-20 {
        margin-top: 20px;
        margin-bottom: 20px
    }
    .mg-xs-y-20-f {
        margin-top: 20px!important;
        margin-bottom: 20px!important
    }
    .mg-xs-x-20 {
        margin-left: 20px;
        margin-right: 20px
    }
    .mg-xs-x-20-f {
        margin-left: 20px!important;
        margin-right: 20px!important
    }
    .mg-xs-t-20 {
        margin-top: 20px
    }
    .mg-xs-r-20 {
        margin-right: 20px
    }
    .mg-xs-b-20 {
        margin-bottom: 20px
    }
    .mg-xs-l-20 {
        margin-left: 20px
    }
    .mg-xs-t-20-f {
        margin-top: 20px!important
    }
    .mg-xs-r-20-f {
        margin-right: 20px!important
    }
    .mg-xs-b-20-f {
        margin-bottom: 20px!important
    }
    .mg-xs-l-20-f {
        margin-left: 20px!important
    }
    .mg-xs-25 {
        margin: 25px
    }
    .mg-xs-25-f {
        margin: 25px!important
    }
    .mg-xs-y-25 {
        margin-top: 25px;
        margin-bottom: 25px
    }
    .mg-xs-y-25-f {
        margin-top: 25px!important;
        margin-bottom: 25px!important
    }
    .mg-xs-x-25 {
        margin-left: 25px;
        margin-right: 25px
    }
    .mg-xs-x-25-f {
        margin-left: 25px!important;
        margin-right: 25px!important
    }
    .mg-xs-t-25 {
        margin-top: 25px
    }
    .mg-xs-r-25 {
        margin-right: 25px
    }
    .mg-xs-b-25 {
        margin-bottom: 25px
    }
    .mg-xs-l-25 {
        margin-left: 25px
    }
    .mg-xs-t-25-f {
        margin-top: 25px!important
    }
    .mg-xs-r-25-f {
        margin-right: 25px!important
    }
    .mg-xs-b-25-f {
        margin-bottom: 25px!important
    }
    .mg-xs-l-25-f {
        margin-left: 25px!important
    }
    .mg-xs-30 {
        margin: 30px
    }
    .mg-xs-30-f {
        margin: 30px!important
    }
    .mg-xs-y-30 {
        margin-top: 30px;
        margin-bottom: 30px
    }
    .mg-xs-y-30-f {
        margin-top: 30px!important;
        margin-bottom: 30px!important
    }
    .mg-xs-x-30 {
        margin-left: 30px;
        margin-right: 30px
    }
    .mg-xs-x-30-f {
        margin-left: 30px!important;
        margin-right: 30px!important
    }
    .mg-xs-t-30 {
        margin-top: 30px
    }
    .mg-xs-r-30 {
        margin-right: 30px
    }
    .mg-xs-b-30 {
        margin-bottom: 30px
    }
    .mg-xs-l-30 {
        margin-left: 30px
    }
    .mg-xs-t-30-f {
        margin-top: 30px!important
    }
    .mg-xs-r-30-f {
        margin-right: 30px!important
    }
    .mg-xs-b-30-f {
        margin-bottom: 30px!important
    }
    .mg-xs-l-30-f {
        margin-left: 30px!important
    }
    .mg-xs-35 {
        margin: 35px
    }
    .mg-xs-35-f {
        margin: 35px!important
    }
    .mg-xs-y-35 {
        margin-top: 35px;
        margin-bottom: 35px
    }
    .mg-xs-y-35-f {
        margin-top: 35px!important;
        margin-bottom: 35px!important
    }
    .mg-xs-x-35 {
        margin-left: 35px;
        margin-right: 35px
    }
    .mg-xs-x-35-f {
        margin-left: 35px!important;
        margin-right: 35px!important
    }
    .mg-xs-t-35 {
        margin-top: 35px
    }
    .mg-xs-r-35 {
        margin-right: 35px
    }
    .mg-xs-b-35 {
        margin-bottom: 35px
    }
    .mg-xs-l-35 {
        margin-left: 35px
    }
    .mg-xs-t-35-f {
        margin-top: 35px!important
    }
    .mg-xs-r-35-f {
        margin-right: 35px!important
    }
    .mg-xs-b-35-f {
        margin-bottom: 35px!important
    }
    .mg-xs-l-35-f {
        margin-left: 35px!important
    }
    .mg-xs-40 {
        margin: 40px
    }
    .mg-xs-40-f {
        margin: 40px!important
    }
    .mg-xs-y-40 {
        margin-top: 40px;
        margin-bottom: 40px
    }
    .mg-xs-y-40-f {
        margin-top: 40px!important;
        margin-bottom: 40px!important
    }
    .mg-xs-x-40 {
        margin-left: 40px;
        margin-right: 40px
    }
    .mg-xs-x-40-f {
        margin-left: 40px!important;
        margin-right: 40px!important
    }
    .mg-xs-t-40 {
        margin-top: 40px
    }
    .mg-xs-r-40 {
        margin-right: 40px
    }
    .mg-xs-b-40 {
        margin-bottom: 40px
    }
    .mg-xs-l-40 {
        margin-left: 40px
    }
    .mg-xs-t-40-f {
        margin-top: 40px!important
    }
    .mg-xs-r-40-f {
        margin-right: 40px!important
    }
    .mg-xs-b-40-f {
        margin-bottom: 40px!important
    }
    .mg-xs-l-40-f {
        margin-left: 40px!important
    }
    .mg-xs-45 {
        margin: 45px
    }
    .mg-xs-45-f {
        margin: 45px!important
    }
    .mg-xs-y-45 {
        margin-top: 45px;
        margin-bottom: 45px
    }
    .mg-xs-y-45-f {
        margin-top: 45px!important;
        margin-bottom: 45px!important
    }
    .mg-xs-x-45 {
        margin-left: 45px;
        margin-right: 45px
    }
    .mg-xs-x-45-f {
        margin-left: 45px!important;
        margin-right: 45px!important
    }
    .mg-xs-t-45 {
        margin-top: 45px
    }
    .mg-xs-r-45 {
        margin-right: 45px
    }
    .mg-xs-b-45 {
        margin-bottom: 45px
    }
    .mg-xs-l-45 {
        margin-left: 45px
    }
    .mg-xs-t-45-f {
        margin-top: 45px!important
    }
    .mg-xs-r-45-f {
        margin-right: 45px!important
    }
    .mg-xs-b-45-f {
        margin-bottom: 45px!important
    }
    .mg-xs-l-45-f {
        margin-left: 45px!important
    }
    .mg-xs-50 {
        margin: 50px
    }
    .mg-xs-50-f {
        margin: 50px!important
    }
    .mg-xs-y-50 {
        margin-top: 50px;
        margin-bottom: 50px
    }
    .mg-xs-y-50-f {
        margin-top: 50px!important;
        margin-bottom: 50px!important
    }
    .mg-xs-x-50 {
        margin-left: 50px;
        margin-right: 50px
    }
    .mg-xs-x-50-f {
        margin-left: 50px!important;
        margin-right: 50px!important
    }
    .mg-xs-t-50 {
        margin-top: 50px
    }
    .mg-xs-r-50 {
        margin-right: 50px
    }
    .mg-xs-b-50 {
        margin-bottom: 50px
    }
    .mg-xs-l-50 {
        margin-left: 50px
    }
    .mg-xs-t-50-f {
        margin-top: 50px!important
    }
    .mg-xs-r-50-f {
        margin-right: 50px!important
    }
    .mg-xs-b-50-f {
        margin-bottom: 50px!important
    }
    .mg-xs-l-50-f {
        margin-left: 50px!important
    }
    .mg-xs-55 {
        margin: 55px
    }
    .mg-xs-55-f {
        margin: 55px!important
    }
    .mg-xs-y-55 {
        margin-top: 55px;
        margin-bottom: 55px
    }
    .mg-xs-y-55-f {
        margin-top: 55px!important;
        margin-bottom: 55px!important
    }
    .mg-xs-x-55 {
        margin-left: 55px;
        margin-right: 55px
    }
    .mg-xs-x-55-f {
        margin-left: 55px!important;
        margin-right: 55px!important
    }
    .mg-xs-t-55 {
        margin-top: 55px
    }
    .mg-xs-r-55 {
        margin-right: 55px
    }
    .mg-xs-b-55 {
        margin-bottom: 55px
    }
    .mg-xs-l-55 {
        margin-left: 55px
    }
    .mg-xs-t-55-f {
        margin-top: 55px!important
    }
    .mg-xs-r-55-f {
        margin-right: 55px!important
    }
    .mg-xs-b-55-f {
        margin-bottom: 55px!important
    }
    .mg-xs-l-55-f {
        margin-left: 55px!important
    }
    .mg-xs-60 {
        margin: 60px
    }
    .mg-xs-60-f {
        margin: 60px!important
    }
    .mg-xs-y-60 {
        margin-top: 60px;
        margin-bottom: 60px
    }
    .mg-xs-y-60-f {
        margin-top: 60px!important;
        margin-bottom: 60px!important
    }
    .mg-xs-x-60 {
        margin-left: 60px;
        margin-right: 60px
    }
    .mg-xs-x-60-f {
        margin-left: 60px!important;
        margin-right: 60px!important
    }
    .mg-xs-t-60 {
        margin-top: 60px
    }
    .mg-xs-r-60 {
        margin-right: 60px
    }
    .mg-xs-b-60 {
        margin-bottom: 60px
    }
    .mg-xs-l-60 {
        margin-left: 60px
    }
    .mg-xs-t-60-f {
        margin-top: 60px!important
    }
    .mg-xs-r-60-f {
        margin-right: 60px!important
    }
    .mg-xs-b-60-f {
        margin-bottom: 60px!important
    }
    .mg-xs-l-60-f {
        margin-left: 60px!important
    }
    .mg-xs-65 {
        margin: 65px
    }
    .mg-xs-65-f {
        margin: 65px!important
    }
    .mg-xs-y-65 {
        margin-top: 65px;
        margin-bottom: 65px
    }
    .mg-xs-y-65-f {
        margin-top: 65px!important;
        margin-bottom: 65px!important
    }
    .mg-xs-x-65 {
        margin-left: 65px;
        margin-right: 65px
    }
    .mg-xs-x-65-f {
        margin-left: 65px!important;
        margin-right: 65px!important
    }
    .mg-xs-t-65 {
        margin-top: 65px
    }
    .mg-xs-r-65 {
        margin-right: 65px
    }
    .mg-xs-b-65 {
        margin-bottom: 65px
    }
    .mg-xs-l-65 {
        margin-left: 65px
    }
    .mg-xs-t-65-f {
        margin-top: 65px!important
    }
    .mg-xs-r-65-f {
        margin-right: 65px!important
    }
    .mg-xs-b-65-f {
        margin-bottom: 65px!important
    }
    .mg-xs-l-65-f {
        margin-left: 65px!important
    }
    .mg-xs-70 {
        margin: 70px
    }
    .mg-xs-70-f {
        margin: 70px!important
    }
    .mg-xs-y-70 {
        margin-top: 70px;
        margin-bottom: 70px
    }
    .mg-xs-y-70-f {
        margin-top: 70px!important;
        margin-bottom: 70px!important
    }
    .mg-xs-x-70 {
        margin-left: 70px;
        margin-right: 70px
    }
    .mg-xs-x-70-f {
        margin-left: 70px!important;
        margin-right: 70px!important
    }
    .mg-xs-t-70 {
        margin-top: 70px
    }
    .mg-xs-r-70 {
        margin-right: 70px
    }
    .mg-xs-b-70 {
        margin-bottom: 70px
    }
    .mg-xs-l-70 {
        margin-left: 70px
    }
    .mg-xs-t-70-f {
        margin-top: 70px!important
    }
    .mg-xs-r-70-f {
        margin-right: 70px!important
    }
    .mg-xs-b-70-f {
        margin-bottom: 70px!important
    }
    .mg-xs-l-70-f {
        margin-left: 70px!important
    }
    .mg-xs-75 {
        margin: 75px
    }
    .mg-xs-75-f {
        margin: 75px!important
    }
    .mg-xs-y-75 {
        margin-top: 75px;
        margin-bottom: 75px
    }
    .mg-xs-y-75-f {
        margin-top: 75px!important;
        margin-bottom: 75px!important
    }
    .mg-xs-x-75 {
        margin-left: 75px;
        margin-right: 75px
    }
    .mg-xs-x-75-f {
        margin-left: 75px!important;
        margin-right: 75px!important
    }
    .mg-xs-t-75 {
        margin-top: 75px
    }
    .mg-xs-r-75 {
        margin-right: 75px
    }
    .mg-xs-b-75 {
        margin-bottom: 75px
    }
    .mg-xs-l-75 {
        margin-left: 75px
    }
    .mg-xs-t-75-f {
        margin-top: 75px!important
    }
    .mg-xs-r-75-f {
        margin-right: 75px!important
    }
    .mg-xs-b-75-f {
        margin-bottom: 75px!important
    }
    .mg-xs-l-75-f {
        margin-left: 75px!important
    }
    .mg-xs-80 {
        margin: 80px
    }
    .mg-xs-80-f {
        margin: 80px!important
    }
    .mg-xs-y-80 {
        margin-top: 80px;
        margin-bottom: 80px
    }
    .mg-xs-y-80-f {
        margin-top: 80px!important;
        margin-bottom: 80px!important
    }
    .mg-xs-x-80 {
        margin-left: 80px;
        margin-right: 80px
    }
    .mg-xs-x-80-f {
        margin-left: 80px!important;
        margin-right: 80px!important
    }
    .mg-xs-t-80 {
        margin-top: 80px
    }
    .mg-xs-r-80 {
        margin-right: 80px
    }
    .mg-xs-b-80 {
        margin-bottom: 80px
    }
    .mg-xs-l-80 {
        margin-left: 80px
    }
    .mg-xs-t-80-f {
        margin-top: 80px!important
    }
    .mg-xs-r-80-f {
        margin-right: 80px!important
    }
    .mg-xs-b-80-f {
        margin-bottom: 80px!important
    }
    .mg-xs-l-80-f {
        margin-left: 80px!important
    }
    .mg-xs-85 {
        margin: 85px
    }
    .mg-xs-85-f {
        margin: 85px!important
    }
    .mg-xs-y-85 {
        margin-top: 85px;
        margin-bottom: 85px
    }
    .mg-xs-y-85-f {
        margin-top: 85px!important;
        margin-bottom: 85px!important
    }
    .mg-xs-x-85 {
        margin-left: 85px;
        margin-right: 85px
    }
    .mg-xs-x-85-f {
        margin-left: 85px!important;
        margin-right: 85px!important
    }
    .mg-xs-t-85 {
        margin-top: 85px
    }
    .mg-xs-r-85 {
        margin-right: 85px
    }
    .mg-xs-b-85 {
        margin-bottom: 85px
    }
    .mg-xs-l-85 {
        margin-left: 85px
    }
    .mg-xs-t-85-f {
        margin-top: 85px!important
    }
    .mg-xs-r-85-f {
        margin-right: 85px!important
    }
    .mg-xs-b-85-f {
        margin-bottom: 85px!important
    }
    .mg-xs-l-85-f {
        margin-left: 85px!important
    }
    .mg-xs-90 {
        margin: 90px
    }
    .mg-xs-90-f {
        margin: 90px!important
    }
    .mg-xs-y-90 {
        margin-top: 90px;
        margin-bottom: 90px
    }
    .mg-xs-y-90-f {
        margin-top: 90px!important;
        margin-bottom: 90px!important
    }
    .mg-xs-x-90 {
        margin-left: 90px;
        margin-right: 90px
    }
    .mg-xs-x-90-f {
        margin-left: 90px!important;
        margin-right: 90px!important
    }
    .mg-xs-t-90 {
        margin-top: 90px
    }
    .mg-xs-r-90 {
        margin-right: 90px
    }
    .mg-xs-b-90 {
        margin-bottom: 90px
    }
    .mg-xs-l-90 {
        margin-left: 90px
    }
    .mg-xs-t-90-f {
        margin-top: 90px!important
    }
    .mg-xs-r-90-f {
        margin-right: 90px!important
    }
    .mg-xs-b-90-f {
        margin-bottom: 90px!important
    }
    .mg-xs-l-90-f {
        margin-left: 90px!important
    }
    .mg-xs-95 {
        margin: 95px
    }
    .mg-xs-95-f {
        margin: 95px!important
    }
    .mg-xs-y-95 {
        margin-top: 95px;
        margin-bottom: 95px
    }
    .mg-xs-y-95-f {
        margin-top: 95px!important;
        margin-bottom: 95px!important
    }
    .mg-xs-x-95 {
        margin-left: 95px;
        margin-right: 95px
    }
    .mg-xs-x-95-f {
        margin-left: 95px!important;
        margin-right: 95px!important
    }
    .mg-xs-t-95 {
        margin-top: 95px
    }
    .mg-xs-r-95 {
        margin-right: 95px
    }
    .mg-xs-b-95 {
        margin-bottom: 95px
    }
    .mg-xs-l-95 {
        margin-left: 95px
    }
    .mg-xs-t-95-f {
        margin-top: 95px!important
    }
    .mg-xs-r-95-f {
        margin-right: 95px!important
    }
    .mg-xs-b-95-f {
        margin-bottom: 95px!important
    }
    .mg-xs-l-95-f {
        margin-left: 95px!important
    }
    .mg-xs-100 {
        margin: 100px
    }
    .mg-xs-100-f {
        margin: 100px!important
    }
    .mg-xs-y-100 {
        margin-top: 100px;
        margin-bottom: 100px
    }
    .mg-xs-y-100-f {
        margin-top: 100px!important;
        margin-bottom: 100px!important
    }
    .mg-xs-x-100 {
        margin-left: 100px;
        margin-right: 100px
    }
    .mg-xs-x-100-f {
        margin-left: 100px!important;
        margin-right: 100px!important
    }
    .mg-xs-t-100 {
        margin-top: 100px
    }
    .mg-xs-r-100 {
        margin-right: 100px
    }
    .mg-xs-b-100 {
        margin-bottom: 100px
    }
    .mg-xs-l-100 {
        margin-left: 100px
    }
    .mg-xs-t-100-f {
        margin-top: 100px!important
    }
    .mg-xs-r-100-f {
        margin-right: 100px!important
    }
    .mg-xs-b-100-f {
        margin-bottom: 100px!important
    }
    .mg-xs-l-100-f {
        margin-left: 100px!important
    }
    .mg-xs-t-auto {
        margin-top: auto
    }
    .mg-xs-r-auto {
        margin-right: auto
    }
    .mg-xs-b-auto {
        margin-bottom: auto
    }
    .mg-xs-l-auto {
        margin-left: auto
    }
    .mg-xs-auto {
        margin: auto
    }
    .mg-xs-x-auto {
        margin-left: auto;
        margin-right: auto
    }
}

@media (min-width:576px) {
    .mg-sm-0 {
        margin: 0
    }
    .mg-sm-0-f {
        margin: 0!important
    }
    .mg-sm-y-0 {
        margin-top: 0;
        margin-bottom: 0
    }
    .mg-sm-y-0-f {
        margin-top: 0!important;
        margin-bottom: 0!important
    }
    .mg-sm-x-0 {
        margin-left: 0;
        margin-right: 0
    }
    .mg-sm-x-0-f {
        margin-left: 0!important;
        margin-right: 0!important
    }
    .mg-sm-t-0 {
        margin-top: 0
    }
    .mg-sm-r-0 {
        margin-right: 0
    }
    .mg-sm-b-0 {
        margin-bottom: 0
    }
    .mg-sm-l-0 {
        margin-left: 0
    }
    .mg-sm-t-0-f {
        margin-top: 0!important
    }
    .mg-sm-r-0-f {
        margin-right: 0!important
    }
    .mg-sm-b-0-f {
        margin-bottom: 0!important
    }
    .mg-sm-l-0-f {
        margin-left: 0!important
    }
    .mg-sm-1 {
        margin: 1px
    }
    .mg-sm-1-f {
        margin: 1px!important
    }
    .mg-sm-y-1 {
        margin-top: 1px;
        margin-bottom: 1px
    }
    .mg-sm-y-1-f {
        margin-top: 1px!important;
        margin-bottom: 1px!important
    }
    .mg-sm-x-1 {
        margin-left: 1px;
        margin-right: 1px
    }
    .mg-sm-x-1-f {
        margin-left: 1px!important;
        margin-right: 1px!important
    }
    .mg-sm-t-1 {
        margin-top: 1px
    }
    .mg-sm-r-1 {
        margin-right: 1px
    }
    .mg-sm-b-1 {
        margin-bottom: 1px
    }
    .mg-sm-l-1 {
        margin-left: 1px
    }
    .mg-sm-t-1-f {
        margin-top: 1px!important
    }
    .mg-sm-r-1-f {
        margin-right: 1px!important
    }
    .mg-sm-b-1-f {
        margin-bottom: 1px!important
    }
    .mg-sm-l-1-f {
        margin-left: 1px!important
    }
    .mg-sm-2 {
        margin: 2px
    }
    .mg-sm-2-f {
        margin: 2px!important
    }
    .mg-sm-y-2 {
        margin-top: 2px;
        margin-bottom: 2px
    }
    .mg-sm-y-2-f {
        margin-top: 2px!important;
        margin-bottom: 2px!important
    }
    .mg-sm-x-2 {
        margin-left: 2px;
        margin-right: 2px
    }
    .mg-sm-x-2-f {
        margin-left: 2px!important;
        margin-right: 2px!important
    }
    .mg-sm-t-2 {
        margin-top: 2px
    }
    .mg-sm-r-2 {
        margin-right: 2px
    }
    .mg-sm-b-2 {
        margin-bottom: 2px
    }
    .mg-sm-l-2 {
        margin-left: 2px
    }
    .mg-sm-t-2-f {
        margin-top: 2px!important
    }
    .mg-sm-r-2-f {
        margin-right: 2px!important
    }
    .mg-sm-b-2-f {
        margin-bottom: 2px!important
    }
    .mg-sm-l-2-f {
        margin-left: 2px!important
    }
    .mg-sm-3 {
        margin: 3px
    }
    .mg-sm-3-f {
        margin: 3px!important
    }
    .mg-sm-y-3 {
        margin-top: 3px;
        margin-bottom: 3px
    }
    .mg-sm-y-3-f {
        margin-top: 3px!important;
        margin-bottom: 3px!important
    }
    .mg-sm-x-3 {
        margin-left: 3px;
        margin-right: 3px
    }
    .mg-sm-x-3-f {
        margin-left: 3px!important;
        margin-right: 3px!important
    }
    .mg-sm-t-3 {
        margin-top: 3px
    }
    .mg-sm-r-3 {
        margin-right: 3px
    }
    .mg-sm-b-3 {
        margin-bottom: 3px
    }
    .mg-sm-l-3 {
        margin-left: 3px
    }
    .mg-sm-t-3-f {
        margin-top: 3px!important
    }
    .mg-sm-r-3-f {
        margin-right: 3px!important
    }
    .mg-sm-b-3-f {
        margin-bottom: 3px!important
    }
    .mg-sm-l-3-f {
        margin-left: 3px!important
    }
    .mg-sm-4 {
        margin: 4px
    }
    .mg-sm-4-f {
        margin: 4px!important
    }
    .mg-sm-y-4 {
        margin-top: 4px;
        margin-bottom: 4px
    }
    .mg-sm-y-4-f {
        margin-top: 4px!important;
        margin-bottom: 4px!important
    }
    .mg-sm-x-4 {
        margin-left: 4px;
        margin-right: 4px
    }
    .mg-sm-x-4-f {
        margin-left: 4px!important;
        margin-right: 4px!important
    }
    .mg-sm-t-4 {
        margin-top: 4px
    }
    .mg-sm-r-4 {
        margin-right: 4px
    }
    .mg-sm-b-4 {
        margin-bottom: 4px
    }
    .mg-sm-l-4 {
        margin-left: 4px
    }
    .mg-sm-t-4-f {
        margin-top: 4px!important
    }
    .mg-sm-r-4-f {
        margin-right: 4px!important
    }
    .mg-sm-b-4-f {
        margin-bottom: 4px!important
    }
    .mg-sm-l-4-f {
        margin-left: 4px!important
    }
    .mg-sm-5 {
        margin: 5px
    }
    .mg-sm-5-f {
        margin: 5px!important
    }
    .mg-sm-y-5 {
        margin-top: 5px;
        margin-bottom: 5px
    }
    .mg-sm-y-5-f {
        margin-top: 5px!important;
        margin-bottom: 5px!important
    }
    .mg-sm-x-5 {
        margin-left: 5px;
        margin-right: 5px
    }
    .mg-sm-x-5-f {
        margin-left: 5px!important;
        margin-right: 5px!important
    }
    .mg-sm-t-5 {
        margin-top: 5px
    }
    .mg-sm-r-5 {
        margin-right: 5px
    }
    .mg-sm-b-5 {
        margin-bottom: 5px
    }
    .mg-sm-l-5 {
        margin-left: 5px
    }
    .mg-sm-t-5-f {
        margin-top: 5px!important
    }
    .mg-sm-r-5-f {
        margin-right: 5px!important
    }
    .mg-sm-b-5-f {
        margin-bottom: 5px!important
    }
    .mg-sm-l-5-f {
        margin-left: 5px!important
    }
    .mg-sm-6 {
        margin: 6px
    }
    .mg-sm-6-f {
        margin: 6px!important
    }
    .mg-sm-y-6 {
        margin-top: 6px;
        margin-bottom: 6px
    }
    .mg-sm-y-6-f {
        margin-top: 6px!important;
        margin-bottom: 6px!important
    }
    .mg-sm-x-6 {
        margin-left: 6px;
        margin-right: 6px
    }
    .mg-sm-x-6-f {
        margin-left: 6px!important;
        margin-right: 6px!important
    }
    .mg-sm-t-6 {
        margin-top: 6px
    }
    .mg-sm-r-6 {
        margin-right: 6px
    }
    .mg-sm-b-6 {
        margin-bottom: 6px
    }
    .mg-sm-l-6 {
        margin-left: 6px
    }
    .mg-sm-t-6-f {
        margin-top: 6px!important
    }
    .mg-sm-r-6-f {
        margin-right: 6px!important
    }
    .mg-sm-b-6-f {
        margin-bottom: 6px!important
    }
    .mg-sm-l-6-f {
        margin-left: 6px!important
    }
    .mg-sm-7 {
        margin: 7px
    }
    .mg-sm-7-f {
        margin: 7px!important
    }
    .mg-sm-y-7 {
        margin-top: 7px;
        margin-bottom: 7px
    }
    .mg-sm-y-7-f {
        margin-top: 7px!important;
        margin-bottom: 7px!important
    }
    .mg-sm-x-7 {
        margin-left: 7px;
        margin-right: 7px
    }
    .mg-sm-x-7-f {
        margin-left: 7px!important;
        margin-right: 7px!important
    }
    .mg-sm-t-7 {
        margin-top: 7px
    }
    .mg-sm-r-7 {
        margin-right: 7px
    }
    .mg-sm-b-7 {
        margin-bottom: 7px
    }
    .mg-sm-l-7 {
        margin-left: 7px
    }
    .mg-sm-t-7-f {
        margin-top: 7px!important
    }
    .mg-sm-r-7-f {
        margin-right: 7px!important
    }
    .mg-sm-b-7-f {
        margin-bottom: 7px!important
    }
    .mg-sm-l-7-f {
        margin-left: 7px!important
    }
    .mg-sm-8 {
        margin: 8px
    }
    .mg-sm-8-f {
        margin: 8px!important
    }
    .mg-sm-y-8 {
        margin-top: 8px;
        margin-bottom: 8px
    }
    .mg-sm-y-8-f {
        margin-top: 8px!important;
        margin-bottom: 8px!important
    }
    .mg-sm-x-8 {
        margin-left: 8px;
        margin-right: 8px
    }
    .mg-sm-x-8-f {
        margin-left: 8px!important;
        margin-right: 8px!important
    }
    .mg-sm-t-8 {
        margin-top: 8px
    }
    .mg-sm-r-8 {
        margin-right: 8px
    }
    .mg-sm-b-8 {
        margin-bottom: 8px
    }
    .mg-sm-l-8 {
        margin-left: 8px
    }
    .mg-sm-t-8-f {
        margin-top: 8px!important
    }
    .mg-sm-r-8-f {
        margin-right: 8px!important
    }
    .mg-sm-b-8-f {
        margin-bottom: 8px!important
    }
    .mg-sm-l-8-f {
        margin-left: 8px!important
    }
    .mg-sm-9 {
        margin: 9px
    }
    .mg-sm-9-f {
        margin: 9px!important
    }
    .mg-sm-y-9 {
        margin-top: 9px;
        margin-bottom: 9px
    }
    .mg-sm-y-9-f {
        margin-top: 9px!important;
        margin-bottom: 9px!important
    }
    .mg-sm-x-9 {
        margin-left: 9px;
        margin-right: 9px
    }
    .mg-sm-x-9-f {
        margin-left: 9px!important;
        margin-right: 9px!important
    }
    .mg-sm-t-9 {
        margin-top: 9px
    }
    .mg-sm-r-9 {
        margin-right: 9px
    }
    .mg-sm-b-9 {
        margin-bottom: 9px
    }
    .mg-sm-l-9 {
        margin-left: 9px
    }
    .mg-sm-t-9-f {
        margin-top: 9px!important
    }
    .mg-sm-r-9-f {
        margin-right: 9px!important
    }
    .mg-sm-b-9-f {
        margin-bottom: 9px!important
    }
    .mg-sm-l-9-f {
        margin-left: 9px!important
    }
    .mg-sm-10 {
        margin: 10px
    }
    .mg-sm-10-f {
        margin: 10px!important
    }
    .mg-sm-y-10 {
        margin-top: 10px;
        margin-bottom: 10px
    }
    .mg-sm-y-10-f {
        margin-top: 10px!important;
        margin-bottom: 10px!important
    }
    .mg-sm-x-10 {
        margin-left: 10px;
        margin-right: 10px
    }
    .mg-sm-x-10-f {
        margin-left: 10px!important;
        margin-right: 10px!important
    }
    .mg-sm-t-10 {
        margin-top: 10px
    }
    .mg-sm-r-10 {
        margin-right: 10px
    }
    .mg-sm-b-10 {
        margin-bottom: 10px
    }
    .mg-sm-l-10 {
        margin-left: 10px
    }
    .mg-sm-t-10-f {
        margin-top: 10px!important
    }
    .mg-sm-r-10-f {
        margin-right: 10px!important
    }
    .mg-sm-b-10-f {
        margin-bottom: 10px!important
    }
    .mg-sm-l-10-f {
        margin-left: 10px!important
    }
    .mg-sm-15 {
        margin: 15px
    }
    .mg-sm-15-f {
        margin: 15px!important
    }
    .mg-sm-y-15 {
        margin-top: 15px;
        margin-bottom: 15px
    }
    .mg-sm-y-15-f {
        margin-top: 15px!important;
        margin-bottom: 15px!important
    }
    .mg-sm-x-15 {
        margin-left: 15px;
        margin-right: 15px
    }
    .mg-sm-x-15-f {
        margin-left: 15px!important;
        margin-right: 15px!important
    }
    .mg-sm-t-15 {
        margin-top: 15px
    }
    .mg-sm-r-15 {
        margin-right: 15px
    }
    .mg-sm-b-15 {
        margin-bottom: 15px
    }
    .mg-sm-l-15 {
        margin-left: 15px
    }
    .mg-sm-t-15-f {
        margin-top: 15px!important
    }
    .mg-sm-r-15-f {
        margin-right: 15px!important
    }
    .mg-sm-b-15-f {
        margin-bottom: 15px!important
    }
    .mg-sm-l-15-f {
        margin-left: 15px!important
    }
    .mg-sm-20 {
        margin: 20px
    }
    .mg-sm-20-f {
        margin: 20px!important
    }
    .mg-sm-y-20 {
        margin-top: 20px;
        margin-bottom: 20px
    }
    .mg-sm-y-20-f {
        margin-top: 20px!important;
        margin-bottom: 20px!important
    }
    .mg-sm-x-20 {
        margin-left: 20px;
        margin-right: 20px
    }
    .mg-sm-x-20-f {
        margin-left: 20px!important;
        margin-right: 20px!important
    }
    .mg-sm-t-20 {
        margin-top: 20px
    }
    .mg-sm-r-20 {
        margin-right: 20px
    }
    .mg-sm-b-20 {
        margin-bottom: 20px
    }
    .mg-sm-l-20 {
        margin-left: 20px
    }
    .mg-sm-t-20-f {
        margin-top: 20px!important
    }
    .mg-sm-r-20-f {
        margin-right: 20px!important
    }
    .mg-sm-b-20-f {
        margin-bottom: 20px!important
    }
    .mg-sm-l-20-f {
        margin-left: 20px!important
    }
    .mg-sm-25 {
        margin: 25px
    }
    .mg-sm-25-f {
        margin: 25px!important
    }
    .mg-sm-y-25 {
        margin-top: 25px;
        margin-bottom: 25px
    }
    .mg-sm-y-25-f {
        margin-top: 25px!important;
        margin-bottom: 25px!important
    }
    .mg-sm-x-25 {
        margin-left: 25px;
        margin-right: 25px
    }
    .mg-sm-x-25-f {
        margin-left: 25px!important;
        margin-right: 25px!important
    }
    .mg-sm-t-25 {
        margin-top: 25px
    }
    .mg-sm-r-25 {
        margin-right: 25px
    }
    .mg-sm-b-25 {
        margin-bottom: 25px
    }
    .mg-sm-l-25 {
        margin-left: 25px
    }
    .mg-sm-t-25-f {
        margin-top: 25px!important
    }
    .mg-sm-r-25-f {
        margin-right: 25px!important
    }
    .mg-sm-b-25-f {
        margin-bottom: 25px!important
    }
    .mg-sm-l-25-f {
        margin-left: 25px!important
    }
    .mg-sm-30 {
        margin: 30px
    }
    .mg-sm-30-f {
        margin: 30px!important
    }
    .mg-sm-y-30 {
        margin-top: 30px;
        margin-bottom: 30px
    }
    .mg-sm-y-30-f {
        margin-top: 30px!important;
        margin-bottom: 30px!important
    }
    .mg-sm-x-30 {
        margin-left: 30px;
        margin-right: 30px
    }
    .mg-sm-x-30-f {
        margin-left: 30px!important;
        margin-right: 30px!important
    }
    .mg-sm-t-30 {
        margin-top: 30px
    }
    .mg-sm-r-30 {
        margin-right: 30px
    }
    .mg-sm-b-30 {
        margin-bottom: 30px
    }
    .mg-sm-l-30 {
        margin-left: 30px
    }
    .mg-sm-t-30-f {
        margin-top: 30px!important
    }
    .mg-sm-r-30-f {
        margin-right: 30px!important
    }
    .mg-sm-b-30-f {
        margin-bottom: 30px!important
    }
    .mg-sm-l-30-f {
        margin-left: 30px!important
    }
    .mg-sm-35 {
        margin: 35px
    }
    .mg-sm-35-f {
        margin: 35px!important
    }
    .mg-sm-y-35 {
        margin-top: 35px;
        margin-bottom: 35px
    }
    .mg-sm-y-35-f {
        margin-top: 35px!important;
        margin-bottom: 35px!important
    }
    .mg-sm-x-35 {
        margin-left: 35px;
        margin-right: 35px
    }
    .mg-sm-x-35-f {
        margin-left: 35px!important;
        margin-right: 35px!important
    }
    .mg-sm-t-35 {
        margin-top: 35px
    }
    .mg-sm-r-35 {
        margin-right: 35px
    }
    .mg-sm-b-35 {
        margin-bottom: 35px
    }
    .mg-sm-l-35 {
        margin-left: 35px
    }
    .mg-sm-t-35-f {
        margin-top: 35px!important
    }
    .mg-sm-r-35-f {
        margin-right: 35px!important
    }
    .mg-sm-b-35-f {
        margin-bottom: 35px!important
    }
    .mg-sm-l-35-f {
        margin-left: 35px!important
    }
    .mg-sm-40 {
        margin: 40px
    }
    .mg-sm-40-f {
        margin: 40px!important
    }
    .mg-sm-y-40 {
        margin-top: 40px;
        margin-bottom: 40px
    }
    .mg-sm-y-40-f {
        margin-top: 40px!important;
        margin-bottom: 40px!important
    }
    .mg-sm-x-40 {
        margin-left: 40px;
        margin-right: 40px
    }
    .mg-sm-x-40-f {
        margin-left: 40px!important;
        margin-right: 40px!important
    }
    .mg-sm-t-40 {
        margin-top: 40px
    }
    .mg-sm-r-40 {
        margin-right: 40px
    }
    .mg-sm-b-40 {
        margin-bottom: 40px
    }
    .mg-sm-l-40 {
        margin-left: 40px
    }
    .mg-sm-t-40-f {
        margin-top: 40px!important
    }
    .mg-sm-r-40-f {
        margin-right: 40px!important
    }
    .mg-sm-b-40-f {
        margin-bottom: 40px!important
    }
    .mg-sm-l-40-f {
        margin-left: 40px!important
    }
    .mg-sm-45 {
        margin: 45px
    }
    .mg-sm-45-f {
        margin: 45px!important
    }
    .mg-sm-y-45 {
        margin-top: 45px;
        margin-bottom: 45px
    }
    .mg-sm-y-45-f {
        margin-top: 45px!important;
        margin-bottom: 45px!important
    }
    .mg-sm-x-45 {
        margin-left: 45px;
        margin-right: 45px
    }
    .mg-sm-x-45-f {
        margin-left: 45px!important;
        margin-right: 45px!important
    }
    .mg-sm-t-45 {
        margin-top: 45px
    }
    .mg-sm-r-45 {
        margin-right: 45px
    }
    .mg-sm-b-45 {
        margin-bottom: 45px
    }
    .mg-sm-l-45 {
        margin-left: 45px
    }
    .mg-sm-t-45-f {
        margin-top: 45px!important
    }
    .mg-sm-r-45-f {
        margin-right: 45px!important
    }
    .mg-sm-b-45-f {
        margin-bottom: 45px!important
    }
    .mg-sm-l-45-f {
        margin-left: 45px!important
    }
    .mg-sm-50 {
        margin: 50px
    }
    .mg-sm-50-f {
        margin: 50px!important
    }
    .mg-sm-y-50 {
        margin-top: 50px;
        margin-bottom: 50px
    }
    .mg-sm-y-50-f {
        margin-top: 50px!important;
        margin-bottom: 50px!important
    }
    .mg-sm-x-50 {
        margin-left: 50px;
        margin-right: 50px
    }
    .mg-sm-x-50-f {
        margin-left: 50px!important;
        margin-right: 50px!important
    }
    .mg-sm-t-50 {
        margin-top: 50px
    }
    .mg-sm-r-50 {
        margin-right: 50px
    }
    .mg-sm-b-50 {
        margin-bottom: 50px
    }
    .mg-sm-l-50 {
        margin-left: 50px
    }
    .mg-sm-t-50-f {
        margin-top: 50px!important
    }
    .mg-sm-r-50-f {
        margin-right: 50px!important
    }
    .mg-sm-b-50-f {
        margin-bottom: 50px!important
    }
    .mg-sm-l-50-f {
        margin-left: 50px!important
    }
    .mg-sm-55 {
        margin: 55px
    }
    .mg-sm-55-f {
        margin: 55px!important
    }
    .mg-sm-y-55 {
        margin-top: 55px;
        margin-bottom: 55px
    }
    .mg-sm-y-55-f {
        margin-top: 55px!important;
        margin-bottom: 55px!important
    }
    .mg-sm-x-55 {
        margin-left: 55px;
        margin-right: 55px
    }
    .mg-sm-x-55-f {
        margin-left: 55px!important;
        margin-right: 55px!important
    }
    .mg-sm-t-55 {
        margin-top: 55px
    }
    .mg-sm-r-55 {
        margin-right: 55px
    }
    .mg-sm-b-55 {
        margin-bottom: 55px
    }
    .mg-sm-l-55 {
        margin-left: 55px
    }
    .mg-sm-t-55-f {
        margin-top: 55px!important
    }
    .mg-sm-r-55-f {
        margin-right: 55px!important
    }
    .mg-sm-b-55-f {
        margin-bottom: 55px!important
    }
    .mg-sm-l-55-f {
        margin-left: 55px!important
    }
    .mg-sm-60 {
        margin: 60px
    }
    .mg-sm-60-f {
        margin: 60px!important
    }
    .mg-sm-y-60 {
        margin-top: 60px;
        margin-bottom: 60px
    }
    .mg-sm-y-60-f {
        margin-top: 60px!important;
        margin-bottom: 60px!important
    }
    .mg-sm-x-60 {
        margin-left: 60px;
        margin-right: 60px
    }
    .mg-sm-x-60-f {
        margin-left: 60px!important;
        margin-right: 60px!important
    }
    .mg-sm-t-60 {
        margin-top: 60px
    }
    .mg-sm-r-60 {
        margin-right: 60px
    }
    .mg-sm-b-60 {
        margin-bottom: 60px
    }
    .mg-sm-l-60 {
        margin-left: 60px
    }
    .mg-sm-t-60-f {
        margin-top: 60px!important
    }
    .mg-sm-r-60-f {
        margin-right: 60px!important
    }
    .mg-sm-b-60-f {
        margin-bottom: 60px!important
    }
    .mg-sm-l-60-f {
        margin-left: 60px!important
    }
    .mg-sm-65 {
        margin: 65px
    }
    .mg-sm-65-f {
        margin: 65px!important
    }
    .mg-sm-y-65 {
        margin-top: 65px;
        margin-bottom: 65px
    }
    .mg-sm-y-65-f {
        margin-top: 65px!important;
        margin-bottom: 65px!important
    }
    .mg-sm-x-65 {
        margin-left: 65px;
        margin-right: 65px
    }
    .mg-sm-x-65-f {
        margin-left: 65px!important;
        margin-right: 65px!important
    }
    .mg-sm-t-65 {
        margin-top: 65px
    }
    .mg-sm-r-65 {
        margin-right: 65px
    }
    .mg-sm-b-65 {
        margin-bottom: 65px
    }
    .mg-sm-l-65 {
        margin-left: 65px
    }
    .mg-sm-t-65-f {
        margin-top: 65px!important
    }
    .mg-sm-r-65-f {
        margin-right: 65px!important
    }
    .mg-sm-b-65-f {
        margin-bottom: 65px!important
    }
    .mg-sm-l-65-f {
        margin-left: 65px!important
    }
    .mg-sm-70 {
        margin: 70px
    }
    .mg-sm-70-f {
        margin: 70px!important
    }
    .mg-sm-y-70 {
        margin-top: 70px;
        margin-bottom: 70px
    }
    .mg-sm-y-70-f {
        margin-top: 70px!important;
        margin-bottom: 70px!important
    }
    .mg-sm-x-70 {
        margin-left: 70px;
        margin-right: 70px
    }
    .mg-sm-x-70-f {
        margin-left: 70px!important;
        margin-right: 70px!important
    }
    .mg-sm-t-70 {
        margin-top: 70px
    }
    .mg-sm-r-70 {
        margin-right: 70px
    }
    .mg-sm-b-70 {
        margin-bottom: 70px
    }
    .mg-sm-l-70 {
        margin-left: 70px
    }
    .mg-sm-t-70-f {
        margin-top: 70px!important
    }
    .mg-sm-r-70-f {
        margin-right: 70px!important
    }
    .mg-sm-b-70-f {
        margin-bottom: 70px!important
    }
    .mg-sm-l-70-f {
        margin-left: 70px!important
    }
    .mg-sm-75 {
        margin: 75px
    }
    .mg-sm-75-f {
        margin: 75px!important
    }
    .mg-sm-y-75 {
        margin-top: 75px;
        margin-bottom: 75px
    }
    .mg-sm-y-75-f {
        margin-top: 75px!important;
        margin-bottom: 75px!important
    }
    .mg-sm-x-75 {
        margin-left: 75px;
        margin-right: 75px
    }
    .mg-sm-x-75-f {
        margin-left: 75px!important;
        margin-right: 75px!important
    }
    .mg-sm-t-75 {
        margin-top: 75px
    }
    .mg-sm-r-75 {
        margin-right: 75px
    }
    .mg-sm-b-75 {
        margin-bottom: 75px
    }
    .mg-sm-l-75 {
        margin-left: 75px
    }
    .mg-sm-t-75-f {
        margin-top: 75px!important
    }
    .mg-sm-r-75-f {
        margin-right: 75px!important
    }
    .mg-sm-b-75-f {
        margin-bottom: 75px!important
    }
    .mg-sm-l-75-f {
        margin-left: 75px!important
    }
    .mg-sm-80 {
        margin: 80px
    }
    .mg-sm-80-f {
        margin: 80px!important
    }
    .mg-sm-y-80 {
        margin-top: 80px;
        margin-bottom: 80px
    }
    .mg-sm-y-80-f {
        margin-top: 80px!important;
        margin-bottom: 80px!important
    }
    .mg-sm-x-80 {
        margin-left: 80px;
        margin-right: 80px
    }
    .mg-sm-x-80-f {
        margin-left: 80px!important;
        margin-right: 80px!important
    }
    .mg-sm-t-80 {
        margin-top: 80px
    }
    .mg-sm-r-80 {
        margin-right: 80px
    }
    .mg-sm-b-80 {
        margin-bottom: 80px
    }
    .mg-sm-l-80 {
        margin-left: 80px
    }
    .mg-sm-t-80-f {
        margin-top: 80px!important
    }
    .mg-sm-r-80-f {
        margin-right: 80px!important
    }
    .mg-sm-b-80-f {
        margin-bottom: 80px!important
    }
    .mg-sm-l-80-f {
        margin-left: 80px!important
    }
    .mg-sm-85 {
        margin: 85px
    }
    .mg-sm-85-f {
        margin: 85px!important
    }
    .mg-sm-y-85 {
        margin-top: 85px;
        margin-bottom: 85px
    }
    .mg-sm-y-85-f {
        margin-top: 85px!important;
        margin-bottom: 85px!important
    }
    .mg-sm-x-85 {
        margin-left: 85px;
        margin-right: 85px
    }
    .mg-sm-x-85-f {
        margin-left: 85px!important;
        margin-right: 85px!important
    }
    .mg-sm-t-85 {
        margin-top: 85px
    }
    .mg-sm-r-85 {
        margin-right: 85px
    }
    .mg-sm-b-85 {
        margin-bottom: 85px
    }
    .mg-sm-l-85 {
        margin-left: 85px
    }
    .mg-sm-t-85-f {
        margin-top: 85px!important
    }
    .mg-sm-r-85-f {
        margin-right: 85px!important
    }
    .mg-sm-b-85-f {
        margin-bottom: 85px!important
    }
    .mg-sm-l-85-f {
        margin-left: 85px!important
    }
    .mg-sm-90 {
        margin: 90px
    }
    .mg-sm-90-f {
        margin: 90px!important
    }
    .mg-sm-y-90 {
        margin-top: 90px;
        margin-bottom: 90px
    }
    .mg-sm-y-90-f {
        margin-top: 90px!important;
        margin-bottom: 90px!important
    }
    .mg-sm-x-90 {
        margin-left: 90px;
        margin-right: 90px
    }
    .mg-sm-x-90-f {
        margin-left: 90px!important;
        margin-right: 90px!important
    }
    .mg-sm-t-90 {
        margin-top: 90px
    }
    .mg-sm-r-90 {
        margin-right: 90px
    }
    .mg-sm-b-90 {
        margin-bottom: 90px
    }
    .mg-sm-l-90 {
        margin-left: 90px
    }
    .mg-sm-t-90-f {
        margin-top: 90px!important
    }
    .mg-sm-r-90-f {
        margin-right: 90px!important
    }
    .mg-sm-b-90-f {
        margin-bottom: 90px!important
    }
    .mg-sm-l-90-f {
        margin-left: 90px!important
    }
    .mg-sm-95 {
        margin: 95px
    }
    .mg-sm-95-f {
        margin: 95px!important
    }
    .mg-sm-y-95 {
        margin-top: 95px;
        margin-bottom: 95px
    }
    .mg-sm-y-95-f {
        margin-top: 95px!important;
        margin-bottom: 95px!important
    }
    .mg-sm-x-95 {
        margin-left: 95px;
        margin-right: 95px
    }
    .mg-sm-x-95-f {
        margin-left: 95px!important;
        margin-right: 95px!important
    }
    .mg-sm-t-95 {
        margin-top: 95px
    }
    .mg-sm-r-95 {
        margin-right: 95px
    }
    .mg-sm-b-95 {
        margin-bottom: 95px
    }
    .mg-sm-l-95 {
        margin-left: 95px
    }
    .mg-sm-t-95-f {
        margin-top: 95px!important
    }
    .mg-sm-r-95-f {
        margin-right: 95px!important
    }
    .mg-sm-b-95-f {
        margin-bottom: 95px!important
    }
    .mg-sm-l-95-f {
        margin-left: 95px!important
    }
    .mg-sm-100 {
        margin: 100px
    }
    .mg-sm-100-f {
        margin: 100px!important
    }
    .mg-sm-y-100 {
        margin-top: 100px;
        margin-bottom: 100px
    }
    .mg-sm-y-100-f {
        margin-top: 100px!important;
        margin-bottom: 100px!important
    }
    .mg-sm-x-100 {
        margin-left: 100px;
        margin-right: 100px
    }
    .mg-sm-x-100-f {
        margin-left: 100px!important;
        margin-right: 100px!important
    }
    .mg-sm-t-100 {
        margin-top: 100px
    }
    .mg-sm-r-100 {
        margin-right: 100px
    }
    .mg-sm-b-100 {
        margin-bottom: 100px
    }
    .mg-sm-l-100 {
        margin-left: 100px
    }
    .mg-sm-t-100-f {
        margin-top: 100px!important
    }
    .mg-sm-r-100-f {
        margin-right: 100px!important
    }
    .mg-sm-b-100-f {
        margin-bottom: 100px!important
    }
    .mg-sm-l-100-f {
        margin-left: 100px!important
    }
    .mg-sm-t-auto {
        margin-top: auto
    }
    .mg-sm-r-auto {
        margin-right: auto
    }
    .mg-sm-b-auto {
        margin-bottom: auto
    }
    .mg-sm-l-auto {
        margin-left: auto
    }
    .mg-sm-auto {
        margin: auto
    }
    .mg-sm-x-auto {
        margin-left: auto;
        margin-right: auto
    }
}

@media (min-width:768px) {
    .mg-md-0 {
        margin: 0
    }
    .mg-md-0-f {
        margin: 0!important
    }
    .mg-md-y-0 {
        margin-top: 0;
        margin-bottom: 0
    }
    .mg-md-y-0-f {
        margin-top: 0!important;
        margin-bottom: 0!important
    }
    .mg-md-x-0 {
        margin-left: 0;
        margin-right: 0
    }
    .mg-md-x-0-f {
        margin-left: 0!important;
        margin-right: 0!important
    }
    .mg-md-t-0 {
        margin-top: 0
    }
    .mg-md-r-0 {
        margin-right: 0
    }
    .mg-md-b-0 {
        margin-bottom: 0
    }
    .mg-md-l-0 {
        margin-left: 0
    }
    .mg-md-t-0-f {
        margin-top: 0!important
    }
    .mg-md-r-0-f {
        margin-right: 0!important
    }
    .mg-md-b-0-f {
        margin-bottom: 0!important
    }
    .mg-md-l-0-f {
        margin-left: 0!important
    }
    .mg-md-1 {
        margin: 1px
    }
    .mg-md-1-f {
        margin: 1px!important
    }
    .mg-md-y-1 {
        margin-top: 1px;
        margin-bottom: 1px
    }
    .mg-md-y-1-f {
        margin-top: 1px!important;
        margin-bottom: 1px!important
    }
    .mg-md-x-1 {
        margin-left: 1px;
        margin-right: 1px
    }
    .mg-md-x-1-f {
        margin-left: 1px!important;
        margin-right: 1px!important
    }
    .mg-md-t-1 {
        margin-top: 1px
    }
    .mg-md-r-1 {
        margin-right: 1px
    }
    .mg-md-b-1 {
        margin-bottom: 1px
    }
    .mg-md-l-1 {
        margin-left: 1px
    }
    .mg-md-t-1-f {
        margin-top: 1px!important
    }
    .mg-md-r-1-f {
        margin-right: 1px!important
    }
    .mg-md-b-1-f {
        margin-bottom: 1px!important
    }
    .mg-md-l-1-f {
        margin-left: 1px!important
    }
    .mg-md-2 {
        margin: 2px
    }
    .mg-md-2-f {
        margin: 2px!important
    }
    .mg-md-y-2 {
        margin-top: 2px;
        margin-bottom: 2px
    }
    .mg-md-y-2-f {
        margin-top: 2px!important;
        margin-bottom: 2px!important
    }
    .mg-md-x-2 {
        margin-left: 2px;
        margin-right: 2px
    }
    .mg-md-x-2-f {
        margin-left: 2px!important;
        margin-right: 2px!important
    }
    .mg-md-t-2 {
        margin-top: 2px
    }
    .mg-md-r-2 {
        margin-right: 2px
    }
    .mg-md-b-2 {
        margin-bottom: 2px
    }
    .mg-md-l-2 {
        margin-left: 2px
    }
    .mg-md-t-2-f {
        margin-top: 2px!important
    }
    .mg-md-r-2-f {
        margin-right: 2px!important
    }
    .mg-md-b-2-f {
        margin-bottom: 2px!important
    }
    .mg-md-l-2-f {
        margin-left: 2px!important
    }
    .mg-md-3 {
        margin: 3px
    }
    .mg-md-3-f {
        margin: 3px!important
    }
    .mg-md-y-3 {
        margin-top: 3px;
        margin-bottom: 3px
    }
    .mg-md-y-3-f {
        margin-top: 3px!important;
        margin-bottom: 3px!important
    }
    .mg-md-x-3 {
        margin-left: 3px;
        margin-right: 3px
    }
    .mg-md-x-3-f {
        margin-left: 3px!important;
        margin-right: 3px!important
    }
    .mg-md-t-3 {
        margin-top: 3px
    }
    .mg-md-r-3 {
        margin-right: 3px
    }
    .mg-md-b-3 {
        margin-bottom: 3px
    }
    .mg-md-l-3 {
        margin-left: 3px
    }
    .mg-md-t-3-f {
        margin-top: 3px!important
    }
    .mg-md-r-3-f {
        margin-right: 3px!important
    }
    .mg-md-b-3-f {
        margin-bottom: 3px!important
    }
    .mg-md-l-3-f {
        margin-left: 3px!important
    }
    .mg-md-4 {
        margin: 4px
    }
    .mg-md-4-f {
        margin: 4px!important
    }
    .mg-md-y-4 {
        margin-top: 4px;
        margin-bottom: 4px
    }
    .mg-md-y-4-f {
        margin-top: 4px!important;
        margin-bottom: 4px!important
    }
    .mg-md-x-4 {
        margin-left: 4px;
        margin-right: 4px
    }
    .mg-md-x-4-f {
        margin-left: 4px!important;
        margin-right: 4px!important
    }
    .mg-md-t-4 {
        margin-top: 4px
    }
    .mg-md-r-4 {
        margin-right: 4px
    }
    .mg-md-b-4 {
        margin-bottom: 4px
    }
    .mg-md-l-4 {
        margin-left: 4px
    }
    .mg-md-t-4-f {
        margin-top: 4px!important
    }
    .mg-md-r-4-f {
        margin-right: 4px!important
    }
    .mg-md-b-4-f {
        margin-bottom: 4px!important
    }
    .mg-md-l-4-f {
        margin-left: 4px!important
    }
    .mg-md-5 {
        margin: 5px
    }
    .mg-md-5-f {
        margin: 5px!important
    }
    .mg-md-y-5 {
        margin-top: 5px;
        margin-bottom: 5px
    }
    .mg-md-y-5-f {
        margin-top: 5px!important;
        margin-bottom: 5px!important
    }
    .mg-md-x-5 {
        margin-left: 5px;
        margin-right: 5px
    }
    .mg-md-x-5-f {
        margin-left: 5px!important;
        margin-right: 5px!important
    }
    .mg-md-t-5 {
        margin-top: 5px
    }
    .mg-md-r-5 {
        margin-right: 5px
    }
    .mg-md-b-5 {
        margin-bottom: 5px
    }
    .mg-md-l-5 {
        margin-left: 5px
    }
    .mg-md-t-5-f {
        margin-top: 5px!important
    }
    .mg-md-r-5-f {
        margin-right: 5px!important
    }
    .mg-md-b-5-f {
        margin-bottom: 5px!important
    }
    .mg-md-l-5-f {
        margin-left: 5px!important
    }
    .mg-md-6 {
        margin: 6px
    }
    .mg-md-6-f {
        margin: 6px!important
    }
    .mg-md-y-6 {
        margin-top: 6px;
        margin-bottom: 6px
    }
    .mg-md-y-6-f {
        margin-top: 6px!important;
        margin-bottom: 6px!important
    }
    .mg-md-x-6 {
        margin-left: 6px;
        margin-right: 6px
    }
    .mg-md-x-6-f {
        margin-left: 6px!important;
        margin-right: 6px!important
    }
    .mg-md-t-6 {
        margin-top: 6px
    }
    .mg-md-r-6 {
        margin-right: 6px
    }
    .mg-md-b-6 {
        margin-bottom: 6px
    }
    .mg-md-l-6 {
        margin-left: 6px
    }
    .mg-md-t-6-f {
        margin-top: 6px!important
    }
    .mg-md-r-6-f {
        margin-right: 6px!important
    }
    .mg-md-b-6-f {
        margin-bottom: 6px!important
    }
    .mg-md-l-6-f {
        margin-left: 6px!important
    }
    .mg-md-7 {
        margin: 7px
    }
    .mg-md-7-f {
        margin: 7px!important
    }
    .mg-md-y-7 {
        margin-top: 7px;
        margin-bottom: 7px
    }
    .mg-md-y-7-f {
        margin-top: 7px!important;
        margin-bottom: 7px!important
    }
    .mg-md-x-7 {
        margin-left: 7px;
        margin-right: 7px
    }
    .mg-md-x-7-f {
        margin-left: 7px!important;
        margin-right: 7px!important
    }
    .mg-md-t-7 {
        margin-top: 7px
    }
    .mg-md-r-7 {
        margin-right: 7px
    }
    .mg-md-b-7 {
        margin-bottom: 7px
    }
    .mg-md-l-7 {
        margin-left: 7px
    }
    .mg-md-t-7-f {
        margin-top: 7px!important
    }
    .mg-md-r-7-f {
        margin-right: 7px!important
    }
    .mg-md-b-7-f {
        margin-bottom: 7px!important
    }
    .mg-md-l-7-f {
        margin-left: 7px!important
    }
    .mg-md-8 {
        margin: 8px
    }
    .mg-md-8-f {
        margin: 8px!important
    }
    .mg-md-y-8 {
        margin-top: 8px;
        margin-bottom: 8px
    }
    .mg-md-y-8-f {
        margin-top: 8px!important;
        margin-bottom: 8px!important
    }
    .mg-md-x-8 {
        margin-left: 8px;
        margin-right: 8px
    }
    .mg-md-x-8-f {
        margin-left: 8px!important;
        margin-right: 8px!important
    }
    .mg-md-t-8 {
        margin-top: 8px
    }
    .mg-md-r-8 {
        margin-right: 8px
    }
    .mg-md-b-8 {
        margin-bottom: 8px
    }
    .mg-md-l-8 {
        margin-left: 8px
    }
    .mg-md-t-8-f {
        margin-top: 8px!important
    }
    .mg-md-r-8-f {
        margin-right: 8px!important
    }
    .mg-md-b-8-f {
        margin-bottom: 8px!important
    }
    .mg-md-l-8-f {
        margin-left: 8px!important
    }
    .mg-md-9 {
        margin: 9px
    }
    .mg-md-9-f {
        margin: 9px!important
    }
    .mg-md-y-9 {
        margin-top: 9px;
        margin-bottom: 9px
    }
    .mg-md-y-9-f {
        margin-top: 9px!important;
        margin-bottom: 9px!important
    }
    .mg-md-x-9 {
        margin-left: 9px;
        margin-right: 9px
    }
    .mg-md-x-9-f {
        margin-left: 9px!important;
        margin-right: 9px!important
    }
    .mg-md-t-9 {
        margin-top: 9px
    }
    .mg-md-r-9 {
        margin-right: 9px
    }
    .mg-md-b-9 {
        margin-bottom: 9px
    }
    .mg-md-l-9 {
        margin-left: 9px
    }
    .mg-md-t-9-f {
        margin-top: 9px!important
    }
    .mg-md-r-9-f {
        margin-right: 9px!important
    }
    .mg-md-b-9-f {
        margin-bottom: 9px!important
    }
    .mg-md-l-9-f {
        margin-left: 9px!important
    }
    .mg-md-10 {
        margin: 10px
    }
    .mg-md-10-f {
        margin: 10px!important
    }
    .mg-md-y-10 {
        margin-top: 10px;
        margin-bottom: 10px
    }
    .mg-md-y-10-f {
        margin-top: 10px!important;
        margin-bottom: 10px!important
    }
    .mg-md-x-10 {
        margin-left: 10px;
        margin-right: 10px
    }
    .mg-md-x-10-f {
        margin-left: 10px!important;
        margin-right: 10px!important
    }
    .mg-md-t-10 {
        margin-top: 10px
    }
    .mg-md-r-10 {
        margin-right: 10px
    }
    .mg-md-b-10 {
        margin-bottom: 10px
    }
    .mg-md-l-10 {
        margin-left: 10px
    }
    .mg-md-t-10-f {
        margin-top: 10px!important
    }
    .mg-md-r-10-f {
        margin-right: 10px!important
    }
    .mg-md-b-10-f {
        margin-bottom: 10px!important
    }
    .mg-md-l-10-f {
        margin-left: 10px!important
    }
    .mg-md-15 {
        margin: 15px
    }
    .mg-md-15-f {
        margin: 15px!important
    }
    .mg-md-y-15 {
        margin-top: 15px;
        margin-bottom: 15px
    }
    .mg-md-y-15-f {
        margin-top: 15px!important;
        margin-bottom: 15px!important
    }
    .mg-md-x-15 {
        margin-left: 15px;
        margin-right: 15px
    }
    .mg-md-x-15-f {
        margin-left: 15px!important;
        margin-right: 15px!important
    }
    .mg-md-t-15 {
        margin-top: 15px
    }
    .mg-md-r-15 {
        margin-right: 15px
    }
    .mg-md-b-15 {
        margin-bottom: 15px
    }
    .mg-md-l-15 {
        margin-left: 15px
    }
    .mg-md-t-15-f {
        margin-top: 15px!important
    }
    .mg-md-r-15-f {
        margin-right: 15px!important
    }
    .mg-md-b-15-f {
        margin-bottom: 15px!important
    }
    .mg-md-l-15-f {
        margin-left: 15px!important
    }
    .mg-md-20 {
        margin: 20px
    }
    .mg-md-20-f {
        margin: 20px!important
    }
    .mg-md-y-20 {
        margin-top: 20px;
        margin-bottom: 20px
    }
    .mg-md-y-20-f {
        margin-top: 20px!important;
        margin-bottom: 20px!important
    }
    .mg-md-x-20 {
        margin-left: 20px;
        margin-right: 20px
    }
    .mg-md-x-20-f {
        margin-left: 20px!important;
        margin-right: 20px!important
    }
    .mg-md-t-20 {
        margin-top: 20px
    }
    .mg-md-r-20 {
        margin-right: 20px
    }
    .mg-md-b-20 {
        margin-bottom: 20px
    }
    .mg-md-l-20 {
        margin-left: 20px
    }
    .mg-md-t-20-f {
        margin-top: 20px!important
    }
    .mg-md-r-20-f {
        margin-right: 20px!important
    }
    .mg-md-b-20-f {
        margin-bottom: 20px!important
    }
    .mg-md-l-20-f {
        margin-left: 20px!important
    }
    .mg-md-25 {
        margin: 25px
    }
    .mg-md-25-f {
        margin: 25px!important
    }
    .mg-md-y-25 {
        margin-top: 25px;
        margin-bottom: 25px
    }
    .mg-md-y-25-f {
        margin-top: 25px!important;
        margin-bottom: 25px!important
    }
    .mg-md-x-25 {
        margin-left: 25px;
        margin-right: 25px
    }
    .mg-md-x-25-f {
        margin-left: 25px!important;
        margin-right: 25px!important
    }
    .mg-md-t-25 {
        margin-top: 25px
    }
    .mg-md-r-25 {
        margin-right: 25px
    }
    .mg-md-b-25 {
        margin-bottom: 25px
    }
    .mg-md-l-25 {
        margin-left: 25px
    }
    .mg-md-t-25-f {
        margin-top: 25px!important
    }
    .mg-md-r-25-f {
        margin-right: 25px!important
    }
    .mg-md-b-25-f {
        margin-bottom: 25px!important
    }
    .mg-md-l-25-f {
        margin-left: 25px!important
    }
    .mg-md-30 {
        margin: 30px
    }
    .mg-md-30-f {
        margin: 30px!important
    }
    .mg-md-y-30 {
        margin-top: 30px;
        margin-bottom: 30px
    }
    .mg-md-y-30-f {
        margin-top: 30px!important;
        margin-bottom: 30px!important
    }
    .mg-md-x-30 {
        margin-left: 30px;
        margin-right: 30px
    }
    .mg-md-x-30-f {
        margin-left: 30px!important;
        margin-right: 30px!important
    }
    .mg-md-t-30 {
        margin-top: 30px
    }
    .mg-md-r-30 {
        margin-right: 30px
    }
    .mg-md-b-30 {
        margin-bottom: 30px
    }
    .mg-md-l-30 {
        margin-left: 30px
    }
    .mg-md-t-30-f {
        margin-top: 30px!important
    }
    .mg-md-r-30-f {
        margin-right: 30px!important
    }
    .mg-md-b-30-f {
        margin-bottom: 30px!important
    }
    .mg-md-l-30-f {
        margin-left: 30px!important
    }
    .mg-md-35 {
        margin: 35px
    }
    .mg-md-35-f {
        margin: 35px!important
    }
    .mg-md-y-35 {
        margin-top: 35px;
        margin-bottom: 35px
    }
    .mg-md-y-35-f {
        margin-top: 35px!important;
        margin-bottom: 35px!important
    }
    .mg-md-x-35 {
        margin-left: 35px;
        margin-right: 35px
    }
    .mg-md-x-35-f {
        margin-left: 35px!important;
        margin-right: 35px!important
    }
    .mg-md-t-35 {
        margin-top: 35px
    }
    .mg-md-r-35 {
        margin-right: 35px
    }
    .mg-md-b-35 {
        margin-bottom: 35px
    }
    .mg-md-l-35 {
        margin-left: 35px
    }
    .mg-md-t-35-f {
        margin-top: 35px!important
    }
    .mg-md-r-35-f {
        margin-right: 35px!important
    }
    .mg-md-b-35-f {
        margin-bottom: 35px!important
    }
    .mg-md-l-35-f {
        margin-left: 35px!important
    }
    .mg-md-40 {
        margin: 40px
    }
    .mg-md-40-f {
        margin: 40px!important
    }
    .mg-md-y-40 {
        margin-top: 40px;
        margin-bottom: 40px
    }
    .mg-md-y-40-f {
        margin-top: 40px!important;
        margin-bottom: 40px!important
    }
    .mg-md-x-40 {
        margin-left: 40px;
        margin-right: 40px
    }
    .mg-md-x-40-f {
        margin-left: 40px!important;
        margin-right: 40px!important
    }
    .mg-md-t-40 {
        margin-top: 40px
    }
    .mg-md-r-40 {
        margin-right: 40px
    }
    .mg-md-b-40 {
        margin-bottom: 40px
    }
    .mg-md-l-40 {
        margin-left: 40px
    }
    .mg-md-t-40-f {
        margin-top: 40px!important
    }
    .mg-md-r-40-f {
        margin-right: 40px!important
    }
    .mg-md-b-40-f {
        margin-bottom: 40px!important
    }
    .mg-md-l-40-f {
        margin-left: 40px!important
    }
    .mg-md-45 {
        margin: 45px
    }
    .mg-md-45-f {
        margin: 45px!important
    }
    .mg-md-y-45 {
        margin-top: 45px;
        margin-bottom: 45px
    }
    .mg-md-y-45-f {
        margin-top: 45px!important;
        margin-bottom: 45px!important
    }
    .mg-md-x-45 {
        margin-left: 45px;
        margin-right: 45px
    }
    .mg-md-x-45-f {
        margin-left: 45px!important;
        margin-right: 45px!important
    }
    .mg-md-t-45 {
        margin-top: 45px
    }
    .mg-md-r-45 {
        margin-right: 45px
    }
    .mg-md-b-45 {
        margin-bottom: 45px
    }
    .mg-md-l-45 {
        margin-left: 45px
    }
    .mg-md-t-45-f {
        margin-top: 45px!important
    }
    .mg-md-r-45-f {
        margin-right: 45px!important
    }
    .mg-md-b-45-f {
        margin-bottom: 45px!important
    }
    .mg-md-l-45-f {
        margin-left: 45px!important
    }
    .mg-md-50 {
        margin: 50px
    }
    .mg-md-50-f {
        margin: 50px!important
    }
    .mg-md-y-50 {
        margin-top: 50px;
        margin-bottom: 50px
    }
    .mg-md-y-50-f {
        margin-top: 50px!important;
        margin-bottom: 50px!important
    }
    .mg-md-x-50 {
        margin-left: 50px;
        margin-right: 50px
    }
    .mg-md-x-50-f {
        margin-left: 50px!important;
        margin-right: 50px!important
    }
    .mg-md-t-50 {
        margin-top: 50px
    }
    .mg-md-r-50 {
        margin-right: 50px
    }
    .mg-md-b-50 {
        margin-bottom: 50px
    }
    .mg-md-l-50 {
        margin-left: 50px
    }
    .mg-md-t-50-f {
        margin-top: 50px!important
    }
    .mg-md-r-50-f {
        margin-right: 50px!important
    }
    .mg-md-b-50-f {
        margin-bottom: 50px!important
    }
    .mg-md-l-50-f {
        margin-left: 50px!important
    }
    .mg-md-55 {
        margin: 55px
    }
    .mg-md-55-f {
        margin: 55px!important
    }
    .mg-md-y-55 {
        margin-top: 55px;
        margin-bottom: 55px
    }
    .mg-md-y-55-f {
        margin-top: 55px!important;
        margin-bottom: 55px!important
    }
    .mg-md-x-55 {
        margin-left: 55px;
        margin-right: 55px
    }
    .mg-md-x-55-f {
        margin-left: 55px!important;
        margin-right: 55px!important
    }
    .mg-md-t-55 {
        margin-top: 55px
    }
    .mg-md-r-55 {
        margin-right: 55px
    }
    .mg-md-b-55 {
        margin-bottom: 55px
    }
    .mg-md-l-55 {
        margin-left: 55px
    }
    .mg-md-t-55-f {
        margin-top: 55px!important
    }
    .mg-md-r-55-f {
        margin-right: 55px!important
    }
    .mg-md-b-55-f {
        margin-bottom: 55px!important
    }
    .mg-md-l-55-f {
        margin-left: 55px!important
    }
    .mg-md-60 {
        margin: 60px
    }
    .mg-md-60-f {
        margin: 60px!important
    }
    .mg-md-y-60 {
        margin-top: 60px;
        margin-bottom: 60px
    }
    .mg-md-y-60-f {
        margin-top: 60px!important;
        margin-bottom: 60px!important
    }
    .mg-md-x-60 {
        margin-left: 60px;
        margin-right: 60px
    }
    .mg-md-x-60-f {
        margin-left: 60px!important;
        margin-right: 60px!important
    }
    .mg-md-t-60 {
        margin-top: 60px
    }
    .mg-md-r-60 {
        margin-right: 60px
    }
    .mg-md-b-60 {
        margin-bottom: 60px
    }
    .mg-md-l-60 {
        margin-left: 60px
    }
    .mg-md-t-60-f {
        margin-top: 60px!important
    }
    .mg-md-r-60-f {
        margin-right: 60px!important
    }
    .mg-md-b-60-f {
        margin-bottom: 60px!important
    }
    .mg-md-l-60-f {
        margin-left: 60px!important
    }
    .mg-md-65 {
        margin: 65px
    }
    .mg-md-65-f {
        margin: 65px!important
    }
    .mg-md-y-65 {
        margin-top: 65px;
        margin-bottom: 65px
    }
    .mg-md-y-65-f {
        margin-top: 65px!important;
        margin-bottom: 65px!important
    }
    .mg-md-x-65 {
        margin-left: 65px;
        margin-right: 65px
    }
    .mg-md-x-65-f {
        margin-left: 65px!important;
        margin-right: 65px!important
    }
    .mg-md-t-65 {
        margin-top: 65px
    }
    .mg-md-r-65 {
        margin-right: 65px
    }
    .mg-md-b-65 {
        margin-bottom: 65px
    }
    .mg-md-l-65 {
        margin-left: 65px
    }
    .mg-md-t-65-f {
        margin-top: 65px!important
    }
    .mg-md-r-65-f {
        margin-right: 65px!important
    }
    .mg-md-b-65-f {
        margin-bottom: 65px!important
    }
    .mg-md-l-65-f {
        margin-left: 65px!important
    }
    .mg-md-70 {
        margin: 70px
    }
    .mg-md-70-f {
        margin: 70px!important
    }
    .mg-md-y-70 {
        margin-top: 70px;
        margin-bottom: 70px
    }
    .mg-md-y-70-f {
        margin-top: 70px!important;
        margin-bottom: 70px!important
    }
    .mg-md-x-70 {
        margin-left: 70px;
        margin-right: 70px
    }
    .mg-md-x-70-f {
        margin-left: 70px!important;
        margin-right: 70px!important
    }
    .mg-md-t-70 {
        margin-top: 70px
    }
    .mg-md-r-70 {
        margin-right: 70px
    }
    .mg-md-b-70 {
        margin-bottom: 70px
    }
    .mg-md-l-70 {
        margin-left: 70px
    }
    .mg-md-t-70-f {
        margin-top: 70px!important
    }
    .mg-md-r-70-f {
        margin-right: 70px!important
    }
    .mg-md-b-70-f {
        margin-bottom: 70px!important
    }
    .mg-md-l-70-f {
        margin-left: 70px!important
    }
    .mg-md-75 {
        margin: 75px
    }
    .mg-md-75-f {
        margin: 75px!important
    }
    .mg-md-y-75 {
        margin-top: 75px;
        margin-bottom: 75px
    }
    .mg-md-y-75-f {
        margin-top: 75px!important;
        margin-bottom: 75px!important
    }
    .mg-md-x-75 {
        margin-left: 75px;
        margin-right: 75px
    }
    .mg-md-x-75-f {
        margin-left: 75px!important;
        margin-right: 75px!important
    }
    .mg-md-t-75 {
        margin-top: 75px
    }
    .mg-md-r-75 {
        margin-right: 75px
    }
    .mg-md-b-75 {
        margin-bottom: 75px
    }
    .mg-md-l-75 {
        margin-left: 75px
    }
    .mg-md-t-75-f {
        margin-top: 75px!important
    }
    .mg-md-r-75-f {
        margin-right: 75px!important
    }
    .mg-md-b-75-f {
        margin-bottom: 75px!important
    }
    .mg-md-l-75-f {
        margin-left: 75px!important
    }
    .mg-md-80 {
        margin: 80px
    }
    .mg-md-80-f {
        margin: 80px!important
    }
    .mg-md-y-80 {
        margin-top: 80px;
        margin-bottom: 80px
    }
    .mg-md-y-80-f {
        margin-top: 80px!important;
        margin-bottom: 80px!important
    }
    .mg-md-x-80 {
        margin-left: 80px;
        margin-right: 80px
    }
    .mg-md-x-80-f {
        margin-left: 80px!important;
        margin-right: 80px!important
    }
    .mg-md-t-80 {
        margin-top: 80px
    }
    .mg-md-r-80 {
        margin-right: 80px
    }
    .mg-md-b-80 {
        margin-bottom: 80px
    }
    .mg-md-l-80 {
        margin-left: 80px
    }
    .mg-md-t-80-f {
        margin-top: 80px!important
    }
    .mg-md-r-80-f {
        margin-right: 80px!important
    }
    .mg-md-b-80-f {
        margin-bottom: 80px!important
    }
    .mg-md-l-80-f {
        margin-left: 80px!important
    }
    .mg-md-85 {
        margin: 85px
    }
    .mg-md-85-f {
        margin: 85px!important
    }
    .mg-md-y-85 {
        margin-top: 85px;
        margin-bottom: 85px
    }
    .mg-md-y-85-f {
        margin-top: 85px!important;
        margin-bottom: 85px!important
    }
    .mg-md-x-85 {
        margin-left: 85px;
        margin-right: 85px
    }
    .mg-md-x-85-f {
        margin-left: 85px!important;
        margin-right: 85px!important
    }
    .mg-md-t-85 {
        margin-top: 85px
    }
    .mg-md-r-85 {
        margin-right: 85px
    }
    .mg-md-b-85 {
        margin-bottom: 85px
    }
    .mg-md-l-85 {
        margin-left: 85px
    }
    .mg-md-t-85-f {
        margin-top: 85px!important
    }
    .mg-md-r-85-f {
        margin-right: 85px!important
    }
    .mg-md-b-85-f {
        margin-bottom: 85px!important
    }
    .mg-md-l-85-f {
        margin-left: 85px!important
    }
    .mg-md-90 {
        margin: 90px
    }
    .mg-md-90-f {
        margin: 90px!important
    }
    .mg-md-y-90 {
        margin-top: 90px;
        margin-bottom: 90px
    }
    .mg-md-y-90-f {
        margin-top: 90px!important;
        margin-bottom: 90px!important
    }
    .mg-md-x-90 {
        margin-left: 90px;
        margin-right: 90px
    }
    .mg-md-x-90-f {
        margin-left: 90px!important;
        margin-right: 90px!important
    }
    .mg-md-t-90 {
        margin-top: 90px
    }
    .mg-md-r-90 {
        margin-right: 90px
    }
    .mg-md-b-90 {
        margin-bottom: 90px
    }
    .mg-md-l-90 {
        margin-left: 90px
    }
    .mg-md-t-90-f {
        margin-top: 90px!important
    }
    .mg-md-r-90-f {
        margin-right: 90px!important
    }
    .mg-md-b-90-f {
        margin-bottom: 90px!important
    }
    .mg-md-l-90-f {
        margin-left: 90px!important
    }
    .mg-md-95 {
        margin: 95px
    }
    .mg-md-95-f {
        margin: 95px!important
    }
    .mg-md-y-95 {
        margin-top: 95px;
        margin-bottom: 95px
    }
    .mg-md-y-95-f {
        margin-top: 95px!important;
        margin-bottom: 95px!important
    }
    .mg-md-x-95 {
        margin-left: 95px;
        margin-right: 95px
    }
    .mg-md-x-95-f {
        margin-left: 95px!important;
        margin-right: 95px!important
    }
    .mg-md-t-95 {
        margin-top: 95px
    }
    .mg-md-r-95 {
        margin-right: 95px
    }
    .mg-md-b-95 {
        margin-bottom: 95px
    }
    .mg-md-l-95 {
        margin-left: 95px
    }
    .mg-md-t-95-f {
        margin-top: 95px!important
    }
    .mg-md-r-95-f {
        margin-right: 95px!important
    }
    .mg-md-b-95-f {
        margin-bottom: 95px!important
    }
    .mg-md-l-95-f {
        margin-left: 95px!important
    }
    .mg-md-100 {
        margin: 100px
    }
    .mg-md-100-f {
        margin: 100px!important
    }
    .mg-md-y-100 {
        margin-top: 100px;
        margin-bottom: 100px
    }
    .mg-md-y-100-f {
        margin-top: 100px!important;
        margin-bottom: 100px!important
    }
    .mg-md-x-100 {
        margin-left: 100px;
        margin-right: 100px
    }
    .mg-md-x-100-f {
        margin-left: 100px!important;
        margin-right: 100px!important
    }
    .mg-md-t-100 {
        margin-top: 100px
    }
    .mg-md-r-100 {
        margin-right: 100px
    }
    .mg-md-b-100 {
        margin-bottom: 100px
    }
    .mg-md-l-100 {
        margin-left: 100px
    }
    .mg-md-t-100-f {
        margin-top: 100px!important
    }
    .mg-md-r-100-f {
        margin-right: 100px!important
    }
    .mg-md-b-100-f {
        margin-bottom: 100px!important
    }
    .mg-md-l-100-f {
        margin-left: 100px!important
    }
    .mg-md-t-auto {
        margin-top: auto
    }
    .mg-md-r-auto {
        margin-right: auto
    }
    .mg-md-b-auto {
        margin-bottom: auto
    }
    .mg-md-l-auto {
        margin-left: auto
    }
    .mg-md-auto {
        margin: auto
    }
    .mg-md-x-auto {
        margin-left: auto;
        margin-right: auto
    }
}

@media (min-width:992px) {
    .mg-lg-0 {
        margin: 0
    }
    .mg-lg-0-f {
        margin: 0!important
    }
    .mg-lg-y-0 {
        margin-top: 0;
        margin-bottom: 0
    }
    .mg-lg-y-0-f {
        margin-top: 0!important;
        margin-bottom: 0!important
    }
    .mg-lg-x-0 {
        margin-left: 0;
        margin-right: 0
    }
    .mg-lg-x-0-f {
        margin-left: 0!important;
        margin-right: 0!important
    }
    .mg-lg-t-0 {
        margin-top: 0
    }
    .mg-lg-r-0 {
        margin-right: 0
    }
    .mg-lg-b-0 {
        margin-bottom: 0
    }
    .mg-lg-l-0 {
        margin-left: 0
    }
    .mg-lg-t-0-f {
        margin-top: 0!important
    }
    .mg-lg-r-0-f {
        margin-right: 0!important
    }
    .mg-lg-b-0-f {
        margin-bottom: 0!important
    }
    .mg-lg-l-0-f {
        margin-left: 0!important
    }
    .mg-lg-1 {
        margin: 1px
    }
    .mg-lg-1-f {
        margin: 1px!important
    }
    .mg-lg-y-1 {
        margin-top: 1px;
        margin-bottom: 1px
    }
    .mg-lg-y-1-f {
        margin-top: 1px!important;
        margin-bottom: 1px!important
    }
    .mg-lg-x-1 {
        margin-left: 1px;
        margin-right: 1px
    }
    .mg-lg-x-1-f {
        margin-left: 1px!important;
        margin-right: 1px!important
    }
    .mg-lg-t-1 {
        margin-top: 1px
    }
    .mg-lg-r-1 {
        margin-right: 1px
    }
    .mg-lg-b-1 {
        margin-bottom: 1px
    }
    .mg-lg-l-1 {
        margin-left: 1px
    }
    .mg-lg-t-1-f {
        margin-top: 1px!important
    }
    .mg-lg-r-1-f {
        margin-right: 1px!important
    }
    .mg-lg-b-1-f {
        margin-bottom: 1px!important
    }
    .mg-lg-l-1-f {
        margin-left: 1px!important
    }
    .mg-lg-2 {
        margin: 2px
    }
    .mg-lg-2-f {
        margin: 2px!important
    }
    .mg-lg-y-2 {
        margin-top: 2px;
        margin-bottom: 2px
    }
    .mg-lg-y-2-f {
        margin-top: 2px!important;
        margin-bottom: 2px!important
    }
    .mg-lg-x-2 {
        margin-left: 2px;
        margin-right: 2px
    }
    .mg-lg-x-2-f {
        margin-left: 2px!important;
        margin-right: 2px!important
    }
    .mg-lg-t-2 {
        margin-top: 2px
    }
    .mg-lg-r-2 {
        margin-right: 2px
    }
    .mg-lg-b-2 {
        margin-bottom: 2px
    }
    .mg-lg-l-2 {
        margin-left: 2px
    }
    .mg-lg-t-2-f {
        margin-top: 2px!important
    }
    .mg-lg-r-2-f {
        margin-right: 2px!important
    }
    .mg-lg-b-2-f {
        margin-bottom: 2px!important
    }
    .mg-lg-l-2-f {
        margin-left: 2px!important
    }
    .mg-lg-3 {
        margin: 3px
    }
    .mg-lg-3-f {
        margin: 3px!important
    }
    .mg-lg-y-3 {
        margin-top: 3px;
        margin-bottom: 3px
    }
    .mg-lg-y-3-f {
        margin-top: 3px!important;
        margin-bottom: 3px!important
    }
    .mg-lg-x-3 {
        margin-left: 3px;
        margin-right: 3px
    }
    .mg-lg-x-3-f {
        margin-left: 3px!important;
        margin-right: 3px!important
    }
    .mg-lg-t-3 {
        margin-top: 3px
    }
    .mg-lg-r-3 {
        margin-right: 3px
    }
    .mg-lg-b-3 {
        margin-bottom: 3px
    }
    .mg-lg-l-3 {
        margin-left: 3px
    }
    .mg-lg-t-3-f {
        margin-top: 3px!important
    }
    .mg-lg-r-3-f {
        margin-right: 3px!important
    }
    .mg-lg-b-3-f {
        margin-bottom: 3px!important
    }
    .mg-lg-l-3-f {
        margin-left: 3px!important
    }
    .mg-lg-4 {
        margin: 4px
    }
    .mg-lg-4-f {
        margin: 4px!important
    }
    .mg-lg-y-4 {
        margin-top: 4px;
        margin-bottom: 4px
    }
    .mg-lg-y-4-f {
        margin-top: 4px!important;
        margin-bottom: 4px!important
    }
    .mg-lg-x-4 {
        margin-left: 4px;
        margin-right: 4px
    }
    .mg-lg-x-4-f {
        margin-left: 4px!important;
        margin-right: 4px!important
    }
    .mg-lg-t-4 {
        margin-top: 4px
    }
    .mg-lg-r-4 {
        margin-right: 4px
    }
    .mg-lg-b-4 {
        margin-bottom: 4px
    }
    .mg-lg-l-4 {
        margin-left: 4px
    }
    .mg-lg-t-4-f {
        margin-top: 4px!important
    }
    .mg-lg-r-4-f {
        margin-right: 4px!important
    }
    .mg-lg-b-4-f {
        margin-bottom: 4px!important
    }
    .mg-lg-l-4-f {
        margin-left: 4px!important
    }
    .mg-lg-5 {
        margin: 5px
    }
    .mg-lg-5-f {
        margin: 5px!important
    }
    .mg-lg-y-5 {
        margin-top: 5px;
        margin-bottom: 5px
    }
    .mg-lg-y-5-f {
        margin-top: 5px!important;
        margin-bottom: 5px!important
    }
    .mg-lg-x-5 {
        margin-left: 5px;
        margin-right: 5px
    }
    .mg-lg-x-5-f {
        margin-left: 5px!important;
        margin-right: 5px!important
    }
    .mg-lg-t-5 {
        margin-top: 5px
    }
    .mg-lg-r-5 {
        margin-right: 5px
    }
    .mg-lg-b-5 {
        margin-bottom: 5px
    }
    .mg-lg-l-5 {
        margin-left: 5px
    }
    .mg-lg-t-5-f {
        margin-top: 5px!important
    }
    .mg-lg-r-5-f {
        margin-right: 5px!important
    }
    .mg-lg-b-5-f {
        margin-bottom: 5px!important
    }
    .mg-lg-l-5-f {
        margin-left: 5px!important
    }
    .mg-lg-6 {
        margin: 6px
    }
    .mg-lg-6-f {
        margin: 6px!important
    }
    .mg-lg-y-6 {
        margin-top: 6px;
        margin-bottom: 6px
    }
    .mg-lg-y-6-f {
        margin-top: 6px!important;
        margin-bottom: 6px!important
    }
    .mg-lg-x-6 {
        margin-left: 6px;
        margin-right: 6px
    }
    .mg-lg-x-6-f {
        margin-left: 6px!important;
        margin-right: 6px!important
    }
    .mg-lg-t-6 {
        margin-top: 6px
    }
    .mg-lg-r-6 {
        margin-right: 6px
    }
    .mg-lg-b-6 {
        margin-bottom: 6px
    }
    .mg-lg-l-6 {
        margin-left: 6px
    }
    .mg-lg-t-6-f {
        margin-top: 6px!important
    }
    .mg-lg-r-6-f {
        margin-right: 6px!important
    }
    .mg-lg-b-6-f {
        margin-bottom: 6px!important
    }
    .mg-lg-l-6-f {
        margin-left: 6px!important
    }
    .mg-lg-7 {
        margin: 7px
    }
    .mg-lg-7-f {
        margin: 7px!important
    }
    .mg-lg-y-7 {
        margin-top: 7px;
        margin-bottom: 7px
    }
    .mg-lg-y-7-f {
        margin-top: 7px!important;
        margin-bottom: 7px!important
    }
    .mg-lg-x-7 {
        margin-left: 7px;
        margin-right: 7px
    }
    .mg-lg-x-7-f {
        margin-left: 7px!important;
        margin-right: 7px!important
    }
    .mg-lg-t-7 {
        margin-top: 7px
    }
    .mg-lg-r-7 {
        margin-right: 7px
    }
    .mg-lg-b-7 {
        margin-bottom: 7px
    }
    .mg-lg-l-7 {
        margin-left: 7px
    }
    .mg-lg-t-7-f {
        margin-top: 7px!important
    }
    .mg-lg-r-7-f {
        margin-right: 7px!important
    }
    .mg-lg-b-7-f {
        margin-bottom: 7px!important
    }
    .mg-lg-l-7-f {
        margin-left: 7px!important
    }
    .mg-lg-8 {
        margin: 8px
    }
    .mg-lg-8-f {
        margin: 8px!important
    }
    .mg-lg-y-8 {
        margin-top: 8px;
        margin-bottom: 8px
    }
    .mg-lg-y-8-f {
        margin-top: 8px!important;
        margin-bottom: 8px!important
    }
    .mg-lg-x-8 {
        margin-left: 8px;
        margin-right: 8px
    }
    .mg-lg-x-8-f {
        margin-left: 8px!important;
        margin-right: 8px!important
    }
    .mg-lg-t-8 {
        margin-top: 8px
    }
    .mg-lg-r-8 {
        margin-right: 8px
    }
    .mg-lg-b-8 {
        margin-bottom: 8px
    }
    .mg-lg-l-8 {
        margin-left: 8px
    }
    .mg-lg-t-8-f {
        margin-top: 8px!important
    }
    .mg-lg-r-8-f {
        margin-right: 8px!important
    }
    .mg-lg-b-8-f {
        margin-bottom: 8px!important
    }
    .mg-lg-l-8-f {
        margin-left: 8px!important
    }
    .mg-lg-9 {
        margin: 9px
    }
    .mg-lg-9-f {
        margin: 9px!important
    }
    .mg-lg-y-9 {
        margin-top: 9px;
        margin-bottom: 9px
    }
    .mg-lg-y-9-f {
        margin-top: 9px!important;
        margin-bottom: 9px!important
    }
    .mg-lg-x-9 {
        margin-left: 9px;
        margin-right: 9px
    }
    .mg-lg-x-9-f {
        margin-left: 9px!important;
        margin-right: 9px!important
    }
    .mg-lg-t-9 {
        margin-top: 9px
    }
    .mg-lg-r-9 {
        margin-right: 9px
    }
    .mg-lg-b-9 {
        margin-bottom: 9px
    }
    .mg-lg-l-9 {
        margin-left: 9px
    }
    .mg-lg-t-9-f {
        margin-top: 9px!important
    }
    .mg-lg-r-9-f {
        margin-right: 9px!important
    }
    .mg-lg-b-9-f {
        margin-bottom: 9px!important
    }
    .mg-lg-l-9-f {
        margin-left: 9px!important
    }
    .mg-lg-10 {
        margin: 10px
    }
    .mg-lg-10-f {
        margin: 10px!important
    }
    .mg-lg-y-10 {
        margin-top: 10px;
        margin-bottom: 10px
    }
    .mg-lg-y-10-f {
        margin-top: 10px!important;
        margin-bottom: 10px!important
    }
    .mg-lg-x-10 {
        margin-left: 10px;
        margin-right: 10px
    }
    .mg-lg-x-10-f {
        margin-left: 10px!important;
        margin-right: 10px!important
    }
    .mg-lg-t-10 {
        margin-top: 10px
    }
    .mg-lg-r-10 {
        margin-right: 10px
    }
    .mg-lg-b-10 {
        margin-bottom: 10px
    }
    .mg-lg-l-10 {
        margin-left: 10px
    }
    .mg-lg-t-10-f {
        margin-top: 10px!important
    }
    .mg-lg-r-10-f {
        margin-right: 10px!important
    }
    .mg-lg-b-10-f {
        margin-bottom: 10px!important
    }
    .mg-lg-l-10-f {
        margin-left: 10px!important
    }
    .mg-lg-15 {
        margin: 15px
    }
    .mg-lg-15-f {
        margin: 15px!important
    }
    .mg-lg-y-15 {
        margin-top: 15px;
        margin-bottom: 15px
    }
    .mg-lg-y-15-f {
        margin-top: 15px!important;
        margin-bottom: 15px!important
    }
    .mg-lg-x-15 {
        margin-left: 15px;
        margin-right: 15px
    }
    .mg-lg-x-15-f {
        margin-left: 15px!important;
        margin-right: 15px!important
    }
    .mg-lg-t-15 {
        margin-top: 15px
    }
    .mg-lg-r-15 {
        margin-right: 15px
    }
    .mg-lg-b-15 {
        margin-bottom: 15px
    }
    .mg-lg-l-15 {
        margin-left: 15px
    }
    .mg-lg-t-15-f {
        margin-top: 15px!important
    }
    .mg-lg-r-15-f {
        margin-right: 15px!important
    }
    .mg-lg-b-15-f {
        margin-bottom: 15px!important
    }
    .mg-lg-l-15-f {
        margin-left: 15px!important
    }
    .mg-lg-20 {
        margin: 20px
    }
    .mg-lg-20-f {
        margin: 20px!important
    }
    .mg-lg-y-20 {
        margin-top: 20px;
        margin-bottom: 20px
    }
    .mg-lg-y-20-f {
        margin-top: 20px!important;
        margin-bottom: 20px!important
    }
    .mg-lg-x-20 {
        margin-left: 20px;
        margin-right: 20px
    }
    .mg-lg-x-20-f {
        margin-left: 20px!important;
        margin-right: 20px!important
    }
    .mg-lg-t-20 {
        margin-top: 20px
    }
    .mg-lg-r-20 {
        margin-right: 20px
    }
    .mg-lg-b-20 {
        margin-bottom: 20px
    }
    .mg-lg-l-20 {
        margin-left: 20px
    }
    .mg-lg-t-20-f {
        margin-top: 20px!important
    }
    .mg-lg-r-20-f {
        margin-right: 20px!important
    }
    .mg-lg-b-20-f {
        margin-bottom: 20px!important
    }
    .mg-lg-l-20-f {
        margin-left: 20px!important
    }
    .mg-lg-25 {
        margin: 25px
    }
    .mg-lg-25-f {
        margin: 25px!important
    }
    .mg-lg-y-25 {
        margin-top: 25px;
        margin-bottom: 25px
    }
    .mg-lg-y-25-f {
        margin-top: 25px!important;
        margin-bottom: 25px!important
    }
    .mg-lg-x-25 {
        margin-left: 25px;
        margin-right: 25px
    }
    .mg-lg-x-25-f {
        margin-left: 25px!important;
        margin-right: 25px!important
    }
    .mg-lg-t-25 {
        margin-top: 25px
    }
    .mg-lg-r-25 {
        margin-right: 25px
    }
    .mg-lg-b-25 {
        margin-bottom: 25px
    }
    .mg-lg-l-25 {
        margin-left: 25px
    }
    .mg-lg-t-25-f {
        margin-top: 25px!important
    }
    .mg-lg-r-25-f {
        margin-right: 25px!important
    }
    .mg-lg-b-25-f {
        margin-bottom: 25px!important
    }
    .mg-lg-l-25-f {
        margin-left: 25px!important
    }
    .mg-lg-30 {
        margin: 30px
    }
    .mg-lg-30-f {
        margin: 30px!important
    }
    .mg-lg-y-30 {
        margin-top: 30px;
        margin-bottom: 30px
    }
    .mg-lg-y-30-f {
        margin-top: 30px!important;
        margin-bottom: 30px!important
    }
    .mg-lg-x-30 {
        margin-left: 30px;
        margin-right: 30px
    }
    .mg-lg-x-30-f {
        margin-left: 30px!important;
        margin-right: 30px!important
    }
    .mg-lg-t-30 {
        margin-top: 30px
    }
    .mg-lg-r-30 {
        margin-right: 30px
    }
    .mg-lg-b-30 {
        margin-bottom: 30px
    }
    .mg-lg-l-30 {
        margin-left: 30px
    }
    .mg-lg-t-30-f {
        margin-top: 30px!important
    }
    .mg-lg-r-30-f {
        margin-right: 30px!important
    }
    .mg-lg-b-30-f {
        margin-bottom: 30px!important
    }
    .mg-lg-l-30-f {
        margin-left: 30px!important
    }
    .mg-lg-35 {
        margin: 35px
    }
    .mg-lg-35-f {
        margin: 35px!important
    }
    .mg-lg-y-35 {
        margin-top: 35px;
        margin-bottom: 35px
    }
    .mg-lg-y-35-f {
        margin-top: 35px!important;
        margin-bottom: 35px!important
    }
    .mg-lg-x-35 {
        margin-left: 35px;
        margin-right: 35px
    }
    .mg-lg-x-35-f {
        margin-left: 35px!important;
        margin-right: 35px!important
    }
    .mg-lg-t-35 {
        margin-top: 35px
    }
    .mg-lg-r-35 {
        margin-right: 35px
    }
    .mg-lg-b-35 {
        margin-bottom: 35px
    }
    .mg-lg-l-35 {
        margin-left: 35px
    }
    .mg-lg-t-35-f {
        margin-top: 35px!important
    }
    .mg-lg-r-35-f {
        margin-right: 35px!important
    }
    .mg-lg-b-35-f {
        margin-bottom: 35px!important
    }
    .mg-lg-l-35-f {
        margin-left: 35px!important
    }
    .mg-lg-40 {
        margin: 40px
    }
    .mg-lg-40-f {
        margin: 40px!important
    }
    .mg-lg-y-40 {
        margin-top: 40px;
        margin-bottom: 40px
    }
    .mg-lg-y-40-f {
        margin-top: 40px!important;
        margin-bottom: 40px!important
    }
    .mg-lg-x-40 {
        margin-left: 40px;
        margin-right: 40px
    }
    .mg-lg-x-40-f {
        margin-left: 40px!important;
        margin-right: 40px!important
    }
    .mg-lg-t-40 {
        margin-top: 40px
    }
    .mg-lg-r-40 {
        margin-right: 40px
    }
    .mg-lg-b-40 {
        margin-bottom: 40px
    }
    .mg-lg-l-40 {
        margin-left: 40px
    }
    .mg-lg-t-40-f {
        margin-top: 40px!important
    }
    .mg-lg-r-40-f {
        margin-right: 40px!important
    }
    .mg-lg-b-40-f {
        margin-bottom: 40px!important
    }
    .mg-lg-l-40-f {
        margin-left: 40px!important
    }
    .mg-lg-45 {
        margin: 45px
    }
    .mg-lg-45-f {
        margin: 45px!important
    }
    .mg-lg-y-45 {
        margin-top: 45px;
        margin-bottom: 45px
    }
    .mg-lg-y-45-f {
        margin-top: 45px!important;
        margin-bottom: 45px!important
    }
    .mg-lg-x-45 {
        margin-left: 45px;
        margin-right: 45px
    }
    .mg-lg-x-45-f {
        margin-left: 45px!important;
        margin-right: 45px!important
    }
    .mg-lg-t-45 {
        margin-top: 45px
    }
    .mg-lg-r-45 {
        margin-right: 45px
    }
    .mg-lg-b-45 {
        margin-bottom: 45px
    }
    .mg-lg-l-45 {
        margin-left: 45px
    }
    .mg-lg-t-45-f {
        margin-top: 45px!important
    }
    .mg-lg-r-45-f {
        margin-right: 45px!important
    }
    .mg-lg-b-45-f {
        margin-bottom: 45px!important
    }
    .mg-lg-l-45-f {
        margin-left: 45px!important
    }
    .mg-lg-50 {
        margin: 50px
    }
    .mg-lg-50-f {
        margin: 50px!important
    }
    .mg-lg-y-50 {
        margin-top: 50px;
        margin-bottom: 50px
    }
    .mg-lg-y-50-f {
        margin-top: 50px!important;
        margin-bottom: 50px!important
    }
    .mg-lg-x-50 {
        margin-left: 50px;
        margin-right: 50px
    }
    .mg-lg-x-50-f {
        margin-left: 50px!important;
        margin-right: 50px!important
    }
    .mg-lg-t-50 {
        margin-top: 50px
    }
    .mg-lg-r-50 {
        margin-right: 50px
    }
    .mg-lg-b-50 {
        margin-bottom: 50px
    }
    .mg-lg-l-50 {
        margin-left: 50px
    }
    .mg-lg-t-50-f {
        margin-top: 50px!important
    }
    .mg-lg-r-50-f {
        margin-right: 50px!important
    }
    .mg-lg-b-50-f {
        margin-bottom: 50px!important
    }
    .mg-lg-l-50-f {
        margin-left: 50px!important
    }
    .mg-lg-55 {
        margin: 55px
    }
    .mg-lg-55-f {
        margin: 55px!important
    }
    .mg-lg-y-55 {
        margin-top: 55px;
        margin-bottom: 55px
    }
    .mg-lg-y-55-f {
        margin-top: 55px!important;
        margin-bottom: 55px!important
    }
    .mg-lg-x-55 {
        margin-left: 55px;
        margin-right: 55px
    }
    .mg-lg-x-55-f {
        margin-left: 55px!important;
        margin-right: 55px!important
    }
    .mg-lg-t-55 {
        margin-top: 55px
    }
    .mg-lg-r-55 {
        margin-right: 55px
    }
    .mg-lg-b-55 {
        margin-bottom: 55px
    }
    .mg-lg-l-55 {
        margin-left: 55px
    }
    .mg-lg-t-55-f {
        margin-top: 55px!important
    }
    .mg-lg-r-55-f {
        margin-right: 55px!important
    }
    .mg-lg-b-55-f {
        margin-bottom: 55px!important
    }
    .mg-lg-l-55-f {
        margin-left: 55px!important
    }
    .mg-lg-60 {
        margin: 60px
    }
    .mg-lg-60-f {
        margin: 60px!important
    }
    .mg-lg-y-60 {
        margin-top: 60px;
        margin-bottom: 60px
    }
    .mg-lg-y-60-f {
        margin-top: 60px!important;
        margin-bottom: 60px!important
    }
    .mg-lg-x-60 {
        margin-left: 60px;
        margin-right: 60px
    }
    .mg-lg-x-60-f {
        margin-left: 60px!important;
        margin-right: 60px!important
    }
    .mg-lg-t-60 {
        margin-top: 60px
    }
    .mg-lg-r-60 {
        margin-right: 60px
    }
    .mg-lg-b-60 {
        margin-bottom: 60px
    }
    .mg-lg-l-60 {
        margin-left: 60px
    }
    .mg-lg-t-60-f {
        margin-top: 60px!important
    }
    .mg-lg-r-60-f {
        margin-right: 60px!important
    }
    .mg-lg-b-60-f {
        margin-bottom: 60px!important
    }
    .mg-lg-l-60-f {
        margin-left: 60px!important
    }
    .mg-lg-65 {
        margin: 65px
    }
    .mg-lg-65-f {
        margin: 65px!important
    }
    .mg-lg-y-65 {
        margin-top: 65px;
        margin-bottom: 65px
    }
    .mg-lg-y-65-f {
        margin-top: 65px!important;
        margin-bottom: 65px!important
    }
    .mg-lg-x-65 {
        margin-left: 65px;
        margin-right: 65px
    }
    .mg-lg-x-65-f {
        margin-left: 65px!important;
        margin-right: 65px!important
    }
    .mg-lg-t-65 {
        margin-top: 65px
    }
    .mg-lg-r-65 {
        margin-right: 65px
    }
    .mg-lg-b-65 {
        margin-bottom: 65px
    }
    .mg-lg-l-65 {
        margin-left: 65px
    }
    .mg-lg-t-65-f {
        margin-top: 65px!important
    }
    .mg-lg-r-65-f {
        margin-right: 65px!important
    }
    .mg-lg-b-65-f {
        margin-bottom: 65px!important
    }
    .mg-lg-l-65-f {
        margin-left: 65px!important
    }
    .mg-lg-70 {
        margin: 70px
    }
    .mg-lg-70-f {
        margin: 70px!important
    }
    .mg-lg-y-70 {
        margin-top: 70px;
        margin-bottom: 70px
    }
    .mg-lg-y-70-f {
        margin-top: 70px!important;
        margin-bottom: 70px!important
    }
    .mg-lg-x-70 {
        margin-left: 70px;
        margin-right: 70px
    }
    .mg-lg-x-70-f {
        margin-left: 70px!important;
        margin-right: 70px!important
    }
    .mg-lg-t-70 {
        margin-top: 70px
    }
    .mg-lg-r-70 {
        margin-right: 70px
    }
    .mg-lg-b-70 {
        margin-bottom: 70px
    }
    .mg-lg-l-70 {
        margin-left: 70px
    }
    .mg-lg-t-70-f {
        margin-top: 70px!important
    }
    .mg-lg-r-70-f {
        margin-right: 70px!important
    }
    .mg-lg-b-70-f {
        margin-bottom: 70px!important
    }
    .mg-lg-l-70-f {
        margin-left: 70px!important
    }
    .mg-lg-75 {
        margin: 75px
    }
    .mg-lg-75-f {
        margin: 75px!important
    }
    .mg-lg-y-75 {
        margin-top: 75px;
        margin-bottom: 75px
    }
    .mg-lg-y-75-f {
        margin-top: 75px!important;
        margin-bottom: 75px!important
    }
    .mg-lg-x-75 {
        margin-left: 75px;
        margin-right: 75px
    }
    .mg-lg-x-75-f {
        margin-left: 75px!important;
        margin-right: 75px!important
    }
    .mg-lg-t-75 {
        margin-top: 75px
    }
    .mg-lg-r-75 {
        margin-right: 75px
    }
    .mg-lg-b-75 {
        margin-bottom: 75px
    }
    .mg-lg-l-75 {
        margin-left: 75px
    }
    .mg-lg-t-75-f {
        margin-top: 75px!important
    }
    .mg-lg-r-75-f {
        margin-right: 75px!important
    }
    .mg-lg-b-75-f {
        margin-bottom: 75px!important
    }
    .mg-lg-l-75-f {
        margin-left: 75px!important
    }
    .mg-lg-80 {
        margin: 80px
    }
    .mg-lg-80-f {
        margin: 80px!important
    }
    .mg-lg-y-80 {
        margin-top: 80px;
        margin-bottom: 80px
    }
    .mg-lg-y-80-f {
        margin-top: 80px!important;
        margin-bottom: 80px!important
    }
    .mg-lg-x-80 {
        margin-left: 80px;
        margin-right: 80px
    }
    .mg-lg-x-80-f {
        margin-left: 80px!important;
        margin-right: 80px!important
    }
    .mg-lg-t-80 {
        margin-top: 80px
    }
    .mg-lg-r-80 {
        margin-right: 80px
    }
    .mg-lg-b-80 {
        margin-bottom: 80px
    }
    .mg-lg-l-80 {
        margin-left: 80px
    }
    .mg-lg-t-80-f {
        margin-top: 80px!important
    }
    .mg-lg-r-80-f {
        margin-right: 80px!important
    }
    .mg-lg-b-80-f {
        margin-bottom: 80px!important
    }
    .mg-lg-l-80-f {
        margin-left: 80px!important
    }
    .mg-lg-85 {
        margin: 85px
    }
    .mg-lg-85-f {
        margin: 85px!important
    }
    .mg-lg-y-85 {
        margin-top: 85px;
        margin-bottom: 85px
    }
    .mg-lg-y-85-f {
        margin-top: 85px!important;
        margin-bottom: 85px!important
    }
    .mg-lg-x-85 {
        margin-left: 85px;
        margin-right: 85px
    }
    .mg-lg-x-85-f {
        margin-left: 85px!important;
        margin-right: 85px!important
    }
    .mg-lg-t-85 {
        margin-top: 85px
    }
    .mg-lg-r-85 {
        margin-right: 85px
    }
    .mg-lg-b-85 {
        margin-bottom: 85px
    }
    .mg-lg-l-85 {
        margin-left: 85px
    }
    .mg-lg-t-85-f {
        margin-top: 85px!important
    }
    .mg-lg-r-85-f {
        margin-right: 85px!important
    }
    .mg-lg-b-85-f {
        margin-bottom: 85px!important
    }
    .mg-lg-l-85-f {
        margin-left: 85px!important
    }
    .mg-lg-90 {
        margin: 90px
    }
    .mg-lg-90-f {
        margin: 90px!important
    }
    .mg-lg-y-90 {
        margin-top: 90px;
        margin-bottom: 90px
    }
    .mg-lg-y-90-f {
        margin-top: 90px!important;
        margin-bottom: 90px!important
    }
    .mg-lg-x-90 {
        margin-left: 90px;
        margin-right: 90px
    }
    .mg-lg-x-90-f {
        margin-left: 90px!important;
        margin-right: 90px!important
    }
    .mg-lg-t-90 {
        margin-top: 90px
    }
    .mg-lg-r-90 {
        margin-right: 90px
    }
    .mg-lg-b-90 {
        margin-bottom: 90px
    }
    .mg-lg-l-90 {
        margin-left: 90px
    }
    .mg-lg-t-90-f {
        margin-top: 90px!important
    }
    .mg-lg-r-90-f {
        margin-right: 90px!important
    }
    .mg-lg-b-90-f {
        margin-bottom: 90px!important
    }
    .mg-lg-l-90-f {
        margin-left: 90px!important
    }
    .mg-lg-95 {
        margin: 95px
    }
    .mg-lg-95-f {
        margin: 95px!important
    }
    .mg-lg-y-95 {
        margin-top: 95px;
        margin-bottom: 95px
    }
    .mg-lg-y-95-f {
        margin-top: 95px!important;
        margin-bottom: 95px!important
    }
    .mg-lg-x-95 {
        margin-left: 95px;
        margin-right: 95px
    }
    .mg-lg-x-95-f {
        margin-left: 95px!important;
        margin-right: 95px!important
    }
    .mg-lg-t-95 {
        margin-top: 95px
    }
    .mg-lg-r-95 {
        margin-right: 95px
    }
    .mg-lg-b-95 {
        margin-bottom: 95px
    }
    .mg-lg-l-95 {
        margin-left: 95px
    }
    .mg-lg-t-95-f {
        margin-top: 95px!important
    }
    .mg-lg-r-95-f {
        margin-right: 95px!important
    }
    .mg-lg-b-95-f {
        margin-bottom: 95px!important
    }
    .mg-lg-l-95-f {
        margin-left: 95px!important
    }
    .mg-lg-100 {
        margin: 100px
    }
    .mg-lg-100-f {
        margin: 100px!important
    }
    .mg-lg-y-100 {
        margin-top: 100px;
        margin-bottom: 100px
    }
    .mg-lg-y-100-f {
        margin-top: 100px!important;
        margin-bottom: 100px!important
    }
    .mg-lg-x-100 {
        margin-left: 100px;
        margin-right: 100px
    }
    .mg-lg-x-100-f {
        margin-left: 100px!important;
        margin-right: 100px!important
    }
    .mg-lg-t-100 {
        margin-top: 100px
    }
    .mg-lg-r-100 {
        margin-right: 100px
    }
    .mg-lg-b-100 {
        margin-bottom: 100px
    }
    .mg-lg-l-100 {
        margin-left: 100px
    }
    .mg-lg-t-100-f {
        margin-top: 100px!important
    }
    .mg-lg-r-100-f {
        margin-right: 100px!important
    }
    .mg-lg-b-100-f {
        margin-bottom: 100px!important
    }
    .mg-lg-l-100-f {
        margin-left: 100px!important
    }
    .mg-lg-t-auto {
        margin-top: auto
    }
    .mg-lg-r-auto {
        margin-right: auto
    }
    .mg-lg-b-auto {
        margin-bottom: auto
    }
    .mg-lg-l-auto {
        margin-left: auto
    }
    .mg-lg-auto {
        margin: auto
    }
    .mg-lg-x-auto {
        margin-left: auto;
        margin-right: auto
    }
}

@media (min-width:1200px) {
    .mg-xl-0 {
        margin: 0
    }
    .mg-xl-0-f {
        margin: 0!important
    }
    .mg-xl-y-0 {
        margin-top: 0;
        margin-bottom: 0
    }
    .mg-xl-y-0-f {
        margin-top: 0!important;
        margin-bottom: 0!important
    }
    .mg-xl-x-0 {
        margin-left: 0;
        margin-right: 0
    }
    .mg-xl-x-0-f {
        margin-left: 0!important;
        margin-right: 0!important
    }
    .mg-xl-t-0 {
        margin-top: 0
    }
    .mg-xl-r-0 {
        margin-right: 0
    }
    .mg-xl-b-0 {
        margin-bottom: 0
    }
    .mg-xl-l-0 {
        margin-left: 0
    }
    .mg-xl-t-0-f {
        margin-top: 0!important
    }
    .mg-xl-r-0-f {
        margin-right: 0!important
    }
    .mg-xl-b-0-f {
        margin-bottom: 0!important
    }
    .mg-xl-l-0-f {
        margin-left: 0!important
    }
    .mg-xl-1 {
        margin: 1px
    }
    .mg-xl-1-f {
        margin: 1px!important
    }
    .mg-xl-y-1 {
        margin-top: 1px;
        margin-bottom: 1px
    }
    .mg-xl-y-1-f {
        margin-top: 1px!important;
        margin-bottom: 1px!important
    }
    .mg-xl-x-1 {
        margin-left: 1px;
        margin-right: 1px
    }
    .mg-xl-x-1-f {
        margin-left: 1px!important;
        margin-right: 1px!important
    }
    .mg-xl-t-1 {
        margin-top: 1px
    }
    .mg-xl-r-1 {
        margin-right: 1px
    }
    .mg-xl-b-1 {
        margin-bottom: 1px
    }
    .mg-xl-l-1 {
        margin-left: 1px
    }
    .mg-xl-t-1-f {
        margin-top: 1px!important
    }
    .mg-xl-r-1-f {
        margin-right: 1px!important
    }
    .mg-xl-b-1-f {
        margin-bottom: 1px!important
    }
    .mg-xl-l-1-f {
        margin-left: 1px!important
    }
    .mg-xl-2 {
        margin: 2px
    }
    .mg-xl-2-f {
        margin: 2px!important
    }
    .mg-xl-y-2 {
        margin-top: 2px;
        margin-bottom: 2px
    }
    .mg-xl-y-2-f {
        margin-top: 2px!important;
        margin-bottom: 2px!important
    }
    .mg-xl-x-2 {
        margin-left: 2px;
        margin-right: 2px
    }
    .mg-xl-x-2-f {
        margin-left: 2px!important;
        margin-right: 2px!important
    }
    .mg-xl-t-2 {
        margin-top: 2px
    }
    .mg-xl-r-2 {
        margin-right: 2px
    }
    .mg-xl-b-2 {
        margin-bottom: 2px
    }
    .mg-xl-l-2 {
        margin-left: 2px
    }
    .mg-xl-t-2-f {
        margin-top: 2px!important
    }
    .mg-xl-r-2-f {
        margin-right: 2px!important
    }
    .mg-xl-b-2-f {
        margin-bottom: 2px!important
    }
    .mg-xl-l-2-f {
        margin-left: 2px!important
    }
    .mg-xl-3 {
        margin: 3px
    }
    .mg-xl-3-f {
        margin: 3px!important
    }
    .mg-xl-y-3 {
        margin-top: 3px;
        margin-bottom: 3px
    }
    .mg-xl-y-3-f {
        margin-top: 3px!important;
        margin-bottom: 3px!important
    }
    .mg-xl-x-3 {
        margin-left: 3px;
        margin-right: 3px
    }
    .mg-xl-x-3-f {
        margin-left: 3px!important;
        margin-right: 3px!important
    }
    .mg-xl-t-3 {
        margin-top: 3px
    }
    .mg-xl-r-3 {
        margin-right: 3px
    }
    .mg-xl-b-3 {
        margin-bottom: 3px
    }
    .mg-xl-l-3 {
        margin-left: 3px
    }
    .mg-xl-t-3-f {
        margin-top: 3px!important
    }
    .mg-xl-r-3-f {
        margin-right: 3px!important
    }
    .mg-xl-b-3-f {
        margin-bottom: 3px!important
    }
    .mg-xl-l-3-f {
        margin-left: 3px!important
    }
    .mg-xl-4 {
        margin: 4px
    }
    .mg-xl-4-f {
        margin: 4px!important
    }
    .mg-xl-y-4 {
        margin-top: 4px;
        margin-bottom: 4px
    }
    .mg-xl-y-4-f {
        margin-top: 4px!important;
        margin-bottom: 4px!important
    }
    .mg-xl-x-4 {
        margin-left: 4px;
        margin-right: 4px
    }
    .mg-xl-x-4-f {
        margin-left: 4px!important;
        margin-right: 4px!important
    }
    .mg-xl-t-4 {
        margin-top: 4px
    }
    .mg-xl-r-4 {
        margin-right: 4px
    }
    .mg-xl-b-4 {
        margin-bottom: 4px
    }
    .mg-xl-l-4 {
        margin-left: 4px
    }
    .mg-xl-t-4-f {
        margin-top: 4px!important
    }
    .mg-xl-r-4-f {
        margin-right: 4px!important
    }
    .mg-xl-b-4-f {
        margin-bottom: 4px!important
    }
    .mg-xl-l-4-f {
        margin-left: 4px!important
    }
    .mg-xl-5 {
        margin: 5px
    }
    .mg-xl-5-f {
        margin: 5px!important
    }
    .mg-xl-y-5 {
        margin-top: 5px;
        margin-bottom: 5px
    }
    .mg-xl-y-5-f {
        margin-top: 5px!important;
        margin-bottom: 5px!important
    }
    .mg-xl-x-5 {
        margin-left: 5px;
        margin-right: 5px
    }
    .mg-xl-x-5-f {
        margin-left: 5px!important;
        margin-right: 5px!important
    }
    .mg-xl-t-5 {
        margin-top: 5px
    }
    .mg-xl-r-5 {
        margin-right: 5px
    }
    .mg-xl-b-5 {
        margin-bottom: 5px
    }
    .mg-xl-l-5 {
        margin-left: 5px
    }
    .mg-xl-t-5-f {
        margin-top: 5px!important
    }
    .mg-xl-r-5-f {
        margin-right: 5px!important
    }
    .mg-xl-b-5-f {
        margin-bottom: 5px!important
    }
    .mg-xl-l-5-f {
        margin-left: 5px!important
    }
    .mg-xl-6 {
        margin: 6px
    }
    .mg-xl-6-f {
        margin: 6px!important
    }
    .mg-xl-y-6 {
        margin-top: 6px;
        margin-bottom: 6px
    }
    .mg-xl-y-6-f {
        margin-top: 6px!important;
        margin-bottom: 6px!important
    }
    .mg-xl-x-6 {
        margin-left: 6px;
        margin-right: 6px
    }
    .mg-xl-x-6-f {
        margin-left: 6px!important;
        margin-right: 6px!important
    }
    .mg-xl-t-6 {
        margin-top: 6px
    }
    .mg-xl-r-6 {
        margin-right: 6px
    }
    .mg-xl-b-6 {
        margin-bottom: 6px
    }
    .mg-xl-l-6 {
        margin-left: 6px
    }
    .mg-xl-t-6-f {
        margin-top: 6px!important
    }
    .mg-xl-r-6-f {
        margin-right: 6px!important
    }
    .mg-xl-b-6-f {
        margin-bottom: 6px!important
    }
    .mg-xl-l-6-f {
        margin-left: 6px!important
    }
    .mg-xl-7 {
        margin: 7px
    }
    .mg-xl-7-f {
        margin: 7px!important
    }
    .mg-xl-y-7 {
        margin-top: 7px;
        margin-bottom: 7px
    }
    .mg-xl-y-7-f {
        margin-top: 7px!important;
        margin-bottom: 7px!important
    }
    .mg-xl-x-7 {
        margin-left: 7px;
        margin-right: 7px
    }
    .mg-xl-x-7-f {
        margin-left: 7px!important;
        margin-right: 7px!important
    }
    .mg-xl-t-7 {
        margin-top: 7px
    }
    .mg-xl-r-7 {
        margin-right: 7px
    }
    .mg-xl-b-7 {
        margin-bottom: 7px
    }
    .mg-xl-l-7 {
        margin-left: 7px
    }
    .mg-xl-t-7-f {
        margin-top: 7px!important
    }
    .mg-xl-r-7-f {
        margin-right: 7px!important
    }
    .mg-xl-b-7-f {
        margin-bottom: 7px!important
    }
    .mg-xl-l-7-f {
        margin-left: 7px!important
    }
    .mg-xl-8 {
        margin: 8px
    }
    .mg-xl-8-f {
        margin: 8px!important
    }
    .mg-xl-y-8 {
        margin-top: 8px;
        margin-bottom: 8px
    }
    .mg-xl-y-8-f {
        margin-top: 8px!important;
        margin-bottom: 8px!important
    }
    .mg-xl-x-8 {
        margin-left: 8px;
        margin-right: 8px
    }
    .mg-xl-x-8-f {
        margin-left: 8px!important;
        margin-right: 8px!important
    }
    .mg-xl-t-8 {
        margin-top: 8px
    }
    .mg-xl-r-8 {
        margin-right: 8px
    }
    .mg-xl-b-8 {
        margin-bottom: 8px
    }
    .mg-xl-l-8 {
        margin-left: 8px
    }
    .mg-xl-t-8-f {
        margin-top: 8px!important
    }
    .mg-xl-r-8-f {
        margin-right: 8px!important
    }
    .mg-xl-b-8-f {
        margin-bottom: 8px!important
    }
    .mg-xl-l-8-f {
        margin-left: 8px!important
    }
    .mg-xl-9 {
        margin: 9px
    }
    .mg-xl-9-f {
        margin: 9px!important
    }
    .mg-xl-y-9 {
        margin-top: 9px;
        margin-bottom: 9px
    }
    .mg-xl-y-9-f {
        margin-top: 9px!important;
        margin-bottom: 9px!important
    }
    .mg-xl-x-9 {
        margin-left: 9px;
        margin-right: 9px
    }
    .mg-xl-x-9-f {
        margin-left: 9px!important;
        margin-right: 9px!important
    }
    .mg-xl-t-9 {
        margin-top: 9px
    }
    .mg-xl-r-9 {
        margin-right: 9px
    }
    .mg-xl-b-9 {
        margin-bottom: 9px
    }
    .mg-xl-l-9 {
        margin-left: 9px
    }
    .mg-xl-t-9-f {
        margin-top: 9px!important
    }
    .mg-xl-r-9-f {
        margin-right: 9px!important
    }
    .mg-xl-b-9-f {
        margin-bottom: 9px!important
    }
    .mg-xl-l-9-f {
        margin-left: 9px!important
    }
    .mg-xl-10 {
        margin: 10px
    }
    .mg-xl-10-f {
        margin: 10px!important
    }
    .mg-xl-y-10 {
        margin-top: 10px;
        margin-bottom: 10px
    }
    .mg-xl-y-10-f {
        margin-top: 10px!important;
        margin-bottom: 10px!important
    }
    .mg-xl-x-10 {
        margin-left: 10px;
        margin-right: 10px
    }
    .mg-xl-x-10-f {
        margin-left: 10px!important;
        margin-right: 10px!important
    }
    .mg-xl-t-10 {
        margin-top: 10px
    }
    .mg-xl-r-10 {
        margin-right: 10px
    }
    .mg-xl-b-10 {
        margin-bottom: 10px
    }
    .mg-xl-l-10 {
        margin-left: 10px
    }
    .mg-xl-t-10-f {
        margin-top: 10px!important
    }
    .mg-xl-r-10-f {
        margin-right: 10px!important
    }
    .mg-xl-b-10-f {
        margin-bottom: 10px!important
    }
    .mg-xl-l-10-f {
        margin-left: 10px!important
    }
    .mg-xl-15 {
        margin: 15px
    }
    .mg-xl-15-f {
        margin: 15px!important
    }
    .mg-xl-y-15 {
        margin-top: 15px;
        margin-bottom: 15px
    }
    .mg-xl-y-15-f {
        margin-top: 15px!important;
        margin-bottom: 15px!important
    }
    .mg-xl-x-15 {
        margin-left: 15px;
        margin-right: 15px
    }
    .mg-xl-x-15-f {
        margin-left: 15px!important;
        margin-right: 15px!important
    }
    .mg-xl-t-15 {
        margin-top: 15px
    }
    .mg-xl-r-15 {
        margin-right: 15px
    }
    .mg-xl-b-15 {
        margin-bottom: 15px
    }
    .mg-xl-l-15 {
        margin-left: 15px
    }
    .mg-xl-t-15-f {
        margin-top: 15px!important
    }
    .mg-xl-r-15-f {
        margin-right: 15px!important
    }
    .mg-xl-b-15-f {
        margin-bottom: 15px!important
    }
    .mg-xl-l-15-f {
        margin-left: 15px!important
    }
    .mg-xl-20 {
        margin: 20px
    }
    .mg-xl-20-f {
        margin: 20px!important
    }
    .mg-xl-y-20 {
        margin-top: 20px;
        margin-bottom: 20px
    }
    .mg-xl-y-20-f {
        margin-top: 20px!important;
        margin-bottom: 20px!important
    }
    .mg-xl-x-20 {
        margin-left: 20px;
        margin-right: 20px
    }
    .mg-xl-x-20-f {
        margin-left: 20px!important;
        margin-right: 20px!important
    }
    .mg-xl-t-20 {
        margin-top: 20px
    }
    .mg-xl-r-20 {
        margin-right: 20px
    }
    .mg-xl-b-20 {
        margin-bottom: 20px
    }
    .mg-xl-l-20 {
        margin-left: 20px
    }
    .mg-xl-t-20-f {
        margin-top: 20px!important
    }
    .mg-xl-r-20-f {
        margin-right: 20px!important
    }
    .mg-xl-b-20-f {
        margin-bottom: 20px!important
    }
    .mg-xl-l-20-f {
        margin-left: 20px!important
    }
    .mg-xl-25 {
        margin: 25px
    }
    .mg-xl-25-f {
        margin: 25px!important
    }
    .mg-xl-y-25 {
        margin-top: 25px;
        margin-bottom: 25px
    }
    .mg-xl-y-25-f {
        margin-top: 25px!important;
        margin-bottom: 25px!important
    }
    .mg-xl-x-25 {
        margin-left: 25px;
        margin-right: 25px
    }
    .mg-xl-x-25-f {
        margin-left: 25px!important;
        margin-right: 25px!important
    }
    .mg-xl-t-25 {
        margin-top: 25px
    }
    .mg-xl-r-25 {
        margin-right: 25px
    }
    .mg-xl-b-25 {
        margin-bottom: 25px
    }
    .mg-xl-l-25 {
        margin-left: 25px
    }
    .mg-xl-t-25-f {
        margin-top: 25px!important
    }
    .mg-xl-r-25-f {
        margin-right: 25px!important
    }
    .mg-xl-b-25-f {
        margin-bottom: 25px!important
    }
    .mg-xl-l-25-f {
        margin-left: 25px!important
    }
    .mg-xl-30 {
        margin: 30px
    }
    .mg-xl-30-f {
        margin: 30px!important
    }
    .mg-xl-y-30 {
        margin-top: 30px;
        margin-bottom: 30px
    }
    .mg-xl-y-30-f {
        margin-top: 30px!important;
        margin-bottom: 30px!important
    }
    .mg-xl-x-30 {
        margin-left: 30px;
        margin-right: 30px
    }
    .mg-xl-x-30-f {
        margin-left: 30px!important;
        margin-right: 30px!important
    }
    .mg-xl-t-30 {
        margin-top: 30px
    }
    .mg-xl-r-30 {
        margin-right: 30px
    }
    .mg-xl-b-30 {
        margin-bottom: 30px
    }
    .mg-xl-l-30 {
        margin-left: 30px
    }
    .mg-xl-t-30-f {
        margin-top: 30px!important
    }
    .mg-xl-r-30-f {
        margin-right: 30px!important
    }
    .mg-xl-b-30-f {
        margin-bottom: 30px!important
    }
    .mg-xl-l-30-f {
        margin-left: 30px!important
    }
    .mg-xl-35 {
        margin: 35px
    }
    .mg-xl-35-f {
        margin: 35px!important
    }
    .mg-xl-y-35 {
        margin-top: 35px;
        margin-bottom: 35px
    }
    .mg-xl-y-35-f {
        margin-top: 35px!important;
        margin-bottom: 35px!important
    }
    .mg-xl-x-35 {
        margin-left: 35px;
        margin-right: 35px
    }
    .mg-xl-x-35-f {
        margin-left: 35px!important;
        margin-right: 35px!important
    }
    .mg-xl-t-35 {
        margin-top: 35px
    }
    .mg-xl-r-35 {
        margin-right: 35px
    }
    .mg-xl-b-35 {
        margin-bottom: 35px
    }
    .mg-xl-l-35 {
        margin-left: 35px
    }
    .mg-xl-t-35-f {
        margin-top: 35px!important
    }
    .mg-xl-r-35-f {
        margin-right: 35px!important
    }
    .mg-xl-b-35-f {
        margin-bottom: 35px!important
    }
    .mg-xl-l-35-f {
        margin-left: 35px!important
    }
    .mg-xl-40 {
        margin: 40px
    }
    .mg-xl-40-f {
        margin: 40px!important
    }
    .mg-xl-y-40 {
        margin-top: 40px;
        margin-bottom: 40px
    }
    .mg-xl-y-40-f {
        margin-top: 40px!important;
        margin-bottom: 40px!important
    }
    .mg-xl-x-40 {
        margin-left: 40px;
        margin-right: 40px
    }
    .mg-xl-x-40-f {
        margin-left: 40px!important;
        margin-right: 40px!important
    }
    .mg-xl-t-40 {
        margin-top: 40px
    }
    .mg-xl-r-40 {
        margin-right: 40px
    }
    .mg-xl-b-40 {
        margin-bottom: 40px
    }
    .mg-xl-l-40 {
        margin-left: 40px
    }
    .mg-xl-t-40-f {
        margin-top: 40px!important
    }
    .mg-xl-r-40-f {
        margin-right: 40px!important
    }
    .mg-xl-b-40-f {
        margin-bottom: 40px!important
    }
    .mg-xl-l-40-f {
        margin-left: 40px!important
    }
    .mg-xl-45 {
        margin: 45px
    }
    .mg-xl-45-f {
        margin: 45px!important
    }
    .mg-xl-y-45 {
        margin-top: 45px;
        margin-bottom: 45px
    }
    .mg-xl-y-45-f {
        margin-top: 45px!important;
        margin-bottom: 45px!important
    }
    .mg-xl-x-45 {
        margin-left: 45px;
        margin-right: 45px
    }
    .mg-xl-x-45-f {
        margin-left: 45px!important;
        margin-right: 45px!important
    }
    .mg-xl-t-45 {
        margin-top: 45px
    }
    .mg-xl-r-45 {
        margin-right: 45px
    }
    .mg-xl-b-45 {
        margin-bottom: 45px
    }
    .mg-xl-l-45 {
        margin-left: 45px
    }
    .mg-xl-t-45-f {
        margin-top: 45px!important
    }
    .mg-xl-r-45-f {
        margin-right: 45px!important
    }
    .mg-xl-b-45-f {
        margin-bottom: 45px!important
    }
    .mg-xl-l-45-f {
        margin-left: 45px!important
    }
    .mg-xl-50 {
        margin: 50px
    }
    .mg-xl-50-f {
        margin: 50px!important
    }
    .mg-xl-y-50 {
        margin-top: 50px;
        margin-bottom: 50px
    }
    .mg-xl-y-50-f {
        margin-top: 50px!important;
        margin-bottom: 50px!important
    }
    .mg-xl-x-50 {
        margin-left: 50px;
        margin-right: 50px
    }
    .mg-xl-x-50-f {
        margin-left: 50px!important;
        margin-right: 50px!important
    }
    .mg-xl-t-50 {
        margin-top: 50px
    }
    .mg-xl-r-50 {
        margin-right: 50px
    }
    .mg-xl-b-50 {
        margin-bottom: 50px
    }
    .mg-xl-l-50 {
        margin-left: 50px
    }
    .mg-xl-t-50-f {
        margin-top: 50px!important
    }
    .mg-xl-r-50-f {
        margin-right: 50px!important
    }
    .mg-xl-b-50-f {
        margin-bottom: 50px!important
    }
    .mg-xl-l-50-f {
        margin-left: 50px!important
    }
    .mg-xl-55 {
        margin: 55px
    }
    .mg-xl-55-f {
        margin: 55px!important
    }
    .mg-xl-y-55 {
        margin-top: 55px;
        margin-bottom: 55px
    }
    .mg-xl-y-55-f {
        margin-top: 55px!important;
        margin-bottom: 55px!important
    }
    .mg-xl-x-55 {
        margin-left: 55px;
        margin-right: 55px
    }
    .mg-xl-x-55-f {
        margin-left: 55px!important;
        margin-right: 55px!important
    }
    .mg-xl-t-55 {
        margin-top: 55px
    }
    .mg-xl-r-55 {
        margin-right: 55px
    }
    .mg-xl-b-55 {
        margin-bottom: 55px
    }
    .mg-xl-l-55 {
        margin-left: 55px
    }
    .mg-xl-t-55-f {
        margin-top: 55px!important
    }
    .mg-xl-r-55-f {
        margin-right: 55px!important
    }
    .mg-xl-b-55-f {
        margin-bottom: 55px!important
    }
    .mg-xl-l-55-f {
        margin-left: 55px!important
    }
    .mg-xl-60 {
        margin: 60px
    }
    .mg-xl-60-f {
        margin: 60px!important
    }
    .mg-xl-y-60 {
        margin-top: 60px;
        margin-bottom: 60px
    }
    .mg-xl-y-60-f {
        margin-top: 60px!important;
        margin-bottom: 60px!important
    }
    .mg-xl-x-60 {
        margin-left: 60px;
        margin-right: 60px
    }
    .mg-xl-x-60-f {
        margin-left: 60px!important;
        margin-right: 60px!important
    }
    .mg-xl-t-60 {
        margin-top: 60px
    }
    .mg-xl-r-60 {
        margin-right: 60px
    }
    .mg-xl-b-60 {
        margin-bottom: 60px
    }
    .mg-xl-l-60 {
        margin-left: 60px
    }
    .mg-xl-t-60-f {
        margin-top: 60px!important
    }
    .mg-xl-r-60-f {
        margin-right: 60px!important
    }
    .mg-xl-b-60-f {
        margin-bottom: 60px!important
    }
    .mg-xl-l-60-f {
        margin-left: 60px!important
    }
    .mg-xl-65 {
        margin: 65px
    }
    .mg-xl-65-f {
        margin: 65px!important
    }
    .mg-xl-y-65 {
        margin-top: 65px;
        margin-bottom: 65px
    }
    .mg-xl-y-65-f {
        margin-top: 65px!important;
        margin-bottom: 65px!important
    }
    .mg-xl-x-65 {
        margin-left: 65px;
        margin-right: 65px
    }
    .mg-xl-x-65-f {
        margin-left: 65px!important;
        margin-right: 65px!important
    }
    .mg-xl-t-65 {
        margin-top: 65px
    }
    .mg-xl-r-65 {
        margin-right: 65px
    }
    .mg-xl-b-65 {
        margin-bottom: 65px
    }
    .mg-xl-l-65 {
        margin-left: 65px
    }
    .mg-xl-t-65-f {
        margin-top: 65px!important
    }
    .mg-xl-r-65-f {
        margin-right: 65px!important
    }
    .mg-xl-b-65-f {
        margin-bottom: 65px!important
    }
    .mg-xl-l-65-f {
        margin-left: 65px!important
    }
    .mg-xl-70 {
        margin: 70px
    }
    .mg-xl-70-f {
        margin: 70px!important
    }
    .mg-xl-y-70 {
        margin-top: 70px;
        margin-bottom: 70px
    }
    .mg-xl-y-70-f {
        margin-top: 70px!important;
        margin-bottom: 70px!important
    }
    .mg-xl-x-70 {
        margin-left: 70px;
        margin-right: 70px
    }
    .mg-xl-x-70-f {
        margin-left: 70px!important;
        margin-right: 70px!important
    }
    .mg-xl-t-70 {
        margin-top: 70px
    }
    .mg-xl-r-70 {
        margin-right: 70px
    }
    .mg-xl-b-70 {
        margin-bottom: 70px
    }
    .mg-xl-l-70 {
        margin-left: 70px
    }
    .mg-xl-t-70-f {
        margin-top: 70px!important
    }
    .mg-xl-r-70-f {
        margin-right: 70px!important
    }
    .mg-xl-b-70-f {
        margin-bottom: 70px!important
    }
    .mg-xl-l-70-f {
        margin-left: 70px!important
    }
    .mg-xl-75 {
        margin: 75px
    }
    .mg-xl-75-f {
        margin: 75px!important
    }
    .mg-xl-y-75 {
        margin-top: 75px;
        margin-bottom: 75px
    }
    .mg-xl-y-75-f {
        margin-top: 75px!important;
        margin-bottom: 75px!important
    }
    .mg-xl-x-75 {
        margin-left: 75px;
        margin-right: 75px
    }
    .mg-xl-x-75-f {
        margin-left: 75px!important;
        margin-right: 75px!important
    }
    .mg-xl-t-75 {
        margin-top: 75px
    }
    .mg-xl-r-75 {
        margin-right: 75px
    }
    .mg-xl-b-75 {
        margin-bottom: 75px
    }
    .mg-xl-l-75 {
        margin-left: 75px
    }
    .mg-xl-t-75-f {
        margin-top: 75px!important
    }
    .mg-xl-r-75-f {
        margin-right: 75px!important
    }
    .mg-xl-b-75-f {
        margin-bottom: 75px!important
    }
    .mg-xl-l-75-f {
        margin-left: 75px!important
    }
    .mg-xl-80 {
        margin: 80px
    }
    .mg-xl-80-f {
        margin: 80px!important
    }
    .mg-xl-y-80 {
        margin-top: 80px;
        margin-bottom: 80px
    }
    .mg-xl-y-80-f {
        margin-top: 80px!important;
        margin-bottom: 80px!important
    }
    .mg-xl-x-80 {
        margin-left: 80px;
        margin-right: 80px
    }
    .mg-xl-x-80-f {
        margin-left: 80px!important;
        margin-right: 80px!important
    }
    .mg-xl-t-80 {
        margin-top: 80px
    }
    .mg-xl-r-80 {
        margin-right: 80px
    }
    .mg-xl-b-80 {
        margin-bottom: 80px
    }
    .mg-xl-l-80 {
        margin-left: 80px
    }
    .mg-xl-t-80-f {
        margin-top: 80px!important
    }
    .mg-xl-r-80-f {
        margin-right: 80px!important
    }
    .mg-xl-b-80-f {
        margin-bottom: 80px!important
    }
    .mg-xl-l-80-f {
        margin-left: 80px!important
    }
    .mg-xl-85 {
        margin: 85px
    }
    .mg-xl-85-f {
        margin: 85px!important
    }
    .mg-xl-y-85 {
        margin-top: 85px;
        margin-bottom: 85px
    }
    .mg-xl-y-85-f {
        margin-top: 85px!important;
        margin-bottom: 85px!important
    }
    .mg-xl-x-85 {
        margin-left: 85px;
        margin-right: 85px
    }
    .mg-xl-x-85-f {
        margin-left: 85px!important;
        margin-right: 85px!important
    }
    .mg-xl-t-85 {
        margin-top: 85px
    }
    .mg-xl-r-85 {
        margin-right: 85px
    }
    .mg-xl-b-85 {
        margin-bottom: 85px
    }
    .mg-xl-l-85 {
        margin-left: 85px
    }
    .mg-xl-t-85-f {
        margin-top: 85px!important
    }
    .mg-xl-r-85-f {
        margin-right: 85px!important
    }
    .mg-xl-b-85-f {
        margin-bottom: 85px!important
    }
    .mg-xl-l-85-f {
        margin-left: 85px!important
    }
    .mg-xl-90 {
        margin: 90px
    }
    .mg-xl-90-f {
        margin: 90px!important
    }
    .mg-xl-y-90 {
        margin-top: 90px;
        margin-bottom: 90px
    }
    .mg-xl-y-90-f {
        margin-top: 90px!important;
        margin-bottom: 90px!important
    }
    .mg-xl-x-90 {
        margin-left: 90px;
        margin-right: 90px
    }
    .mg-xl-x-90-f {
        margin-left: 90px!important;
        margin-right: 90px!important
    }
    .mg-xl-t-90 {
        margin-top: 90px
    }
    .mg-xl-r-90 {
        margin-right: 90px
    }
    .mg-xl-b-90 {
        margin-bottom: 90px
    }
    .mg-xl-l-90 {
        margin-left: 90px
    }
    .mg-xl-t-90-f {
        margin-top: 90px!important
    }
    .mg-xl-r-90-f {
        margin-right: 90px!important
    }
    .mg-xl-b-90-f {
        margin-bottom: 90px!important
    }
    .mg-xl-l-90-f {
        margin-left: 90px!important
    }
    .mg-xl-95 {
        margin: 95px
    }
    .mg-xl-95-f {
        margin: 95px!important
    }
    .mg-xl-y-95 {
        margin-top: 95px;
        margin-bottom: 95px
    }
    .mg-xl-y-95-f {
        margin-top: 95px!important;
        margin-bottom: 95px!important
    }
    .mg-xl-x-95 {
        margin-left: 95px;
        margin-right: 95px
    }
    .mg-xl-x-95-f {
        margin-left: 95px!important;
        margin-right: 95px!important
    }
    .mg-xl-t-95 {
        margin-top: 95px
    }
    .mg-xl-r-95 {
        margin-right: 95px
    }
    .mg-xl-b-95 {
        margin-bottom: 95px
    }
    .mg-xl-l-95 {
        margin-left: 95px
    }
    .mg-xl-t-95-f {
        margin-top: 95px!important
    }
    .mg-xl-r-95-f {
        margin-right: 95px!important
    }
    .mg-xl-b-95-f {
        margin-bottom: 95px!important
    }
    .mg-xl-l-95-f {
        margin-left: 95px!important
    }
    .mg-xl-100 {
        margin: 100px
    }
    .mg-xl-100-f {
        margin: 100px!important
    }
    .mg-xl-y-100 {
        margin-top: 100px;
        margin-bottom: 100px
    }
    .mg-xl-y-100-f {
        margin-top: 100px!important;
        margin-bottom: 100px!important
    }
    .mg-xl-x-100 {
        margin-left: 100px;
        margin-right: 100px
    }
    .mg-xl-x-100-f {
        margin-left: 100px!important;
        margin-right: 100px!important
    }
    .mg-xl-t-100 {
        margin-top: 100px
    }
    .mg-xl-r-100 {
        margin-right: 100px
    }
    .mg-xl-b-100 {
        margin-bottom: 100px
    }
    .mg-xl-l-100 {
        margin-left: 100px
    }
    .mg-xl-t-100-f {
        margin-top: 100px!important
    }
    .mg-xl-r-100-f {
        margin-right: 100px!important
    }
    .mg-xl-b-100-f {
        margin-bottom: 100px!important
    }
    .mg-xl-l-100-f {
        margin-left: 100px!important
    }
    .mg-xl-t-auto {
        margin-top: auto
    }
    .mg-xl-r-auto {
        margin-right: auto
    }
    .mg-xl-b-auto {
        margin-bottom: auto
    }
    .mg-xl-l-auto {
        margin-left: auto
    }
    .mg-xl-auto {
        margin: auto
    }
    .mg-xl-x-auto {
        margin-left: auto;
        margin-right: auto
    }
}

.op-0 {
    opacity: 0
}

.op-0-f {
    opacity: 0!important
}

.op-1 {
    opacity: .1
}

.op-1-f {
    opacity: .1!important
}

.op-2 {
    opacity: .2
}

.op-2-f {
    opacity: .2!important
}

.op-3 {
    opacity: .3
}

.op-3-f {
    opacity: .3!important
}

.op-4 {
    opacity: .4
}

.op-4-f {
    opacity: .4!important
}

.op-5 {
    opacity: .5
}

.op-5-f {
    opacity: .5!important
}

.op-6 {
    opacity: .6
}

.op-6-f {
    opacity: .6!important
}

.op-7 {
    opacity: .7
}

.op-7-f {
    opacity: .7!important
}

.op-8 {
    opacity: .8
}

.op-8-f {
    opacity: .8!important
}

.op-9 {
    opacity: .9
}

.op-9-f {
    opacity: .9!important
}

.opacity-1 {
    opacity: 1
}

.shadow-base {
    box-shadow: 0 0 2px 2px rgba(28, 39, 60, .03)
}

.shadow-1 {
    box-shadow: 0 0 7px 7px rgba(28, 39, 60, .1)
}

.shadow-2 {
    box-shadow: 0 0 30px rgba(28, 39, 60, .08)
}

.shadow-none {
    box-shadow: none
}

.overflow-y-auto {
    overflow-y: auto
}

.overflow-hidden {
    overflow: hidden
}

.flex-1 {
    flex: 1
}

.flex-0 {
    flex: 0
}

.img-fit-cover {
    width: 100%;
    height: 100%;
    object-fit: cover
}

@media (min-width:480px) {
    .op-xs-0 {
        opacity: 0
    }
    .op-xs-0-f {
        opacity: 0!important
    }
    .op-xs-1 {
        opacity: .1
    }
    .op-xs-1-f {
        opacity: .1!important
    }
    .op-xs-2 {
        opacity: .2
    }
    .op-xs-2-f {
        opacity: .2!important
    }
    .op-xs-3 {
        opacity: .3
    }
    .op-xs-3-f {
        opacity: .3!important
    }
    .op-xs-4 {
        opacity: .4
    }
    .op-xs-4-f {
        opacity: .4!important
    }
    .op-xs-5 {
        opacity: .5
    }
    .op-xs-5-f {
        opacity: .5!important
    }
    .op-xs-6 {
        opacity: .6
    }
    .op-xs-6-f {
        opacity: .6!important
    }
    .op-xs-7 {
        opacity: .7
    }
    .op-xs-7-f {
        opacity: .7!important
    }
    .op-xs-8 {
        opacity: .8
    }
    .op-xs-8-f {
        opacity: .8!important
    }
    .op-xs-9 {
        opacity: .9
    }
    .op-xs-9-f {
        opacity: .9!important
    }
}

@media (min-width:576px) {
    .op-sm-0 {
        opacity: 0
    }
    .op-sm-0-f {
        opacity: 0!important
    }
    .op-sm-1 {
        opacity: .1
    }
    .op-sm-1-f {
        opacity: .1!important
    }
    .op-sm-2 {
        opacity: .2
    }
    .op-sm-2-f {
        opacity: .2!important
    }
    .op-sm-3 {
        opacity: .3
    }
    .op-sm-3-f {
        opacity: .3!important
    }
    .op-sm-4 {
        opacity: .4
    }
    .op-sm-4-f {
        opacity: .4!important
    }
    .op-sm-5 {
        opacity: .5
    }
    .op-sm-5-f {
        opacity: .5!important
    }
    .op-sm-6 {
        opacity: .6
    }
    .op-sm-6-f {
        opacity: .6!important
    }
    .op-sm-7 {
        opacity: .7
    }
    .op-sm-7-f {
        opacity: .7!important
    }
    .op-sm-8 {
        opacity: .8
    }
    .op-sm-8-f {
        opacity: .8!important
    }
    .op-sm-9 {
        opacity: .9
    }
    .op-sm-9-f {
        opacity: .9!important
    }
}

@media (min-width:768px) {
    .op-md-0 {
        opacity: 0
    }
    .op-md-0-f {
        opacity: 0!important
    }
    .op-md-1 {
        opacity: .1
    }
    .op-md-1-f {
        opacity: .1!important
    }
    .op-md-2 {
        opacity: .2
    }
    .op-md-2-f {
        opacity: .2!important
    }
    .op-md-3 {
        opacity: .3
    }
    .op-md-3-f {
        opacity: .3!important
    }
    .op-md-4 {
        opacity: .4
    }
    .op-md-4-f {
        opacity: .4!important
    }
    .op-md-5 {
        opacity: .5
    }
    .op-md-5-f {
        opacity: .5!important
    }
    .op-md-6 {
        opacity: .6
    }
    .op-md-6-f {
        opacity: .6!important
    }
    .op-md-7 {
        opacity: .7
    }
    .op-md-7-f {
        opacity: .7!important
    }
    .op-md-8 {
        opacity: .8
    }
    .op-md-8-f {
        opacity: .8!important
    }
    .op-md-9 {
        opacity: .9
    }
    .op-md-9-f {
        opacity: .9!important
    }
}

@media (min-width:992px) {
    .op-lg-0 {
        opacity: 0
    }
    .op-lg-0-f {
        opacity: 0!important
    }
    .op-lg-1 {
        opacity: .1
    }
    .op-lg-1-f {
        opacity: .1!important
    }
    .op-lg-2 {
        opacity: .2
    }
    .op-lg-2-f {
        opacity: .2!important
    }
    .op-lg-3 {
        opacity: .3
    }
    .op-lg-3-f {
        opacity: .3!important
    }
    .op-lg-4 {
        opacity: .4
    }
    .op-lg-4-f {
        opacity: .4!important
    }
    .op-lg-5 {
        opacity: .5
    }
    .op-lg-5-f {
        opacity: .5!important
    }
    .op-lg-6 {
        opacity: .6
    }
    .op-lg-6-f {
        opacity: .6!important
    }
    .op-lg-7 {
        opacity: .7
    }
    .op-lg-7-f {
        opacity: .7!important
    }
    .op-lg-8 {
        opacity: .8
    }
    .op-lg-8-f {
        opacity: .8!important
    }
    .op-lg-9 {
        opacity: .9
    }
    .op-lg-9-f {
        opacity: .9!important
    }
}

@media (min-width:1200px) {
    .op-xl-0 {
        opacity: 0
    }
    .op-xl-0-f {
        opacity: 0!important
    }
    .op-xl-1 {
        opacity: .1
    }
    .op-xl-1-f {
        opacity: .1!important
    }
    .op-xl-2 {
        opacity: .2
    }
    .op-xl-2-f {
        opacity: .2!important
    }
    .op-xl-3 {
        opacity: .3
    }
    .op-xl-3-f {
        opacity: .3!important
    }
    .op-xl-4 {
        opacity: .4
    }
    .op-xl-4-f {
        opacity: .4!important
    }
    .op-xl-5 {
        opacity: .5
    }
    .op-xl-5-f {
        opacity: .5!important
    }
    .op-xl-6 {
        opacity: .6
    }
    .op-xl-6-f {
        opacity: .6!important
    }
    .op-xl-7 {
        opacity: .7
    }
    .op-xl-7-f {
        opacity: .7!important
    }
    .op-xl-8 {
        opacity: .8
    }
    .op-xl-8-f {
        opacity: .8!important
    }
    .op-xl-9 {
        opacity: .9
    }
    .op-xl-9-f {
        opacity: .9!important
    }
}

.p-0 {
    padding: 0!important
}

.pt-0,
.py-0 {
    padding-top: 0!important
}

.pr-0,
.px-0 {
    padding-right: 0!important
}

.pb-0,
.py-0 {
    padding-bottom: 0!important
}

.pl-0,
.px-0 {
    padding-left: 0!important
}

.p-1 {
    padding: .25rem!important
}

.pt-1,
.py-1 {
    padding-top: .25rem!important
}

.pr-1,
.px-1 {
    padding-right: .25rem!important
}

.pb-1,
.py-1 {
    padding-bottom: .25rem!important
}

.pl-1,
.px-1 {
    padding-left: .25rem!important
}

.p-2 {
    padding: .5rem!important
}

.pt-2,
.py-2 {
    padding-top: .5rem!important
}

.pr-2,
.px-2 {
    padding-right: .5rem!important
}

.pb-2,
.py-2 {
    padding-bottom: .5rem!important
}

.pl-2,
.px-2 {
    padding-left: .5rem!important
}

.p-3 {
    padding: 1rem!important
}

.pt-3,
.py-3 {
    padding-top: 1rem!important
}

.pr-3,
.px-3 {
    padding-right: 1rem!important
}

.pb-3,
.py-3 {
    padding-bottom: 1rem!important
}

.pl-3,
.px-3 {
    padding-left: 1rem!important
}

.p-4 {
    padding: 1.5rem!important
}

.pt-4,
.py-4 {
    padding-top: 1.5rem!important
}

.pr-4,
.px-4 {
    padding-right: 1.5rem!important
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem!important
}

.pl-4,
.px-4 {
    padding-left: 1.5rem!important
}

.p-5 {
    padding: 3rem!important
}

.pt-5,
.py-5 {
    padding-top: 3rem!important
}

.pr-5,
.px-5 {
    padding-right: 3rem!important
}

.pb-5,
.py-5 {
    padding-bottom: 3rem!important
}

.pl-5,
.px-5 {
    padding-left: 3rem!important
}

@media (min-width:576px) {
    .p-sm-0 {
        padding: 0!important
    }
    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0!important
    }
    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0!important
    }
    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0!important
    }
    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0!important
    }
    .p-sm-1 {
        padding: .25rem!important
    }
    .pt-sm-1,
    .py-sm-1 {
        padding-top: .25rem!important
    }
    .pr-sm-1,
    .px-sm-1 {
        padding-right: .25rem!important
    }
    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: .25rem!important
    }
    .pl-sm-1,
    .px-sm-1 {
        padding-left: .25rem!important
    }
    .p-sm-2 {
        padding: .5rem!important
    }
    .pt-sm-2,
    .py-sm-2 {
        padding-top: .5rem!important
    }
    .pr-sm-2,
    .px-sm-2 {
        padding-right: .5rem!important
    }
    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: .5rem!important
    }
    .pl-sm-2,
    .px-sm-2 {
        padding-left: .5rem!important
    }
    .p-sm-3 {
        padding: 1rem!important
    }
    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem!important
    }
    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem!important
    }
    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem!important
    }
    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem!important
    }
    .p-sm-4 {
        padding: 1.5rem!important
    }
    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem!important
    }
    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem!important
    }
    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem!important
    }
    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem!important
    }
    .p-sm-5 {
        padding: 3rem!important
    }
    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem!important
    }
    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem!important
    }
    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem!important
    }
    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem!important
    }
}

@media (min-width:768px) {
    .p-md-0 {
        padding: 0!important
    }
    .pt-md-0,
    .py-md-0 {
        padding-top: 0!important
    }
    .pr-md-0,
    .px-md-0 {
        padding-right: 0!important
    }
    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0!important
    }
    .pl-md-0,
    .px-md-0 {
        padding-left: 0!important
    }
    .p-md-1 {
        padding: .25rem!important
    }
    .pt-md-1,
    .py-md-1 {
        padding-top: .25rem!important
    }
    .pr-md-1,
    .px-md-1 {
        padding-right: .25rem!important
    }
    .pb-md-1,
    .py-md-1 {
        padding-bottom: .25rem!important
    }
    .pl-md-1,
    .px-md-1 {
        padding-left: .25rem!important
    }
    .p-md-2 {
        padding: .5rem!important
    }
    .pt-md-2,
    .py-md-2 {
        padding-top: .5rem!important
    }
    .pr-md-2,
    .px-md-2 {
        padding-right: .5rem!important
    }
    .pb-md-2,
    .py-md-2 {
        padding-bottom: .5rem!important
    }
    .pl-md-2,
    .px-md-2 {
        padding-left: .5rem!important
    }
    .p-md-3 {
        padding: 1rem!important
    }
    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem!important
    }
    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem!important
    }
    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem!important
    }
    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem!important
    }
    .p-md-4 {
        padding: 1.5rem!important
    }
    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem!important
    }
    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem!important
    }
    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem!important
    }
    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem!important
    }
    .p-md-5 {
        padding: 3rem!important
    }
    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem!important
    }
    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem!important
    }
    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem!important
    }
    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem!important
    }
}

@media (min-width:992px) {
    .p-lg-0 {
        padding: 0!important
    }
    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0!important
    }
    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0!important
    }
    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0!important
    }
    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0!important
    }
    .p-lg-1 {
        padding: .25rem!important
    }
    .pt-lg-1,
    .py-lg-1 {
        padding-top: .25rem!important
    }
    .pr-lg-1,
    .px-lg-1 {
        padding-right: .25rem!important
    }
    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: .25rem!important
    }
    .pl-lg-1,
    .px-lg-1 {
        padding-left: .25rem!important
    }
    .p-lg-2 {
        padding: .5rem!important
    }
    .pt-lg-2,
    .py-lg-2 {
        padding-top: .5rem!important
    }
    .pr-lg-2,
    .px-lg-2 {
        padding-right: .5rem!important
    }
    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: .5rem!important
    }
    .pl-lg-2,
    .px-lg-2 {
        padding-left: .5rem!important
    }
    .p-lg-3 {
        padding: 1rem!important
    }
    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem!important
    }
    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem!important
    }
    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem!important
    }
    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem!important
    }
    .p-lg-4 {
        padding: 1.5rem!important
    }
    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem!important
    }
    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem!important
    }
    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem!important
    }
    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem!important
    }
    .p-lg-5 {
        padding: 3rem!important
    }
    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem!important
    }
    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem!important
    }
    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem!important
    }
    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem!important
    }
}

@media (min-width:1200px) {
    .p-xl-0 {
        padding: 0!important
    }
    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0!important
    }
    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0!important
    }
    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0!important
    }
    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0!important
    }
    .p-xl-1 {
        padding: .25rem!important
    }
    .pt-xl-1,
    .py-xl-1 {
        padding-top: .25rem!important
    }
    .pr-xl-1,
    .px-xl-1 {
        padding-right: .25rem!important
    }
    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: .25rem!important
    }
    .pl-xl-1,
    .px-xl-1 {
        padding-left: .25rem!important
    }
    .p-xl-2 {
        padding: .5rem!important
    }
    .pt-xl-2,
    .py-xl-2 {
        padding-top: .5rem!important
    }
    .pr-xl-2,
    .px-xl-2 {
        padding-right: .5rem!important
    }
    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: .5rem!important
    }
    .pl-xl-2,
    .px-xl-2 {
        padding-left: .5rem!important
    }
    .p-xl-3 {
        padding: 1rem!important
    }
    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem!important
    }
    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem!important
    }
    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem!important
    }
    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem!important
    }
    .p-xl-4 {
        padding: 1.5rem!important
    }
    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem!important
    }
    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem!important
    }
    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem!important
    }
    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem!important
    }
    .p-xl-5 {
        padding: 3rem!important
    }
    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem!important
    }
    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem!important
    }
    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem!important
    }
    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem!important
    }
}

.pd-0 {
    padding: 0
}

.pd-0-f {
    padding: 0!important
}

.pd-y-0 {
    padding-top: 0;
    padding-bottom: 0
}

.pd-y-0-f {
    padding-top: 0!important;
    padding-bottom: 0!important
}

.pd-x-0 {
    padding-left: 0;
    padding-right: 0
}

.pd-x-0-f {
    padding-left: 0!important;
    padding-right: 0!important
}

.pd-t-0 {
    padding-top: 0
}

.pd-r-0 {
    padding-right: 0
}

.pd-b-0 {
    padding-bottom: 0
}

.pd-l-0 {
    padding-left: 0
}

.pd-t-0-f {
    padding-top: 0!important
}

.pd-r-0-f {
    padding-right: 0!important
}

.pd-b-0-f {
    padding-bottom: 0!important
}

.pd-l-0-f {
    padding-left: 0!important
}

.pd-1 {
    padding: 1px
}

.pd-1-f {
    padding: 1px!important
}

.pd-y-1 {
    padding-top: 1px;
    padding-bottom: 1px
}

.pd-y-1-f {
    padding-top: 1px!important;
    padding-bottom: 1px!important
}

.pd-x-1 {
    padding-left: 1px;
    padding-right: 1px
}

.pd-x-1-f {
    padding-left: 1px!important;
    padding-right: 1px!important
}

.pd-t-1 {
    padding-top: 1px
}

.pd-r-1 {
    padding-right: 1px
}

.pd-b-1 {
    padding-bottom: 1px
}

.pd-l-1 {
    padding-left: 1px
}

.pd-t-1-f {
    padding-top: 1px!important
}

.pd-r-1-f {
    padding-right: 1px!important
}

.pd-b-1-f {
    padding-bottom: 1px!important
}

.pd-l-1-f {
    padding-left: 1px!important
}

.pd-2 {
    padding: 2px
}

.pd-2-f {
    padding: 2px!important
}

.pd-y-2 {
    padding-top: 2px;
    padding-bottom: 2px
}

.pd-y-2-f {
    padding-top: 2px!important;
    padding-bottom: 2px!important
}

.pd-x-2 {
    padding-left: 2px;
    padding-right: 2px
}

.pd-x-2-f {
    padding-left: 2px!important;
    padding-right: 2px!important
}

.pd-t-2 {
    padding-top: 2px
}

.pd-r-2 {
    padding-right: 2px
}

.pd-b-2 {
    padding-bottom: 2px
}

.pd-l-2 {
    padding-left: 2px
}

.pd-t-2-f {
    padding-top: 2px!important
}

.pd-r-2-f {
    padding-right: 2px!important
}

.pd-b-2-f {
    padding-bottom: 2px!important
}

.pd-l-2-f {
    padding-left: 2px!important
}

.pd-3 {
    padding: 3px
}

.pd-3-f {
    padding: 3px!important
}

.pd-y-3 {
    padding-top: 3px;
    padding-bottom: 3px
}

.pd-y-3-f {
    padding-top: 3px!important;
    padding-bottom: 3px!important
}

.pd-x-3 {
    padding-left: 3px;
    padding-right: 3px
}

.pd-x-3-f {
    padding-left: 3px!important;
    padding-right: 3px!important
}

.pd-t-3 {
    padding-top: 3px
}

.pd-r-3 {
    padding-right: 3px
}

.pd-b-3 {
    padding-bottom: 3px
}

.pd-l-3 {
    padding-left: 3px
}

.pd-t-3-f {
    padding-top: 3px!important
}

.pd-r-3-f {
    padding-right: 3px!important
}

.pd-b-3-f {
    padding-bottom: 3px!important
}

.pd-l-3-f {
    padding-left: 3px!important
}

.pd-4 {
    padding: 4px
}

.pd-4-f {
    padding: 4px!important
}

.pd-y-4 {
    padding-top: 4px;
    padding-bottom: 4px
}

.pd-y-4-f {
    padding-top: 4px!important;
    padding-bottom: 4px!important
}

.pd-x-4 {
    padding-left: 4px;
    padding-right: 4px
}

.pd-x-4-f {
    padding-left: 4px!important;
    padding-right: 4px!important
}

.pd-t-4 {
    padding-top: 4px
}

.pd-r-4 {
    padding-right: 4px
}

.pd-b-4 {
    padding-bottom: 4px
}

.pd-l-4 {
    padding-left: 4px
}

.pd-t-4-f {
    padding-top: 4px!important
}

.pd-r-4-f {
    padding-right: 4px!important
}

.pd-b-4-f {
    padding-bottom: 4px!important
}

.pd-l-4-f {
    padding-left: 4px!important
}

.pd-5 {
    padding: 5px
}

.pd-5-f {
    padding: 5px!important
}

.pd-y-5 {
    padding-top: 5px;
    padding-bottom: 5px
}

.pd-y-5-f {
    padding-top: 5px!important;
    padding-bottom: 5px!important
}

.pd-x-5 {
    padding-left: 5px;
    padding-right: 5px
}

.pd-x-5-f {
    padding-left: 5px!important;
    padding-right: 5px!important
}

.pd-t-5 {
    padding-top: 5px
}

.pd-r-5 {
    padding-right: 5px
}

.pd-b-5 {
    padding-bottom: 5px
}

.pd-l-5 {
    padding-left: 5px
}

.pd-t-5-f {
    padding-top: 5px!important
}

.pd-r-5-f {
    padding-right: 5px!important
}

.pd-b-5-f {
    padding-bottom: 5px!important
}

.pd-l-5-f {
    padding-left: 5px!important
}

.pd-6 {
    padding: 6px
}

.pd-6-f {
    padding: 6px!important
}

.pd-y-6 {
    padding-top: 6px;
    padding-bottom: 6px
}

.pd-y-6-f {
    padding-top: 6px!important;
    padding-bottom: 6px!important
}

.pd-x-6 {
    padding-left: 6px;
    padding-right: 6px
}

.pd-x-6-f {
    padding-left: 6px!important;
    padding-right: 6px!important
}

.pd-t-6 {
    padding-top: 6px!important
}

.pd-r-6 {
    padding-right: 6px
}

.pd-b-6 {
    padding-bottom: 6px
}

.pd-l-6 {
    padding-left: 6px
}

.pd-t-6-f {
    padding-top: 6px!important
}

.pd-r-6-f {
    padding-right: 6px!important
}

.pd-b-6-f {
    padding-bottom: 6px!important
}

.pd-l-6-f {
    padding-left: 6px!important
}

.pd-7 {
    padding: 7px
}

.pd-7-f {
    padding: 7px!important
}

.pd-y-7 {
    padding-top: 7px;
    padding-bottom: 7px
}

.pd-y-7-f {
    padding-top: 7px!important;
    padding-bottom: 7px!important
}

.pd-x-7 {
    padding-left: 7px;
    padding-right: 7px
}

.pd-x-7-f {
    padding-left: 7px!important;
    padding-right: 7px!important
}

.pd-t-7 {
    padding-top: 7px
}

.pd-r-7 {
    padding-right: 7px
}

.pd-b-7 {
    padding-bottom: 7px
}

.pd-l-7 {
    padding-left: 7px
}

.pd-t-7-f {
    padding-top: 7px!important
}

.pd-r-7-f {
    padding-right: 7px!important
}

.pd-b-7-f {
    padding-bottom: 7px!important
}

.pd-l-7-f {
    padding-left: 7px!important
}

.pd-8 {
    padding: 8px
}

.pd-8-f {
    padding: 8px!important
}

.pd-y-8 {
    padding-top: 8px;
    padding-bottom: 8px
}

.pd-y-8-f {
    padding-top: 8px!important;
    padding-bottom: 8px!important
}

.pd-x-8 {
    padding-left: 8px;
    padding-right: 8px
}

.pd-x-8-f {
    padding-left: 8px!important;
    padding-right: 8px!important
}

.pd-t-8 {
    padding-top: 8px
}

.pd-r-8 {
    padding-right: 8px
}

.pd-b-8 {
    padding-bottom: 8px
}

.pd-l-8 {
    padding-left: 8px
}

.pd-t-8-f {
    padding-top: 8px!important
}

.pd-r-8-f {
    padding-right: 8px!important
}

.pd-b-8-f {
    padding-bottom: 8px!important
}

.pd-l-8-f {
    padding-left: 8px!important
}

.pd-9 {
    padding: 9px
}

.pd-9-f {
    padding: 9px!important
}

.pd-y-9 {
    padding-top: 9px;
    padding-bottom: 9px
}

.pd-y-9-f {
    padding-top: 9px!important;
    padding-bottom: 9px!important
}

.pd-x-9 {
    padding-left: 9px;
    padding-right: 9px
}

.pd-x-9-f {
    padding-left: 9px!important;
    padding-right: 9px!important
}

.pd-t-9 {
    padding-top: 9px
}

.pd-r-9 {
    padding-right: 9px
}

.pd-b-9 {
    padding-bottom: 9px
}

.pd-l-9 {
    padding-left: 9px
}

.pd-t-9-f {
    padding-top: 9px!important
}

.pd-r-9-f {
    padding-right: 9px!important
}

.pd-b-9-f {
    padding-bottom: 9px!important
}

.pd-l-9-f {
    padding-left: 9px!important
}

.pd-10 {
    padding: 10px
}

.pd-10-f {
    padding: 10px!important
}

.pd-y-10 {
    padding-top: 10px;
    padding-bottom: 10px
}

.pd-y-10-f {
    padding-top: 10px!important;
    padding-bottom: 10px!important
}

.pd-x-10 {
    padding-left: 10px;
    padding-right: 10px
}

.pd-x-10-f {
    padding-left: 10px!important;
    padding-right: 10px!important
}

.pd-t-10 {
    padding-top: 10px
}

.pd-r-10 {
    padding-right: 10px
}

.pd-b-10 {
    padding-bottom: 10px
}

.pd-l-10 {
    padding-left: 10px
}

.pd-t-10-f {
    padding-top: 10px!important
}

.pd-r-10-f {
    padding-right: 10px!important
}

.pd-b-10-f {
    padding-bottom: 10px!important
}

.pd-l-10-f {
    padding-left: 10px!important
}

.pd-15 {
    padding: 15px
}

.pd-15-f {
    padding: 15px!important
}

.pd-y-15 {
    padding-top: 15px;
    padding-bottom: 15px
}

.pd-y-15-f {
    padding-top: 15px!important;
    padding-bottom: 15px!important
}

.pd-x-15 {
    padding-left: 15px;
    padding-right: 15px
}

.pd-x-15-f {
    padding-left: 15px!important;
    padding-right: 15px!important
}

.pd-t-15 {
    padding-top: 15px
}

.pd-r-15 {
    padding-right: 15px
}

.pd-b-15 {
    padding-bottom: 15px
}

.pd-l-15 {
    padding-left: 15px
}

.pd-t-15-f {
    padding-top: 15px!important
}

.pd-r-15-f {
    padding-right: 15px!important
}

.pd-b-15-f {
    padding-bottom: 15px!important
}

.pd-l-15-f {
    padding-left: 15px!important
}

.pd-20 {
    padding: 20px
}

.pd-20-f {
    padding: 20px!important
}

.pd-y-20 {
    padding-top: 20px;
    padding-bottom: 20px
}

.pd-y-20-f {
    padding-top: 20px!important;
    padding-bottom: 20px!important
}

.pd-x-20 {
    padding-left: 20px;
    padding-right: 20px
}

.pd-x-20-f {
    padding-left: 20px!important;
    padding-right: 20px!important
}

.pd-t-20 {
    padding-top: 20px
}

.pd-r-20 {
    padding-right: 20px
}

.pd-b-20 {
    padding-bottom: 20px
}

.pd-l-20 {
    padding-left: 20px
}

.pd-t-20-f {
    padding-top: 20px!important
}

.pd-r-20-f {
    padding-right: 20px!important
}

.pd-b-20-f {
    padding-bottom: 20px!important
}

.pd-l-20-f {
    padding-left: 20px!important
}

.pd-25 {
    padding: 25px
}

.pd-25-f {
    padding: 25px!important
}

.pd-y-25 {
    padding-top: 25px;
    padding-bottom: 25px
}

.pd-y-25-f {
    padding-top: 25px!important;
    padding-bottom: 25px!important
}

.pd-x-25 {
    padding-left: 25px;
    padding-right: 25px
}

.pd-x-25-f {
    padding-left: 25px!important;
    padding-right: 25px!important
}

.pd-t-25 {
    padding-top: 25px
}

.pd-r-25 {
    padding-right: 25px
}

.pd-b-25 {
    padding-bottom: 25px
}

.pd-l-25 {
    padding-left: 25px
}

.pd-t-25-f {
    padding-top: 25px!important
}

.pd-r-25-f {
    padding-right: 25px!important
}

.pd-b-25-f {
    padding-bottom: 25px!important
}

.pd-l-25-f {
    padding-left: 25px!important
}

.pd-30 {
    padding: 30px
}

.pd-30-f {
    padding: 30px!important
}

.pd-y-30 {
    padding-top: 30px;
    padding-bottom: 30px
}

.pd-y-30-f {
    padding-top: 30px!important;
    padding-bottom: 30px!important
}

.pd-x-30 {
    padding-left: 30px;
    padding-right: 30px
}

.pd-x-30-f {
    padding-left: 30px!important;
    padding-right: 30px!important
}

.pd-t-30 {
    padding-top: 30px
}

.pd-r-30 {
    padding-right: 30px
}

.pd-b-30 {
    padding-bottom: 30px
}

.pd-l-30 {
    padding-left: 30px
}

.pd-t-30-f {
    padding-top: 30px!important
}

.pd-r-30-f {
    padding-right: 30px!important
}

.pd-b-30-f {
    padding-bottom: 30px!important
}

.pd-l-30-f {
    padding-left: 30px!important
}

.pd-35 {
    padding: 35px
}

.pd-35-f {
    padding: 35px!important
}

.pd-y-35 {
    padding-top: 35px;
    padding-bottom: 35px
}

.pd-y-35-f {
    padding-top: 35px!important;
    padding-bottom: 35px!important
}

.pd-x-35 {
    padding-left: 35px;
    padding-right: 35px
}

.pd-x-35-f {
    padding-left: 35px!important;
    padding-right: 35px!important
}

.pd-t-35 {
    padding-top: 35px
}

.pd-r-35 {
    padding-right: 35px
}

.pd-b-35 {
    padding-bottom: 35px
}

.pd-l-35 {
    padding-left: 35px
}

.pd-t-35-f {
    padding-top: 35px!important
}

.pd-r-35-f {
    padding-right: 35px!important
}

.pd-b-35-f {
    padding-bottom: 35px!important
}

.pd-l-35-f {
    padding-left: 35px!important
}

.pd-40 {
    padding: 40px
}

.pd-40-f {
    padding: 40px!important
}

.pd-y-40 {
    padding-top: 40px;
    padding-bottom: 40px
}

.pd-y-40-f {
    padding-top: 40px!important;
    padding-bottom: 40px!important
}

.pd-x-40 {
    padding-left: 40px;
    padding-right: 40px
}

.pd-x-40-f {
    padding-left: 40px!important;
    padding-right: 40px!important
}

.pd-t-40 {
    padding-top: 40px
}

.pd-r-40 {
    padding-right: 40px
}

.pd-b-40 {
    padding-bottom: 40px
}

.pd-l-40 {
    padding-left: 40px
}

.pd-t-40-f {
    padding-top: 40px!important
}

.pd-r-40-f {
    padding-right: 40px!important
}

.pd-b-40-f {
    padding-bottom: 40px!important
}

.pd-l-40-f {
    padding-left: 40px!important
}

.pd-45 {
    padding: 45px
}

.pd-45-f {
    padding: 45px!important
}

.pd-y-45 {
    padding-top: 45px;
    padding-bottom: 45px
}

.pd-y-45-f {
    padding-top: 45px!important;
    padding-bottom: 45px!important
}

.pd-x-45 {
    padding-left: 45px;
    padding-right: 45px
}

.pd-x-45-f {
    padding-left: 45px!important;
    padding-right: 45px!important
}

.pd-t-45 {
    padding-top: 45px
}

.pd-r-45 {
    padding-right: 45px
}

.pd-b-45 {
    padding-bottom: 45px
}

.pd-l-45 {
    padding-left: 45px
}

.pd-t-45-f {
    padding-top: 45px!important
}

.pd-r-45-f {
    padding-right: 45px!important
}

.pd-b-45-f {
    padding-bottom: 45px!important
}

.pd-l-45-f {
    padding-left: 45px!important
}

.pd-50 {
    padding: 50px
}

.pd-50-f {
    padding: 50px!important
}

.pd-y-50 {
    padding-top: 50px;
    padding-bottom: 50px
}

.pd-y-50-f {
    padding-top: 50px!important;
    padding-bottom: 50px!important
}

.pd-x-50 {
    padding-left: 50px;
    padding-right: 50px
}

.pd-x-50-f {
    padding-left: 50px!important;
    padding-right: 50px!important
}

.pd-t-50 {
    padding-top: 50px
}

.pd-r-50 {
    padding-right: 50px
}

.pd-b-50 {
    padding-bottom: 50px
}

.pd-l-50 {
    padding-left: 50px
}

.pd-t-50-f {
    padding-top: 50px!important
}

.pd-r-50-f {
    padding-right: 50px!important
}

.pd-b-50-f {
    padding-bottom: 50px!important
}

.pd-l-50-f {
    padding-left: 50px!important
}

.pd-55 {
    padding: 55px
}

.pd-55-f {
    padding: 55px!important
}

.pd-y-55 {
    padding-top: 55px;
    padding-bottom: 55px
}

.pd-y-55-f {
    padding-top: 55px!important;
    padding-bottom: 55px!important
}

.pd-x-55 {
    padding-left: 55px;
    padding-right: 55px
}

.pd-x-55-f {
    padding-left: 55px!important;
    padding-right: 55px!important
}

.pd-t-55 {
    padding-top: 55px
}

.pd-r-55 {
    padding-right: 55px
}

.pd-b-55 {
    padding-bottom: 55px
}

.pd-l-55 {
    padding-left: 55px
}

.pd-t-55-f {
    padding-top: 55px!important
}

.pd-r-55-f {
    padding-right: 55px!important
}

.pd-b-55-f {
    padding-bottom: 55px!important
}

.pd-l-55-f {
    padding-left: 55px!important
}

.pd-60 {
    padding: 60px
}

.pd-60-f {
    padding: 60px!important
}

.pd-y-60 {
    padding-top: 60px;
    padding-bottom: 60px
}

.pd-y-60-f {
    padding-top: 60px!important;
    padding-bottom: 60px!important
}

.pd-x-60 {
    padding-left: 60px;
    padding-right: 60px
}

.pd-x-60-f {
    padding-left: 60px!important;
    padding-right: 60px!important
}

.pd-t-60 {
    padding-top: 60px
}

.pd-r-60 {
    padding-right: 60px
}

.pd-b-60 {
    padding-bottom: 60px
}

.pd-l-60 {
    padding-left: 60px
}

.pd-t-60-f {
    padding-top: 60px!important
}

.pd-r-60-f {
    padding-right: 60px!important
}

.pd-b-60-f {
    padding-bottom: 60px!important
}

.pd-l-60-f {
    padding-left: 60px!important
}

.pd-65 {
    padding: 65px
}

.pd-65-f {
    padding: 65px!important
}

.pd-y-65 {
    padding-top: 65px;
    padding-bottom: 65px
}

.pd-y-65-f {
    padding-top: 65px!important;
    padding-bottom: 65px!important
}

.pd-x-65 {
    padding-left: 65px;
    padding-right: 65px
}

.pd-x-65-f {
    padding-left: 65px!important;
    padding-right: 65px!important
}

.pd-t-65 {
    padding-top: 65px
}

.pd-r-65 {
    padding-right: 65px
}

.pd-b-65 {
    padding-bottom: 65px
}

.pd-l-65 {
    padding-left: 65px
}

.pd-t-65-f {
    padding-top: 65px!important
}

.pd-r-65-f {
    padding-right: 65px!important
}

.pd-b-65-f {
    padding-bottom: 65px!important
}

.pd-l-65-f {
    padding-left: 65px!important
}

.pd-70 {
    padding: 70px
}

.pd-70-f {
    padding: 70px!important
}

.pd-y-70 {
    padding-top: 70px;
    padding-bottom: 70px
}

.pd-y-70-f {
    padding-top: 70px!important;
    padding-bottom: 70px!important
}

.pd-x-70 {
    padding-left: 70px;
    padding-right: 70px
}

.pd-x-70-f {
    padding-left: 70px!important;
    padding-right: 70px!important
}

.pd-t-70 {
    padding-top: 70px
}

.pd-r-70 {
    padding-right: 70px
}

.pd-b-70 {
    padding-bottom: 70px
}

.pd-l-70 {
    padding-left: 70px
}

.pd-t-70-f {
    padding-top: 70px!important
}

.pd-r-70-f {
    padding-right: 70px!important
}

.pd-b-70-f {
    padding-bottom: 70px!important
}

.pd-l-70-f {
    padding-left: 70px!important
}

.pd-75 {
    padding: 75px
}

.pd-75-f {
    padding: 75px!important
}

.pd-y-75 {
    padding-top: 75px;
    padding-bottom: 75px
}

.pd-y-75-f {
    padding-top: 75px!important;
    padding-bottom: 75px!important
}

.pd-x-75 {
    padding-left: 75px;
    padding-right: 75px
}

.pd-x-75-f {
    padding-left: 75px!important;
    padding-right: 75px!important
}

.pd-t-75 {
    padding-top: 75px
}

.pd-r-75 {
    padding-right: 75px
}

.pd-b-75 {
    padding-bottom: 75px
}

.pd-l-75 {
    padding-left: 75px
}

.pd-t-75-f {
    padding-top: 75px!important
}

.pd-r-75-f {
    padding-right: 75px!important
}

.pd-b-75-f {
    padding-bottom: 75px!important
}

.pd-l-75-f {
    padding-left: 75px!important
}

.pd-80 {
    padding: 80px
}

.pd-80-f {
    padding: 80px!important
}

.pd-y-80 {
    padding-top: 80px;
    padding-bottom: 80px
}

.pd-y-80-f {
    padding-top: 80px!important;
    padding-bottom: 80px!important
}

.pd-x-80 {
    padding-left: 80px;
    padding-right: 80px
}

.pd-x-80-f {
    padding-left: 80px!important;
    padding-right: 80px!important
}

.pd-t-80 {
    padding-top: 80px
}

.pd-r-80 {
    padding-right: 80px
}

.pd-b-80 {
    padding-bottom: 80px
}

.pd-l-80 {
    padding-left: 80px
}

.pd-t-80-f {
    padding-top: 80px!important
}

.pd-r-80-f {
    padding-right: 80px!important
}

.pd-b-80-f {
    padding-bottom: 80px!important
}

.pd-l-80-f {
    padding-left: 80px!important
}

.pd-85 {
    padding: 85px
}

.pd-85-f {
    padding: 85px!important
}

.pd-y-85 {
    padding-top: 85px;
    padding-bottom: 85px
}

.pd-y-85-f {
    padding-top: 85px!important;
    padding-bottom: 85px!important
}

.pd-x-85 {
    padding-left: 85px;
    padding-right: 85px
}

.pd-x-85-f {
    padding-left: 85px!important;
    padding-right: 85px!important
}

.pd-t-85 {
    padding-top: 85px
}

.pd-r-85 {
    padding-right: 85px
}

.pd-b-85 {
    padding-bottom: 85px
}

.pd-l-85 {
    padding-left: 85px
}

.pd-t-85-f {
    padding-top: 85px!important
}

.pd-r-85-f {
    padding-right: 85px!important
}

.pd-b-85-f {
    padding-bottom: 85px!important
}

.pd-l-85-f {
    padding-left: 85px!important
}

.pd-90 {
    padding: 90px
}

.pd-90-f {
    padding: 90px!important
}

.pd-y-90 {
    padding-top: 90px;
    padding-bottom: 90px
}

.pd-y-90-f {
    padding-top: 90px!important;
    padding-bottom: 90px!important
}

.pd-x-90 {
    padding-left: 90px;
    padding-right: 90px
}

.pd-x-90-f {
    padding-left: 90px!important;
    padding-right: 90px!important
}

.pd-t-90 {
    padding-top: 90px
}

.pd-r-90 {
    padding-right: 90px
}

.pd-b-90 {
    padding-bottom: 90px
}

.pd-l-90 {
    padding-left: 90px
}

.pd-t-90-f {
    padding-top: 90px!important
}

.pd-r-90-f {
    padding-right: 90px!important
}

.pd-b-90-f {
    padding-bottom: 90px!important
}

.pd-l-90-f {
    padding-left: 90px!important
}

.pd-95 {
    padding: 95px
}

.pd-95-f {
    padding: 95px!important
}

.pd-y-95 {
    padding-top: 95px;
    padding-bottom: 95px
}

.pd-y-95-f {
    padding-top: 95px!important;
    padding-bottom: 95px!important
}

.pd-x-95 {
    padding-left: 95px;
    padding-right: 95px
}

.pd-x-95-f {
    padding-left: 95px!important;
    padding-right: 95px!important
}

.pd-t-95 {
    padding-top: 95px
}

.pd-r-95 {
    padding-right: 95px
}

.pd-b-95 {
    padding-bottom: 95px
}

.pd-l-95 {
    padding-left: 95px
}

.pd-t-95-f {
    padding-top: 95px!important
}

.pd-r-95-f {
    padding-right: 95px!important
}

.pd-b-95-f {
    padding-bottom: 95px!important
}

.pd-l-95-f {
    padding-left: 95px!important
}

.pd-100 {
    padding: 100px
}

.pd-100-f {
    padding: 100px!important
}

.pd-y-100 {
    padding-top: 100px;
    padding-bottom: 100px
}

.pd-y-100-f {
    padding-top: 100px!important;
    padding-bottom: 100px!important
}

.pd-x-100 {
    padding-left: 100px;
    padding-right: 100px
}

.pd-x-100-f {
    padding-left: 100px!important;
    padding-right: 100px!important
}

.pd-t-100 {
    padding-top: 100px
}

.pd-r-100 {
    padding-right: 100px
}

.pd-b-100 {
    padding-bottom: 100px
}

.pd-l-100 {
    padding-left: 100px
}

.pd-t-100-f {
    padding-top: 100px!important
}

.pd-r-100-f {
    padding-right: 100px!important
}

.pd-b-100-f {
    padding-bottom: 100px!important
}

.pd-l-100-f {
    padding-left: 100px!important
}

.pd-110 {
    padding: 110px
}

.pd-110-f {
    padding: 110px!important
}

.pd-y-110 {
    padding-top: 110px;
    padding-bottom: 110px
}

.pd-y-110-f {
    padding-top: 110px!important;
    padding-bottom: 110px!important
}

.pd-x-110 {
    padding-left: 110px;
    padding-right: 110px
}

.pd-x-110-f {
    padding-left: 110px!important;
    padding-right: 110px!important
}

.pd-t-110 {
    padding-top: 110px
}

.pd-r-110 {
    padding-right: 110px
}

.pd-b-110 {
    padding-bottom: 110px
}

.pd-l-110 {
    padding-left: 110px
}

.pd-t-110-f {
    padding-top: 110px!important
}

.pd-r-110-f {
    padding-right: 110px!important
}

.pd-b-110-f {
    padding-bottom: 110px!important
}

.pd-l-110-f {
    padding-left: 110px!important
}

.pd-120 {
    padding: 120px
}

.pd-120-f {
    padding: 120px!important
}

.pd-y-120 {
    padding-top: 120px;
    padding-bottom: 120px
}

.pd-y-120-f {
    padding-top: 120px!important;
    padding-bottom: 120px!important
}

.pd-x-120 {
    padding-left: 120px;
    padding-right: 120px
}

.pd-x-120-f {
    padding-left: 120px!important;
    padding-right: 120px!important
}

.pd-t-120 {
    padding-top: 120px
}

.pd-r-120 {
    padding-right: 120px
}

.pd-b-120 {
    padding-bottom: 120px
}

.pd-l-120 {
    padding-left: 120px
}

.pd-t-120-f {
    padding-top: 120px!important
}

.pd-r-120-f {
    padding-right: 120px!important
}

.pd-b-120-f {
    padding-bottom: 120px!important
}

.pd-l-120-f {
    padding-left: 120px!important
}

.pd-130 {
    padding: 130px
}

.pd-130-f {
    padding: 130px!important
}

.pd-y-130 {
    padding-top: 130px;
    padding-bottom: 130px
}

.pd-y-130-f {
    padding-top: 130px!important;
    padding-bottom: 130px!important
}

.pd-x-130 {
    padding-left: 130px;
    padding-right: 130px
}

.pd-x-130-f {
    padding-left: 130px!important;
    padding-right: 130px!important
}

.pd-t-130 {
    padding-top: 130px
}

.pd-r-130 {
    padding-right: 130px
}

.pd-b-130 {
    padding-bottom: 130px
}

.pd-l-130 {
    padding-left: 130px
}

.pd-t-130-f {
    padding-top: 130px!important
}

.pd-r-130-f {
    padding-right: 130px!important
}

.pd-b-130-f {
    padding-bottom: 130px!important
}

.pd-l-130-f {
    padding-left: 130px!important
}

.pd-140 {
    padding: 140px
}

.pd-140-f {
    padding: 140px!important
}

.pd-y-140 {
    padding-top: 140px;
    padding-bottom: 140px
}

.pd-y-140-f {
    padding-top: 140px!important;
    padding-bottom: 140px!important
}

.pd-x-140 {
    padding-left: 140px;
    padding-right: 140px
}

.pd-x-140-f {
    padding-left: 140px!important;
    padding-right: 140px!important
}

.pd-t-140 {
    padding-top: 140px
}

.pd-r-140 {
    padding-right: 140px
}

.pd-b-140 {
    padding-bottom: 140px
}

.pd-l-140 {
    padding-left: 140px
}

.pd-t-140-f {
    padding-top: 140px!important
}

.pd-r-140-f {
    padding-right: 140px!important
}

.pd-b-140-f {
    padding-bottom: 140px!important
}

.pd-l-140-f {
    padding-left: 140px!important
}

.pd-150 {
    padding: 150px
}

.pd-150-f {
    padding: 150px!important
}

.pd-y-150 {
    padding-top: 150px;
    padding-bottom: 150px
}

.pd-y-150-f {
    padding-top: 150px!important;
    padding-bottom: 150px!important
}

.pd-x-150 {
    padding-left: 150px;
    padding-right: 150px
}

.pd-x-150-f {
    padding-left: 150px!important;
    padding-right: 150px!important
}

.pd-t-150 {
    padding-top: 150px
}

.pd-r-150 {
    padding-right: 150px
}

.pd-b-150 {
    padding-bottom: 150px
}

.pd-l-150 {
    padding-left: 150px
}

.pd-t-150-f {
    padding-top: 150px!important
}

.pd-r-150-f {
    padding-right: 150px!important
}

.pd-b-150-f {
    padding-bottom: 150px!important
}

.pd-l-150-f {
    padding-left: 150px!important
}

.pd-160 {
    padding: 160px
}

.pd-160-f {
    padding: 160px!important
}

.pd-y-160 {
    padding-top: 160px;
    padding-bottom: 160px
}

.pd-y-160-f {
    padding-top: 160px!important;
    padding-bottom: 160px!important
}

.pd-x-160 {
    padding-left: 160px;
    padding-right: 160px
}

.pd-x-160-f {
    padding-left: 160px!important;
    padding-right: 160px!important
}

.pd-t-160 {
    padding-top: 160px
}

.pd-r-160 {
    padding-right: 160px
}

.pd-b-160 {
    padding-bottom: 160px
}

.pd-l-160 {
    padding-left: 160px
}

.pd-t-160-f {
    padding-top: 160px!important
}

.pd-r-160-f {
    padding-right: 160px!important
}

.pd-b-160-f {
    padding-bottom: 160px!important
}

.pd-l-160-f {
    padding-left: 160px!important
}

.pd-170 {
    padding: 170px
}

.pd-170-f {
    padding: 170px!important
}

.pd-y-170 {
    padding-top: 170px;
    padding-bottom: 170px
}

.pd-y-170-f {
    padding-top: 170px!important;
    padding-bottom: 170px!important
}

.pd-x-170 {
    padding-left: 170px;
    padding-right: 170px
}

.pd-x-170-f {
    padding-left: 170px!important;
    padding-right: 170px!important
}

.pd-t-170 {
    padding-top: 170px
}

.pd-r-170 {
    padding-right: 170px
}

.pd-b-170 {
    padding-bottom: 170px
}

.pd-l-170 {
    padding-left: 170px
}

.pd-t-170-f {
    padding-top: 170px!important
}

.pd-r-170-f {
    padding-right: 170px!important
}

.pd-b-170-f {
    padding-bottom: 170px!important
}

.pd-l-170-f {
    padding-left: 170px!important
}

.pd-180 {
    padding: 180px
}

.pd-180-f {
    padding: 180px!important
}

.pd-y-180 {
    padding-top: 180px;
    padding-bottom: 180px
}

.pd-y-180-f {
    padding-top: 180px!important;
    padding-bottom: 180px!important
}

.pd-x-180 {
    padding-left: 180px;
    padding-right: 180px
}

.pd-x-180-f {
    padding-left: 180px!important;
    padding-right: 180px!important
}

.pd-t-180 {
    padding-top: 180px
}

.pd-r-180 {
    padding-right: 180px
}

.pd-b-180 {
    padding-bottom: 180px
}

.pd-l-180 {
    padding-left: 180px
}

.pd-t-180-f {
    padding-top: 180px!important
}

.pd-r-180-f {
    padding-right: 180px!important
}

.pd-b-180-f {
    padding-bottom: 180px!important
}

.pd-l-180-f {
    padding-left: 180px!important
}

.pd-190 {
    padding: 190px
}

.pd-190-f {
    padding: 190px!important
}

.pd-y-190 {
    padding-top: 190px;
    padding-bottom: 190px
}

.pd-y-190-f {
    padding-top: 190px!important;
    padding-bottom: 190px!important
}

.pd-x-190 {
    padding-left: 190px;
    padding-right: 190px
}

.pd-x-190-f {
    padding-left: 190px!important;
    padding-right: 190px!important
}

.pd-t-190 {
    padding-top: 190px
}

.pd-r-190 {
    padding-right: 190px
}

.pd-b-190 {
    padding-bottom: 190px
}

.pd-l-190 {
    padding-left: 190px
}

.pd-t-190-f {
    padding-top: 190px!important
}

.pd-r-190-f {
    padding-right: 190px!important
}

.pd-b-190-f {
    padding-bottom: 190px!important
}

.pd-l-190-f {
    padding-left: 190px!important
}

.pd-200 {
    padding: 200px
}

.pd-200-f {
    padding: 200px!important
}

.pd-y-200 {
    padding-top: 200px;
    padding-bottom: 200px
}

.pd-y-200-f {
    padding-top: 200px!important;
    padding-bottom: 200px!important
}

.pd-x-200 {
    padding-left: 200px;
    padding-right: 200px
}

.pd-x-200-f {
    padding-left: 200px!important;
    padding-right: 200px!important
}

.pd-t-200 {
    padding-top: 200px
}

.pd-r-200 {
    padding-right: 200px
}

.pd-b-200 {
    padding-bottom: 200px
}

.pd-l-200 {
    padding-left: 200px
}

.pd-t-200-f {
    padding-top: 200px!important
}

.pd-r-200-f {
    padding-right: 200px!important
}

.pd-b-200-f {
    padding-bottom: 200px!important
}

.pd-l-200-f {
    padding-left: 200px!important
}

@media (min-width:480px) {
    .pd-xs-0 {
        padding: 0
    }
    .pd-xs-0-f {
        padding: 0!important
    }
    .pd-xs-y-0 {
        padding-top: 0;
        padding-bottom: 0
    }
    .pd-xs-y-0-f {
        padding-top: 0!important;
        padding-bottom: 0!important
    }
    .pd-xs-x-0 {
        padding-left: 0;
        padding-right: 0
    }
    .pd-xs-x-0-f {
        padding-left: 0!important;
        padding-right: 0!important
    }
    .pd-xs-t-0 {
        padding-top: 0
    }
    .pd-xs-r-0 {
        padding-right: 0
    }
    .pd-xs-b-0 {
        padding-bottom: 0
    }
    .pd-xs-l-0 {
        padding-left: 0
    }
    .pd-xs-t-0-f {
        padding-top: 0!important
    }
    .pd-xs-r-0-f {
        padding-right: 0!important
    }
    .pd-xs-b-0-f {
        padding-bottom: 0!important
    }
    .pd-xs-l-0-f {
        padding-left: 0!important
    }
    .pd-xs-1 {
        padding: 1px
    }
    .pd-xs-1-f {
        padding: 1px!important
    }
    .pd-xs-y-1 {
        padding-top: 1px;
        padding-bottom: 1px
    }
    .pd-xs-y-1-f {
        padding-top: 1px!important;
        padding-bottom: 1px!important
    }
    .pd-xs-x-1 {
        padding-left: 1px;
        padding-right: 1px
    }
    .pd-xs-x-1-f {
        padding-left: 1px!important;
        padding-right: 1px!important
    }
    .pd-xs-t-1 {
        padding-top: 1px
    }
    .pd-xs-r-1 {
        padding-right: 1px
    }
    .pd-xs-b-1 {
        padding-bottom: 1px
    }
    .pd-xs-l-1 {
        padding-left: 1px
    }
    .pd-xs-t-1-f {
        padding-top: 1px!important
    }
    .pd-xs-r-1-f {
        padding-right: 1px!important
    }
    .pd-xs-b-1-f {
        padding-bottom: 1px!important
    }
    .pd-xs-l-1-f {
        padding-left: 1px!important
    }
    .pd-xs-2 {
        padding: 2px
    }
    .pd-xs-2-f {
        padding: 2px!important
    }
    .pd-xs-y-2 {
        padding-top: 2px;
        padding-bottom: 2px
    }
    .pd-xs-y-2-f {
        padding-top: 2px!important;
        padding-bottom: 2px!important
    }
    .pd-xs-x-2 {
        padding-left: 2px;
        padding-right: 2px
    }
    .pd-xs-x-2-f {
        padding-left: 2px!important;
        padding-right: 2px!important
    }
    .pd-xs-t-2 {
        padding-top: 2px
    }
    .pd-xs-r-2 {
        padding-right: 2px
    }
    .pd-xs-b-2 {
        padding-bottom: 2px
    }
    .pd-xs-l-2 {
        padding-left: 2px
    }
    .pd-xs-t-2-f {
        padding-top: 2px!important
    }
    .pd-xs-r-2-f {
        padding-right: 2px!important
    }
    .pd-xs-b-2-f {
        padding-bottom: 2px!important
    }
    .pd-xs-l-2-f {
        padding-left: 2px!important
    }
    .pd-xs-3 {
        padding: 3px
    }
    .pd-xs-3-f {
        padding: 3px!important
    }
    .pd-xs-y-3 {
        padding-top: 3px;
        padding-bottom: 3px
    }
    .pd-xs-y-3-f {
        padding-top: 3px!important;
        padding-bottom: 3px!important
    }
    .pd-xs-x-3 {
        padding-left: 3px;
        padding-right: 3px
    }
    .pd-xs-x-3-f {
        padding-left: 3px!important;
        padding-right: 3px!important
    }
    .pd-xs-t-3 {
        padding-top: 3px
    }
    .pd-xs-r-3 {
        padding-right: 3px
    }
    .pd-xs-b-3 {
        padding-bottom: 3px
    }
    .pd-xs-l-3 {
        padding-left: 3px
    }
    .pd-xs-t-3-f {
        padding-top: 3px!important
    }
    .pd-xs-r-3-f {
        padding-right: 3px!important
    }
    .pd-xs-b-3-f {
        padding-bottom: 3px!important
    }
    .pd-xs-l-3-f {
        padding-left: 3px!important
    }
    .pd-xs-4 {
        padding: 4px
    }
    .pd-xs-4-f {
        padding: 4px!important
    }
    .pd-xs-y-4 {
        padding-top: 4px;
        padding-bottom: 4px
    }
    .pd-xs-y-4-f {
        padding-top: 4px!important;
        padding-bottom: 4px!important
    }
    .pd-xs-x-4 {
        padding-left: 4px;
        padding-right: 4px
    }
    .pd-xs-x-4-f {
        padding-left: 4px!important;
        padding-right: 4px!important
    }
    .pd-xs-t-4 {
        padding-top: 4px
    }
    .pd-xs-r-4 {
        padding-right: 4px
    }
    .pd-xs-b-4 {
        padding-bottom: 4px
    }
    .pd-xs-l-4 {
        padding-left: 4px
    }
    .pd-xs-t-4-f {
        padding-top: 4px!important
    }
    .pd-xs-r-4-f {
        padding-right: 4px!important
    }
    .pd-xs-b-4-f {
        padding-bottom: 4px!important
    }
    .pd-xs-l-4-f {
        padding-left: 4px!important
    }
    .pd-xs-5 {
        padding: 5px
    }
    .pd-xs-5-f {
        padding: 5px!important
    }
    .pd-xs-y-5 {
        padding-top: 5px;
        padding-bottom: 5px
    }
    .pd-xs-y-5-f {
        padding-top: 5px!important;
        padding-bottom: 5px!important
    }
    .pd-xs-x-5 {
        padding-left: 5px;
        padding-right: 5px
    }
    .pd-xs-x-5-f {
        padding-left: 5px!important;
        padding-right: 5px!important
    }
    .pd-xs-t-5 {
        padding-top: 5px
    }
    .pd-xs-r-5 {
        padding-right: 5px
    }
    .pd-xs-b-5 {
        padding-bottom: 5px
    }
    .pd-xs-l-5 {
        padding-left: 5px
    }
    .pd-xs-t-5-f {
        padding-top: 5px!important
    }
    .pd-xs-r-5-f {
        padding-right: 5px!important
    }
    .pd-xs-b-5-f {
        padding-bottom: 5px!important
    }
    .pd-xs-l-5-f {
        padding-left: 5px!important
    }
    .pd-xs-6 {
        padding: 6px
    }
    .pd-xs-6-f {
        padding: 6px!important
    }
    .pd-xs-y-6 {
        padding-top: 6px;
        padding-bottom: 6px
    }
    .pd-xs-y-6-f {
        padding-top: 6px!important;
        padding-bottom: 6px!important
    }
    .pd-xs-x-6 {
        padding-left: 6px;
        padding-right: 6px
    }
    .pd-xs-x-6-f {
        padding-left: 6px!important;
        padding-right: 6px!important
    }
    .pd-xs-t-6 {
        padding-top: 6px
    }
    .pd-xs-r-6 {
        padding-right: 6px
    }
    .pd-xs-b-6 {
        padding-bottom: 6px
    }
    .pd-xs-l-6 {
        padding-left: 6px
    }
    .pd-xs-t-6-f {
        padding-top: 6px!important
    }
    .pd-xs-r-6-f {
        padding-right: 6px!important
    }
    .pd-xs-b-6-f {
        padding-bottom: 6px!important
    }
    .pd-xs-l-6-f {
        padding-left: 6px!important
    }
    .pd-xs-7 {
        padding: 7px
    }
    .pd-xs-7-f {
        padding: 7px!important
    }
    .pd-xs-y-7 {
        padding-top: 7px;
        padding-bottom: 7px
    }
    .pd-xs-y-7-f {
        padding-top: 7px!important;
        padding-bottom: 7px!important
    }
    .pd-xs-x-7 {
        padding-left: 7px;
        padding-right: 7px
    }
    .pd-xs-x-7-f {
        padding-left: 7px!important;
        padding-right: 7px!important
    }
    .pd-xs-t-7 {
        padding-top: 7px
    }
    .pd-xs-r-7 {
        padding-right: 7px
    }
    .pd-xs-b-7 {
        padding-bottom: 7px
    }
    .pd-xs-l-7 {
        padding-left: 7px
    }
    .pd-xs-t-7-f {
        padding-top: 7px!important
    }
    .pd-xs-r-7-f {
        padding-right: 7px!important
    }
    .pd-xs-b-7-f {
        padding-bottom: 7px!important
    }
    .pd-xs-l-7-f {
        padding-left: 7px!important
    }
    .pd-xs-8 {
        padding: 8px
    }
    .pd-xs-8-f {
        padding: 8px!important
    }
    .pd-xs-y-8 {
        padding-top: 8px;
        padding-bottom: 8px
    }
    .pd-xs-y-8-f {
        padding-top: 8px!important;
        padding-bottom: 8px!important
    }
    .pd-xs-x-8 {
        padding-left: 8px;
        padding-right: 8px
    }
    .pd-xs-x-8-f {
        padding-left: 8px!important;
        padding-right: 8px!important
    }
    .pd-xs-t-8 {
        padding-top: 8px
    }
    .pd-xs-r-8 {
        padding-right: 8px
    }
    .pd-xs-b-8 {
        padding-bottom: 8px
    }
    .pd-xs-l-8 {
        padding-left: 8px
    }
    .pd-xs-t-8-f {
        padding-top: 8px!important
    }
    .pd-xs-r-8-f {
        padding-right: 8px!important
    }
    .pd-xs-b-8-f {
        padding-bottom: 8px!important
    }
    .pd-xs-l-8-f {
        padding-left: 8px!important
    }
    .pd-xs-9 {
        padding: 9px
    }
    .pd-xs-9-f {
        padding: 9px!important
    }
    .pd-xs-y-9 {
        padding-top: 9px;
        padding-bottom: 9px
    }
    .pd-xs-y-9-f {
        padding-top: 9px!important;
        padding-bottom: 9px!important
    }
    .pd-xs-x-9 {
        padding-left: 9px;
        padding-right: 9px
    }
    .pd-xs-x-9-f {
        padding-left: 9px!important;
        padding-right: 9px!important
    }
    .pd-xs-t-9 {
        padding-top: 9px
    }
    .pd-xs-r-9 {
        padding-right: 9px
    }
    .pd-xs-b-9 {
        padding-bottom: 9px
    }
    .pd-xs-l-9 {
        padding-left: 9px
    }
    .pd-xs-t-9-f {
        padding-top: 9px!important
    }
    .pd-xs-r-9-f {
        padding-right: 9px!important
    }
    .pd-xs-b-9-f {
        padding-bottom: 9px!important
    }
    .pd-xs-l-9-f {
        padding-left: 9px!important
    }
    .pd-xs-10 {
        padding: 10px
    }
    .pd-xs-10-f {
        padding: 10px!important
    }
    .pd-xs-y-10 {
        padding-top: 10px;
        padding-bottom: 10px
    }
    .pd-xs-y-10-f {
        padding-top: 10px!important;
        padding-bottom: 10px!important
    }
    .pd-xs-x-10 {
        padding-left: 10px;
        padding-right: 10px
    }
    .pd-xs-x-10-f {
        padding-left: 10px!important;
        padding-right: 10px!important
    }
    .pd-xs-t-10 {
        padding-top: 10px
    }
    .pd-xs-r-10 {
        padding-right: 10px
    }
    .pd-xs-b-10 {
        padding-bottom: 10px
    }
    .pd-xs-l-10 {
        padding-left: 10px
    }
    .pd-xs-t-10-f {
        padding-top: 10px!important
    }
    .pd-xs-r-10-f {
        padding-right: 10px!important
    }
    .pd-xs-b-10-f {
        padding-bottom: 10px!important
    }
    .pd-xs-l-10-f {
        padding-left: 10px!important
    }
    .pd-xs-15 {
        padding: 15px
    }
    .pd-xs-15-f {
        padding: 15px!important
    }
    .pd-xs-y-15 {
        padding-top: 15px;
        padding-bottom: 15px
    }
    .pd-xs-y-15-f {
        padding-top: 15px!important;
        padding-bottom: 15px!important
    }
    .pd-xs-x-15 {
        padding-left: 15px;
        padding-right: 15px
    }
    .pd-xs-x-15-f {
        padding-left: 15px!important;
        padding-right: 15px!important
    }
    .pd-xs-t-15 {
        padding-top: 15px
    }
    .pd-xs-r-15 {
        padding-right: 15px
    }
    .pd-xs-b-15 {
        padding-bottom: 15px
    }
    .pd-xs-l-15 {
        padding-left: 15px
    }
    .pd-xs-t-15-f {
        padding-top: 15px!important
    }
    .pd-xs-r-15-f {
        padding-right: 15px!important
    }
    .pd-xs-b-15-f {
        padding-bottom: 15px!important
    }
    .pd-xs-l-15-f {
        padding-left: 15px!important
    }
    .pd-xs-20 {
        padding: 20px
    }
    .pd-xs-20-f {
        padding: 20px!important
    }
    .pd-xs-y-20 {
        padding-top: 20px;
        padding-bottom: 20px
    }
    .pd-xs-y-20-f {
        padding-top: 20px!important;
        padding-bottom: 20px!important
    }
    .pd-xs-x-20 {
        padding-left: 20px;
        padding-right: 20px
    }
    .pd-xs-x-20-f {
        padding-left: 20px!important;
        padding-right: 20px!important
    }
    .pd-xs-t-20 {
        padding-top: 20px
    }
    .pd-xs-r-20 {
        padding-right: 20px
    }
    .pd-xs-b-20 {
        padding-bottom: 20px
    }
    .pd-xs-l-20 {
        padding-left: 20px
    }
    .pd-xs-t-20-f {
        padding-top: 20px!important
    }
    .pd-xs-r-20-f {
        padding-right: 20px!important
    }
    .pd-xs-b-20-f {
        padding-bottom: 20px!important
    }
    .pd-xs-l-20-f {
        padding-left: 20px!important
    }
    .pd-xs-25 {
        padding: 25px
    }
    .pd-xs-25-f {
        padding: 25px!important
    }
    .pd-xs-y-25 {
        padding-top: 25px;
        padding-bottom: 25px
    }
    .pd-xs-y-25-f {
        padding-top: 25px!important;
        padding-bottom: 25px!important
    }
    .pd-xs-x-25 {
        padding-left: 25px;
        padding-right: 25px
    }
    .pd-xs-x-25-f {
        padding-left: 25px!important;
        padding-right: 25px!important
    }
    .pd-xs-t-25 {
        padding-top: 25px
    }
    .pd-xs-r-25 {
        padding-right: 25px
    }
    .pd-xs-b-25 {
        padding-bottom: 25px
    }
    .pd-xs-l-25 {
        padding-left: 25px
    }
    .pd-xs-t-25-f {
        padding-top: 25px!important
    }
    .pd-xs-r-25-f {
        padding-right: 25px!important
    }
    .pd-xs-b-25-f {
        padding-bottom: 25px!important
    }
    .pd-xs-l-25-f {
        padding-left: 25px!important
    }
    .pd-xs-30 {
        padding: 30px
    }
    .pd-xs-30-f {
        padding: 30px!important
    }
    .pd-xs-y-30 {
        padding-top: 30px;
        padding-bottom: 30px
    }
    .pd-xs-y-30-f {
        padding-top: 30px!important;
        padding-bottom: 30px!important
    }
    .pd-xs-x-30 {
        padding-left: 30px;
        padding-right: 30px
    }
    .pd-xs-x-30-f {
        padding-left: 30px!important;
        padding-right: 30px!important
    }
    .pd-xs-t-30 {
        padding-top: 30px
    }
    .pd-xs-r-30 {
        padding-right: 30px
    }
    .pd-xs-b-30 {
        padding-bottom: 30px
    }
    .pd-xs-l-30 {
        padding-left: 30px
    }
    .pd-xs-t-30-f {
        padding-top: 30px!important
    }
    .pd-xs-r-30-f {
        padding-right: 30px!important
    }
    .pd-xs-b-30-f {
        padding-bottom: 30px!important
    }
    .pd-xs-l-30-f {
        padding-left: 30px!important
    }
    .pd-xs-35 {
        padding: 35px
    }
    .pd-xs-35-f {
        padding: 35px!important
    }
    .pd-xs-y-35 {
        padding-top: 35px;
        padding-bottom: 35px
    }
    .pd-xs-y-35-f {
        padding-top: 35px!important;
        padding-bottom: 35px!important
    }
    .pd-xs-x-35 {
        padding-left: 35px;
        padding-right: 35px
    }
    .pd-xs-x-35-f {
        padding-left: 35px!important;
        padding-right: 35px!important
    }
    .pd-xs-t-35 {
        padding-top: 35px
    }
    .pd-xs-r-35 {
        padding-right: 35px
    }
    .pd-xs-b-35 {
        padding-bottom: 35px
    }
    .pd-xs-l-35 {
        padding-left: 35px
    }
    .pd-xs-t-35-f {
        padding-top: 35px!important
    }
    .pd-xs-r-35-f {
        padding-right: 35px!important
    }
    .pd-xs-b-35-f {
        padding-bottom: 35px!important
    }
    .pd-xs-l-35-f {
        padding-left: 35px!important
    }
    .pd-xs-40 {
        padding: 40px
    }
    .pd-xs-40-f {
        padding: 40px!important
    }
    .pd-xs-y-40 {
        padding-top: 40px;
        padding-bottom: 40px
    }
    .pd-xs-y-40-f {
        padding-top: 40px!important;
        padding-bottom: 40px!important
    }
    .pd-xs-x-40 {
        padding-left: 40px;
        padding-right: 40px
    }
    .pd-xs-x-40-f {
        padding-left: 40px!important;
        padding-right: 40px!important
    }
    .pd-xs-t-40 {
        padding-top: 40px
    }
    .pd-xs-r-40 {
        padding-right: 40px
    }
    .pd-xs-b-40 {
        padding-bottom: 40px
    }
    .pd-xs-l-40 {
        padding-left: 40px
    }
    .pd-xs-t-40-f {
        padding-top: 40px!important
    }
    .pd-xs-r-40-f {
        padding-right: 40px!important
    }
    .pd-xs-b-40-f {
        padding-bottom: 40px!important
    }
    .pd-xs-l-40-f {
        padding-left: 40px!important
    }
    .pd-xs-45 {
        padding: 45px
    }
    .pd-xs-45-f {
        padding: 45px!important
    }
    .pd-xs-y-45 {
        padding-top: 45px;
        padding-bottom: 45px
    }
    .pd-xs-y-45-f {
        padding-top: 45px!important;
        padding-bottom: 45px!important
    }
    .pd-xs-x-45 {
        padding-left: 45px;
        padding-right: 45px
    }
    .pd-xs-x-45-f {
        padding-left: 45px!important;
        padding-right: 45px!important
    }
    .pd-xs-t-45 {
        padding-top: 45px
    }
    .pd-xs-r-45 {
        padding-right: 45px
    }
    .pd-xs-b-45 {
        padding-bottom: 45px
    }
    .pd-xs-l-45 {
        padding-left: 45px
    }
    .pd-xs-t-45-f {
        padding-top: 45px!important
    }
    .pd-xs-r-45-f {
        padding-right: 45px!important
    }
    .pd-xs-b-45-f {
        padding-bottom: 45px!important
    }
    .pd-xs-l-45-f {
        padding-left: 45px!important
    }
    .pd-xs-50 {
        padding: 50px
    }
    .pd-xs-50-f {
        padding: 50px!important
    }
    .pd-xs-y-50 {
        padding-top: 50px;
        padding-bottom: 50px
    }
    .pd-xs-y-50-f {
        padding-top: 50px!important;
        padding-bottom: 50px!important
    }
    .pd-xs-x-50 {
        padding-left: 50px;
        padding-right: 50px
    }
    .pd-xs-x-50-f {
        padding-left: 50px!important;
        padding-right: 50px!important
    }
    .pd-xs-t-50 {
        padding-top: 50px
    }
    .pd-xs-r-50 {
        padding-right: 50px
    }
    .pd-xs-b-50 {
        padding-bottom: 50px
    }
    .pd-xs-l-50 {
        padding-left: 50px
    }
    .pd-xs-t-50-f {
        padding-top: 50px!important
    }
    .pd-xs-r-50-f {
        padding-right: 50px!important
    }
    .pd-xs-b-50-f {
        padding-bottom: 50px!important
    }
    .pd-xs-l-50-f {
        padding-left: 50px!important
    }
    .pd-xs-55 {
        padding: 55px
    }
    .pd-xs-55-f {
        padding: 55px!important
    }
    .pd-xs-y-55 {
        padding-top: 55px;
        padding-bottom: 55px
    }
    .pd-xs-y-55-f {
        padding-top: 55px!important;
        padding-bottom: 55px!important
    }
    .pd-xs-x-55 {
        padding-left: 55px;
        padding-right: 55px
    }
    .pd-xs-x-55-f {
        padding-left: 55px!important;
        padding-right: 55px!important
    }
    .pd-xs-t-55 {
        padding-top: 55px
    }
    .pd-xs-r-55 {
        padding-right: 55px
    }
    .pd-xs-b-55 {
        padding-bottom: 55px
    }
    .pd-xs-l-55 {
        padding-left: 55px
    }
    .pd-xs-t-55-f {
        padding-top: 55px!important
    }
    .pd-xs-r-55-f {
        padding-right: 55px!important
    }
    .pd-xs-b-55-f {
        padding-bottom: 55px!important
    }
    .pd-xs-l-55-f {
        padding-left: 55px!important
    }
    .pd-xs-60 {
        padding: 60px
    }
    .pd-xs-60-f {
        padding: 60px!important
    }
    .pd-xs-y-60 {
        padding-top: 60px;
        padding-bottom: 60px
    }
    .pd-xs-y-60-f {
        padding-top: 60px!important;
        padding-bottom: 60px!important
    }
    .pd-xs-x-60 {
        padding-left: 60px;
        padding-right: 60px
    }
    .pd-xs-x-60-f {
        padding-left: 60px!important;
        padding-right: 60px!important
    }
    .pd-xs-t-60 {
        padding-top: 60px
    }
    .pd-xs-r-60 {
        padding-right: 60px
    }
    .pd-xs-b-60 {
        padding-bottom: 60px
    }
    .pd-xs-l-60 {
        padding-left: 60px
    }
    .pd-xs-t-60-f {
        padding-top: 60px!important
    }
    .pd-xs-r-60-f {
        padding-right: 60px!important
    }
    .pd-xs-b-60-f {
        padding-bottom: 60px!important
    }
    .pd-xs-l-60-f {
        padding-left: 60px!important
    }
    .pd-xs-65 {
        padding: 65px
    }
    .pd-xs-65-f {
        padding: 65px!important
    }
    .pd-xs-y-65 {
        padding-top: 65px;
        padding-bottom: 65px
    }
    .pd-xs-y-65-f {
        padding-top: 65px!important;
        padding-bottom: 65px!important
    }
    .pd-xs-x-65 {
        padding-left: 65px;
        padding-right: 65px
    }
    .pd-xs-x-65-f {
        padding-left: 65px!important;
        padding-right: 65px!important
    }
    .pd-xs-t-65 {
        padding-top: 65px
    }
    .pd-xs-r-65 {
        padding-right: 65px
    }
    .pd-xs-b-65 {
        padding-bottom: 65px
    }
    .pd-xs-l-65 {
        padding-left: 65px
    }
    .pd-xs-t-65-f {
        padding-top: 65px!important
    }
    .pd-xs-r-65-f {
        padding-right: 65px!important
    }
    .pd-xs-b-65-f {
        padding-bottom: 65px!important
    }
    .pd-xs-l-65-f {
        padding-left: 65px!important
    }
    .pd-xs-70 {
        padding: 70px
    }
    .pd-xs-70-f {
        padding: 70px!important
    }
    .pd-xs-y-70 {
        padding-top: 70px;
        padding-bottom: 70px
    }
    .pd-xs-y-70-f {
        padding-top: 70px!important;
        padding-bottom: 70px!important
    }
    .pd-xs-x-70 {
        padding-left: 70px;
        padding-right: 70px
    }
    .pd-xs-x-70-f {
        padding-left: 70px!important;
        padding-right: 70px!important
    }
    .pd-xs-t-70 {
        padding-top: 70px
    }
    .pd-xs-r-70 {
        padding-right: 70px
    }
    .pd-xs-b-70 {
        padding-bottom: 70px
    }
    .pd-xs-l-70 {
        padding-left: 70px
    }
    .pd-xs-t-70-f {
        padding-top: 70px!important
    }
    .pd-xs-r-70-f {
        padding-right: 70px!important
    }
    .pd-xs-b-70-f {
        padding-bottom: 70px!important
    }
    .pd-xs-l-70-f {
        padding-left: 70px!important
    }
    .pd-xs-75 {
        padding: 75px
    }
    .pd-xs-75-f {
        padding: 75px!important
    }
    .pd-xs-y-75 {
        padding-top: 75px;
        padding-bottom: 75px
    }
    .pd-xs-y-75-f {
        padding-top: 75px!important;
        padding-bottom: 75px!important
    }
    .pd-xs-x-75 {
        padding-left: 75px;
        padding-right: 75px
    }
    .pd-xs-x-75-f {
        padding-left: 75px!important;
        padding-right: 75px!important
    }
    .pd-xs-t-75 {
        padding-top: 75px
    }
    .pd-xs-r-75 {
        padding-right: 75px
    }
    .pd-xs-b-75 {
        padding-bottom: 75px
    }
    .pd-xs-l-75 {
        padding-left: 75px
    }
    .pd-xs-t-75-f {
        padding-top: 75px!important
    }
    .pd-xs-r-75-f {
        padding-right: 75px!important
    }
    .pd-xs-b-75-f {
        padding-bottom: 75px!important
    }
    .pd-xs-l-75-f {
        padding-left: 75px!important
    }
    .pd-xs-80 {
        padding: 80px
    }
    .pd-xs-80-f {
        padding: 80px!important
    }
    .pd-xs-y-80 {
        padding-top: 80px;
        padding-bottom: 80px
    }
    .pd-xs-y-80-f {
        padding-top: 80px!important;
        padding-bottom: 80px!important
    }
    .pd-xs-x-80 {
        padding-left: 80px;
        padding-right: 80px
    }
    .pd-xs-x-80-f {
        padding-left: 80px!important;
        padding-right: 80px!important
    }
    .pd-xs-t-80 {
        padding-top: 80px
    }
    .pd-xs-r-80 {
        padding-right: 80px
    }
    .pd-xs-b-80 {
        padding-bottom: 80px
    }
    .pd-xs-l-80 {
        padding-left: 80px
    }
    .pd-xs-t-80-f {
        padding-top: 80px!important
    }
    .pd-xs-r-80-f {
        padding-right: 80px!important
    }
    .pd-xs-b-80-f {
        padding-bottom: 80px!important
    }
    .pd-xs-l-80-f {
        padding-left: 80px!important
    }
    .pd-xs-85 {
        padding: 85px
    }
    .pd-xs-85-f {
        padding: 85px!important
    }
    .pd-xs-y-85 {
        padding-top: 85px;
        padding-bottom: 85px
    }
    .pd-xs-y-85-f {
        padding-top: 85px!important;
        padding-bottom: 85px!important
    }
    .pd-xs-x-85 {
        padding-left: 85px;
        padding-right: 85px
    }
    .pd-xs-x-85-f {
        padding-left: 85px!important;
        padding-right: 85px!important
    }
    .pd-xs-t-85 {
        padding-top: 85px
    }
    .pd-xs-r-85 {
        padding-right: 85px
    }
    .pd-xs-b-85 {
        padding-bottom: 85px
    }
    .pd-xs-l-85 {
        padding-left: 85px
    }
    .pd-xs-t-85-f {
        padding-top: 85px!important
    }
    .pd-xs-r-85-f {
        padding-right: 85px!important
    }
    .pd-xs-b-85-f {
        padding-bottom: 85px!important
    }
    .pd-xs-l-85-f {
        padding-left: 85px!important
    }
    .pd-xs-90 {
        padding: 90px
    }
    .pd-xs-90-f {
        padding: 90px!important
    }
    .pd-xs-y-90 {
        padding-top: 90px;
        padding-bottom: 90px
    }
    .pd-xs-y-90-f {
        padding-top: 90px!important;
        padding-bottom: 90px!important
    }
    .pd-xs-x-90 {
        padding-left: 90px;
        padding-right: 90px
    }
    .pd-xs-x-90-f {
        padding-left: 90px!important;
        padding-right: 90px!important
    }
    .pd-xs-t-90 {
        padding-top: 90px
    }
    .pd-xs-r-90 {
        padding-right: 90px
    }
    .pd-xs-b-90 {
        padding-bottom: 90px
    }
    .pd-xs-l-90 {
        padding-left: 90px
    }
    .pd-xs-t-90-f {
        padding-top: 90px!important
    }
    .pd-xs-r-90-f {
        padding-right: 90px!important
    }
    .pd-xs-b-90-f {
        padding-bottom: 90px!important
    }
    .pd-xs-l-90-f {
        padding-left: 90px!important
    }
    .pd-xs-95 {
        padding: 95px
    }
    .pd-xs-95-f {
        padding: 95px!important
    }
    .pd-xs-y-95 {
        padding-top: 95px;
        padding-bottom: 95px
    }
    .pd-xs-y-95-f {
        padding-top: 95px!important;
        padding-bottom: 95px!important
    }
    .pd-xs-x-95 {
        padding-left: 95px;
        padding-right: 95px
    }
    .pd-xs-x-95-f {
        padding-left: 95px!important;
        padding-right: 95px!important
    }
    .pd-xs-t-95 {
        padding-top: 95px
    }
    .pd-xs-r-95 {
        padding-right: 95px
    }
    .pd-xs-b-95 {
        padding-bottom: 95px
    }
    .pd-xs-l-95 {
        padding-left: 95px
    }
    .pd-xs-t-95-f {
        padding-top: 95px!important
    }
    .pd-xs-r-95-f {
        padding-right: 95px!important
    }
    .pd-xs-b-95-f {
        padding-bottom: 95px!important
    }
    .pd-xs-l-95-f {
        padding-left: 95px!important
    }
    .pd-xs-100 {
        padding: 100px
    }
    .pd-xs-100-f {
        padding: 100px!important
    }
    .pd-xs-y-100 {
        padding-top: 100px;
        padding-bottom: 100px
    }
    .pd-xs-y-100-f {
        padding-top: 100px!important;
        padding-bottom: 100px!important
    }
    .pd-xs-x-100 {
        padding-left: 100px;
        padding-right: 100px
    }
    .pd-xs-x-100-f {
        padding-left: 100px!important;
        padding-right: 100px!important
    }
    .pd-xs-t-100 {
        padding-top: 100px
    }
    .pd-xs-r-100 {
        padding-right: 100px
    }
    .pd-xs-b-100 {
        padding-bottom: 100px
    }
    .pd-xs-l-100 {
        padding-left: 100px
    }
    .pd-xs-t-100-f {
        padding-top: 100px!important
    }
    .pd-xs-r-100-f {
        padding-right: 100px!important
    }
    .pd-xs-b-100-f {
        padding-bottom: 100px!important
    }
    .pd-xs-l-100-f {
        padding-left: 100px!important
    }
    .pd-xs-110 {
        padding: 110px
    }
    .pd-xs-110-f {
        padding: 110px!important
    }
    .pd-xs-y-110 {
        padding-top: 110px;
        padding-bottom: 110px
    }
    .pd-xs-y-110-f {
        padding-top: 110px!important;
        padding-bottom: 110px!important
    }
    .pd-xs-x-110 {
        padding-left: 110px;
        padding-right: 110px
    }
    .pd-xs-x-110-f {
        padding-left: 110px!important;
        padding-right: 110px!important
    }
    .pd-xs-t-110 {
        padding-top: 110px
    }
    .pd-xs-r-110 {
        padding-right: 110px
    }
    .pd-xs-b-110 {
        padding-bottom: 110px
    }
    .pd-xs-l-110 {
        padding-left: 110px
    }
    .pd-xs-t-110-f {
        padding-top: 110px!important
    }
    .pd-xs-r-110-f {
        padding-right: 110px!important
    }
    .pd-xs-b-110-f {
        padding-bottom: 110px!important
    }
    .pd-xs-l-110-f {
        padding-left: 110px!important
    }
    .pd-xs-120 {
        padding: 120px
    }
    .pd-xs-120-f {
        padding: 120px!important
    }
    .pd-xs-y-120 {
        padding-top: 120px;
        padding-bottom: 120px
    }
    .pd-xs-y-120-f {
        padding-top: 120px!important;
        padding-bottom: 120px!important
    }
    .pd-xs-x-120 {
        padding-left: 120px;
        padding-right: 120px
    }
    .pd-xs-x-120-f {
        padding-left: 120px!important;
        padding-right: 120px!important
    }
    .pd-xs-t-120 {
        padding-top: 120px
    }
    .pd-xs-r-120 {
        padding-right: 120px
    }
    .pd-xs-b-120 {
        padding-bottom: 120px
    }
    .pd-xs-l-120 {
        padding-left: 120px
    }
    .pd-xs-t-120-f {
        padding-top: 120px!important
    }
    .pd-xs-r-120-f {
        padding-right: 120px!important
    }
    .pd-xs-b-120-f {
        padding-bottom: 120px!important
    }
    .pd-xs-l-120-f {
        padding-left: 120px!important
    }
    .pd-xs-130 {
        padding: 130px
    }
    .pd-xs-130-f {
        padding: 130px!important
    }
    .pd-xs-y-130 {
        padding-top: 130px;
        padding-bottom: 130px
    }
    .pd-xs-y-130-f {
        padding-top: 130px!important;
        padding-bottom: 130px!important
    }
    .pd-xs-x-130 {
        padding-left: 130px;
        padding-right: 130px
    }
    .pd-xs-x-130-f {
        padding-left: 130px!important;
        padding-right: 130px!important
    }
    .pd-xs-t-130 {
        padding-top: 130px
    }
    .pd-xs-r-130 {
        padding-right: 130px
    }
    .pd-xs-b-130 {
        padding-bottom: 130px
    }
    .pd-xs-l-130 {
        padding-left: 130px
    }
    .pd-xs-t-130-f {
        padding-top: 130px!important
    }
    .pd-xs-r-130-f {
        padding-right: 130px!important
    }
    .pd-xs-b-130-f {
        padding-bottom: 130px!important
    }
    .pd-xs-l-130-f {
        padding-left: 130px!important
    }
    .pd-xs-140 {
        padding: 140px
    }
    .pd-xs-140-f {
        padding: 140px!important
    }
    .pd-xs-y-140 {
        padding-top: 140px;
        padding-bottom: 140px
    }
    .pd-xs-y-140-f {
        padding-top: 140px!important;
        padding-bottom: 140px!important
    }
    .pd-xs-x-140 {
        padding-left: 140px;
        padding-right: 140px
    }
    .pd-xs-x-140-f {
        padding-left: 140px!important;
        padding-right: 140px!important
    }
    .pd-xs-t-140 {
        padding-top: 140px
    }
    .pd-xs-r-140 {
        padding-right: 140px
    }
    .pd-xs-b-140 {
        padding-bottom: 140px
    }
    .pd-xs-l-140 {
        padding-left: 140px
    }
    .pd-xs-t-140-f {
        padding-top: 140px!important
    }
    .pd-xs-r-140-f {
        padding-right: 140px!important
    }
    .pd-xs-b-140-f {
        padding-bottom: 140px!important
    }
    .pd-xs-l-140-f {
        padding-left: 140px!important
    }
    .pd-xs-150 {
        padding: 150px
    }
    .pd-xs-150-f {
        padding: 150px!important
    }
    .pd-xs-y-150 {
        padding-top: 150px;
        padding-bottom: 150px
    }
    .pd-xs-y-150-f {
        padding-top: 150px!important;
        padding-bottom: 150px!important
    }
    .pd-xs-x-150 {
        padding-left: 150px;
        padding-right: 150px
    }
    .pd-xs-x-150-f {
        padding-left: 150px!important;
        padding-right: 150px!important
    }
    .pd-xs-t-150 {
        padding-top: 150px
    }
    .pd-xs-r-150 {
        padding-right: 150px
    }
    .pd-xs-b-150 {
        padding-bottom: 150px
    }
    .pd-xs-l-150 {
        padding-left: 150px
    }
    .pd-xs-t-150-f {
        padding-top: 150px!important
    }
    .pd-xs-r-150-f {
        padding-right: 150px!important
    }
    .pd-xs-b-150-f {
        padding-bottom: 150px!important
    }
    .pd-xs-l-150-f {
        padding-left: 150px!important
    }
    .pd-xs-160 {
        padding: 160px
    }
    .pd-xs-160-f {
        padding: 160px!important
    }
    .pd-xs-y-160 {
        padding-top: 160px;
        padding-bottom: 160px
    }
    .pd-xs-y-160-f {
        padding-top: 160px!important;
        padding-bottom: 160px!important
    }
    .pd-xs-x-160 {
        padding-left: 160px;
        padding-right: 160px
    }
    .pd-xs-x-160-f {
        padding-left: 160px!important;
        padding-right: 160px!important
    }
    .pd-xs-t-160 {
        padding-top: 160px
    }
    .pd-xs-r-160 {
        padding-right: 160px
    }
    .pd-xs-b-160 {
        padding-bottom: 160px
    }
    .pd-xs-l-160 {
        padding-left: 160px
    }
    .pd-xs-t-160-f {
        padding-top: 160px!important
    }
    .pd-xs-r-160-f {
        padding-right: 160px!important
    }
    .pd-xs-b-160-f {
        padding-bottom: 160px!important
    }
    .pd-xs-l-160-f {
        padding-left: 160px!important
    }
    .pd-xs-170 {
        padding: 170px
    }
    .pd-xs-170-f {
        padding: 170px!important
    }
    .pd-xs-y-170 {
        padding-top: 170px;
        padding-bottom: 170px
    }
    .pd-xs-y-170-f {
        padding-top: 170px!important;
        padding-bottom: 170px!important
    }
    .pd-xs-x-170 {
        padding-left: 170px;
        padding-right: 170px
    }
    .pd-xs-x-170-f {
        padding-left: 170px!important;
        padding-right: 170px!important
    }
    .pd-xs-t-170 {
        padding-top: 170px
    }
    .pd-xs-r-170 {
        padding-right: 170px
    }
    .pd-xs-b-170 {
        padding-bottom: 170px
    }
    .pd-xs-l-170 {
        padding-left: 170px
    }
    .pd-xs-t-170-f {
        padding-top: 170px!important
    }
    .pd-xs-r-170-f {
        padding-right: 170px!important
    }
    .pd-xs-b-170-f {
        padding-bottom: 170px!important
    }
    .pd-xs-l-170-f {
        padding-left: 170px!important
    }
    .pd-xs-180 {
        padding: 180px
    }
    .pd-xs-180-f {
        padding: 180px!important
    }
    .pd-xs-y-180 {
        padding-top: 180px;
        padding-bottom: 180px
    }
    .pd-xs-y-180-f {
        padding-top: 180px!important;
        padding-bottom: 180px!important
    }
    .pd-xs-x-180 {
        padding-left: 180px;
        padding-right: 180px
    }
    .pd-xs-x-180-f {
        padding-left: 180px!important;
        padding-right: 180px!important
    }
    .pd-xs-t-180 {
        padding-top: 180px
    }
    .pd-xs-r-180 {
        padding-right: 180px
    }
    .pd-xs-b-180 {
        padding-bottom: 180px
    }
    .pd-xs-l-180 {
        padding-left: 180px
    }
    .pd-xs-t-180-f {
        padding-top: 180px!important
    }
    .pd-xs-r-180-f {
        padding-right: 180px!important
    }
    .pd-xs-b-180-f {
        padding-bottom: 180px!important
    }
    .pd-xs-l-180-f {
        padding-left: 180px!important
    }
    .pd-xs-190 {
        padding: 190px
    }
    .pd-xs-190-f {
        padding: 190px!important
    }
    .pd-xs-y-190 {
        padding-top: 190px;
        padding-bottom: 190px
    }
    .pd-xs-y-190-f {
        padding-top: 190px!important;
        padding-bottom: 190px!important
    }
    .pd-xs-x-190 {
        padding-left: 190px;
        padding-right: 190px
    }
    .pd-xs-x-190-f {
        padding-left: 190px!important;
        padding-right: 190px!important
    }
    .pd-xs-t-190 {
        padding-top: 190px
    }
    .pd-xs-r-190 {
        padding-right: 190px
    }
    .pd-xs-b-190 {
        padding-bottom: 190px
    }
    .pd-xs-l-190 {
        padding-left: 190px
    }
    .pd-xs-t-190-f {
        padding-top: 190px!important
    }
    .pd-xs-r-190-f {
        padding-right: 190px!important
    }
    .pd-xs-b-190-f {
        padding-bottom: 190px!important
    }
    .pd-xs-l-190-f {
        padding-left: 190px!important
    }
    .pd-xs-200 {
        padding: 200px
    }
    .pd-xs-200-f {
        padding: 200px!important
    }
    .pd-xs-y-200 {
        padding-top: 200px;
        padding-bottom: 200px
    }
    .pd-xs-y-200-f {
        padding-top: 200px!important;
        padding-bottom: 200px!important
    }
    .pd-xs-x-200 {
        padding-left: 200px;
        padding-right: 200px
    }
    .pd-xs-x-200-f {
        padding-left: 200px!important;
        padding-right: 200px!important
    }
    .pd-xs-t-200 {
        padding-top: 200px
    }
    .pd-xs-r-200 {
        padding-right: 200px
    }
    .pd-xs-b-200 {
        padding-bottom: 200px
    }
    .pd-xs-l-200 {
        padding-left: 200px
    }
    .pd-xs-t-200-f {
        padding-top: 200px!important
    }
    .pd-xs-r-200-f {
        padding-right: 200px!important
    }
    .pd-xs-b-200-f {
        padding-bottom: 200px!important
    }
    .pd-xs-l-200-f {
        padding-left: 200px!important
    }
}

@media (min-width:576px) {
    .pd-sm-0 {
        padding: 0
    }
    .pd-sm-0-f {
        padding: 0!important
    }
    .pd-sm-y-0 {
        padding-top: 0;
        padding-bottom: 0
    }
    .pd-sm-y-0-f {
        padding-top: 0!important;
        padding-bottom: 0!important
    }
    .pd-sm-x-0 {
        padding-left: 0;
        padding-right: 0
    }
    .pd-sm-x-0-f {
        padding-left: 0!important;
        padding-right: 0!important
    }
    .pd-sm-t-0 {
        padding-top: 0
    }
    .pd-sm-r-0 {
        padding-right: 0
    }
    .pd-sm-b-0 {
        padding-bottom: 0
    }
    .pd-sm-l-0 {
        padding-left: 0
    }
    .pd-sm-t-0-f {
        padding-top: 0!important
    }
    .pd-sm-r-0-f {
        padding-right: 0!important
    }
    .pd-sm-b-0-f {
        padding-bottom: 0!important
    }
    .pd-sm-l-0-f {
        padding-left: 0!important
    }
    .pd-sm-1 {
        padding: 1px
    }
    .pd-sm-1-f {
        padding: 1px!important
    }
    .pd-sm-y-1 {
        padding-top: 1px;
        padding-bottom: 1px
    }
    .pd-sm-y-1-f {
        padding-top: 1px!important;
        padding-bottom: 1px!important
    }
    .pd-sm-x-1 {
        padding-left: 1px;
        padding-right: 1px
    }
    .pd-sm-x-1-f {
        padding-left: 1px!important;
        padding-right: 1px!important
    }
    .pd-sm-t-1 {
        padding-top: 1px
    }
    .pd-sm-r-1 {
        padding-right: 1px
    }
    .pd-sm-b-1 {
        padding-bottom: 1px
    }
    .pd-sm-l-1 {
        padding-left: 1px
    }
    .pd-sm-t-1-f {
        padding-top: 1px!important
    }
    .pd-sm-r-1-f {
        padding-right: 1px!important
    }
    .pd-sm-b-1-f {
        padding-bottom: 1px!important
    }
    .pd-sm-l-1-f {
        padding-left: 1px!important
    }
    .pd-sm-2 {
        padding: 2px
    }
    .pd-sm-2-f {
        padding: 2px!important
    }
    .pd-sm-y-2 {
        padding-top: 2px;
        padding-bottom: 2px
    }
    .pd-sm-y-2-f {
        padding-top: 2px!important;
        padding-bottom: 2px!important
    }
    .pd-sm-x-2 {
        padding-left: 2px;
        padding-right: 2px
    }
    .pd-sm-x-2-f {
        padding-left: 2px!important;
        padding-right: 2px!important
    }
    .pd-sm-t-2 {
        padding-top: 2px
    }
    .pd-sm-r-2 {
        padding-right: 2px
    }
    .pd-sm-b-2 {
        padding-bottom: 2px
    }
    .pd-sm-l-2 {
        padding-left: 2px
    }
    .pd-sm-t-2-f {
        padding-top: 2px!important
    }
    .pd-sm-r-2-f {
        padding-right: 2px!important
    }
    .pd-sm-b-2-f {
        padding-bottom: 2px!important
    }
    .pd-sm-l-2-f {
        padding-left: 2px!important
    }
    .pd-sm-3 {
        padding: 3px
    }
    .pd-sm-3-f {
        padding: 3px!important
    }
    .pd-sm-y-3 {
        padding-top: 3px;
        padding-bottom: 3px
    }
    .pd-sm-y-3-f {
        padding-top: 3px!important;
        padding-bottom: 3px!important
    }
    .pd-sm-x-3 {
        padding-left: 3px;
        padding-right: 3px
    }
    .pd-sm-x-3-f {
        padding-left: 3px!important;
        padding-right: 3px!important
    }
    .pd-sm-t-3 {
        padding-top: 3px
    }
    .pd-sm-r-3 {
        padding-right: 3px
    }
    .pd-sm-b-3 {
        padding-bottom: 3px
    }
    .pd-sm-l-3 {
        padding-left: 3px
    }
    .pd-sm-t-3-f {
        padding-top: 3px!important
    }
    .pd-sm-r-3-f {
        padding-right: 3px!important
    }
    .pd-sm-b-3-f {
        padding-bottom: 3px!important
    }
    .pd-sm-l-3-f {
        padding-left: 3px!important
    }
    .pd-sm-4 {
        padding: 4px
    }
    .pd-sm-4-f {
        padding: 4px!important
    }
    .pd-sm-y-4 {
        padding-top: 4px;
        padding-bottom: 4px
    }
    .pd-sm-y-4-f {
        padding-top: 4px!important;
        padding-bottom: 4px!important
    }
    .pd-sm-x-4 {
        padding-left: 4px;
        padding-right: 4px
    }
    .pd-sm-x-4-f {
        padding-left: 4px!important;
        padding-right: 4px!important
    }
    .pd-sm-t-4 {
        padding-top: 4px
    }
    .pd-sm-r-4 {
        padding-right: 4px
    }
    .pd-sm-b-4 {
        padding-bottom: 4px
    }
    .pd-sm-l-4 {
        padding-left: 4px
    }
    .pd-sm-t-4-f {
        padding-top: 4px!important
    }
    .pd-sm-r-4-f {
        padding-right: 4px!important
    }
    .pd-sm-b-4-f {
        padding-bottom: 4px!important
    }
    .pd-sm-l-4-f {
        padding-left: 4px!important
    }
    .pd-sm-5 {
        padding: 5px
    }
    .pd-sm-5-f {
        padding: 5px!important
    }
    .pd-sm-y-5 {
        padding-top: 5px;
        padding-bottom: 5px
    }
    .pd-sm-y-5-f {
        padding-top: 5px!important;
        padding-bottom: 5px!important
    }
    .pd-sm-x-5 {
        padding-left: 5px;
        padding-right: 5px
    }
    .pd-sm-x-5-f {
        padding-left: 5px!important;
        padding-right: 5px!important
    }
    .pd-sm-t-5 {
        padding-top: 5px
    }
    .pd-sm-r-5 {
        padding-right: 5px
    }
    .pd-sm-b-5 {
        padding-bottom: 5px
    }
    .pd-sm-l-5 {
        padding-left: 5px
    }
    .pd-sm-t-5-f {
        padding-top: 5px!important
    }
    .pd-sm-r-5-f {
        padding-right: 5px!important
    }
    .pd-sm-b-5-f {
        padding-bottom: 5px!important
    }
    .pd-sm-l-5-f {
        padding-left: 5px!important
    }
    .pd-sm-6 {
        padding: 6px
    }
    .pd-sm-6-f {
        padding: 6px!important
    }
    .pd-sm-y-6 {
        padding-top: 6px;
        padding-bottom: 6px
    }
    .pd-sm-y-6-f {
        padding-top: 6px!important;
        padding-bottom: 6px!important
    }
    .pd-sm-x-6 {
        padding-left: 6px;
        padding-right: 6px
    }
    .pd-sm-x-6-f {
        padding-left: 6px!important;
        padding-right: 6px!important
    }
    .pd-sm-t-6 {
        padding-top: 6px
    }
    .pd-sm-r-6 {
        padding-right: 6px
    }
    .pd-sm-b-6 {
        padding-bottom: 6px
    }
    .pd-sm-l-6 {
        padding-left: 6px
    }
    .pd-sm-t-6-f {
        padding-top: 6px!important
    }
    .pd-sm-r-6-f {
        padding-right: 6px!important
    }
    .pd-sm-b-6-f {
        padding-bottom: 6px!important
    }
    .pd-sm-l-6-f {
        padding-left: 6px!important
    }
    .pd-sm-7 {
        padding: 7px
    }
    .pd-sm-7-f {
        padding: 7px!important
    }
    .pd-sm-y-7 {
        padding-top: 7px;
        padding-bottom: 7px
    }
    .pd-sm-y-7-f {
        padding-top: 7px!important;
        padding-bottom: 7px!important
    }
    .pd-sm-x-7 {
        padding-left: 7px;
        padding-right: 7px
    }
    .pd-sm-x-7-f {
        padding-left: 7px!important;
        padding-right: 7px!important
    }
    .pd-sm-t-7 {
        padding-top: 7px
    }
    .pd-sm-r-7 {
        padding-right: 7px
    }
    .pd-sm-b-7 {
        padding-bottom: 7px
    }
    .pd-sm-l-7 {
        padding-left: 7px
    }
    .pd-sm-t-7-f {
        padding-top: 7px!important
    }
    .pd-sm-r-7-f {
        padding-right: 7px!important
    }
    .pd-sm-b-7-f {
        padding-bottom: 7px!important
    }
    .pd-sm-l-7-f {
        padding-left: 7px!important
    }
    .pd-sm-8 {
        padding: 8px
    }
    .pd-sm-8-f {
        padding: 8px!important
    }
    .pd-sm-y-8 {
        padding-top: 8px;
        padding-bottom: 8px
    }
    .pd-sm-y-8-f {
        padding-top: 8px!important;
        padding-bottom: 8px!important
    }
    .pd-sm-x-8 {
        padding-left: 8px;
        padding-right: 8px
    }
    .pd-sm-x-8-f {
        padding-left: 8px!important;
        padding-right: 8px!important
    }
    .pd-sm-t-8 {
        padding-top: 8px
    }
    .pd-sm-r-8 {
        padding-right: 8px
    }
    .pd-sm-b-8 {
        padding-bottom: 8px
    }
    .pd-sm-l-8 {
        padding-left: 8px
    }
    .pd-sm-t-8-f {
        padding-top: 8px!important
    }
    .pd-sm-r-8-f {
        padding-right: 8px!important
    }
    .pd-sm-b-8-f {
        padding-bottom: 8px!important
    }
    .pd-sm-l-8-f {
        padding-left: 8px!important
    }
    .pd-sm-9 {
        padding: 9px
    }
    .pd-sm-9-f {
        padding: 9px!important
    }
    .pd-sm-y-9 {
        padding-top: 9px;
        padding-bottom: 9px
    }
    .pd-sm-y-9-f {
        padding-top: 9px!important;
        padding-bottom: 9px!important
    }
    .pd-sm-x-9 {
        padding-left: 9px;
        padding-right: 9px
    }
    .pd-sm-x-9-f {
        padding-left: 9px!important;
        padding-right: 9px!important
    }
    .pd-sm-t-9 {
        padding-top: 9px
    }
    .pd-sm-r-9 {
        padding-right: 9px
    }
    .pd-sm-b-9 {
        padding-bottom: 9px
    }
    .pd-sm-l-9 {
        padding-left: 9px
    }
    .pd-sm-t-9-f {
        padding-top: 9px!important
    }
    .pd-sm-r-9-f {
        padding-right: 9px!important
    }
    .pd-sm-b-9-f {
        padding-bottom: 9px!important
    }
    .pd-sm-l-9-f {
        padding-left: 9px!important
    }
    .pd-sm-10 {
        padding: 10px
    }
    .pd-sm-10-f {
        padding: 10px!important
    }
    .pd-sm-y-10 {
        padding-top: 10px;
        padding-bottom: 10px
    }
    .pd-sm-y-10-f {
        padding-top: 10px!important;
        padding-bottom: 10px!important
    }
    .pd-sm-x-10 {
        padding-left: 10px;
        padding-right: 10px
    }
    .pd-sm-x-10-f {
        padding-left: 10px!important;
        padding-right: 10px!important
    }
    .pd-sm-t-10 {
        padding-top: 10px
    }
    .pd-sm-r-10 {
        padding-right: 10px
    }
    .pd-sm-b-10 {
        padding-bottom: 10px
    }
    .pd-sm-l-10 {
        padding-left: 10px
    }
    .pd-sm-t-10-f {
        padding-top: 10px!important
    }
    .pd-sm-r-10-f {
        padding-right: 10px!important
    }
    .pd-sm-b-10-f {
        padding-bottom: 10px!important
    }
    .pd-sm-l-10-f {
        padding-left: 10px!important
    }
    .pd-sm-15 {
        padding: 15px
    }
    .pd-sm-15-f {
        padding: 15px!important
    }
    .pd-sm-y-15 {
        padding-top: 15px;
        padding-bottom: 15px
    }
    .pd-sm-y-15-f {
        padding-top: 15px!important;
        padding-bottom: 15px!important
    }
    .pd-sm-x-15 {
        padding-left: 15px;
        padding-right: 15px
    }
    .pd-sm-x-15-f {
        padding-left: 15px!important;
        padding-right: 15px!important
    }
    .pd-sm-t-15 {
        padding-top: 15px
    }
    .pd-sm-r-15 {
        padding-right: 15px
    }
    .pd-sm-b-15 {
        padding-bottom: 15px
    }
    .pd-sm-l-15 {
        padding-left: 15px
    }
    .pd-sm-t-15-f {
        padding-top: 15px!important
    }
    .pd-sm-r-15-f {
        padding-right: 15px!important
    }
    .pd-sm-b-15-f {
        padding-bottom: 15px!important
    }
    .pd-sm-l-15-f {
        padding-left: 15px!important
    }
    .pd-sm-20 {
        padding: 20px
    }
    .pd-sm-20-f {
        padding: 20px!important
    }
    .pd-sm-y-20 {
        padding-top: 20px;
        padding-bottom: 20px
    }
    .pd-sm-y-20-f {
        padding-top: 20px!important;
        padding-bottom: 20px!important
    }
    .pd-sm-x-20 {
        padding-left: 20px;
        padding-right: 20px
    }
    .pd-sm-x-20-f {
        padding-left: 20px!important;
        padding-right: 20px!important
    }
    .pd-sm-t-20 {
        padding-top: 20px
    }
    .pd-sm-r-20 {
        padding-right: 20px
    }
    .pd-sm-b-20 {
        padding-bottom: 20px
    }
    .pd-sm-l-20 {
        padding-left: 20px
    }
    .pd-sm-t-20-f {
        padding-top: 20px!important
    }
    .pd-sm-r-20-f {
        padding-right: 20px!important
    }
    .pd-sm-b-20-f {
        padding-bottom: 20px!important
    }
    .pd-sm-l-20-f {
        padding-left: 20px!important
    }
    .pd-sm-25 {
        padding: 25px
    }
    .pd-sm-25-f {
        padding: 25px!important
    }
    .pd-sm-y-25 {
        padding-top: 25px;
        padding-bottom: 25px
    }
    .pd-sm-y-25-f {
        padding-top: 25px!important;
        padding-bottom: 25px!important
    }
    .pd-sm-x-25 {
        padding-left: 25px;
        padding-right: 25px
    }
    .pd-sm-x-25-f {
        padding-left: 25px!important;
        padding-right: 25px!important
    }
    .pd-sm-t-25 {
        padding-top: 25px
    }
    .pd-sm-r-25 {
        padding-right: 25px
    }
    .pd-sm-b-25 {
        padding-bottom: 25px
    }
    .pd-sm-l-25 {
        padding-left: 25px
    }
    .pd-sm-t-25-f {
        padding-top: 25px!important
    }
    .pd-sm-r-25-f {
        padding-right: 25px!important
    }
    .pd-sm-b-25-f {
        padding-bottom: 25px!important
    }
    .pd-sm-l-25-f {
        padding-left: 25px!important
    }
    .pd-sm-30 {
        padding: 30px
    }
    .pd-sm-30-f {
        padding: 30px!important
    }
    .pd-sm-y-30 {
        padding-top: 30px;
        padding-bottom: 30px
    }
    .pd-sm-y-30-f {
        padding-top: 30px!important;
        padding-bottom: 30px!important
    }
    .pd-sm-x-30 {
        padding-left: 30px;
        padding-right: 30px
    }
    .pd-sm-x-30-f {
        padding-left: 30px!important;
        padding-right: 30px!important
    }
    .pd-sm-t-30 {
        padding-top: 30px
    }
    .pd-sm-r-30 {
        padding-right: 30px
    }
    .pd-sm-b-30 {
        padding-bottom: 30px
    }
    .pd-sm-l-30 {
        padding-left: 30px
    }
    .pd-sm-t-30-f {
        padding-top: 30px!important
    }
    .pd-sm-r-30-f {
        padding-right: 30px!important
    }
    .pd-sm-b-30-f {
        padding-bottom: 30px!important
    }
    .pd-sm-l-30-f {
        padding-left: 30px!important
    }
    .pd-sm-35 {
        padding: 35px
    }
    .pd-sm-35-f {
        padding: 35px!important
    }
    .pd-sm-y-35 {
        padding-top: 35px;
        padding-bottom: 35px
    }
    .pd-sm-y-35-f {
        padding-top: 35px!important;
        padding-bottom: 35px!important
    }
    .pd-sm-x-35 {
        padding-left: 35px;
        padding-right: 35px
    }
    .pd-sm-x-35-f {
        padding-left: 35px!important;
        padding-right: 35px!important
    }
    .pd-sm-t-35 {
        padding-top: 35px
    }
    .pd-sm-r-35 {
        padding-right: 35px
    }
    .pd-sm-b-35 {
        padding-bottom: 35px
    }
    .pd-sm-l-35 {
        padding-left: 35px
    }
    .pd-sm-t-35-f {
        padding-top: 35px!important
    }
    .pd-sm-r-35-f {
        padding-right: 35px!important
    }
    .pd-sm-b-35-f {
        padding-bottom: 35px!important
    }
    .pd-sm-l-35-f {
        padding-left: 35px!important
    }
    .pd-sm-40 {
        padding: 40px
    }
    .pd-sm-40-f {
        padding: 40px!important
    }
    .pd-sm-y-40 {
        padding-top: 40px;
        padding-bottom: 40px
    }
    .pd-sm-y-40-f {
        padding-top: 40px!important;
        padding-bottom: 40px!important
    }
    .pd-sm-x-40 {
        padding-left: 40px;
        padding-right: 40px
    }
    .pd-sm-x-40-f {
        padding-left: 40px!important;
        padding-right: 40px!important
    }
    .pd-sm-t-40 {
        padding-top: 40px
    }
    .pd-sm-r-40 {
        padding-right: 40px
    }
    .pd-sm-b-40 {
        padding-bottom: 40px
    }
    .pd-sm-l-40 {
        padding-left: 40px
    }
    .pd-sm-t-40-f {
        padding-top: 40px!important
    }
    .pd-sm-r-40-f {
        padding-right: 40px!important
    }
    .pd-sm-b-40-f {
        padding-bottom: 40px!important
    }
    .pd-sm-l-40-f {
        padding-left: 40px!important
    }
    .pd-sm-45 {
        padding: 45px
    }
    .pd-sm-45-f {
        padding: 45px!important
    }
    .pd-sm-y-45 {
        padding-top: 45px;
        padding-bottom: 45px
    }
    .pd-sm-y-45-f {
        padding-top: 45px!important;
        padding-bottom: 45px!important
    }
    .pd-sm-x-45 {
        padding-left: 45px;
        padding-right: 45px
    }
    .pd-sm-x-45-f {
        padding-left: 45px!important;
        padding-right: 45px!important
    }
    .pd-sm-t-45 {
        padding-top: 45px
    }
    .pd-sm-r-45 {
        padding-right: 45px
    }
    .pd-sm-b-45 {
        padding-bottom: 45px
    }
    .pd-sm-l-45 {
        padding-left: 45px
    }
    .pd-sm-t-45-f {
        padding-top: 45px!important
    }
    .pd-sm-r-45-f {
        padding-right: 45px!important
    }
    .pd-sm-b-45-f {
        padding-bottom: 45px!important
    }
    .pd-sm-l-45-f {
        padding-left: 45px!important
    }
    .pd-sm-50 {
        padding: 50px
    }
    .pd-sm-50-f {
        padding: 50px!important
    }
    .pd-sm-y-50 {
        padding-top: 50px;
        padding-bottom: 50px
    }
    .pd-sm-y-50-f {
        padding-top: 50px!important;
        padding-bottom: 50px!important
    }
    .pd-sm-x-50 {
        padding-left: 50px;
        padding-right: 50px
    }
    .pd-sm-x-50-f {
        padding-left: 50px!important;
        padding-right: 50px!important
    }
    .pd-sm-t-50 {
        padding-top: 50px
    }
    .pd-sm-r-50 {
        padding-right: 50px
    }
    .pd-sm-b-50 {
        padding-bottom: 50px
    }
    .pd-sm-l-50 {
        padding-left: 50px
    }
    .pd-sm-t-50-f {
        padding-top: 50px!important
    }
    .pd-sm-r-50-f {
        padding-right: 50px!important
    }
    .pd-sm-b-50-f {
        padding-bottom: 50px!important
    }
    .pd-sm-l-50-f {
        padding-left: 50px!important
    }
    .pd-sm-55 {
        padding: 55px
    }
    .pd-sm-55-f {
        padding: 55px!important
    }
    .pd-sm-y-55 {
        padding-top: 55px;
        padding-bottom: 55px
    }
    .pd-sm-y-55-f {
        padding-top: 55px!important;
        padding-bottom: 55px!important
    }
    .pd-sm-x-55 {
        padding-left: 55px;
        padding-right: 55px
    }
    .pd-sm-x-55-f {
        padding-left: 55px!important;
        padding-right: 55px!important
    }
    .pd-sm-t-55 {
        padding-top: 55px
    }
    .pd-sm-r-55 {
        padding-right: 55px
    }
    .pd-sm-b-55 {
        padding-bottom: 55px
    }
    .pd-sm-l-55 {
        padding-left: 55px
    }
    .pd-sm-t-55-f {
        padding-top: 55px!important
    }
    .pd-sm-r-55-f {
        padding-right: 55px!important
    }
    .pd-sm-b-55-f {
        padding-bottom: 55px!important
    }
    .pd-sm-l-55-f {
        padding-left: 55px!important
    }
    .pd-sm-60 {
        padding: 60px
    }
    .pd-sm-60-f {
        padding: 60px!important
    }
    .pd-sm-y-60 {
        padding-top: 60px;
        padding-bottom: 60px
    }
    .pd-sm-y-60-f {
        padding-top: 60px!important;
        padding-bottom: 60px!important
    }
    .pd-sm-x-60 {
        padding-left: 60px;
        padding-right: 60px
    }
    .pd-sm-x-60-f {
        padding-left: 60px!important;
        padding-right: 60px!important
    }
    .pd-sm-t-60 {
        padding-top: 60px
    }
    .pd-sm-r-60 {
        padding-right: 60px
    }
    .pd-sm-b-60 {
        padding-bottom: 60px
    }
    .pd-sm-l-60 {
        padding-left: 60px
    }
    .pd-sm-t-60-f {
        padding-top: 60px!important
    }
    .pd-sm-r-60-f {
        padding-right: 60px!important
    }
    .pd-sm-b-60-f {
        padding-bottom: 60px!important
    }
    .pd-sm-l-60-f {
        padding-left: 60px!important
    }
    .pd-sm-65 {
        padding: 65px
    }
    .pd-sm-65-f {
        padding: 65px!important
    }
    .pd-sm-y-65 {
        padding-top: 65px;
        padding-bottom: 65px
    }
    .pd-sm-y-65-f {
        padding-top: 65px!important;
        padding-bottom: 65px!important
    }
    .pd-sm-x-65 {
        padding-left: 65px;
        padding-right: 65px
    }
    .pd-sm-x-65-f {
        padding-left: 65px!important;
        padding-right: 65px!important
    }
    .pd-sm-t-65 {
        padding-top: 65px
    }
    .pd-sm-r-65 {
        padding-right: 65px
    }
    .pd-sm-b-65 {
        padding-bottom: 65px
    }
    .pd-sm-l-65 {
        padding-left: 65px
    }
    .pd-sm-t-65-f {
        padding-top: 65px!important
    }
    .pd-sm-r-65-f {
        padding-right: 65px!important
    }
    .pd-sm-b-65-f {
        padding-bottom: 65px!important
    }
    .pd-sm-l-65-f {
        padding-left: 65px!important
    }
    .pd-sm-70 {
        padding: 70px
    }
    .pd-sm-70-f {
        padding: 70px!important
    }
    .pd-sm-y-70 {
        padding-top: 70px;
        padding-bottom: 70px
    }
    .pd-sm-y-70-f {
        padding-top: 70px!important;
        padding-bottom: 70px!important
    }
    .pd-sm-x-70 {
        padding-left: 70px;
        padding-right: 70px
    }
    .pd-sm-x-70-f {
        padding-left: 70px!important;
        padding-right: 70px!important
    }
    .pd-sm-t-70 {
        padding-top: 70px
    }
    .pd-sm-r-70 {
        padding-right: 70px
    }
    .pd-sm-b-70 {
        padding-bottom: 70px
    }
    .pd-sm-l-70 {
        padding-left: 70px
    }
    .pd-sm-t-70-f {
        padding-top: 70px!important
    }
    .pd-sm-r-70-f {
        padding-right: 70px!important
    }
    .pd-sm-b-70-f {
        padding-bottom: 70px!important
    }
    .pd-sm-l-70-f {
        padding-left: 70px!important
    }
    .pd-sm-75 {
        padding: 75px
    }
    .pd-sm-75-f {
        padding: 75px!important
    }
    .pd-sm-y-75 {
        padding-top: 75px;
        padding-bottom: 75px
    }
    .pd-sm-y-75-f {
        padding-top: 75px!important;
        padding-bottom: 75px!important
    }
    .pd-sm-x-75 {
        padding-left: 75px;
        padding-right: 75px
    }
    .pd-sm-x-75-f {
        padding-left: 75px!important;
        padding-right: 75px!important
    }
    .pd-sm-t-75 {
        padding-top: 75px
    }
    .pd-sm-r-75 {
        padding-right: 75px
    }
    .pd-sm-b-75 {
        padding-bottom: 75px
    }
    .pd-sm-l-75 {
        padding-left: 75px
    }
    .pd-sm-t-75-f {
        padding-top: 75px!important
    }
    .pd-sm-r-75-f {
        padding-right: 75px!important
    }
    .pd-sm-b-75-f {
        padding-bottom: 75px!important
    }
    .pd-sm-l-75-f {
        padding-left: 75px!important
    }
    .pd-sm-80 {
        padding: 80px
    }
    .pd-sm-80-f {
        padding: 80px!important
    }
    .pd-sm-y-80 {
        padding-top: 80px;
        padding-bottom: 80px
    }
    .pd-sm-y-80-f {
        padding-top: 80px!important;
        padding-bottom: 80px!important
    }
    .pd-sm-x-80 {
        padding-left: 80px;
        padding-right: 80px
    }
    .pd-sm-x-80-f {
        padding-left: 80px!important;
        padding-right: 80px!important
    }
    .pd-sm-t-80 {
        padding-top: 80px
    }
    .pd-sm-r-80 {
        padding-right: 80px
    }
    .pd-sm-b-80 {
        padding-bottom: 80px
    }
    .pd-sm-l-80 {
        padding-left: 80px
    }
    .pd-sm-t-80-f {
        padding-top: 80px!important
    }
    .pd-sm-r-80-f {
        padding-right: 80px!important
    }
    .pd-sm-b-80-f {
        padding-bottom: 80px!important
    }
    .pd-sm-l-80-f {
        padding-left: 80px!important
    }
    .pd-sm-85 {
        padding: 85px
    }
    .pd-sm-85-f {
        padding: 85px!important
    }
    .pd-sm-y-85 {
        padding-top: 85px;
        padding-bottom: 85px
    }
    .pd-sm-y-85-f {
        padding-top: 85px!important;
        padding-bottom: 85px!important
    }
    .pd-sm-x-85 {
        padding-left: 85px;
        padding-right: 85px
    }
    .pd-sm-x-85-f {
        padding-left: 85px!important;
        padding-right: 85px!important
    }
    .pd-sm-t-85 {
        padding-top: 85px
    }
    .pd-sm-r-85 {
        padding-right: 85px
    }
    .pd-sm-b-85 {
        padding-bottom: 85px
    }
    .pd-sm-l-85 {
        padding-left: 85px
    }
    .pd-sm-t-85-f {
        padding-top: 85px!important
    }
    .pd-sm-r-85-f {
        padding-right: 85px!important
    }
    .pd-sm-b-85-f {
        padding-bottom: 85px!important
    }
    .pd-sm-l-85-f {
        padding-left: 85px!important
    }
    .pd-sm-90 {
        padding: 90px
    }
    .pd-sm-90-f {
        padding: 90px!important
    }
    .pd-sm-y-90 {
        padding-top: 90px;
        padding-bottom: 90px
    }
    .pd-sm-y-90-f {
        padding-top: 90px!important;
        padding-bottom: 90px!important
    }
    .pd-sm-x-90 {
        padding-left: 90px;
        padding-right: 90px
    }
    .pd-sm-x-90-f {
        padding-left: 90px!important;
        padding-right: 90px!important
    }
    .pd-sm-t-90 {
        padding-top: 90px
    }
    .pd-sm-r-90 {
        padding-right: 90px
    }
    .pd-sm-b-90 {
        padding-bottom: 90px
    }
    .pd-sm-l-90 {
        padding-left: 90px
    }
    .pd-sm-t-90-f {
        padding-top: 90px!important
    }
    .pd-sm-r-90-f {
        padding-right: 90px!important
    }
    .pd-sm-b-90-f {
        padding-bottom: 90px!important
    }
    .pd-sm-l-90-f {
        padding-left: 90px!important
    }
    .pd-sm-95 {
        padding: 95px
    }
    .pd-sm-95-f {
        padding: 95px!important
    }
    .pd-sm-y-95 {
        padding-top: 95px;
        padding-bottom: 95px
    }
    .pd-sm-y-95-f {
        padding-top: 95px!important;
        padding-bottom: 95px!important
    }
    .pd-sm-x-95 {
        padding-left: 95px;
        padding-right: 95px
    }
    .pd-sm-x-95-f {
        padding-left: 95px!important;
        padding-right: 95px!important
    }
    .pd-sm-t-95 {
        padding-top: 95px
    }
    .pd-sm-r-95 {
        padding-right: 95px
    }
    .pd-sm-b-95 {
        padding-bottom: 95px
    }
    .pd-sm-l-95 {
        padding-left: 95px
    }
    .pd-sm-t-95-f {
        padding-top: 95px!important
    }
    .pd-sm-r-95-f {
        padding-right: 95px!important
    }
    .pd-sm-b-95-f {
        padding-bottom: 95px!important
    }
    .pd-sm-l-95-f {
        padding-left: 95px!important
    }
    .pd-sm-100 {
        padding: 100px
    }
    .pd-sm-100-f {
        padding: 100px!important
    }
    .pd-sm-y-100 {
        padding-top: 100px;
        padding-bottom: 100px
    }
    .pd-sm-y-100-f {
        padding-top: 100px!important;
        padding-bottom: 100px!important
    }
    .pd-sm-x-100 {
        padding-left: 100px;
        padding-right: 100px
    }
    .pd-sm-x-100-f {
        padding-left: 100px!important;
        padding-right: 100px!important
    }
    .pd-sm-t-100 {
        padding-top: 100px
    }
    .pd-sm-r-100 {
        padding-right: 100px
    }
    .pd-sm-b-100 {
        padding-bottom: 100px
    }
    .pd-sm-l-100 {
        padding-left: 100px
    }
    .pd-sm-t-100-f {
        padding-top: 100px!important
    }
    .pd-sm-r-100-f {
        padding-right: 100px!important
    }
    .pd-sm-b-100-f {
        padding-bottom: 100px!important
    }
    .pd-sm-l-100-f {
        padding-left: 100px!important
    }
    .pd-sm-110 {
        padding: 110px
    }
    .pd-sm-110-f {
        padding: 110px!important
    }
    .pd-sm-y-110 {
        padding-top: 110px;
        padding-bottom: 110px
    }
    .pd-sm-y-110-f {
        padding-top: 110px!important;
        padding-bottom: 110px!important
    }
    .pd-sm-x-110 {
        padding-left: 110px;
        padding-right: 110px
    }
    .pd-sm-x-110-f {
        padding-left: 110px!important;
        padding-right: 110px!important
    }
    .pd-sm-t-110 {
        padding-top: 110px
    }
    .pd-sm-r-110 {
        padding-right: 110px
    }
    .pd-sm-b-110 {
        padding-bottom: 110px
    }
    .pd-sm-l-110 {
        padding-left: 110px
    }
    .pd-sm-t-110-f {
        padding-top: 110px!important
    }
    .pd-sm-r-110-f {
        padding-right: 110px!important
    }
    .pd-sm-b-110-f {
        padding-bottom: 110px!important
    }
    .pd-sm-l-110-f {
        padding-left: 110px!important
    }
    .pd-sm-120 {
        padding: 120px
    }
    .pd-sm-120-f {
        padding: 120px!important
    }
    .pd-sm-y-120 {
        padding-top: 120px;
        padding-bottom: 120px
    }
    .pd-sm-y-120-f {
        padding-top: 120px!important;
        padding-bottom: 120px!important
    }
    .pd-sm-x-120 {
        padding-left: 120px;
        padding-right: 120px
    }
    .pd-sm-x-120-f {
        padding-left: 120px!important;
        padding-right: 120px!important
    }
    .pd-sm-t-120 {
        padding-top: 120px
    }
    .pd-sm-r-120 {
        padding-right: 120px
    }
    .pd-sm-b-120 {
        padding-bottom: 120px
    }
    .pd-sm-l-120 {
        padding-left: 120px
    }
    .pd-sm-t-120-f {
        padding-top: 120px!important
    }
    .pd-sm-r-120-f {
        padding-right: 120px!important
    }
    .pd-sm-b-120-f {
        padding-bottom: 120px!important
    }
    .pd-sm-l-120-f {
        padding-left: 120px!important
    }
    .pd-sm-130 {
        padding: 130px
    }
    .pd-sm-130-f {
        padding: 130px!important
    }
    .pd-sm-y-130 {
        padding-top: 130px;
        padding-bottom: 130px
    }
    .pd-sm-y-130-f {
        padding-top: 130px!important;
        padding-bottom: 130px!important
    }
    .pd-sm-x-130 {
        padding-left: 130px;
        padding-right: 130px
    }
    .pd-sm-x-130-f {
        padding-left: 130px!important;
        padding-right: 130px!important
    }
    .pd-sm-t-130 {
        padding-top: 130px
    }
    .pd-sm-r-130 {
        padding-right: 130px
    }
    .pd-sm-b-130 {
        padding-bottom: 130px
    }
    .pd-sm-l-130 {
        padding-left: 130px
    }
    .pd-sm-t-130-f {
        padding-top: 130px!important
    }
    .pd-sm-r-130-f {
        padding-right: 130px!important
    }
    .pd-sm-b-130-f {
        padding-bottom: 130px!important
    }
    .pd-sm-l-130-f {
        padding-left: 130px!important
    }
    .pd-sm-140 {
        padding: 140px
    }
    .pd-sm-140-f {
        padding: 140px!important
    }
    .pd-sm-y-140 {
        padding-top: 140px;
        padding-bottom: 140px
    }
    .pd-sm-y-140-f {
        padding-top: 140px!important;
        padding-bottom: 140px!important
    }
    .pd-sm-x-140 {
        padding-left: 140px;
        padding-right: 140px
    }
    .pd-sm-x-140-f {
        padding-left: 140px!important;
        padding-right: 140px!important
    }
    .pd-sm-t-140 {
        padding-top: 140px
    }
    .pd-sm-r-140 {
        padding-right: 140px
    }
    .pd-sm-b-140 {
        padding-bottom: 140px
    }
    .pd-sm-l-140 {
        padding-left: 140px
    }
    .pd-sm-t-140-f {
        padding-top: 140px!important
    }
    .pd-sm-r-140-f {
        padding-right: 140px!important
    }
    .pd-sm-b-140-f {
        padding-bottom: 140px!important
    }
    .pd-sm-l-140-f {
        padding-left: 140px!important
    }
    .pd-sm-150 {
        padding: 150px
    }
    .pd-sm-150-f {
        padding: 150px!important
    }
    .pd-sm-y-150 {
        padding-top: 150px;
        padding-bottom: 150px
    }
    .pd-sm-y-150-f {
        padding-top: 150px!important;
        padding-bottom: 150px!important
    }
    .pd-sm-x-150 {
        padding-left: 150px;
        padding-right: 150px
    }
    .pd-sm-x-150-f {
        padding-left: 150px!important;
        padding-right: 150px!important
    }
    .pd-sm-t-150 {
        padding-top: 150px
    }
    .pd-sm-r-150 {
        padding-right: 150px
    }
    .pd-sm-b-150 {
        padding-bottom: 150px
    }
    .pd-sm-l-150 {
        padding-left: 150px
    }
    .pd-sm-t-150-f {
        padding-top: 150px!important
    }
    .pd-sm-r-150-f {
        padding-right: 150px!important
    }
    .pd-sm-b-150-f {
        padding-bottom: 150px!important
    }
    .pd-sm-l-150-f {
        padding-left: 150px!important
    }
    .pd-sm-160 {
        padding: 160px
    }
    .pd-sm-160-f {
        padding: 160px!important
    }
    .pd-sm-y-160 {
        padding-top: 160px;
        padding-bottom: 160px
    }
    .pd-sm-y-160-f {
        padding-top: 160px!important;
        padding-bottom: 160px!important
    }
    .pd-sm-x-160 {
        padding-left: 160px;
        padding-right: 160px
    }
    .pd-sm-x-160-f {
        padding-left: 160px!important;
        padding-right: 160px!important
    }
    .pd-sm-t-160 {
        padding-top: 160px
    }
    .pd-sm-r-160 {
        padding-right: 160px
    }
    .pd-sm-b-160 {
        padding-bottom: 160px
    }
    .pd-sm-l-160 {
        padding-left: 160px
    }
    .pd-sm-t-160-f {
        padding-top: 160px!important
    }
    .pd-sm-r-160-f {
        padding-right: 160px!important
    }
    .pd-sm-b-160-f {
        padding-bottom: 160px!important
    }
    .pd-sm-l-160-f {
        padding-left: 160px!important
    }
    .pd-sm-170 {
        padding: 170px
    }
    .pd-sm-170-f {
        padding: 170px!important
    }
    .pd-sm-y-170 {
        padding-top: 170px;
        padding-bottom: 170px
    }
    .pd-sm-y-170-f {
        padding-top: 170px!important;
        padding-bottom: 170px!important
    }
    .pd-sm-x-170 {
        padding-left: 170px;
        padding-right: 170px
    }
    .pd-sm-x-170-f {
        padding-left: 170px!important;
        padding-right: 170px!important
    }
    .pd-sm-t-170 {
        padding-top: 170px
    }
    .pd-sm-r-170 {
        padding-right: 170px
    }
    .pd-sm-b-170 {
        padding-bottom: 170px
    }
    .pd-sm-l-170 {
        padding-left: 170px
    }
    .pd-sm-t-170-f {
        padding-top: 170px!important
    }
    .pd-sm-r-170-f {
        padding-right: 170px!important
    }
    .pd-sm-b-170-f {
        padding-bottom: 170px!important
    }
    .pd-sm-l-170-f {
        padding-left: 170px!important
    }
    .pd-sm-180 {
        padding: 180px
    }
    .pd-sm-180-f {
        padding: 180px!important
    }
    .pd-sm-y-180 {
        padding-top: 180px;
        padding-bottom: 180px
    }
    .pd-sm-y-180-f {
        padding-top: 180px!important;
        padding-bottom: 180px!important
    }
    .pd-sm-x-180 {
        padding-left: 180px;
        padding-right: 180px
    }
    .pd-sm-x-180-f {
        padding-left: 180px!important;
        padding-right: 180px!important
    }
    .pd-sm-t-180 {
        padding-top: 180px
    }
    .pd-sm-r-180 {
        padding-right: 180px
    }
    .pd-sm-b-180 {
        padding-bottom: 180px
    }
    .pd-sm-l-180 {
        padding-left: 180px
    }
    .pd-sm-t-180-f {
        padding-top: 180px!important
    }
    .pd-sm-r-180-f {
        padding-right: 180px!important
    }
    .pd-sm-b-180-f {
        padding-bottom: 180px!important
    }
    .pd-sm-l-180-f {
        padding-left: 180px!important
    }
    .pd-sm-190 {
        padding: 190px
    }
    .pd-sm-190-f {
        padding: 190px!important
    }
    .pd-sm-y-190 {
        padding-top: 190px;
        padding-bottom: 190px
    }
    .pd-sm-y-190-f {
        padding-top: 190px!important;
        padding-bottom: 190px!important
    }
    .pd-sm-x-190 {
        padding-left: 190px;
        padding-right: 190px
    }
    .pd-sm-x-190-f {
        padding-left: 190px!important;
        padding-right: 190px!important
    }
    .pd-sm-t-190 {
        padding-top: 190px
    }
    .pd-sm-r-190 {
        padding-right: 190px
    }
    .pd-sm-b-190 {
        padding-bottom: 190px
    }
    .pd-sm-l-190 {
        padding-left: 190px
    }
    .pd-sm-t-190-f {
        padding-top: 190px!important
    }
    .pd-sm-r-190-f {
        padding-right: 190px!important
    }
    .pd-sm-b-190-f {
        padding-bottom: 190px!important
    }
    .pd-sm-l-190-f {
        padding-left: 190px!important
    }
    .pd-sm-200 {
        padding: 200px
    }
    .pd-sm-200-f {
        padding: 200px!important
    }
    .pd-sm-y-200 {
        padding-top: 200px;
        padding-bottom: 200px
    }
    .pd-sm-y-200-f {
        padding-top: 200px!important;
        padding-bottom: 200px!important
    }
    .pd-sm-x-200 {
        padding-left: 200px;
        padding-right: 200px
    }
    .pd-sm-x-200-f {
        padding-left: 200px!important;
        padding-right: 200px!important
    }
    .pd-sm-t-200 {
        padding-top: 200px
    }
    .pd-sm-r-200 {
        padding-right: 200px
    }
    .pd-sm-b-200 {
        padding-bottom: 200px
    }
    .pd-sm-l-200 {
        padding-left: 200px
    }
    .pd-sm-t-200-f {
        padding-top: 200px!important
    }
    .pd-sm-r-200-f {
        padding-right: 200px!important
    }
    .pd-sm-b-200-f {
        padding-bottom: 200px!important
    }
    .pd-sm-l-200-f {
        padding-left: 200px!important
    }
}

@media (min-width:768px) {
    .pd-md-0 {
        padding: 0
    }
    .pd-md-0-f {
        padding: 0!important
    }
    .pd-md-y-0 {
        padding-top: 0;
        padding-bottom: 0
    }
    .pd-md-y-0-f {
        padding-top: 0!important;
        padding-bottom: 0!important
    }
    .pd-md-x-0 {
        padding-left: 0;
        padding-right: 0
    }
    .pd-md-x-0-f {
        padding-left: 0!important;
        padding-right: 0!important
    }
    .pd-md-t-0 {
        padding-top: 0
    }
    .pd-md-r-0 {
        padding-right: 0
    }
    .pd-md-b-0 {
        padding-bottom: 0
    }
    .pd-md-l-0 {
        padding-left: 0
    }
    .pd-md-t-0-f {
        padding-top: 0!important
    }
    .pd-md-r-0-f {
        padding-right: 0!important
    }
    .pd-md-b-0-f {
        padding-bottom: 0!important
    }
    .pd-md-l-0-f {
        padding-left: 0!important
    }
    .pd-md-1 {
        padding: 1px
    }
    .pd-md-1-f {
        padding: 1px!important
    }
    .pd-md-y-1 {
        padding-top: 1px;
        padding-bottom: 1px
    }
    .pd-md-y-1-f {
        padding-top: 1px!important;
        padding-bottom: 1px!important
    }
    .pd-md-x-1 {
        padding-left: 1px;
        padding-right: 1px
    }
    .pd-md-x-1-f {
        padding-left: 1px!important;
        padding-right: 1px!important
    }
    .pd-md-t-1 {
        padding-top: 1px
    }
    .pd-md-r-1 {
        padding-right: 1px
    }
    .pd-md-b-1 {
        padding-bottom: 1px
    }
    .pd-md-l-1 {
        padding-left: 1px
    }
    .pd-md-t-1-f {
        padding-top: 1px!important
    }
    .pd-md-r-1-f {
        padding-right: 1px!important
    }
    .pd-md-b-1-f {
        padding-bottom: 1px!important
    }
    .pd-md-l-1-f {
        padding-left: 1px!important
    }
    .pd-md-2 {
        padding: 2px
    }
    .pd-md-2-f {
        padding: 2px!important
    }
    .pd-md-y-2 {
        padding-top: 2px;
        padding-bottom: 2px
    }
    .pd-md-y-2-f {
        padding-top: 2px!important;
        padding-bottom: 2px!important
    }
    .pd-md-x-2 {
        padding-left: 2px;
        padding-right: 2px
    }
    .pd-md-x-2-f {
        padding-left: 2px!important;
        padding-right: 2px!important
    }
    .pd-md-t-2 {
        padding-top: 2px
    }
    .pd-md-r-2 {
        padding-right: 2px
    }
    .pd-md-b-2 {
        padding-bottom: 2px
    }
    .pd-md-l-2 {
        padding-left: 2px
    }
    .pd-md-t-2-f {
        padding-top: 2px!important
    }
    .pd-md-r-2-f {
        padding-right: 2px!important
    }
    .pd-md-b-2-f {
        padding-bottom: 2px!important
    }
    .pd-md-l-2-f {
        padding-left: 2px!important
    }
    .pd-md-3 {
        padding: 3px
    }
    .pd-md-3-f {
        padding: 3px!important
    }
    .pd-md-y-3 {
        padding-top: 3px;
        padding-bottom: 3px
    }
    .pd-md-y-3-f {
        padding-top: 3px!important;
        padding-bottom: 3px!important
    }
    .pd-md-x-3 {
        padding-left: 3px;
        padding-right: 3px
    }
    .pd-md-x-3-f {
        padding-left: 3px!important;
        padding-right: 3px!important
    }
    .pd-md-t-3 {
        padding-top: 3px
    }
    .pd-md-r-3 {
        padding-right: 3px
    }
    .pd-md-b-3 {
        padding-bottom: 3px
    }
    .pd-md-l-3 {
        padding-left: 3px
    }
    .pd-md-t-3-f {
        padding-top: 3px!important
    }
    .pd-md-r-3-f {
        padding-right: 3px!important
    }
    .pd-md-b-3-f {
        padding-bottom: 3px!important
    }
    .pd-md-l-3-f {
        padding-left: 3px!important
    }
    .pd-md-4 {
        padding: 4px
    }
    .pd-md-4-f {
        padding: 4px!important
    }
    .pd-md-y-4 {
        padding-top: 4px;
        padding-bottom: 4px
    }
    .pd-md-y-4-f {
        padding-top: 4px!important;
        padding-bottom: 4px!important
    }
    .pd-md-x-4 {
        padding-left: 4px;
        padding-right: 4px
    }
    .pd-md-x-4-f {
        padding-left: 4px!important;
        padding-right: 4px!important
    }
    .pd-md-t-4 {
        padding-top: 4px
    }
    .pd-md-r-4 {
        padding-right: 4px
    }
    .pd-md-b-4 {
        padding-bottom: 4px
    }
    .pd-md-l-4 {
        padding-left: 4px
    }
    .pd-md-t-4-f {
        padding-top: 4px!important
    }
    .pd-md-r-4-f {
        padding-right: 4px!important
    }
    .pd-md-b-4-f {
        padding-bottom: 4px!important
    }
    .pd-md-l-4-f {
        padding-left: 4px!important
    }
    .pd-md-5 {
        padding: 5px
    }
    .pd-md-5-f {
        padding: 5px!important
    }
    .pd-md-y-5 {
        padding-top: 5px;
        padding-bottom: 5px
    }
    .pd-md-y-5-f {
        padding-top: 5px!important;
        padding-bottom: 5px!important
    }
    .pd-md-x-5 {
        padding-left: 5px;
        padding-right: 5px
    }
    .pd-md-x-5-f {
        padding-left: 5px!important;
        padding-right: 5px!important
    }
    .pd-md-t-5 {
        padding-top: 5px
    }
    .pd-md-r-5 {
        padding-right: 5px
    }
    .pd-md-b-5 {
        padding-bottom: 5px
    }
    .pd-md-l-5 {
        padding-left: 5px
    }
    .pd-md-t-5-f {
        padding-top: 5px!important
    }
    .pd-md-r-5-f {
        padding-right: 5px!important
    }
    .pd-md-b-5-f {
        padding-bottom: 5px!important
    }
    .pd-md-l-5-f {
        padding-left: 5px!important
    }
    .pd-md-6 {
        padding: 6px
    }
    .pd-md-6-f {
        padding: 6px!important
    }
    .pd-md-y-6 {
        padding-top: 6px;
        padding-bottom: 6px
    }
    .pd-md-y-6-f {
        padding-top: 6px!important;
        padding-bottom: 6px!important
    }
    .pd-md-x-6 {
        padding-left: 6px;
        padding-right: 6px
    }
    .pd-md-x-6-f {
        padding-left: 6px!important;
        padding-right: 6px!important
    }
    .pd-md-t-6 {
        padding-top: 6px
    }
    .pd-md-r-6 {
        padding-right: 6px
    }
    .pd-md-b-6 {
        padding-bottom: 6px
    }
    .pd-md-l-6 {
        padding-left: 6px
    }
    .pd-md-t-6-f {
        padding-top: 6px!important
    }
    .pd-md-r-6-f {
        padding-right: 6px!important
    }
    .pd-md-b-6-f {
        padding-bottom: 6px!important
    }
    .pd-md-l-6-f {
        padding-left: 6px!important
    }
    .pd-md-7 {
        padding: 7px
    }
    .pd-md-7-f {
        padding: 7px!important
    }
    .pd-md-y-7 {
        padding-top: 7px;
        padding-bottom: 7px
    }
    .pd-md-y-7-f {
        padding-top: 7px!important;
        padding-bottom: 7px!important
    }
    .pd-md-x-7 {
        padding-left: 7px;
        padding-right: 7px
    }
    .pd-md-x-7-f {
        padding-left: 7px!important;
        padding-right: 7px!important
    }
    .pd-md-t-7 {
        padding-top: 7px
    }
    .pd-md-r-7 {
        padding-right: 7px
    }
    .pd-md-b-7 {
        padding-bottom: 7px
    }
    .pd-md-l-7 {
        padding-left: 7px
    }
    .pd-md-t-7-f {
        padding-top: 7px!important
    }
    .pd-md-r-7-f {
        padding-right: 7px!important
    }
    .pd-md-b-7-f {
        padding-bottom: 7px!important
    }
    .pd-md-l-7-f {
        padding-left: 7px!important
    }
    .pd-md-8 {
        padding: 8px
    }
    .pd-md-8-f {
        padding: 8px!important
    }
    .pd-md-y-8 {
        padding-top: 8px;
        padding-bottom: 8px
    }
    .pd-md-y-8-f {
        padding-top: 8px!important;
        padding-bottom: 8px!important
    }
    .pd-md-x-8 {
        padding-left: 8px;
        padding-right: 8px
    }
    .pd-md-x-8-f {
        padding-left: 8px!important;
        padding-right: 8px!important
    }
    .pd-md-t-8 {
        padding-top: 8px
    }
    .pd-md-r-8 {
        padding-right: 8px
    }
    .pd-md-b-8 {
        padding-bottom: 8px
    }
    .pd-md-l-8 {
        padding-left: 8px
    }
    .pd-md-t-8-f {
        padding-top: 8px!important
    }
    .pd-md-r-8-f {
        padding-right: 8px!important
    }
    .pd-md-b-8-f {
        padding-bottom: 8px!important
    }
    .pd-md-l-8-f {
        padding-left: 8px!important
    }
    .pd-md-9 {
        padding: 9px
    }
    .pd-md-9-f {
        padding: 9px!important
    }
    .pd-md-y-9 {
        padding-top: 9px;
        padding-bottom: 9px
    }
    .pd-md-y-9-f {
        padding-top: 9px!important;
        padding-bottom: 9px!important
    }
    .pd-md-x-9 {
        padding-left: 9px;
        padding-right: 9px
    }
    .pd-md-x-9-f {
        padding-left: 9px!important;
        padding-right: 9px!important
    }
    .pd-md-t-9 {
        padding-top: 9px
    }
    .pd-md-r-9 {
        padding-right: 9px
    }
    .pd-md-b-9 {
        padding-bottom: 9px
    }
    .pd-md-l-9 {
        padding-left: 9px
    }
    .pd-md-t-9-f {
        padding-top: 9px!important
    }
    .pd-md-r-9-f {
        padding-right: 9px!important
    }
    .pd-md-b-9-f {
        padding-bottom: 9px!important
    }
    .pd-md-l-9-f {
        padding-left: 9px!important
    }
    .pd-md-10 {
        padding: 10px
    }
    .pd-md-10-f {
        padding: 10px!important
    }
    .pd-md-y-10 {
        padding-top: 10px;
        padding-bottom: 10px
    }
    .pd-md-y-10-f {
        padding-top: 10px!important;
        padding-bottom: 10px!important
    }
    .pd-md-x-10 {
        padding-left: 10px;
        padding-right: 10px
    }
    .pd-md-x-10-f {
        padding-left: 10px!important;
        padding-right: 10px!important
    }
    .pd-md-t-10 {
        padding-top: 10px
    }
    .pd-md-r-10 {
        padding-right: 10px
    }
    .pd-md-b-10 {
        padding-bottom: 10px
    }
    .pd-md-l-10 {
        padding-left: 10px
    }
    .pd-md-t-10-f {
        padding-top: 10px!important
    }
    .pd-md-r-10-f {
        padding-right: 10px!important
    }
    .pd-md-b-10-f {
        padding-bottom: 10px!important
    }
    .pd-md-l-10-f {
        padding-left: 10px!important
    }
    .pd-md-15 {
        padding: 15px
    }
    .pd-md-15-f {
        padding: 15px!important
    }
    .pd-md-y-15 {
        padding-top: 15px;
        padding-bottom: 15px
    }
    .pd-md-y-15-f {
        padding-top: 15px!important;
        padding-bottom: 15px!important
    }
    .pd-md-x-15 {
        padding-left: 15px;
        padding-right: 15px
    }
    .pd-md-x-15-f {
        padding-left: 15px!important;
        padding-right: 15px!important
    }
    .pd-md-t-15 {
        padding-top: 15px
    }
    .pd-md-r-15 {
        padding-right: 15px
    }
    .pd-md-b-15 {
        padding-bottom: 15px
    }
    .pd-md-l-15 {
        padding-left: 15px
    }
    .pd-md-t-15-f {
        padding-top: 15px!important
    }
    .pd-md-r-15-f {
        padding-right: 15px!important
    }
    .pd-md-b-15-f {
        padding-bottom: 15px!important
    }
    .pd-md-l-15-f {
        padding-left: 15px!important
    }
    .pd-md-20 {
        padding: 20px
    }
    .pd-md-20-f {
        padding: 20px!important
    }
    .pd-md-y-20 {
        padding-top: 20px;
        padding-bottom: 20px
    }
    .pd-md-y-20-f {
        padding-top: 20px!important;
        padding-bottom: 20px!important
    }
    .pd-md-x-20 {
        padding-left: 20px;
        padding-right: 20px
    }
    .pd-md-x-20-f {
        padding-left: 20px!important;
        padding-right: 20px!important
    }
    .pd-md-t-20 {
        padding-top: 20px
    }
    .pd-md-r-20 {
        padding-right: 20px
    }
    .pd-md-b-20 {
        padding-bottom: 20px
    }
    .pd-md-l-20 {
        padding-left: 20px
    }
    .pd-md-t-20-f {
        padding-top: 20px!important
    }
    .pd-md-r-20-f {
        padding-right: 20px!important
    }
    .pd-md-b-20-f {
        padding-bottom: 20px!important
    }
    .pd-md-l-20-f {
        padding-left: 20px!important
    }
    .pd-md-25 {
        padding: 25px
    }
    .pd-md-25-f {
        padding: 25px!important
    }
    .pd-md-y-25 {
        padding-top: 25px;
        padding-bottom: 25px
    }
    .pd-md-y-25-f {
        padding-top: 25px!important;
        padding-bottom: 25px!important
    }
    .pd-md-x-25 {
        padding-left: 25px;
        padding-right: 25px
    }
    .pd-md-x-25-f {
        padding-left: 25px!important;
        padding-right: 25px!important
    }
    .pd-md-t-25 {
        padding-top: 25px
    }
    .pd-md-r-25 {
        padding-right: 25px
    }
    .pd-md-b-25 {
        padding-bottom: 25px
    }
    .pd-md-l-25 {
        padding-left: 25px
    }
    .pd-md-t-25-f {
        padding-top: 25px!important
    }
    .pd-md-r-25-f {
        padding-right: 25px!important
    }
    .pd-md-b-25-f {
        padding-bottom: 25px!important
    }
    .pd-md-l-25-f {
        padding-left: 25px!important
    }
    .pd-md-30 {
        padding: 30px
    }
    .pd-md-30-f {
        padding: 30px!important
    }
    .pd-md-y-30 {
        padding-top: 30px;
        padding-bottom: 30px
    }
    .pd-md-y-30-f {
        padding-top: 30px!important;
        padding-bottom: 30px!important
    }
    .pd-md-x-30 {
        padding-left: 30px;
        padding-right: 30px
    }
    .pd-md-x-30-f {
        padding-left: 30px!important;
        padding-right: 30px!important
    }
    .pd-md-t-30 {
        padding-top: 30px
    }
    .pd-md-r-30 {
        padding-right: 30px
    }
    .pd-md-b-30 {
        padding-bottom: 30px
    }
    .pd-md-l-30 {
        padding-left: 30px
    }
    .pd-md-t-30-f {
        padding-top: 30px!important
    }
    .pd-md-r-30-f {
        padding-right: 30px!important
    }
    .pd-md-b-30-f {
        padding-bottom: 30px!important
    }
    .pd-md-l-30-f {
        padding-left: 30px!important
    }
    .pd-md-35 {
        padding: 35px
    }
    .pd-md-35-f {
        padding: 35px!important
    }
    .pd-md-y-35 {
        padding-top: 35px;
        padding-bottom: 35px
    }
    .pd-md-y-35-f {
        padding-top: 35px!important;
        padding-bottom: 35px!important
    }
    .pd-md-x-35 {
        padding-left: 35px;
        padding-right: 35px
    }
    .pd-md-x-35-f {
        padding-left: 35px!important;
        padding-right: 35px!important
    }
    .pd-md-t-35 {
        padding-top: 35px
    }
    .pd-md-r-35 {
        padding-right: 35px
    }
    .pd-md-b-35 {
        padding-bottom: 35px
    }
    .pd-md-l-35 {
        padding-left: 35px
    }
    .pd-md-t-35-f {
        padding-top: 35px!important
    }
    .pd-md-r-35-f {
        padding-right: 35px!important
    }
    .pd-md-b-35-f {
        padding-bottom: 35px!important
    }
    .pd-md-l-35-f {
        padding-left: 35px!important
    }
    .pd-md-40 {
        padding: 40px
    }
    .pd-md-40-f {
        padding: 40px!important
    }
    .pd-md-y-40 {
        padding-top: 40px;
        padding-bottom: 40px
    }
    .pd-md-y-40-f {
        padding-top: 40px!important;
        padding-bottom: 40px!important
    }
    .pd-md-x-40 {
        padding-left: 40px;
        padding-right: 40px
    }
    .pd-md-x-40-f {
        padding-left: 40px!important;
        padding-right: 40px!important
    }
    .pd-md-t-40 {
        padding-top: 40px
    }
    .pd-md-r-40 {
        padding-right: 40px
    }
    .pd-md-b-40 {
        padding-bottom: 40px
    }
    .pd-md-l-40 {
        padding-left: 40px
    }
    .pd-md-t-40-f {
        padding-top: 40px!important
    }
    .pd-md-r-40-f {
        padding-right: 40px!important
    }
    .pd-md-b-40-f {
        padding-bottom: 40px!important
    }
    .pd-md-l-40-f {
        padding-left: 40px!important
    }
    .pd-md-45 {
        padding: 45px
    }
    .pd-md-45-f {
        padding: 45px!important
    }
    .pd-md-y-45 {
        padding-top: 45px;
        padding-bottom: 45px
    }
    .pd-md-y-45-f {
        padding-top: 45px!important;
        padding-bottom: 45px!important
    }
    .pd-md-x-45 {
        padding-left: 45px;
        padding-right: 45px
    }
    .pd-md-x-45-f {
        padding-left: 45px!important;
        padding-right: 45px!important
    }
    .pd-md-t-45 {
        padding-top: 45px
    }
    .pd-md-r-45 {
        padding-right: 45px
    }
    .pd-md-b-45 {
        padding-bottom: 45px
    }
    .pd-md-l-45 {
        padding-left: 45px
    }
    .pd-md-t-45-f {
        padding-top: 45px!important
    }
    .pd-md-r-45-f {
        padding-right: 45px!important
    }
    .pd-md-b-45-f {
        padding-bottom: 45px!important
    }
    .pd-md-l-45-f {
        padding-left: 45px!important
    }
    .pd-md-50 {
        padding: 50px
    }
    .pd-md-50-f {
        padding: 50px!important
    }
    .pd-md-y-50 {
        padding-top: 50px;
        padding-bottom: 50px
    }
    .pd-md-y-50-f {
        padding-top: 50px!important;
        padding-bottom: 50px!important
    }
    .pd-md-x-50 {
        padding-left: 50px;
        padding-right: 50px
    }
    .pd-md-x-50-f {
        padding-left: 50px!important;
        padding-right: 50px!important
    }
    .pd-md-t-50 {
        padding-top: 50px
    }
    .pd-md-r-50 {
        padding-right: 50px
    }
    .pd-md-b-50 {
        padding-bottom: 50px
    }
    .pd-md-l-50 {
        padding-left: 50px
    }
    .pd-md-t-50-f {
        padding-top: 50px!important
    }
    .pd-md-r-50-f {
        padding-right: 50px!important
    }
    .pd-md-b-50-f {
        padding-bottom: 50px!important
    }
    .pd-md-l-50-f {
        padding-left: 50px!important
    }
    .pd-md-55 {
        padding: 55px
    }
    .pd-md-55-f {
        padding: 55px!important
    }
    .pd-md-y-55 {
        padding-top: 55px;
        padding-bottom: 55px
    }
    .pd-md-y-55-f {
        padding-top: 55px!important;
        padding-bottom: 55px!important
    }
    .pd-md-x-55 {
        padding-left: 55px;
        padding-right: 55px
    }
    .pd-md-x-55-f {
        padding-left: 55px!important;
        padding-right: 55px!important
    }
    .pd-md-t-55 {
        padding-top: 55px
    }
    .pd-md-r-55 {
        padding-right: 55px
    }
    .pd-md-b-55 {
        padding-bottom: 55px
    }
    .pd-md-l-55 {
        padding-left: 55px
    }
    .pd-md-t-55-f {
        padding-top: 55px!important
    }
    .pd-md-r-55-f {
        padding-right: 55px!important
    }
    .pd-md-b-55-f {
        padding-bottom: 55px!important
    }
    .pd-md-l-55-f {
        padding-left: 55px!important
    }
    .pd-md-60 {
        padding: 60px
    }
    .pd-md-60-f {
        padding: 60px!important
    }
    .pd-md-y-60 {
        padding-top: 60px;
        padding-bottom: 60px
    }
    .pd-md-y-60-f {
        padding-top: 60px!important;
        padding-bottom: 60px!important
    }
    .pd-md-x-60 {
        padding-left: 60px;
        padding-right: 60px
    }
    .pd-md-x-60-f {
        padding-left: 60px!important;
        padding-right: 60px!important
    }
    .pd-md-t-60 {
        padding-top: 60px
    }
    .pd-md-r-60 {
        padding-right: 60px
    }
    .pd-md-b-60 {
        padding-bottom: 60px
    }
    .pd-md-l-60 {
        padding-left: 60px
    }
    .pd-md-t-60-f {
        padding-top: 60px!important
    }
    .pd-md-r-60-f {
        padding-right: 60px!important
    }
    .pd-md-b-60-f {
        padding-bottom: 60px!important
    }
    .pd-md-l-60-f {
        padding-left: 60px!important
    }
    .pd-md-65 {
        padding: 65px
    }
    .pd-md-65-f {
        padding: 65px!important
    }
    .pd-md-y-65 {
        padding-top: 65px;
        padding-bottom: 65px
    }
    .pd-md-y-65-f {
        padding-top: 65px!important;
        padding-bottom: 65px!important
    }
    .pd-md-x-65 {
        padding-left: 65px;
        padding-right: 65px
    }
    .pd-md-x-65-f {
        padding-left: 65px!important;
        padding-right: 65px!important
    }
    .pd-md-t-65 {
        padding-top: 65px
    }
    .pd-md-r-65 {
        padding-right: 65px
    }
    .pd-md-b-65 {
        padding-bottom: 65px
    }
    .pd-md-l-65 {
        padding-left: 65px
    }
    .pd-md-t-65-f {
        padding-top: 65px!important
    }
    .pd-md-r-65-f {
        padding-right: 65px!important
    }
    .pd-md-b-65-f {
        padding-bottom: 65px!important
    }
    .pd-md-l-65-f {
        padding-left: 65px!important
    }
    .pd-md-70 {
        padding: 70px
    }
    .pd-md-70-f {
        padding: 70px!important
    }
    .pd-md-y-70 {
        padding-top: 70px;
        padding-bottom: 70px
    }
    .pd-md-y-70-f {
        padding-top: 70px!important;
        padding-bottom: 70px!important
    }
    .pd-md-x-70 {
        padding-left: 70px;
        padding-right: 70px
    }
    .pd-md-x-70-f {
        padding-left: 70px!important;
        padding-right: 70px!important
    }
    .pd-md-t-70 {
        padding-top: 70px
    }
    .pd-md-r-70 {
        padding-right: 70px
    }
    .pd-md-b-70 {
        padding-bottom: 70px
    }
    .pd-md-l-70 {
        padding-left: 70px
    }
    .pd-md-t-70-f {
        padding-top: 70px!important
    }
    .pd-md-r-70-f {
        padding-right: 70px!important
    }
    .pd-md-b-70-f {
        padding-bottom: 70px!important
    }
    .pd-md-l-70-f {
        padding-left: 70px!important
    }
    .pd-md-75 {
        padding: 75px
    }
    .pd-md-75-f {
        padding: 75px!important
    }
    .pd-md-y-75 {
        padding-top: 75px;
        padding-bottom: 75px
    }
    .pd-md-y-75-f {
        padding-top: 75px!important;
        padding-bottom: 75px!important
    }
    .pd-md-x-75 {
        padding-left: 75px;
        padding-right: 75px
    }
    .pd-md-x-75-f {
        padding-left: 75px!important;
        padding-right: 75px!important
    }
    .pd-md-t-75 {
        padding-top: 75px
    }
    .pd-md-r-75 {
        padding-right: 75px
    }
    .pd-md-b-75 {
        padding-bottom: 75px
    }
    .pd-md-l-75 {
        padding-left: 75px
    }
    .pd-md-t-75-f {
        padding-top: 75px!important
    }
    .pd-md-r-75-f {
        padding-right: 75px!important
    }
    .pd-md-b-75-f {
        padding-bottom: 75px!important
    }
    .pd-md-l-75-f {
        padding-left: 75px!important
    }
    .pd-md-80 {
        padding: 80px
    }
    .pd-md-80-f {
        padding: 80px!important
    }
    .pd-md-y-80 {
        padding-top: 80px;
        padding-bottom: 80px
    }
    .pd-md-y-80-f {
        padding-top: 80px!important;
        padding-bottom: 80px!important
    }
    .pd-md-x-80 {
        padding-left: 80px;
        padding-right: 80px
    }
    .pd-md-x-80-f {
        padding-left: 80px!important;
        padding-right: 80px!important
    }
    .pd-md-t-80 {
        padding-top: 80px
    }
    .pd-md-r-80 {
        padding-right: 80px
    }
    .pd-md-b-80 {
        padding-bottom: 80px
    }
    .pd-md-l-80 {
        padding-left: 80px
    }
    .pd-md-t-80-f {
        padding-top: 80px!important
    }
    .pd-md-r-80-f {
        padding-right: 80px!important
    }
    .pd-md-b-80-f {
        padding-bottom: 80px!important
    }
    .pd-md-l-80-f {
        padding-left: 80px!important
    }
    .pd-md-85 {
        padding: 85px
    }
    .pd-md-85-f {
        padding: 85px!important
    }
    .pd-md-y-85 {
        padding-top: 85px;
        padding-bottom: 85px
    }
    .pd-md-y-85-f {
        padding-top: 85px!important;
        padding-bottom: 85px!important
    }
    .pd-md-x-85 {
        padding-left: 85px;
        padding-right: 85px
    }
    .pd-md-x-85-f {
        padding-left: 85px!important;
        padding-right: 85px!important
    }
    .pd-md-t-85 {
        padding-top: 85px
    }
    .pd-md-r-85 {
        padding-right: 85px
    }
    .pd-md-b-85 {
        padding-bottom: 85px
    }
    .pd-md-l-85 {
        padding-left: 85px
    }
    .pd-md-t-85-f {
        padding-top: 85px!important
    }
    .pd-md-r-85-f {
        padding-right: 85px!important
    }
    .pd-md-b-85-f {
        padding-bottom: 85px!important
    }
    .pd-md-l-85-f {
        padding-left: 85px!important
    }
    .pd-md-90 {
        padding: 90px
    }
    .pd-md-90-f {
        padding: 90px!important
    }
    .pd-md-y-90 {
        padding-top: 90px;
        padding-bottom: 90px
    }
    .pd-md-y-90-f {
        padding-top: 90px!important;
        padding-bottom: 90px!important
    }
    .pd-md-x-90 {
        padding-left: 90px;
        padding-right: 90px
    }
    .pd-md-x-90-f {
        padding-left: 90px!important;
        padding-right: 90px!important
    }
    .pd-md-t-90 {
        padding-top: 90px
    }
    .pd-md-r-90 {
        padding-right: 90px
    }
    .pd-md-b-90 {
        padding-bottom: 90px
    }
    .pd-md-l-90 {
        padding-left: 90px
    }
    .pd-md-t-90-f {
        padding-top: 90px!important
    }
    .pd-md-r-90-f {
        padding-right: 90px!important
    }
    .pd-md-b-90-f {
        padding-bottom: 90px!important
    }
    .pd-md-l-90-f {
        padding-left: 90px!important
    }
    .pd-md-95 {
        padding: 95px
    }
    .pd-md-95-f {
        padding: 95px!important
    }
    .pd-md-y-95 {
        padding-top: 95px;
        padding-bottom: 95px
    }
    .pd-md-y-95-f {
        padding-top: 95px!important;
        padding-bottom: 95px!important
    }
    .pd-md-x-95 {
        padding-left: 95px;
        padding-right: 95px
    }
    .pd-md-x-95-f {
        padding-left: 95px!important;
        padding-right: 95px!important
    }
    .pd-md-t-95 {
        padding-top: 95px
    }
    .pd-md-r-95 {
        padding-right: 95px
    }
    .pd-md-b-95 {
        padding-bottom: 95px
    }
    .pd-md-l-95 {
        padding-left: 95px
    }
    .pd-md-t-95-f {
        padding-top: 95px!important
    }
    .pd-md-r-95-f {
        padding-right: 95px!important
    }
    .pd-md-b-95-f {
        padding-bottom: 95px!important
    }
    .pd-md-l-95-f {
        padding-left: 95px!important
    }
    .pd-md-100 {
        padding: 100px
    }
    .pd-md-100-f {
        padding: 100px!important
    }
    .pd-md-y-100 {
        padding-top: 100px;
        padding-bottom: 100px
    }
    .pd-md-y-100-f {
        padding-top: 100px!important;
        padding-bottom: 100px!important
    }
    .pd-md-x-100 {
        padding-left: 100px;
        padding-right: 100px
    }
    .pd-md-x-100-f {
        padding-left: 100px!important;
        padding-right: 100px!important
    }
    .pd-md-t-100 {
        padding-top: 100px
    }
    .pd-md-r-100 {
        padding-right: 100px
    }
    .pd-md-b-100 {
        padding-bottom: 100px
    }
    .pd-md-l-100 {
        padding-left: 100px
    }
    .pd-md-t-100-f {
        padding-top: 100px!important
    }
    .pd-md-r-100-f {
        padding-right: 100px!important
    }
    .pd-md-b-100-f {
        padding-bottom: 100px!important
    }
    .pd-md-l-100-f {
        padding-left: 100px!important
    }
    .pd-md-110 {
        padding: 110px
    }
    .pd-md-110-f {
        padding: 110px!important
    }
    .pd-md-y-110 {
        padding-top: 110px;
        padding-bottom: 110px
    }
    .pd-md-y-110-f {
        padding-top: 110px!important;
        padding-bottom: 110px!important
    }
    .pd-md-x-110 {
        padding-left: 110px;
        padding-right: 110px
    }
    .pd-md-x-110-f {
        padding-left: 110px!important;
        padding-right: 110px!important
    }
    .pd-md-t-110 {
        padding-top: 110px
    }
    .pd-md-r-110 {
        padding-right: 110px
    }
    .pd-md-b-110 {
        padding-bottom: 110px
    }
    .pd-md-l-110 {
        padding-left: 110px
    }
    .pd-md-t-110-f {
        padding-top: 110px!important
    }
    .pd-md-r-110-f {
        padding-right: 110px!important
    }
    .pd-md-b-110-f {
        padding-bottom: 110px!important
    }
    .pd-md-l-110-f {
        padding-left: 110px!important
    }
    .pd-md-120 {
        padding: 120px
    }
    .pd-md-120-f {
        padding: 120px!important
    }
    .pd-md-y-120 {
        padding-top: 120px;
        padding-bottom: 120px
    }
    .pd-md-y-120-f {
        padding-top: 120px!important;
        padding-bottom: 120px!important
    }
    .pd-md-x-120 {
        padding-left: 120px;
        padding-right: 120px
    }
    .pd-md-x-120-f {
        padding-left: 120px!important;
        padding-right: 120px!important
    }
    .pd-md-t-120 {
        padding-top: 120px
    }
    .pd-md-r-120 {
        padding-right: 120px
    }
    .pd-md-b-120 {
        padding-bottom: 120px
    }
    .pd-md-l-120 {
        padding-left: 120px
    }
    .pd-md-t-120-f {
        padding-top: 120px!important
    }
    .pd-md-r-120-f {
        padding-right: 120px!important
    }
    .pd-md-b-120-f {
        padding-bottom: 120px!important
    }
    .pd-md-l-120-f {
        padding-left: 120px!important
    }
    .pd-md-130 {
        padding: 130px
    }
    .pd-md-130-f {
        padding: 130px!important
    }
    .pd-md-y-130 {
        padding-top: 130px;
        padding-bottom: 130px
    }
    .pd-md-y-130-f {
        padding-top: 130px!important;
        padding-bottom: 130px!important
    }
    .pd-md-x-130 {
        padding-left: 130px;
        padding-right: 130px
    }
    .pd-md-x-130-f {
        padding-left: 130px!important;
        padding-right: 130px!important
    }
    .pd-md-t-130 {
        padding-top: 130px
    }
    .pd-md-r-130 {
        padding-right: 130px
    }
    .pd-md-b-130 {
        padding-bottom: 130px
    }
    .pd-md-l-130 {
        padding-left: 130px
    }
    .pd-md-t-130-f {
        padding-top: 130px!important
    }
    .pd-md-r-130-f {
        padding-right: 130px!important
    }
    .pd-md-b-130-f {
        padding-bottom: 130px!important
    }
    .pd-md-l-130-f {
        padding-left: 130px!important
    }
    .pd-md-140 {
        padding: 140px
    }
    .pd-md-140-f {
        padding: 140px!important
    }
    .pd-md-y-140 {
        padding-top: 140px;
        padding-bottom: 140px
    }
    .pd-md-y-140-f {
        padding-top: 140px!important;
        padding-bottom: 140px!important
    }
    .pd-md-x-140 {
        padding-left: 140px;
        padding-right: 140px
    }
    .pd-md-x-140-f {
        padding-left: 140px!important;
        padding-right: 140px!important
    }
    .pd-md-t-140 {
        padding-top: 140px
    }
    .pd-md-r-140 {
        padding-right: 140px
    }
    .pd-md-b-140 {
        padding-bottom: 140px
    }
    .pd-md-l-140 {
        padding-left: 140px
    }
    .pd-md-t-140-f {
        padding-top: 140px!important
    }
    .pd-md-r-140-f {
        padding-right: 140px!important
    }
    .pd-md-b-140-f {
        padding-bottom: 140px!important
    }
    .pd-md-l-140-f {
        padding-left: 140px!important
    }
    .pd-md-150 {
        padding: 150px
    }
    .pd-md-150-f {
        padding: 150px!important
    }
    .pd-md-y-150 {
        padding-top: 150px;
        padding-bottom: 150px
    }
    .pd-md-y-150-f {
        padding-top: 150px!important;
        padding-bottom: 150px!important
    }
    .pd-md-x-150 {
        padding-left: 150px;
        padding-right: 150px
    }
    .pd-md-x-150-f {
        padding-left: 150px!important;
        padding-right: 150px!important
    }
    .pd-md-t-150 {
        padding-top: 150px
    }
    .pd-md-r-150 {
        padding-right: 150px
    }
    .pd-md-b-150 {
        padding-bottom: 150px
    }
    .pd-md-l-150 {
        padding-left: 150px
    }
    .pd-md-t-150-f {
        padding-top: 150px!important
    }
    .pd-md-r-150-f {
        padding-right: 150px!important
    }
    .pd-md-b-150-f {
        padding-bottom: 150px!important
    }
    .pd-md-l-150-f {
        padding-left: 150px!important
    }
    .pd-md-160 {
        padding: 160px
    }
    .pd-md-160-f {
        padding: 160px!important
    }
    .pd-md-y-160 {
        padding-top: 160px;
        padding-bottom: 160px
    }
    .pd-md-y-160-f {
        padding-top: 160px!important;
        padding-bottom: 160px!important
    }
    .pd-md-x-160 {
        padding-left: 160px;
        padding-right: 160px
    }
    .pd-md-x-160-f {
        padding-left: 160px!important;
        padding-right: 160px!important
    }
    .pd-md-t-160 {
        padding-top: 160px
    }
    .pd-md-r-160 {
        padding-right: 160px
    }
    .pd-md-b-160 {
        padding-bottom: 160px
    }
    .pd-md-l-160 {
        padding-left: 160px
    }
    .pd-md-t-160-f {
        padding-top: 160px!important
    }
    .pd-md-r-160-f {
        padding-right: 160px!important
    }
    .pd-md-b-160-f {
        padding-bottom: 160px!important
    }
    .pd-md-l-160-f {
        padding-left: 160px!important
    }
    .pd-md-170 {
        padding: 170px
    }
    .pd-md-170-f {
        padding: 170px!important
    }
    .pd-md-y-170 {
        padding-top: 170px;
        padding-bottom: 170px
    }
    .pd-md-y-170-f {
        padding-top: 170px!important;
        padding-bottom: 170px!important
    }
    .pd-md-x-170 {
        padding-left: 170px;
        padding-right: 170px
    }
    .pd-md-x-170-f {
        padding-left: 170px!important;
        padding-right: 170px!important
    }
    .pd-md-t-170 {
        padding-top: 170px
    }
    .pd-md-r-170 {
        padding-right: 170px
    }
    .pd-md-b-170 {
        padding-bottom: 170px
    }
    .pd-md-l-170 {
        padding-left: 170px
    }
    .pd-md-t-170-f {
        padding-top: 170px!important
    }
    .pd-md-r-170-f {
        padding-right: 170px!important
    }
    .pd-md-b-170-f {
        padding-bottom: 170px!important
    }
    .pd-md-l-170-f {
        padding-left: 170px!important
    }
    .pd-md-180 {
        padding: 180px
    }
    .pd-md-180-f {
        padding: 180px!important
    }
    .pd-md-y-180 {
        padding-top: 180px;
        padding-bottom: 180px
    }
    .pd-md-y-180-f {
        padding-top: 180px!important;
        padding-bottom: 180px!important
    }
    .pd-md-x-180 {
        padding-left: 180px;
        padding-right: 180px
    }
    .pd-md-x-180-f {
        padding-left: 180px!important;
        padding-right: 180px!important
    }
    .pd-md-t-180 {
        padding-top: 180px
    }
    .pd-md-r-180 {
        padding-right: 180px
    }
    .pd-md-b-180 {
        padding-bottom: 180px
    }
    .pd-md-l-180 {
        padding-left: 180px
    }
    .pd-md-t-180-f {
        padding-top: 180px!important
    }
    .pd-md-r-180-f {
        padding-right: 180px!important
    }
    .pd-md-b-180-f {
        padding-bottom: 180px!important
    }
    .pd-md-l-180-f {
        padding-left: 180px!important
    }
    .pd-md-190 {
        padding: 190px
    }
    .pd-md-190-f {
        padding: 190px!important
    }
    .pd-md-y-190 {
        padding-top: 190px;
        padding-bottom: 190px
    }
    .pd-md-y-190-f {
        padding-top: 190px!important;
        padding-bottom: 190px!important
    }
    .pd-md-x-190 {
        padding-left: 190px;
        padding-right: 190px
    }
    .pd-md-x-190-f {
        padding-left: 190px!important;
        padding-right: 190px!important
    }
    .pd-md-t-190 {
        padding-top: 190px
    }
    .pd-md-r-190 {
        padding-right: 190px
    }
    .pd-md-b-190 {
        padding-bottom: 190px
    }
    .pd-md-l-190 {
        padding-left: 190px
    }
    .pd-md-t-190-f {
        padding-top: 190px!important
    }
    .pd-md-r-190-f {
        padding-right: 190px!important
    }
    .pd-md-b-190-f {
        padding-bottom: 190px!important
    }
    .pd-md-l-190-f {
        padding-left: 190px!important
    }
    .pd-md-200 {
        padding: 200px
    }
    .pd-md-200-f {
        padding: 200px!important
    }
    .pd-md-y-200 {
        padding-top: 200px;
        padding-bottom: 200px
    }
    .pd-md-y-200-f {
        padding-top: 200px!important;
        padding-bottom: 200px!important
    }
    .pd-md-x-200 {
        padding-left: 200px;
        padding-right: 200px
    }
    .pd-md-x-200-f {
        padding-left: 200px!important;
        padding-right: 200px!important
    }
    .pd-md-t-200 {
        padding-top: 200px
    }
    .pd-md-r-200 {
        padding-right: 200px
    }
    .pd-md-b-200 {
        padding-bottom: 200px
    }
    .pd-md-l-200 {
        padding-left: 200px
    }
    .pd-md-t-200-f {
        padding-top: 200px!important
    }
    .pd-md-r-200-f {
        padding-right: 200px!important
    }
    .pd-md-b-200-f {
        padding-bottom: 200px!important
    }
    .pd-md-l-200-f {
        padding-left: 200px!important
    }
}

@media (min-width:992px) {
    .pd-lg-0 {
        padding: 0
    }
    .pd-lg-0-f {
        padding: 0!important
    }
    .pd-lg-y-0 {
        padding-top: 0;
        padding-bottom: 0
    }
    .pd-lg-y-0-f {
        padding-top: 0!important;
        padding-bottom: 0!important
    }
    .pd-lg-x-0 {
        padding-left: 0;
        padding-right: 0
    }
    .pd-lg-x-0-f {
        padding-left: 0!important;
        padding-right: 0!important
    }
    .pd-lg-t-0 {
        padding-top: 0
    }
    .pd-lg-r-0 {
        padding-right: 0
    }
    .pd-lg-b-0 {
        padding-bottom: 0
    }
    .pd-lg-l-0 {
        padding-left: 0
    }
    .pd-lg-t-0-f {
        padding-top: 0!important
    }
    .pd-lg-r-0-f {
        padding-right: 0!important
    }
    .pd-lg-b-0-f {
        padding-bottom: 0!important
    }
    .pd-lg-l-0-f {
        padding-left: 0!important
    }
    .pd-lg-1 {
        padding: 1px
    }
    .pd-lg-1-f {
        padding: 1px!important
    }
    .pd-lg-y-1 {
        padding-top: 1px;
        padding-bottom: 1px
    }
    .pd-lg-y-1-f {
        padding-top: 1px!important;
        padding-bottom: 1px!important
    }
    .pd-lg-x-1 {
        padding-left: 1px;
        padding-right: 1px
    }
    .pd-lg-x-1-f {
        padding-left: 1px!important;
        padding-right: 1px!important
    }
    .pd-lg-t-1 {
        padding-top: 1px
    }
    .pd-lg-r-1 {
        padding-right: 1px
    }
    .pd-lg-b-1 {
        padding-bottom: 1px
    }
    .pd-lg-l-1 {
        padding-left: 1px
    }
    .pd-lg-t-1-f {
        padding-top: 1px!important
    }
    .pd-lg-r-1-f {
        padding-right: 1px!important
    }
    .pd-lg-b-1-f {
        padding-bottom: 1px!important
    }
    .pd-lg-l-1-f {
        padding-left: 1px!important
    }
    .pd-lg-2 {
        padding: 2px
    }
    .pd-lg-2-f {
        padding: 2px!important
    }
    .pd-lg-y-2 {
        padding-top: 2px;
        padding-bottom: 2px
    }
    .pd-lg-y-2-f {
        padding-top: 2px!important;
        padding-bottom: 2px!important
    }
    .pd-lg-x-2 {
        padding-left: 2px;
        padding-right: 2px
    }
    .pd-lg-x-2-f {
        padding-left: 2px!important;
        padding-right: 2px!important
    }
    .pd-lg-t-2 {
        padding-top: 2px
    }
    .pd-lg-r-2 {
        padding-right: 2px
    }
    .pd-lg-b-2 {
        padding-bottom: 2px
    }
    .pd-lg-l-2 {
        padding-left: 2px
    }
    .pd-lg-t-2-f {
        padding-top: 2px!important
    }
    .pd-lg-r-2-f {
        padding-right: 2px!important
    }
    .pd-lg-b-2-f {
        padding-bottom: 2px!important
    }
    .pd-lg-l-2-f {
        padding-left: 2px!important
    }
    .pd-lg-3 {
        padding: 3px
    }
    .pd-lg-3-f {
        padding: 3px!important
    }
    .pd-lg-y-3 {
        padding-top: 3px;
        padding-bottom: 3px
    }
    .pd-lg-y-3-f {
        padding-top: 3px!important;
        padding-bottom: 3px!important
    }
    .pd-lg-x-3 {
        padding-left: 3px;
        padding-right: 3px
    }
    .pd-lg-x-3-f {
        padding-left: 3px!important;
        padding-right: 3px!important
    }
    .pd-lg-t-3 {
        padding-top: 3px
    }
    .pd-lg-r-3 {
        padding-right: 3px
    }
    .pd-lg-b-3 {
        padding-bottom: 3px
    }
    .pd-lg-l-3 {
        padding-left: 3px
    }
    .pd-lg-t-3-f {
        padding-top: 3px!important
    }
    .pd-lg-r-3-f {
        padding-right: 3px!important
    }
    .pd-lg-b-3-f {
        padding-bottom: 3px!important
    }
    .pd-lg-l-3-f {
        padding-left: 3px!important
    }
    .pd-lg-4 {
        padding: 4px
    }
    .pd-lg-4-f {
        padding: 4px!important
    }
    .pd-lg-y-4 {
        padding-top: 4px;
        padding-bottom: 4px
    }
    .pd-lg-y-4-f {
        padding-top: 4px!important;
        padding-bottom: 4px!important
    }
    .pd-lg-x-4 {
        padding-left: 4px;
        padding-right: 4px
    }
    .pd-lg-x-4-f {
        padding-left: 4px!important;
        padding-right: 4px!important
    }
    .pd-lg-t-4 {
        padding-top: 4px
    }
    .pd-lg-r-4 {
        padding-right: 4px
    }
    .pd-lg-b-4 {
        padding-bottom: 4px
    }
    .pd-lg-l-4 {
        padding-left: 4px
    }
    .pd-lg-t-4-f {
        padding-top: 4px!important
    }
    .pd-lg-r-4-f {
        padding-right: 4px!important
    }
    .pd-lg-b-4-f {
        padding-bottom: 4px!important
    }
    .pd-lg-l-4-f {
        padding-left: 4px!important
    }
    .pd-lg-5 {
        padding: 5px
    }
    .pd-lg-5-f {
        padding: 5px!important
    }
    .pd-lg-y-5 {
        padding-top: 5px;
        padding-bottom: 5px
    }
    .pd-lg-y-5-f {
        padding-top: 5px!important;
        padding-bottom: 5px!important
    }
    .pd-lg-x-5 {
        padding-left: 5px;
        padding-right: 5px
    }
    .pd-lg-x-5-f {
        padding-left: 5px!important;
        padding-right: 5px!important
    }
    .pd-lg-t-5 {
        padding-top: 5px
    }
    .pd-lg-r-5 {
        padding-right: 5px
    }
    .pd-lg-b-5 {
        padding-bottom: 5px
    }
    .pd-lg-l-5 {
        padding-left: 5px
    }
    .pd-lg-t-5-f {
        padding-top: 5px!important
    }
    .pd-lg-r-5-f {
        padding-right: 5px!important
    }
    .pd-lg-b-5-f {
        padding-bottom: 5px!important
    }
    .pd-lg-l-5-f {
        padding-left: 5px!important
    }
    .pd-lg-6 {
        padding: 6px
    }
    .pd-lg-6-f {
        padding: 6px!important
    }
    .pd-lg-y-6 {
        padding-top: 6px;
        padding-bottom: 6px
    }
    .pd-lg-y-6-f {
        padding-top: 6px!important;
        padding-bottom: 6px!important
    }
    .pd-lg-x-6 {
        padding-left: 6px;
        padding-right: 6px
    }
    .pd-lg-x-6-f {
        padding-left: 6px!important;
        padding-right: 6px!important
    }
    .pd-lg-t-6 {
        padding-top: 6px
    }
    .pd-lg-r-6 {
        padding-right: 6px
    }
    .pd-lg-b-6 {
        padding-bottom: 6px
    }
    .pd-lg-l-6 {
        padding-left: 6px
    }
    .pd-lg-t-6-f {
        padding-top: 6px!important
    }
    .pd-lg-r-6-f {
        padding-right: 6px!important
    }
    .pd-lg-b-6-f {
        padding-bottom: 6px!important
    }
    .pd-lg-l-6-f {
        padding-left: 6px!important
    }
    .pd-lg-7 {
        padding: 7px
    }
    .pd-lg-7-f {
        padding: 7px!important
    }
    .pd-lg-y-7 {
        padding-top: 7px;
        padding-bottom: 7px
    }
    .pd-lg-y-7-f {
        padding-top: 7px!important;
        padding-bottom: 7px!important
    }
    .pd-lg-x-7 {
        padding-left: 7px;
        padding-right: 7px
    }
    .pd-lg-x-7-f {
        padding-left: 7px!important;
        padding-right: 7px!important
    }
    .pd-lg-t-7 {
        padding-top: 7px
    }
    .pd-lg-r-7 {
        padding-right: 7px
    }
    .pd-lg-b-7 {
        padding-bottom: 7px
    }
    .pd-lg-l-7 {
        padding-left: 7px
    }
    .pd-lg-t-7-f {
        padding-top: 7px!important
    }
    .pd-lg-r-7-f {
        padding-right: 7px!important
    }
    .pd-lg-b-7-f {
        padding-bottom: 7px!important
    }
    .pd-lg-l-7-f {
        padding-left: 7px!important
    }
    .pd-lg-8 {
        padding: 8px
    }
    .pd-lg-8-f {
        padding: 8px!important
    }
    .pd-lg-y-8 {
        padding-top: 8px;
        padding-bottom: 8px
    }
    .pd-lg-y-8-f {
        padding-top: 8px!important;
        padding-bottom: 8px!important
    }
    .pd-lg-x-8 {
        padding-left: 8px;
        padding-right: 8px
    }
    .pd-lg-x-8-f {
        padding-left: 8px!important;
        padding-right: 8px!important
    }
    .pd-lg-t-8 {
        padding-top: 8px
    }
    .pd-lg-r-8 {
        padding-right: 8px
    }
    .pd-lg-b-8 {
        padding-bottom: 8px
    }
    .pd-lg-l-8 {
        padding-left: 8px
    }
    .pd-lg-t-8-f {
        padding-top: 8px!important
    }
    .pd-lg-r-8-f {
        padding-right: 8px!important
    }
    .pd-lg-b-8-f {
        padding-bottom: 8px!important
    }
    .pd-lg-l-8-f {
        padding-left: 8px!important
    }
    .pd-lg-9 {
        padding: 9px
    }
    .pd-lg-9-f {
        padding: 9px!important
    }
    .pd-lg-y-9 {
        padding-top: 9px;
        padding-bottom: 9px
    }
    .pd-lg-y-9-f {
        padding-top: 9px!important;
        padding-bottom: 9px!important
    }
    .pd-lg-x-9 {
        padding-left: 9px;
        padding-right: 9px
    }
    .pd-lg-x-9-f {
        padding-left: 9px!important;
        padding-right: 9px!important
    }
    .pd-lg-t-9 {
        padding-top: 9px
    }
    .pd-lg-r-9 {
        padding-right: 9px
    }
    .pd-lg-b-9 {
        padding-bottom: 9px
    }
    .pd-lg-l-9 {
        padding-left: 9px
    }
    .pd-lg-t-9-f {
        padding-top: 9px!important
    }
    .pd-lg-r-9-f {
        padding-right: 9px!important
    }
    .pd-lg-b-9-f {
        padding-bottom: 9px!important
    }
    .pd-lg-l-9-f {
        padding-left: 9px!important
    }
    .pd-lg-10 {
        padding: 10px
    }
    .pd-lg-10-f {
        padding: 10px!important
    }
    .pd-lg-y-10 {
        padding-top: 10px;
        padding-bottom: 10px
    }
    .pd-lg-y-10-f {
        padding-top: 10px!important;
        padding-bottom: 10px!important
    }
    .pd-lg-x-10 {
        padding-left: 10px;
        padding-right: 10px
    }
    .pd-lg-x-10-f {
        padding-left: 10px!important;
        padding-right: 10px!important
    }
    .pd-lg-t-10 {
        padding-top: 10px
    }
    .pd-lg-r-10 {
        padding-right: 10px
    }
    .pd-lg-b-10 {
        padding-bottom: 10px
    }
    .pd-lg-l-10 {
        padding-left: 10px
    }
    .pd-lg-t-10-f {
        padding-top: 10px!important
    }
    .pd-lg-r-10-f {
        padding-right: 10px!important
    }
    .pd-lg-b-10-f {
        padding-bottom: 10px!important
    }
    .pd-lg-l-10-f {
        padding-left: 10px!important
    }
    .pd-lg-15 {
        padding: 15px
    }
    .pd-lg-15-f {
        padding: 15px!important
    }
    .pd-lg-y-15 {
        padding-top: 15px;
        padding-bottom: 15px
    }
    .pd-lg-y-15-f {
        padding-top: 15px!important;
        padding-bottom: 15px!important
    }
    .pd-lg-x-15 {
        padding-left: 15px;
        padding-right: 15px
    }
    .pd-lg-x-15-f {
        padding-left: 15px!important;
        padding-right: 15px!important
    }
    .pd-lg-t-15 {
        padding-top: 15px
    }
    .pd-lg-r-15 {
        padding-right: 15px
    }
    .pd-lg-b-15 {
        padding-bottom: 15px
    }
    .pd-lg-l-15 {
        padding-left: 15px
    }
    .pd-lg-t-15-f {
        padding-top: 15px!important
    }
    .pd-lg-r-15-f {
        padding-right: 15px!important
    }
    .pd-lg-b-15-f {
        padding-bottom: 15px!important
    }
    .pd-lg-l-15-f {
        padding-left: 15px!important
    }
    .pd-lg-20 {
        padding: 20px
    }
    .pd-lg-20-f {
        padding: 20px!important
    }
    .pd-lg-y-20 {
        padding-top: 20px;
        padding-bottom: 20px
    }
    .pd-lg-y-20-f {
        padding-top: 20px!important;
        padding-bottom: 20px!important
    }
    .pd-lg-x-20 {
        padding-left: 20px;
        padding-right: 20px
    }
    .pd-lg-x-20-f {
        padding-left: 20px!important;
        padding-right: 20px!important
    }
    .pd-lg-t-20 {
        padding-top: 20px
    }
    .pd-lg-r-20 {
        padding-right: 20px
    }
    .pd-lg-b-20 {
        padding-bottom: 20px
    }
    .pd-lg-l-20 {
        padding-left: 20px
    }
    .pd-lg-t-20-f {
        padding-top: 20px!important
    }
    .pd-lg-r-20-f {
        padding-right: 20px!important
    }
    .pd-lg-b-20-f {
        padding-bottom: 20px!important
    }
    .pd-lg-l-20-f {
        padding-left: 20px!important
    }
    .pd-lg-25 {
        padding: 25px
    }
    .pd-lg-25-f {
        padding: 25px!important
    }
    .pd-lg-y-25 {
        padding-top: 25px;
        padding-bottom: 25px
    }
    .pd-lg-y-25-f {
        padding-top: 25px!important;
        padding-bottom: 25px!important
    }
    .pd-lg-x-25 {
        padding-left: 25px;
        padding-right: 25px
    }
    .pd-lg-x-25-f {
        padding-left: 25px!important;
        padding-right: 25px!important
    }
    .pd-lg-t-25 {
        padding-top: 25px
    }
    .pd-lg-r-25 {
        padding-right: 25px
    }
    .pd-lg-b-25 {
        padding-bottom: 25px
    }
    .pd-lg-l-25 {
        padding-left: 25px
    }
    .pd-lg-t-25-f {
        padding-top: 25px!important
    }
    .pd-lg-r-25-f {
        padding-right: 25px!important
    }
    .pd-lg-b-25-f {
        padding-bottom: 25px!important
    }
    .pd-lg-l-25-f {
        padding-left: 25px!important
    }
    .pd-lg-30 {
        padding: 30px
    }
    .pd-lg-30-f {
        padding: 30px!important
    }
    .pd-lg-y-30 {
        padding-top: 30px;
        padding-bottom: 30px
    }
    .pd-lg-y-30-f {
        padding-top: 30px!important;
        padding-bottom: 30px!important
    }
    .pd-lg-x-30 {
        padding-left: 30px;
        padding-right: 30px
    }
    .pd-lg-x-30-f {
        padding-left: 30px!important;
        padding-right: 30px!important
    }
    .pd-lg-t-30 {
        padding-top: 30px
    }
    .pd-lg-r-30 {
        padding-right: 30px
    }
    .pd-lg-b-30 {
        padding-bottom: 30px
    }
    .pd-lg-l-30 {
        padding-left: 30px
    }
    .pd-lg-t-30-f {
        padding-top: 30px!important
    }
    .pd-lg-r-30-f {
        padding-right: 30px!important
    }
    .pd-lg-b-30-f {
        padding-bottom: 30px!important
    }
    .pd-lg-l-30-f {
        padding-left: 30px!important
    }
    .pd-lg-35 {
        padding: 35px
    }
    .pd-lg-35-f {
        padding: 35px!important
    }
    .pd-lg-y-35 {
        padding-top: 35px;
        padding-bottom: 35px
    }
    .pd-lg-y-35-f {
        padding-top: 35px!important;
        padding-bottom: 35px!important
    }
    .pd-lg-x-35 {
        padding-left: 35px;
        padding-right: 35px
    }
    .pd-lg-x-35-f {
        padding-left: 35px!important;
        padding-right: 35px!important
    }
    .pd-lg-t-35 {
        padding-top: 35px
    }
    .pd-lg-r-35 {
        padding-right: 35px
    }
    .pd-lg-b-35 {
        padding-bottom: 35px
    }
    .pd-lg-l-35 {
        padding-left: 35px
    }
    .pd-lg-t-35-f {
        padding-top: 35px!important
    }
    .pd-lg-r-35-f {
        padding-right: 35px!important
    }
    .pd-lg-b-35-f {
        padding-bottom: 35px!important
    }
    .pd-lg-l-35-f {
        padding-left: 35px!important
    }
    .pd-lg-40 {
        padding: 40px
    }
    .pd-lg-40-f {
        padding: 40px!important
    }
    .pd-lg-y-40 {
        padding-top: 40px;
        padding-bottom: 40px
    }
    .pd-lg-y-40-f {
        padding-top: 40px!important;
        padding-bottom: 40px!important
    }
    .pd-lg-x-40 {
        padding-left: 40px;
        padding-right: 40px
    }
    .pd-lg-x-40-f {
        padding-left: 40px!important;
        padding-right: 40px!important
    }
    .pd-lg-t-40 {
        padding-top: 40px
    }
    .pd-lg-r-40 {
        padding-right: 40px
    }
    .pd-lg-b-40 {
        padding-bottom: 40px
    }
    .pd-lg-l-40 {
        padding-left: 40px
    }
    .pd-lg-t-40-f {
        padding-top: 40px!important
    }
    .pd-lg-r-40-f {
        padding-right: 40px!important
    }
    .pd-lg-b-40-f {
        padding-bottom: 40px!important
    }
    .pd-lg-l-40-f {
        padding-left: 40px!important
    }
    .pd-lg-45 {
        padding: 45px
    }
    .pd-lg-45-f {
        padding: 45px!important
    }
    .pd-lg-y-45 {
        padding-top: 45px;
        padding-bottom: 45px
    }
    .pd-lg-y-45-f {
        padding-top: 45px!important;
        padding-bottom: 45px!important
    }
    .pd-lg-x-45 {
        padding-left: 45px;
        padding-right: 45px
    }
    .pd-lg-x-45-f {
        padding-left: 45px!important;
        padding-right: 45px!important
    }
    .pd-lg-t-45 {
        padding-top: 45px
    }
    .pd-lg-r-45 {
        padding-right: 45px
    }
    .pd-lg-b-45 {
        padding-bottom: 45px
    }
    .pd-lg-l-45 {
        padding-left: 45px
    }
    .pd-lg-t-45-f {
        padding-top: 45px!important
    }
    .pd-lg-r-45-f {
        padding-right: 45px!important
    }
    .pd-lg-b-45-f {
        padding-bottom: 45px!important
    }
    .pd-lg-l-45-f {
        padding-left: 45px!important
    }
    .pd-lg-50 {
        padding: 50px
    }
    .pd-lg-50-f {
        padding: 50px!important
    }
    .pd-lg-y-50 {
        padding-top: 50px;
        padding-bottom: 50px
    }
    .pd-lg-y-50-f {
        padding-top: 50px!important;
        padding-bottom: 50px!important
    }
    .pd-lg-x-50 {
        padding-left: 50px;
        padding-right: 50px
    }
    .pd-lg-x-50-f {
        padding-left: 50px!important;
        padding-right: 50px!important
    }
    .pd-lg-t-50 {
        padding-top: 50px
    }
    .pd-lg-r-50 {
        padding-right: 50px
    }
    .pd-lg-b-50 {
        padding-bottom: 50px
    }
    .pd-lg-l-50 {
        padding-left: 50px
    }
    .pd-lg-t-50-f {
        padding-top: 50px!important
    }
    .pd-lg-r-50-f {
        padding-right: 50px!important
    }
    .pd-lg-b-50-f {
        padding-bottom: 50px!important
    }
    .pd-lg-l-50-f {
        padding-left: 50px!important
    }
    .pd-lg-55 {
        padding: 55px
    }
    .pd-lg-55-f {
        padding: 55px!important
    }
    .pd-lg-y-55 {
        padding-top: 55px;
        padding-bottom: 55px
    }
    .pd-lg-y-55-f {
        padding-top: 55px!important;
        padding-bottom: 55px!important
    }
    .pd-lg-x-55 {
        padding-left: 55px;
        padding-right: 55px
    }
    .pd-lg-x-55-f {
        padding-left: 55px!important;
        padding-right: 55px!important
    }
    .pd-lg-t-55 {
        padding-top: 55px
    }
    .pd-lg-r-55 {
        padding-right: 55px
    }
    .pd-lg-b-55 {
        padding-bottom: 55px
    }
    .pd-lg-l-55 {
        padding-left: 55px
    }
    .pd-lg-t-55-f {
        padding-top: 55px!important
    }
    .pd-lg-r-55-f {
        padding-right: 55px!important
    }
    .pd-lg-b-55-f {
        padding-bottom: 55px!important
    }
    .pd-lg-l-55-f {
        padding-left: 55px!important
    }
    .pd-lg-60 {
        padding: 60px
    }
    .pd-lg-60-f {
        padding: 60px!important
    }
    .pd-lg-y-60 {
        padding-top: 60px;
        padding-bottom: 60px
    }
    .pd-lg-y-60-f {
        padding-top: 60px!important;
        padding-bottom: 60px!important
    }
    .pd-lg-x-60 {
        padding-left: 60px;
        padding-right: 60px
    }
    .pd-lg-x-60-f {
        padding-left: 60px!important;
        padding-right: 60px!important
    }
    .pd-lg-t-60 {
        padding-top: 60px
    }
    .pd-lg-r-60 {
        padding-right: 60px
    }
    .pd-lg-b-60 {
        padding-bottom: 60px
    }
    .pd-lg-l-60 {
        padding-left: 60px
    }
    .pd-lg-t-60-f {
        padding-top: 60px!important
    }
    .pd-lg-r-60-f {
        padding-right: 60px!important
    }
    .pd-lg-b-60-f {
        padding-bottom: 60px!important
    }
    .pd-lg-l-60-f {
        padding-left: 60px!important
    }
    .pd-lg-65 {
        padding: 65px
    }
    .pd-lg-65-f {
        padding: 65px!important
    }
    .pd-lg-y-65 {
        padding-top: 65px;
        padding-bottom: 65px
    }
    .pd-lg-y-65-f {
        padding-top: 65px!important;
        padding-bottom: 65px!important
    }
    .pd-lg-x-65 {
        padding-left: 65px;
        padding-right: 65px
    }
    .pd-lg-x-65-f {
        padding-left: 65px!important;
        padding-right: 65px!important
    }
    .pd-lg-t-65 {
        padding-top: 65px
    }
    .pd-lg-r-65 {
        padding-right: 65px
    }
    .pd-lg-b-65 {
        padding-bottom: 65px
    }
    .pd-lg-l-65 {
        padding-left: 65px
    }
    .pd-lg-t-65-f {
        padding-top: 65px!important
    }
    .pd-lg-r-65-f {
        padding-right: 65px!important
    }
    .pd-lg-b-65-f {
        padding-bottom: 65px!important
    }
    .pd-lg-l-65-f {
        padding-left: 65px!important
    }
    .pd-lg-70 {
        padding: 70px
    }
    .pd-lg-70-f {
        padding: 70px!important
    }
    .pd-lg-y-70 {
        padding-top: 70px;
        padding-bottom: 70px
    }
    .pd-lg-y-70-f {
        padding-top: 70px!important;
        padding-bottom: 70px!important
    }
    .pd-lg-x-70 {
        padding-left: 70px;
        padding-right: 70px
    }
    .pd-lg-x-70-f {
        padding-left: 70px!important;
        padding-right: 70px!important
    }
    .pd-lg-t-70 {
        padding-top: 70px
    }
    .pd-lg-r-70 {
        padding-right: 70px
    }
    .pd-lg-b-70 {
        padding-bottom: 70px
    }
    .pd-lg-l-70 {
        padding-left: 70px
    }
    .pd-lg-t-70-f {
        padding-top: 70px!important
    }
    .pd-lg-r-70-f {
        padding-right: 70px!important
    }
    .pd-lg-b-70-f {
        padding-bottom: 70px!important
    }
    .pd-lg-l-70-f {
        padding-left: 70px!important
    }
    .pd-lg-75 {
        padding: 75px
    }
    .pd-lg-75-f {
        padding: 75px!important
    }
    .pd-lg-y-75 {
        padding-top: 75px;
        padding-bottom: 75px
    }
    .pd-lg-y-75-f {
        padding-top: 75px!important;
        padding-bottom: 75px!important
    }
    .pd-lg-x-75 {
        padding-left: 75px;
        padding-right: 75px
    }
    .pd-lg-x-75-f {
        padding-left: 75px!important;
        padding-right: 75px!important
    }
    .pd-lg-t-75 {
        padding-top: 75px
    }
    .pd-lg-r-75 {
        padding-right: 75px
    }
    .pd-lg-b-75 {
        padding-bottom: 75px
    }
    .pd-lg-l-75 {
        padding-left: 75px
    }
    .pd-lg-t-75-f {
        padding-top: 75px!important
    }
    .pd-lg-r-75-f {
        padding-right: 75px!important
    }
    .pd-lg-b-75-f {
        padding-bottom: 75px!important
    }
    .pd-lg-l-75-f {
        padding-left: 75px!important
    }
    .pd-lg-80 {
        padding: 80px
    }
    .pd-lg-80-f {
        padding: 80px!important
    }
    .pd-lg-y-80 {
        padding-top: 80px;
        padding-bottom: 80px
    }
    .pd-lg-y-80-f {
        padding-top: 80px!important;
        padding-bottom: 80px!important
    }
    .pd-lg-x-80 {
        padding-left: 80px;
        padding-right: 80px
    }
    .pd-lg-x-80-f {
        padding-left: 80px!important;
        padding-right: 80px!important
    }
    .pd-lg-t-80 {
        padding-top: 80px
    }
    .pd-lg-r-80 {
        padding-right: 80px
    }
    .pd-lg-b-80 {
        padding-bottom: 80px
    }
    .pd-lg-l-80 {
        padding-left: 80px
    }
    .pd-lg-t-80-f {
        padding-top: 80px!important
    }
    .pd-lg-r-80-f {
        padding-right: 80px!important
    }
    .pd-lg-b-80-f {
        padding-bottom: 80px!important
    }
    .pd-lg-l-80-f {
        padding-left: 80px!important
    }
    .pd-lg-85 {
        padding: 85px
    }
    .pd-lg-85-f {
        padding: 85px!important
    }
    .pd-lg-y-85 {
        padding-top: 85px;
        padding-bottom: 85px
    }
    .pd-lg-y-85-f {
        padding-top: 85px!important;
        padding-bottom: 85px!important
    }
    .pd-lg-x-85 {
        padding-left: 85px;
        padding-right: 85px
    }
    .pd-lg-x-85-f {
        padding-left: 85px!important;
        padding-right: 85px!important
    }
    .pd-lg-t-85 {
        padding-top: 85px
    }
    .pd-lg-r-85 {
        padding-right: 85px
    }
    .pd-lg-b-85 {
        padding-bottom: 85px
    }
    .pd-lg-l-85 {
        padding-left: 85px
    }
    .pd-lg-t-85-f {
        padding-top: 85px!important
    }
    .pd-lg-r-85-f {
        padding-right: 85px!important
    }
    .pd-lg-b-85-f {
        padding-bottom: 85px!important
    }
    .pd-lg-l-85-f {
        padding-left: 85px!important
    }
    .pd-lg-90 {
        padding: 90px
    }
    .pd-lg-90-f {
        padding: 90px!important
    }
    .pd-lg-y-90 {
        padding-top: 90px;
        padding-bottom: 90px
    }
    .pd-lg-y-90-f {
        padding-top: 90px!important;
        padding-bottom: 90px!important
    }
    .pd-lg-x-90 {
        padding-left: 90px;
        padding-right: 90px
    }
    .pd-lg-x-90-f {
        padding-left: 90px!important;
        padding-right: 90px!important
    }
    .pd-lg-t-90 {
        padding-top: 90px
    }
    .pd-lg-r-90 {
        padding-right: 90px
    }
    .pd-lg-b-90 {
        padding-bottom: 90px
    }
    .pd-lg-l-90 {
        padding-left: 90px
    }
    .pd-lg-t-90-f {
        padding-top: 90px!important
    }
    .pd-lg-r-90-f {
        padding-right: 90px!important
    }
    .pd-lg-b-90-f {
        padding-bottom: 90px!important
    }
    .pd-lg-l-90-f {
        padding-left: 90px!important
    }
    .pd-lg-95 {
        padding: 95px
    }
    .pd-lg-95-f {
        padding: 95px!important
    }
    .pd-lg-y-95 {
        padding-top: 95px;
        padding-bottom: 95px
    }
    .pd-lg-y-95-f {
        padding-top: 95px!important;
        padding-bottom: 95px!important
    }
    .pd-lg-x-95 {
        padding-left: 95px;
        padding-right: 95px
    }
    .pd-lg-x-95-f {
        padding-left: 95px!important;
        padding-right: 95px!important
    }
    .pd-lg-t-95 {
        padding-top: 95px
    }
    .pd-lg-r-95 {
        padding-right: 95px
    }
    .pd-lg-b-95 {
        padding-bottom: 95px
    }
    .pd-lg-l-95 {
        padding-left: 95px
    }
    .pd-lg-t-95-f {
        padding-top: 95px!important
    }
    .pd-lg-r-95-f {
        padding-right: 95px!important
    }
    .pd-lg-b-95-f {
        padding-bottom: 95px!important
    }
    .pd-lg-l-95-f {
        padding-left: 95px!important
    }
    .pd-lg-100 {
        padding: 100px
    }
    .pd-lg-100-f {
        padding: 100px!important
    }
    .pd-lg-y-100 {
        padding-top: 100px;
        padding-bottom: 100px
    }
    .pd-lg-y-100-f {
        padding-top: 100px!important;
        padding-bottom: 100px!important
    }
    .pd-lg-x-100 {
        padding-left: 100px;
        padding-right: 100px
    }
    .pd-lg-x-100-f {
        padding-left: 100px!important;
        padding-right: 100px!important
    }
    .pd-lg-t-100 {
        padding-top: 100px
    }
    .pd-lg-r-100 {
        padding-right: 100px
    }
    .pd-lg-b-100 {
        padding-bottom: 100px
    }
    .pd-lg-l-100 {
        padding-left: 100px
    }
    .pd-lg-t-100-f {
        padding-top: 100px!important
    }
    .pd-lg-r-100-f {
        padding-right: 100px!important
    }
    .pd-lg-b-100-f {
        padding-bottom: 100px!important
    }
    .pd-lg-l-100-f {
        padding-left: 100px!important
    }
    .pd-lg-110 {
        padding: 110px
    }
    .pd-lg-110-f {
        padding: 110px!important
    }
    .pd-lg-y-110 {
        padding-top: 110px;
        padding-bottom: 110px
    }
    .pd-lg-y-110-f {
        padding-top: 110px!important;
        padding-bottom: 110px!important
    }
    .pd-lg-x-110 {
        padding-left: 110px;
        padding-right: 110px
    }
    .pd-lg-x-110-f {
        padding-left: 110px!important;
        padding-right: 110px!important
    }
    .pd-lg-t-110 {
        padding-top: 110px
    }
    .pd-lg-r-110 {
        padding-right: 110px
    }
    .pd-lg-b-110 {
        padding-bottom: 110px
    }
    .pd-lg-l-110 {
        padding-left: 110px
    }
    .pd-lg-t-110-f {
        padding-top: 110px!important
    }
    .pd-lg-r-110-f {
        padding-right: 110px!important
    }
    .pd-lg-b-110-f {
        padding-bottom: 110px!important
    }
    .pd-lg-l-110-f {
        padding-left: 110px!important
    }
    .pd-lg-120 {
        padding: 120px
    }
    .pd-lg-120-f {
        padding: 120px!important
    }
    .pd-lg-y-120 {
        padding-top: 120px;
        padding-bottom: 120px
    }
    .pd-lg-y-120-f {
        padding-top: 120px!important;
        padding-bottom: 120px!important
    }
    .pd-lg-x-120 {
        padding-left: 120px;
        padding-right: 120px
    }
    .pd-lg-x-120-f {
        padding-left: 120px!important;
        padding-right: 120px!important
    }
    .pd-lg-t-120 {
        padding-top: 120px
    }
    .pd-lg-r-120 {
        padding-right: 120px
    }
    .pd-lg-b-120 {
        padding-bottom: 120px
    }
    .pd-lg-l-120 {
        padding-left: 120px
    }
    .pd-lg-t-120-f {
        padding-top: 120px!important
    }
    .pd-lg-r-120-f {
        padding-right: 120px!important
    }
    .pd-lg-b-120-f {
        padding-bottom: 120px!important
    }
    .pd-lg-l-120-f {
        padding-left: 120px!important
    }
    .pd-lg-130 {
        padding: 130px
    }
    .pd-lg-130-f {
        padding: 130px!important
    }
    .pd-lg-y-130 {
        padding-top: 130px;
        padding-bottom: 130px
    }
    .pd-lg-y-130-f {
        padding-top: 130px!important;
        padding-bottom: 130px!important
    }
    .pd-lg-x-130 {
        padding-left: 130px;
        padding-right: 130px
    }
    .pd-lg-x-130-f {
        padding-left: 130px!important;
        padding-right: 130px!important
    }
    .pd-lg-t-130 {
        padding-top: 130px
    }
    .pd-lg-r-130 {
        padding-right: 130px
    }
    .pd-lg-b-130 {
        padding-bottom: 130px
    }
    .pd-lg-l-130 {
        padding-left: 130px
    }
    .pd-lg-t-130-f {
        padding-top: 130px!important
    }
    .pd-lg-r-130-f {
        padding-right: 130px!important
    }
    .pd-lg-b-130-f {
        padding-bottom: 130px!important
    }
    .pd-lg-l-130-f {
        padding-left: 130px!important
    }
    .pd-lg-140 {
        padding: 140px
    }
    .pd-lg-140-f {
        padding: 140px!important
    }
    .pd-lg-y-140 {
        padding-top: 140px;
        padding-bottom: 140px
    }
    .pd-lg-y-140-f {
        padding-top: 140px!important;
        padding-bottom: 140px!important
    }
    .pd-lg-x-140 {
        padding-left: 140px;
        padding-right: 140px
    }
    .pd-lg-x-140-f {
        padding-left: 140px!important;
        padding-right: 140px!important
    }
    .pd-lg-t-140 {
        padding-top: 140px
    }
    .pd-lg-r-140 {
        padding-right: 140px
    }
    .pd-lg-b-140 {
        padding-bottom: 140px
    }
    .pd-lg-l-140 {
        padding-left: 140px
    }
    .pd-lg-t-140-f {
        padding-top: 140px!important
    }
    .pd-lg-r-140-f {
        padding-right: 140px!important
    }
    .pd-lg-b-140-f {
        padding-bottom: 140px!important
    }
    .pd-lg-l-140-f {
        padding-left: 140px!important
    }
    .pd-lg-150 {
        padding: 150px
    }
    .pd-lg-150-f {
        padding: 150px!important
    }
    .pd-lg-y-150 {
        padding-top: 150px;
        padding-bottom: 150px
    }
    .pd-lg-y-150-f {
        padding-top: 150px!important;
        padding-bottom: 150px!important
    }
    .pd-lg-x-150 {
        padding-left: 150px;
        padding-right: 150px
    }
    .pd-lg-x-150-f {
        padding-left: 150px!important;
        padding-right: 150px!important
    }
    .pd-lg-t-150 {
        padding-top: 150px
    }
    .pd-lg-r-150 {
        padding-right: 150px
    }
    .pd-lg-b-150 {
        padding-bottom: 150px
    }
    .pd-lg-l-150 {
        padding-left: 150px
    }
    .pd-lg-t-150-f {
        padding-top: 150px!important
    }
    .pd-lg-r-150-f {
        padding-right: 150px!important
    }
    .pd-lg-b-150-f {
        padding-bottom: 150px!important
    }
    .pd-lg-l-150-f {
        padding-left: 150px!important
    }
    .pd-lg-160 {
        padding: 160px
    }
    .pd-lg-160-f {
        padding: 160px!important
    }
    .pd-lg-y-160 {
        padding-top: 160px;
        padding-bottom: 160px
    }
    .pd-lg-y-160-f {
        padding-top: 160px!important;
        padding-bottom: 160px!important
    }
    .pd-lg-x-160 {
        padding-left: 160px;
        padding-right: 160px
    }
    .pd-lg-x-160-f {
        padding-left: 160px!important;
        padding-right: 160px!important
    }
    .pd-lg-t-160 {
        padding-top: 160px
    }
    .pd-lg-r-160 {
        padding-right: 160px
    }
    .pd-lg-b-160 {
        padding-bottom: 160px
    }
    .pd-lg-l-160 {
        padding-left: 160px
    }
    .pd-lg-t-160-f {
        padding-top: 160px!important
    }
    .pd-lg-r-160-f {
        padding-right: 160px!important
    }
    .pd-lg-b-160-f {
        padding-bottom: 160px!important
    }
    .pd-lg-l-160-f {
        padding-left: 160px!important
    }
    .pd-lg-170 {
        padding: 170px
    }
    .pd-lg-170-f {
        padding: 170px!important
    }
    .pd-lg-y-170 {
        padding-top: 170px;
        padding-bottom: 170px
    }
    .pd-lg-y-170-f {
        padding-top: 170px!important;
        padding-bottom: 170px!important
    }
    .pd-lg-x-170 {
        padding-left: 170px;
        padding-right: 170px
    }
    .pd-lg-x-170-f {
        padding-left: 170px!important;
        padding-right: 170px!important
    }
    .pd-lg-t-170 {
        padding-top: 170px
    }
    .pd-lg-r-170 {
        padding-right: 170px
    }
    .pd-lg-b-170 {
        padding-bottom: 170px
    }
    .pd-lg-l-170 {
        padding-left: 170px
    }
    .pd-lg-t-170-f {
        padding-top: 170px!important
    }
    .pd-lg-r-170-f {
        padding-right: 170px!important
    }
    .pd-lg-b-170-f {
        padding-bottom: 170px!important
    }
    .pd-lg-l-170-f {
        padding-left: 170px!important
    }
    .pd-lg-180 {
        padding: 180px
    }
    .pd-lg-180-f {
        padding: 180px!important
    }
    .pd-lg-y-180 {
        padding-top: 180px;
        padding-bottom: 180px
    }
    .pd-lg-y-180-f {
        padding-top: 180px!important;
        padding-bottom: 180px!important
    }
    .pd-lg-x-180 {
        padding-left: 180px;
        padding-right: 180px
    }
    .pd-lg-x-180-f {
        padding-left: 180px!important;
        padding-right: 180px!important
    }
    .pd-lg-t-180 {
        padding-top: 180px
    }
    .pd-lg-r-180 {
        padding-right: 180px
    }
    .pd-lg-b-180 {
        padding-bottom: 180px
    }
    .pd-lg-l-180 {
        padding-left: 180px
    }
    .pd-lg-t-180-f {
        padding-top: 180px!important
    }
    .pd-lg-r-180-f {
        padding-right: 180px!important
    }
    .pd-lg-b-180-f {
        padding-bottom: 180px!important
    }
    .pd-lg-l-180-f {
        padding-left: 180px!important
    }
    .pd-lg-190 {
        padding: 190px
    }
    .pd-lg-190-f {
        padding: 190px!important
    }
    .pd-lg-y-190 {
        padding-top: 190px;
        padding-bottom: 190px
    }
    .pd-lg-y-190-f {
        padding-top: 190px!important;
        padding-bottom: 190px!important
    }
    .pd-lg-x-190 {
        padding-left: 190px;
        padding-right: 190px
    }
    .pd-lg-x-190-f {
        padding-left: 190px!important;
        padding-right: 190px!important
    }
    .pd-lg-t-190 {
        padding-top: 190px
    }
    .pd-lg-r-190 {
        padding-right: 190px
    }
    .pd-lg-b-190 {
        padding-bottom: 190px
    }
    .pd-lg-l-190 {
        padding-left: 190px
    }
    .pd-lg-t-190-f {
        padding-top: 190px!important
    }
    .pd-lg-r-190-f {
        padding-right: 190px!important
    }
    .pd-lg-b-190-f {
        padding-bottom: 190px!important
    }
    .pd-lg-l-190-f {
        padding-left: 190px!important
    }
    .pd-lg-200 {
        padding: 200px
    }
    .pd-lg-200-f {
        padding: 200px!important
    }
    .pd-lg-y-200 {
        padding-top: 200px;
        padding-bottom: 200px
    }
    .pd-lg-y-200-f {
        padding-top: 200px!important;
        padding-bottom: 200px!important
    }
    .pd-lg-x-200 {
        padding-left: 200px;
        padding-right: 200px
    }
    .pd-lg-x-200-f {
        padding-left: 200px!important;
        padding-right: 200px!important
    }
    .pd-lg-t-200 {
        padding-top: 200px
    }
    .pd-lg-r-200 {
        padding-right: 200px
    }
    .pd-lg-b-200 {
        padding-bottom: 200px
    }
    .pd-lg-l-200 {
        padding-left: 200px
    }
    .pd-lg-t-200-f {
        padding-top: 200px!important
    }
    .pd-lg-r-200-f {
        padding-right: 200px!important
    }
    .pd-lg-b-200-f {
        padding-bottom: 200px!important
    }
    .pd-lg-l-200-f {
        padding-left: 200px!important
    }
}

@media (min-width:1200px) {
    .pd-xl-0 {
        padding: 0
    }
    .pd-xl-0-f {
        padding: 0!important
    }
    .pd-xl-y-0 {
        padding-top: 0;
        padding-bottom: 0
    }
    .pd-xl-y-0-f {
        padding-top: 0!important;
        padding-bottom: 0!important
    }
    .pd-xl-x-0 {
        padding-left: 0;
        padding-right: 0
    }
    .pd-xl-x-0-f {
        padding-left: 0!important;
        padding-right: 0!important
    }
    .pd-xl-t-0 {
        padding-top: 0
    }
    .pd-xl-r-0 {
        padding-right: 0
    }
    .pd-xl-b-0 {
        padding-bottom: 0
    }
    .pd-xl-l-0 {
        padding-left: 0
    }
    .pd-xl-t-0-f {
        padding-top: 0!important
    }
    .pd-xl-r-0-f {
        padding-right: 0!important
    }
    .pd-xl-b-0-f {
        padding-bottom: 0!important
    }
    .pd-xl-l-0-f {
        padding-left: 0!important
    }
    .pd-xl-1 {
        padding: 1px
    }
    .pd-xl-1-f {
        padding: 1px!important
    }
    .pd-xl-y-1 {
        padding-top: 1px;
        padding-bottom: 1px
    }
    .pd-xl-y-1-f {
        padding-top: 1px!important;
        padding-bottom: 1px!important
    }
    .pd-xl-x-1 {
        padding-left: 1px;
        padding-right: 1px
    }
    .pd-xl-x-1-f {
        padding-left: 1px!important;
        padding-right: 1px!important
    }
    .pd-xl-t-1 {
        padding-top: 1px
    }
    .pd-xl-r-1 {
        padding-right: 1px
    }
    .pd-xl-b-1 {
        padding-bottom: 1px
    }
    .pd-xl-l-1 {
        padding-left: 1px
    }
    .pd-xl-t-1-f {
        padding-top: 1px!important
    }
    .pd-xl-r-1-f {
        padding-right: 1px!important
    }
    .pd-xl-b-1-f {
        padding-bottom: 1px!important
    }
    .pd-xl-l-1-f {
        padding-left: 1px!important
    }
    .pd-xl-2 {
        padding: 2px
    }
    .pd-xl-2-f {
        padding: 2px!important
    }
    .pd-xl-y-2 {
        padding-top: 2px;
        padding-bottom: 2px
    }
    .pd-xl-y-2-f {
        padding-top: 2px!important;
        padding-bottom: 2px!important
    }
    .pd-xl-x-2 {
        padding-left: 2px;
        padding-right: 2px
    }
    .pd-xl-x-2-f {
        padding-left: 2px!important;
        padding-right: 2px!important
    }
    .pd-xl-t-2 {
        padding-top: 2px
    }
    .pd-xl-r-2 {
        padding-right: 2px
    }
    .pd-xl-b-2 {
        padding-bottom: 2px
    }
    .pd-xl-l-2 {
        padding-left: 2px
    }
    .pd-xl-t-2-f {
        padding-top: 2px!important
    }
    .pd-xl-r-2-f {
        padding-right: 2px!important
    }
    .pd-xl-b-2-f {
        padding-bottom: 2px!important
    }
    .pd-xl-l-2-f {
        padding-left: 2px!important
    }
    .pd-xl-3 {
        padding: 3px
    }
    .pd-xl-3-f {
        padding: 3px!important
    }
    .pd-xl-y-3 {
        padding-top: 3px;
        padding-bottom: 3px
    }
    .pd-xl-y-3-f {
        padding-top: 3px!important;
        padding-bottom: 3px!important
    }
    .pd-xl-x-3 {
        padding-left: 3px;
        padding-right: 3px
    }
    .pd-xl-x-3-f {
        padding-left: 3px!important;
        padding-right: 3px!important
    }
    .pd-xl-t-3 {
        padding-top: 3px
    }
    .pd-xl-r-3 {
        padding-right: 3px
    }
    .pd-xl-b-3 {
        padding-bottom: 3px
    }
    .pd-xl-l-3 {
        padding-left: 3px
    }
    .pd-xl-t-3-f {
        padding-top: 3px!important
    }
    .pd-xl-r-3-f {
        padding-right: 3px!important
    }
    .pd-xl-b-3-f {
        padding-bottom: 3px!important
    }
    .pd-xl-l-3-f {
        padding-left: 3px!important
    }
    .pd-xl-4 {
        padding: 4px
    }
    .pd-xl-4-f {
        padding: 4px!important
    }
    .pd-xl-y-4 {
        padding-top: 4px;
        padding-bottom: 4px
    }
    .pd-xl-y-4-f {
        padding-top: 4px!important;
        padding-bottom: 4px!important
    }
    .pd-xl-x-4 {
        padding-left: 4px;
        padding-right: 4px
    }
    .pd-xl-x-4-f {
        padding-left: 4px!important;
        padding-right: 4px!important
    }
    .pd-xl-t-4 {
        padding-top: 4px
    }
    .pd-xl-r-4 {
        padding-right: 4px
    }
    .pd-xl-b-4 {
        padding-bottom: 4px
    }
    .pd-xl-l-4 {
        padding-left: 4px
    }
    .pd-xl-t-4-f {
        padding-top: 4px!important
    }
    .pd-xl-r-4-f {
        padding-right: 4px!important
    }
    .pd-xl-b-4-f {
        padding-bottom: 4px!important
    }
    .pd-xl-l-4-f {
        padding-left: 4px!important
    }
    .pd-xl-5 {
        padding: 5px
    }
    .pd-xl-5-f {
        padding: 5px!important
    }
    .pd-xl-y-5 {
        padding-top: 5px;
        padding-bottom: 5px
    }
    .pd-xl-y-5-f {
        padding-top: 5px!important;
        padding-bottom: 5px!important
    }
    .pd-xl-x-5 {
        padding-left: 5px;
        padding-right: 5px
    }
    .pd-xl-x-5-f {
        padding-left: 5px!important;
        padding-right: 5px!important
    }
    .pd-xl-t-5 {
        padding-top: 5px
    }
    .pd-xl-r-5 {
        padding-right: 5px
    }
    .pd-xl-b-5 {
        padding-bottom: 5px
    }
    .pd-xl-l-5 {
        padding-left: 5px
    }
    .pd-xl-t-5-f {
        padding-top: 5px!important
    }
    .pd-xl-r-5-f {
        padding-right: 5px!important
    }
    .pd-xl-b-5-f {
        padding-bottom: 5px!important
    }
    .pd-xl-l-5-f {
        padding-left: 5px!important
    }
    .pd-xl-6 {
        padding: 6px
    }
    .pd-xl-6-f {
        padding: 6px!important
    }
    .pd-xl-y-6 {
        padding-top: 6px;
        padding-bottom: 6px
    }
    .pd-xl-y-6-f {
        padding-top: 6px!important;
        padding-bottom: 6px!important
    }
    .pd-xl-x-6 {
        padding-left: 6px;
        padding-right: 6px
    }
    .pd-xl-x-6-f {
        padding-left: 6px!important;
        padding-right: 6px!important
    }
    .pd-xl-t-6 {
        padding-top: 6px
    }
    .pd-xl-r-6 {
        padding-right: 6px
    }
    .pd-xl-b-6 {
        padding-bottom: 6px
    }
    .pd-xl-l-6 {
        padding-left: 6px
    }
    .pd-xl-t-6-f {
        padding-top: 6px!important
    }
    .pd-xl-r-6-f {
        padding-right: 6px!important
    }
    .pd-xl-b-6-f {
        padding-bottom: 6px!important
    }
    .pd-xl-l-6-f {
        padding-left: 6px!important
    }
    .pd-xl-7 {
        padding: 7px
    }
    .pd-xl-7-f {
        padding: 7px!important
    }
    .pd-xl-y-7 {
        padding-top: 7px;
        padding-bottom: 7px
    }
    .pd-xl-y-7-f {
        padding-top: 7px!important;
        padding-bottom: 7px!important
    }
    .pd-xl-x-7 {
        padding-left: 7px;
        padding-right: 7px
    }
    .pd-xl-x-7-f {
        padding-left: 7px!important;
        padding-right: 7px!important
    }
    .pd-xl-t-7 {
        padding-top: 7px
    }
    .pd-xl-r-7 {
        padding-right: 7px
    }
    .pd-xl-b-7 {
        padding-bottom: 7px
    }
    .pd-xl-l-7 {
        padding-left: 7px
    }
    .pd-xl-t-7-f {
        padding-top: 7px!important
    }
    .pd-xl-r-7-f {
        padding-right: 7px!important
    }
    .pd-xl-b-7-f {
        padding-bottom: 7px!important
    }
    .pd-xl-l-7-f {
        padding-left: 7px!important
    }
    .pd-xl-8 {
        padding: 8px
    }
    .pd-xl-8-f {
        padding: 8px!important
    }
    .pd-xl-y-8 {
        padding-top: 8px;
        padding-bottom: 8px
    }
    .pd-xl-y-8-f {
        padding-top: 8px!important;
        padding-bottom: 8px!important
    }
    .pd-xl-x-8 {
        padding-left: 8px;
        padding-right: 8px
    }
    .pd-xl-x-8-f {
        padding-left: 8px!important;
        padding-right: 8px!important
    }
    .pd-xl-t-8 {
        padding-top: 8px
    }
    .pd-xl-r-8 {
        padding-right: 8px
    }
    .pd-xl-b-8 {
        padding-bottom: 8px
    }
    .pd-xl-l-8 {
        padding-left: 8px
    }
    .pd-xl-t-8-f {
        padding-top: 8px!important
    }
    .pd-xl-r-8-f {
        padding-right: 8px!important
    }
    .pd-xl-b-8-f {
        padding-bottom: 8px!important
    }
    .pd-xl-l-8-f {
        padding-left: 8px!important
    }
    .pd-xl-9 {
        padding: 9px
    }
    .pd-xl-9-f {
        padding: 9px!important
    }
    .pd-xl-y-9 {
        padding-top: 9px;
        padding-bottom: 9px
    }
    .pd-xl-y-9-f {
        padding-top: 9px!important;
        padding-bottom: 9px!important
    }
    .pd-xl-x-9 {
        padding-left: 9px;
        padding-right: 9px
    }
    .pd-xl-x-9-f {
        padding-left: 9px!important;
        padding-right: 9px!important
    }
    .pd-xl-t-9 {
        padding-top: 9px
    }
    .pd-xl-r-9 {
        padding-right: 9px
    }
    .pd-xl-b-9 {
        padding-bottom: 9px
    }
    .pd-xl-l-9 {
        padding-left: 9px
    }
    .pd-xl-t-9-f {
        padding-top: 9px!important
    }
    .pd-xl-r-9-f {
        padding-right: 9px!important
    }
    .pd-xl-b-9-f {
        padding-bottom: 9px!important
    }
    .pd-xl-l-9-f {
        padding-left: 9px!important
    }
    .pd-xl-10 {
        padding: 10px
    }
    .pd-xl-10-f {
        padding: 10px!important
    }
    .pd-xl-y-10 {
        padding-top: 10px;
        padding-bottom: 10px
    }
    .pd-xl-y-10-f {
        padding-top: 10px!important;
        padding-bottom: 10px!important
    }
    .pd-xl-x-10 {
        padding-left: 10px;
        padding-right: 10px
    }
    .pd-xl-x-10-f {
        padding-left: 10px!important;
        padding-right: 10px!important
    }
    .pd-xl-t-10 {
        padding-top: 10px
    }
    .pd-xl-r-10 {
        padding-right: 10px
    }
    .pd-xl-b-10 {
        padding-bottom: 10px
    }
    .pd-xl-l-10 {
        padding-left: 10px
    }
    .pd-xl-t-10-f {
        padding-top: 10px!important
    }
    .pd-xl-r-10-f {
        padding-right: 10px!important
    }
    .pd-xl-b-10-f {
        padding-bottom: 10px!important
    }
    .pd-xl-l-10-f {
        padding-left: 10px!important
    }
    .pd-xl-15 {
        padding: 15px
    }
    .pd-xl-15-f {
        padding: 15px!important
    }
    .pd-xl-y-15 {
        padding-top: 15px;
        padding-bottom: 15px
    }
    .pd-xl-y-15-f {
        padding-top: 15px!important;
        padding-bottom: 15px!important
    }
    .pd-xl-x-15 {
        padding-left: 15px;
        padding-right: 15px
    }
    .pd-xl-x-15-f {
        padding-left: 15px!important;
        padding-right: 15px!important
    }
    .pd-xl-t-15 {
        padding-top: 15px
    }
    .pd-xl-r-15 {
        padding-right: 15px
    }
    .pd-xl-b-15 {
        padding-bottom: 15px
    }
    .pd-xl-l-15 {
        padding-left: 15px
    }
    .pd-xl-t-15-f {
        padding-top: 15px!important
    }
    .pd-xl-r-15-f {
        padding-right: 15px!important
    }
    .pd-xl-b-15-f {
        padding-bottom: 15px!important
    }
    .pd-xl-l-15-f {
        padding-left: 15px!important
    }
    .pd-xl-20 {
        padding: 20px
    }
    .pd-xl-20-f {
        padding: 20px!important
    }
    .pd-xl-y-20 {
        padding-top: 20px;
        padding-bottom: 20px
    }
    .pd-xl-y-20-f {
        padding-top: 20px!important;
        padding-bottom: 20px!important
    }
    .pd-xl-x-20 {
        padding-left: 20px;
        padding-right: 20px
    }
    .pd-xl-x-20-f {
        padding-left: 20px!important;
        padding-right: 20px!important
    }
    .pd-xl-t-20 {
        padding-top: 20px
    }
    .pd-xl-r-20 {
        padding-right: 20px
    }
    .pd-xl-b-20 {
        padding-bottom: 20px
    }
    .pd-xl-l-20 {
        padding-left: 20px
    }
    .pd-xl-t-20-f {
        padding-top: 20px!important
    }
    .pd-xl-r-20-f {
        padding-right: 20px!important
    }
    .pd-xl-b-20-f {
        padding-bottom: 20px!important
    }
    .pd-xl-l-20-f {
        padding-left: 20px!important
    }
    .pd-xl-25 {
        padding: 25px
    }
    .pd-xl-25-f {
        padding: 25px!important
    }
    .pd-xl-y-25 {
        padding-top: 25px;
        padding-bottom: 25px
    }
    .pd-xl-y-25-f {
        padding-top: 25px!important;
        padding-bottom: 25px!important
    }
    .pd-xl-x-25 {
        padding-left: 25px;
        padding-right: 25px
    }
    .pd-xl-x-25-f {
        padding-left: 25px!important;
        padding-right: 25px!important
    }
    .pd-xl-t-25 {
        padding-top: 25px
    }
    .pd-xl-r-25 {
        padding-right: 25px
    }
    .pd-xl-b-25 {
        padding-bottom: 25px
    }
    .pd-xl-l-25 {
        padding-left: 25px
    }
    .pd-xl-t-25-f {
        padding-top: 25px!important
    }
    .pd-xl-r-25-f {
        padding-right: 25px!important
    }
    .pd-xl-b-25-f {
        padding-bottom: 25px!important
    }
    .pd-xl-l-25-f {
        padding-left: 25px!important
    }
    .pd-xl-30 {
        padding: 30px
    }
    .pd-xl-30-f {
        padding: 30px!important
    }
    .pd-xl-y-30 {
        padding-top: 30px;
        padding-bottom: 30px
    }
    .pd-xl-y-30-f {
        padding-top: 30px!important;
        padding-bottom: 30px!important
    }
    .pd-xl-x-30 {
        padding-left: 30px;
        padding-right: 30px
    }
    .pd-xl-x-30-f {
        padding-left: 30px!important;
        padding-right: 30px!important
    }
    .pd-xl-t-30 {
        padding-top: 30px
    }
    .pd-xl-r-30 {
        padding-right: 30px
    }
    .pd-xl-b-30 {
        padding-bottom: 30px
    }
    .pd-xl-l-30 {
        padding-left: 30px
    }
    .pd-xl-t-30-f {
        padding-top: 30px!important
    }
    .pd-xl-r-30-f {
        padding-right: 30px!important
    }
    .pd-xl-b-30-f {
        padding-bottom: 30px!important
    }
    .pd-xl-l-30-f {
        padding-left: 30px!important
    }
    .pd-xl-35 {
        padding: 35px
    }
    .pd-xl-35-f {
        padding: 35px!important
    }
    .pd-xl-y-35 {
        padding-top: 35px;
        padding-bottom: 35px
    }
    .pd-xl-y-35-f {
        padding-top: 35px!important;
        padding-bottom: 35px!important
    }
    .pd-xl-x-35 {
        padding-left: 35px;
        padding-right: 35px
    }
    .pd-xl-x-35-f {
        padding-left: 35px!important;
        padding-right: 35px!important
    }
    .pd-xl-t-35 {
        padding-top: 35px
    }
    .pd-xl-r-35 {
        padding-right: 35px
    }
    .pd-xl-b-35 {
        padding-bottom: 35px
    }
    .pd-xl-l-35 {
        padding-left: 35px
    }
    .pd-xl-t-35-f {
        padding-top: 35px!important
    }
    .pd-xl-r-35-f {
        padding-right: 35px!important
    }
    .pd-xl-b-35-f {
        padding-bottom: 35px!important
    }
    .pd-xl-l-35-f {
        padding-left: 35px!important
    }
    .pd-xl-40 {
        padding: 40px
    }
    .pd-xl-40-f {
        padding: 40px!important
    }
    .pd-xl-y-40 {
        padding-top: 40px;
        padding-bottom: 40px
    }
    .pd-xl-y-40-f {
        padding-top: 40px!important;
        padding-bottom: 40px!important
    }
    .pd-xl-x-40 {
        padding-left: 40px;
        padding-right: 40px
    }
    .pd-xl-x-40-f {
        padding-left: 40px!important;
        padding-right: 40px!important
    }
    .pd-xl-t-40 {
        padding-top: 40px
    }
    .pd-xl-r-40 {
        padding-right: 40px
    }
    .pd-xl-b-40 {
        padding-bottom: 40px
    }
    .pd-xl-l-40 {
        padding-left: 40px
    }
    .pd-xl-t-40-f {
        padding-top: 40px!important
    }
    .pd-xl-r-40-f {
        padding-right: 40px!important
    }
    .pd-xl-b-40-f {
        padding-bottom: 40px!important
    }
    .pd-xl-l-40-f {
        padding-left: 40px!important
    }
    .pd-xl-45 {
        padding: 45px
    }
    .pd-xl-45-f {
        padding: 45px!important
    }
    .pd-xl-y-45 {
        padding-top: 45px;
        padding-bottom: 45px
    }
    .pd-xl-y-45-f {
        padding-top: 45px!important;
        padding-bottom: 45px!important
    }
    .pd-xl-x-45 {
        padding-left: 45px;
        padding-right: 45px
    }
    .pd-xl-x-45-f {
        padding-left: 45px!important;
        padding-right: 45px!important
    }
    .pd-xl-t-45 {
        padding-top: 45px
    }
    .pd-xl-r-45 {
        padding-right: 45px
    }
    .pd-xl-b-45 {
        padding-bottom: 45px
    }
    .pd-xl-l-45 {
        padding-left: 45px
    }
    .pd-xl-t-45-f {
        padding-top: 45px!important
    }
    .pd-xl-r-45-f {
        padding-right: 45px!important
    }
    .pd-xl-b-45-f {
        padding-bottom: 45px!important
    }
    .pd-xl-l-45-f {
        padding-left: 45px!important
    }
    .pd-xl-50 {
        padding: 50px
    }
    .pd-xl-50-f {
        padding: 50px!important
    }
    .pd-xl-y-50 {
        padding-top: 50px;
        padding-bottom: 50px
    }
    .pd-xl-y-50-f {
        padding-top: 50px!important;
        padding-bottom: 50px!important
    }
    .pd-xl-x-50 {
        padding-left: 50px;
        padding-right: 50px
    }
    .pd-xl-x-50-f {
        padding-left: 50px!important;
        padding-right: 50px!important
    }
    .pd-xl-t-50 {
        padding-top: 50px
    }
    .pd-xl-r-50 {
        padding-right: 50px
    }
    .pd-xl-b-50 {
        padding-bottom: 50px
    }
    .pd-xl-l-50 {
        padding-left: 50px
    }
    .pd-xl-t-50-f {
        padding-top: 50px!important
    }
    .pd-xl-r-50-f {
        padding-right: 50px!important
    }
    .pd-xl-b-50-f {
        padding-bottom: 50px!important
    }
    .pd-xl-l-50-f {
        padding-left: 50px!important
    }
    .pd-xl-55 {
        padding: 55px
    }
    .pd-xl-55-f {
        padding: 55px!important
    }
    .pd-xl-y-55 {
        padding-top: 55px;
        padding-bottom: 55px
    }
    .pd-xl-y-55-f {
        padding-top: 55px!important;
        padding-bottom: 55px!important
    }
    .pd-xl-x-55 {
        padding-left: 55px;
        padding-right: 55px
    }
    .pd-xl-x-55-f {
        padding-left: 55px!important;
        padding-right: 55px!important
    }
    .pd-xl-t-55 {
        padding-top: 55px
    }
    .pd-xl-r-55 {
        padding-right: 55px
    }
    .pd-xl-b-55 {
        padding-bottom: 55px
    }
    .pd-xl-l-55 {
        padding-left: 55px
    }
    .pd-xl-t-55-f {
        padding-top: 55px!important
    }
    .pd-xl-r-55-f {
        padding-right: 55px!important
    }
    .pd-xl-b-55-f {
        padding-bottom: 55px!important
    }
    .pd-xl-l-55-f {
        padding-left: 55px!important
    }
    .pd-xl-60 {
        padding: 60px
    }
    .pd-xl-60-f {
        padding: 60px!important
    }
    .pd-xl-y-60 {
        padding-top: 60px;
        padding-bottom: 60px
    }
    .pd-xl-y-60-f {
        padding-top: 60px!important;
        padding-bottom: 60px!important
    }
    .pd-xl-x-60 {
        padding-left: 60px;
        padding-right: 60px
    }
    .pd-xl-x-60-f {
        padding-left: 60px!important;
        padding-right: 60px!important
    }
    .pd-xl-t-60 {
        padding-top: 60px
    }
    .pd-xl-r-60 {
        padding-right: 60px
    }
    .pd-xl-b-60 {
        padding-bottom: 60px
    }
    .pd-xl-l-60 {
        padding-left: 60px
    }
    .pd-xl-t-60-f {
        padding-top: 60px!important
    }
    .pd-xl-r-60-f {
        padding-right: 60px!important
    }
    .pd-xl-b-60-f {
        padding-bottom: 60px!important
    }
    .pd-xl-l-60-f {
        padding-left: 60px!important
    }
    .pd-xl-65 {
        padding: 65px
    }
    .pd-xl-65-f {
        padding: 65px!important
    }
    .pd-xl-y-65 {
        padding-top: 65px;
        padding-bottom: 65px
    }
    .pd-xl-y-65-f {
        padding-top: 65px!important;
        padding-bottom: 65px!important
    }
    .pd-xl-x-65 {
        padding-left: 65px;
        padding-right: 65px
    }
    .pd-xl-x-65-f {
        padding-left: 65px!important;
        padding-right: 65px!important
    }
    .pd-xl-t-65 {
        padding-top: 65px
    }
    .pd-xl-r-65 {
        padding-right: 65px
    }
    .pd-xl-b-65 {
        padding-bottom: 65px
    }
    .pd-xl-l-65 {
        padding-left: 65px
    }
    .pd-xl-t-65-f {
        padding-top: 65px!important
    }
    .pd-xl-r-65-f {
        padding-right: 65px!important
    }
    .pd-xl-b-65-f {
        padding-bottom: 65px!important
    }
    .pd-xl-l-65-f {
        padding-left: 65px!important
    }
    .pd-xl-70 {
        padding: 70px
    }
    .pd-xl-70-f {
        padding: 70px!important
    }
    .pd-xl-y-70 {
        padding-top: 70px;
        padding-bottom: 70px
    }
    .pd-xl-y-70-f {
        padding-top: 70px!important;
        padding-bottom: 70px!important
    }
    .pd-xl-x-70 {
        padding-left: 70px;
        padding-right: 70px
    }
    .pd-xl-x-70-f {
        padding-left: 70px!important;
        padding-right: 70px!important
    }
    .pd-xl-t-70 {
        padding-top: 70px
    }
    .pd-xl-r-70 {
        padding-right: 70px
    }
    .pd-xl-b-70 {
        padding-bottom: 70px
    }
    .pd-xl-l-70 {
        padding-left: 70px
    }
    .pd-xl-t-70-f {
        padding-top: 70px!important
    }
    .pd-xl-r-70-f {
        padding-right: 70px!important
    }
    .pd-xl-b-70-f {
        padding-bottom: 70px!important
    }
    .pd-xl-l-70-f {
        padding-left: 70px!important
    }
    .pd-xl-75 {
        padding: 75px
    }
    .pd-xl-75-f {
        padding: 75px!important
    }
    .pd-xl-y-75 {
        padding-top: 75px;
        padding-bottom: 75px
    }
    .pd-xl-y-75-f {
        padding-top: 75px!important;
        padding-bottom: 75px!important
    }
    .pd-xl-x-75 {
        padding-left: 75px;
        padding-right: 75px
    }
    .pd-xl-x-75-f {
        padding-left: 75px!important;
        padding-right: 75px!important
    }
    .pd-xl-t-75 {
        padding-top: 75px
    }
    .pd-xl-r-75 {
        padding-right: 75px
    }
    .pd-xl-b-75 {
        padding-bottom: 75px
    }
    .pd-xl-l-75 {
        padding-left: 75px
    }
    .pd-xl-t-75-f {
        padding-top: 75px!important
    }
    .pd-xl-r-75-f {
        padding-right: 75px!important
    }
    .pd-xl-b-75-f {
        padding-bottom: 75px!important
    }
    .pd-xl-l-75-f {
        padding-left: 75px!important
    }
    .pd-xl-80 {
        padding: 80px
    }
    .pd-xl-80-f {
        padding: 80px!important
    }
    .pd-xl-y-80 {
        padding-top: 80px;
        padding-bottom: 80px
    }
    .pd-xl-y-80-f {
        padding-top: 80px!important;
        padding-bottom: 80px!important
    }
    .pd-xl-x-80 {
        padding-left: 80px;
        padding-right: 80px
    }
    .pd-xl-x-80-f {
        padding-left: 80px!important;
        padding-right: 80px!important
    }
    .pd-xl-t-80 {
        padding-top: 80px
    }
    .pd-xl-r-80 {
        padding-right: 80px
    }
    .pd-xl-b-80 {
        padding-bottom: 80px
    }
    .pd-xl-l-80 {
        padding-left: 80px
    }
    .pd-xl-t-80-f {
        padding-top: 80px!important
    }
    .pd-xl-r-80-f {
        padding-right: 80px!important
    }
    .pd-xl-b-80-f {
        padding-bottom: 80px!important
    }
    .pd-xl-l-80-f {
        padding-left: 80px!important
    }
    .pd-xl-85 {
        padding: 85px
    }
    .pd-xl-85-f {
        padding: 85px!important
    }
    .pd-xl-y-85 {
        padding-top: 85px;
        padding-bottom: 85px
    }
    .pd-xl-y-85-f {
        padding-top: 85px!important;
        padding-bottom: 85px!important
    }
    .pd-xl-x-85 {
        padding-left: 85px;
        padding-right: 85px
    }
    .pd-xl-x-85-f {
        padding-left: 85px!important;
        padding-right: 85px!important
    }
    .pd-xl-t-85 {
        padding-top: 85px
    }
    .pd-xl-r-85 {
        padding-right: 85px
    }
    .pd-xl-b-85 {
        padding-bottom: 85px
    }
    .pd-xl-l-85 {
        padding-left: 85px
    }
    .pd-xl-t-85-f {
        padding-top: 85px!important
    }
    .pd-xl-r-85-f {
        padding-right: 85px!important
    }
    .pd-xl-b-85-f {
        padding-bottom: 85px!important
    }
    .pd-xl-l-85-f {
        padding-left: 85px!important
    }
    .pd-xl-90 {
        padding: 90px
    }
    .pd-xl-90-f {
        padding: 90px!important
    }
    .pd-xl-y-90 {
        padding-top: 90px;
        padding-bottom: 90px
    }
    .pd-xl-y-90-f {
        padding-top: 90px!important;
        padding-bottom: 90px!important
    }
    .pd-xl-x-90 {
        padding-left: 90px;
        padding-right: 90px
    }
    .pd-xl-x-90-f {
        padding-left: 90px!important;
        padding-right: 90px!important
    }
    .pd-xl-t-90 {
        padding-top: 90px
    }
    .pd-xl-r-90 {
        padding-right: 90px
    }
    .pd-xl-b-90 {
        padding-bottom: 90px
    }
    .pd-xl-l-90 {
        padding-left: 90px
    }
    .pd-xl-t-90-f {
        padding-top: 90px!important
    }
    .pd-xl-r-90-f {
        padding-right: 90px!important
    }
    .pd-xl-b-90-f {
        padding-bottom: 90px!important
    }
    .pd-xl-l-90-f {
        padding-left: 90px!important
    }
    .pd-xl-95 {
        padding: 95px
    }
    .pd-xl-95-f {
        padding: 95px!important
    }
    .pd-xl-y-95 {
        padding-top: 95px;
        padding-bottom: 95px
    }
    .pd-xl-y-95-f {
        padding-top: 95px!important;
        padding-bottom: 95px!important
    }
    .pd-xl-x-95 {
        padding-left: 95px;
        padding-right: 95px
    }
    .pd-xl-x-95-f {
        padding-left: 95px!important;
        padding-right: 95px!important
    }
    .pd-xl-t-95 {
        padding-top: 95px
    }
    .pd-xl-r-95 {
        padding-right: 95px
    }
    .pd-xl-b-95 {
        padding-bottom: 95px
    }
    .pd-xl-l-95 {
        padding-left: 95px
    }
    .pd-xl-t-95-f {
        padding-top: 95px!important
    }
    .pd-xl-r-95-f {
        padding-right: 95px!important
    }
    .pd-xl-b-95-f {
        padding-bottom: 95px!important
    }
    .pd-xl-l-95-f {
        padding-left: 95px!important
    }
    .pd-xl-100 {
        padding: 100px
    }
    .pd-xl-100-f {
        padding: 100px!important
    }
    .pd-xl-y-100 {
        padding-top: 100px;
        padding-bottom: 100px
    }
    .pd-xl-y-100-f {
        padding-top: 100px!important;
        padding-bottom: 100px!important
    }
    .pd-xl-x-100 {
        padding-left: 100px;
        padding-right: 100px
    }
    .pd-xl-x-100-f {
        padding-left: 100px!important;
        padding-right: 100px!important
    }
    .pd-xl-t-100 {
        padding-top: 100px
    }
    .pd-xl-r-100 {
        padding-right: 100px
    }
    .pd-xl-b-100 {
        padding-bottom: 100px
    }
    .pd-xl-l-100 {
        padding-left: 100px
    }
    .pd-xl-t-100-f {
        padding-top: 100px!important
    }
    .pd-xl-r-100-f {
        padding-right: 100px!important
    }
    .pd-xl-b-100-f {
        padding-bottom: 100px!important
    }
    .pd-xl-l-100-f {
        padding-left: 100px!important
    }
    .pd-xl-110 {
        padding: 110px
    }
    .pd-xl-110-f {
        padding: 110px!important
    }
    .pd-xl-y-110 {
        padding-top: 110px;
        padding-bottom: 110px
    }
    .pd-xl-y-110-f {
        padding-top: 110px!important;
        padding-bottom: 110px!important
    }
    .pd-xl-x-110 {
        padding-left: 110px;
        padding-right: 110px
    }
    .pd-xl-x-110-f {
        padding-left: 110px!important;
        padding-right: 110px!important
    }
    .pd-xl-t-110 {
        padding-top: 110px
    }
    .pd-xl-r-110 {
        padding-right: 110px
    }
    .pd-xl-b-110 {
        padding-bottom: 110px
    }
    .pd-xl-l-110 {
        padding-left: 110px
    }
    .pd-xl-t-110-f {
        padding-top: 110px!important
    }
    .pd-xl-r-110-f {
        padding-right: 110px!important
    }
    .pd-xl-b-110-f {
        padding-bottom: 110px!important
    }
    .pd-xl-l-110-f {
        padding-left: 110px!important
    }
    .pd-xl-120 {
        padding: 120px
    }
    .pd-xl-120-f {
        padding: 120px!important
    }
    .pd-xl-y-120 {
        padding-top: 120px;
        padding-bottom: 120px
    }
    .pd-xl-y-120-f {
        padding-top: 120px!important;
        padding-bottom: 120px!important
    }
    .pd-xl-x-120 {
        padding-left: 120px;
        padding-right: 120px
    }
    .pd-xl-x-120-f {
        padding-left: 120px!important;
        padding-right: 120px!important
    }
    .pd-xl-t-120 {
        padding-top: 120px
    }
    .pd-xl-r-120 {
        padding-right: 120px
    }
    .pd-xl-b-120 {
        padding-bottom: 120px
    }
    .pd-xl-l-120 {
        padding-left: 120px
    }
    .pd-xl-t-120-f {
        padding-top: 120px!important
    }
    .pd-xl-r-120-f {
        padding-right: 120px!important
    }
    .pd-xl-b-120-f {
        padding-bottom: 120px!important
    }
    .pd-xl-l-120-f {
        padding-left: 120px!important
    }
    .pd-xl-130 {
        padding: 130px
    }
    .pd-xl-130-f {
        padding: 130px!important
    }
    .pd-xl-y-130 {
        padding-top: 130px;
        padding-bottom: 130px
    }
    .pd-xl-y-130-f {
        padding-top: 130px!important;
        padding-bottom: 130px!important
    }
    .pd-xl-x-130 {
        padding-left: 130px;
        padding-right: 130px
    }
    .pd-xl-x-130-f {
        padding-left: 130px!important;
        padding-right: 130px!important
    }
    .pd-xl-t-130 {
        padding-top: 130px
    }
    .pd-xl-r-130 {
        padding-right: 130px
    }
    .pd-xl-b-130 {
        padding-bottom: 130px
    }
    .pd-xl-l-130 {
        padding-left: 130px
    }
    .pd-xl-t-130-f {
        padding-top: 130px!important
    }
    .pd-xl-r-130-f {
        padding-right: 130px!important
    }
    .pd-xl-b-130-f {
        padding-bottom: 130px!important
    }
    .pd-xl-l-130-f {
        padding-left: 130px!important
    }
    .pd-xl-140 {
        padding: 140px
    }
    .pd-xl-140-f {
        padding: 140px!important
    }
    .pd-xl-y-140 {
        padding-top: 140px;
        padding-bottom: 140px
    }
    .pd-xl-y-140-f {
        padding-top: 140px!important;
        padding-bottom: 140px!important
    }
    .pd-xl-x-140 {
        padding-left: 140px;
        padding-right: 140px
    }
    .pd-xl-x-140-f {
        padding-left: 140px!important;
        padding-right: 140px!important
    }
    .pd-xl-t-140 {
        padding-top: 140px
    }
    .pd-xl-r-140 {
        padding-right: 140px
    }
    .pd-xl-b-140 {
        padding-bottom: 140px
    }
    .pd-xl-l-140 {
        padding-left: 140px
    }
    .pd-xl-t-140-f {
        padding-top: 140px!important
    }
    .pd-xl-r-140-f {
        padding-right: 140px!important
    }
    .pd-xl-b-140-f {
        padding-bottom: 140px!important
    }
    .pd-xl-l-140-f {
        padding-left: 140px!important
    }
    .pd-xl-150 {
        padding: 150px
    }
    .pd-xl-150-f {
        padding: 150px!important
    }
    .pd-xl-y-150 {
        padding-top: 150px;
        padding-bottom: 150px
    }
    .pd-xl-y-150-f {
        padding-top: 150px!important;
        padding-bottom: 150px!important
    }
    .pd-xl-x-150 {
        padding-left: 150px;
        padding-right: 150px
    }
    .pd-xl-x-150-f {
        padding-left: 150px!important;
        padding-right: 150px!important
    }
    .pd-xl-t-150 {
        padding-top: 150px
    }
    .pd-xl-r-150 {
        padding-right: 150px
    }
    .pd-xl-b-150 {
        padding-bottom: 150px
    }
    .pd-xl-l-150 {
        padding-left: 150px
    }
    .pd-xl-t-150-f {
        padding-top: 150px!important
    }
    .pd-xl-r-150-f {
        padding-right: 150px!important
    }
    .pd-xl-b-150-f {
        padding-bottom: 150px!important
    }
    .pd-xl-l-150-f {
        padding-left: 150px!important
    }
    .pd-xl-160 {
        padding: 160px
    }
    .pd-xl-160-f {
        padding: 160px!important
    }
    .pd-xl-y-160 {
        padding-top: 160px;
        padding-bottom: 160px
    }
    .pd-xl-y-160-f {
        padding-top: 160px!important;
        padding-bottom: 160px!important
    }
    .pd-xl-x-160 {
        padding-left: 160px;
        padding-right: 160px
    }
    .pd-xl-x-160-f {
        padding-left: 160px!important;
        padding-right: 160px!important
    }
    .pd-xl-t-160 {
        padding-top: 160px
    }
    .pd-xl-r-160 {
        padding-right: 160px
    }
    .pd-xl-b-160 {
        padding-bottom: 160px
    }
    .pd-xl-l-160 {
        padding-left: 160px
    }
    .pd-xl-t-160-f {
        padding-top: 160px!important
    }
    .pd-xl-r-160-f {
        padding-right: 160px!important
    }
    .pd-xl-b-160-f {
        padding-bottom: 160px!important
    }
    .pd-xl-l-160-f {
        padding-left: 160px!important
    }
    .pd-xl-170 {
        padding: 170px
    }
    .pd-xl-170-f {
        padding: 170px!important
    }
    .pd-xl-y-170 {
        padding-top: 170px;
        padding-bottom: 170px
    }
    .pd-xl-y-170-f {
        padding-top: 170px!important;
        padding-bottom: 170px!important
    }
    .pd-xl-x-170 {
        padding-left: 170px;
        padding-right: 170px
    }
    .pd-xl-x-170-f {
        padding-left: 170px!important;
        padding-right: 170px!important
    }
    .pd-xl-t-170 {
        padding-top: 170px
    }
    .pd-xl-r-170 {
        padding-right: 170px
    }
    .pd-xl-b-170 {
        padding-bottom: 170px
    }
    .pd-xl-l-170 {
        padding-left: 170px
    }
    .pd-xl-t-170-f {
        padding-top: 170px!important
    }
    .pd-xl-r-170-f {
        padding-right: 170px!important
    }
    .pd-xl-b-170-f {
        padding-bottom: 170px!important
    }
    .pd-xl-l-170-f {
        padding-left: 170px!important
    }
    .pd-xl-180 {
        padding: 180px
    }
    .pd-xl-180-f {
        padding: 180px!important
    }
    .pd-xl-y-180 {
        padding-top: 180px;
        padding-bottom: 180px
    }
    .pd-xl-y-180-f {
        padding-top: 180px!important;
        padding-bottom: 180px!important
    }
    .pd-xl-x-180 {
        padding-left: 180px;
        padding-right: 180px
    }
    .pd-xl-x-180-f {
        padding-left: 180px!important;
        padding-right: 180px!important
    }
    .pd-xl-t-180 {
        padding-top: 180px
    }
    .pd-xl-r-180 {
        padding-right: 180px
    }
    .pd-xl-b-180 {
        padding-bottom: 180px
    }
    .pd-xl-l-180 {
        padding-left: 180px
    }
    .pd-xl-t-180-f {
        padding-top: 180px!important
    }
    .pd-xl-r-180-f {
        padding-right: 180px!important
    }
    .pd-xl-b-180-f {
        padding-bottom: 180px!important
    }
    .pd-xl-l-180-f {
        padding-left: 180px!important
    }
    .pd-xl-190 {
        padding: 190px
    }
    .pd-xl-190-f {
        padding: 190px!important
    }
    .pd-xl-y-190 {
        padding-top: 190px;
        padding-bottom: 190px
    }
    .pd-xl-y-190-f {
        padding-top: 190px!important;
        padding-bottom: 190px!important
    }
    .pd-xl-x-190 {
        padding-left: 190px;
        padding-right: 190px
    }
    .pd-xl-x-190-f {
        padding-left: 190px!important;
        padding-right: 190px!important
    }
    .pd-xl-t-190 {
        padding-top: 190px
    }
    .pd-xl-r-190 {
        padding-right: 190px
    }
    .pd-xl-b-190 {
        padding-bottom: 190px
    }
    .pd-xl-l-190 {
        padding-left: 190px
    }
    .pd-xl-t-190-f {
        padding-top: 190px!important
    }
    .pd-xl-r-190-f {
        padding-right: 190px!important
    }
    .pd-xl-b-190-f {
        padding-bottom: 190px!important
    }
    .pd-xl-l-190-f {
        padding-left: 190px!important
    }
    .pd-xl-200 {
        padding: 200px
    }
    .pd-xl-200-f {
        padding: 200px!important
    }
    .pd-xl-y-200 {
        padding-top: 200px;
        padding-bottom: 200px
    }
    .pd-xl-y-200-f {
        padding-top: 200px!important;
        padding-bottom: 200px!important
    }
    .pd-xl-x-200 {
        padding-left: 200px;
        padding-right: 200px
    }
    .pd-xl-x-200-f {
        padding-left: 200px!important;
        padding-right: 200px!important
    }
    .pd-xl-t-200 {
        padding-top: 200px
    }
    .pd-xl-r-200 {
        padding-right: 200px
    }
    .pd-xl-b-200 {
        padding-bottom: 200px
    }
    .pd-xl-l-200 {
        padding-left: 200px
    }
    .pd-xl-t-200-f {
        padding-top: 200px!important
    }
    .pd-xl-r-200-f {
        padding-right: 200px!important
    }
    .pd-xl-b-200-f {
        padding-bottom: 200px!important
    }
    .pd-xl-l-200-f {
        padding-left: 200px!important
    }
}

.pd-12 {
    padding: 12px
}

.pd-12-f {
    padding: 12px!important
}

.pd-y-12 {
    padding-top: 12px;
    padding-bottom: 12px
}

.pd-y-12-f {
    padding-top: 12px!important;
    padding-bottom: 12px!important
}

.pd-x-12 {
    padding-left: 12px;
    padding-right: 12px
}

.pd-x-12-f {
    padding-left: 12px!important;
    padding-right: 12px!important
}

.pd-t-12 {
    padding-top: 12px
}

.pd-r-12 {
    padding-right: 12px
}

.pd-b-12 {
    padding-bottom: 12px
}

.pd-l-12 {
    padding-left: 12px
}

.pd-t-12-f {
    padding-top: 12px!important
}

.pd-r-12-f {
    padding-right: 12px!important
}

.pd-b-12-f {
    padding-bottom: 12px!important
}

.pd-l-12-f {
    padding-left: 12px!important
}

.pd-13 {
    padding: 13px
}

.pd-13-f {
    padding: 13px!important
}

.pd-y-13 {
    padding-top: 13px;
    padding-bottom: 13px
}

.pd-y-13-f {
    padding-top: 13px!important;
    padding-bottom: 13px!important
}

.pd-x-13 {
    padding-left: 13px;
    padding-right: 13px
}

.pd-x-13-f {
    padding-left: 13px!important;
    padding-right: 13px!important
}

.pd-t-13 {
    padding-top: 13px
}

.pd-r-13 {
    padding-right: 13px
}

.pd-b-13 {
    padding-bottom: 13px
}

.pd-l-13 {
    padding-left: 13px
}

.pd-t-13-f {
    padding-top: 13px!important
}

.pd-r-13-f {
    padding-right: 13px!important
}

.pd-b-13-f {
    padding-bottom: 13px!important
}

.pd-l-13-f {
    padding-left: 13px!important
}

.pos-absolute {
    position: absolute
}

.pos-relative {
    position: relative
}

.pos-fixed {
    position: fixed
}

.pos-static {
    position: static
}

.pos-absolute-f {
    position: absolute!important
}

.pos-relative-f {
    position: relative!important
}

.pos-fixed-f {
    position: fixed!important
}

.pos-static-f {
    position: static!important
}

.t-0 {
    top: 0
}

.r-0 {
    right: 0
}

.b-0 {
    bottom: 0
}

.l-0 {
    left: 0
}

.t--0 {
    top: 0
}

.r--0 {
    right: 0
}

.b--0 {
    bottom: 0
}

.l--0 {
    left: 0
}

.a-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.x-0,
.y-0 {
    left: 0;
    right: 0
}

.t-5 {
    top: 5px
}

.r-5 {
    right: 5px
}

.b-5 {
    bottom: 5px
}

.l-5 {
    left: 5px
}

.t--5 {
    top: -5px
}

.r--5 {
    right: -5px
}

.b--5 {
    bottom: -5px
}

.l--5 {
    left: -5px
}

.a-5 {
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px
}

.x-5,
.y-5 {
    left: 5px;
    right: 5px
}

.t-10 {
    top: 10px
}

.r-10 {
    right: 10px
}

.b-10 {
    bottom: 10px
}

.l-10 {
    left: 10px
}

.t--10 {
    top: -10px
}

.r--10 {
    right: -10px
}

.b--10 {
    bottom: -10px
}

.l--10 {
    left: -10px
}

.a-10 {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px
}

.x-10,
.y-10 {
    left: 10px;
    right: 10px
}

.t-15 {
    top: 15px
}

.r-15 {
    right: 15px
}

.b-15 {
    bottom: 15px
}

.l-15 {
    left: 15px
}

.t--15 {
    top: -15px
}

.r--15 {
    right: -15px
}

.b--15 {
    bottom: -15px
}

.l--15 {
    left: -15px
}

.a-15 {
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px
}

.x-15,
.y-15 {
    left: 15px;
    right: 15px
}

.t-20 {
    top: 20px
}

.r-20 {
    right: 20px
}

.b-20 {
    bottom: 20px
}

.l-20 {
    left: 20px
}

.t--20 {
    top: -20px
}

.r--20 {
    right: -20px
}

.b--20 {
    bottom: -20px
}

.l--20 {
    left: -20px
}

.a-20 {
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px
}

.x-20,
.y-20 {
    left: 20px;
    right: 20px
}

.t-25 {
    top: 25px
}

.r-25 {
    right: 25px
}

.b-25 {
    bottom: 25px
}

.l-25 {
    left: 25px
}

.t--25 {
    top: -25px
}

.r--25 {
    right: -25px
}

.b--25 {
    bottom: -25px
}

.l--25 {
    left: -25px
}

.a-25 {
    top: 25px;
    right: 25px;
    bottom: 25px;
    left: 25px
}

.x-25,
.y-25 {
    left: 25px;
    right: 25px
}

.t-30 {
    top: 30px
}

.r-30 {
    right: 30px
}

.b-30 {
    bottom: 30px
}

.l-30 {
    left: 30px
}

.t--30 {
    top: -30px
}

.r--30 {
    right: -30px
}

.b--30 {
    bottom: -30px
}

.l--30 {
    left: -30px
}

.a-30 {
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px
}

.x-30,
.y-30 {
    left: 30px;
    right: 30px
}

.t-35 {
    top: 35px
}

.r-35 {
    right: 35px
}

.b-35 {
    bottom: 35px
}

.l-35 {
    left: 35px
}

.t--35 {
    top: -35px
}

.r--35 {
    right: -35px
}

.b--35 {
    bottom: -35px
}

.l--35 {
    left: -35px
}

.a-35 {
    top: 35px;
    right: 35px;
    bottom: 35px;
    left: 35px
}

.x-35,
.y-35 {
    left: 35px;
    right: 35px
}

.t-40 {
    top: 40px
}

.r-40 {
    right: 40px
}

.b-40 {
    bottom: 40px
}

.l-40 {
    left: 40px
}

.t--40 {
    top: -40px
}

.r--40 {
    right: -40px
}

.b--40 {
    bottom: -40px
}

.l--40 {
    left: -40px
}

.a-40 {
    top: 40px;
    right: 40px;
    bottom: 40px;
    left: 40px
}

.x-40,
.y-40 {
    left: 40px;
    right: 40px
}

.t-45 {
    top: 45px
}

.r-45 {
    right: 45px
}

.b-45 {
    bottom: 45px
}

.l-45 {
    left: 45px
}

.t--45 {
    top: -45px
}

.r--45 {
    right: -45px
}

.b--45 {
    bottom: -45px
}

.l--45 {
    left: -45px
}

.a-45 {
    top: 45px;
    right: 45px;
    bottom: 45px;
    left: 45px
}

.x-45,
.y-45 {
    left: 45px;
    right: 45px
}

.t-50 {
    top: 50px
}

.r-50 {
    right: 50px
}

.b-50 {
    bottom: 50px
}

.l-50 {
    left: 50px
}

.t--50 {
    top: -50px
}

.r--50 {
    right: -50px
}

.b--50 {
    bottom: -50px
}

.l--50 {
    left: -50px
}

.a-50 {
    top: 50px;
    right: 50px;
    bottom: 50px;
    left: 50px
}

.x-50,
.y-50 {
    left: 50px;
    right: 50px
}

.t-55 {
    top: 55px
}

.r-55 {
    right: 55px
}

.b-55 {
    bottom: 55px
}

.l-55 {
    left: 55px
}

.t--55 {
    top: -55px
}

.r--55 {
    right: -55px
}

.b--55 {
    bottom: -55px
}

.l--55 {
    left: -55px
}

.a-55 {
    top: 55px;
    right: 55px;
    bottom: 55px;
    left: 55px
}

.x-55,
.y-55 {
    left: 55px;
    right: 55px
}

.t-60 {
    top: 60px
}

.r-60 {
    right: 60px
}

.b-60 {
    bottom: 60px
}

.l-60 {
    left: 60px
}

.t--60 {
    top: -60px
}

.r--60 {
    right: -60px
}

.b--60 {
    bottom: -60px
}

.l--60 {
    left: -60px
}

.a-60 {
    top: 60px;
    right: 60px;
    bottom: 60px;
    left: 60px
}

.x-60,
.y-60 {
    left: 60px;
    right: 60px
}

.t-65 {
    top: 65px
}

.r-65 {
    right: 65px
}

.b-65 {
    bottom: 65px
}

.l-65 {
    left: 65px
}

.t--65 {
    top: -65px
}

.r--65 {
    right: -65px
}

.b--65 {
    bottom: -65px
}

.l--65 {
    left: -65px
}

.a-65 {
    top: 65px;
    right: 65px;
    bottom: 65px;
    left: 65px
}

.x-65,
.y-65 {
    left: 65px;
    right: 65px
}

.t-70 {
    top: 70px
}

.r-70 {
    right: 70px
}

.b-70 {
    bottom: 70px
}

.l-70 {
    left: 70px
}

.t--70 {
    top: -70px
}

.r--70 {
    right: -70px
}

.b--70 {
    bottom: -70px
}

.l--70 {
    left: -70px
}

.a-70 {
    top: 70px;
    right: 70px;
    bottom: 70px;
    left: 70px
}

.x-70,
.y-70 {
    left: 70px;
    right: 70px
}

.t-75 {
    top: 75px
}

.r-75 {
    right: 75px
}

.b-75 {
    bottom: 75px
}

.l-75 {
    left: 75px
}

.t--75 {
    top: -75px
}

.r--75 {
    right: -75px
}

.b--75 {
    bottom: -75px
}

.l--75 {
    left: -75px
}

.a-75 {
    top: 75px;
    right: 75px;
    bottom: 75px;
    left: 75px
}

.x-75,
.y-75 {
    left: 75px;
    right: 75px
}

.t-80 {
    top: 80px
}

.r-80 {
    right: 80px
}

.b-80 {
    bottom: 80px
}

.l-80 {
    left: 80px
}

.t--80 {
    top: -80px
}

.r--80 {
    right: -80px
}

.b--80 {
    bottom: -80px
}

.l--80 {
    left: -80px
}

.a-80 {
    top: 80px;
    right: 80px;
    bottom: 80px;
    left: 80px
}

.x-80,
.y-80 {
    left: 80px;
    right: 80px
}

.t-85 {
    top: 85px
}

.r-85 {
    right: 85px
}

.b-85 {
    bottom: 85px
}

.l-85 {
    left: 85px
}

.t--85 {
    top: -85px
}

.r--85 {
    right: -85px
}

.b--85 {
    bottom: -85px
}

.l--85 {
    left: -85px
}

.a-85 {
    top: 85px;
    right: 85px;
    bottom: 85px;
    left: 85px
}

.x-85,
.y-85 {
    left: 85px;
    right: 85px
}

.t-90 {
    top: 90px
}

.r-90 {
    right: 90px
}

.b-90 {
    bottom: 90px
}

.l-90 {
    left: 90px
}

.t--90 {
    top: -90px
}

.r--90 {
    right: -90px
}

.b--90 {
    bottom: -90px
}

.l--90 {
    left: -90px
}

.a-90 {
    top: 90px;
    right: 90px;
    bottom: 90px;
    left: 90px
}

.x-90,
.y-90 {
    left: 90px;
    right: 90px
}

.t-95 {
    top: 95px
}

.r-95 {
    right: 95px
}

.b-95 {
    bottom: 95px
}

.l-95 {
    left: 95px
}

.t--95 {
    top: -95px
}

.r--95 {
    right: -95px
}

.b--95 {
    bottom: -95px
}

.l--95 {
    left: -95px
}

.a-95 {
    top: 95px;
    right: 95px;
    bottom: 95px;
    left: 95px
}

.x-95,
.y-95 {
    left: 95px;
    right: 95px
}

.t-100 {
    top: 100px
}

.r-100 {
    right: 100px
}

.b-100 {
    bottom: 100px
}

.l-100 {
    left: 100px
}

.t--100 {
    top: -100px
}

.r--100 {
    right: -100px
}

.b--100 {
    bottom: -100px
}

.l--100 {
    left: -100px
}

.a-100 {
    top: 100px;
    right: 100px;
    bottom: 100px;
    left: 100px
}

.x-100,
.y-100 {
    left: 100px;
    right: 100px
}

.t-2 {
    top: 2px
}

.r--5 {
    right: -5px
}

.z-index-0 {
    z-index: 0
}

.z-index-10 {
    z-index: 10
}

.z-index-50 {
    z-index: 50
}

.z-index-100 {
    z-index: 100
}

.z-index-150 {
    z-index: 150
}

.z-index-200 {
    z-index: 200
}

@media (min-width:480px) {
    .pos-xs-absolute {
        position: absolute
    }
    .pos-xs-relative {
        position: relative
    }
    .pos-xs-fixed {
        position: fixed
    }
    .pos-xs-static {
        position: static
    }
    .t-xs-auto {
        top: auto
    }
    .r-xs-auto {
        right: auto
    }
    .b-xs-auto {
        bottom: auto
    }
    .l-xs-auto {
        left: auto
    }
}

@media (min-width:576px) {
    .pos-sm-absolute {
        position: absolute
    }
    .pos-sm-relative {
        position: relative
    }
    .pos-sm-fixed {
        position: fixed
    }
    .pos-sm-static {
        position: static
    }
    .t-sm-auto {
        top: auto
    }
    .r-sm-auto {
        right: auto
    }
    .b-sm-auto {
        bottom: auto
    }
    .l-sm-auto {
        left: auto
    }
}

@media (min-width:768px) {
    .pos-md-absolute {
        position: absolute
    }
    .pos-md-relative {
        position: relative
    }
    .pos-md-fixed {
        position: fixed
    }
    .pos-md-static {
        position: static
    }
    .t-md-auto {
        top: auto
    }
    .r-md-auto {
        right: auto
    }
    .b-md-auto {
        bottom: auto
    }
    .l-md-auto {
        left: auto
    }
}

@media (min-width:992px) {
    .pos-lg-absolute {
        position: absolute
    }
    .pos-lg-relative {
        position: relative
    }
    .pos-lg-fixed {
        position: fixed
    }
    .pos-lg-static {
        position: static
    }
    .t-lg-auto {
        top: auto
    }
    .r-lg-auto {
        right: auto
    }
    .b-lg-auto {
        bottom: auto
    }
    .l-lg-auto {
        left: auto
    }
}

@media (min-width:1200px) {
    .pos-xl-absolute {
        position: absolute
    }
    .pos-xl-relative {
        position: relative
    }
    .pos-xl-fixed {
        position: fixed
    }
    .pos-xl-static {
        position: static
    }
    .t-xl-auto {
        top: auto
    }
    .r-xl-auto {
        right: auto
    }
    .b-xl-auto {
        bottom: auto
    }
    .l-xl-auto {
        left: auto
    }
}

.position-static {
    position: static!important
}

.position-relative {
    position: relative!important
}

.position-absolute {
    position: absolute!important
}

.position-fixed {
    position: fixed!important
}

.position-sticky {
    position: sticky!important
}

.tx-black {
    font-weight: 900
}

.tx-bold {
    font-weight: 700
}

.tx-semibold {
    font-weight: 600
}

.tx-medium {
    font-weight: 500
}

.tx-normal {
    font-weight: 400
}

.tx-light {
    font-weight: 300
}

.tx-thin {
    font-weight: 200
}

.tx-xthin {
    font-weight: 100
}

.tx-sserif {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.tx-roboto {
    font-family: Roboto, sans-serif
}

.tx-white {
    color: #fff
}

.tx-facebook {
    color: #3b5998
}

.tx-pinterest {
    color: #cd2029
}

.tx-google-plus {
    color: #d34836
}

.tx-twitter {
    color: #429cd6
}

.tx-black {
    color: #000
}

.tx-primary {
    color: #078586
}

.tx-success {
    color: #21b18a
}

.tx-warning {
    color: #fbbc0b
}

.tx-danger {
    color: #ee335e
}

.tx-info {
    color: #00b9ff
}

.tx-inverse {
    color: #242f48
}

.tx-teal {
    color: #0cc
}

.tx-dark {
    color: #242f48
}

.tx-indigo {
    color: #078586
}

.tx-purple {
    color: #673ab7
}

.tx-orange {
    color: #fd7e14
}

.tx-pink {
    color: #f10075
}

.tx-gray-100 {
    color: #ecf0fa
}

.tx-gray-200 {
    color: #dde2ef
}

.tx-gray-300 {
    color: #d0d7e8
}

.tx-gray-400 {
    color: #b9c2d8
}

.tx-gray-500 {
    color: #949eb7
}

.tx-gray-600 {
    color: #737f9e
}

.tx-gray-700 {
    color: #4d5875
}

.tx-gray-800 {
    color: #364261
}

.tx-gray-900 {
    color: #242f48
}

.tx-white-2 {
    color: rgba(255, 255, 255, .2)
}

.tx-white-3 {
    color: rgba(255, 255, 255, .3)
}

.tx-white-4 {
    color: rgba(255, 255, 255, .4)
}

.tx-white-5 {
    color: rgba(255, 255, 255, .5)
}

.tx-white-6 {
    color: rgba(255, 255, 255, .6)
}

.tx-white-7 {
    color: rgba(255, 255, 255, .7)
}

.tx-white-8 {
    color: rgba(255, 255, 255, .8)
}

.tx-spacing-1 {
    letter-spacing: .5px
}

.tx-spacing-2 {
    letter-spacing: 1px
}

.tx-spacing-3 {
    letter-spacing: 1.5px
}

.tx-spacing-4 {
    letter-spacing: 2px
}

.tx-spacing-5 {
    letter-spacing: 2.5px
}

.tx-spacing-6 {
    letter-spacing: 3px
}

.tx-spacing-7 {
    letter-spacign: 3.5px
}

.tx-spacing-8 {
    letter-spacing: 4px
}

.tx-spacing--1 {
    letter-spacing: -.5px
}

.tx-spacing--2 {
    letter-spacing: -1px
}

.tx-spacing--3 {
    letter-spacing: -1.5px
}

.tx-spacing--4 {
    letter-spacing: -2px
}

.tx-spacing--5 {
    letter-spacing: -2.5px
}

.tx-spacing--6 {
    letter-spacing: -3px
}

.tx-spacing--7 {
    letter-spacing: -3.5px
}

.tx-spacing--8 {
    letter-spacing: -4px
}

.tx-uppercase {
    text-transform: uppercase
}

.tx-lowercase {
    text-transform: lowercase
}

.tx-transform-none {
    text-transform: none
}

.tx-center {
    text-align: center
}

.tx-right {
    text-align: right
}

.tx-left {
    text-align: left
}

.tx-center-f {
    text-align: center!important
}

.tx-right-f {
    text-align: right!important
}

.tx-left-f {
    text-align: left!important
}

.tx-italic {
    font-style: italic
}

.tx-style-normal {
    font-style: normal
}

.lh-base {
    line-height: 1.5
}

.lh-normal {
    line-height: normal
}

.lh-0 {
    line-height: 0
}

.lh--1 {
    line-height: .1
}

.lh--2 {
    line-height: .2
}

.lh--3 {
    line-height: .3
}

.lh--4 {
    line-height: .4
}

.lh--5 {
    line-height: .5
}

.lh--6 {
    line-height: .6
}

.lh--7 {
    line-height: .7
}

.lh--8 {
    line-height: .8
}

.lh--9 {
    line-height: .9
}

.lh-1 {
    line-height: 1.1
}

.lh-2 {
    line-height: 1.2
}

.lh-3 {
    line-height: 1.3
}

.lh-4 {
    line-height: 1.4
}

.lh-5 {
    line-height: 1.5
}

.lh-6 {
    line-height: 1.6
}

.lh-7 {
    line-height: 1.7
}

.lh-8 {
    line-height: 1.8
}

.lh-9 {
    line-height: 1.9
}

.lh-10 {
    line-height: 2
}

.lh-11 {
    line-height: 2.1
}

.lh-12 {
    line-height: 2.2
}

.lh-13 {
    line-height: 2.3
}

.lh-14 {
    line-height: 2.4
}

.lh-15 {
    line-height: 2.5
}

.valign-top {
    vertical-align: top
}

.valign-middle {
    vertical-align: middle
}

.valign-bottom {
    vertical-align: baseline
}

.valign-top-f {
    vertical-align: top!important
}

.valign-middle-f {
    vertical-align: middle!important
}

.valign-bottom-f {
    vertical-align: baseline!important
}

.tx-base {
    font-size: .875rem
}

.tx-8 {
    font-size: 8px
}

.tx-8-f {
    font-size: 8px!important
}

.tx-9 {
    font-size: 9px
}

.tx-9-f {
    font-size: 9px!important
}

.tx-10 {
    font-size: 10px
}

.tx-10-f {
    font-size: 10px!important
}

.tx-11 {
    font-size: 11px
}

.tx-11-f {
    font-size: 11px!important
}

.tx-12 {
    font-size: 12px
}

.tx-12-f {
    font-size: 12px!important
}

.tx-13 {
    font-size: 13px
}

.tx-13-f {
    font-size: 13px!important
}

.tx-14 {
    font-size: 14px
}

.tx-14-f {
    font-size: 14px!important
}

.tx-15 {
    font-size: 15px
}

.tx-15-f {
    font-size: 15px!important
}

.tx-16 {
    font-size: 16px
}

.tx-16-f {
    font-size: 16px!important
}

.tx-17 {
    font-size: 17px
}

.tx-18 {
    font-size: 18px
}

.tx-18-f {
    font-size: 18px!important
}

.tx-20 {
    font-size: 20px!important
}

.tx-20-f {
    font-size: 20px!important
}

.tx-21 {
    font-size: 21px
}

.tx-21-f {
    font-size: 21px!important
}

.tx-22 {
    font-size: 22px
}

.tx-22-f {
    font-size: 22px!important
}

.tx-24 {
    font-size: 24px
}

.tx-24-f {
    font-size: 24px!important
}

.tx-26 {
    font-size: 26px
}

.tx-26-f {
    font-size: 26px!important
}

.tx-28 {
    font-size: 28px
}

.tx-28-f {
    font-size: 28px!important
}

.tx-30 {
    font-size: 30px
}

.tx-30-f {
    font-size: 30px!important
}

.tx-32 {
    font-size: 32px
}

.tx-32-f {
    font-size: 32px!important
}

.tx-34 {
    font-size: 34px
}

.tx-34-f {
    font-size: 34px!important
}

.tx-36 {
    font-size: 36px
}

.tx-36-f {
    font-size: 36px!important
}

.tx-38 {
    font-size: 38px
}

.tx-38-f {
    font-size: 38px!important
}

.tx-40 {
    font-size: 40px
}

.tx-40-f {
    font-size: 40px!important
}

.tx-42 {
    font-size: 42px
}

.tx-42-f {
    font-size: 42px!important
}

.tx-44 {
    font-size: 44px
}

.tx-44-f {
    font-size: 44px!important
}

.tx-46 {
    font-size: 46px
}

.tx-46-f {
    font-size: 46px!important
}

.tx-48 {
    font-size: 48px
}

.tx-48-f {
    font-size: 48px!important
}

.tx-50 {
    font-size: 50px
}

.tx-50-f {
    font-size: 50px!important
}

.tx-52 {
    font-size: 52px
}

.tx-52-f {
    font-size: 52px!important
}

.tx-54 {
    font-size: 54px
}

.tx-54-f {
    font-size: 54px!important
}

.tx-56 {
    font-size: 56px
}

.tx-56-f {
    font-size: 56px!important
}

.tx-58 {
    font-size: 58px
}

.tx-58-f {
    font-size: 58px!important
}

.tx-60 {
    font-size: 60px
}

.tx-60-f {
    font-size: 60px!important
}

.tx-62 {
    font-size: 62px
}

.tx-62-f {
    font-size: 62px!important
}

.tx-64 {
    font-size: 64px
}

.tx-64-f {
    font-size: 64px!important
}

.tx-66 {
    font-size: 66px
}

.tx-66-f {
    font-size: 66px!important
}

.tx-68 {
    font-size: 68px
}

.tx-68-f {
    font-size: 68px!important
}

.tx-70 {
    font-size: 70px
}

.tx-70-f {
    font-size: 70px!important
}

.tx-72 {
    font-size: 72px
}

.tx-72-f {
    font-size: 72px!important
}

.tx-74 {
    font-size: 74px
}

.tx-74-f {
    font-size: 74px!important
}

.tx-76 {
    font-size: 76px
}

.tx-76-f {
    font-size: 76px!important
}

.tx-78 {
    font-size: 78px
}

.tx-78-f {
    font-size: 78px!important
}

.tx-80 {
    font-size: 80px
}

.tx-80-f {
    font-size: 80px!important
}

.tx-82 {
    font-size: 82px
}

.tx-82-f {
    font-size: 82px!important
}

.tx-84 {
    font-size: 84px
}

.tx-84-f {
    font-size: 84px!important
}

.tx-86 {
    font-size: 86px
}

.tx-86-f {
    font-size: 86px!important
}

.tx-88 {
    font-size: 88px
}

.tx-88-f {
    font-size: 88px!important
}

.tx-90 {
    font-size: 90px
}

.tx-90-f {
    font-size: 90px!important
}

.tx-92 {
    font-size: 92px
}

.tx-92-f {
    font-size: 92px!important
}

.tx-94 {
    font-size: 94px
}

.tx-94-f {
    font-size: 94px!important
}

.tx-96 {
    font-size: 96px
}

.tx-96-f {
    font-size: 96px!important
}

.tx-98 {
    font-size: 98px
}

.tx-98-f {
    font-size: 98px!important
}

.tx-100 {
    font-size: 100px
}

.tx-100-f {
    font-size: 100px!important
}

.tx-102 {
    font-size: 102px
}

.tx-102-f {
    font-size: 102px!important
}

.tx-104 {
    font-size: 104px
}

.tx-104-f {
    font-size: 104px!important
}

.tx-106 {
    font-size: 106px
}

.tx-106-f {
    font-size: 106px!important
}

.tx-108 {
    font-size: 108px
}

.tx-108-f {
    font-size: 108px!important
}

.tx-110 {
    font-size: 110px
}

.tx-110-f {
    font-size: 110px!important
}

.tx-112 {
    font-size: 112px
}

.tx-112-f {
    font-size: 112px!important
}

.tx-114 {
    font-size: 114px
}

.tx-114-f {
    font-size: 114px!important
}

.tx-116 {
    font-size: 116px
}

.tx-116-f {
    font-size: 116px!important
}

.tx-118 {
    font-size: 118px
}

.tx-118-f {
    font-size: 118px!important
}

.tx-120 {
    font-size: 120px
}

.tx-120-f {
    font-size: 120px!important
}

.tx-122 {
    font-size: 122px
}

.tx-122-f {
    font-size: 122px!important
}

.tx-124 {
    font-size: 124px
}

.tx-124-f {
    font-size: 124px!important
}

.tx-126 {
    font-size: 126px
}

.tx-126-f {
    font-size: 126px!important
}

.tx-128 {
    font-size: 128px
}

.tx-128-f {
    font-size: 128px!important
}

.tx-130 {
    font-size: 130px
}

.tx-130-f {
    font-size: 130px!important
}

.tx-132 {
    font-size: 132px
}

.tx-132-f {
    font-size: 132px!important
}

.tx-134 {
    font-size: 134px
}

.tx-134-f {
    font-size: 134px!important
}

.tx-136 {
    font-size: 136px
}

.tx-136-f {
    font-size: 136px!important
}

.tx-138 {
    font-size: 138px
}

.tx-138-f {
    font-size: 138px!important
}

.tx-140 {
    font-size: 140px
}

.tx-140-f {
    font-size: 140px!important
}

@media (min-width:480px) {
    .tx-xs-8 {
        font-size: 8px
    }
    .tx-xs-8-f {
        font-size: 8px!important
    }
    .tx-xs-9 {
        font-size: 9px
    }
    .tx-xs-9-f {
        font-size: 9px!important
    }
    .tx-xs-10 {
        font-size: 10px
    }
    .tx-xs-10-f {
        font-size: 10px!important
    }
    .tx-xs-11 {
        font-size: 11px
    }
    .tx-xs-11-f {
        font-size: 11px!important
    }
    .tx-xs-12 {
        font-size: 12px
    }
    .tx-xs-12-f {
        font-size: 12px!important
    }
    .tx-xs-13 {
        font-size: 13px
    }
    .tx-xs-13-f {
        font-size: 13px!important
    }
    .tx-xs-14 {
        font-size: 14px
    }
    .tx-xs-14-f {
        font-size: 14px!important
    }
    .tx-xs-15 {
        font-size: 15px
    }
    .tx-xs-15-f {
        font-size: 15px!important
    }
    .tx-xs-16 {
        font-size: 16px
    }
    .tx-xs-16-f {
        font-size: 16px!important
    }
    .tx-xs-18 {
        font-size: 18px
    }
    .tx-xs-18-f {
        font-size: 18px!important
    }
    .tx-xs-20 {
        font-size: 20px
    }
    .tx-xs-20-f {
        font-size: 20px!important
    }
    .tx-xs-22 {
        font-size: 22px
    }
    .tx-xs-22-f {
        font-size: 22px!important
    }
    .tx-xs-24 {
        font-size: 24px
    }
    .tx-xs-24-f {
        font-size: 24px!important
    }
    .tx-xs-26 {
        font-size: 26px
    }
    .tx-xs-26-f {
        font-size: 26px!important
    }
    .tx-xs-28 {
        font-size: 28px
    }
    .tx-xs-28-f {
        font-size: 28px!important
    }
    .tx-xs-30 {
        font-size: 30px
    }
    .tx-xs-30-f {
        font-size: 30px!important
    }
    .tx-xs-32 {
        font-size: 32px
    }
    .tx-xs-32-f {
        font-size: 32px!important
    }
    .tx-xs-34 {
        font-size: 34px
    }
    .tx-xs-34-f {
        font-size: 34px!important
    }
    .tx-xs-36 {
        font-size: 36px
    }
    .tx-xs-36-f {
        font-size: 36px!important
    }
    .tx-xs-38 {
        font-size: 38px
    }
    .tx-xs-38-f {
        font-size: 38px!important
    }
    .tx-xs-40 {
        font-size: 40px
    }
    .tx-xs-40-f {
        font-size: 40px!important
    }
    .tx-xs-42 {
        font-size: 42px
    }
    .tx-xs-42-f {
        font-size: 42px!important
    }
    .tx-xs-44 {
        font-size: 44px
    }
    .tx-xs-44-f {
        font-size: 44px!important
    }
    .tx-xs-46 {
        font-size: 46px
    }
    .tx-xs-46-f {
        font-size: 46px!important
    }
    .tx-xs-48 {
        font-size: 48px
    }
    .tx-xs-48-f {
        font-size: 48px!important
    }
    .tx-xs-50 {
        font-size: 50px
    }
    .tx-xs-50-f {
        font-size: 50px!important
    }
    .tx-xs-52 {
        font-size: 52px
    }
    .tx-xs-52-f {
        font-size: 52px!important
    }
    .tx-xs-54 {
        font-size: 54px
    }
    .tx-xs-54-f {
        font-size: 54px!important
    }
    .tx-xs-56 {
        font-size: 56px
    }
    .tx-xs-56-f {
        font-size: 56px!important
    }
    .tx-xs-58 {
        font-size: 58px
    }
    .tx-xs-58-f {
        font-size: 58px!important
    }
    .tx-xs-60 {
        font-size: 60px
    }
    .tx-xs-60-f {
        font-size: 60px!important
    }
    .tx-xs-62 {
        font-size: 62px
    }
    .tx-xs-62-f {
        font-size: 62px!important
    }
    .tx-xs-64 {
        font-size: 64px
    }
    .tx-xs-64-f {
        font-size: 64px!important
    }
    .tx-xs-66 {
        font-size: 66px
    }
    .tx-xs-66-f {
        font-size: 66px!important
    }
    .tx-xs-68 {
        font-size: 68px
    }
    .tx-xs-68-f {
        font-size: 68px!important
    }
    .tx-xs-70 {
        font-size: 70px
    }
    .tx-xs-70-f {
        font-size: 70px!important
    }
    .tx-xs-72 {
        font-size: 72px
    }
    .tx-xs-72-f {
        font-size: 72px!important
    }
    .tx-xs-74 {
        font-size: 74px
    }
    .tx-xs-74-f {
        font-size: 74px!important
    }
    .tx-xs-76 {
        font-size: 76px
    }
    .tx-xs-76-f {
        font-size: 76px!important
    }
    .tx-xs-78 {
        font-size: 78px
    }
    .tx-xs-78-f {
        font-size: 78px!important
    }
    .tx-xs-80 {
        font-size: 80px
    }
    .tx-xs-80-f {
        font-size: 80px!important
    }
    .tx-xs-82 {
        font-size: 82px
    }
    .tx-xs-82-f {
        font-size: 82px!important
    }
    .tx-xs-84 {
        font-size: 84px
    }
    .tx-xs-84-f {
        font-size: 84px!important
    }
    .tx-xs-86 {
        font-size: 86px
    }
    .tx-xs-86-f {
        font-size: 86px!important
    }
    .tx-xs-88 {
        font-size: 88px
    }
    .tx-xs-88-f {
        font-size: 88px!important
    }
    .tx-xs-90 {
        font-size: 90px
    }
    .tx-xs-90-f {
        font-size: 90px!important
    }
    .tx-xs-92 {
        font-size: 92px
    }
    .tx-xs-92-f {
        font-size: 92px!important
    }
    .tx-xs-94 {
        font-size: 94px
    }
    .tx-xs-94-f {
        font-size: 94px!important
    }
    .tx-xs-96 {
        font-size: 96px
    }
    .tx-xs-96-f {
        font-size: 96px!important
    }
    .tx-xs-98 {
        font-size: 98px
    }
    .tx-xs-98-f {
        font-size: 98px!important
    }
    .tx-xs-100 {
        font-size: 100px
    }
    .tx-xs-100-f {
        font-size: 100px!important
    }
    .tx-xs-102 {
        font-size: 102px
    }
    .tx-xs-102-f {
        font-size: 102px!important
    }
    .tx-xs-104 {
        font-size: 104px
    }
    .tx-xs-104-f {
        font-size: 104px!important
    }
    .tx-xs-106 {
        font-size: 106px
    }
    .tx-xs-106-f {
        font-size: 106px!important
    }
    .tx-xs-108 {
        font-size: 108px
    }
    .tx-xs-108-f {
        font-size: 108px!important
    }
    .tx-xs-110 {
        font-size: 110px
    }
    .tx-xs-110-f {
        font-size: 110px!important
    }
    .tx-xs-112 {
        font-size: 112px
    }
    .tx-xs-112-f {
        font-size: 112px!important
    }
    .tx-xs-114 {
        font-size: 114px
    }
    .tx-xs-114-f {
        font-size: 114px!important
    }
    .tx-xs-116 {
        font-size: 116px
    }
    .tx-xs-116-f {
        font-size: 116px!important
    }
    .tx-xs-118 {
        font-size: 118px
    }
    .tx-xs-118-f {
        font-size: 118px!important
    }
    .tx-xs-120 {
        font-size: 120px
    }
    .tx-xs-120-f {
        font-size: 120px!important
    }
    .tx-xs-122 {
        font-size: 122px
    }
    .tx-xs-122-f {
        font-size: 122px!important
    }
    .tx-xs-124 {
        font-size: 124px
    }
    .tx-xs-124-f {
        font-size: 124px!important
    }
    .tx-xs-126 {
        font-size: 126px
    }
    .tx-xs-126-f {
        font-size: 126px!important
    }
    .tx-xs-128 {
        font-size: 128px
    }
    .tx-xs-128-f {
        font-size: 128px!important
    }
    .tx-xs-130 {
        font-size: 130px
    }
    .tx-xs-130-f {
        font-size: 130px!important
    }
    .tx-xs-132 {
        font-size: 132px
    }
    .tx-xs-132-f {
        font-size: 132px!important
    }
    .tx-xs-134 {
        font-size: 134px
    }
    .tx-xs-134-f {
        font-size: 134px!important
    }
    .tx-xs-136 {
        font-size: 136px
    }
    .tx-xs-136-f {
        font-size: 136px!important
    }
    .tx-xs-138 {
        font-size: 138px
    }
    .tx-xs-138-f {
        font-size: 138px!important
    }
    .tx-xs-140 {
        font-size: 140px
    }
    .tx-xs-140-f {
        font-size: 140px!important
    }
    .tx-xs-base {
        font-size: .875rem
    }
    .tx-xs-bold {
        font-weight: 700
    }
    .tx-xs-semibold {
        font-weight: 600
    }
    .tx-xs-medium {
        font-weight: 500
    }
    .tx-xs-normal {
        font-weight: 400
    }
    .tx-xs-light {
        font-weight: 300
    }
    .tx-xs-thin {
        font-weight: 200
    }
    .tx-xs-xthin {
        font-weight: 100
    }
    .lh-xs-0 {
        line-height: 0
    }
    .lh-xs-1 {
        line-height: 1.1
    }
    .lh-xs-2 {
        line-height: 1.2
    }
    .lh-xs-3 {
        line-height: 1.3
    }
    .lh-xs-4 {
        line-height: 1.4
    }
    .lh-xs-5 {
        line-height: 1.5
    }
    .lh-xs-6 {
        line-height: 1.6
    }
    .lh-xs-7 {
        line-height: 1.7
    }
    .lh-xs-8 {
        line-height: 1.8
    }
    .lh-xs-9 {
        line-height: 1.9
    }
    .lh-xs-10 {
        line-height: 2
    }
    .lh-xs-11 {
        line-height: 2.1
    }
    .lh-xs-12 {
        line-height: 2.2
    }
    .lh-xs-13 {
        line-height: 2.3
    }
    .lh-xs-14 {
        line-height: 2.4
    }
    .lh-xs-15 {
        line-height: 2.5
    }
    .tx-xs-center {
        text-align: center
    }
    .tx-xs-right {
        text-align: right
    }
    .tx-xs-left {
        text-align: left
    }
    .tx-xs-center-f {
        text-align: center!important
    }
    .tx-xs-right-f {
        text-align: right!important
    }
    .tx-xs-left-f {
        text-align: left!important
    }
}

@media (min-width:576px) {
    .tx-sm-8 {
        font-size: 8px
    }
    .tx-sm-8-f {
        font-size: 8px!important
    }
    .tx-sm-9 {
        font-size: 9px
    }
    .tx-sm-9-f {
        font-size: 9px!important
    }
    .tx-sm-10 {
        font-size: 10px
    }
    .tx-sm-10-f {
        font-size: 10px!important
    }
    .tx-sm-11 {
        font-size: 11px
    }
    .tx-sm-11-f {
        font-size: 11px!important
    }
    .tx-sm-12 {
        font-size: 12px
    }
    .tx-sm-12-f {
        font-size: 12px!important
    }
    .tx-sm-13 {
        font-size: 13px
    }
    .tx-sm-13-f {
        font-size: 13px!important
    }
    .tx-sm-14 {
        font-size: 14px
    }
    .tx-sm-14-f {
        font-size: 14px!important
    }
    .tx-sm-15 {
        font-size: 15px
    }
    .tx-sm-15-f {
        font-size: 15px!important
    }
    .tx-sm-16 {
        font-size: 16px
    }
    .tx-sm-16-f {
        font-size: 16px!important
    }
    .tx-sm-18 {
        font-size: 18px
    }
    .tx-sm-18-f {
        font-size: 18px!important
    }
    .tx-sm-20 {
        font-size: 20px
    }
    .tx-sm-20-f {
        font-size: 20px!important
    }
    .tx-sm-22 {
        font-size: 22px
    }
    .tx-sm-22-f {
        font-size: 22px!important
    }
    .tx-sm-24 {
        font-size: 24px
    }
    .tx-sm-24-f {
        font-size: 24px!important
    }
    .tx-sm-26 {
        font-size: 26px
    }
    .tx-sm-26-f {
        font-size: 26px!important
    }
    .tx-sm-28 {
        font-size: 28px
    }
    .tx-sm-28-f {
        font-size: 28px!important
    }
    .tx-sm-30 {
        font-size: 30px
    }
    .tx-sm-30-f {
        font-size: 30px!important
    }
    .tx-sm-32 {
        font-size: 32px
    }
    .tx-sm-32-f {
        font-size: 32px!important
    }
    .tx-sm-34 {
        font-size: 34px
    }
    .tx-sm-34-f {
        font-size: 34px!important
    }
    .tx-sm-36 {
        font-size: 36px
    }
    .tx-sm-36-f {
        font-size: 36px!important
    }
    .tx-sm-38 {
        font-size: 38px
    }
    .tx-sm-38-f {
        font-size: 38px!important
    }
    .tx-sm-40 {
        font-size: 40px
    }
    .tx-sm-40-f {
        font-size: 40px!important
    }
    .tx-sm-42 {
        font-size: 42px
    }
    .tx-sm-42-f {
        font-size: 42px!important
    }
    .tx-sm-44 {
        font-size: 44px
    }
    .tx-sm-44-f {
        font-size: 44px!important
    }
    .tx-sm-46 {
        font-size: 46px
    }
    .tx-sm-46-f {
        font-size: 46px!important
    }
    .tx-sm-48 {
        font-size: 48px
    }
    .tx-sm-48-f {
        font-size: 48px!important
    }
    .tx-sm-50 {
        font-size: 50px
    }
    .tx-sm-50-f {
        font-size: 50px!important
    }
    .tx-sm-52 {
        font-size: 52px
    }
    .tx-sm-52-f {
        font-size: 52px!important
    }
    .tx-sm-54 {
        font-size: 54px
    }
    .tx-sm-54-f {
        font-size: 54px!important
    }
    .tx-sm-56 {
        font-size: 56px
    }
    .tx-sm-56-f {
        font-size: 56px!important
    }
    .tx-sm-58 {
        font-size: 58px
    }
    .tx-sm-58-f {
        font-size: 58px!important
    }
    .tx-sm-60 {
        font-size: 60px
    }
    .tx-sm-60-f {
        font-size: 60px!important
    }
    .tx-sm-62 {
        font-size: 62px
    }
    .tx-sm-62-f {
        font-size: 62px!important
    }
    .tx-sm-64 {
        font-size: 64px
    }
    .tx-sm-64-f {
        font-size: 64px!important
    }
    .tx-sm-66 {
        font-size: 66px
    }
    .tx-sm-66-f {
        font-size: 66px!important
    }
    .tx-sm-68 {
        font-size: 68px
    }
    .tx-sm-68-f {
        font-size: 68px!important
    }
    .tx-sm-70 {
        font-size: 70px
    }
    .tx-sm-70-f {
        font-size: 70px!important
    }
    .tx-sm-72 {
        font-size: 72px
    }
    .tx-sm-72-f {
        font-size: 72px!important
    }
    .tx-sm-74 {
        font-size: 74px
    }
    .tx-sm-74-f {
        font-size: 74px!important
    }
    .tx-sm-76 {
        font-size: 76px
    }
    .tx-sm-76-f {
        font-size: 76px!important
    }
    .tx-sm-78 {
        font-size: 78px
    }
    .tx-sm-78-f {
        font-size: 78px!important
    }
    .tx-sm-80 {
        font-size: 80px
    }
    .tx-sm-80-f {
        font-size: 80px!important
    }
    .tx-sm-82 {
        font-size: 82px
    }
    .tx-sm-82-f {
        font-size: 82px!important
    }
    .tx-sm-84 {
        font-size: 84px
    }
    .tx-sm-84-f {
        font-size: 84px!important
    }
    .tx-sm-86 {
        font-size: 86px
    }
    .tx-sm-86-f {
        font-size: 86px!important
    }
    .tx-sm-88 {
        font-size: 88px
    }
    .tx-sm-88-f {
        font-size: 88px!important
    }
    .tx-sm-90 {
        font-size: 90px
    }
    .tx-sm-90-f {
        font-size: 90px!important
    }
    .tx-sm-92 {
        font-size: 92px
    }
    .tx-sm-92-f {
        font-size: 92px!important
    }
    .tx-sm-94 {
        font-size: 94px
    }
    .tx-sm-94-f {
        font-size: 94px!important
    }
    .tx-sm-96 {
        font-size: 96px
    }
    .tx-sm-96-f {
        font-size: 96px!important
    }
    .tx-sm-98 {
        font-size: 98px
    }
    .tx-sm-98-f {
        font-size: 98px!important
    }
    .tx-sm-100 {
        font-size: 100px
    }
    .tx-sm-100-f {
        font-size: 100px!important
    }
    .tx-sm-102 {
        font-size: 102px
    }
    .tx-sm-102-f {
        font-size: 102px!important
    }
    .tx-sm-104 {
        font-size: 104px
    }
    .tx-sm-104-f {
        font-size: 104px!important
    }
    .tx-sm-106 {
        font-size: 106px
    }
    .tx-sm-106-f {
        font-size: 106px!important
    }
    .tx-sm-108 {
        font-size: 108px
    }
    .tx-sm-108-f {
        font-size: 108px!important
    }
    .tx-sm-110 {
        font-size: 110px
    }
    .tx-sm-110-f {
        font-size: 110px!important
    }
    .tx-sm-112 {
        font-size: 112px
    }
    .tx-sm-112-f {
        font-size: 112px!important
    }
    .tx-sm-114 {
        font-size: 114px
    }
    .tx-sm-114-f {
        font-size: 114px!important
    }
    .tx-sm-116 {
        font-size: 116px
    }
    .tx-sm-116-f {
        font-size: 116px!important
    }
    .tx-sm-118 {
        font-size: 118px
    }
    .tx-sm-118-f {
        font-size: 118px!important
    }
    .tx-sm-120 {
        font-size: 120px
    }
    .tx-sm-120-f {
        font-size: 120px!important
    }
    .tx-sm-122 {
        font-size: 122px
    }
    .tx-sm-122-f {
        font-size: 122px!important
    }
    .tx-sm-124 {
        font-size: 124px
    }
    .tx-sm-124-f {
        font-size: 124px!important
    }
    .tx-sm-126 {
        font-size: 126px
    }
    .tx-sm-126-f {
        font-size: 126px!important
    }
    .tx-sm-128 {
        font-size: 128px
    }
    .tx-sm-128-f {
        font-size: 128px!important
    }
    .tx-sm-130 {
        font-size: 130px
    }
    .tx-sm-130-f {
        font-size: 130px!important
    }
    .tx-sm-132 {
        font-size: 132px
    }
    .tx-sm-132-f {
        font-size: 132px!important
    }
    .tx-sm-134 {
        font-size: 134px
    }
    .tx-sm-134-f {
        font-size: 134px!important
    }
    .tx-sm-136 {
        font-size: 136px
    }
    .tx-sm-136-f {
        font-size: 136px!important
    }
    .tx-sm-138 {
        font-size: 138px
    }
    .tx-sm-138-f {
        font-size: 138px!important
    }
    .tx-sm-140 {
        font-size: 140px
    }
    .tx-sm-140-f {
        font-size: 140px!important
    }
    .tx-sm-base {
        font-size: .875rem
    }
    .tx-sm-bold {
        font-weight: 700
    }
    .tx-sm-semibold {
        font-weight: 600
    }
    .tx-sm-medium {
        font-weight: 500
    }
    .tx-sm-normal {
        font-weight: 400
    }
    .tx-sm-light {
        font-weight: 300
    }
    .tx-sm-thin {
        font-weight: 200
    }
    .tx-sm-xthin {
        font-weight: 100
    }
    .lh-sm-0 {
        line-height: 0
    }
    .lh-sm-1 {
        line-height: 1.1
    }
    .lh-sm-2 {
        line-height: 1.2
    }
    .lh-sm-3 {
        line-height: 1.3
    }
    .lh-sm-4 {
        line-height: 1.4
    }
    .lh-sm-5 {
        line-height: 1.5
    }
    .lh-sm-6 {
        line-height: 1.6
    }
    .lh-sm-7 {
        line-height: 1.7
    }
    .lh-sm-8 {
        line-height: 1.8
    }
    .lh-sm-9 {
        line-height: 1.9
    }
    .lh-sm-10 {
        line-height: 2
    }
    .lh-sm-11 {
        line-height: 2.1
    }
    .lh-sm-12 {
        line-height: 2.2
    }
    .lh-sm-13 {
        line-height: 2.3
    }
    .lh-sm-14 {
        line-height: 2.4
    }
    .lh-sm-15 {
        line-height: 2.5
    }
    .tx-sm-center {
        text-align: center
    }
    .tx-sm-right {
        text-align: right
    }
    .tx-sm-left {
        text-align: left
    }
    .tx-sm-center-f {
        text-align: center!important
    }
    .tx-sm-right-f {
        text-align: right!important
    }
    .tx-sm-left-f {
        text-align: left!important
    }
}

@media (min-width:768px) {
    .tx-md-8 {
        font-size: 8px
    }
    .tx-md-8-f {
        font-size: 8px!important
    }
    .tx-md-9 {
        font-size: 9px
    }
    .tx-md-9-f {
        font-size: 9px!important
    }
    .tx-md-10 {
        font-size: 10px
    }
    .tx-md-10-f {
        font-size: 10px!important
    }
    .tx-md-11 {
        font-size: 11px
    }
    .tx-md-11-f {
        font-size: 11px!important
    }
    .tx-md-12 {
        font-size: 12px
    }
    .tx-md-12-f {
        font-size: 12px!important
    }
    .tx-md-13 {
        font-size: 13px
    }
    .tx-md-13-f {
        font-size: 13px!important
    }
    .tx-md-14 {
        font-size: 14px
    }
    .tx-md-14-f {
        font-size: 14px!important
    }
    .tx-md-15 {
        font-size: 15px
    }
    .tx-md-15-f {
        font-size: 15px!important
    }
    .tx-md-16 {
        font-size: 16px
    }
    .tx-md-16-f {
        font-size: 16px!important
    }
    .tx-md-18 {
        font-size: 18px
    }
    .tx-md-18-f {
        font-size: 18px!important
    }
    .tx-md-20 {
        font-size: 20px
    }
    .tx-md-20-f {
        font-size: 20px!important
    }
    .tx-md-22 {
        font-size: 22px
    }
    .tx-md-22-f {
        font-size: 22px!important
    }
    .tx-md-24 {
        font-size: 24px
    }
    .tx-md-24-f {
        font-size: 24px!important
    }
    .tx-md-26 {
        font-size: 26px
    }
    .tx-md-26-f {
        font-size: 26px!important
    }
    .tx-md-28 {
        font-size: 28px
    }
    .tx-md-28-f {
        font-size: 28px!important
    }
    .tx-md-30 {
        font-size: 30px
    }
    .tx-md-30-f {
        font-size: 30px!important
    }
    .tx-md-32 {
        font-size: 32px
    }
    .tx-md-32-f {
        font-size: 32px!important
    }
    .tx-md-34 {
        font-size: 34px
    }
    .tx-md-34-f {
        font-size: 34px!important
    }
    .tx-md-36 {
        font-size: 36px
    }
    .tx-md-36-f {
        font-size: 36px!important
    }
    .tx-md-38 {
        font-size: 38px
    }
    .tx-md-38-f {
        font-size: 38px!important
    }
    .tx-md-40 {
        font-size: 40px
    }
    .tx-md-40-f {
        font-size: 40px!important
    }
    .tx-md-42 {
        font-size: 42px
    }
    .tx-md-42-f {
        font-size: 42px!important
    }
    .tx-md-44 {
        font-size: 44px
    }
    .tx-md-44-f {
        font-size: 44px!important
    }
    .tx-md-46 {
        font-size: 46px
    }
    .tx-md-46-f {
        font-size: 46px!important
    }
    .tx-md-48 {
        font-size: 48px
    }
    .tx-md-48-f {
        font-size: 48px!important
    }
    .tx-md-50 {
        font-size: 50px
    }
    .tx-md-50-f {
        font-size: 50px!important
    }
    .tx-md-52 {
        font-size: 52px
    }
    .tx-md-52-f {
        font-size: 52px!important
    }
    .tx-md-54 {
        font-size: 54px
    }
    .tx-md-54-f {
        font-size: 54px!important
    }
    .tx-md-56 {
        font-size: 56px
    }
    .tx-md-56-f {
        font-size: 56px!important
    }
    .tx-md-58 {
        font-size: 58px
    }
    .tx-md-58-f {
        font-size: 58px!important
    }
    .tx-md-60 {
        font-size: 60px
    }
    .tx-md-60-f {
        font-size: 60px!important
    }
    .tx-md-62 {
        font-size: 62px
    }
    .tx-md-62-f {
        font-size: 62px!important
    }
    .tx-md-64 {
        font-size: 64px
    }
    .tx-md-64-f {
        font-size: 64px!important
    }
    .tx-md-66 {
        font-size: 66px
    }
    .tx-md-66-f {
        font-size: 66px!important
    }
    .tx-md-68 {
        font-size: 68px
    }
    .tx-md-68-f {
        font-size: 68px!important
    }
    .tx-md-70 {
        font-size: 70px
    }
    .tx-md-70-f {
        font-size: 70px!important
    }
    .tx-md-72 {
        font-size: 72px
    }
    .tx-md-72-f {
        font-size: 72px!important
    }
    .tx-md-74 {
        font-size: 74px
    }
    .tx-md-74-f {
        font-size: 74px!important
    }
    .tx-md-76 {
        font-size: 76px
    }
    .tx-md-76-f {
        font-size: 76px!important
    }
    .tx-md-78 {
        font-size: 78px
    }
    .tx-md-78-f {
        font-size: 78px!important
    }
    .tx-md-80 {
        font-size: 80px
    }
    .tx-md-80-f {
        font-size: 80px!important
    }
    .tx-md-82 {
        font-size: 82px
    }
    .tx-md-82-f {
        font-size: 82px!important
    }
    .tx-md-84 {
        font-size: 84px
    }
    .tx-md-84-f {
        font-size: 84px!important
    }
    .tx-md-86 {
        font-size: 86px
    }
    .tx-md-86-f {
        font-size: 86px!important
    }
    .tx-md-88 {
        font-size: 88px
    }
    .tx-md-88-f {
        font-size: 88px!important
    }
    .tx-md-90 {
        font-size: 90px
    }
    .tx-md-90-f {
        font-size: 90px!important
    }
    .tx-md-92 {
        font-size: 92px
    }
    .tx-md-92-f {
        font-size: 92px!important
    }
    .tx-md-94 {
        font-size: 94px
    }
    .tx-md-94-f {
        font-size: 94px!important
    }
    .tx-md-96 {
        font-size: 96px
    }
    .tx-md-96-f {
        font-size: 96px!important
    }
    .tx-md-98 {
        font-size: 98px
    }
    .tx-md-98-f {
        font-size: 98px!important
    }
    .tx-md-100 {
        font-size: 100px
    }
    .tx-md-100-f {
        font-size: 100px!important
    }
    .tx-md-102 {
        font-size: 102px
    }
    .tx-md-102-f {
        font-size: 102px!important
    }
    .tx-md-104 {
        font-size: 104px
    }
    .tx-md-104-f {
        font-size: 104px!important
    }
    .tx-md-106 {
        font-size: 106px
    }
    .tx-md-106-f {
        font-size: 106px!important
    }
    .tx-md-108 {
        font-size: 108px
    }
    .tx-md-108-f {
        font-size: 108px!important
    }
    .tx-md-110 {
        font-size: 110px
    }
    .tx-md-110-f {
        font-size: 110px!important
    }
    .tx-md-112 {
        font-size: 112px
    }
    .tx-md-112-f {
        font-size: 112px!important
    }
    .tx-md-114 {
        font-size: 114px
    }
    .tx-md-114-f {
        font-size: 114px!important
    }
    .tx-md-116 {
        font-size: 116px
    }
    .tx-md-116-f {
        font-size: 116px!important
    }
    .tx-md-118 {
        font-size: 118px
    }
    .tx-md-118-f {
        font-size: 118px!important
    }
    .tx-md-120 {
        font-size: 120px
    }
    .tx-md-120-f {
        font-size: 120px!important
    }
    .tx-md-122 {
        font-size: 122px
    }
    .tx-md-122-f {
        font-size: 122px!important
    }
    .tx-md-124 {
        font-size: 124px
    }
    .tx-md-124-f {
        font-size: 124px!important
    }
    .tx-md-126 {
        font-size: 126px
    }
    .tx-md-126-f {
        font-size: 126px!important
    }
    .tx-md-128 {
        font-size: 128px
    }
    .tx-md-128-f {
        font-size: 128px!important
    }
    .tx-md-130 {
        font-size: 130px
    }
    .tx-md-130-f {
        font-size: 130px!important
    }
    .tx-md-132 {
        font-size: 132px
    }
    .tx-md-132-f {
        font-size: 132px!important
    }
    .tx-md-134 {
        font-size: 134px
    }
    .tx-md-134-f {
        font-size: 134px!important
    }
    .tx-md-136 {
        font-size: 136px
    }
    .tx-md-136-f {
        font-size: 136px!important
    }
    .tx-md-138 {
        font-size: 138px
    }
    .tx-md-138-f {
        font-size: 138px!important
    }
    .tx-md-140 {
        font-size: 140px
    }
    .tx-md-140-f {
        font-size: 140px!important
    }
    .tx-md-base {
        font-size: .875rem
    }
    .tx-md-bold {
        font-weight: 700
    }
    .tx-md-semibold {
        font-weight: 600
    }
    .tx-md-medium {
        font-weight: 500
    }
    .tx-md-normal {
        font-weight: 400
    }
    .tx-md-light {
        font-weight: 300
    }
    .tx-md-thin {
        font-weight: 200
    }
    .tx-md-xthin {
        font-weight: 100
    }
    .lh-md-0 {
        line-height: 0
    }
    .lh-md-1 {
        line-height: 1.1
    }
    .lh-md-2 {
        line-height: 1.2
    }
    .lh-md-3 {
        line-height: 1.3
    }
    .lh-md-4 {
        line-height: 1.4
    }
    .lh-md-5 {
        line-height: 1.5
    }
    .lh-md-6 {
        line-height: 1.6
    }
    .lh-md-7 {
        line-height: 1.7
    }
    .lh-md-8 {
        line-height: 1.8
    }
    .lh-md-9 {
        line-height: 1.9
    }
    .lh-md-10 {
        line-height: 2
    }
    .lh-md-11 {
        line-height: 2.1
    }
    .lh-md-12 {
        line-height: 2.2
    }
    .lh-md-13 {
        line-height: 2.3
    }
    .lh-md-14 {
        line-height: 2.4
    }
    .lh-md-15 {
        line-height: 2.5
    }
    .tx-md-center {
        text-align: center
    }
    .tx-md-right {
        text-align: right
    }
    .tx-md-left {
        text-align: left
    }
    .tx-md-center-f {
        text-align: center!important
    }
    .tx-md-right-f {
        text-align: right!important
    }
    .tx-md-left-f {
        text-align: left!important
    }
}

@media (min-width:992px) {
    .tx-lg-8 {
        font-size: 8px
    }
    .tx-lg-8-f {
        font-size: 8px!important
    }
    .tx-lg-9 {
        font-size: 9px
    }
    .tx-lg-9-f {
        font-size: 9px!important
    }
    .tx-lg-10 {
        font-size: 10px
    }
    .tx-lg-10-f {
        font-size: 10px!important
    }
    .tx-lg-11 {
        font-size: 11px
    }
    .tx-lg-11-f {
        font-size: 11px!important
    }
    .tx-lg-12 {
        font-size: 12px
    }
    .tx-lg-12-f {
        font-size: 12px!important
    }
    .tx-lg-13 {
        font-size: 13px
    }
    .tx-lg-13-f {
        font-size: 13px!important
    }
    .tx-lg-14 {
        font-size: 14px
    }
    .tx-lg-14-f {
        font-size: 14px!important
    }
    .tx-lg-15 {
        font-size: 15px
    }
    .tx-lg-15-f {
        font-size: 15px!important
    }
    .tx-lg-16 {
        font-size: 16px
    }
    .tx-lg-16-f {
        font-size: 16px!important
    }
    .tx-lg-18 {
        font-size: 18px
    }
    .tx-lg-18-f {
        font-size: 18px!important
    }
    .tx-lg-20 {
        font-size: 20px
    }
    .tx-lg-20-f {
        font-size: 20px!important
    }
    .tx-lg-22 {
        font-size: 22px
    }
    .tx-lg-22-f {
        font-size: 22px!important
    }
    .tx-lg-24 {
        font-size: 24px
    }
    .tx-lg-24-f {
        font-size: 24px!important
    }
    .tx-lg-26 {
        font-size: 26px
    }
    .tx-lg-26-f {
        font-size: 26px!important
    }
    .tx-lg-28 {
        font-size: 28px
    }
    .tx-lg-28-f {
        font-size: 28px!important
    }
    .tx-lg-30 {
        font-size: 30px
    }
    .tx-lg-30-f {
        font-size: 30px!important
    }
    .tx-lg-32 {
        font-size: 32px
    }
    .tx-lg-32-f {
        font-size: 32px!important
    }
    .tx-lg-34 {
        font-size: 34px
    }
    .tx-lg-34-f {
        font-size: 34px!important
    }
    .tx-lg-36 {
        font-size: 36px
    }
    .tx-lg-36-f {
        font-size: 36px!important
    }
    .tx-lg-38 {
        font-size: 38px
    }
    .tx-lg-38-f {
        font-size: 38px!important
    }
    .tx-lg-40 {
        font-size: 40px
    }
    .tx-lg-40-f {
        font-size: 40px!important
    }
    .tx-lg-42 {
        font-size: 42px
    }
    .tx-lg-42-f {
        font-size: 42px!important
    }
    .tx-lg-44 {
        font-size: 44px
    }
    .tx-lg-44-f {
        font-size: 44px!important
    }
    .tx-lg-46 {
        font-size: 46px
    }
    .tx-lg-46-f {
        font-size: 46px!important
    }
    .tx-lg-48 {
        font-size: 48px
    }
    .tx-lg-48-f {
        font-size: 48px!important
    }
    .tx-lg-50 {
        font-size: 50px
    }
    .tx-lg-50-f {
        font-size: 50px!important
    }
    .tx-lg-52 {
        font-size: 52px
    }
    .tx-lg-52-f {
        font-size: 52px!important
    }
    .tx-lg-54 {
        font-size: 54px
    }
    .tx-lg-54-f {
        font-size: 54px!important
    }
    .tx-lg-56 {
        font-size: 56px
    }
    .tx-lg-56-f {
        font-size: 56px!important
    }
    .tx-lg-58 {
        font-size: 58px
    }
    .tx-lg-58-f {
        font-size: 58px!important
    }
    .tx-lg-60 {
        font-size: 60px
    }
    .tx-lg-60-f {
        font-size: 60px!important
    }
    .tx-lg-62 {
        font-size: 62px
    }
    .tx-lg-62-f {
        font-size: 62px!important
    }
    .tx-lg-64 {
        font-size: 64px
    }
    .tx-lg-64-f {
        font-size: 64px!important
    }
    .tx-lg-66 {
        font-size: 66px
    }
    .tx-lg-66-f {
        font-size: 66px!important
    }
    .tx-lg-68 {
        font-size: 68px
    }
    .tx-lg-68-f {
        font-size: 68px!important
    }
    .tx-lg-70 {
        font-size: 70px
    }
    .tx-lg-70-f {
        font-size: 70px!important
    }
    .tx-lg-72 {
        font-size: 72px
    }
    .tx-lg-72-f {
        font-size: 72px!important
    }
    .tx-lg-74 {
        font-size: 74px
    }
    .tx-lg-74-f {
        font-size: 74px!important
    }
    .tx-lg-76 {
        font-size: 76px
    }
    .tx-lg-76-f {
        font-size: 76px!important
    }
    .tx-lg-78 {
        font-size: 78px
    }
    .tx-lg-78-f {
        font-size: 78px!important
    }
    .tx-lg-80 {
        font-size: 80px
    }
    .tx-lg-80-f {
        font-size: 80px!important
    }
    .tx-lg-82 {
        font-size: 82px
    }
    .tx-lg-82-f {
        font-size: 82px!important
    }
    .tx-lg-84 {
        font-size: 84px
    }
    .tx-lg-84-f {
        font-size: 84px!important
    }
    .tx-lg-86 {
        font-size: 86px
    }
    .tx-lg-86-f {
        font-size: 86px!important
    }
    .tx-lg-88 {
        font-size: 88px
    }
    .tx-lg-88-f {
        font-size: 88px!important
    }
    .tx-lg-90 {
        font-size: 90px
    }
    .tx-lg-90-f {
        font-size: 90px!important
    }
    .tx-lg-92 {
        font-size: 92px
    }
    .tx-lg-92-f {
        font-size: 92px!important
    }
    .tx-lg-94 {
        font-size: 94px
    }
    .tx-lg-94-f {
        font-size: 94px!important
    }
    .tx-lg-96 {
        font-size: 96px
    }
    .tx-lg-96-f {
        font-size: 96px!important
    }
    .tx-lg-98 {
        font-size: 98px
    }
    .tx-lg-98-f {
        font-size: 98px!important
    }
    .tx-lg-100 {
        font-size: 100px
    }
    .tx-lg-100-f {
        font-size: 100px!important
    }
    .tx-lg-102 {
        font-size: 102px
    }
    .tx-lg-102-f {
        font-size: 102px!important
    }
    .tx-lg-104 {
        font-size: 104px
    }
    .tx-lg-104-f {
        font-size: 104px!important
    }
    .tx-lg-106 {
        font-size: 106px
    }
    .tx-lg-106-f {
        font-size: 106px!important
    }
    .tx-lg-108 {
        font-size: 108px
    }
    .tx-lg-108-f {
        font-size: 108px!important
    }
    .tx-lg-110 {
        font-size: 110px
    }
    .tx-lg-110-f {
        font-size: 110px!important
    }
    .tx-lg-112 {
        font-size: 112px
    }
    .tx-lg-112-f {
        font-size: 112px!important
    }
    .tx-lg-114 {
        font-size: 114px
    }
    .tx-lg-114-f {
        font-size: 114px!important
    }
    .tx-lg-116 {
        font-size: 116px
    }
    .tx-lg-116-f {
        font-size: 116px!important
    }
    .tx-lg-118 {
        font-size: 118px
    }
    .tx-lg-118-f {
        font-size: 118px!important
    }
    .tx-lg-120 {
        font-size: 120px
    }
    .tx-lg-120-f {
        font-size: 120px!important
    }
    .tx-lg-122 {
        font-size: 122px
    }
    .tx-lg-122-f {
        font-size: 122px!important
    }
    .tx-lg-124 {
        font-size: 124px
    }
    .tx-lg-124-f {
        font-size: 124px!important
    }
    .tx-lg-126 {
        font-size: 126px
    }
    .tx-lg-126-f {
        font-size: 126px!important
    }
    .tx-lg-128 {
        font-size: 128px
    }
    .tx-lg-128-f {
        font-size: 128px!important
    }
    .tx-lg-130 {
        font-size: 130px
    }
    .tx-lg-130-f {
        font-size: 130px!important
    }
    .tx-lg-132 {
        font-size: 132px
    }
    .tx-lg-132-f {
        font-size: 132px!important
    }
    .tx-lg-134 {
        font-size: 134px
    }
    .tx-lg-134-f {
        font-size: 134px!important
    }
    .tx-lg-136 {
        font-size: 136px
    }
    .tx-lg-136-f {
        font-size: 136px!important
    }
    .tx-lg-138 {
        font-size: 138px
    }
    .tx-lg-138-f {
        font-size: 138px!important
    }
    .tx-lg-140 {
        font-size: 140px
    }
    .tx-lg-140-f {
        font-size: 140px!important
    }
    .tx-lg-base {
        font-size: .875rem
    }
    .tx-lg-bold {
        font-weight: 700
    }
    .tx-lg-semibold {
        font-weight: 600
    }
    .tx-lg-medium {
        font-weight: 500
    }
    .tx-lg-normal {
        font-weight: 400
    }
    .tx-lg-light {
        font-weight: 300
    }
    .tx-lg-thin {
        font-weight: 200
    }
    .tx-lg-xthin {
        font-weight: 100
    }
    .lh-lg-0 {
        line-height: 0
    }
    .lh-lg-1 {
        line-height: 1.1
    }
    .lh-lg-2 {
        line-height: 1.2
    }
    .lh-lg-3 {
        line-height: 1.3
    }
    .lh-lg-4 {
        line-height: 1.4
    }
    .lh-lg-5 {
        line-height: 1.5
    }
    .lh-lg-6 {
        line-height: 1.6
    }
    .lh-lg-7 {
        line-height: 1.7
    }
    .lh-lg-8 {
        line-height: 1.8
    }
    .lh-lg-9 {
        line-height: 1.9
    }
    .lh-lg-10 {
        line-height: 2
    }
    .lh-lg-11 {
        line-height: 2.1
    }
    .lh-lg-12 {
        line-height: 2.2
    }
    .lh-lg-13 {
        line-height: 2.3
    }
    .lh-lg-14 {
        line-height: 2.4
    }
    .lh-lg-15 {
        line-height: 2.5
    }
    .tx-lg-center {
        text-align: center
    }
    .tx-lg-right {
        text-align: right
    }
    .tx-lg-left {
        text-align: left
    }
    .tx-lg-center-f {
        text-align: center!important
    }
    .tx-lg-right-f {
        text-align: right!important
    }
    .tx-lg-left-f {
        text-align: left!important
    }
}

@media (min-width:1200px) {
    .tx-xl-8 {
        font-size: 8px
    }
    .tx-xl-8-f {
        font-size: 8px!important
    }
    .tx-xl-9 {
        font-size: 9px
    }
    .tx-xl-9-f {
        font-size: 9px!important
    }
    .tx-xl-10 {
        font-size: 10px
    }
    .tx-xl-10-f {
        font-size: 10px!important
    }
    .tx-xl-11 {
        font-size: 11px
    }
    .tx-xl-11-f {
        font-size: 11px!important
    }
    .tx-xl-12 {
        font-size: 12px
    }
    .tx-xl-12-f {
        font-size: 12px!important
    }
    .tx-xl-13 {
        font-size: 13px
    }
    .tx-xl-13-f {
        font-size: 13px!important
    }
    .tx-xl-14 {
        font-size: 14px
    }
    .tx-xl-14-f {
        font-size: 14px!important
    }
    .tx-xl-15 {
        font-size: 15px
    }
    .tx-xl-15-f {
        font-size: 15px!important
    }
    .tx-xl-16 {
        font-size: 16px
    }
    .tx-xl-16-f {
        font-size: 16px!important
    }
    .tx-xl-18 {
        font-size: 18px
    }
    .tx-xl-18-f {
        font-size: 18px!important
    }
    .tx-xl-20 {
        font-size: 20px
    }
    .tx-xl-20-f {
        font-size: 20px!important
    }
    .tx-xl-22 {
        font-size: 22px
    }
    .tx-xl-22-f {
        font-size: 22px!important
    }
    .tx-xl-24 {
        font-size: 24px
    }
    .tx-xl-24-f {
        font-size: 24px!important
    }
    .tx-xl-26 {
        font-size: 26px
    }
    .tx-xl-26-f {
        font-size: 26px!important
    }
    .tx-xl-28 {
        font-size: 28px
    }
    .tx-xl-28-f {
        font-size: 28px!important
    }
    .tx-xl-30 {
        font-size: 30px
    }
    .tx-xl-30-f {
        font-size: 30px!important
    }
    .tx-xl-32 {
        font-size: 32px
    }
    .tx-xl-32-f {
        font-size: 32px!important
    }
    .tx-xl-34 {
        font-size: 34px
    }
    .tx-xl-34-f {
        font-size: 34px!important
    }
    .tx-xl-36 {
        font-size: 36px
    }
    .tx-xl-36-f {
        font-size: 36px!important
    }
    .tx-xl-38 {
        font-size: 38px
    }
    .tx-xl-38-f {
        font-size: 38px!important
    }
    .tx-xl-40 {
        font-size: 40px
    }
    .tx-xl-40-f {
        font-size: 40px!important
    }
    .tx-xl-42 {
        font-size: 42px
    }
    .tx-xl-42-f {
        font-size: 42px!important
    }
    .tx-xl-44 {
        font-size: 44px
    }
    .tx-xl-44-f {
        font-size: 44px!important
    }
    .tx-xl-46 {
        font-size: 46px
    }
    .tx-xl-46-f {
        font-size: 46px!important
    }
    .tx-xl-48 {
        font-size: 48px
    }
    .tx-xl-48-f {
        font-size: 48px!important
    }
    .tx-xl-50 {
        font-size: 50px
    }
    .tx-xl-50-f {
        font-size: 50px!important
    }
    .tx-xl-52 {
        font-size: 52px
    }
    .tx-xl-52-f {
        font-size: 52px!important
    }
    .tx-xl-54 {
        font-size: 54px
    }
    .tx-xl-54-f {
        font-size: 54px!important
    }
    .tx-xl-56 {
        font-size: 56px
    }
    .tx-xl-56-f {
        font-size: 56px!important
    }
    .tx-xl-58 {
        font-size: 58px
    }
    .tx-xl-58-f {
        font-size: 58px!important
    }
    .tx-xl-60 {
        font-size: 60px
    }
    .tx-xl-60-f {
        font-size: 60px!important
    }
    .tx-xl-62 {
        font-size: 62px
    }
    .tx-xl-62-f {
        font-size: 62px!important
    }
    .tx-xl-64 {
        font-size: 64px
    }
    .tx-xl-64-f {
        font-size: 64px!important
    }
    .tx-xl-66 {
        font-size: 66px
    }
    .tx-xl-66-f {
        font-size: 66px!important
    }
    .tx-xl-68 {
        font-size: 68px
    }
    .tx-xl-68-f {
        font-size: 68px!important
    }
    .tx-xl-70 {
        font-size: 70px
    }
    .tx-xl-70-f {
        font-size: 70px!important
    }
    .tx-xl-72 {
        font-size: 72px
    }
    .tx-xl-72-f {
        font-size: 72px!important
    }
    .tx-xl-74 {
        font-size: 74px
    }
    .tx-xl-74-f {
        font-size: 74px!important
    }
    .tx-xl-76 {
        font-size: 76px
    }
    .tx-xl-76-f {
        font-size: 76px!important
    }
    .tx-xl-78 {
        font-size: 78px
    }
    .tx-xl-78-f {
        font-size: 78px!important
    }
    .tx-xl-80 {
        font-size: 80px
    }
    .tx-xl-80-f {
        font-size: 80px!important
    }
    .tx-xl-82 {
        font-size: 82px
    }
    .tx-xl-82-f {
        font-size: 82px!important
    }
    .tx-xl-84 {
        font-size: 84px
    }
    .tx-xl-84-f {
        font-size: 84px!important
    }
    .tx-xl-86 {
        font-size: 86px
    }
    .tx-xl-86-f {
        font-size: 86px!important
    }
    .tx-xl-88 {
        font-size: 88px
    }
    .tx-xl-88-f {
        font-size: 88px!important
    }
    .tx-xl-90 {
        font-size: 90px
    }
    .tx-xl-90-f {
        font-size: 90px!important
    }
    .tx-xl-92 {
        font-size: 92px
    }
    .tx-xl-92-f {
        font-size: 92px!important
    }
    .tx-xl-94 {
        font-size: 94px
    }
    .tx-xl-94-f {
        font-size: 94px!important
    }
    .tx-xl-96 {
        font-size: 96px
    }
    .tx-xl-96-f {
        font-size: 96px!important
    }
    .tx-xl-98 {
        font-size: 98px
    }
    .tx-xl-98-f {
        font-size: 98px!important
    }
    .tx-xl-100 {
        font-size: 100px
    }
    .tx-xl-100-f {
        font-size: 100px!important
    }
    .tx-xl-102 {
        font-size: 102px
    }
    .tx-xl-102-f {
        font-size: 102px!important
    }
    .tx-xl-104 {
        font-size: 104px
    }
    .tx-xl-104-f {
        font-size: 104px!important
    }
    .tx-xl-106 {
        font-size: 106px
    }
    .tx-xl-106-f {
        font-size: 106px!important
    }
    .tx-xl-108 {
        font-size: 108px
    }
    .tx-xl-108-f {
        font-size: 108px!important
    }
    .tx-xl-110 {
        font-size: 110px
    }
    .tx-xl-110-f {
        font-size: 110px!important
    }
    .tx-xl-112 {
        font-size: 112px
    }
    .tx-xl-112-f {
        font-size: 112px!important
    }
    .tx-xl-114 {
        font-size: 114px
    }
    .tx-xl-114-f {
        font-size: 114px!important
    }
    .tx-xl-116 {
        font-size: 116px
    }
    .tx-xl-116-f {
        font-size: 116px!important
    }
    .tx-xl-118 {
        font-size: 118px
    }
    .tx-xl-118-f {
        font-size: 118px!important
    }
    .tx-xl-120 {
        font-size: 120px
    }
    .tx-xl-120-f {
        font-size: 120px!important
    }
    .tx-xl-122 {
        font-size: 122px
    }
    .tx-xl-122-f {
        font-size: 122px!important
    }
    .tx-xl-124 {
        font-size: 124px
    }
    .tx-xl-124-f {
        font-size: 124px!important
    }
    .tx-xl-126 {
        font-size: 126px
    }
    .tx-xl-126-f {
        font-size: 126px!important
    }
    .tx-xl-128 {
        font-size: 128px
    }
    .tx-xl-128-f {
        font-size: 128px!important
    }
    .tx-xl-130 {
        font-size: 130px
    }
    .tx-xl-130-f {
        font-size: 130px!important
    }
    .tx-xl-132 {
        font-size: 132px
    }
    .tx-xl-132-f {
        font-size: 132px!important
    }
    .tx-xl-134 {
        font-size: 134px
    }
    .tx-xl-134-f {
        font-size: 134px!important
    }
    .tx-xl-136 {
        font-size: 136px
    }
    .tx-xl-136-f {
        font-size: 136px!important
    }
    .tx-xl-138 {
        font-size: 138px
    }
    .tx-xl-138-f {
        font-size: 138px!important
    }
    .tx-xl-140 {
        font-size: 140px
    }
    .tx-xl-140-f {
        font-size: 140px!important
    }
    .tx-xl-base {
        font-size: .875rem
    }
    .tx-xl-bold {
        font-weight: 700
    }
    .tx-xl-semibold {
        font-weight: 600
    }
    .tx-xl-medium {
        font-weight: 500
    }
    .tx-xl-normal {
        font-weight: 400
    }
    .tx-xl-light {
        font-weight: 300
    }
    .tx-xl-thin {
        font-weight: 200
    }
    .tx-xl-xthin {
        font-weight: 100
    }
    .lh-xl-0 {
        line-height: 0
    }
    .lh-xl-1 {
        line-height: 1.1
    }
    .lh-xl-2 {
        line-height: 1.2
    }
    .lh-xl-3 {
        line-height: 1.3
    }
    .lh-xl-4 {
        line-height: 1.4
    }
    .lh-xl-5 {
        line-height: 1.5
    }
    .lh-xl-6 {
        line-height: 1.6
    }
    .lh-xl-7 {
        line-height: 1.7
    }
    .lh-xl-8 {
        line-height: 1.8
    }
    .lh-xl-9 {
        line-height: 1.9
    }
    .lh-xl-10 {
        line-height: 2
    }
    .lh-xl-11 {
        line-height: 2.1
    }
    .lh-xl-12 {
        line-height: 2.2
    }
    .lh-xl-13 {
        line-height: 2.3
    }
    .lh-xl-14 {
        line-height: 2.4
    }
    .lh-xl-15 {
        line-height: 2.5
    }
    .tx-xl-center {
        text-align: center
    }
    .tx-xl-right {
        text-align: right
    }
    .tx-xl-left {
        text-align: left
    }
    .tx-xl-center-f {
        text-align: center!important
    }
    .tx-xl-right-f {
        text-align: right!important
    }
    .tx-xl-left-f {
        text-align: left!important
    }
}

.text-nowrap {
    white-space: nowrap!important
}

@media (max-width:576px) {
    .text-sm-nowrap {
        white-space: nowrap!important
    }
}

@media (max-width:768px) {
    .text-md-nowrap {
        white-space: nowrap!important
    }
}

@media (max-width:992px) {
    .text-lg-nowrap {
        white-space: nowrap!important
    }
}

@media (max-width:1000px) {
    .text-xl-nowrap {
        white-space: nowrap!important
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace!important
}

.text-justify {
    text-align: justify!important
}

.text-wrap {
    white-space: normal!important
}

.text-nowrap {
    white-space: nowrap!important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left!important
}

.text-right {
    text-align: right!important
}

.text-center {
    text-align: center!important
}

@media (min-width:576px) {
    .text-sm-left {
        text-align: left!important
    }
    .text-sm-right {
        text-align: right!important
    }
    .text-sm-center {
        text-align: center!important
    }
}

@media (min-width:768px) {
    .text-md-left {
        text-align: left!important
    }
    .text-md-right {
        text-align: right!important
    }
    .text-md-center {
        text-align: center!important
    }
}

@media (min-width:992px) {
    .text-lg-left {
        text-align: left!important
    }
    .text-lg-right {
        text-align: right!important
    }
    .text-lg-center {
        text-align: center!important
    }
}

@media (min-width:1200px) {
    .text-xl-left {
        text-align: left!important
    }
    .text-xl-right {
        text-align: right!important
    }
    .text-xl-center {
        text-align: center!important
    }
}

.text-lowercase {
    text-transform: lowercase!important
}

.text-uppercase {
    text-transform: uppercase!important
}

.text-capitalize {
    text-transform: capitalize!important
}

.font-weight-light {
    font-weight: 300!important
}

.font-weight-lighter {
    font-weight: lighter!important
}

.font-weight-normal {
    font-weight: 400!important
}

.font-weight-bold {
    font-weight: 700!important
}

.font-weight-bolder {
    font-weight: bolder!important
}

.font-italic {
    font-style: italic!important
}

.text-white {
    color: #fff!important
}

.text-primary {
    color: #078586!important
}

a.text-primary:focus,
a.text-primary:hover {
    color: #8485fb!important
}

.text-secondary {
    color: #5f6d88!important
}

a.text-secondary:focus,
a.text-secondary:hover {
    color: #556179!important
}

.text-success {
    color: #21b18a!important
}

a.text-success:focus,
a.text-success:hover {
    color: #226401!important
}

.text-info {
    color: #078586!important
}

a.text-info:focus,
a.text-info:hover {
    color: #0f6674!important
}

.text-warning {
    color: #e8b210!important
}

a.text-warning:focus,
a.text-warning:hover {
    color: #ba8b00!important
}

.text-purple {
    color: #673ab7!important
}

a.text-purple:focus,
a.text-purple:hover {
    color: #673ab7!important
}

.text-danger {
    color: #ee335e!important
}

a.text-danger:focus,
a.text-danger:hover {
    color: #a71d2a!important
}

.text-pink {
    color: #f10075!important
}

a.text-pink:focus,
a.text-pink:hover {
    color: #e61078!important
}

.text-orange {
    color: #fd7e14!important
}

a.text-orange:focus,
a.text-orange:hover {
    color: #e46f0e!important
}

.text-light {
    color: #ecf0fa!important
}

a.text-light:focus,
a.text-light:hover {
    color: #c5cbda!important
}

.text-teal {
    color: #0cc!important
}

a.text-teal:focus,
a.text-teal:hover {
    color: #06c1c1!important
}

.text-dark {
    color: #364261!important
}

a.text-dark:focus,
a.text-dark:hover {
    color: #1e2533!important
}

.text-body {
    color: #031b4e!important
}

.text-muted {
    color: #7987a1!important
}

.text-black-50 {
    color: rgba(0, 0, 0, .5)!important
}

.text-white-50 {
    color: rgba(255, 255, 255, .5)!important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.text-decoration-none {
    text-decoration: none!important
}

.text-break {
    word-break: break-word!important;
    overflow-wrap: break-word!important
}

.text-reset {
    color: inherit!important
}

.visible {
    visibility: visible!important
}

.invisible {
    visibility: hidden!important
}

.w-25 {
    width: 25%!important
}

.w-50 {
    width: 50%!important
}

.w-75 {
    width: 75%!important
}

.w-100 {
    width: 100%!important
}

.w-auto {
    width: auto!important
}

.wd-1 {
    width: 1px
}

.wd-2 {
    width: 2px
}

.wd-3 {
    width: 3px
}

.wd-4 {
    width: 4px
}

.wd-6 {
    width: 6px
}

.wd-7 {
    width: 7px
}

.wd-8 {
    width: 8px
}

.wd-9 {
    width: 9px
}

.wd-280 {
    width: 280px
}

.wd-5 {
    width: 5px
}

.wd-5p {
    width: 5%
}

.mx-wd-5p {
    max-width: 5%
}

.mn-wd-5p {
    min-width: 5%
}

.wd-5-f {
    width: 5px!important
}

.wd-5p-f {
    width: 5%!important
}

.mx-wd-5p-f {
    max-width: 5%!important
}

.mn-wd-5p-f {
    min-width: 5%!important
}

.wd-10 {
    width: 10px
}

.wd-10p {
    width: 10%
}

.mx-wd-10p {
    max-width: 10%
}

.mn-wd-10p {
    min-width: 10%
}

.wd-10-f {
    width: 10px!important
}

.wd-10p-f {
    width: 10%!important
}

.mx-wd-10p-f {
    max-width: 10%!important
}

.mn-wd-10p-f {
    min-width: 10%!important
}

.wd-15 {
    width: 15px
}

.wd-15p {
    width: 15%
}

.mx-wd-15p {
    max-width: 15%
}

.mn-wd-15p {
    min-width: 15%
}

.wd-15-f {
    width: 15px!important
}

.wd-15p-f {
    width: 15%!important
}

.mx-wd-15p-f {
    max-width: 15%!important
}

.mn-wd-15p-f {
    min-width: 15%!important
}

.wd-20 {
    width: 20px
}

.wd-20p {
    width: 20%
}

.mx-wd-20p {
    max-width: 20%
}

.mn-wd-20p {
    min-width: 20%
}

.wd-20-f {
    width: 20px!important
}

.wd-20p-f {
    width: 20%!important
}

.mx-wd-20p-f {
    max-width: 20%!important
}

.mn-wd-20p-f {
    min-width: 20%!important
}

.wd-25 {
    width: 25px
}

.wd-25p {
    width: 25%
}

.mx-wd-25p {
    max-width: 25%
}

.mn-wd-25p {
    min-width: 25%
}

.wd-25-f {
    width: 25px!important
}

.wd-25p-f {
    width: 25%!important
}

.mx-wd-25p-f {
    max-width: 25%!important
}

.mn-wd-25p-f {
    min-width: 25%!important
}

.wd-30 {
    width: 30px
}

.wd-30p {
    width: 30%
}

.mx-wd-30p {
    max-width: 30%
}

.mn-wd-30p {
    min-width: 30%
}

.wd-30-f {
    width: 30px!important
}

.wd-30p-f {
    width: 30%!important
}

.mx-wd-30p-f {
    max-width: 30%!important
}

.mn-wd-30p-f {
    min-width: 30%!important
}

.wd-35 {
    width: 35px
}

.wd-35p {
    width: 35%
}

.mx-wd-35p {
    max-width: 35%
}

.mn-wd-35p {
    min-width: 35%
}

.wd-35-f {
    width: 35px!important
}

.wd-35p-f {
    width: 35%!important
}

.mx-wd-35p-f {
    max-width: 35%!important
}

.mn-wd-35p-f {
    min-width: 35%!important
}

.wd-40 {
    width: 40px
}

.wd-40p {
    width: 40%
}

.mx-wd-40p {
    max-width: 40%
}

.mn-wd-40p {
    min-width: 40%
}

.wd-40-f {
    width: 40px!important
}

.wd-40p-f {
    width: 40%!important
}

.mx-wd-40p-f {
    max-width: 40%!important
}

.mn-wd-40p-f {
    min-width: 40%!important
}

.wd-45 {
    width: 45px
}

.wd-45p {
    width: 45%
}

.mx-wd-45p {
    max-width: 45%
}

.mn-wd-45p {
    min-width: 45%
}

.wd-45-f {
    width: 45px!important
}

.wd-45p-f {
    width: 45%!important
}

.mx-wd-45p-f {
    max-width: 45%!important
}

.mn-wd-45p-f {
    min-width: 45%!important
}

.wd-50 {
    width: 50px
}

.wd-50p {
    width: 50%
}

.mx-wd-50p {
    max-width: 50%
}

.mn-wd-50p {
    min-width: 50%
}

.wd-50-f {
    width: 50px!important
}

.wd-50p-f {
    width: 50%!important
}

.mx-wd-50p-f {
    max-width: 50%!important
}

.mn-wd-50p-f {
    min-width: 50%!important
}

.wd-55 {
    width: 55px
}

.wd-55p {
    width: 55%
}

.mx-wd-55p {
    max-width: 55%
}

.mn-wd-55p {
    min-width: 55%
}

.wd-55-f {
    width: 55px!important
}

.wd-55p-f {
    width: 55%!important
}

.mx-wd-55p-f {
    max-width: 55%!important
}

.mn-wd-55p-f {
    min-width: 55%!important
}

.wd-60 {
    width: 60px
}

.wd-60p {
    width: 60%
}

.mx-wd-60p {
    max-width: 60%
}

.mn-wd-60p {
    min-width: 60%
}

.wd-60-f {
    width: 60px!important
}

.wd-60p-f {
    width: 60%!important
}

.mx-wd-60p-f {
    max-width: 60%!important
}

.mn-wd-60p-f {
    min-width: 60%!important
}

.wd-65 {
    width: 65px
}

.wd-65p {
    width: 65%
}

.mx-wd-65p {
    max-width: 65%
}

.mn-wd-65p {
    min-width: 65%
}

.wd-65-f {
    width: 65px!important
}

.wd-65p-f {
    width: 65%!important
}

.mx-wd-65p-f {
    max-width: 65%!important
}

.mn-wd-65p-f {
    min-width: 65%!important
}

.wd-70 {
    width: 70px
}

.wd-70p {
    width: 70%
}

.mx-wd-70p {
    max-width: 70%
}

.mn-wd-70p {
    min-width: 70%
}

.wd-70-f {
    width: 70px!important
}

.wd-70p-f {
    width: 70%!important
}

.mx-wd-70p-f {
    max-width: 70%!important
}

.mn-wd-70p-f {
    min-width: 70%!important
}

.wd-75 {
    width: 75px
}

.wd-75p {
    width: 75%
}

.mx-wd-75p {
    max-width: 75%
}

.mn-wd-75p {
    min-width: 75%
}

.wd-75-f {
    width: 75px!important
}

.wd-75p-f {
    width: 75%!important
}

.mx-wd-75p-f {
    max-width: 75%!important
}

.mn-wd-75p-f {
    min-width: 75%!important
}

.wd-80 {
    width: 80px
}

.wd-80p {
    width: 80%
}

.mx-wd-80p {
    max-width: 80%
}

.mn-wd-80p {
    min-width: 80%
}

.wd-80-f {
    width: 80px!important
}

.wd-80p-f {
    width: 80%!important
}

.mx-wd-80p-f {
    max-width: 80%!important
}

.mn-wd-80p-f {
    min-width: 80%!important
}

.wd-85 {
    width: 85px
}

.wd-85p {
    width: 85%
}

.mx-wd-85p {
    max-width: 85%
}

.mn-wd-85p {
    min-width: 85%
}

.wd-85-f {
    width: 85px!important
}

.wd-85p-f {
    width: 85%!important
}

.mx-wd-85p-f {
    max-width: 85%!important
}

.mn-wd-85p-f {
    min-width: 85%!important
}

.wd-90 {
    width: 90px
}

.wd-90p {
    width: 90%
}

.mx-wd-90p {
    max-width: 90%
}

.mn-wd-90p {
    min-width: 90%
}

.wd-90-f {
    width: 90px!important
}

.wd-90p-f {
    width: 90%!important
}

.mx-wd-90p-f {
    max-width: 90%!important
}

.mn-wd-90p-f {
    min-width: 90%!important
}

.wd-95 {
    width: 95px
}

.wd-95p {
    width: 95%
}

.mx-wd-95p {
    max-width: 95%
}

.mn-wd-95p {
    min-width: 95%
}

.wd-95-f {
    width: 95px!important
}

.wd-95p-f {
    width: 95%!important
}

.mx-wd-95p-f {
    max-width: 95%!important
}

.mn-wd-95p-f {
    min-width: 95%!important
}

.wd-100 {
    width: 100px!important
}

.wd-100p {
    width: 100%
}

.mx-wd-100p {
    max-width: 100%
}

.mn-wd-100p {
    min-width: 100%
}

.wd-100-f {
    width: 100px!important
}

.wd-100p-f {
    width: 100%!important
}

.mx-wd-100p-f {
    max-width: 100%!important
}

.mn-wd-100p-f {
    min-width: 100%!important
}

.wd-150 {
    width: 150px
}

.wd-150-f {
    width: 150px!important
}

.wd-200 {
    width: 200px
}

.wd-200-f {
    width: 200px!important
}

.wd-230 {
    width: 230px
}

.wd-250 {
    width: 250px
}

.wd-250-f {
    width: 250px!important
}

.wd-300 {
    width: 300px
}

.wd-300-f {
    width: 300px!important
}

.wd-350 {
    width: 350px
}

.wd-350-f {
    width: 350px!important
}

.wd-400 {
    width: 400px
}

.wd-400-f {
    width: 400px!important
}

.wd-450 {
    width: 450px
}

.wd-450-f {
    width: 450px!important
}

.wd-500 {
    width: 500px
}

.wd-500-f {
    width: 500px!important
}

.wd-550 {
    width: 550px
}

.wd-550-f {
    width: 550px!important
}

.wd-600 {
    width: 600px
}

.wd-600-f {
    width: 600px!important
}

.wd-650 {
    width: 650px
}

.wd-650-f {
    width: 650px!important
}

.wd-700 {
    width: 700px
}

.wd-700-f {
    width: 700px!important
}

.wd-750 {
    width: 750px
}

.wd-750-f {
    width: 750px!important
}

.wd-800 {
    width: 800px
}

.wd-800-f {
    width: 800px!important
}

.wd-850 {
    width: 850px
}

.wd-850-f {
    width: 850px!important
}

.wd-900 {
    width: 900px
}

.wd-900-f {
    width: 900px!important
}

.wd-950 {
    width: 950px
}

.wd-950-f {
    width: 950px!important
}

.wd-1000 {
    width: 1000px
}

.wd-1000-f {
    width: 1000px!important
}

@media (min-width:480px) {
    .wd-xs-5 {
        width: 5px
    }
    .wd-xs-5p {
        width: 5%
    }
    .mx-wd-xs-5p {
        max-width: 5%
    }
    .mn-wd-xs-5p {
        min-width: 5%
    }
    .wd-xs-5-f {
        width: 5px!important
    }
    .wd-xs-5p-f {
        width: 5%!important
    }
    .mx-wd-xs-5p-f {
        max-width: 5%!important
    }
    .mn-wd-xs-5p-f {
        min-width: 5%!important
    }
    .wd-xs-10 {
        width: 10px
    }
    .wd-xs-10p {
        width: 10%
    }
    .mx-wd-xs-10p {
        max-width: 10%
    }
    .mn-wd-xs-10p {
        min-width: 10%
    }
    .wd-xs-10-f {
        width: 10px!important
    }
    .wd-xs-10p-f {
        width: 10%!important
    }
    .mx-wd-xs-10p-f {
        max-width: 10%!important
    }
    .mn-wd-xs-10p-f {
        min-width: 10%!important
    }
    .wd-xs-15 {
        width: 15px
    }
    .wd-xs-15p {
        width: 15%
    }
    .mx-wd-xs-15p {
        max-width: 15%
    }
    .mn-wd-xs-15p {
        min-width: 15%
    }
    .wd-xs-15-f {
        width: 15px!important
    }
    .wd-xs-15p-f {
        width: 15%!important
    }
    .mx-wd-xs-15p-f {
        max-width: 15%!important
    }
    .mn-wd-xs-15p-f {
        min-width: 15%!important
    }
    .wd-xs-20 {
        width: 20px
    }
    .wd-xs-20p {
        width: 20%
    }
    .mx-wd-xs-20p {
        max-width: 20%
    }
    .mn-wd-xs-20p {
        min-width: 20%
    }
    .wd-xs-20-f {
        width: 20px!important
    }
    .wd-xs-20p-f {
        width: 20%!important
    }
    .mx-wd-xs-20p-f {
        max-width: 20%!important
    }
    .mn-wd-xs-20p-f {
        min-width: 20%!important
    }
    .wd-xs-25 {
        width: 25px
    }
    .wd-xs-25p {
        width: 25%
    }
    .mx-wd-xs-25p {
        max-width: 25%
    }
    .mn-wd-xs-25p {
        min-width: 25%
    }
    .wd-xs-25-f {
        width: 25px!important
    }
    .wd-xs-25p-f {
        width: 25%!important
    }
    .mx-wd-xs-25p-f {
        max-width: 25%!important
    }
    .mn-wd-xs-25p-f {
        min-width: 25%!important
    }
    .wd-xs-30 {
        width: 30px
    }
    .wd-xs-30p {
        width: 30%
    }
    .mx-wd-xs-30p {
        max-width: 30%
    }
    .mn-wd-xs-30p {
        min-width: 30%
    }
    .wd-xs-30-f {
        width: 30px!important
    }
    .wd-xs-30p-f {
        width: 30%!important
    }
    .mx-wd-xs-30p-f {
        max-width: 30%!important
    }
    .mn-wd-xs-30p-f {
        min-width: 30%!important
    }
    .wd-xs-35 {
        width: 35px
    }
    .wd-xs-35p {
        width: 35%
    }
    .mx-wd-xs-35p {
        max-width: 35%
    }
    .mn-wd-xs-35p {
        min-width: 35%
    }
    .wd-xs-35-f {
        width: 35px!important
    }
    .wd-xs-35p-f {
        width: 35%!important
    }
    .mx-wd-xs-35p-f {
        max-width: 35%!important
    }
    .mn-wd-xs-35p-f {
        min-width: 35%!important
    }
    .wd-xs-40 {
        width: 40px
    }
    .wd-xs-40p {
        width: 40%
    }
    .mx-wd-xs-40p {
        max-width: 40%
    }
    .mn-wd-xs-40p {
        min-width: 40%
    }
    .wd-xs-40-f {
        width: 40px!important
    }
    .wd-xs-40p-f {
        width: 40%!important
    }
    .mx-wd-xs-40p-f {
        max-width: 40%!important
    }
    .mn-wd-xs-40p-f {
        min-width: 40%!important
    }
    .wd-xs-45 {
        width: 45px
    }
    .wd-xs-45p {
        width: 45%
    }
    .mx-wd-xs-45p {
        max-width: 45%
    }
    .mn-wd-xs-45p {
        min-width: 45%
    }
    .wd-xs-45-f {
        width: 45px!important
    }
    .wd-xs-45p-f {
        width: 45%!important
    }
    .mx-wd-xs-45p-f {
        max-width: 45%!important
    }
    .mn-wd-xs-45p-f {
        min-width: 45%!important
    }
    .wd-xs-50 {
        width: 50px
    }
    .wd-xs-50p {
        width: 50%
    }
    .mx-wd-xs-50p {
        max-width: 50%
    }
    .mn-wd-xs-50p {
        min-width: 50%
    }
    .wd-xs-50-f {
        width: 50px!important
    }
    .wd-xs-50p-f {
        width: 50%!important
    }
    .mx-wd-xs-50p-f {
        max-width: 50%!important
    }
    .mn-wd-xs-50p-f {
        min-width: 50%!important
    }
    .wd-xs-55 {
        width: 55px
    }
    .wd-xs-55p {
        width: 55%
    }
    .mx-wd-xs-55p {
        max-width: 55%
    }
    .mn-wd-xs-55p {
        min-width: 55%
    }
    .wd-xs-55-f {
        width: 55px!important
    }
    .wd-xs-55p-f {
        width: 55%!important
    }
    .mx-wd-xs-55p-f {
        max-width: 55%!important
    }
    .mn-wd-xs-55p-f {
        min-width: 55%!important
    }
    .wd-xs-60 {
        width: 60px
    }
    .wd-xs-60p {
        width: 60%
    }
    .mx-wd-xs-60p {
        max-width: 60%
    }
    .mn-wd-xs-60p {
        min-width: 60%
    }
    .wd-xs-60-f {
        width: 60px!important
    }
    .wd-xs-60p-f {
        width: 60%!important
    }
    .mx-wd-xs-60p-f {
        max-width: 60%!important
    }
    .mn-wd-xs-60p-f {
        min-width: 60%!important
    }
    .wd-xs-65 {
        width: 65px
    }
    .wd-xs-65p {
        width: 65%
    }
    .mx-wd-xs-65p {
        max-width: 65%
    }
    .mn-wd-xs-65p {
        min-width: 65%
    }
    .wd-xs-65-f {
        width: 65px!important
    }
    .wd-xs-65p-f {
        width: 65%!important
    }
    .mx-wd-xs-65p-f {
        max-width: 65%!important
    }
    .mn-wd-xs-65p-f {
        min-width: 65%!important
    }
    .wd-xs-70 {
        width: 70px
    }
    .wd-xs-70p {
        width: 70%
    }
    .mx-wd-xs-70p {
        max-width: 70%
    }
    .mn-wd-xs-70p {
        min-width: 70%
    }
    .wd-xs-70-f {
        width: 70px!important
    }
    .wd-xs-70p-f {
        width: 70%!important
    }
    .mx-wd-xs-70p-f {
        max-width: 70%!important
    }
    .mn-wd-xs-70p-f {
        min-width: 70%!important
    }
    .wd-xs-75 {
        width: 75px
    }
    .wd-xs-75p {
        width: 75%
    }
    .mx-wd-xs-75p {
        max-width: 75%
    }
    .mn-wd-xs-75p {
        min-width: 75%
    }
    .wd-xs-75-f {
        width: 75px!important
    }
    .wd-xs-75p-f {
        width: 75%!important
    }
    .mx-wd-xs-75p-f {
        max-width: 75%!important
    }
    .mn-wd-xs-75p-f {
        min-width: 75%!important
    }
    .wd-xs-80 {
        width: 80px
    }
    .wd-xs-80p {
        width: 80%
    }
    .mx-wd-xs-80p {
        max-width: 80%
    }
    .mn-wd-xs-80p {
        min-width: 80%
    }
    .wd-xs-80-f {
        width: 80px!important
    }
    .wd-xs-80p-f {
        width: 80%!important
    }
    .mx-wd-xs-80p-f {
        max-width: 80%!important
    }
    .mn-wd-xs-80p-f {
        min-width: 80%!important
    }
    .wd-xs-85 {
        width: 85px
    }
    .wd-xs-85p {
        width: 85%
    }
    .mx-wd-xs-85p {
        max-width: 85%
    }
    .mn-wd-xs-85p {
        min-width: 85%
    }
    .wd-xs-85-f {
        width: 85px!important
    }
    .wd-xs-85p-f {
        width: 85%!important
    }
    .mx-wd-xs-85p-f {
        max-width: 85%!important
    }
    .mn-wd-xs-85p-f {
        min-width: 85%!important
    }
    .wd-xs-90 {
        width: 90px
    }
    .wd-xs-90p {
        width: 90%
    }
    .mx-wd-xs-90p {
        max-width: 90%
    }
    .mn-wd-xs-90p {
        min-width: 90%
    }
    .wd-xs-90-f {
        width: 90px!important
    }
    .wd-xs-90p-f {
        width: 90%!important
    }
    .mx-wd-xs-90p-f {
        max-width: 90%!important
    }
    .mn-wd-xs-90p-f {
        min-width: 90%!important
    }
    .wd-xs-95 {
        width: 95px
    }
    .wd-xs-95p {
        width: 95%
    }
    .mx-wd-xs-95p {
        max-width: 95%
    }
    .mn-wd-xs-95p {
        min-width: 95%
    }
    .wd-xs-95-f {
        width: 95px!important
    }
    .wd-xs-95p-f {
        width: 95%!important
    }
    .mx-wd-xs-95p-f {
        max-width: 95%!important
    }
    .mn-wd-xs-95p-f {
        min-width: 95%!important
    }
    .wd-xs-100 {
        width: 100px
    }
    .wd-xs-100p {
        width: 100%
    }
    .mx-wd-xs-100p {
        max-width: 100%
    }
    .mn-wd-xs-100p {
        min-width: 100%
    }
    .wd-xs-100-f {
        width: 100px!important
    }
    .wd-xs-100p-f {
        width: 100%!important
    }
    .mx-wd-xs-100p-f {
        max-width: 100%!important
    }
    .mn-wd-xs-100p-f {
        min-width: 100%!important
    }
    .wd-xs-150 {
        width: 150px
    }
    .wd-xs-150p {
        width: 150%
    }
    .mx-wd-xs-150p {
        max-width: 150%
    }
    .mn-wd-xs-150p {
        min-width: 150%
    }
    .wd-xs-150-f {
        width: 150px!important
    }
    .wd-xs-150p-f {
        width: 150%!important
    }
    .mx-wd-xs-150p-f {
        max-width: 150%!important
    }
    .mn-wd-xs-150p-f {
        min-width: 150%!important
    }
    .wd-xs-200 {
        width: 200px
    }
    .wd-xs-200p {
        width: 200%
    }
    .mx-wd-xs-200p {
        max-width: 200%
    }
    .mn-wd-xs-200p {
        min-width: 200%
    }
    .wd-xs-200-f {
        width: 200px!important
    }
    .wd-xs-200p-f {
        width: 200%!important
    }
    .mx-wd-xs-200p-f {
        max-width: 200%!important
    }
    .mn-wd-xs-200p-f {
        min-width: 200%!important
    }
    .wd-xs-250 {
        width: 250px
    }
    .wd-xs-250p {
        width: 250%
    }
    .mx-wd-xs-250p {
        max-width: 250%
    }
    .mn-wd-xs-250p {
        min-width: 250%
    }
    .wd-xs-250-f {
        width: 250px!important
    }
    .wd-xs-250p-f {
        width: 250%!important
    }
    .mx-wd-xs-250p-f {
        max-width: 250%!important
    }
    .mn-wd-xs-250p-f {
        min-width: 250%!important
    }
    .wd-xs-300 {
        width: 300px
    }
    .wd-xs-300p {
        width: 300%
    }
    .mx-wd-xs-300p {
        max-width: 300%
    }
    .mn-wd-xs-300p {
        min-width: 300%
    }
    .wd-xs-300-f {
        width: 300px!important
    }
    .wd-xs-300p-f {
        width: 300%!important
    }
    .mx-wd-xs-300p-f {
        max-width: 300%!important
    }
    .mn-wd-xs-300p-f {
        min-width: 300%!important
    }
    .wd-xs-350 {
        width: 350px
    }
    .wd-xs-350p {
        width: 350%
    }
    .mx-wd-xs-350p {
        max-width: 350%
    }
    .mn-wd-xs-350p {
        min-width: 350%
    }
    .wd-xs-350-f {
        width: 350px!important
    }
    .wd-xs-350p-f {
        width: 350%!important
    }
    .mx-wd-xs-350p-f {
        max-width: 350%!important
    }
    .mn-wd-xs-350p-f {
        min-width: 350%!important
    }
    .wd-xs-400 {
        width: 400px
    }
    .wd-xs-400p {
        width: 400%
    }
    .mx-wd-xs-400p {
        max-width: 400%
    }
    .mn-wd-xs-400p {
        min-width: 400%
    }
    .wd-xs-400-f {
        width: 400px!important
    }
    .wd-xs-400p-f {
        width: 400%!important
    }
    .mx-wd-xs-400p-f {
        max-width: 400%!important
    }
    .mn-wd-xs-400p-f {
        min-width: 400%!important
    }
    .wd-xs-450 {
        width: 450px
    }
    .wd-xs-450p {
        width: 450%
    }
    .mx-wd-xs-450p {
        max-width: 450%
    }
    .mn-wd-xs-450p {
        min-width: 450%
    }
    .wd-xs-450-f {
        width: 450px!important
    }
    .wd-xs-450p-f {
        width: 450%!important
    }
    .mx-wd-xs-450p-f {
        max-width: 450%!important
    }
    .mn-wd-xs-450p-f {
        min-width: 450%!important
    }
    .wd-xs-500 {
        width: 500px
    }
    .wd-xs-500p {
        width: 500%
    }
    .mx-wd-xs-500p {
        max-width: 500%
    }
    .mn-wd-xs-500p {
        min-width: 500%
    }
    .wd-xs-500-f {
        width: 500px!important
    }
    .wd-xs-500p-f {
        width: 500%!important
    }
    .mx-wd-xs-500p-f {
        max-width: 500%!important
    }
    .mn-wd-xs-500p-f {
        min-width: 500%!important
    }
    .wd-xs-550 {
        width: 550px
    }
    .wd-xs-550p {
        width: 550%
    }
    .mx-wd-xs-550p {
        max-width: 550%
    }
    .mn-wd-xs-550p {
        min-width: 550%
    }
    .wd-xs-550-f {
        width: 550px!important
    }
    .wd-xs-550p-f {
        width: 550%!important
    }
    .mx-wd-xs-550p-f {
        max-width: 550%!important
    }
    .mn-wd-xs-550p-f {
        min-width: 550%!important
    }
    .wd-xs-600 {
        width: 600px
    }
    .wd-xs-600p {
        width: 600%
    }
    .mx-wd-xs-600p {
        max-width: 600%
    }
    .mn-wd-xs-600p {
        min-width: 600%
    }
    .wd-xs-600-f {
        width: 600px!important
    }
    .wd-xs-600p-f {
        width: 600%!important
    }
    .mx-wd-xs-600p-f {
        max-width: 600%!important
    }
    .mn-wd-xs-600p-f {
        min-width: 600%!important
    }
    .wd-xs-650 {
        width: 650px
    }
    .wd-xs-650p {
        width: 650%
    }
    .mx-wd-xs-650p {
        max-width: 650%
    }
    .mn-wd-xs-650p {
        min-width: 650%
    }
    .wd-xs-650-f {
        width: 650px!important
    }
    .wd-xs-650p-f {
        width: 650%!important
    }
    .mx-wd-xs-650p-f {
        max-width: 650%!important
    }
    .mn-wd-xs-650p-f {
        min-width: 650%!important
    }
    .wd-xs-700 {
        width: 700px
    }
    .wd-xs-700p {
        width: 700%
    }
    .mx-wd-xs-700p {
        max-width: 700%
    }
    .mn-wd-xs-700p {
        min-width: 700%
    }
    .wd-xs-700-f {
        width: 700px!important
    }
    .wd-xs-700p-f {
        width: 700%!important
    }
    .mx-wd-xs-700p-f {
        max-width: 700%!important
    }
    .mn-wd-xs-700p-f {
        min-width: 700%!important
    }
    .wd-xs-750 {
        width: 750px
    }
    .wd-xs-750p {
        width: 750%
    }
    .mx-wd-xs-750p {
        max-width: 750%
    }
    .mn-wd-xs-750p {
        min-width: 750%
    }
    .wd-xs-750-f {
        width: 750px!important
    }
    .wd-xs-750p-f {
        width: 750%!important
    }
    .mx-wd-xs-750p-f {
        max-width: 750%!important
    }
    .mn-wd-xs-750p-f {
        min-width: 750%!important
    }
    .wd-xs-800 {
        width: 800px
    }
    .wd-xs-800p {
        width: 800%
    }
    .mx-wd-xs-800p {
        max-width: 800%
    }
    .mn-wd-xs-800p {
        min-width: 800%
    }
    .wd-xs-800-f {
        width: 800px!important
    }
    .wd-xs-800p-f {
        width: 800%!important
    }
    .mx-wd-xs-800p-f {
        max-width: 800%!important
    }
    .mn-wd-xs-800p-f {
        min-width: 800%!important
    }
    .wd-xs-850 {
        width: 850px
    }
    .wd-xs-850p {
        width: 850%
    }
    .mx-wd-xs-850p {
        max-width: 850%
    }
    .mn-wd-xs-850p {
        min-width: 850%
    }
    .wd-xs-850-f {
        width: 850px!important
    }
    .wd-xs-850p-f {
        width: 850%!important
    }
    .mx-wd-xs-850p-f {
        max-width: 850%!important
    }
    .mn-wd-xs-850p-f {
        min-width: 850%!important
    }
    .wd-xs-900 {
        width: 900px
    }
    .wd-xs-900p {
        width: 900%
    }
    .mx-wd-xs-900p {
        max-width: 900%
    }
    .mn-wd-xs-900p {
        min-width: 900%
    }
    .wd-xs-900-f {
        width: 900px!important
    }
    .wd-xs-900p-f {
        width: 900%!important
    }
    .mx-wd-xs-900p-f {
        max-width: 900%!important
    }
    .mn-wd-xs-900p-f {
        min-width: 900%!important
    }
    .wd-xs-950 {
        width: 950px
    }
    .wd-xs-950p {
        width: 950%
    }
    .mx-wd-xs-950p {
        max-width: 950%
    }
    .mn-wd-xs-950p {
        min-width: 950%
    }
    .wd-xs-950-f {
        width: 950px!important
    }
    .wd-xs-950p-f {
        width: 950%!important
    }
    .mx-wd-xs-950p-f {
        max-width: 950%!important
    }
    .mn-wd-xs-950p-f {
        min-width: 950%!important
    }
    .wd-xs-1000 {
        width: 1000px
    }
    .wd-xs-1000p {
        width: 1000%
    }
    .mx-wd-xs-1000p {
        max-width: 1000%
    }
    .mn-wd-xs-1000p {
        min-width: 1000%
    }
    .wd-xs-1000-f {
        width: 1000px!important
    }
    .wd-xs-1000p-f {
        width: 1000%!important
    }
    .mx-wd-xs-1000p-f {
        max-width: 1000%!important
    }
    .mn-wd-xs-1000p-f {
        min-width: 1000%!important
    }
    .wd-xs-auto {
        width: auto
    }
    .wd-xs-auto-f {
        width: auto!important
    }
}

@media (min-width:576px) {
    .wd-sm-5 {
        width: 5px
    }
    .wd-sm-5p {
        width: 5%
    }
    .mx-wd-sm-5p {
        max-width: 5%
    }
    .mn-wd-sm-5p {
        min-width: 5%
    }
    .wd-sm-5-f {
        width: 5px!important
    }
    .wd-sm-5p-f {
        width: 5%!important
    }
    .mx-wd-sm-5p-f {
        max-width: 5%!important
    }
    .mn-wd-sm-5p-f {
        min-width: 5%!important
    }
    .wd-sm-10 {
        width: 10px
    }
    .wd-sm-10p {
        width: 10%
    }
    .mx-wd-sm-10p {
        max-width: 10%
    }
    .mn-wd-sm-10p {
        min-width: 10%
    }
    .wd-sm-10-f {
        width: 10px!important
    }
    .wd-sm-10p-f {
        width: 10%!important
    }
    .mx-wd-sm-10p-f {
        max-width: 10%!important
    }
    .mn-wd-sm-10p-f {
        min-width: 10%!important
    }
    .wd-sm-15 {
        width: 15px
    }
    .wd-sm-15p {
        width: 15%
    }
    .mx-wd-sm-15p {
        max-width: 15%
    }
    .mn-wd-sm-15p {
        min-width: 15%
    }
    .wd-sm-15-f {
        width: 15px!important
    }
    .wd-sm-15p-f {
        width: 15%!important
    }
    .mx-wd-sm-15p-f {
        max-width: 15%!important
    }
    .mn-wd-sm-15p-f {
        min-width: 15%!important
    }
    .wd-sm-20 {
        width: 20px
    }
    .wd-sm-20p {
        width: 20%
    }
    .mx-wd-sm-20p {
        max-width: 20%
    }
    .mn-wd-sm-20p {
        min-width: 20%
    }
    .wd-sm-20-f {
        width: 20px!important
    }
    .wd-sm-20p-f {
        width: 20%!important
    }
    .mx-wd-sm-20p-f {
        max-width: 20%!important
    }
    .mn-wd-sm-20p-f {
        min-width: 20%!important
    }
    .wd-sm-25 {
        width: 25px
    }
    .wd-sm-25p {
        width: 25%
    }
    .mx-wd-sm-25p {
        max-width: 25%
    }
    .mn-wd-sm-25p {
        min-width: 25%
    }
    .wd-sm-25-f {
        width: 25px!important
    }
    .wd-sm-25p-f {
        width: 25%!important
    }
    .mx-wd-sm-25p-f {
        max-width: 25%!important
    }
    .mn-wd-sm-25p-f {
        min-width: 25%!important
    }
    .wd-sm-30 {
        width: 30px
    }
    .wd-sm-30p {
        width: 30%
    }
    .mx-wd-sm-30p {
        max-width: 30%
    }
    .mn-wd-sm-30p {
        min-width: 30%
    }
    .wd-sm-30-f {
        width: 30px!important
    }
    .wd-sm-30p-f {
        width: 30%!important
    }
    .mx-wd-sm-30p-f {
        max-width: 30%!important
    }
    .mn-wd-sm-30p-f {
        min-width: 30%!important
    }
    .wd-sm-35 {
        width: 35px
    }
    .wd-sm-35p {
        width: 35%
    }
    .mx-wd-sm-35p {
        max-width: 35%
    }
    .mn-wd-sm-35p {
        min-width: 35%
    }
    .wd-sm-35-f {
        width: 35px!important
    }
    .wd-sm-35p-f {
        width: 35%!important
    }
    .mx-wd-sm-35p-f {
        max-width: 35%!important
    }
    .mn-wd-sm-35p-f {
        min-width: 35%!important
    }
    .wd-sm-40 {
        width: 40px
    }
    .wd-sm-40p {
        width: 40%
    }
    .mx-wd-sm-40p {
        max-width: 40%
    }
    .mn-wd-sm-40p {
        min-width: 40%
    }
    .wd-sm-40-f {
        width: 40px!important
    }
    .wd-sm-40p-f {
        width: 40%!important
    }
    .mx-wd-sm-40p-f {
        max-width: 40%!important
    }
    .mn-wd-sm-40p-f {
        min-width: 40%!important
    }
    .wd-sm-45 {
        width: 45px
    }
    .wd-sm-45p {
        width: 45%
    }
    .mx-wd-sm-45p {
        max-width: 45%
    }
    .mn-wd-sm-45p {
        min-width: 45%
    }
    .wd-sm-45-f {
        width: 45px!important
    }
    .wd-sm-45p-f {
        width: 45%!important
    }
    .mx-wd-sm-45p-f {
        max-width: 45%!important
    }
    .mn-wd-sm-45p-f {
        min-width: 45%!important
    }
    .wd-sm-50 {
        width: 50px
    }
    .wd-sm-50p {
        width: 50%
    }
    .mx-wd-sm-50p {
        max-width: 50%
    }
    .mn-wd-sm-50p {
        min-width: 50%
    }
    .wd-sm-50-f {
        width: 50px!important
    }
    .wd-sm-50p-f {
        width: 50%!important
    }
    .mx-wd-sm-50p-f {
        max-width: 50%!important
    }
    .mn-wd-sm-50p-f {
        min-width: 50%!important
    }
    .wd-sm-55 {
        width: 55px
    }
    .wd-sm-55p {
        width: 55%
    }
    .mx-wd-sm-55p {
        max-width: 55%
    }
    .mn-wd-sm-55p {
        min-width: 55%
    }
    .wd-sm-55-f {
        width: 55px!important
    }
    .wd-sm-55p-f {
        width: 55%!important
    }
    .mx-wd-sm-55p-f {
        max-width: 55%!important
    }
    .mn-wd-sm-55p-f {
        min-width: 55%!important
    }
    .wd-sm-60 {
        width: 60px
    }
    .wd-sm-60p {
        width: 60%
    }
    .mx-wd-sm-60p {
        max-width: 60%
    }
    .mn-wd-sm-60p {
        min-width: 60%
    }
    .wd-sm-60-f {
        width: 60px!important
    }
    .wd-sm-60p-f {
        width: 60%!important
    }
    .mx-wd-sm-60p-f {
        max-width: 60%!important
    }
    .mn-wd-sm-60p-f {
        min-width: 60%!important
    }
    .wd-sm-65 {
        width: 65px
    }
    .wd-sm-65p {
        width: 65%
    }
    .mx-wd-sm-65p {
        max-width: 65%
    }
    .mn-wd-sm-65p {
        min-width: 65%
    }
    .wd-sm-65-f {
        width: 65px!important
    }
    .wd-sm-65p-f {
        width: 65%!important
    }
    .mx-wd-sm-65p-f {
        max-width: 65%!important
    }
    .mn-wd-sm-65p-f {
        min-width: 65%!important
    }
    .wd-sm-70 {
        width: 70px
    }
    .wd-sm-70p {
        width: 70%
    }
    .mx-wd-sm-70p {
        max-width: 70%
    }
    .mn-wd-sm-70p {
        min-width: 70%
    }
    .wd-sm-70-f {
        width: 70px!important
    }
    .wd-sm-70p-f {
        width: 70%!important
    }
    .mx-wd-sm-70p-f {
        max-width: 70%!important
    }
    .mn-wd-sm-70p-f {
        min-width: 70%!important
    }
    .wd-sm-75 {
        width: 75px
    }
    .wd-sm-75p {
        width: 75%
    }
    .mx-wd-sm-75p {
        max-width: 75%
    }
    .mn-wd-sm-75p {
        min-width: 75%
    }
    .wd-sm-75-f {
        width: 75px!important
    }
    .wd-sm-75p-f {
        width: 75%!important
    }
    .mx-wd-sm-75p-f {
        max-width: 75%!important
    }
    .mn-wd-sm-75p-f {
        min-width: 75%!important
    }
    .wd-sm-80 {
        width: 80px
    }
    .wd-sm-80p {
        width: 80%
    }
    .mx-wd-sm-80p {
        max-width: 80%
    }
    .mn-wd-sm-80p {
        min-width: 80%
    }
    .wd-sm-80-f {
        width: 80px!important
    }
    .wd-sm-80p-f {
        width: 80%!important
    }
    .mx-wd-sm-80p-f {
        max-width: 80%!important
    }
    .mn-wd-sm-80p-f {
        min-width: 80%!important
    }
    .wd-sm-85 {
        width: 85px
    }
    .wd-sm-85p {
        width: 85%
    }
    .mx-wd-sm-85p {
        max-width: 85%
    }
    .mn-wd-sm-85p {
        min-width: 85%
    }
    .wd-sm-85-f {
        width: 85px!important
    }
    .wd-sm-85p-f {
        width: 85%!important
    }
    .mx-wd-sm-85p-f {
        max-width: 85%!important
    }
    .mn-wd-sm-85p-f {
        min-width: 85%!important
    }
    .wd-sm-90 {
        width: 90px
    }
    .wd-sm-90p {
        width: 90%
    }
    .mx-wd-sm-90p {
        max-width: 90%
    }
    .mn-wd-sm-90p {
        min-width: 90%
    }
    .wd-sm-90-f {
        width: 90px!important
    }
    .wd-sm-90p-f {
        width: 90%!important
    }
    .mx-wd-sm-90p-f {
        max-width: 90%!important
    }
    .mn-wd-sm-90p-f {
        min-width: 90%!important
    }
    .wd-sm-95 {
        width: 95px
    }
    .wd-sm-95p {
        width: 95%
    }
    .mx-wd-sm-95p {
        max-width: 95%
    }
    .mn-wd-sm-95p {
        min-width: 95%
    }
    .wd-sm-95-f {
        width: 95px!important
    }
    .wd-sm-95p-f {
        width: 95%!important
    }
    .mx-wd-sm-95p-f {
        max-width: 95%!important
    }
    .mn-wd-sm-95p-f {
        min-width: 95%!important
    }
    .wd-sm-100 {
        width: 100px
    }
    .wd-sm-100p {
        width: 100%
    }
    .mx-wd-sm-100p {
        max-width: 100%
    }
    .mn-wd-sm-100p {
        min-width: 100%
    }
    .wd-sm-100-f {
        width: 100px!important
    }
    .wd-sm-100p-f {
        width: 100%!important
    }
    .mx-wd-sm-100p-f {
        max-width: 100%!important
    }
    .mn-wd-sm-100p-f {
        min-width: 100%!important
    }
    .wd-sm-150 {
        width: 150px
    }
    .wd-sm-150p {
        width: 150%
    }
    .mx-wd-sm-150p {
        max-width: 150%
    }
    .mn-wd-sm-150p {
        min-width: 150%
    }
    .wd-sm-150-f {
        width: 150px!important
    }
    .wd-sm-150p-f {
        width: 150%!important
    }
    .mx-wd-sm-150p-f {
        max-width: 150%!important
    }
    .mn-wd-sm-150p-f {
        min-width: 150%!important
    }
    .wd-sm-200 {
        width: 200px
    }
    .wd-sm-200p {
        width: 200%
    }
    .mx-wd-sm-200p {
        max-width: 200%
    }
    .mn-wd-sm-200p {
        min-width: 200%
    }
    .wd-sm-200-f {
        width: 200px!important
    }
    .wd-sm-200p-f {
        width: 200%!important
    }
    .mx-wd-sm-200p-f {
        max-width: 200%!important
    }
    .mn-wd-sm-200p-f {
        min-width: 200%!important
    }
    .wd-sm-250 {
        width: 250px
    }
    .wd-sm-250p {
        width: 250%
    }
    .mx-wd-sm-250p {
        max-width: 250%
    }
    .mn-wd-sm-250p {
        min-width: 250%
    }
    .wd-sm-250-f {
        width: 250px!important
    }
    .wd-sm-250p-f {
        width: 250%!important
    }
    .mx-wd-sm-250p-f {
        max-width: 250%!important
    }
    .mn-wd-sm-250p-f {
        min-width: 250%!important
    }
    .wd-sm-300 {
        width: 300px
    }
    .wd-sm-300p {
        width: 300%
    }
    .mx-wd-sm-300p {
        max-width: 300%
    }
    .mn-wd-sm-300p {
        min-width: 300%
    }
    .wd-sm-300-f {
        width: 300px!important
    }
    .wd-sm-300p-f {
        width: 300%!important
    }
    .mx-wd-sm-300p-f {
        max-width: 300%!important
    }
    .mn-wd-sm-300p-f {
        min-width: 300%!important
    }
    .wd-sm-350 {
        width: 350px
    }
    .wd-sm-350p {
        width: 350%
    }
    .mx-wd-sm-350p {
        max-width: 350%
    }
    .mn-wd-sm-350p {
        min-width: 350%
    }
    .wd-sm-350-f {
        width: 350px!important
    }
    .wd-sm-350p-f {
        width: 350%!important
    }
    .mx-wd-sm-350p-f {
        max-width: 350%!important
    }
    .mn-wd-sm-350p-f {
        min-width: 350%!important
    }
    .wd-sm-400 {
        width: 400px
    }
    .wd-sm-400p {
        width: 400%
    }
    .mx-wd-sm-400p {
        max-width: 400%
    }
    .mn-wd-sm-400p {
        min-width: 400%
    }
    .wd-sm-400-f {
        width: 400px!important
    }
    .wd-sm-400p-f {
        width: 400%!important
    }
    .mx-wd-sm-400p-f {
        max-width: 400%!important
    }
    .mn-wd-sm-400p-f {
        min-width: 400%!important
    }
    .wd-sm-450 {
        width: 450px
    }
    .wd-sm-450p {
        width: 450%
    }
    .mx-wd-sm-450p {
        max-width: 450%
    }
    .mn-wd-sm-450p {
        min-width: 450%
    }
    .wd-sm-450-f {
        width: 450px!important
    }
    .wd-sm-450p-f {
        width: 450%!important
    }
    .mx-wd-sm-450p-f {
        max-width: 450%!important
    }
    .mn-wd-sm-450p-f {
        min-width: 450%!important
    }
    .wd-sm-500 {
        width: 500px
    }
    .wd-sm-500p {
        width: 500%
    }
    .mx-wd-sm-500p {
        max-width: 500%
    }
    .mn-wd-sm-500p {
        min-width: 500%
    }
    .wd-sm-500-f {
        width: 500px!important
    }
    .wd-sm-500p-f {
        width: 500%!important
    }
    .mx-wd-sm-500p-f {
        max-width: 500%!important
    }
    .mn-wd-sm-500p-f {
        min-width: 500%!important
    }
    .wd-sm-550 {
        width: 550px
    }
    .wd-sm-550p {
        width: 550%
    }
    .mx-wd-sm-550p {
        max-width: 550%
    }
    .mn-wd-sm-550p {
        min-width: 550%
    }
    .wd-sm-550-f {
        width: 550px!important
    }
    .wd-sm-550p-f {
        width: 550%!important
    }
    .mx-wd-sm-550p-f {
        max-width: 550%!important
    }
    .mn-wd-sm-550p-f {
        min-width: 550%!important
    }
    .wd-sm-600 {
        width: 600px
    }
    .wd-sm-600p {
        width: 600%
    }
    .mx-wd-sm-600p {
        max-width: 600%
    }
    .mn-wd-sm-600p {
        min-width: 600%
    }
    .wd-sm-600-f {
        width: 600px!important
    }
    .wd-sm-600p-f {
        width: 600%!important
    }
    .mx-wd-sm-600p-f {
        max-width: 600%!important
    }
    .mn-wd-sm-600p-f {
        min-width: 600%!important
    }
    .wd-sm-650 {
        width: 650px
    }
    .wd-sm-650p {
        width: 650%
    }
    .mx-wd-sm-650p {
        max-width: 650%
    }
    .mn-wd-sm-650p {
        min-width: 650%
    }
    .wd-sm-650-f {
        width: 650px!important
    }
    .wd-sm-650p-f {
        width: 650%!important
    }
    .mx-wd-sm-650p-f {
        max-width: 650%!important
    }
    .mn-wd-sm-650p-f {
        min-width: 650%!important
    }
    .wd-sm-700 {
        width: 700px
    }
    .wd-sm-700p {
        width: 700%
    }
    .mx-wd-sm-700p {
        max-width: 700%
    }
    .mn-wd-sm-700p {
        min-width: 700%
    }
    .wd-sm-700-f {
        width: 700px!important
    }
    .wd-sm-700p-f {
        width: 700%!important
    }
    .mx-wd-sm-700p-f {
        max-width: 700%!important
    }
    .mn-wd-sm-700p-f {
        min-width: 700%!important
    }
    .wd-sm-750 {
        width: 750px
    }
    .wd-sm-750p {
        width: 750%
    }
    .mx-wd-sm-750p {
        max-width: 750%
    }
    .mn-wd-sm-750p {
        min-width: 750%
    }
    .wd-sm-750-f {
        width: 750px!important
    }
    .wd-sm-750p-f {
        width: 750%!important
    }
    .mx-wd-sm-750p-f {
        max-width: 750%!important
    }
    .mn-wd-sm-750p-f {
        min-width: 750%!important
    }
    .wd-sm-800 {
        width: 800px
    }
    .wd-sm-800p {
        width: 800%
    }
    .mx-wd-sm-800p {
        max-width: 800%
    }
    .mn-wd-sm-800p {
        min-width: 800%
    }
    .wd-sm-800-f {
        width: 800px!important
    }
    .wd-sm-800p-f {
        width: 800%!important
    }
    .mx-wd-sm-800p-f {
        max-width: 800%!important
    }
    .mn-wd-sm-800p-f {
        min-width: 800%!important
    }
    .wd-sm-850 {
        width: 850px
    }
    .wd-sm-850p {
        width: 850%
    }
    .mx-wd-sm-850p {
        max-width: 850%
    }
    .mn-wd-sm-850p {
        min-width: 850%
    }
    .wd-sm-850-f {
        width: 850px!important
    }
    .wd-sm-850p-f {
        width: 850%!important
    }
    .mx-wd-sm-850p-f {
        max-width: 850%!important
    }
    .mn-wd-sm-850p-f {
        min-width: 850%!important
    }
    .wd-sm-900 {
        width: 900px
    }
    .wd-sm-900p {
        width: 900%
    }
    .mx-wd-sm-900p {
        max-width: 900%
    }
    .mn-wd-sm-900p {
        min-width: 900%
    }
    .wd-sm-900-f {
        width: 900px!important
    }
    .wd-sm-900p-f {
        width: 900%!important
    }
    .mx-wd-sm-900p-f {
        max-width: 900%!important
    }
    .mn-wd-sm-900p-f {
        min-width: 900%!important
    }
    .wd-sm-950 {
        width: 950px
    }
    .wd-sm-950p {
        width: 950%
    }
    .mx-wd-sm-950p {
        max-width: 950%
    }
    .mn-wd-sm-950p {
        min-width: 950%
    }
    .wd-sm-950-f {
        width: 950px!important
    }
    .wd-sm-950p-f {
        width: 950%!important
    }
    .mx-wd-sm-950p-f {
        max-width: 950%!important
    }
    .mn-wd-sm-950p-f {
        min-width: 950%!important
    }
    .wd-sm-1000 {
        width: 1000px
    }
    .wd-sm-1000p {
        width: 1000%
    }
    .mx-wd-sm-1000p {
        max-width: 1000%
    }
    .mn-wd-sm-1000p {
        min-width: 1000%
    }
    .wd-sm-1000-f {
        width: 1000px!important
    }
    .wd-sm-1000p-f {
        width: 1000%!important
    }
    .mx-wd-sm-1000p-f {
        max-width: 1000%!important
    }
    .mn-wd-sm-1000p-f {
        min-width: 1000%!important
    }
    .wd-sm-auto {
        width: auto
    }
    .wd-sm-auto-f {
        width: auto!important
    }
}

@media (min-width:768px) {
    .wd-md-5 {
        width: 5px
    }
    .wd-md-5p {
        width: 5%
    }
    .mx-wd-md-5p {
        max-width: 5%
    }
    .mn-wd-md-5p {
        min-width: 5%
    }
    .wd-md-5-f {
        width: 5px!important
    }
    .wd-md-5p-f {
        width: 5%!important
    }
    .mx-wd-md-5p-f {
        max-width: 5%!important
    }
    .mn-wd-md-5p-f {
        min-width: 5%!important
    }
    .wd-md-10 {
        width: 10px
    }
    .wd-md-10p {
        width: 10%
    }
    .mx-wd-md-10p {
        max-width: 10%
    }
    .mn-wd-md-10p {
        min-width: 10%
    }
    .wd-md-10-f {
        width: 10px!important
    }
    .wd-md-10p-f {
        width: 10%!important
    }
    .mx-wd-md-10p-f {
        max-width: 10%!important
    }
    .mn-wd-md-10p-f {
        min-width: 10%!important
    }
    .wd-md-15 {
        width: 15px
    }
    .wd-md-15p {
        width: 15%
    }
    .mx-wd-md-15p {
        max-width: 15%
    }
    .mn-wd-md-15p {
        min-width: 15%
    }
    .wd-md-15-f {
        width: 15px!important
    }
    .wd-md-15p-f {
        width: 15%!important
    }
    .mx-wd-md-15p-f {
        max-width: 15%!important
    }
    .mn-wd-md-15p-f {
        min-width: 15%!important
    }
    .wd-md-20 {
        width: 20px
    }
    .wd-md-20p {
        width: 20%
    }
    .mx-wd-md-20p {
        max-width: 20%
    }
    .mn-wd-md-20p {
        min-width: 20%
    }
    .wd-md-20-f {
        width: 20px!important
    }
    .wd-md-20p-f {
        width: 20%!important
    }
    .mx-wd-md-20p-f {
        max-width: 20%!important
    }
    .mn-wd-md-20p-f {
        min-width: 20%!important
    }
    .wd-md-25 {
        width: 25px
    }
    .wd-md-25p {
        width: 25%
    }
    .mx-wd-md-25p {
        max-width: 25%
    }
    .mn-wd-md-25p {
        min-width: 25%
    }
    .wd-md-25-f {
        width: 25px!important
    }
    .wd-md-25p-f {
        width: 25%!important
    }
    .mx-wd-md-25p-f {
        max-width: 25%!important
    }
    .mn-wd-md-25p-f {
        min-width: 25%!important
    }
    .wd-md-30 {
        width: 30px
    }
    .wd-md-30p {
        width: 30%
    }
    .mx-wd-md-30p {
        max-width: 30%
    }
    .mn-wd-md-30p {
        min-width: 30%
    }
    .wd-md-30-f {
        width: 30px!important
    }
    .wd-md-30p-f {
        width: 30%!important
    }
    .mx-wd-md-30p-f {
        max-width: 30%!important
    }
    .mn-wd-md-30p-f {
        min-width: 30%!important
    }
    .wd-md-35 {
        width: 35px
    }
    .wd-md-35p {
        width: 35%
    }
    .mx-wd-md-35p {
        max-width: 35%
    }
    .mn-wd-md-35p {
        min-width: 35%
    }
    .wd-md-35-f {
        width: 35px!important
    }
    .wd-md-35p-f {
        width: 35%!important
    }
    .mx-wd-md-35p-f {
        max-width: 35%!important
    }
    .mn-wd-md-35p-f {
        min-width: 35%!important
    }
    .wd-md-40 {
        width: 40px
    }
    .wd-md-40p {
        width: 40%
    }
    .mx-wd-md-40p {
        max-width: 40%
    }
    .mn-wd-md-40p {
        min-width: 40%
    }
    .wd-md-40-f {
        width: 40px!important
    }
    .wd-md-40p-f {
        width: 40%!important
    }
    .mx-wd-md-40p-f {
        max-width: 40%!important
    }
    .mn-wd-md-40p-f {
        min-width: 40%!important
    }
    .wd-md-45 {
        width: 45px
    }
    .wd-md-45p {
        width: 45%
    }
    .mx-wd-md-45p {
        max-width: 45%
    }
    .mn-wd-md-45p {
        min-width: 45%
    }
    .wd-md-45-f {
        width: 45px!important
    }
    .wd-md-45p-f {
        width: 45%!important
    }
    .mx-wd-md-45p-f {
        max-width: 45%!important
    }
    .mn-wd-md-45p-f {
        min-width: 45%!important
    }
    .wd-md-50 {
        width: 50px
    }
    .wd-md-50p {
        width: 50%
    }
    .mx-wd-md-50p {
        max-width: 50%
    }
    .mn-wd-md-50p {
        min-width: 50%
    }
    .wd-md-50-f {
        width: 50px!important
    }
    .wd-md-50p-f {
        width: 50%!important
    }
    .mx-wd-md-50p-f {
        max-width: 50%!important
    }
    .mn-wd-md-50p-f {
        min-width: 50%!important
    }
    .wd-md-55 {
        width: 55px
    }
    .wd-md-55p {
        width: 55%
    }
    .mx-wd-md-55p {
        max-width: 55%
    }
    .mn-wd-md-55p {
        min-width: 55%
    }
    .wd-md-55-f {
        width: 55px!important
    }
    .wd-md-55p-f {
        width: 55%!important
    }
    .mx-wd-md-55p-f {
        max-width: 55%!important
    }
    .mn-wd-md-55p-f {
        min-width: 55%!important
    }
    .wd-md-60 {
        width: 60px
    }
    .wd-md-60p {
        width: 60%
    }
    .mx-wd-md-60p {
        max-width: 60%
    }
    .mn-wd-md-60p {
        min-width: 60%
    }
    .wd-md-60-f {
        width: 60px!important
    }
    .wd-md-60p-f {
        width: 60%!important
    }
    .mx-wd-md-60p-f {
        max-width: 60%!important
    }
    .mn-wd-md-60p-f {
        min-width: 60%!important
    }
    .wd-md-65 {
        width: 65px
    }
    .wd-md-65p {
        width: 65%
    }
    .mx-wd-md-65p {
        max-width: 65%
    }
    .mn-wd-md-65p {
        min-width: 65%
    }
    .wd-md-65-f {
        width: 65px!important
    }
    .wd-md-65p-f {
        width: 65%!important
    }
    .mx-wd-md-65p-f {
        max-width: 65%!important
    }
    .mn-wd-md-65p-f {
        min-width: 65%!important
    }
    .wd-md-70 {
        width: 70px
    }
    .wd-md-70p {
        width: 70%
    }
    .mx-wd-md-70p {
        max-width: 70%
    }
    .mn-wd-md-70p {
        min-width: 70%
    }
    .wd-md-70-f {
        width: 70px!important
    }
    .wd-md-70p-f {
        width: 70%!important
    }
    .mx-wd-md-70p-f {
        max-width: 70%!important
    }
    .mn-wd-md-70p-f {
        min-width: 70%!important
    }
    .wd-md-75 {
        width: 75px
    }
    .wd-md-75p {
        width: 75%
    }
    .mx-wd-md-75p {
        max-width: 75%
    }
    .mn-wd-md-75p {
        min-width: 75%
    }
    .wd-md-75-f {
        width: 75px!important
    }
    .wd-md-75p-f {
        width: 75%!important
    }
    .mx-wd-md-75p-f {
        max-width: 75%!important
    }
    .mn-wd-md-75p-f {
        min-width: 75%!important
    }
    .wd-md-80 {
        width: 80px
    }
    .wd-md-80p {
        width: 80%
    }
    .mx-wd-md-80p {
        max-width: 80%
    }
    .mn-wd-md-80p {
        min-width: 80%
    }
    .wd-md-80-f {
        width: 80px!important
    }
    .wd-md-80p-f {
        width: 80%!important
    }
    .mx-wd-md-80p-f {
        max-width: 80%!important
    }
    .mn-wd-md-80p-f {
        min-width: 80%!important
    }
    .wd-md-85 {
        width: 85px
    }
    .wd-md-85p {
        width: 85%
    }
    .mx-wd-md-85p {
        max-width: 85%
    }
    .mn-wd-md-85p {
        min-width: 85%
    }
    .wd-md-85-f {
        width: 85px!important
    }
    .wd-md-85p-f {
        width: 85%!important
    }
    .mx-wd-md-85p-f {
        max-width: 85%!important
    }
    .mn-wd-md-85p-f {
        min-width: 85%!important
    }
    .wd-md-90 {
        width: 90px
    }
    .wd-md-90p {
        width: 90%
    }
    .mx-wd-md-90p {
        max-width: 90%
    }
    .mn-wd-md-90p {
        min-width: 90%
    }
    .wd-md-90-f {
        width: 90px!important
    }
    .wd-md-90p-f {
        width: 90%!important
    }
    .mx-wd-md-90p-f {
        max-width: 90%!important
    }
    .mn-wd-md-90p-f {
        min-width: 90%!important
    }
    .wd-md-95 {
        width: 95px
    }
    .wd-md-95p {
        width: 95%
    }
    .mx-wd-md-95p {
        max-width: 95%
    }
    .mn-wd-md-95p {
        min-width: 95%
    }
    .wd-md-95-f {
        width: 95px!important
    }
    .wd-md-95p-f {
        width: 95%!important
    }
    .mx-wd-md-95p-f {
        max-width: 95%!important
    }
    .mn-wd-md-95p-f {
        min-width: 95%!important
    }
    .wd-md-100 {
        width: 100px
    }
    .wd-md-100p {
        width: 100%
    }
    .mx-wd-md-100p {
        max-width: 100%
    }
    .mn-wd-md-100p {
        min-width: 100%
    }
    .wd-md-100-f {
        width: 100px!important
    }
    .wd-md-100p-f {
        width: 100%!important
    }
    .mx-wd-md-100p-f {
        max-width: 100%!important
    }
    .mn-wd-md-100p-f {
        min-width: 100%!important
    }
    .wd-md-150 {
        width: 150px
    }
    .wd-md-150p {
        width: 150%
    }
    .mx-wd-md-150p {
        max-width: 150%
    }
    .mn-wd-md-150p {
        min-width: 150%
    }
    .wd-md-150-f {
        width: 150px!important
    }
    .wd-md-150p-f {
        width: 150%!important
    }
    .mx-wd-md-150p-f {
        max-width: 150%!important
    }
    .mn-wd-md-150p-f {
        min-width: 150%!important
    }
    .wd-md-200 {
        width: 200px
    }
    .wd-md-200p {
        width: 200%
    }
    .mx-wd-md-200p {
        max-width: 200%
    }
    .mn-wd-md-200p {
        min-width: 200%
    }
    .wd-md-200-f {
        width: 200px!important
    }
    .wd-md-200p-f {
        width: 200%!important
    }
    .mx-wd-md-200p-f {
        max-width: 200%!important
    }
    .mn-wd-md-200p-f {
        min-width: 200%!important
    }
    .wd-md-250 {
        width: 250px
    }
    .wd-md-250p {
        width: 250%
    }
    .mx-wd-md-250p {
        max-width: 250%
    }
    .mn-wd-md-250p {
        min-width: 250%
    }
    .wd-md-250-f {
        width: 250px!important
    }
    .wd-md-250p-f {
        width: 250%!important
    }
    .mx-wd-md-250p-f {
        max-width: 250%!important
    }
    .mn-wd-md-250p-f {
        min-width: 250%!important
    }
    .wd-md-300 {
        width: 300px
    }
    .wd-md-300p {
        width: 300%
    }
    .mx-wd-md-300p {
        max-width: 300%
    }
    .mn-wd-md-300p {
        min-width: 300%
    }
    .wd-md-300-f {
        width: 300px!important
    }
    .wd-md-300p-f {
        width: 300%!important
    }
    .mx-wd-md-300p-f {
        max-width: 300%!important
    }
    .mn-wd-md-300p-f {
        min-width: 300%!important
    }
    .wd-md-350 {
        width: 350px
    }
    .wd-md-350p {
        width: 350%
    }
    .mx-wd-md-350p {
        max-width: 350%
    }
    .mn-wd-md-350p {
        min-width: 350%
    }
    .wd-md-350-f {
        width: 350px!important
    }
    .wd-md-350p-f {
        width: 350%!important
    }
    .mx-wd-md-350p-f {
        max-width: 350%!important
    }
    .mn-wd-md-350p-f {
        min-width: 350%!important
    }
    .wd-md-400 {
        width: 400px
    }
    .wd-md-400p {
        width: 400%
    }
    .mx-wd-md-400p {
        max-width: 400%
    }
    .mn-wd-md-400p {
        min-width: 400%
    }
    .wd-md-400-f {
        width: 400px!important
    }
    .wd-md-400p-f {
        width: 400%!important
    }
    .mx-wd-md-400p-f {
        max-width: 400%!important
    }
    .mn-wd-md-400p-f {
        min-width: 400%!important
    }
    .wd-md-450 {
        width: 450px
    }
    .wd-md-450p {
        width: 450%
    }
    .mx-wd-md-450p {
        max-width: 450%
    }
    .mn-wd-md-450p {
        min-width: 450%
    }
    .wd-md-450-f {
        width: 450px!important
    }
    .wd-md-450p-f {
        width: 450%!important
    }
    .mx-wd-md-450p-f {
        max-width: 450%!important
    }
    .mn-wd-md-450p-f {
        min-width: 450%!important
    }
    .wd-md-500 {
        width: 500px
    }
    .wd-md-500p {
        width: 500%
    }
    .mx-wd-md-500p {
        max-width: 500%
    }
    .mn-wd-md-500p {
        min-width: 500%
    }
    .wd-md-500-f {
        width: 500px!important
    }
    .wd-md-500p-f {
        width: 500%!important
    }
    .mx-wd-md-500p-f {
        max-width: 500%!important
    }
    .mn-wd-md-500p-f {
        min-width: 500%!important
    }
    .wd-md-550 {
        width: 550px
    }
    .wd-md-550p {
        width: 550%
    }
    .mx-wd-md-550p {
        max-width: 550%
    }
    .mn-wd-md-550p {
        min-width: 550%
    }
    .wd-md-550-f {
        width: 550px!important
    }
    .wd-md-550p-f {
        width: 550%!important
    }
    .mx-wd-md-550p-f {
        max-width: 550%!important
    }
    .mn-wd-md-550p-f {
        min-width: 550%!important
    }
    .wd-md-600 {
        width: 600px
    }
    .wd-md-600p {
        width: 600%
    }
    .mx-wd-md-600p {
        max-width: 600%
    }
    .mn-wd-md-600p {
        min-width: 600%
    }
    .wd-md-600-f {
        width: 600px!important
    }
    .wd-md-600p-f {
        width: 600%!important
    }
    .mx-wd-md-600p-f {
        max-width: 600%!important
    }
    .mn-wd-md-600p-f {
        min-width: 600%!important
    }
    .wd-md-650 {
        width: 650px
    }
    .wd-md-650p {
        width: 650%
    }
    .mx-wd-md-650p {
        max-width: 650%
    }
    .mn-wd-md-650p {
        min-width: 650%
    }
    .wd-md-650-f {
        width: 650px!important
    }
    .wd-md-650p-f {
        width: 650%!important
    }
    .mx-wd-md-650p-f {
        max-width: 650%!important
    }
    .mn-wd-md-650p-f {
        min-width: 650%!important
    }
    .wd-md-700 {
        width: 700px
    }
    .wd-md-700p {
        width: 700%
    }
    .mx-wd-md-700p {
        max-width: 700%
    }
    .mn-wd-md-700p {
        min-width: 700%
    }
    .wd-md-700-f {
        width: 700px!important
    }
    .wd-md-700p-f {
        width: 700%!important
    }
    .mx-wd-md-700p-f {
        max-width: 700%!important
    }
    .mn-wd-md-700p-f {
        min-width: 700%!important
    }
    .wd-md-750 {
        width: 750px
    }
    .wd-md-750p {
        width: 750%
    }
    .mx-wd-md-750p {
        max-width: 750%
    }
    .mn-wd-md-750p {
        min-width: 750%
    }
    .wd-md-750-f {
        width: 750px!important
    }
    .wd-md-750p-f {
        width: 750%!important
    }
    .mx-wd-md-750p-f {
        max-width: 750%!important
    }
    .mn-wd-md-750p-f {
        min-width: 750%!important
    }
    .wd-md-800 {
        width: 800px
    }
    .wd-md-800p {
        width: 800%
    }
    .mx-wd-md-800p {
        max-width: 800%
    }
    .mn-wd-md-800p {
        min-width: 800%
    }
    .wd-md-800-f {
        width: 800px!important
    }
    .wd-md-800p-f {
        width: 800%!important
    }
    .mx-wd-md-800p-f {
        max-width: 800%!important
    }
    .mn-wd-md-800p-f {
        min-width: 800%!important
    }
    .wd-md-850 {
        width: 850px
    }
    .wd-md-850p {
        width: 850%
    }
    .mx-wd-md-850p {
        max-width: 850%
    }
    .mn-wd-md-850p {
        min-width: 850%
    }
    .wd-md-850-f {
        width: 850px!important
    }
    .wd-md-850p-f {
        width: 850%!important
    }
    .mx-wd-md-850p-f {
        max-width: 850%!important
    }
    .mn-wd-md-850p-f {
        min-width: 850%!important
    }
    .wd-md-900 {
        width: 900px
    }
    .wd-md-900p {
        width: 900%
    }
    .mx-wd-md-900p {
        max-width: 900%
    }
    .mn-wd-md-900p {
        min-width: 900%
    }
    .wd-md-900-f {
        width: 900px!important
    }
    .wd-md-900p-f {
        width: 900%!important
    }
    .mx-wd-md-900p-f {
        max-width: 900%!important
    }
    .mn-wd-md-900p-f {
        min-width: 900%!important
    }
    .wd-md-950 {
        width: 950px
    }
    .wd-md-950p {
        width: 950%
    }
    .mx-wd-md-950p {
        max-width: 950%
    }
    .mn-wd-md-950p {
        min-width: 950%
    }
    .wd-md-950-f {
        width: 950px!important
    }
    .wd-md-950p-f {
        width: 950%!important
    }
    .mx-wd-md-950p-f {
        max-width: 950%!important
    }
    .mn-wd-md-950p-f {
        min-width: 950%!important
    }
    .wd-md-1000 {
        width: 1000px
    }
    .wd-md-1000p {
        width: 1000%
    }
    .mx-wd-md-1000p {
        max-width: 1000%
    }
    .mn-wd-md-1000p {
        min-width: 1000%
    }
    .wd-md-1000-f {
        width: 1000px!important
    }
    .wd-md-1000p-f {
        width: 1000%!important
    }
    .mx-wd-md-1000p-f {
        max-width: 1000%!important
    }
    .mn-wd-md-1000p-f {
        min-width: 1000%!important
    }
    .wd-md-auto {
        width: auto
    }
    .wd-md-auto-f {
        width: auto!important
    }
    .wd-md-120 {
        width: 120px
    }
}

@media (min-width:992px) {
    .wd-lg-5 {
        width: 5px
    }
    .wd-lg-5p {
        width: 5%
    }
    .mx-wd-lg-5p {
        max-width: 5%
    }
    .mn-wd-lg-5p {
        min-width: 5%
    }
    .wd-lg-5-f {
        width: 5px!important
    }
    .wd-lg-5p-f {
        width: 5%!important
    }
    .mx-wd-lg-5p-f {
        max-width: 5%!important
    }
    .mn-wd-lg-5p-f {
        min-width: 5%!important
    }
    .wd-lg-10 {
        width: 10px
    }
    .wd-lg-10p {
        width: 10%
    }
    .mx-wd-lg-10p {
        max-width: 10%
    }
    .mn-wd-lg-10p {
        min-width: 10%
    }
    .wd-lg-10-f {
        width: 10px!important
    }
    .wd-lg-10p-f {
        width: 10%!important
    }
    .mx-wd-lg-10p-f {
        max-width: 10%!important
    }
    .mn-wd-lg-10p-f {
        min-width: 10%!important
    }
    .wd-lg-15 {
        width: 15px
    }
    .wd-lg-15p {
        width: 15%
    }
    .mx-wd-lg-15p {
        max-width: 15%
    }
    .mn-wd-lg-15p {
        min-width: 15%
    }
    .wd-lg-15-f {
        width: 15px!important
    }
    .wd-lg-15p-f {
        width: 15%!important
    }
    .mx-wd-lg-15p-f {
        max-width: 15%!important
    }
    .mn-wd-lg-15p-f {
        min-width: 15%!important
    }
    .wd-lg-20 {
        width: 20px
    }
    .wd-lg-20p {
        width: 20%
    }
    .mx-wd-lg-20p {
        max-width: 20%
    }
    .mn-wd-lg-20p {
        min-width: 20%
    }
    .wd-lg-20-f {
        width: 20px!important
    }
    .wd-lg-20p-f {
        width: 20%!important
    }
    .mx-wd-lg-20p-f {
        max-width: 20%!important
    }
    .mn-wd-lg-20p-f {
        min-width: 20%!important
    }
    .wd-lg-25 {
        width: 25px
    }
    .wd-lg-25p {
        width: 25%
    }
    .mx-wd-lg-25p {
        max-width: 25%
    }
    .mn-wd-lg-25p {
        min-width: 25%
    }
    .wd-lg-25-f {
        width: 25px!important
    }
    .wd-lg-25p-f {
        width: 25%!important
    }
    .mx-wd-lg-25p-f {
        max-width: 25%!important
    }
    .mn-wd-lg-25p-f {
        min-width: 25%!important
    }
    .wd-lg-30 {
        width: 30px
    }
    .wd-lg-30p {
        width: 30%
    }
    .mx-wd-lg-30p {
        max-width: 30%
    }
    .mn-wd-lg-30p {
        min-width: 30%
    }
    .wd-lg-30-f {
        width: 30px!important
    }
    .wd-lg-30p-f {
        width: 30%!important
    }
    .mx-wd-lg-30p-f {
        max-width: 30%!important
    }
    .mn-wd-lg-30p-f {
        min-width: 30%!important
    }
    .wd-lg-35 {
        width: 35px
    }
    .wd-lg-35p {
        width: 35%
    }
    .mx-wd-lg-35p {
        max-width: 35%
    }
    .mn-wd-lg-35p {
        min-width: 35%
    }
    .wd-lg-35-f {
        width: 35px!important
    }
    .wd-lg-35p-f {
        width: 35%!important
    }
    .mx-wd-lg-35p-f {
        max-width: 35%!important
    }
    .mn-wd-lg-35p-f {
        min-width: 35%!important
    }
    .wd-lg-40 {
        width: 40px
    }
    .wd-lg-40p {
        width: 40%
    }
    .mx-wd-lg-40p {
        max-width: 40%
    }
    .mn-wd-lg-40p {
        min-width: 40%
    }
    .wd-lg-40-f {
        width: 40px!important
    }
    .wd-lg-40p-f {
        width: 40%!important
    }
    .mx-wd-lg-40p-f {
        max-width: 40%!important
    }
    .mn-wd-lg-40p-f {
        min-width: 40%!important
    }
    .wd-lg-45 {
        width: 45px
    }
    .wd-lg-45p {
        width: 45%
    }
    .mx-wd-lg-45p {
        max-width: 45%
    }
    .mn-wd-lg-45p {
        min-width: 45%
    }
    .wd-lg-45-f {
        width: 45px!important
    }
    .wd-lg-45p-f {
        width: 45%!important
    }
    .mx-wd-lg-45p-f {
        max-width: 45%!important
    }
    .mn-wd-lg-45p-f {
        min-width: 45%!important
    }
    .wd-lg-50 {
        width: 50px
    }
    .wd-lg-50p {
        width: 50%
    }
    .mx-wd-lg-50p {
        max-width: 50%
    }
    .mn-wd-lg-50p {
        min-width: 50%
    }
    .wd-lg-50-f {
        width: 50px!important
    }
    .wd-lg-50p-f {
        width: 50%!important
    }
    .mx-wd-lg-50p-f {
        max-width: 50%!important
    }
    .mn-wd-lg-50p-f {
        min-width: 50%!important
    }
    .wd-lg-55 {
        width: 55px
    }
    .wd-lg-55p {
        width: 55%
    }
    .mx-wd-lg-55p {
        max-width: 55%
    }
    .mn-wd-lg-55p {
        min-width: 55%
    }
    .wd-lg-55-f {
        width: 55px!important
    }
    .wd-lg-55p-f {
        width: 55%!important
    }
    .mx-wd-lg-55p-f {
        max-width: 55%!important
    }
    .mn-wd-lg-55p-f {
        min-width: 55%!important
    }
    .wd-lg-60 {
        width: 60px
    }
    .wd-lg-60p {
        width: 60%
    }
    .mx-wd-lg-60p {
        max-width: 60%
    }
    .mn-wd-lg-60p {
        min-width: 60%
    }
    .wd-lg-60-f {
        width: 60px!important
    }
    .wd-lg-60p-f {
        width: 60%!important
    }
    .mx-wd-lg-60p-f {
        max-width: 60%!important
    }
    .mn-wd-lg-60p-f {
        min-width: 60%!important
    }
    .wd-lg-65 {
        width: 65px
    }
    .wd-lg-65p {
        width: 65%
    }
    .mx-wd-lg-65p {
        max-width: 65%
    }
    .mn-wd-lg-65p {
        min-width: 65%
    }
    .wd-lg-65-f {
        width: 65px!important
    }
    .wd-lg-65p-f {
        width: 65%!important
    }
    .mx-wd-lg-65p-f {
        max-width: 65%!important
    }
    .mn-wd-lg-65p-f {
        min-width: 65%!important
    }
    .wd-lg-70 {
        width: 70px
    }
    .wd-lg-70p {
        width: 70%
    }
    .mx-wd-lg-70p {
        max-width: 70%
    }
    .mn-wd-lg-70p {
        min-width: 70%
    }
    .wd-lg-70-f {
        width: 70px!important
    }
    .wd-lg-70p-f {
        width: 70%!important
    }
    .mx-wd-lg-70p-f {
        max-width: 70%!important
    }
    .mn-wd-lg-70p-f {
        min-width: 70%!important
    }
    .wd-lg-75 {
        width: 75px
    }
    .wd-lg-75p {
        width: 75%
    }
    .mx-wd-lg-75p {
        max-width: 75%
    }
    .mn-wd-lg-75p {
        min-width: 75%
    }
    .wd-lg-75-f {
        width: 75px!important
    }
    .wd-lg-75p-f {
        width: 75%!important
    }
    .mx-wd-lg-75p-f {
        max-width: 75%!important
    }
    .mn-wd-lg-75p-f {
        min-width: 75%!important
    }
    .wd-lg-80 {
        width: 80px
    }
    .wd-lg-80p {
        width: 80%
    }
    .mx-wd-lg-80p {
        max-width: 80%
    }
    .mn-wd-lg-80p {
        min-width: 80%
    }
    .wd-lg-80-f {
        width: 80px!important
    }
    .wd-lg-80p-f {
        width: 80%!important
    }
    .mx-wd-lg-80p-f {
        max-width: 80%!important
    }
    .mn-wd-lg-80p-f {
        min-width: 80%!important
    }
    .wd-lg-85 {
        width: 85px
    }
    .wd-lg-85p {
        width: 85%
    }
    .mx-wd-lg-85p {
        max-width: 85%
    }
    .mn-wd-lg-85p {
        min-width: 85%
    }
    .wd-lg-85-f {
        width: 85px!important
    }
    .wd-lg-85p-f {
        width: 85%!important
    }
    .mx-wd-lg-85p-f {
        max-width: 85%!important
    }
    .mn-wd-lg-85p-f {
        min-width: 85%!important
    }
    .wd-lg-90 {
        width: 90px
    }
    .wd-lg-90p {
        width: 90%
    }
    .mx-wd-lg-90p {
        max-width: 90%
    }
    .mn-wd-lg-90p {
        min-width: 90%
    }
    .wd-lg-90-f {
        width: 90px!important
    }
    .wd-lg-90p-f {
        width: 90%!important
    }
    .mx-wd-lg-90p-f {
        max-width: 90%!important
    }
    .mn-wd-lg-90p-f {
        min-width: 90%!important
    }
    .wd-lg-95 {
        width: 95px
    }
    .wd-lg-95p {
        width: 95%
    }
    .mx-wd-lg-95p {
        max-width: 95%
    }
    .mn-wd-lg-95p {
        min-width: 95%
    }
    .wd-lg-95-f {
        width: 95px!important
    }
    .wd-lg-95p-f {
        width: 95%!important
    }
    .mx-wd-lg-95p-f {
        max-width: 95%!important
    }
    .mn-wd-lg-95p-f {
        min-width: 95%!important
    }
    .wd-lg-100 {
        width: 100px
    }
    .wd-lg-100p {
        width: 100%
    }
    .mx-wd-lg-100p {
        max-width: 100%
    }
    .mn-wd-lg-100p {
        min-width: 100%
    }
    .wd-lg-100-f {
        width: 100px!important
    }
    .wd-lg-100p-f {
        width: 100%!important
    }
    .mx-wd-lg-100p-f {
        max-width: 100%!important
    }
    .mn-wd-lg-100p-f {
        min-width: 100%!important
    }
    .wd-lg-150 {
        width: 150px
    }
    .wd-lg-150p {
        width: 150%
    }
    .mx-wd-lg-150p {
        max-width: 150%
    }
    .mn-wd-lg-150p {
        min-width: 150%
    }
    .wd-lg-150-f {
        width: 150px!important
    }
    .wd-lg-150p-f {
        width: 150%!important
    }
    .mx-wd-lg-150p-f {
        max-width: 150%!important
    }
    .mn-wd-lg-150p-f {
        min-width: 150%!important
    }
    .wd-lg-200 {
        width: 200px
    }
    .wd-lg-200p {
        width: 200%
    }
    .mx-wd-lg-200p {
        max-width: 200%
    }
    .mn-wd-lg-200p {
        min-width: 200%
    }
    .wd-lg-200-f {
        width: 200px!important
    }
    .wd-lg-200p-f {
        width: 200%!important
    }
    .mx-wd-lg-200p-f {
        max-width: 200%!important
    }
    .mn-wd-lg-200p-f {
        min-width: 200%!important
    }
    .wd-lg-250 {
        width: 250px
    }
    .wd-lg-250p {
        width: 250%
    }
    .mx-wd-lg-250p {
        max-width: 250%
    }
    .mn-wd-lg-250p {
        min-width: 250%
    }
    .wd-lg-250-f {
        width: 250px!important
    }
    .wd-lg-250p-f {
        width: 250%!important
    }
    .mx-wd-lg-250p-f {
        max-width: 250%!important
    }
    .mn-wd-lg-250p-f {
        min-width: 250%!important
    }
    .wd-lg-300 {
        width: 300px
    }
    .wd-lg-300p {
        width: 300%
    }
    .mx-wd-lg-300p {
        max-width: 300%
    }
    .mn-wd-lg-300p {
        min-width: 300%
    }
    .wd-lg-300-f {
        width: 300px!important
    }
    .wd-lg-300p-f {
        width: 300%!important
    }
    .mx-wd-lg-300p-f {
        max-width: 300%!important
    }
    .mn-wd-lg-300p-f {
        min-width: 300%!important
    }
    .wd-lg-350 {
        width: 350px
    }
    .wd-lg-350p {
        width: 350%
    }
    .mx-wd-lg-350p {
        max-width: 350%
    }
    .mn-wd-lg-350p {
        min-width: 350%
    }
    .wd-lg-350-f {
        width: 350px!important
    }
    .wd-lg-350p-f {
        width: 350%!important
    }
    .mx-wd-lg-350p-f {
        max-width: 350%!important
    }
    .mn-wd-lg-350p-f {
        min-width: 350%!important
    }
    .wd-lg-400 {
        width: 400px
    }
    .wd-lg-400p {
        width: 400%
    }
    .mx-wd-lg-400p {
        max-width: 400%
    }
    .mn-wd-lg-400p {
        min-width: 400%
    }
    .wd-lg-400-f {
        width: 400px!important
    }
    .wd-lg-400p-f {
        width: 400%!important
    }
    .mx-wd-lg-400p-f {
        max-width: 400%!important
    }
    .mn-wd-lg-400p-f {
        min-width: 400%!important
    }
    .wd-lg-450 {
        width: 450px
    }
    .wd-lg-450p {
        width: 450%
    }
    .mx-wd-lg-450p {
        max-width: 450%
    }
    .mn-wd-lg-450p {
        min-width: 450%
    }
    .wd-lg-450-f {
        width: 450px!important
    }
    .wd-lg-450p-f {
        width: 450%!important
    }
    .mx-wd-lg-450p-f {
        max-width: 450%!important
    }
    .mn-wd-lg-450p-f {
        min-width: 450%!important
    }
    .wd-lg-500 {
        width: 500px
    }
    .wd-lg-500p {
        width: 500%
    }
    .mx-wd-lg-500p {
        max-width: 500%
    }
    .mn-wd-lg-500p {
        min-width: 500%
    }
    .wd-lg-500-f {
        width: 500px!important
    }
    .wd-lg-500p-f {
        width: 500%!important
    }
    .mx-wd-lg-500p-f {
        max-width: 500%!important
    }
    .mn-wd-lg-500p-f {
        min-width: 500%!important
    }
    .wd-lg-550 {
        width: 550px
    }
    .wd-lg-550p {
        width: 550%
    }
    .mx-wd-lg-550p {
        max-width: 550%
    }
    .mn-wd-lg-550p {
        min-width: 550%
    }
    .wd-lg-550-f {
        width: 550px!important
    }
    .wd-lg-550p-f {
        width: 550%!important
    }
    .mx-wd-lg-550p-f {
        max-width: 550%!important
    }
    .mn-wd-lg-550p-f {
        min-width: 550%!important
    }
    .wd-lg-600 {
        width: 600px
    }
    .wd-lg-600p {
        width: 600%
    }
    .mx-wd-lg-600p {
        max-width: 600%
    }
    .mn-wd-lg-600p {
        min-width: 600%
    }
    .wd-lg-600-f {
        width: 600px!important
    }
    .wd-lg-600p-f {
        width: 600%!important
    }
    .mx-wd-lg-600p-f {
        max-width: 600%!important
    }
    .mn-wd-lg-600p-f {
        min-width: 600%!important
    }
    .wd-lg-650 {
        width: 650px
    }
    .wd-lg-650p {
        width: 650%
    }
    .mx-wd-lg-650p {
        max-width: 650%
    }
    .mn-wd-lg-650p {
        min-width: 650%
    }
    .wd-lg-650-f {
        width: 650px!important
    }
    .wd-lg-650p-f {
        width: 650%!important
    }
    .mx-wd-lg-650p-f {
        max-width: 650%!important
    }
    .mn-wd-lg-650p-f {
        min-width: 650%!important
    }
    .wd-lg-700 {
        width: 700px
    }
    .wd-lg-700p {
        width: 700%
    }
    .mx-wd-lg-700p {
        max-width: 700%
    }
    .mn-wd-lg-700p {
        min-width: 700%
    }
    .wd-lg-700-f {
        width: 700px!important
    }
    .wd-lg-700p-f {
        width: 700%!important
    }
    .mx-wd-lg-700p-f {
        max-width: 700%!important
    }
    .mn-wd-lg-700p-f {
        min-width: 700%!important
    }
    .wd-lg-750 {
        width: 750px
    }
    .wd-lg-750p {
        width: 750%
    }
    .mx-wd-lg-750p {
        max-width: 750%
    }
    .mn-wd-lg-750p {
        min-width: 750%
    }
    .wd-lg-750-f {
        width: 750px!important
    }
    .wd-lg-750p-f {
        width: 750%!important
    }
    .mx-wd-lg-750p-f {
        max-width: 750%!important
    }
    .mn-wd-lg-750p-f {
        min-width: 750%!important
    }
    .wd-lg-800 {
        width: 800px
    }
    .wd-lg-800p {
        width: 800%
    }
    .mx-wd-lg-800p {
        max-width: 800%
    }
    .mn-wd-lg-800p {
        min-width: 800%
    }
    .wd-lg-800-f {
        width: 800px!important
    }
    .wd-lg-800p-f {
        width: 800%!important
    }
    .mx-wd-lg-800p-f {
        max-width: 800%!important
    }
    .mn-wd-lg-800p-f {
        min-width: 800%!important
    }
    .wd-lg-850 {
        width: 850px
    }
    .wd-lg-850p {
        width: 850%
    }
    .mx-wd-lg-850p {
        max-width: 850%
    }
    .mn-wd-lg-850p {
        min-width: 850%
    }
    .wd-lg-850-f {
        width: 850px!important
    }
    .wd-lg-850p-f {
        width: 850%!important
    }
    .mx-wd-lg-850p-f {
        max-width: 850%!important
    }
    .mn-wd-lg-850p-f {
        min-width: 850%!important
    }
    .wd-lg-900 {
        width: 900px
    }
    .wd-lg-900p {
        width: 900%
    }
    .mx-wd-lg-900p {
        max-width: 900%
    }
    .mn-wd-lg-900p {
        min-width: 900%
    }
    .wd-lg-900-f {
        width: 900px!important
    }
    .wd-lg-900p-f {
        width: 900%!important
    }
    .mx-wd-lg-900p-f {
        max-width: 900%!important
    }
    .mn-wd-lg-900p-f {
        min-width: 900%!important
    }
    .wd-lg-950 {
        width: 950px
    }
    .wd-lg-950p {
        width: 950%
    }
    .mx-wd-lg-950p {
        max-width: 950%
    }
    .mn-wd-lg-950p {
        min-width: 950%
    }
    .wd-lg-950-f {
        width: 950px!important
    }
    .wd-lg-950p-f {
        width: 950%!important
    }
    .mx-wd-lg-950p-f {
        max-width: 950%!important
    }
    .mn-wd-lg-950p-f {
        min-width: 950%!important
    }
    .wd-lg-1000 {
        width: 1000px
    }
    .wd-lg-1000p {
        width: 1000%
    }
    .mx-wd-lg-1000p {
        max-width: 1000%
    }
    .mn-wd-lg-1000p {
        min-width: 1000%
    }
    .wd-lg-1000-f {
        width: 1000px!important
    }
    .wd-lg-1000p-f {
        width: 1000%!important
    }
    .mx-wd-lg-1000p-f {
        max-width: 1000%!important
    }
    .mn-wd-lg-1000p-f {
        min-width: 1000%!important
    }
    .wd-lg-auto {
        width: auto
    }
    .wd-lg-auto-f {
        width: auto!important
    }
}

@media (min-width:1200px) {
    .wd-xl-5 {
        width: 5px
    }
    .wd-xl-5p {
        width: 5%
    }
    .mx-wd-xl-5p {
        max-width: 5%
    }
    .mn-wd-xl-5p {
        min-width: 5%
    }
    .wd-xl-5-f {
        width: 5px!important
    }
    .wd-xl-5p-f {
        width: 5%!important
    }
    .mx-wd-xl-5p-f {
        max-width: 5%!important
    }
    .mn-wd-xl-5p-f {
        min-width: 5%!important
    }
    .wd-xl-10 {
        width: 10px
    }
    .wd-xl-10p {
        width: 10%
    }
    .mx-wd-xl-10p {
        max-width: 10%
    }
    .mn-wd-xl-10p {
        min-width: 10%
    }
    .wd-xl-10-f {
        width: 10px!important
    }
    .wd-xl-10p-f {
        width: 10%!important
    }
    .mx-wd-xl-10p-f {
        max-width: 10%!important
    }
    .mn-wd-xl-10p-f {
        min-width: 10%!important
    }
    .wd-xl-15 {
        width: 15px
    }
    .wd-xl-15p {
        width: 15%
    }
    .mx-wd-xl-15p {
        max-width: 15%
    }
    .mn-wd-xl-15p {
        min-width: 15%
    }
    .wd-xl-15-f {
        width: 15px!important
    }
    .wd-xl-15p-f {
        width: 15%!important
    }
    .mx-wd-xl-15p-f {
        max-width: 15%!important
    }
    .mn-wd-xl-15p-f {
        min-width: 15%!important
    }
    .wd-xl-20 {
        width: 20px
    }
    .wd-xl-20p {
        width: 20%
    }
    .mx-wd-xl-20p {
        max-width: 20%
    }
    .mn-wd-xl-20p {
        min-width: 20%
    }
    .wd-xl-20-f {
        width: 20px!important
    }
    .wd-xl-20p-f {
        width: 20%!important
    }
    .mx-wd-xl-20p-f {
        max-width: 20%!important
    }
    .mn-wd-xl-20p-f {
        min-width: 20%!important
    }
    .wd-xl-25 {
        width: 25px
    }
    .wd-xl-25p {
        width: 25%
    }
    .mx-wd-xl-25p {
        max-width: 25%
    }
    .mn-wd-xl-25p {
        min-width: 25%
    }
    .wd-xl-25-f {
        width: 25px!important
    }
    .wd-xl-25p-f {
        width: 25%!important
    }
    .mx-wd-xl-25p-f {
        max-width: 25%!important
    }
    .mn-wd-xl-25p-f {
        min-width: 25%!important
    }
    .wd-xl-30 {
        width: 30px
    }
    .wd-xl-30p {
        width: 30%
    }
    .mx-wd-xl-30p {
        max-width: 30%
    }
    .mn-wd-xl-30p {
        min-width: 30%
    }
    .wd-xl-30-f {
        width: 30px!important
    }
    .wd-xl-30p-f {
        width: 30%!important
    }
    .mx-wd-xl-30p-f {
        max-width: 30%!important
    }
    .mn-wd-xl-30p-f {
        min-width: 30%!important
    }
    .wd-xl-35 {
        width: 35px
    }
    .wd-xl-35p {
        width: 35%
    }
    .mx-wd-xl-35p {
        max-width: 35%
    }
    .mn-wd-xl-35p {
        min-width: 35%
    }
    .wd-xl-35-f {
        width: 35px!important
    }
    .wd-xl-35p-f {
        width: 35%!important
    }
    .mx-wd-xl-35p-f {
        max-width: 35%!important
    }
    .mn-wd-xl-35p-f {
        min-width: 35%!important
    }
    .wd-xl-40 {
        width: 40px
    }
    .wd-xl-40p {
        width: 40%
    }
    .mx-wd-xl-40p {
        max-width: 40%
    }
    .mn-wd-xl-40p {
        min-width: 40%
    }
    .wd-xl-40-f {
        width: 40px!important
    }
    .wd-xl-40p-f {
        width: 40%!important
    }
    .mx-wd-xl-40p-f {
        max-width: 40%!important
    }
    .mn-wd-xl-40p-f {
        min-width: 40%!important
    }
    .wd-xl-45 {
        width: 45px
    }
    .wd-xl-45p {
        width: 45%
    }
    .mx-wd-xl-45p {
        max-width: 45%
    }
    .mn-wd-xl-45p {
        min-width: 45%
    }
    .wd-xl-45-f {
        width: 45px!important
    }
    .wd-xl-45p-f {
        width: 45%!important
    }
    .mx-wd-xl-45p-f {
        max-width: 45%!important
    }
    .mn-wd-xl-45p-f {
        min-width: 45%!important
    }
    .wd-xl-50 {
        width: 50px
    }
    .wd-xl-50p {
        width: 50%
    }
    .mx-wd-xl-50p {
        max-width: 50%
    }
    .mn-wd-xl-50p {
        min-width: 50%
    }
    .wd-xl-50-f {
        width: 50px!important
    }
    .wd-xl-50p-f {
        width: 50%!important
    }
    .mx-wd-xl-50p-f {
        max-width: 50%!important
    }
    .mn-wd-xl-50p-f {
        min-width: 50%!important
    }
    .wd-xl-55 {
        width: 55px
    }
    .wd-xl-55p {
        width: 55%
    }
    .mx-wd-xl-55p {
        max-width: 55%
    }
    .mn-wd-xl-55p {
        min-width: 55%
    }
    .wd-xl-55-f {
        width: 55px!important
    }
    .wd-xl-55p-f {
        width: 55%!important
    }
    .mx-wd-xl-55p-f {
        max-width: 55%!important
    }
    .mn-wd-xl-55p-f {
        min-width: 55%!important
    }
    .wd-xl-60 {
        width: 60px
    }
    .wd-xl-60p {
        width: 60%
    }
    .mx-wd-xl-60p {
        max-width: 60%
    }
    .mn-wd-xl-60p {
        min-width: 60%
    }
    .wd-xl-60-f {
        width: 60px!important
    }
    .wd-xl-60p-f {
        width: 60%!important
    }
    .mx-wd-xl-60p-f {
        max-width: 60%!important
    }
    .mn-wd-xl-60p-f {
        min-width: 60%!important
    }
    .wd-xl-65 {
        width: 65px
    }
    .wd-xl-65p {
        width: 65%
    }
    .mx-wd-xl-65p {
        max-width: 65%
    }
    .mn-wd-xl-65p {
        min-width: 65%
    }
    .wd-xl-65-f {
        width: 65px!important
    }
    .wd-xl-65p-f {
        width: 65%!important
    }
    .mx-wd-xl-65p-f {
        max-width: 65%!important
    }
    .mn-wd-xl-65p-f {
        min-width: 65%!important
    }
    .wd-xl-70 {
        width: 70px
    }
    .wd-xl-70p {
        width: 70%
    }
    .mx-wd-xl-70p {
        max-width: 70%
    }
    .mn-wd-xl-70p {
        min-width: 70%
    }
    .wd-xl-70-f {
        width: 70px!important
    }
    .wd-xl-70p-f {
        width: 70%!important
    }
    .mx-wd-xl-70p-f {
        max-width: 70%!important
    }
    .mn-wd-xl-70p-f {
        min-width: 70%!important
    }
    .wd-xl-75 {
        width: 75px
    }
    .wd-xl-75p {
        width: 75%
    }
    .mx-wd-xl-75p {
        max-width: 75%
    }
    .mn-wd-xl-75p {
        min-width: 75%
    }
    .wd-xl-75-f {
        width: 75px!important
    }
    .wd-xl-75p-f {
        width: 75%!important
    }
    .mx-wd-xl-75p-f {
        max-width: 75%!important
    }
    .mn-wd-xl-75p-f {
        min-width: 75%!important
    }
    .wd-xl-80 {
        width: 80px
    }
    .wd-xl-80p {
        width: 80%
    }
    .mx-wd-xl-80p {
        max-width: 80%
    }
    .mn-wd-xl-80p {
        min-width: 80%
    }
    .wd-xl-80-f {
        width: 80px!important
    }
    .wd-xl-80p-f {
        width: 80%!important
    }
    .mx-wd-xl-80p-f {
        max-width: 80%!important
    }
    .mn-wd-xl-80p-f {
        min-width: 80%!important
    }
    .wd-xl-85 {
        width: 85px
    }
    .wd-xl-85p {
        width: 85%
    }
    .mx-wd-xl-85p {
        max-width: 85%
    }
    .mn-wd-xl-85p {
        min-width: 85%
    }
    .wd-xl-85-f {
        width: 85px!important
    }
    .wd-xl-85p-f {
        width: 85%!important
    }
    .mx-wd-xl-85p-f {
        max-width: 85%!important
    }
    .mn-wd-xl-85p-f {
        min-width: 85%!important
    }
    .wd-xl-90 {
        width: 90px
    }
    .wd-xl-90p {
        width: 90%
    }
    .mx-wd-xl-90p {
        max-width: 90%
    }
    .mn-wd-xl-90p {
        min-width: 90%
    }
    .wd-xl-90-f {
        width: 90px!important
    }
    .wd-xl-90p-f {
        width: 90%!important
    }
    .mx-wd-xl-90p-f {
        max-width: 90%!important
    }
    .mn-wd-xl-90p-f {
        min-width: 90%!important
    }
    .wd-xl-95 {
        width: 95px
    }
    .wd-xl-95p {
        width: 95%
    }
    .mx-wd-xl-95p {
        max-width: 95%
    }
    .mn-wd-xl-95p {
        min-width: 95%
    }
    .wd-xl-95-f {
        width: 95px!important
    }
    .wd-xl-95p-f {
        width: 95%!important
    }
    .mx-wd-xl-95p-f {
        max-width: 95%!important
    }
    .mn-wd-xl-95p-f {
        min-width: 95%!important
    }
    .wd-xl-100 {
        width: 100px
    }
    .wd-xl-100p {
        width: 100%
    }
    .mx-wd-xl-100p {
        max-width: 100%
    }
    .mn-wd-xl-100p {
        min-width: 100%
    }
    .wd-xl-100-f {
        width: 100px!important
    }
    .wd-xl-100p-f {
        width: 100%!important
    }
    .mx-wd-xl-100p-f {
        max-width: 100%!important
    }
    .mn-wd-xl-100p-f {
        min-width: 100%!important
    }
    .wd-xl-150 {
        width: 150px
    }
    .wd-xl-150p {
        width: 150%
    }
    .mx-wd-xl-150p {
        max-width: 150%
    }
    .mn-wd-xl-150p {
        min-width: 150%
    }
    .wd-xl-150-f {
        width: 150px!important
    }
    .wd-xl-150p-f {
        width: 150%!important
    }
    .mx-wd-xl-150p-f {
        max-width: 150%!important
    }
    .mn-wd-xl-150p-f {
        min-width: 150%!important
    }
    .wd-xl-200 {
        width: 200px
    }
    .wd-xl-200p {
        width: 200%
    }
    .mx-wd-xl-200p {
        max-width: 200%
    }
    .mn-wd-xl-200p {
        min-width: 200%
    }
    .wd-xl-200-f {
        width: 200px!important
    }
    .wd-xl-200p-f {
        width: 200%!important
    }
    .mx-wd-xl-200p-f {
        max-width: 200%!important
    }
    .mn-wd-xl-200p-f {
        min-width: 200%!important
    }
    .wd-xl-250 {
        width: 250px
    }
    .wd-xl-250p {
        width: 250%
    }
    .mx-wd-xl-250p {
        max-width: 250%
    }
    .mn-wd-xl-250p {
        min-width: 250%
    }
    .wd-xl-250-f {
        width: 250px!important
    }
    .wd-xl-250p-f {
        width: 250%!important
    }
    .mx-wd-xl-250p-f {
        max-width: 250%!important
    }
    .mn-wd-xl-250p-f {
        min-width: 250%!important
    }
    .wd-xl-300 {
        width: 300px
    }
    .wd-xl-300p {
        width: 300%
    }
    .mx-wd-xl-300p {
        max-width: 300%
    }
    .mn-wd-xl-300p {
        min-width: 300%
    }
    .wd-xl-300-f {
        width: 300px!important
    }
    .wd-xl-300p-f {
        width: 300%!important
    }
    .mx-wd-xl-300p-f {
        max-width: 300%!important
    }
    .mn-wd-xl-300p-f {
        min-width: 300%!important
    }
    .wd-xl-350 {
        width: 350px
    }
    .wd-xl-350p {
        width: 350%
    }
    .mx-wd-xl-350p {
        max-width: 350%
    }
    .mn-wd-xl-350p {
        min-width: 350%
    }
    .wd-xl-350-f {
        width: 350px!important
    }
    .wd-xl-350p-f {
        width: 350%!important
    }
    .mx-wd-xl-350p-f {
        max-width: 350%!important
    }
    .mn-wd-xl-350p-f {
        min-width: 350%!important
    }
    .wd-xl-400 {
        width: 400px
    }
    .wd-xl-400p {
        width: 400%
    }
    .mx-wd-xl-400p {
        max-width: 400%
    }
    .mn-wd-xl-400p {
        min-width: 400%
    }
    .wd-xl-400-f {
        width: 400px!important
    }
    .wd-xl-400p-f {
        width: 400%!important
    }
    .mx-wd-xl-400p-f {
        max-width: 400%!important
    }
    .mn-wd-xl-400p-f {
        min-width: 400%!important
    }
    .wd-xl-450 {
        width: 450px
    }
    .wd-xl-450p {
        width: 450%
    }
    .mx-wd-xl-450p {
        max-width: 450%
    }
    .mn-wd-xl-450p {
        min-width: 450%
    }
    .wd-xl-450-f {
        width: 450px!important
    }
    .wd-xl-450p-f {
        width: 450%!important
    }
    .mx-wd-xl-450p-f {
        max-width: 450%!important
    }
    .mn-wd-xl-450p-f {
        min-width: 450%!important
    }
    .wd-xl-500 {
        width: 500px
    }
    .wd-xl-500p {
        width: 500%
    }
    .mx-wd-xl-500p {
        max-width: 500%
    }
    .mn-wd-xl-500p {
        min-width: 500%
    }
    .wd-xl-500-f {
        width: 500px!important
    }
    .wd-xl-500p-f {
        width: 500%!important
    }
    .mx-wd-xl-500p-f {
        max-width: 500%!important
    }
    .mn-wd-xl-500p-f {
        min-width: 500%!important
    }
    .wd-xl-550 {
        width: 550px
    }
    .wd-xl-550p {
        width: 550%
    }
    .mx-wd-xl-550p {
        max-width: 550%
    }
    .mn-wd-xl-550p {
        min-width: 550%
    }
    .wd-xl-550-f {
        width: 550px!important
    }
    .wd-xl-550p-f {
        width: 550%!important
    }
    .mx-wd-xl-550p-f {
        max-width: 550%!important
    }
    .mn-wd-xl-550p-f {
        min-width: 550%!important
    }
    .wd-xl-600 {
        width: 600px
    }
    .wd-xl-600p {
        width: 600%
    }
    .mx-wd-xl-600p {
        max-width: 600%
    }
    .mn-wd-xl-600p {
        min-width: 600%
    }
    .wd-xl-600-f {
        width: 600px!important
    }
    .wd-xl-600p-f {
        width: 600%!important
    }
    .mx-wd-xl-600p-f {
        max-width: 600%!important
    }
    .mn-wd-xl-600p-f {
        min-width: 600%!important
    }
    .wd-xl-650 {
        width: 650px
    }
    .wd-xl-650p {
        width: 650%
    }
    .mx-wd-xl-650p {
        max-width: 650%
    }
    .mn-wd-xl-650p {
        min-width: 650%
    }
    .wd-xl-650-f {
        width: 650px!important
    }
    .wd-xl-650p-f {
        width: 650%!important
    }
    .mx-wd-xl-650p-f {
        max-width: 650%!important
    }
    .mn-wd-xl-650p-f {
        min-width: 650%!important
    }
    .wd-xl-700 {
        width: 700px
    }
    .wd-xl-700p {
        width: 700%
    }
    .mx-wd-xl-700p {
        max-width: 700%
    }
    .mn-wd-xl-700p {
        min-width: 700%
    }
    .wd-xl-700-f {
        width: 700px!important
    }
    .wd-xl-700p-f {
        width: 700%!important
    }
    .mx-wd-xl-700p-f {
        max-width: 700%!important
    }
    .mn-wd-xl-700p-f {
        min-width: 700%!important
    }
    .wd-xl-750 {
        width: 750px
    }
    .wd-xl-750p {
        width: 750%
    }
    .mx-wd-xl-750p {
        max-width: 750%
    }
    .mn-wd-xl-750p {
        min-width: 750%
    }
    .wd-xl-750-f {
        width: 750px!important
    }
    .wd-xl-750p-f {
        width: 750%!important
    }
    .mx-wd-xl-750p-f {
        max-width: 750%!important
    }
    .mn-wd-xl-750p-f {
        min-width: 750%!important
    }
    .wd-xl-800 {
        width: 800px
    }
    .wd-xl-800p {
        width: 800%
    }
    .mx-wd-xl-800p {
        max-width: 800%
    }
    .mn-wd-xl-800p {
        min-width: 800%
    }
    .wd-xl-800-f {
        width: 800px!important
    }
    .wd-xl-800p-f {
        width: 800%!important
    }
    .mx-wd-xl-800p-f {
        max-width: 800%!important
    }
    .mn-wd-xl-800p-f {
        min-width: 800%!important
    }
    .wd-xl-850 {
        width: 850px
    }
    .wd-xl-850p {
        width: 850%
    }
    .mx-wd-xl-850p {
        max-width: 850%
    }
    .mn-wd-xl-850p {
        min-width: 850%
    }
    .wd-xl-850-f {
        width: 850px!important
    }
    .wd-xl-850p-f {
        width: 850%!important
    }
    .mx-wd-xl-850p-f {
        max-width: 850%!important
    }
    .mn-wd-xl-850p-f {
        min-width: 850%!important
    }
    .wd-xl-900 {
        width: 900px
    }
    .wd-xl-900p {
        width: 900%
    }
    .mx-wd-xl-900p {
        max-width: 900%
    }
    .mn-wd-xl-900p {
        min-width: 900%
    }
    .wd-xl-900-f {
        width: 900px!important
    }
    .wd-xl-900p-f {
        width: 900%!important
    }
    .mx-wd-xl-900p-f {
        max-width: 900%!important
    }
    .mn-wd-xl-900p-f {
        min-width: 900%!important
    }
    .wd-xl-950 {
        width: 950px
    }
    .wd-xl-950p {
        width: 950%
    }
    .mx-wd-xl-950p {
        max-width: 950%
    }
    .mn-wd-xl-950p {
        min-width: 950%
    }
    .wd-xl-950-f {
        width: 950px!important
    }
    .wd-xl-950p-f {
        width: 950%!important
    }
    .mx-wd-xl-950p-f {
        max-width: 950%!important
    }
    .mn-wd-xl-950p-f {
        min-width: 950%!important
    }
    .wd-xl-1000 {
        width: 1000px
    }
    .wd-xl-1000p {
        width: 1000%
    }
    .mx-wd-xl-1000p {
        max-width: 1000%
    }
    .mn-wd-xl-1000p {
        min-width: 1000%
    }
    .wd-xl-1000-f {
        width: 1000px!important
    }
    .wd-xl-1000p-f {
        width: 1000%!important
    }
    .mx-wd-xl-1000p-f {
        max-width: 1000%!important
    }
    .mn-wd-xl-1000p-f {
        min-width: 1000%!important
    }
    .wd-xl-auto {
        width: auto;
        width: auto!important
    }
}

.wd-100v {
    width: 100vw
}

.list-room .card {
    box-shadow: none;
}

.form-check label.form-check-label {
    cursor: pointer;
}

.list-room.accordion .card {
    border: 1px solid #dddddd !important;
    margin-bottom: 10px;
    border-radius: 0;
}

.list-room.accordion .table-bordered td,
.list-room.accordion .table-bordered th {
    padding: 5px;
}
.btn-reset-filter {
    margin-top: 29px;
    margin-right: 20px;
}
h6.revenue-statistic {
    margin: 20px 10px;
}
button > div.checked-in {
    position: absolute;
    width: 8px;
    height: 8px;
    left: 2px;
    top: 4px;
    border-radius: 50%;
    background-color: #0dc81b;
}
button > div.checked-out {
    position: absolute;
    width: 8px;
    height: 8px;
    right: 2px;
    top: 4px;
    border-radius: 50%;
    background-color: #c30f21;
}
.ajax-booking-cruise-onboard td > button {
    border: none;
}

.disable-room-label.hide-mobile{
    opacity: 0.4;
    margin: 0;
    font-size: 13px;
}
.info-td-room{
    min-width: 90px;
    font-size: 14px;
    text-align: center;
    height: 18px;
    position: relative;
    line-height: 18px;
    vertical-align: middle;
}
.info-td-room label{
    position: absolute;
    top:0;
    left:0;
}
.info-td-room a{
    position: absolute;
    right: 0;
}
@media (min-width:768px) {
    .disable-room-label.hide-mobile{
        opacity: 0;
        margin: 0;
    
    }
    .disable-room-label.hide-mobile:hover{
        opacity: 0.5;
        margin: 0;
    }
}
#booking-search-label {
    top: -8px;
    font-size: 13px;
}
#booking-search-label + div {
    padding: 0;
}
#booking-search {
    font-size: 13px;
}
.booking-prices label.btn {
    margin-top: 28px;
}
.dropdown {
    position:relative;
    display:block;
}
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    z-index: 1;
    top:8px;
    padding:10px 25px;
    box-shadow: 0 8px 16px 0 rgba(230, 233, 239, .4);
    border: 1px solid #ecf0fa;
}
.dropdown-content li{
    margin-top: 5px;
}

.dropdown:hover .dropdown-content {
     display: block;
     align-items: start;

}
.dropdown-content a{
    color: #5B6E88;
    font-size: 13px;
    white-space: nowrap;
}
.dropdown-content a:hover{
    color: #0362E8;
}
.btn-partner-list{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.sel-month-year{
    align-items: center;

}
.sel-month-year .col-2:nth-child(4){
    display: grid;
    margin-top: 10px;
}
.go-to-list {
    color: #078586;
    margin-left: 5px;
    font-size: 30px;
}
.go-to-list:hover{
    cursor: pointer;
}
@media(max-width:576px){
    .row-mobile{
        display: grid !important;
        grid-template-columns: repeat(3,1fr) !important;
    }
    .sel-month-year{
        display: grid !important;
        grid-template-columns: repeat(2,1fr) !important;
    }
    .sel-month-year .col-2{
        max-width: 100%;
        z-index: 2;
    }
}
/* .row-mobile{
    display: grid !important;
    grid-template-columns: repeat(3,1fr) !important;
} */